import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { Body, BodySize } from '../Typography/Body';
import { DoubleCheck } from '../Icons/DoubleCheck';
import { TypographyVariant } from '../Typography';

const BubbleContent = styled.div<{ isMine: boolean }>`
  padding: 4px 14px;
  background: ${({ isMine }) =>
    isMine
      ? `${Theme.gradients.gradientSecondary}`
      : `${Theme.colors.cappuccino[50]}`};
  border-radius: ${({ isMine }) =>
    isMine ? '19px 0px 19px 19px' : '0px 19px 19px 19px'};
  line-height: 150%;
  width: fit-content;
  align-items: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  max-width: 500px;
  word-break: break-word;
`;

const Container = styled.div`
  display: flex;
`;

const WrapperDate = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DoubleCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface IChatBubble {
  chatText: string;
  isMine: boolean;
  sentAt: string;
  readByOther?: boolean;
}

export const ChatBubble: React.FC<IChatBubble> = ({
  chatText,
  isMine,
  sentAt,
  readByOther
}) => {
  return (
    <BubbleContent isMine={isMine}>
      <Body
        size={BodySize.Body80}
        variant={TypographyVariant.Regular}
        margin={'4px 40px 4px 0'}
        color={
          isMine ? Theme.specialColors.white : Theme.colors.cappuccino[900]
        }
      >
        {chatText}
      </Body>
      <Container>
        {readByOther && (
          <DoubleCheckWrapper>
            <DoubleCheck
              color={
                isMine ? Theme.colors.candy[50] : Theme.colors.cappuccino[500]
              }
              height={11}
              width={15}
            />
          </DoubleCheckWrapper>
        )}
        <WrapperDate data-testid="data-and-hour-msg">
          <Body
            variant={TypographyVariant.Regular}
            size={BodySize.Body50}
            color={
              isMine ? Theme.colors.candy[50] : Theme.colors.cappuccino[700]
            }
          >
            {sentAt}
          </Body>
        </WrapperDate>
      </Container>
    </BubbleContent>
  );
};
