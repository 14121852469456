import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export const AppointmentRedirect = () => {
  const { currentUser, loaded } = useFirebaseUser();
  const { id } = useParams();
  const location = useLocation();

  if (!loaded) {
    return <></>;
  }
  if (currentUser.isAnonymous) {
    return <Navigate to="/signup/survey/welcome" replace />;
  }

  if (currentUser.role === 'onboarding_doctor') {
    return <Navigate to="/doctor-acquisition" replace />;
  }

  if (currentUser.role === 'doctor') {
    return <Navigate to={`/doctor/appointment/${id}`} replace />;
  }

  if (currentUser.role === 'patient') {
    return <Navigate to={`/patient/appointment/${id}`} replace />;
  }

  return <Navigate to="/login" state={{ prevLocation: location }} replace />;
};
