import React from 'react';
import { Stack, Text, ActionIcon } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { ArrowLeft, ArrowRight } from '@unobravo/zenit-icons';
import { NotificationBadge } from '@unobravo-monorepo/patient/shared/components/NotificationBadge';

export interface IWeek {
  dayOfWeek: string;
  dayOfMonth: number;
  date: DateTime;
}

export const WeekDaySelector: React.FC<{
  selectedDay: DateTime;
  setSelectedDay: (date: DateTime) => void;
  country: string;
  notificationCount?: number;
}> = ({ selectedDay, setSelectedDay, country, notificationCount }) => {
  const firstDayOfWeek = selectedDay.startOf('week');
  const lastDayOfWeek = selectedDay.endOf('week');

  const onNext = () => {
    const newSelectedDay = selectedDay.plus({ week: 1 });
    setSelectedDay(newSelectedDay);
  };

  const onPrev = () => {
    const newSelectedDay = selectedDay.minus({ week: 1 });
    setSelectedDay(newSelectedDay);
  };

  return (
    <Stack align="center" mt="md" mb="sm" justify="space-between">
      {notificationCount && notificationCount > 0 ? (
        <NotificationBadge
          style={{ cursor: 'pointer' }}
          value={notificationCount}
          onClick={onNext}
        />
      ) : null}
      <ActionIcon
        size="md"
        colorVariant="default"
        variant="ghost"
        onClick={onPrev}
      >
        <ArrowLeft />
      </ActionIcon>
      <Text
        variant="md"
        color="grey.700"
        fontWeight="semibold"
        style={{
          textTransform: 'capitalize'
        }}
      >
        {firstDayOfWeek?.toLocaleString({
          day: 'numeric',
          month: 'short'
        })}
        {' - '}
        {lastDayOfWeek?.toLocaleString(
          {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          },
          { locale: country }
        )}
      </Text>
      <ActionIcon
        size="md"
        colorVariant="default"
        variant="ghost"
        onClick={onNext}
      >
        <ArrowRight />
      </ActionIcon>
    </Stack>
  );
};
