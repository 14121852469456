import { Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  CopyToClipboard,
  CopyToClipboardVariant
} from '../../../shared/components/CopyToClipboard';
import { useToast } from '../../toaster/hooks/useToast';
import { VoucherAchievementFragmentWithStatus } from '../types';

type VoucherProps = {
  voucher: VoucherAchievementFragmentWithStatus;
  tag: string;
};

type VoucherBodyProps = {
  description: string | null | undefined;
  variant: CopyToClipboardVariant;
  onCopySuccess?: () => void;
  value: string;
  tag: string;
};

const VoucherBody = ({
  description,
  variant,
  onCopySuccess,
  value,
  tag
}: VoucherBodyProps) => {
  return (
    <>
      <CopyToClipboard
        variant={variant}
        value={value}
        onCopySuccess={onCopySuccess}
        data-testid={tag}
      />
      <Text
        variant="xs"
        fontWeight="medium"
        color="grey.800"
        textAlign="center"
      >
        {description}
      </Text>
    </>
  );
};

export const VoucherInternal = ({ voucher, tag }: VoucherProps) => {
  const { t } = useTranslation();
  const { sendToast } = useToast();

  switch (voucher.voucherStatus) {
    case 'not-achieved':
      return (
        <VoucherBody
          description={voucher.description}
          tag={tag}
          value="********"
          variant="code_locked"
        />
      );
    case 'achieved':
      return (
        <VoucherBody
          description={`${t('vouchers.voucher.validUntil')} ${DateTime.fromISO(
            voucher.validUntil
          ).toFormat('dd/mm/yyyy')}`}
          tag={tag}
          value={voucher.code}
          variant="code_generated"
          onCopySuccess={() => {
            sendToast({
              variant: 'success',
              title: t('vouchers.voucher.codeCopied')
            });
          }}
        />
      );
    case 'expired':
      return (
        <VoucherBody
          description={`${t('vouchers.voucher.expired')}`}
          tag={tag}
          value={voucher.code}
          variant="code_expired"
        />
      );
    case 'used':
      return (
        <VoucherBody
          description={`${t('vouchers.voucher.redeemed')}`}
          tag={tag}
          value={voucher.code}
          variant="code_already_used"
        />
      );
    default:
      return null;
  }
};

export const Voucher = ({ voucher, tag }: VoucherProps) => {
  const voucherTitle = useMemo(
    () => (
      <Text variant="sm" fontWeight="bold" color="grey.800">
        {voucher.title}
      </Text>
    ),
    [voucher.title]
  );

  return (
    <Stack direction="column" spacing="2xs">
      {voucherTitle}

      <VoucherInternal tag={tag} voucher={voucher} />
    </Stack>
  );
};
