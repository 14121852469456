import React, { Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { mediaQueries } from '@unobravo-monorepo/common';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { Box, Stack, Heading, Text, RStack, Button } from '@unobravo/zenit-web';
import paymentImg from '@unobravo-monorepo/patient/assets/payment.svg';
import { PaymentElement } from '@stripe/react-stripe-js';
import { StripeDisclaimer } from '../../payment/components/StripeDisclaimer';
import { RightWrapperIllustration } from './RightWrapperIllustration';
import { SectionHeader } from './SectionHeader';
import { usePaymentMethod } from '../../payment/hooks/usePaymentMethod';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  ${mediaQueries('md')(`
    justify-content: space-between;
  `)}
`;

interface IAddCardProps {
  context?: 'WEBAPP' | 'MOBILE';
  setAddNewCard?: Dispatch<SetStateAction<boolean>>;
}

export const AddNewCard: React.FC<IAddCardProps> = ({
  setAddNewCard,
  context
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [complete, setComplete] = React.useState(false);
  const { addElementLoading: loading, addElement } = usePaymentMethod();
  const { handleSubmit } = useForm();
  const isMobileApp = context === 'MOBILE';

  const submitHandler = async () => {
    const result = await addElement();
    if (result.payment_method) {
      if (setAddNewCard) {
        setAddNewCard(false);
      }
      navigate(isMobileApp ? '../mobile/paymentMethod' : '../paymentMethod', {
        state: { reload: true }
      });
    }
  };

  return (
    <PageContainer data-testid="card-number">
      {context !== 'MOBILE' && (
        <SectionHeader
          path="../paymentMethod"
          title={`${t('profileAndSettings.paymentMethodLabel')}`}
        />
      )}
      <Stack justify="space-between" h="100%">
        <RStack
          bgColor="white"
          grow
          p="lg"
          pb={{ base: 'lg', sm: undefined }}
          rounded="md"
          style={{ borderTopLeftRadius: '0px' }}
        >
          <Box maxW="480px">
            <Box my="2xs">
              <Heading variant="xl">{t('paymentMethod.cardData')}</Heading>
            </Box>
            <Box my="2xs">
              <Text variant="lg">{t('profileAndSettings.info')}</Text>
            </Box>
            <StyledForm onSubmit={handleSubmit(submitHandler)}>
              <Stack spacing="md" direction="column">
                <Box>
                  <PaymentElement
                    onChange={(e: { complete: boolean }) => {
                      setComplete(e.complete);
                    }}
                  />
                  <Stack my="xs" spacing="xs">
                    <StripeDisclaimer noStripeLink={isMobileApp} />
                  </Stack>
                </Box>
              </Stack>
              <RStack
                py="sm"
                spacing="md"
                justify={{ sm: 'start', base: 'center' }}
                w="100%"
                position={{ base: 'sticky', sm: 'relative' }}
                bottom="0"
                bgColor="white"
              >
                <Button
                  data-testid="save-btn"
                  label={t('personalInfo.save')}
                  type="submit"
                  disabled={!complete || loading}
                />
                <Button
                  label={t('personalInfo.cancel')}
                  variant="outlined"
                  type="button"
                  data-testid="cancel-btn"
                  onClick={() => {
                    navigate(
                      isMobileApp
                        ? '../mobile/paymentMethod'
                        : '../paymentMethod'
                    );
                  }}
                />
              </RStack>
            </StyledForm>
          </Box>
        </RStack>
        <RightWrapperIllustration illustration={paymentImg} />
      </Stack>
    </PageContainer>
  );
};
