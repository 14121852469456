import { Heading, Link, RStack } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { useTherapyFeedback } from '../../hooks/useTherapyFeedback';
import { openFeedbackModal } from '../../home.slice';
import { surveysForSession } from '../../utils/therapyFeedbackUtils';

export const TherapyFeedback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { doctor } = usePatient();
  const { numberOfSessions } = useTherapyFeedback();

  return (
    <RStack
      direction="column"
      p="xl"
      spacing="md"
      w={{ base: '100%', md: '400px' }}
    >
      <RStack direction="column">
        <Heading fontWeight="bold" color="cappuccino.900" variant="md">
          {t(`${surveysForSession[numberOfSessions].title}`, {
            name: doctor?.name
          })}
        </Heading>
      </RStack>
      <Link
        data-testid="hp-hero-CTA-open-therapyRating"
        color="candy.500"
        to={() => dispatch(openFeedbackModal())}
      >
        {t('home.feedbackMessage.button')}
      </Link>
    </RStack>
  );
};
