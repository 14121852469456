import { useSession } from '@unobravo-monorepo/patient/shared/hooks/useSession';
import { Box, Button, Heading, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateContainer } from './DateContainer';
import { useBundle } from '../../bundles/hooks/useBundle';
import { PaymentContext } from '../types/paymentContext';
import { Completed } from '../Completed';
import { BundleCard } from '../../../shared/components/Bundles/BundleCard';

export const PaySessionWithBundle = ({
  uuid,
  context
}: {
  uuid: string;
  context: PaymentContext;
}) => {
  const sessionData = useSession(uuid);
  const { t } = useTranslation();
  const { payLoading, payWithBundle, getPathFromContext, latestBundle } =
    useBundle();
  const navigate = useNavigate();
  const [confirmPage, setConfirmPage] = useState(false);
  if (!sessionData || sessionData.dataLoading) {
    return <Spinner />;
  }
  const pay = async () => {
    const res = await payWithBundle(+sessionData!.id);
    if (res && context !== 'MOBILE') {
      navigate(`../${getPathFromContext(context)}`);
    }
    setConfirmPage(true);
  };

  if (confirmPage && context === 'MOBILE') {
    return (
      <Completed
        context={context}
        sessionDate={sessionData.sessionDate}
        onClose={() => null}
      />
    );
  }

  return (
    <Stack direction="column" justify="space-between" h="100%">
      <Stack direction="column" spacing="xl" px="xl">
        <Stack direction="column" pt="xl">
          <Heading variant="lg" color="grey.900">
            {t('paySession.appointmentRecap.title')}
          </Heading>
        </Stack>
        <Stack direction="column" spacing="md" pt="md">
          <Box bgColor="cappuccino.100" rounded="md">
            <DateContainer sessionDate={sessionData.sessionDate} />
          </Box>
        </Stack>
        <BundleCard activeBundle bundle={latestBundle} />
      </Stack>
      <Stack spacing="xs" direction="column" p="xl">
        <RStack justify="end" direction={{ xs: 'column', md: 'row' }}>
          <Button
            onClick={pay}
            label={t('therapySetting.payWithBundle')}
            loading={payLoading}
            size="lg"
            data-testid="pay-session-button"
          />
        </RStack>
        <Text variant="sm" color="grey.600">
          {t('therapySetting.hpCard.payWithBundleNote')}
        </Text>
      </Stack>
    </Stack>
  );
};
