import { useMutation } from '@apollo/client';
import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { IToast } from '@unobravo-monorepo/common/types/IToast';
import { useFirebaseUser } from '@unobravo/core';
import { PlatformCountry } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SendResetPasswordEmailDocument } from '../graphql/mutation.generated';

export const useRecoverPassword = () => {
  const { t } = useTranslation();
  const { domainCountry } = useCountry();
  const { sendPasswordResetEmail } = useFirebaseUser();

  const [sendResetPasswordMutation] = useMutation(
    SendResetPasswordEmailDocument
  );

  const [loading, setLoading] = useState<boolean>(false);
  const addToast = useToaster();

  const recoverPassword = async (email: string) => {
    setLoading(true);
    let success = false;
    try {
      if (domainCountry === 'es') {
        await sendResetPasswordMutation({
          variables: {
            email,
            platformCountry: PlatformCountry.Es
          }
        });
      } else {
        await sendPasswordResetEmail(email, domainCountry);
      }
      success = true;

      const toast: IToast = {
        id: 'recoverPassword',
        title: t('recoverPassword.changePsw.successTitle'),
        description: t('recoverPassword.changePsw.successMessage'),
        variant: 'success'
      };
      addToast(toast);
    } catch (e) {
      const toast: Partial<IToast> = {
        title: t('common:errorPage.ops2'),
        description: t('recoverPassword.changePsw.error'),
        variant: 'error',
        duration: 'persistent'
      };
      addToast(toast);
    }
    setLoading(false);
    return success;
  };

  return { recoverPassword, loading };
};
