import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export const ActivatedPatientGuard: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const { currentUser } = useFirebaseUser();
  const { hasEmailVerified: userHasEmailVerified } = currentUser;

  if (!userHasEmailVerified) {
    return <Navigate to="activate-patient" />;
  }

  return <>{children}</>;
};
