import { Box, Heading, RStack, Stack, Text } from '@unobravo/zenit-web';
import { ModalStepper } from '../../../shared/components/Stepper/ModalStepper';
import { StepStatus } from '../../../types/IStep';

interface IInfoHeaderProps {
  title: string;
  description?: string;
  active: string;
  showStepper: boolean;
}

const SHOWN_STEPS: {
  status: StepStatus;
  id: string;
  labelI18nKey: string;
}[] = [
  {
    labelI18nKey: 'common:appointment',
    status: 'inactive',
    id: 'RECAP'
  },
  {
    labelI18nKey: 'paySession.paymentStepper.billing',
    status: 'inactive',
    id: 'BILLING_INFO'
  },
  {
    labelI18nKey: 'paySession.paymentStepper.payment',
    status: 'inactive',
    id: 'PAYMENT_METHOD'
  }
];

export const InfoHeader = ({
  title,
  description,
  active,
  showStepper
}: IInfoHeaderProps) => {
  return (
    <Box
      position="sticky"
      top={0}
      bgColor="white"
      style={{ zIndex: 3 }}
      px="xl"
      pt="xl"
      rounded="lg"
      radiusTopLeft="lg"
      radiusTopRight="lg"
      pb="2xs"
    >
      {showStepper && (
        <RStack
          justify={{
            xs: 'center',
            sm: 'center',
            md: 'start',
            lg: 'start',
            xl: 'start'
          }}
        >
          <Box mt="xs" mb="xl">
            <ModalStepper
              stepsLabels={SHOWN_STEPS.map((one) => ({
                ...one,
                status: active === one.id ? 'active' : 'inactive'
              }))}
            />
          </Box>
        </RStack>
      )}
      <Stack direction="column" spacing="2xs">
        <Heading variant="lg" color="grey.900">
          {title}
        </Heading>
        {description && (
          <Text variant="md" color="grey.600">
            {description}
          </Text>
        )}
      </Stack>
    </Box>
  );
};
