import { Color, useColor } from '@unobravo/zenit-core';

export const Checked = ({
  color = 'grey.100',
  size = 20
}: {
  color?: Color;
  size?: number;
}) => {
  const calculatedColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill={calculatedColor} />
      <path
        d="M14.25 7L8.75 12.5L6.25 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
