import { createSlice } from '@reduxjs/toolkit';
import { ITherapySetting } from '@unobravo-monorepo/common/types/ITherapySetting';
import { PatientRootState } from '@unobravo-monorepo/patient/store';

export interface ITherapySettingState {
  therapyInfo?: {
    therapySetting?: ITherapySetting;
    firstSession?: string;
    firstSessionWithCurrentDoctor?: string;
    doctorTherapeuticOrientation?: string;
  };
}

const initialState: ITherapySettingState = {
  therapyInfo: {}
};

export const TherapySettingSlice = createSlice({
  name: 'therapySetting',
  initialState,
  reducers: {
    setTherapyInfo: (state, action) => {
      state.therapyInfo = action.payload;
    }
  }
});

export default TherapySettingSlice.reducer;

export const { setTherapyInfo } = TherapySettingSlice.actions;

export const TherapySettingSelector = (
  state: PatientRootState
): ITherapySettingState => state.therapySetting;
