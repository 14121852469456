import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { NewPaySession } from '@unobravo-monorepo/patient/features/newPaySession';
import { useFirstPayment } from '@unobravo/patient';
import { Navigate, useParams } from 'react-router-dom';
import { useBundle } from '../bundles/hooks/useBundle';
import { PaySession } from '../paySession';
import { PaymentContext } from './types/paymentContext';

export const PaySessionWrapper = ({ context }: { context: PaymentContext }) => {
  const { sessionId } = useParams();
  const { candidateForFirstPurchase, loading } = useFirstPayment(sessionId!);
  const {
    hasSeenPaywall,
    hasBundleRemaining,
    isBundleOpened,
    getPathFromContext
  } = useBundle();

  if (isBundleOpened && (hasBundleRemaining || !hasSeenPaywall)) {
    return (
      <>
        {context !== 'MOBILE' ? (
          <Navigate
            to={`../${getPathFromContext(context)}`}
            state={{
              referrer: {
                path: `${getPathFromContext(
                  context
                )}/bundlePaySession/${sessionId}`
              }
            }}
          />
        ) : (
          <Navigate to={`../mobile/bundlePaySession/${sessionId}`} />
        )}
      </>
    );
  }

  if (loading) return <Spinner />;

  return (
    <>
      {candidateForFirstPurchase ? (
        <PaySession context={context} />
      ) : (
        <NewPaySession context={context} />
      )}
    </>
  );
};
