/* eslint-disable @typescript-eslint/naming-convention */
export enum SIGNUP_REDIRECT_CONTEXTS {
  INFO = 'INFO',
  ACTIVATE_PATIENT = 'ACTIVATE_PATIENT'
}

export enum PATIENT_REDIRECT_CONTEXTS {
  SIGNUP_WELCOME = 'SIGNUP_WELCOME',
  REASSIGNMENT_CONFIRM = 'REASSIGNMENT_CONFIRM'
}

export enum PARTNERS_REDIRECT_CONTEXT {
  SIGNUP = 'SIGNUP'
}
