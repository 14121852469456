import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useCountry } from '../hooks/useCountry';
import { useI18nBackends } from '../hooks/useI18nBackends';
import { Namespace } from '../types/namespaces';
import { isLocalDev } from '../utils';

type TranslationsProviderProps = {
  namespaces: Namespace[];
};

export function TranslationsProvider({
  namespaces,
  children
}: PropsWithChildren<TranslationsProviderProps>) {
  const { backends, backendOptions } = useI18nBackends({
    production: ['S3', 'local-files'],
    staging: ['S3', 'local-files'], // XYZ
    preview: ['local-files'],
    local: ['local-files']
  });

  const [translationInstance] = useState(() => i18n.createInstance());

  const [i18nLoaded, setI18nLoaded] = useState<boolean>(false);
  const { domainCountry } = useCountry();

  const ns = [...namespaces, 'common'];

  const lng =
    window.origin.includes('translations-ub') || isLocalDev('lang=cc')
      ? 'cc'
      : domainCountry;

  function retryLoadResources(lang: string, namesp: string, retries = 3) {
    import(`../../../../locales/${lang}/${namesp}.json`)
      .then((resources) => {
        translationInstance.addResourceBundle(lang, namesp, resources.default);
      })
      .catch(() => {
        if (retries > 0) {
          // Retry after 1 second
          setTimeout(() => retryLoadResources(lang, namesp, retries - 1), 5000);
        } else {
          // If the resource not exist send a Sentry Error
          Sentry.captureException(
            new Error(`Could not load i18n resources: ${lang}/${namesp}`),
            {
              extra: {
                lng: lang,
                ns: namesp
              }
            }
          );
        }
      });
  }

  function initializeI18n() {
    // MissingKey observer
    // Scope definition is not relevant
    translationInstance.on('missingKey', (lngs, namespace, key, res) => {
      // Check if the namespace with his bundle has been loaded
      const hasBundleLoaded = translationInstance.hasResourceBundle(
        lng,
        namespace
      );

      // Check if the namespace contains the key
      const keyExsists = translationInstance.exists(key, {
        ns
      });

      // if namespace has been loaded and not contain the key then captureException.
      if (hasBundleLoaded && !keyExsists) {
        Sentry.captureException(new Error(`Missing i18n key: ${key}`), {
          extra: {
            loaded: translationInstance.isInitialized,
            lngs,
            ns: namespace,
            key,
            res
          }
        });
      }
    });

    translationInstance.on('failedLoading', (lang, names) => {
      // Check if the files/cdn is missing or is a page refreshing.
      retryLoadResources(lang, names);
    });

    translationInstance.use(Backend).init(
      {
        debug: false,
        fallbackLng: lng,
        // instead of using "en-EN" it will use "en"
        load: 'languageOnly',
        ns,
        // if default namespace is not found then check the fallback ns (all)
        fallbackNS: ns,
        defaultNS: ns[0],
        interpolation: {
          escapeValue: false
        },
        react: {
          useSuspense: true
        },

        // required to make missingKey working
        saveMissing: true,
        // handling missingkey when i18n is not loaded
        // will be overwriten by missingkey observer
        missingKeyHandler: () => {},
        backend: {
          backends,
          backendOptions
        }
      },
      () => setI18nLoaded(true)
    );
  }

  useEffect(() => {
    if (!i18nLoaded) {
      initializeI18n();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18nLoaded]);

  useEffect(() => {
    // clean the missingKey and failedLoading observers at unmount (switch lib)
    return () => {
      translationInstance.off('missingKey');
      translationInstance.off('failedLoading');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!i18nLoaded) return <></>;
  return (
    <I18nextProvider i18n={translationInstance}>
      <Suspense fallback={<></>}>{children}</Suspense>
    </I18nextProvider>
  );
}
