import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { paySessionDataSelector } from '../newPaySession/paySessionData.slice';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { RecapVoucher } from './RecapVoucher';
import { CardForm } from './components/CardForm';
import { InfoHeader } from './components/InfoHeader';

export const PaymentMethod: React.FC<{
  onBack?: () => void;
  onComplete?: () => void;
  onError?: () => void;
}> = ({ onBack, onError, onComplete }) => {
  const { sessionId } = useParams();
  const { voucher } = useSelector(paySessionDataSelector);
  const {
    paySession,
    paymentLoading: loading,
    amount: originalAmount
  } = useStripePaymentIntent(sessionId!);
  const { t } = useTranslation();
  const amount = voucher?.total ?? originalAmount;

  const pay = async (cardId?: string) => {
    try {
      await paySession(cardId);
      onComplete?.();
    } catch {
      onError?.();
    }
  };

  return (
    <>
      <InfoHeader
        showStepper
        title={t('profileAndSettings.paymentMethodLabel')}
        active="PAYMENT_METHOD"
      />
      {voucher?.total === '0,00' ? (
        <RecapVoucher pay={pay} loading={loading} onBack={() => onBack?.()} />
      ) : (
        <CardForm
          amount={amount ?? ''}
          loading={loading}
          pay={pay}
          onBack={() => onBack?.()}
          onError={() => onError?.()}
        />
      )}
    </>
  );
};
