import { Stack, Text } from '@unobravo/zenit-web';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AppleIcon = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9988 6.82353C14.688 7.64706 13.8783 9.05882 13.8783 10.6275C13.8783 12.3922 14.9193 14 16.5 14.6667C16.1916 15.6863 15.7289 16.6275 15.1506 17.4902C14.3024 18.7059 13.4157 19.9608 12.1048 19.9608C10.794 19.9608 10.4084 19.1765 8.86627 19.1765C7.36265 19.1765 6.82289 20 5.58916 20C4.35542 20 3.50723 18.8627 2.54337 17.451C1.27108 15.4902 0.538554 13.2157 0.5 10.8235C0.5 6.94118 2.96747 4.86274 5.43494 4.86274C6.74578 4.86274 7.8253 5.72549 8.63494 5.72549C9.40602 5.72549 10.6398 4.82353 12.1048 4.82353C13.647 4.78431 15.112 5.52941 15.9988 6.82353ZM11.4108 3.17647C12.0663 2.39216 12.4133 1.41176 12.4518 0.392157C12.4518 0.27451 12.4518 0.117647 12.4133 0C11.2952 0.117647 10.2542 0.666667 9.52169 1.52941C8.86626 2.27451 8.48072 3.21569 8.44217 4.23529C8.44217 4.35294 8.44217 4.47059 8.48072 4.58824C8.55783 4.58824 8.67349 4.62745 8.7506 4.62745C9.79157 4.54902 10.7554 4 11.4108 3.17647Z"
      fill="#0A0A0A"
    />
  </svg>
);

const GoogleIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="google_symbol.svg">
      <path
        id="Vector"
        d="M19.3 10.209C19.3 9.55898 19.2417 8.93398 19.1333 8.33398H10.5V11.8798H15.4333C15.2208 13.0257 14.575 13.9965 13.6042 14.6465V16.9465H16.5667C18.3 15.3507 19.3 13.0007 19.3 10.209Z"
        fill="#4285F4"
      />
      <path
        id="Vector_2"
        d="M10.5 19.1672C12.975 19.1672 15.05 18.3464 16.5667 16.9464L13.6042 14.6464C12.7833 15.1964 11.7333 15.5214 10.5 15.5214C8.11252 15.5214 6.09168 13.9089 5.37085 11.7422H2.30835V14.1172C3.81668 17.113 6.91668 19.1672 10.5 19.1672Z"
        fill="#34A853"
      />
      <path
        id="Vector_3"
        d="M5.37087 11.7411C5.18754 11.1911 5.08337 10.6036 5.08337 9.99948C5.08337 9.39531 5.18754 8.80781 5.37087 8.25781V5.88281H2.30837C1.66671 7.16019 1.33281 8.56999 1.33337 9.99948C1.33337 11.4786 1.68754 12.8786 2.30837 14.1161L5.37087 11.7411Z"
        fill="#FBBC05"
      />
      <path
        id="Vector_4"
        d="M10.5 4.47982C11.8458 4.47982 13.0542 4.94232 14.0042 5.85065L16.6333 3.22148C15.0458 1.74232 12.9708 0.833984 10.5 0.833984C6.91668 0.833984 3.81668 2.88815 2.30835 5.88398L5.37085 8.25898C6.09168 6.09232 8.11252 4.47982 10.5 4.47982Z"
        fill="#EA4335"
      />
    </g>
  </svg>
);

const Button = styled(Stack)`
  padding: ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.xl}px ${({ theme }) => theme.spacing.sm}px
    ${({ theme }) => theme.spacing.lg}px;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 90px;
  border: 1.5px solid ${({ theme }) => theme.colors.grey[200]};
  cursor: pointer;
`;

export const AppleButton = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Button {...props}>
      <AppleIcon />
      <Text variant="md" fontWeight="semibold">
        {t('common:social.registrationWithApple')}
      </Text>
    </Button>
  );
};

export const GoogleButton = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Button {...props}>
      <GoogleIcon />
      <Text variant="md" fontWeight="semibold">
        {t('common:social.registrationWithGoogle')}
      </Text>
    </Button>
  );
};
