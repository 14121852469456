import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import packageJson from '../../../../package.json';

let initialCheckDone = false;

export function useVersionCheck() {
  // get the base url
  const { origin } = window.location;
  const { pathname } = useLocation();
  // split the url by '/' and get the second part
  const second = pathname.split('/')[2];
  const timerIdRef = useRef<NodeJS.Timer>();
  const shouldReload = useRef(false);

  const packageJsonVersion = packageJson.version;

  const pollingCallback = useCallback(() => {
    fetch(`${origin}/assets/version.txt`, {
      cache: 'no-store'
    })
      .then((res) => {
        if (!res.ok) {
          return;
        }
        return res.text();
      })
      .then((rawVersion) => {
        if (!rawVersion) {
          return;
        }
        const version = rawVersion.trim();
        if (packageJsonVersion !== version) {
          shouldReload.current = true;
        }
      })
      .catch();
  }, [origin, packageJsonVersion]);

  useEffect(() => {
    if (!initialCheckDone) {
      initialCheckDone = true;
      pollingCallback();
    }
    timerIdRef.current = setInterval(pollingCallback, 1000 * 60 * 60);

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, [pollingCallback]);

  useEffect(() => {
    const first = pathname.split('/')[1];

    // if the user is on the questionnaire page, we don't want to reload the page
    if (shouldReload.current && first !== 'signup') {
      window.location.reload();
      shouldReload.current = false;
    }
    // this could maybe be improved in the future
    // for now we don't put "pathname" into the dependency array since we don't want to run this effect on every pathname change
    // we only need it to check if the user is currently on the questionnaire page, since we don't want to reload in that case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);
}
