import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Edit: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.candy[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3032 6.09686C16.6214 6.41519 16.8001 6.84682 16.8001 7.29686C16.8001 7.7469 16.6214 8.17853 16.3032 8.49686L8.31365 16.4865C8.10862 16.6915 7.85173 16.8369 7.57045 16.9073L4.80005 17.6001L5.49285 14.8297C5.56318 14.5484 5.70863 14.2915 5.91365 14.0865L13.904 6.09686C14.2222 5.77878 14.6537 5.6001 15.1037 5.6001C15.5536 5.6001 15.9851 5.77878 16.3032 6.09686V6.09686Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8 10.0001L12.4 7.6001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2001 16.8L18.3249 17.6752C17.8607 18.1393 17.2312 18.4 16.5749 18.4C15.9185 18.4 15.2891 18.1393 14.8249 17.6752C14.3601 17.2121 13.7307 16.9521 13.0745 16.9521C12.4183 16.9521 11.7889 17.2121 11.3241 17.6752"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
