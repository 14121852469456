import { usePatient } from '../../patientData/hooks/usePatient';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePaymentUtils } from '@unobravo/patient';

export const useDeleteCard = () => {
  const { id: patientId } = usePatient();
  const { deleteCard, deleteCardLoading } = usePaymentUtils();
  const { sendGenericError } = useErrorHandler();

  const removeCard = async (cardId: string) => {
    if (!patientId) {
      return false;
    }
    const result = await deleteCard(cardId);
    if (!result) {
      sendGenericError();
    }
    return !!result;
  };

  return {
    removeCard,
    loading: deleteCardLoading
  };
};
