import { Theme } from '@unobravo-monorepo/common';
import {
  ChatBubbleWrapper,
  DateWrapper,
  HEADER_HEIGHT,
  MoreMessagesBtn,
  MoreMessagesBtnContainer
} from '@unobravo-monorepo/common/components/Chat';
import { ChatBubble } from '@unobravo-monorepo/common/components/ChatBubble';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { mobileHeaderHeight } from '@unobravo-monorepo/common/layouts/consts';
import { getMessageDate as getDate } from '@unobravo-monorepo/common/utils/dateUtils';
import { Box } from '@unobravo/zenit-web';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { AppointmentBubble } from '../../../shared/components/AppointmentBubble';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useChat } from '../hooks/useChat';
import { ChatAlerts } from './ChatAlerts';
import { chatSelector } from '../store/selectors';

export const ChatBox: React.FC<{
  scrollToBottom: (smooth?: boolean) => void;
}> = ({ scrollToBottom }) => {
  const { t } = useTranslation();
  const [chatTopHeight, setChatTopHeight] = useState(0);
  const { timezone, id } = usePatient();
  const { isMobile } = useViewport();
  const {
    messages,
    hasPreviousMessages,
    loadMessages,
    scroll,
    loading,
    error,
    groupedMessages,
    setMessageAsRead
  } = useChat();
  const { newMessage } = useSelector(chatSelector);
  const alertStickyRef = useRef<HTMLDivElement>(null);
  const messagesLengthRef = useRef<number>(0);

  const dateKeys = useMemo(
    () =>
      Object.keys(groupedMessages).sort((a: string, b: string) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }),
    [groupedMessages]
  );

  useLayoutEffect(() => {
    setChatTopHeight(
      HEADER_HEIGHT +
        (isMobile ? mobileHeaderHeight : 0) +
        (alertStickyRef.current?.offsetHeight || 1) -
        1
    );
  }, [isMobile, alertStickyRef.current?.offsetHeight, messages.length]);

  useEffect(() => {
    if ((scroll || messagesLengthRef.current === 0) && messages.length > 0) {
      scrollToBottom(scroll === 'SMOOTH');
    }
    messagesLengthRef.current = messages.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, messages]);

  const setMessageRead = async (conversationId: number, patientId: number) => {
    try {
      await setMessageAsRead(conversationId, patientId);
    } catch (e) {
      Sentry.captureException(new Error(`Failed to set the message as read`), {
        extra: {
          error: e
        }
      });
    }
  };

  useEffect(() => {
    if (id && messages && messages[0]?.conversationId && newMessage) {
      setMessageRead(messages[0].conversationId, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, messages, newMessage]);

  if (loading && !messages.length) {
    return <Spinner />;
  }

  return (
    <>
      <ChatAlerts ref={alertStickyRef} />
      <MoreMessagesBtnContainer>
        {(hasPreviousMessages || error) && !loading && (
          <MoreMessagesBtn
            onClick={() => loadMessages()}
            data-testid="more-msgs-btn"
            disabled={loading}
          >
            {t('chat.moreMessages')}
          </MoreMessagesBtn>
        )}
      </MoreMessagesBtnContainer>

      {dateKeys.map((daysMessages, index) => {
        const date = getDate(groupedMessages[daysMessages][0].sentAt, timezone);
        return (
          <React.Fragment key={daysMessages}>
            <DateWrapper isSticky top={chatTopHeight} index={index}>
              <Body size={BodySize.Body50} color={Theme.colors.gray[300]}>
                {date.isToday
                  ? t('chat.today')
                  : `${date.day} ${date.monthLong} ${date.year}`}
              </Body>
            </DateWrapper>
            {groupedMessages[daysMessages].map((message) =>
              message.session ? (
                <Box mb="xl" mt="xl" key={message.id}>
                  <AppointmentBubble
                    session={message.session}
                    sentAt={getDate(message.sentAt, timezone)?.hour}
                    key={message.id}
                    showVideocall
                  />
                </Box>
              ) : (
                <ChatBubbleWrapper isMine={message.isMine} key={message.id}>
                  <ChatBubble
                    chatText={message.content}
                    isMine={message.isMine}
                    sentAt={getDate(message.sentAt, timezone)?.hour}
                  />
                </ChatBubbleWrapper>
              )
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
