import {
  Alignment,
  Fit,
  Layout as RiveLayout,
  useRive,
  useStateMachineInput
} from '@rive-app/react-canvas';
import { Box, useResponsiveValue } from '@unobravo/zenit-web';
import { useEffect } from 'react';
import styled from 'styled-components';
import BgAnimation from '../../assets/background.riv';

export const RiveContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  z-index: -2;
`;

export const RiveBackground = () => {
  const size = useResponsiveValue<number>({
    xs: 60,
    sm: 30,
    md: 0,
    lg: 0,
    xl: 0
  });

  const { RiveComponent: Background, rive } = useRive({
    src: BgAnimation,
    autoplay: true,
    stateMachines: 'background',

    layout: new RiveLayout({
      alignment: Alignment.BottomCenter,
      fit: Fit.Cover
    })
  });

  const backgroundInput = useStateMachineInput(
    rive,
    'background',
    'viewSize',
    size
  );

  useEffect(() => {
    if (!backgroundInput) return;

    backgroundInput.value = size;
  });

  return <Background />;
};
