import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { IAppliedVoucher } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { Box, Button, Stack, Text } from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFootnote } from '../../../../../common/src/utils/priceUtils';
import {
  ParsedVoucher,
  paySessionDataSelector
} from '../../newPaySession/paySessionData.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useApplyVoucher } from '../../payment/hooks/useApplyVoucher';

export const GiftCard = ({
  appliedVoucher,
  originalAmount,
  sessionId,
  onVoucherConfirmed
}: {
  appliedVoucher?: ParsedVoucher;
  originalAmount: string;
  sessionId: string;
  onVoucherConfirmed: (voucher: IAppliedVoucher & { code?: string }) => void;
}) => {
  const [code, setCode] = useState(appliedVoucher?.code ?? '');
  const { t } = useTranslation();
  const { isBpEnabled } = usePatient();
  const { verifyVoucher, voucherLoading, verifyBonus } = useApplyVoucher();
  const { domainCountry } = useCountry();
  const { paymentIntent } = useSelector(paySessionDataSelector);

  useEffect(() => {
    const verifyBonusPsicologo = async () => {
      if (sessionId && isBpEnabled) {
        const voucher = await verifyBonus(sessionId);
        if (voucher?.success) {
          onVoucherConfirmed(voucher);
          setCode('Bonus psicologo');
        }
      }
    };

    verifyBonusPsicologo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, isBpEnabled]);

  const discountedAmount = appliedVoucher?.success
    ? appliedVoucher?.total
    : undefined;
  const giftError =
    appliedVoucher?.code === code && !!appliedVoucher?.failureReason;

  const footnote = getFootnote(domainCountry, paymentIntent?.amount);

  return (
    <>
      <Stack direction="column" spacing="md">
        <Stack spacing="sm">
          <TextInput
            value={code}
            disabled={appliedVoucher?.success}
            onChange={({ target: { value } }) => setCode(value)}
            placeholder={t('paySession.giftCardSection.placeholderXs')}
            errorMessage={giftError ? t('paySession.wrongGiftCard') : ''}
            data-testid="session-gift-card-input"
          />
          <Stack py="2xs">
            {discountedAmount ? (
              <Box minW={110} style={{ alignSelf: 'center' }}>
                <Text color="edamame.500" fontWeight="semibold" variant="sm">
                  {t('paySession.giftCardSection.successXs')}
                </Text>
              </Box>
            ) : (
              <Button
                type="submit"
                variant={code.length ? 'outlined' : 'ghost'}
                label={t('paySession.giftCardSection.button')}
                disabled={!code.length}
                onClick={async () => {
                  const voucher = await verifyVoucher(code, sessionId);
                  if (voucher) {
                    onVoucherConfirmed({ ...voucher, code });
                  }
                }}
                loading={voucherLoading}
                data-testid="session-apply-gift-card-button"
              />
            )}
          </Stack>
        </Stack>
        <Stack>
          <Box grow>
            <Text color="black">{t('paySession.total')}</Text>
          </Box>
          {discountedAmount ? (
            <>
              <Box mr="2xs">
                <Text
                  color="grey.600"
                  style={{
                    textDecoration: 'line-through'
                  }}
                  data-testid="session-gift-card-original-amount"
                >{`${originalAmount}€${footnote}`}</Text>
              </Box>
              <Text
                fontWeight="bold"
                data-testid="session-gift-card-success"
              >{`${discountedAmount}€`}</Text>
            </>
          ) : (
            <Text fontWeight="bold">{`${originalAmount}€${footnote}`}</Text>
          )}
        </Stack>
      </Stack>
    </>
  );
};
