import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import { useTranslation } from 'react-i18next';

export const useTherapistSpecialization = (
  doctorOption: PreMatchOptionFragment
) => {
  const { t } = useTranslation();
  const { isSpecializing, sex, therapeuticOrientation } =
    doctorOption?.doctor || {};

  const therapeuticOrientationKey = `selectYourTp.therapeuticOrientation${
    isSpecializing ? `_psy${sex}` : '_tp'
  }` as const;

  const specializationJobKey = `selectYourTp.curriculum.job${
    isSpecializing ? `_psy${sex}` : '_tp'
  }` as const;

  return {
    therapeuticOrientation: therapeuticOrientation
      ? `${t(therapeuticOrientationKey)} ${therapeuticOrientation}`
      : null,
    specializationJobKey
  };
};
