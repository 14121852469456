import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import {
  Box,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFromURL } from '@unobravo-monorepo/common/utils/dateURLUtils';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { TherapySettingInfo } from '../components/TherapySettingInfo/TherapySettingInfo';
import { WeeklyAgenda } from '../components/WeeklyAgenda';

export const TherapySettingPage: React.FC = () => {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const { loadAppointments } = useAgenda();

  useEffect(() => {
    loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientLayout>
      <PageContainer>
        <PageContainerHeader>
          <Box px="xs">
            <Text variant="lg" fontWeight="semibold">
              {t('therapySetting.title')}
            </Text>
          </Box>
        </PageContainerHeader>
        {isMobile && (
          <Stack
            bgColor="cappuccino.50"
            borderTop="sm"
            borderBottom="sm"
            borderColor="cappuccino.200"
            w="100%"
            h={50}
            align="center"
            justify="center"
            style={{ flexShrink: 0 }}
          >
            <Text variant="lg" fontWeight="semibold">
              {t('therapySetting.title')}
            </Text>
          </Stack>
        )}

        <RStack
          direction={{ base: 'column', sm: 'row' }}
          px="md"
          py="2xl"
          spacing="xl"
        >
          <WeeklyAgenda agendaDay={getDateFromURL('day')} />
          <TherapySettingInfo />
        </RStack>
      </PageContainer>
    </PatientLayout>
  );
};
