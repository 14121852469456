import {
  FailurePage,
  IButton
} from '@unobravo-monorepo/common/components/SuccessFailure/FailurePage';
import { useInternationalVariables } from '@unobravo/translations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function ReassignmentFallback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { zendeskDefaultUrl } = useInternationalVariables();

  const buttons: IButton[] = [
    {
      label: 'Torna al tuo account',
      onClick: () => navigate('/')
    }
  ];

  return (
    <FailurePage
      title={t('reassignment.errorPage.title')}
      buttons={buttons}
      description={t('reassignment.errorPage.description', {
        zendeskDefaultUrl
      })}
      label={t('reassignment.errorPage.label').toUpperCase()}
    />
  );
}
