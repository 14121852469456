import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type VoucherAchievementFragment = { __typename?: 'VoucherAchievement', code: string, title: string, description?: string | null, validUntil: any, redeemedAt?: any | null };

export type ListAchievementsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type ListAchievementsQuery = { __typename?: 'Query', listAchievements: Array<{ __typename?: 'PatientAchievement', title: string, subtitle: string, description: string, tag: string, image: string, achieved: boolean, voucher?: { __typename?: 'VoucherAchievement', code: string, title: string, description?: string | null, validUntil: any, redeemedAt?: any | null } | null }> };

export const VoucherAchievementFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VoucherAchievement"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"VoucherAchievement"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"validUntil"}},{"kind":"Field","name":{"kind":"Name","value":"redeemedAt"}}]}}]} as unknown as DocumentNode<VoucherAchievementFragment, unknown>;
export const ListAchievementsDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListAchievements"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listAchievements"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"subtitle"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"tag"}},{"kind":"Field","name":{"kind":"Name","value":"image"}},{"kind":"Field","name":{"kind":"Name","value":"achieved"}},{"kind":"Field","name":{"kind":"Name","value":"voucher"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VoucherAchievement"}}]}}]}}]}},...VoucherAchievementFragmentDoc.definitions]} as unknown as DocumentNode<ListAchievementsQuery, ListAchievementsQueryVariables>;