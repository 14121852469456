import { DateTime } from 'luxon';
import { ListPatientBundlesQuery } from '../../features/home/graphql/queries.generated';

export const shouldOpenPaywall = (
  bundles: ListPatientBundlesQuery | undefined
) => {
  if (
    !bundles ||
    !bundles.listPatientBundles ||
    bundles.listPatientBundles?.length === 0
  )
    return true;

  const bundlesList = bundles?.listPatientBundles || [];
  const lastBundle = bundlesList[bundlesList.length - 1];

  if (
    lastBundle &&
    (DateTime.fromISO(lastBundle.validUntil) < DateTime.now() ||
      lastBundle.sessionsAvailables === 0)
  )
    return true;

  return false;
};
