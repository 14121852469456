import { mobileFirstMedia } from '@unobravo-monorepo/common';
import { Checkbox } from '@unobravo-monorepo/common/components/Checkbox';
import { CustomTooltip } from '@unobravo-monorepo/common/components/CustomTooltip';
import { Select } from '@unobravo-monorepo/common/components/Select';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { useDebounce } from '@unobravo-monorepo/common/hooks/useDebounce';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { IBillingData, usePatientInvoiceLanguages } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import {
  Countries,
  countriesMap,
  getCFValidator,
  normalizeFiscalCode,
  OtherCountries
} from '@unobravo/utils';
import { QuestionMark } from '@unobravo/zenit-icons';
import { Autocomplete, Stack, Text } from '@unobravo/zenit-web';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { generateFirstSixFiscalCode } from '@unobravo-monorepo/common/utils/generateFiscalCode';
import { useABTestVariant } from '@unobravo-monorepo/common/hooks';
import { useErrorHandler } from '../../shared/hooks/useErrorHandler';
import { FormPatient } from '../../types/IBillingForm';
import { usePlacesAutocomplete } from './hooks/usePlacesAutocomplete';

const FormContainer = styled(({ ...props }) => (
  <Stack {...props} bgColor="white" spacing="md" wrap />
))`
  --full: 100%;
  --3of6: calc(50% - 16px);
  --2of6: calc(((100% / 6) * 2) - 16px);
  --4of6: calc(((100% / 6) * 4) - 16px);
  --5of6: calc(((100% / 6) * 5) - 16px);
`;

const Label = styled(({ ...props }) => (
  <Text {...props} variant="md" fontWeight="medium" color="grey.900" />
))``;

const FullSize = styled(({ ...props }) => (
  <Stack {...props} direction="column" spacing="2xs" />
))`
  flex: 1 0 var(--full);
`;

const HalfSize = styled(FullSize)`
  ${() =>
    mobileFirstMedia('sm')(`
    flex: 1  0 var(--3of6);
  `)}
`;

const AddressDiv = styled(FullSize)`
  ${() =>
    mobileFirstMedia('sm')(`
     flex: 1 0 var(--4of6);
  `)}
`;

const MotionStack = styled(motion(Stack))`
  flex: 1 0 var(--3of6);
  ${() =>
    mobileFirstMedia('sm')`
      flex: 1 0 var(--2of6);
    `}
`;

const StyledAutocomplete = styled(Autocomplete)`
  [data-testid='autocomplete'] {
    gap: 4px;
  }
  [data-testid='open'] > div {
    padding: 4px;
    > div {
      padding: 8px;
    }
  }
`;

const SmallSize = ({ children }: { children: ReactNode }) => (
  <MotionStack
    direction="column"
    spacing="2xs"
    initial={{
      height: 0
    }}
    animate={{
      height: 'auto'
    }}
    exit={{
      height: 0
    }}
  >
    {children}
  </MotionStack>
);

const countries = Object.keys(countriesMap).map((i) => ({
  label: (countriesMap as Record<string, string>)[i],
  value: i
}));

interface BillingInfoProps {
  context?: 'DIALOG' | 'PAGE';
  showAddressFields?: boolean;
  isFirstFilling?: boolean;
}

type Option = { label: string; value: string };

export const BillingInfoForm: React.FC<BillingInfoProps> = ({
  showAddressFields,
  context = 'DIALOG',
  isFirstFilling = false
}) => {
  const vwoVariant = useABTestVariant('AutocompletePatientInfo');
  const isInVariantABTest = vwoVariant === 'variant1';
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
    watch
  } = useFormContext<{
    billing: IBillingData;
    patient: FormPatient;
  }>();
  const { domainCountry } = useCountry();
  const [numberAutocompleteRequest, setNumberAutocompleteRequest] = useState(0);
  const [addressOptions, setAddressOptions] = useState<Option[]>([]);
  const { fetchAutocomplete, fetchAddressValidation } = usePlacesAutocomplete();
  const [addressIsLoading, setAddressLoading] = useState(false);
  const address = watch('billing.address', '');
  const debouncedAddress = useDebounce(address, 500);
  const { t } = useTranslation();
  const [tooltip, setTooltip] = useState(false);
  const { sendGenericError } = useErrorHandler();
  const [selectOptions, setSelectOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const {
    languages: { loading: languagesLoading }
  } = usePatientInvoiceLanguages({
    onCompleted: (data) => {
      const languagesData = data?.getSupportedPatientInvoiceLanguages;
      if (Array.isArray(languagesData)) {
        setSelectOptions(
          languagesData.map((value) => ({
            value,
            label: t(
              `personalInfo.billingInfo.languages.${value.toUpperCase()}`
            )
          }))
        );
      }
    },
    onError: () => sendGenericError()
  });
  const validateEmptyString = (value: string | undefined | null) => {
    if (value?.trim().length === 0) {
      return t('personalInfo.fieldError');
    }
    return undefined;
  };

  const requiredErrorMessage = t('personalInfo.error');
  const billingCountryField = 'billing.countryIsoCode';
  const billingLanguageField = 'billing.invoiceLanguage';
  const showAutocomplete = context === 'DIALOG' && isFirstFilling;

  const setFieldValue = (
    fieldName: `billing.${keyof IBillingData}`,
    fieldValue: string
  ) => {
    setValue(fieldName, fieldValue, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  useEffect(() => {
    if (showAutocomplete) {
      const search = async () => {
        let results: Option[] = [];
        try {
          setAddressLoading(true);
          if (debouncedAddress) {
            setNumberAutocompleteRequest((r) => r + 1);
            const response = await fetchAutocomplete(debouncedAddress);
            if (response && Array.isArray(response.suggestions)) {
              results = response.suggestions.map(
                ({
                  placePrediction: {
                    text: { text },
                    placeId
                  }
                }) => ({ label: text, value: placeId })
              );
            }
          }
        } finally {
          setAddressOptions(results);
          setAddressLoading(false);
        }
      };

      search();
    }
  }, [debouncedAddress, showAutocomplete]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (isInVariantABTest) {
        if (
          value?.billing?.name &&
          value?.billing?.surname &&
          value?.billing?.countryIsoCode?.toLowerCase() === 'it' &&
          name !== 'billing.cf'
        ) {
          const validateCF = getCFValidator('IT' as Countries & OtherCountries);
          const { isValid } = validateCF(value?.billing?.cf ?? '');
          if (!isValid) {
            setValue(
              'billing.cf',
              generateFirstSixFiscalCode(
                value.billing.name,
                value.billing.surname
              )
            );
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isInVariantABTest]);

  const handleSelect = async ({ value }: Option) => {
    try {
      const response = await fetchAddressValidation(value);
      if (response.postal_code)
        setFieldValue('billing.cap', response.postal_code);
      if (response.administrative_area_level_3)
        setFieldValue('billing.city', response.administrative_area_level_3);
      if (!response.administrative_area_level_3 && response.locality)
        setFieldValue('billing.city', response.locality);
      if (response.administrative_area_level_2)
        setFieldValue('billing.province', response.administrative_area_level_2);
      if (response.country)
        setFieldValue('billing.countryIsoCode', response.country);
      if (response.route) {
        const searchedAddress = `${response.route}${
          response.street_number ? `, ${response.street_number}` : ''
        }`;
        setFieldValue('billing.address', searchedAddress);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <FormContainer context={context}>
      <HalfSize>
        <Label>{t('personalInfo.mainInfo.name')}</Label>
        <TextInput
          autoComplete="first-name"
          data-testid="name-billing"
          {...register('billing.name', {
            required: `${requiredErrorMessage}`,
            validate: validateEmptyString
          })}
          errorMessage={errors?.billing?.name?.message}
          placeholder={t('personalInfo.placeholders.name')}
        />
      </HalfSize>
      <HalfSize>
        <Label>{t('personalInfo.mainInfo.surname')}</Label>
        <TextInput
          autoComplete="surname"
          data-testid="surname-billing"
          {...register('billing.surname', {
            required: `${requiredErrorMessage}`,

            validate: validateEmptyString
          })}
          errorMessage={errors?.billing?.surname?.message}
          placeholder={t('personalInfo.placeholders.lastname')}
        />
      </HalfSize>
      <AddressDiv>
        <Label>{t('personalInfo.billingInfo.address')}</Label>
        {showAutocomplete ? (
          <StyledAutocomplete
            options={addressOptions}
            loading={addressIsLoading}
            onSelectedOption={handleSelect}
            component={
              <TextInput
                autoComplete="street-address"
                {...register('billing.address', {
                  required: `${requiredErrorMessage}`,
                  validate: validateEmptyString
                })}
                value={address ?? ''}
                onChange={(event) =>
                  setFieldValue('billing.address', event.target.value)
                }
                errorMessage={errors?.billing?.address?.message}
                data-testid="appointment-billing-address"
                placeholder={t('personalInfo.placeholders.address')}
              />
            }
            onBlur={() => {
              if (numberAutocompleteRequest) {
                pendoTrack('autocomplete', {
                  numberRequest: numberAutocompleteRequest
                });
                setNumberAutocompleteRequest(0);
              }
            }}
          />
        ) : (
          <TextInput
            {...register('billing.address', {
              required: `${requiredErrorMessage}`,
              validate: validateEmptyString
            })}
            autoComplete="street-address"
            errorMessage={errors?.billing?.address?.message}
            data-testid="appointment-billing-address"
            placeholder={t('personalInfo.placeholders.address')}
          />
        )}
      </AddressDiv>
      <AnimatePresence>
        {showAddressFields && (
          <>
            <SmallSize>
              <Label>{t('personalInfo.billingInfo.cap')}</Label>
              <TextInput
                data-testid="cap-billing"
                autoComplete="postal-code"
                {...register('billing.cap', {
                  required: `${requiredErrorMessage}`,
                  validate: validateEmptyString,
                  pattern: {
                    value: /^[A-Za-z0-9][A-Za-z0-9\- ]{0,10}[A-Za-z0-9]$/,
                    message: t('personalInfo.billingInfo.fiscalCodeValid')
                  }
                })}
                errorMessage={errors?.billing?.cap?.message}
                placeholder={t('personalInfo.placeholders.cap')}
              />
            </SmallSize>
            <SmallSize>
              <Label>{t('personalInfo.billingInfo.city')}</Label>
              <TextInput
                autoComplete="address-level2"
                data-testid="city-billing"
                {...register('billing.city', {
                  required: `${requiredErrorMessage}`,
                  validate: validateEmptyString
                })}
                errorMessage={errors?.billing?.city?.message}
                placeholder={t('personalInfo.placeholders.city')}
              />
            </SmallSize>
            <SmallSize>
              <Label>{t('personalInfo.billingInfo.region')}</Label>
              <TextInput
                autoComplete="address-level1"
                data-testid="region-billing"
                {...register('billing.province', {
                  required: `${requiredErrorMessage}`,
                  validate: validateEmptyString
                })}
                errorMessage={errors?.billing?.province?.message}
                placeholder={t('personalInfo.placeholders.region')}
              />
            </SmallSize>
            <SmallSize>
              <Label>{t('personalInfo.billingInfo.state')}</Label>
              <Controller
                name={billingCountryField}
                control={control}
                rules={{
                  validate: validateEmptyString,
                  required: `${requiredErrorMessage}`
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    data-testid="state-billing"
                    onBlur={onBlur}
                    value={countries.find((c) => c.value === value)}
                    isSearchable
                    options={countries}
                    onChange={(val) => onChange((val as Option)?.value)}
                    errorMessage={errors?.billing?.countryIsoCode?.message}
                  />
                )}
              />
            </SmallSize>
          </>
        )}
      </AnimatePresence>
      <FullSize>
        <Label>{t('personalInfo.billingInfo.fiscalCode')}</Label>
        <TextInput
          data-testid="cf-billing"
          {...register('billing.cf', {
            required: `${requiredErrorMessage}`,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const element = e.currentTarget;
              const caret = element.selectionStart;
              setValue('billing.cf', normalizeFiscalCode(element.value));
              setTimeout(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
              });
            },
            validate: (value: string) => {
              const billingCountryValue = getValues(billingCountryField);
              if (
                billingCountryValue?.toUpperCase() ===
                domainCountry.toUpperCase()
              ) {
                const validateCF = getCFValidator(
                  billingCountryValue as Countries & OtherCountries
                );
                const { isValid } = validateCF(value);
                if (!isValid) {
                  return `${t('legal:informedConsent.infoMessages.taxCode')}`;
                }
              }
              return undefined;
            }
          })}
          errorMessage={errors?.billing?.cf?.message}
          placeholder={t('personalInfo.placeholders.fiscalCode')}
        />
      </FullSize>
      <FullSize>
        <Label data-testid="selectTitle-invoice-language">
          {t('personalInfo.billingInfo.invoiceLanguage')}
        </Label>
        <Controller
          name={billingLanguageField}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              onBlur={onBlur}
              isDisabled={languagesLoading}
              value={selectOptions.find((c) => c.value === value)}
              options={selectOptions}
              onChange={(val) => onChange((val as Option)?.value)}
              data-testid="billing-language-select"
            />
          )}
        />
      </FullSize>
      {domainCountry === 'it' && (
        <FullSize>
          <Stack spacing="2xs">
            <Label>{t('personalInfo.billingInfo.doYouAgree')}</Label>
            <CustomTooltip
              anchor="left"
              label={t('personalInfo.billingInfo.tooltip')}
              open={tooltip}
            >
              <Stack
                onClick={() => setTooltip((open) => !open)}
                align="center"
                style={{ cursor: 'pointer' }}
              >
                <QuestionMark color="grey.900" size="sm" />
              </Stack>
            </CustomTooltip>
          </Stack>
          <Stack align="start" my="xs">
            <Checkbox
              {...register('patient.consentTS')}
              size={26}
              id="consentTS"
              data-testid="fiscal-detraction"
            />
            <Text
              variant="sm"
              color="grey.700"
              style={{ lineHeight: '18.2px', letterSpacing: 0.13 }}
            >
              {t('personalInfo.billingInfo.agree')}
            </Text>
          </Stack>
        </FullSize>
      )}
    </FormContainer>
  );
};
