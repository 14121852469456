import { useColor } from '@unobravo/zenit-core';
import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../theme';
import { Logo } from '../Logo';

export const HEADER_HEIGHT_DESKTOP = 60;
export const HEADER_HEIGHT_MOBILE = 52;

const Wrapper = styled.header<{ bgColor: string }>`
  height: ${HEADER_HEIGHT_DESKTOP}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background: ${({ bgColor }) => bgColor};
  ${mediaQueries('md')(`
    height: ${HEADER_HEIGHT_MOBILE}px;
  `)}

  ${mediaQueries('xs')(`
    img {
          width: 120px;
          height: 24px;
        }
  `)};
`;

export const Header: React.FC<{
  bgTransparent?: boolean;
}> = React.memo(({ bgTransparent }) => {
  const white = useColor('white');
  const bgColor = bgTransparent ? 'translarent' : white;
  return (
    <Wrapper bgColor={bgColor} data-testid="header">
      <Logo width={128} />
    </Wrapper>
  );
});

Header.displayName = 'Header';
