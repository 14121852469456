import * as Sentry from '@sentry/react';
import Doubtful from '@unobravo-monorepo/common/assets/DoubtLaptop.png';
import { IErrorPageProps } from '@unobravo-monorepo/common/types/IErrorPageProps';
import { Country } from '@unobravo/core';
import { useCountry } from '@unobravo/translations';
import { CSSProperties, ReactNode } from 'react';

const styles: Record<string, CSSProperties> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: '#F4F0EE'
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    flexGrow: 1,
    padding: '20px 0'
  },
  img: {
    height: '400px',
    width: 400
  },
  imgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 10%',
    width: '100%',
    order: 2,
    textAlign: 'center',
    alignItems: 'center'
  },
  textContent: {
    marginBottom: '16px'
  },
  heading: {
    fontSize: '2rem',
    marginBottom: '1rem'
  },
  bodyBold: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  bodyRegular: {
    fontSize: '1.2rem'
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  logo: {
    width: '120px',
    height: '24px'
  }
};

const ErrorPage = () => {
  const { domainCountry } = useCountry();

  const defaultErrorPageProps: IErrorPageProps = {
    wentWrong: 'Oops!',
    description: 'Something went wrong?',
    pageMalfunction: "Don't worry, it's just the page malfunctioning!"
  };

  const errorPageTexts: Record<Country, IErrorPageProps> = {
    it: {
      wentWrong: 'Opsss!',
      description: 'Qualcosa è andato storto?',
      pageMalfunction:
        "Non farti prendere dall'ansia, è la pagina che non funziona!"
    },
    es: {
      wentWrong: 'Ups',
      description: '¿Algo salió mal?',
      pageMalfunction: '¡No te preocupes, es la página la que no funciona!'
    },
    fr: {
      wentWrong: 'Oups !',
      description: "Quelque chose s'est mal passé ?",
      pageMalfunction:
        "Ne vous inquiétez pas, c'est la page qui ne fonctionne pas !"
    }
  };

  const errorPageProps = errorPageTexts[domainCountry] || defaultErrorPageProps;

  return (
    <div style={styles['wrapper']}>
      <header style={styles['header']}>
        <img src="/assets/logo/logo.svg" alt="ub-logo" style={styles['logo']} />
      </header>
      <main style={styles['content']}>
        <div style={styles['contentWrapper']}>
          <div style={styles['imgWrapper']}>
            <img
              src={Doubtful}
              style={styles['img']}
              alt={errorPageProps.wentWrong}
            />
          </div>
          <div style={styles['textContent']}>
            <h1 style={styles['heading']}>{errorPageProps.wentWrong}</h1>
            <p style={styles['bodyBold']}>{errorPageProps.description}</p>
            <p style={styles['bodyRegular']}>
              {errorPageProps.pageMalfunction}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

export function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTags({
          errorType: 'render',
          triggeredFrom: 'init'
        });
      }}
      fallback={<ErrorPage />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
