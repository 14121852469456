import { useMutation, useQuery } from '@apollo/client';
import {
  GET_PATIENT_SURVEYS_INFO,
  SAVE_PATIENT_SURVEY_RESPONSE
} from '@unobravo/patient';
import { useFirebaseUser } from '@unobravo/core';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../patientData/hooks/usePatient';
import { surveysForSession } from '../utils/therapyFeedbackUtils';
import { FeedbackData, SurveyInstance } from '../types/therapyFeedback.types';

interface InAppSurvey {
  inAppSurveyId?: string;
}

export const useTherapyFeedback = () => {
  const { currentUser } = useFirebaseUser();
  const { id, doctor } = usePatient();
  const { sendGenericError } = useErrorHandler();
  const { data, loading } = useQuery(GET_PATIENT_SURVEYS_INFO, {
    variables: { id: currentUser.firebaseId },
    skip: !currentUser.firebaseId
  });

  const session = data?.getPatientFromFirebaseId;

  const [savePatientSurvey, { loading: saveSurveyLoading }] = useMutation(
    SAVE_PATIENT_SURVEY_RESPONSE,
    {
      refetchQueries: [GET_PATIENT_SURVEYS_INFO]
    }
  );

  const saveSurvey = async (
    surveyData: FeedbackData,
    inAppSurveyId: SurveyInstance
  ) => {
    try {
      if (doctor?.id) {
        await savePatientSurvey({
          variables: {
            patientId: id,
            doctorId: doctor.id,
            inAppSurveyId,
            result: surveyData
          }
        });
      }
    } catch (e) {
      sendGenericError();
    }
  };

  const numberOfSessions = session?.numberOfSessions;
  const inAppSurveys = session?.inAppSurveys;

  const surveyToShow = surveysForSession[numberOfSessions]
    ? inAppSurveys.some((e: InAppSurvey) => {
        return e.inAppSurveyId === surveysForSession[numberOfSessions].id;
      })
      ? undefined
      : surveysForSession[numberOfSessions].id
    : undefined;

  return {
    numberOfSessions,
    surveyToShow,
    saveSurvey,
    loading,
    saveSurveyLoading
  };
};
