import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const DoubleCheck: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.white
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 7L5 17L1 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 7L13 17L11 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
