import { useDispatch, useSelector } from 'react-redux';
import {
  loadCards,
  updateCards,
  usePatientCardsUtils
} from '@unobravo/patient';
import { useEffect } from 'react';
import { patientSelector } from '../store/selectors';

export const usePatientCards = () => {
  const dispatch = useDispatch();
  const patient = useSelector(patientSelector);
  const { cards, sepaDebits, cardsLoading, refreshPatientCards } =
    usePatientCardsUtils();

  useEffect(() => {
    if (patient.cardsState === 'idle') {
      refreshPatientCards();
      dispatch(loadCards());
    }
  }, [patient, refreshPatientCards, dispatch]);

  useEffect(() => {
    if (cards || sepaDebits) {
      dispatch(
        updateCards({
          cards: cards,
          sepaDebits: sepaDebits
        })
      );
    }
  }, [dispatch, cards, sepaDebits]);

  const removeCard = (paymentMethodId: string) => {
    if (!patient.cards || !patient.sepaDebits) {
      return;
    }
    const filteredCards = patient.cards.filter(
      (c) => c.cardId !== paymentMethodId
    );
    const filteredSepaDebits = patient.sepaDebits.filter(
      (c) => c.sepaId !== paymentMethodId
    );
    dispatch(
      updateCards({ cards: filteredCards, sepaDebits: filteredSepaDebits })
    );
  };

  return {
    refreshPatientCards,
    loading: cardsLoading,
    cards: patient.cards,
    sepaDebits: patient.sepaDebits,
    cardsLoaded: patient.loaded,
    removeCard
  };
};
