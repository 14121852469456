import { ErrorPageContext } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import {
  IPatientState,
  PatientStatus,
  UpdatePatientInput,
  setPatientData,
  usePatientUtils
} from '@unobravo/patient';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useToast } from '../../toaster/hooks/useToast';
import { patientSelector } from '../store/selectors';

export const usePatient = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setGlobalError } = useContext(ErrorPageContext);
  const { sendErrorMessage, sendGenericError } = useErrorHandler();
  const { sendToast } = useToast();
  const patient = useSelector(patientSelector);
  const {
    patientData,
    error,
    updatePatient: updatePatientUtil,
    requestDeletePatient,
    updatePatientEmail,
    changePatientEmail,
    refetch: refetchFunction
  } = usePatientUtils();

  const updatePatient = async (data: Partial<UpdatePatientInput>) => {
    if (await updatePatientUtil(data)) {
      dispatch(setPatientData({ ...patient, ...data }));
      sendToast({
        variant: 'success',
        title: t('modifyToast.title'),
        description: t('modifyToast.description'),
        'data-testid': 'patient-message-toast'
      });
      return true;
    }
    sendGenericError();
    return false;
  };

  const updateEmail = async (email: string) => {
    const result = await updatePatientEmail(email);
    if (result === 'SUCCESS') {
      dispatch(setPatientData({ ...patient, email }));
      return true;
    }
    if (result === 'MAIL_EXISTS') {
      sendErrorMessage(t('verifyEmailScreen.mailExists'));
    }
    if (result === 'ERROR') {
      sendGenericError();
    }
    return false;
  };

  useEffect(() => {
    if (patientData && +patientData.id !== patient.id) {
      dispatch(
        setPatientData({
          ...patientData
        })
      );
    }
  }, [dispatch, patient.id, patientData]);

  const setStatus = (status: PatientStatus) => {
    dispatch(setPatientData({ ...patient, status }));
  };

  useEffect(() => {
    if (error) {
      setGlobalError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const updatePatientData = async (data: Partial<IPatientState>) => {
    dispatch(setPatientData({ ...patient, ...data }));
  };

  const refetch = async () => {
    const result = await refetchFunction();
    if (result?.data?.getPatientFromFirebaseId) {
      dispatch(
        setPatientData({
          ...patient,
          ...result?.data?.getPatientFromFirebaseId
        })
      );
      return result?.data?.getPatientFromFirebaseId;
    }

    return undefined;
  };

  const hasDoctorMatch = patient.doctor?.id !== undefined;

  return {
    ...(patient as Omit<IPatientState, 'sepaDebit' | 'cards'>),
    updatePatient,
    requestDeletePatient,
    updateEmail,
    setStatus,
    updatePatientData,
    changePatientEmail,
    refetch,
    hasDoctorMatch
  };
};
