import { DateTime } from 'luxon';
import { IAppointment } from '@unobravo/core';
import { useQuery } from '@apollo/client';
import { GetPatientAppointmentsDocument } from '@unobravo/patient';
import { usePatient } from '../../patientData/hooks/usePatient';

const isDateWithinLast24Hours = (isoDateString: string) => {
  const date = DateTime.fromISO(isoDateString);
  const now = DateTime.now();
  const diffInHours = now.diff(date, 'hours').hours;

  return diffInHours <= 24;
};

export const useHasAppointmentsPaidWithin24Hours = () => {
  const { id } = usePatient();
  const { data, loading } = useQuery(GetPatientAppointmentsDocument, {
    variables: { id: id!, last: 5, includeCanceled: false },
    skip: !id,
    fetchPolicy: 'network-only'
  });
  const nextSessions = data?.getPatient?.nextSessions as [IAppointment];

  return {
    hasAppointment: nextSessions?.some(
      ({ paid, when }) => isDateWithinLast24Hours(when) && paid
    ),
    loading
  };
};
