import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetMemberGetMemberReferralCodeQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;


export type GetMemberGetMemberReferralCodeQuery = { __typename?: 'Query', getMemberGetMemberReferralCode?: { __typename?: 'GetMemberGetMemberCodeResponse', isEligible: boolean, referralCode?: { __typename?: 'ReferralCode', code: string, startDate?: any | null, expirationDate?: any | null, hasBeenRedeemed: boolean, redemptionsCount?: number | null } | null, rewardCode?: { __typename?: 'RewardCode', code: string, startDate?: any | null, expirationDate?: any | null, redeemedAt?: any | null } | null, referralCampaign?: { __typename?: 'ReferralCampaign', id?: string | null, redemptionsLimit?: number | null } | null } | null };


export const GetMemberGetMemberReferralCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMemberGetMemberReferralCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getMemberGetMemberReferralCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralCode"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"hasBeenRedeemed"}},{"kind":"Field","name":{"kind":"Name","value":"redemptionsCount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"rewardCode"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"redeemedAt"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isEligible"}},{"kind":"Field","name":{"kind":"Name","value":"referralCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"redemptionsLimit"}}]}}]}}]}}]} as unknown as DocumentNode<GetMemberGetMemberReferralCodeQuery, GetMemberGetMemberReferralCodeQueryVariables>;