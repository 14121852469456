import { ArrowRight, Info } from '@unobravo/zenit-icons';
import {
  Box,
  Link,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFreeSessionHomeEnable } from '../../home/hooks/useIsFreeSessionHomeEnable';
import {
  layoutSelector,
  setIsOnBoardingOpen,
  toggleLeftMenu
} from '../../layout/layout.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { RiveBadge } from './RiveBadge';

export const BadgeDrawer = () => {
  const { isLeftMenuOpen } = useSelector(layoutSelector);
  const [endOpeningMenu, setEndOpeningMenu] = useState(isLeftMenuOpen);
  const [isOver, setIsOver] = useState(false);
  const { isOnboardingOpen } = useSelector(layoutSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpointValue();
  const { status: patientStatus } = usePatient();
  const { value: isFreeSessionEnable } = useIsFreeSessionHomeEnable();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (!isLeftMenuOpen) {
      setEndOpeningMenu(false);
    } else {
      timer = setTimeout(() => setEndOpeningMenu(true), 300);
      // Clear the timeout on cleanup
    }
    return () => clearTimeout(timer);
  }, [isLeftMenuOpen]);

  const onBadgeClick = () => {
    isMobile && dispatch(toggleLeftMenu());
    dispatch(setIsOnBoardingOpen(true));
  };

  if (
    !['NEW', 'REPLIER', 'NOT_CONVERTED'].includes(patientStatus!) ||
    isFreeSessionEnable
  ) {
    return <></>;
  }

  return (
    <Stack
      style={{ zIndex: isOnboardingOpen ? 1 : 50 }}
      w="100%"
      justify="start"
      position="relative"
      rounded="md"
      mt="md"
    >
      <Box
        w="100%"
        onClick={onBadgeClick}
        style={{ cursor: 'pointer' }}
        data-testid="onboarding-badge-click"
      >
        <RiveBadge
          w="100%"
          {...(!(isLeftMenuOpen && endOpeningMenu)
            ? { h: '50px', borderRadius: '25px' }
            : undefined)}
        />
        <Stack direction="column" position="absolute" top={0} left={0}>
          <AnimatePresence>
            {isLeftMenuOpen && endOpeningMenu && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Box p="md">
                  <Text variant="md" color="candy.500" fontWeight="semibold">
                    {t('badgeDrawer.whoItWorks')}
                  </Text>
                  <Stack
                    onMouseEnter={() => setIsOver(true)}
                    onMouseLeave={() => setIsOver(false)}
                    pt="2xs"
                    align="center"
                  >
                    <Link to={onBadgeClick} color="grey.700" underline={isOver}>
                      <Text variant="sm" fontWeight="semibold">
                        {t('badgeDrawer.discoverMore')}
                      </Text>
                    </Link>
                    <ArrowRight color="grey.700" size="sm" />
                  </Stack>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!isLeftMenuOpen && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Box p="sm">
                  <Info color="candy.500" />
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
      </Box>
    </Stack>
  );
};
