// eslint-disable-next-line no-restricted-imports
import { LDProvider, LDContext } from 'launchdarkly-react-client-sdk';
import { ReactNode } from 'react';
import { useFirebaseUser } from '../../hooks';

const { NX_APP_LD_ID } = process.env;

export const LaunchDarklyProvider: React.FC<{
  flags: object;
  children: ReactNode;
  customAttributes?: Record<
    Exclude<string, 'key' | 'firebaseId' | 'role' | 'isTlSupervisor'>,
    unknown
  >;
}> = ({ flags, children, customAttributes }) => {
  const { currentUser } = useFirebaseUser();
  const {
    unbvId,
    isTlSupervisor,
    firebaseId = '',
    role = '',
    platformCountry = ''
  } = currentUser;

  const context: LDContext = {
    kind: 'user',
    key: unbvId
      ? `${
          role === 'doctor' || role === 'onboarding_doctor' ? 'TP-' : ''
        }${unbvId}`
      : 'anonymous',
    firebaseId,
    role,
    isTlSupervisor,
    platformCountry,
    ...customAttributes,
    ...(customAttributes
      ? { _meta: { privateAttributes: Object.keys(customAttributes) } }
      : {})
  };

  return (
    <LDProvider
      clientSideID={NX_APP_LD_ID!}
      context={context}
      deferInitialization
      flags={flags}
    >
      {children}
    </LDProvider>
  );
};
