import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import {
  Alert,
  Button,
  Heading,
  RBox,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTherapistInfo } from '../hook/useTherapistInfo';
import { useTherapistSpecialization } from '../hook/useTherapistSpecialization';
import { ConfirmSelectionModal } from './confirmSelectionModal';
import { TpInfoBox } from './tpInfoBox';

interface TherapistInfoProps {
  doctorOption: PreMatchOptionFragment;
  onSelectedOption: () => void;
}

export const TherapistInfo = ({
  doctorOption,
  onSelectedOption
}: TherapistInfoProps) => {
  const { isMobile } = useBreakpointValue();

  const [variant] = useTherapistInfo(doctorOption.doctorPresentation, 1);

  const [openConfirmSelection, setOpenConfirmSelection] = useState(false);

  const { name, surname } = doctorOption?.doctor || {};

  const { t } = useTranslation();

  const { therapeuticOrientation: therapeuticOrientationLabel } =
    useTherapistSpecialization(doctorOption);

  return (
    <>
      <RStack
        bgColor="white"
        border="sm"
        borderColor="cappuccino.200"
        rounded="sm"
        mt="md"
        direction="column"
        px="xl"
        pb="xl"
        style={{
          paddingTop: isMobile ? '16px' : '20px'
        }}
        minW={{ base: '100%', md: undefined }}
      >
        <RStack
          direction="column"
          spacing="xs"
          w={{ base: '280px', md: '352px' }}
          mb="sm"
        >
          <Heading
            variant={isMobile ? 'md' : 'lg'}
            fontWeight="bold"
            color="grey.800"
            textAlign="center"
          >
            {name} {surname}
          </Heading>
          <Text variant="md" color="grey.600" textAlign="center">
            {therapeuticOrientationLabel}
          </Text>
        </RStack>

        <TpInfoBox variant={variant} doctorOption={doctorOption} />

        <Stack direction="row" style={{ marginTop: '20px' }} spacing="md">
          {doctorOption?.isAvailable ? (
            <>
              <Button
                label={t('selectYourTp.showProfile')}
                size="md"
                fullWidth
                variant="outlined"
                data-testid="PreMatch-View-Profile-Choice"
                onClick={onSelectedOption}
              />

              <Button
                label={t('selectYourTp.choose')}
                size="md"
                fullWidth
                data-testid="PreMatch-Select-TP-Choice"
                onClick={() => setOpenConfirmSelection(true)}
              />
            </>
          ) : (
            <RBox w={{ base: '280px', md: '352px' }}>
              <Alert
                type="critical"
                icon={false}
                title={t('selectYourTp.noAvailability.singleTpMessage')}
              />
            </RBox>
          )}
        </Stack>
      </RStack>

      {openConfirmSelection && (
        <ConfirmSelectionModal
          pendoConfirmEventId="PreMatch-Confirm-TP-Choice"
          pendoCancelEventId="PreMatch-Cancel-TP-Choice"
          onClose={() => setOpenConfirmSelection(false)}
          doctorOption={doctorOption}
        />
      )}
    </>
  );
};
