import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Warning: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 12.8958V9.90346M13.6236 5.71017L19.7516 16.4346C20.4636 17.6812 19.5635 19.2325 18.1282 19.2325H5.87234C4.43617 19.2325 3.53607 17.6812 4.24895 16.4346L10.3769 5.71017C11.0946 4.45322 12.906 4.45322 13.6236 5.71017Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.0006" cy="15.4604" r="1" fill={color} />
    </svg>
  );
};
