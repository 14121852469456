import { Carousel } from '@unobravo-monorepo/common/components/Carousel';
import { Box } from '@unobravo/zenit-web';
import { useDoctorInfo } from '../../hooks/useDoctorInfo';

export const DoctorReviews = () => {
  const { reviews } = useDoctorInfo();

  return (
    <>
      {Array.isArray(reviews) && reviews?.length > 0 && (
        <Box w="100%">
          <Carousel items={reviews} />
        </Box>
      )}
    </>
  );
};
