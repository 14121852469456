import { useMutation } from '@apollo/client';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useInterval } from '@unobravo-monorepo/common/hooks/useInterval';
import bgImg from '@unobravo-monorepo/signup/assets/select_tp_ellipse_bg.svg';
import { useFirebaseUser } from '@unobravo/core';
import { useRegistrationStatus } from '@unobravo/signup';
import { useInternationalVariables } from '@unobravo/translations';
import { useTheme } from '@unobravo/zenit-core';
import {
  Alert,
  Box,
  Heading,
  ModalAlert,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SelectDoctorFromPreMatchDocument } from '../../../../../common/src/graphql/mutation.generated';
import { usePatient } from '../../patientData/hooks/usePatient';
import { TherapistBox } from '../components/therapistBox';
import { TherapistDetail } from '../components/therapistDetail';
import { TherapistInfo } from '../components/therapistInfo';
import { useGetCurrentPreMatch } from '../hook/useGetCurrentPreMatch';

const EllipseBackground = styled.img<{ breakpoint: string }>`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  bottom: ${({ breakpoint }) =>
    breakpoint === 'xs' ? '90px' : breakpoint === 'sm' ? '190px' : '280px'};
`;

const CircleImg = styled.img`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  object-fit: fill;
`;

const INTERVAL_TIME = 1 * 60 * 1000; // 1 minute

const useRedirectOnAlreadySelected = () => {
  const { currentUser } = useFirebaseUser();
  const { token } = currentUser || {};

  const { registrationStatus, refetch } = useRegistrationStatus({
    skip: !token,
    fetchPolicy: 'no-cache'
  });

  useInterval(refetch, INTERVAL_TIME);

  const navigate = useNavigate();

  useEffect(() => {
    if (!registrationStatus) return;

    if (registrationStatus.getRegistrationStatus.doctorSelected === false)
      return;

    return navigate('/');
  }, [token, registrationStatus, navigate]);
};

export const SelectTherapist = () => {
  const { currentUser } = useFirebaseUser();
  const { refetch: refetchPatient } = usePatient();

  const { data, loading, refetch } = useGetCurrentPreMatch(
    currentUser?.unbvId || 0
  );

  useInterval(refetch, INTERVAL_TIME);

  useRedirectOnAlreadySelected();

  const { t } = useTranslation();

  const { isMobile, breakpoint } = useBreakpointValue();

  const [selectedTpIndex, setSelectedTpIndex] = useState(0);

  const [openDetail, setOpenDetail] = useState(false);

  const [randomChoice, setRandomChoice] = useState(false);

  const { margin } = useTheme();

  const { supportUrl } = useInternationalVariables();

  const navigate = useNavigate();

  const [selectDoctorFromPreMatch, { loading: randomSelectionLoading }] =
    useMutation(SelectDoctorFromPreMatchDocument);

  const selectRandomTp = async () => {
    await selectDoctorFromPreMatch({
      variables: {
        patientId: currentUser?.unbvId || 0,
        options: {
          doctorId: null,
          randomChoice: true
        }
      }
    });

    await refetchPatient();

    navigate('/');
  };

  if (!data || loading || randomSelectionLoading) return <Spinner />;

  // order tp by position property
  const tpToSelect = [...data.options].sort(
    (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
  );

  const allUnavailable = tpToSelect.every((tp) => !tp?.isAvailable);

  const onlyOneAvailable =
    tpToSelect?.filter((tp) => tp?.isAvailable).length === 1;

  const onlyOneAvailableDoctor = tpToSelect?.find((tp) => tp?.isAvailable);

  const selectedTherapist = tpToSelect?.[selectedTpIndex];

  const handleTherapistSelect = (id: string) => {
    const tpIndex = tpToSelect.findIndex((tp) => tp?.doctorId === id);

    setSelectedTpIndex(tpIndex || 0);
  };

  const handleAllTherapistUnavailableConfirm = async () => {
    await selectDoctorFromPreMatch({
      variables: {
        patientId: currentUser?.unbvId || 0,
        options: {
          doctorId: null,
          randomChoice: false,
          newTp: true
        }
      }
    });

    await refetchPatient();

    return navigate('/');
  };
  const handleOnlyOneTherapistUnavailableConfirm = async () => {
    await selectDoctorFromPreMatch({
      variables: {
        patientId: currentUser?.unbvId || 0,
        options: {
          doctorId: Number(onlyOneAvailableDoctor?.doctorId || 0),
          randomChoice: false,
          newTp: true
        }
      }
    });

    await refetchPatient();

    return navigate('/');
  };

  return (
    <>
      <EllipseBackground src={bgImg} breakpoint={breakpoint} />

      <Stack
        overflow="scroll"
        w="100%"
        h="100dvh"
        position="fixed"
        style={{
          zIndex: 2
        }}
      >
        <RStack
          style={{
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
          direction="column"
          w="min-content"
          minW={{ base: undefined, md: 440 }}
        >
          {/* Heading */}
          <RStack
            direction="column"
            style={{
              marginTop: isMobile ? margin.md : '72px'
            }}
          >
            <Heading
              variant={isMobile ? 'sm' : 'lg'}
              fontWeight="bold"
              color="black"
              textAlign="center"
            >
              {t('selectYourTp.header')}
            </Heading>

            <RStack
              direction="row"
              spacing="md"
              mt="xl"
              justify={{ base: 'center', md: undefined }}
            >
              {tpToSelect.map((therapist, index) => (
                <TherapistBox
                  key={therapist?.doctorId}
                  doctorOption={therapist!}
                  tpDataTestId={`PreMatch-View-TP${index + 1}-Choice`}
                  onClick={() =>
                    handleTherapistSelect(therapist?.doctorId || '')
                  }
                  isSelected={selectedTpIndex === index}
                />
              ))}
            </RStack>
          </RStack>

          {/* TP Detail */}
          {selectedTherapist && (
            <TherapistInfo
              onSelectedOption={() => setOpenDetail(true)}
              doctorOption={selectedTherapist}
            />
          )}

          {/* Footer */}
          <Stack direction="column" spacing="2xl" w="100%" mt="2xl">
            <Text
              color="candy.500"
              style={{ cursor: 'pointer' }}
              variant="sm"
              fontWeight="semibold"
              textAlign="center"
              data-testid="PreMatch-Select-DMMT-Choice"
              onClick={() => setRandomChoice(true)}
            >
              {t('selectYourTp.randomChoice.cta')}
            </Text>
            <Box mb="lg">
              <Alert type="informative" title={t('selectYourTp.agenda')} />
            </Box>
          </Stack>
        </RStack>
      </Stack>

      {openDetail && selectedTherapist && (
        <TherapistDetail
          show={openDetail}
          onClose={() => setOpenDetail(false)}
          doctorOption={selectedTherapist}
          right={{
            disabled: selectedTpIndex === tpToSelect.length - 1,
            onClick: () => setSelectedTpIndex(selectedTpIndex + 1)
          }}
          left={{
            disabled: selectedTpIndex === 0,
            onClick: () => setSelectedTpIndex(selectedTpIndex - 1)
          }}
        />
      )}

      {allUnavailable && (
        <ModalAlert
          open
          confirmButtonLabel={t('selectYourTp.noAvailability.confirmLabel')}
          title={t('selectYourTp.noAvailability.title')}
          onConfirmClick={handleAllTherapistUnavailableConfirm}
        >
          <Text
            variant="md"
            fontWeight="normal"
            color="grey.600"
            style={{ marginTop: '4px' }}
          >
            {t('selectYourTp.noAvailability.allTpMessage')}
          </Text>
        </ModalAlert>
      )}

      {randomChoice && (
        <ModalAlert
          open={randomChoice}
          confirmButtonLabel={t('selectYourTp.randomChoice.confirmLabel')}
          cancelButtonLabel={t('common:cancel')}
          title={t('selectYourTp.randomChoice.title')}
          onConfirmClick={selectRandomTp}
          dataTestIdConfirmButton="PreMatch-Confirm-DMMT-Choice"
          dataTestIdCancelButton="PreMatch-Cancel-DMMT-Choice"
          dataTestIdCloseIcon="PreMatch-Cancel-DMMT-Choice"
          onCancelClick={() => setRandomChoice(false)}
        >
          <Text
            variant="md"
            fontWeight="normal"
            color="grey.600"
            style={{ marginTop: '4px' }}
          >
            {t('selectYourTp.randomChoice.message')}
          </Text>
        </ModalAlert>
      )}

      {onlyOneAvailable && (
        <ModalAlert
          open
          w="450px"
          confirmButtonLabel={t(
            'selectYourTp.onlyOneAvailableOne.confirmButtonLabel',
            { doctorName: onlyOneAvailableDoctor?.doctor.name }
          )}
          onConfirmClick={handleOnlyOneTherapistUnavailableConfirm}
          onCancelClick={() => {
            window.open(supportUrl, '_blank');
          }}
          cancelButtonLabel={t(
            'selectYourTp.onlyOneAvailableOne.cancelButtonLabel'
          )}
        >
          <Stack direction="column" spacing="md">
            <Stack rounded="md" bgColor="ginger.50" p="md">
              <Text variant="md" fontWeight="semibold" color="ginger.800">
                {t('selectYourTp.onlyOneAvailableOne.message', {
                  doctorName: onlyOneAvailableDoctor?.doctor.name
                })}
              </Text>
            </Stack>

            <Stack direction="row" spacing="sm" alignContent="center">
              <CircleImg
                src={onlyOneAvailableDoctor?.doctor.profilePicture || ''}
                alt={`${onlyOneAvailableDoctor?.doctor.name}-${onlyOneAvailableDoctor?.doctor.surname}-profile-picture-only-one`}
              />
              <Stack direction="column" spacing="2xs">
                <Heading color="grey.800" fontWeight="bold" variant="md">
                  {`${onlyOneAvailableDoctor?.doctor.name} ${onlyOneAvailableDoctor?.doctor.surname}`}
                </Heading>
                <Text color="grey.600" variant="md">
                  {onlyOneAvailableDoctor?.doctor.therapeuticOrientation}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </ModalAlert>
      )}
    </>
  );
};
