import React from 'react';
import styled from 'styled-components';
import { theme } from '@unobravo/zenit-core';
import error from '@unobravo-monorepo/common/assets/error.svg';
import expiredMobile from '@unobravo-monorepo/common/assets/expired-mobile-256.svg';
import { Box, Button, Heading, Stack, Text } from '@unobravo/zenit-web';
import { useViewport } from '../../hooks/useViewportHook';
import { mediaQueries } from '../../theme';
import { Logo } from '../Logo';

const StyledLogo = styled(Logo)`
  margin-top: 30px;
  margin-bottom: 50px;
  ${mediaQueries('sm')`
    margin: 30px;
  `}
`;

const TextWrapper = styled(Box)`
  a {
    color: ${theme.colors.candy[500]};
    text-decoration: underline;
    font-weight: 600;
    &:visited {
      color: ${theme.colors.candy[600]};
    }
    &:focus-visible {
      outline-color: ${theme.colors.candy[500]};
    }
  }
`;

export interface IButton {
  label: string;
  variant?: 'filled' | 'outlined' | 'ghost';
  onClick: () => void;
}

interface IFailurePage {
  title: string;
  label: string;
  description: string;
  buttons?: IButton[];
  footer1?: string;
  footer2?: string;
}

export function FailurePage({
  title,
  label,
  description,
  buttons,
  footer1,
  footer2
}: IFailurePage) {
  const { isMobile, isMobileSM } = useViewport();

  return (
    <Stack
      align={isMobileSM ? 'end' : 'center'}
      justify="center"
      h="100vh"
      w="100%"
      style={{
        backgroundImage: isMobileSM ? `url(${error})` : `url(${expiredMobile})`,
        backgroundSize: isMobileSM ? 'contain' : 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Stack
        align="center"
        bgColor="white"
        direction="column"
        maxW={750}
        h={isMobileSM ? '75%' : undefined}
        style={{
          padding: isMobileSM ? '20px' : '60px',
          borderRadius: isMobileSM ? '40px 40px 0 0' : '40px',
          justifyContent: 'space-evenly',
          textAlign: 'center'
        }}
      >
        <StyledLogo width={isMobile ? 200 : 240} />
        <Box>
          <Box mb="xs">
            <Text
              fontWeight="bold"
              variant="sm"
              color="candy.500"
              style={{ letterSpacing: '1.68px', lineHeight: '18.9px' }}
            >
              {label}
            </Text>
          </Box>
          <Heading textAlign="center" fontWeight="semibold" variant="xl">
            {title}
          </Heading>
          <TextWrapper>
            <Box mb="xs" mt="xs">
              <Text variant="lg" fontWeight="normal" color="grey.800">
                {description}
              </Text>
            </Box>
          </TextWrapper>
        </Box>
        {Array.isArray(buttons) && buttons.length > 0 && (
          <Stack
            direction="column"
            justify="center"
            align="center"
            spacing="xs"
            mt="2xl"
          >
            {buttons.map((button: IButton) => (
              <Button
                key={button.label}
                label={button.label}
                variant={button.variant}
                size="lg"
                fullWidth={false}
                onClick={button.onClick}
              />
            ))}
          </Stack>
        )}
        <TextWrapper
          pt={isMobileSM ? 'xs' : '2xl'}
          mt={isMobileSM ? 'xs' : '2xl'}
        >
          {footer1 && (
            <Text variant="lg" color="grey.900">
              {footer1}
            </Text>
          )}
          {footer2 && (
            <Text variant="lg" color="grey.900">
              {footer2}
            </Text>
          )}
        </TextWrapper>
      </Stack>
    </Stack>
  );
}
