import { RStack, Text } from '@unobravo/zenit-web';
import { IntlRender } from '@unobravo/translations';

interface IPrivacySection {
  termsAndConditionsLabel: string;
  privacyPolicyLabel: string;
}

export const PrivacySection: React.FC<IPrivacySection> = ({
  termsAndConditionsLabel,
  privacyPolicyLabel
}) => {
  return (
    <IntlRender include={['it']}>
      <RStack direction="row" spacing="md">
        <Text color="candy.500" variant="sm" fontWeight="medium">
          {termsAndConditionsLabel}
        </Text>
        <Text color="candy.500" variant="sm" fontWeight="medium">
          {privacyPolicyLabel}
        </Text>
      </RStack>
    </IntlRender>
  );
};
