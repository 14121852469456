import { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Heading,
  Modal,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '@unobravo-monorepo/common';
import surveyConsentModalTopImg from '@unobravo-monorepo/patient/assets/img/survey-consent-modal-top.svg';
import { ArrowRight } from '@unobravo/zenit-icons';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { ACCEPT_PATIENT_SURVEY_AGREEMENT } from './graphql/mutation';
import { usePatient } from '../patientData/hooks/usePatient';
import { useTherapySurveyConsent } from './hooks/useTherapySurveyConsent';
import {
  closeTherapySurveyConsentModal,
  therapySurveyConsentSelector
} from './therapySurveyConsent.slice';

const BORDER_RADIUS = 24;

const Wrapper = styled(Stack)`
  ${mediaQueries('sm')(`
    border-radius: 24px 24px 0 0
  `)}
`;

const Image = styled.img`
  width: 100%;
  margin-top: -60px;
`;

const BodyContainer = styled(Stack)`
  margin-top: -24px;
  min-height: 350px;
`;

const TherapySurveyConsentModalInner = () => {
  const { t } = useTranslation();
  const { id } = usePatient();
  const { isMobile } = useBreakpointValue();

  const [consentChecked3a, setConsentChecked3a] = useState(false);
  const [consentChecked3g, setConsentChecked3g] = useState(false);

  const isConsentGiven = useTherapySurveyConsent();
  const [acceptPatientTos] = useMutation(ACCEPT_PATIENT_SURVEY_AGREEMENT);

  const dispatch = useDispatch();
  const { isConsentModalOpen } = useSelector(therapySurveyConsentSelector);

  const closeModal = async () => {
    await acceptPatientTos({
      variables: {
        patientId: id,
        consent3a: consentChecked3a,
        consent3g: consentChecked3g
      }
    });

    dispatch(closeTherapySurveyConsentModal());
  };

  return (
    <Modal open={isConsentModalOpen} w={600} h={760}>
      <Wrapper
        direction="column"
        grow={1}
        align="center"
        h="100%"
        w="100%"
        overflow="hidden"
      >
        <Box
          maxH={`calc(${isMobile ? 90 : 164}px + ${BORDER_RADIUS}px)`}
          w="100%"
        >
          <Image src={surveyConsentModalTopImg} />
        </Box>

        <BodyContainer
          bgColor="white"
          radiusTopRight="xl"
          radiusTopLeft="xl"
          direction="column"
          w="100%"
          grow={1}
          align="center"
        >
          <Stack
            grow={1}
            overflow="auto"
            w="100%"
            px="xl"
            py="2xl"
            direction="column"
            spacing="md"
          >
            <Stack direction="column" spacing="xs">
              <Heading
                textAlign="left"
                variant="xl"
                color="berry.600"
                fontWeight="semibold"
              >
                {t(`therapySurveyConsentModal.title`)}
              </Heading>
            </Stack>
            <Box rounded="xl" bgColor="berry.50" px="md" py="xl">
              <Stack direction="column" spacing="xs">
                <Stack direction="column" spacing="2xs">
                  <Text
                    color="grey.800"
                    textAlign="left"
                    variant="lg"
                    fontWeight="semibold"
                  >
                    {t(
                      `therapySurveyConsentModal.surveysExplanation.firstParagraph`
                    )}
                  </Text>

                  <Text color="grey.800" textAlign="left">
                    {t(
                      `therapySurveyConsentModal.surveysExplanation.secondParagraph`
                    )}
                  </Text>
                </Stack>
              </Stack>
            </Box>
            <Stack direction="column" w="100%" pt="xl" spacing="xl">
              {!isConsentGiven && (
                <Stack direction="column" spacing="md" w="100%">
                  <Text color="grey.800" variant="sm" textAlign="left">
                    {t(`therapySurveyConsentModal.footer.giveUsConsent`)}
                  </Text>

                  <Checkbox
                    checked={consentChecked3a}
                    onChange={(e) => setConsentChecked3a(e?.target.checked)}
                    label={t(
                      `therapySurveyConsentModal.footer.checkboxLabel3a`
                    )}
                    labelSize="sm"
                    colorScale="berry"
                    dataTestId="qot-checkbox-consent-3a"
                  />

                  <Checkbox
                    checked={consentChecked3g}
                    onChange={(e) => setConsentChecked3g(e?.target.checked)}
                    label={t(
                      `therapySurveyConsentModal.footer.checkboxLabel3g`
                    )}
                    labelSize="sm"
                    colorScale="berry"
                    dataTestId="qot-checkbox-consent-3g"
                  />
                </Stack>
              )}
            </Stack>
            <Stack w="100%" grow={1} direction="column" justify="end">
              <Button
                onClick={closeModal}
                label={t(
                  `therapySurveyConsentModal.footer.goToSurveyButtonLabel`
                )}
                data-testid="qot-confirm-button-modal"
                fullWidth
                colorVariant="accent_berry"
                iconPosition="right"
                icon={ArrowRight}
              />
            </Stack>
          </Stack>
        </BodyContainer>
      </Wrapper>
    </Modal>
  );
};

export const TherapySurveyConsentModal = () => {
  const isConsentGiven = useTherapySurveyConsent();

  if (isConsentGiven) return null;

  return <TherapySurveyConsentModalInner />;
};
