import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import { Tab } from '@unobravo-monorepo/common/components/Tab';
import { Box, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Toggle } from '../../../shared/components/Toggle';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { useBundle } from '../../bundles/hooks/useBundle';
import { BundleInvoices } from '../components/BundleInvoices';
import { Invoices } from '../components/Invoices';

export const InvoicesPage: React.FC = () => {
  const { loadAppointments } = useAgenda();
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const defaultTab = searchParams.get('defaultTab');
  const [selectedTab, setSelectedTab] = useState(defaultTab || 'invoices');
  const { isBundleOpened } = useBundle();

  const tabs = [
    {
      id: 'invoices',
      label: `${t('invoices.sessionsTab')}`
    },
    {
      id: 'bundles',
      label: `${t('invoices.bundlesTab')}`
    }
  ];

  useEffect(() => {
    loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientLayout>
      <PageContainer>
        {!isMobile && (
          <>
            <PageContainerHeader>
              {isBundleOpened ? (
                <Stack
                  align="center"
                  justify="space-between"
                  w="100%"
                  pl="xs"
                  pr="xs"
                >
                  <Text variant="md" fontWeight="semibold">
                    {t('invoices.pageTitle')}
                  </Text>
                  <Toggle
                    tabs={tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    width="200px"
                  />
                </Stack>
              ) : (
                <Text variant="md" fontWeight="semibold">
                  {t('invoices.pageTitle')}
                </Text>
              )}
            </PageContainerHeader>
          </>
        )}

        <Stack position="relative" style={{ flexGrow: 1 }} direction="column">
          {isMobile && (
            <>
              <Box pb="md">
                <Stack
                  bgColor="cappuccino.50"
                  borderTop="sm"
                  borderBottom="sm"
                  borderColor="cappuccino.200"
                  w="100%"
                  h={50}
                  align="center"
                  justify="center"
                >
                  <Text variant="lg" fontWeight="semibold">
                    {t('invoices.pageTitle')}
                  </Text>
                </Stack>
                {isBundleOpened ? (
                  <Stack pt="md">
                    <Tab
                      tabs={tabs}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                  </Stack>
                ) : (
                  <Invoices />
                )}
              </Box>
            </>
          )}
          {selectedTab === 'invoices' && <Invoices />}
          {selectedTab === 'bundles' && <BundleInvoices />}
        </Stack>
      </PageContainer>
    </PatientLayout>
  );
};
