import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

//TODO: Check with UX
export const Close: React.FC<IIcon> = ({
  width = 44,
  height = 44,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.12999 2L42.3732 41.997M41.6134 2L2.37323 42"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
