import {
  ActionIcon,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { Eye, EyeClosed } from '@unobravo/zenit-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  Auth
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { useForm } from 'react-hook-form';
import { passwordRegex } from '@unobravo-monorepo/common/utils/regexes';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { FlowType, PlatformCountry } from '../../generated/auth.generated';
import { AuthLayout } from '../layout';
import { SendResetPasswordEmailDocument } from '../graphql/mutations.generated';
import { useCountry } from '../../../translations/src';

const DURATION = 0.3;

type Status = 'loading' | 'expired' | 'form' | 'success';

const MotionBox = motion(Box);

function SetPasswordForm({
  actionCode,
  setCurrentStatus,
  auth
}: {
  actionCode: string;
  setCurrentStatus: (status: Status) => void;
  auth: Auth;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch
  } = useForm({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: ''
    }
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const newPasswordValue = watch('newPassword');
  const confirmNewPasswordValue = watch('confirmNewPassword');
  return (
    <form
      onSubmit={handleSubmit(async ({ newPassword }) => {
        try {
          await confirmPasswordReset(auth, actionCode, newPassword);
          navigate('/login');
        } catch (error) {
          setCurrentStatus('expired');
        }
      })}
    >
      <Stack direction="column" spacing="md">
        <Stack direction="column" spacing="2xs">
          <TextInput
            type={!showPassword ? 'password' : 'text'}
            label={t('setPassword.form.newPassword')}
            errorMessage={errors?.newPassword?.message}
            icon={
              <ActionIcon
                variant="ghost"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? <Eye /> : <EyeClosed />}
              </ActionIcon>
            }
            {...register('newPassword', {
              required: t('common:requiredField'),
              validate: (value) => {
                if (!passwordRegex.test(value)) {
                  return t('setPassword.errors.tooWeak');
                }
                return undefined;
              }
            })}
          />
          <Text variant="xs" fontWeight="light" color="grey.700">
            {t('setPassword.form.newPasswordHint')}
          </Text>
        </Stack>
        <TextInput
          type={!showConfirmPassword ? 'password' : 'text'}
          label={t('setPassword.form.confirmNewPassword')}
          errorMessage={errors?.confirmNewPassword?.message}
          icon={
            <ActionIcon
              variant="ghost"
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {!showConfirmPassword ? <Eye /> : <EyeClosed />}
            </ActionIcon>
          }
          {...register('confirmNewPassword', {
            required: `${t('common:requiredField')}`
          })}
        />
        <Button
          type="submit"
          variant="filled"
          colorVariant="primary_gradient"
          label={t('setPassword.form.submit')}
          loading={isSubmitting}
          disabled={
            !newPasswordValue || newPasswordValue !== confirmNewPasswordValue
          }
        />
      </Stack>
    </form>
  );
}

function SendNewEmailForm() {
  const { t } = useTranslation();
  const [urlParams] = useSearchParams();
  const initialEmail = urlParams.get('email');
  const [sendNewMailMutation] = useMutation(SendResetPasswordEmailDocument);
  const { domainCountry } = useCountry();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isSubmitSuccessful },
    setError,
    watch
  } = useForm({
    defaultValues: {
      email: initialEmail
    }
  });

  const email = watch('email');

  const onSubmit = handleSubmit(async (formValues) => {
    if (!formValues.email) {
      setError('email', {
        type: 'manual',
        message: t('common:requiredField')
      });
      return;
    }
    try {
      const result = await sendNewMailMutation({
        variables: {
          email: formValues.email,
          flowType: FlowType.Internal,
          platformCountry: domainCountry.toUpperCase() as PlatformCountry
        }
      });
      if (!result.data?.sendResetPasswordEmail) {
        setError('root', {
          message: t('common:errorPage.retry')
        });
      }
    } catch (error) {
      setError('root', {
        message: t('common:errorPage.retry')
      });
    }
  });

  if (isSubmitSuccessful) {
    return (
      <>
        <Box mb="md">
          <Heading variant="xl">{t('setPassword.title.emailSent')}</Heading>
        </Box>
        <Text variant="xl">{t('setPassword.form.emailSent', { email })}</Text>
      </>
    );
  }
  return (
    <>
      <Box mb="md">
        <Heading variant="xl">{t('setPassword.title.expired')}</Heading>
      </Box>
      <form onSubmit={onSubmit}>
        <Stack direction="column" spacing="md">
          <TextInput
            type="email"
            label={t('setPassword.form.email')}
            errorMessage={errors?.email?.message}
            {...register('email', {
              required: `${t('common:requiredField')}`
            })}
          />
          {!!errors?.root?.message && (
            <Text variant="sm" fontWeight="light" color="ribes.500">
              {errors.root?.message}
            </Text>
          )}

          <Button
            type="submit"
            variant="filled"
            colorVariant="primary_gradient"
            label={t('setPassword.form.sendNewMail')}
            disabled={!isValid}
            loading={isSubmitting}
          />
        </Stack>
      </form>
    </>
  );
}

export function SetPassword() {
  // firebase stuff
  const [urlParams] = useSearchParams();
  const actionCode = urlParams.get('oobCode');
  const auth = getAuth();

  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState<Status>(
    !actionCode ? 'expired' : 'loading'
  );

  useEffect(() => {
    if (!actionCode) {
      return;
    }
    verifyPasswordResetCode(auth, actionCode)
      .then(() => {
        setCurrentStatus('form');
      })
      .catch(() => {
        setCurrentStatus('expired');
      });
  }, [actionCode, auth]);

  return (
    <AuthLayout duration={0.3} animateHeight={!isMobile}>
      <Box position="relative">
        <AnimatePresence initial={false} exitBeforeEnter>
          {currentStatus === 'loading' ? (
            <MotionBox
              key="loading-state"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: DURATION }}
            >
              <Box mb="md">
                <Heading variant="xl">{t('setPassword.title.default')}</Heading>
              </Box>
              <Stack justify="center" align="center">
                <Spinner absolute={false} />
              </Stack>
            </MotionBox>
          ) : currentStatus === 'expired' ? (
            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: DURATION }}
              key="expired-state"
            >
              <SendNewEmailForm />
            </MotionBox>
          ) : currentStatus === 'success' ? (
            <MotionBox
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: DURATION }}
              key="success-state"
            >
              <Box mb="xs">
                <Heading variant="xl" color="candy.500">
                  {t('setPassword.title.success')}
                </Heading>
              </Box>
              <Text variant="xl">{t('setPassword.redirectToLogin')}</Text>
            </MotionBox>
          ) : (
            <MotionBox
              key="set-password-form"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: DURATION }}
            >
              <Box mb="md">
                <Heading variant="xl">{t('setPassword.title.default')}</Heading>
              </Box>
              <SetPasswordForm
                auth={auth}
                actionCode={actionCode || ''}
                setCurrentStatus={setCurrentStatus}
              />
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </AuthLayout>
  );
}
