import { Box, Button, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSession } from '../../shared/hooks/useSession';
import { paySessionDataSelector } from '../newPaySession/paySessionData.slice';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import { DateContainer } from './components/DateContainer';
import { GiftCard } from './components/GiftCard';
import { RecapSkeleton } from './components/RecapSkeleton';

export const RecapVoucher = ({
  pay,
  loading,
  onBack
}: {
  pay: () => Promise<void>;
  loading?: boolean;
  onBack?: () => void;
}) => {
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const sessionData = useSession(sessionId!);
  const { amount: originalAmount } = useStripePaymentIntent(sessionId!);
  const { voucher } = useSelector(paySessionDataSelector);

  if (!sessionData || sessionData.dataLoading) {
    return <RecapSkeleton />;
  }

  return (
    <>
      <Stack direction="column" px="xl" spacing="md" grow>
        <Box bgColor="cappuccino.100" rounded="md" mt="md">
          <DateContainer sessionDate={sessionData.sessionDate} />
        </Box>

        <Text variant="lg" fontWeight="bold" color="grey.800">
          {t('paySession.discountedCode')}
        </Text>
        {voucher && (
          <GiftCard
            appliedVoucher={voucher}
            onVoucherConfirmed={() => {}}
            originalAmount={originalAmount!}
            sessionId={sessionData.uuid}
          />
        )}
      </Stack>
      <RStack
        direction={{ xs: 'column-reverse', md: 'row' }}
        justify="space-between"
        spacing="xs"
        p="xl"
        style={{ justifySelf: 'end' }}
      >
        <Button
          variant="ghost"
          onClick={onBack}
          label={t('paymentMethod.back')}
          size="lg"
          iconPosition="left"
          type="button"
          data-testid="recap-voucher-back-button"
        />
        <Button
          onClick={() => {
            pay();
          }}
          label={t(`paySession.confirmPayment`, {
            cost: voucher?.total
          })}
          loading={loading}
          size="lg"
          data-testid="pay-session-voucher"
        />
      </RStack>
    </>
  );
};
