import { Slider } from '@unobravo-monorepo/common/components/Slider/Slider';
import { PatientOnboardingStatus } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { Box, Stack, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useOnboardingStatus } from '../../hooks/useOnboardingStatus';
import { Section } from '../Section';
import { CarouselCard } from './CarouselCard';

const CHILDREN_WIDTH = 300;

const CarouselWrapper = styled(Stack)`
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
`;

export const InstructionCarousel = () => {
  const { t } = useTranslation();
  const { domainCountry } = useCountry();
  const { status } = useOnboardingStatus();
  const { isMobile } = useBreakpointValue();

  const variant =
    status === PatientOnboardingStatus.FreeSessionCompleted
      ? 'START_THERAPY'
      : 'MEET_TP';

  const steps =
    variant === 'MEET_TP'
      ? [
          {
            id: 'first',
            titleKey: 'home.freeSession.carousel_meetTp.firstTitle',
            coloredTitle: t(
              'home.freeSession.carousel_meetTp.firstColoredTitle'
            ),
            description: t('home.freeSession.carousel_meetTp.firstDescription')
          },
          {
            id: 'second',
            titleKey: 'home.freeSession.carousel_meetTp.secondTitle',
            coloredTitle: t(
              'home.freeSession.carousel_meetTp.secondColoredTitle'
            ),
            description: t('home.freeSession.carousel_meetTp.secondDescription')
          },
          {
            id: 'third',
            titleKey: 'home.freeSession.carousel_meetTp.thirdTitle',
            coloredTitle: t(
              'home.freeSession.carousel_meetTp.thirdColoredTitle'
            ),
            description: t('home.freeSession.carousel_meetTp.thirdDescription')
          },
          {
            id: 'fourth',
            titleKey: 'home.freeSession.carousel_meetTp.fourthTitle',
            coloredTitle: t(
              'home.freeSession.carousel_meetTp.fourthColoredTitle'
            ),
            description: t('home.freeSession.carousel_meetTp.fourthDescription')
          }
        ]
      : [
          {
            id: 'first',
            titleKey: 'home.freeSession.carousel_startTherapy.firstTitle',
            coloredTitle: t(
              'home.freeSession.carousel_startTherapy.firstColoredTitle'
            ),
            description: t(
              'home.freeSession.carousel_startTherapy.firstDescription'
            )
          },
          ...(domainCountry === 'it'
            ? [
                {
                  id: 'second',
                  titleKey:
                    'home.freeSession.carousel_startTherapy.secondTitle',
                  coloredTitle: t(
                    'home.freeSession.carousel_startTherapy.secondColoredTitle'
                  ),
                  description: t(
                    'home.freeSession.carousel_startTherapy.secondDescription'
                  )
                },
                {
                  id: 'third',
                  titleKey: 'home.freeSession.carousel_startTherapy.thirdTitle',
                  coloredTitle: t(
                    'home.freeSession.carousel_startTherapy.thirdColoredTitle'
                  ),
                  description: t(
                    'home.freeSession.carousel_startTherapy.thirdDescription'
                  )
                }
              ]
            : []),
          {
            id: 'fourth',
            titleKey: 'home.freeSession.carousel_startTherapy.fourthTitle',
            coloredTitle: t(
              'home.freeSession.carousel_startTherapy.fourthColoredTitle'
            ),
            description: t(
              'home.freeSession.carousel_startTherapy.fourthDescription'
            )
          }
        ];

  return (
    <Box position="relative" maxW={2 * CHILDREN_WIDTH}>
      <Section title={t('home.freeSession.infoTitle')} gotoChat={false} />
      <CarouselWrapper position="absolute">
        <Slider childrenWidth={CHILDREN_WIDTH} showArrows={!isMobile}>
          {steps.map((one) => (
            <Box key={one.id} h="100%">
              <CarouselCard {...one} variant={variant} />
            </Box>
          ))}
        </Slider>
      </CarouselWrapper>
    </Box>
  );
};
