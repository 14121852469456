import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Doubtful from '@unobravo-monorepo/common/assets/DoubtLaptop.png';
import Vector25 from '@unobravo-monorepo/common/assets/Vector25.svg';
import { useTranslation } from 'react-i18next';
import { mobileFirstMedia, Theme } from '../../theme';
import { IErrorPageProps } from '../../types/IErrorPageProps';
import { Button } from '../Button/index';
import { Header } from '../Header/index';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  Label,
  LabelSize,
  TypographyVariant
} from '../Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Content = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-grow: 1;
  background-color: ${Theme.colors.cappuccino[50]};
  background-size: 50vh auto;
  background-image: url(${Vector25});
  background-repeat: no-repeat;
  background-position: right top;
  ${mobileFirstMedia('md')`
    flex-direction: row;
    background-size: 85vh auto;
  `}
  padding: 20px 0;
`;

const ImgWrapper = styled.div`
  display: inline-flex;
  max-height: 50%;
  width: 80%;
  order: 1;
  align-self: center;
  justify-content: center;
  background: url(${Doubtful});
  height: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  ${mobileFirstMedia('md')`
    width: 40%;
    max-height: 60%;
    order: 2;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
  width: 100%;
  order: 2;
  text-align: center;
  align-items: center;
  ${mobileFirstMedia('md')`
    width: 60%;
    order: 1;
    text-align: left;
    align-items: flex-start;
  `}
`;

const TextContent = styled.div`
  margin-bottom: 20px;
`;
type ReloadProps = { withReload?: boolean; reloadLabel?: string };
export const ErrorPage: React.FC<IErrorPageProps & ReloadProps> = ({
  error404,
  wentWrong,
  description,
  pageMalfunction,
  backHome,
  customRedirect,
  noHeader,
  withReload,
  reloadLabel
}) => {
  const navigate = useNavigate();
  const redirect = () => navigate(customRedirect || '/');

  const reload = () => window.location.reload();
  return (
    <Wrapper>
      {!noHeader && <Header />}
      <Content>
        <ContentWrapper>
          <TextContent>
            {error404 && (
              <Label
                size={LabelSize.Label100}
                color={Theme.colors.candy[500]}
                margin
              >
                {error404}
              </Label>
            )}
            {wentWrong && (
              <Heading size={HeadingSize.Title100} margin>
                {wentWrong}
              </Heading>
            )}
            {description && (
              <Body variant={TypographyVariant.Bold} size={BodySize.Body60}>
                {description}
              </Body>
            )}
            {pageMalfunction && (
              <Body variant={TypographyVariant.Regular} size={BodySize.Body60}>
                {pageMalfunction}
              </Body>
            )}
          </TextContent>
          {backHome && (
            <Button
              data-testid="back-home"
              label={backHome}
              onClick={redirect}
            />
          )}
          {withReload && (
            <Button
              data-testid="reload-button"
              label={reloadLabel}
              onClick={reload}
            />
          )}
        </ContentWrapper>
        <ImgWrapper />
      </Content>
    </Wrapper>
  );
};

export function GenericErrorPage({ noHeader = false }: { noHeader?: boolean }) {
  const { t } = useTranslation();
  return (
    <ErrorPage
      noHeader={noHeader}
      error404={t('errorPage.error404')}
      wentWrong={t('errorPage.wentWrong')}
      pageMalfunction={t('errorPage.pageMalfunction')}
      customRedirect="../"
      backHome={t('errorPage.backHome')}
    />
  );
}
