import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import {
  Box,
  Heading,
  RBox,
  RStack,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { DoctorInfoModal } from '../../shared/components/TherapySetting/Modals/DoctorInfoModal';
import { PatientLayout } from '../../shared/layouts/PatientLayout';
import { useAgenda } from '../agenda/hooks/useAgenda';
import { usePatient } from '../patientData/hooks/usePatient';
import { DoctorInfoCard } from './components/DoctorInfoCard';
import { FreeSessionSteps } from './components/FreeSessionSteps';
import { InstructionCarousel } from './components/InstructionCarousel';

export const FreeSessionHome = () => {
  const { status: patientStatus, name } = usePatient();
  const { t } = useTranslation();
  const { status, loadAppointments } = useAgenda();
  const { isMobile } = useBreakpointValue();

  useEffect(() => {
    loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !!patientStatus &&
    ['SUSPENDED', 'ONGOING', 'COMPLETED', 'TERMINATED'].includes(patientStatus)
  ) {
    return <Navigate to="/" />;
  }

  return (
    <PatientLayout>
      <PageContainer
        style={{
          width: 'unset',
          display: 'inline-block'
        }}
      >
        {['loading', 'idle'].includes(status) ? (
          <Spinner />
        ) : (
          <>
            <Box p="md">
              <Heading color="cappuccino.800">
                {t(
                  isMobile
                    ? 'home.freeSession.heroMobile'
                    : 'home.freeSession.hero',
                  { name }
                )}
              </Heading>
            </Box>
            <RStack
              direction={{ base: 'column', md: 'row' }}
              pb="2xl"
              pt="sm"
              spacing="xl"
              px={{ base: undefined, md: 'md' }}
            >
              <RBox grow px={{ base: 'md', md: undefined }}>
                <FreeSessionSteps />
              </RBox>
              <RBox grow minW={{ base: undefined, md: 500 }}>
                <RBox px={{ base: 'md', md: undefined }}>
                  <DoctorInfoCard />
                </RBox>
                <RBox mt="2xl" pl={{ base: 'md', md: undefined }}>
                  <InstructionCarousel />
                </RBox>
              </RBox>
            </RStack>
            <DoctorInfoModal />
          </>
        )}
      </PageContainer>
    </PatientLayout>
  );
};
