import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface IBonusItem {
  icon: ReactNode;
  label: string;
  value: string;
  isBold?: boolean;
}

const BonusItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const BonusItem = (props: IBonusItem) => {
  const { label, value, icon, isBold = true } = props;
  return (
    <BonusItemWrapper>
      <>
        {icon}
        <div>
          <Body size={BodySize.Body70}>{label}</Body>
          <Body
            size={BodySize.Body70}
            variant={
              isBold ? TypographyVariant.Bold : TypographyVariant.Regular
            }
          >
            {value}
          </Body>
        </div>
      </>
    </BonusItemWrapper>
  );
};
