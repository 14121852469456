import { transformAmount } from '@unobravo-monorepo/common/utils/priceUtils';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import {
  Box,
  Button,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSession } from '../../shared/hooks/useSession';
import { DateContainer } from './components/DateContainer';
import { GiftCard } from './components/GiftCard';
import { InfoHeader } from './components/InfoHeader';
import { RecapSkeleton } from './components/RecapSkeleton';
import { useStripePaymentIntent } from '../payment/hooks/useStripePaymentIntent';
import {
  paySessionDataSelector,
  setVoucher
} from '../newPaySession/paySessionData.slice';
import { usePatient } from '../patientData/hooks/usePatient';
import { layoutSelector } from '../layout/layout.slice';

export const Recap = ({ onNext }: { onNext?: () => void }) => {
  const { t } = useTranslation();
  const { uuid, id } = usePatient();
  const { sessionId } = useParams();
  const sessionData = useSession(sessionId!);
  const dispatch = useDispatch();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { isMobileApp } = useSelector(layoutSelector);
  const { amount: originalAmount } = useStripePaymentIntent(sessionId!);
  const { voucher } = useSelector(paySessionDataSelector);
  const { isMobile } = useBreakpointValue();

  if (!sessionData || sessionData.dataLoading) {
    return <RecapSkeleton />;
  }

  return (
    <>
      <InfoHeader
        showStepper
        title={t('paySession.appointmentRecap.title')}
        active="RECAP"
      />
      <Stack direction="column" px="xl" spacing="md" grow>
        <Box bgColor="cappuccino.100" rounded="md" mt="md">
          <DateContainer sessionDate={sessionData.sessionDate} />
        </Box>

        <Text variant="lg" fontWeight="bold" color="grey.800">
          {t('paySession.discountedCode')}
        </Text>
        <GiftCard
          appliedVoucher={voucher}
          onVoucherConfirmed={(v) => {
            dispatch(
              setVoucher({
                ...v,
                total: transformAmount(v.total)!
              })
            );
            pushAuthenticatedEvent('AppliedVoucher');
          }}
          originalAmount={originalAmount!}
          sessionId={sessionData.uuid}
        />
      </Stack>
      <Stack
        justify="end"
        p="xl"
        direction={isMobile ? 'column' : 'row'}
        style={{ justifySelf: 'end' }}
      >
        <Button
          onClick={() => {
            onNext?.();
            pushAuthenticatedEvent('InitCheckout', {
              application: isMobileApp ? 'APP' : 'WEB',
              user_id: id,
              uuid
            });
          }}
          label={t('buttonNext')}
          size="lg"
          data-testid="recap-next-cta"
        />
      </Stack>
    </>
  );
};
