import { useDispatch } from 'react-redux';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';

export const useLogout = () => {
  const { logout } = useFirebaseUser();
  const dispatch = useDispatch();
  const logoutFunction = async () => {
    await logout();
    setTimeout(() => dispatch({ type: 'LOGOUT' }));
  };
  return logoutFunction;
};
