import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { BundleDocument } from '../../../../generated/patient.generated';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { usePatient } from '../../patientData/hooks/usePatient';
import { BundleInvoicesDocument } from '../graphql/queries.generated';

export const useBundleInvoices = () => {
  const { id, timezone } = usePatient();

  const { data, loading } = useQuery(BundleInvoicesDocument, {
    variables: { patientId: id!, page: 0, pageSize: 10 }
  });

  const groupedByMonth = useMemo(() => {
    const bundles = (data?.bundleInvoices?.edges ?? []) as BundleDocument[];
    const result: { [key: string]: BundleDocument[] } = {};

    bundles.forEach((bundle) => {
      const { month, year } = getSessionDate(bundle?.date, timezone || 'it');
      const monthKey = `${month} ${year}`;

      if (result[monthKey]) {
        result[monthKey].push(bundle);
      } else {
        result[monthKey] = [bundle];
      }
    });

    return result;
  }, [data, timezone]);

  return {
    loading,
    groupedByMonth,
    hasPreviousPage: data?.bundleInvoices?.hasPreviousPage
  };
};
