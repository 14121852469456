import { Theme } from '@unobravo-monorepo/common';
import { Button } from '@unobravo-monorepo/common/components/Button';
import { Warning } from '@unobravo-monorepo/common/components/Icons/Warning';
import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const AlertContainer = styled.div`
  background: ${Theme.colors.ginger[50]};
  border-radius: 12px;
  padding: 16px;
  display: flex;
`;

const NoShrink = styled.div`
  flex-shrink: 0;
  padding-right: 8px;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
`;

export const InformedConsentAlert = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AlertContainer>
      <NoShrink>
        <Warning color={Theme.colors.ginger[600]} />
      </NoShrink>
      <FlexWrap>
        <div>
          <Body
            size={BodySize.Body80}
            variant={TypographyVariant.SemiBold}
            margin="0 0 4px 0"
            color={Theme.colors.ginger[600]}
          >
            {t('legal:informedConsent.alertTitle')}
          </Body>
          <Body
            size={BodySize.Body70}
            margin="0 0 4px 0"
            color={Theme.colors.ginger[600]}
          >
            {t('legal:informedConsent.alertDescription')}
          </Body>
        </div>
        <Button
          variant="secondary"
          label={t('legal:informedConsent.informedConsentButton')}
          colorScale={Theme.colors.ginger}
          highContrast
          size="small"
          onClick={() => navigate('../preferences/informedConsent')}
        />
      </FlexWrap>
    </AlertContainer>
  );
};
