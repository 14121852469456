import React, { ReactNode, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useCountry, useInternationalVariables } from '@unobravo/translations';

export const StripeProvider: React.FC<{
  children?: ReactNode;
  clientSecret?: string;
}> = ({ children, clientSecret }) => {
  const { stripeKey } = useInternationalVariables();
  const { domainCountry } = useCountry();

  const [stripePromise] = useState(() => loadStripe(stripeKey!));

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret, locale: domainCountry }}
    >
      {children}
    </Elements>
  );
};
