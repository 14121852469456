import { IntlRenderDynamic } from '@unobravo/translations';
import { InformedConsentTextES } from './InformedConsentTextES';
import { InformedConsentTextFR } from './InformedConsentTextFR';
import { InformedConsentTextIT } from './InformedConsentTextIT';

export const InformedConsentText = () => {
  const countryComponents = {
    it: <InformedConsentTextIT />,
    es: <InformedConsentTextES />,
    fr: <InformedConsentTextFR />,
    default: <InformedConsentTextIT />
  };
  return <IntlRenderDynamic {...countryComponents} />;
};
