import { useTheme } from '@unobravo/zenit-core';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { LeftIcon } from './LeftIcon';

export const CheckedStep = ({
  title,
  showDashedLine = false
}: {
  title: string;
  showDashedLine: boolean;
}) => {
  const { colors } = useTheme();
  const startGradient = colors.edamame['50'];
  const endGradient = colors.edamame['100'];

  return (
    <Stack direction="row" spacing="md">
      <LeftIcon checked showDashedLine={showDashedLine} />
      <Box
        style={{
          background: `linear-gradient(${startGradient},${endGradient})`
        }}
        rounded="md"
        px="md"
        py="sm"
        grow
      >
        <Text color="black" fontWeight="medium">
          {title}
        </Text>
      </Box>
    </Stack>
  );
};
