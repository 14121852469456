import { Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import React from 'react';
import styled from 'styled-components';

const BoxWrapper = styled.div<{ width?: string }>`
  display: flex;
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: 35px;
  border-radius: 100px;
  background: ${Theme.specialColors.white};
`;

const Wrapper = styled.div<{ isSelected: boolean; num: number }>`
  content: '';
  display: block;
  border-radius: 100px;
  width: ${({ num }) => (100 + 6 * (num - 1)) / num}%;
  height: 100%;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? Theme.colors.candy[500] : 'unset'};
  transition: 0.2s;
  &:not(:last-child) {
    margin-right: -6%;
  }
`;

const BoxText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
interface IToggleProps {
  tabs: IToggle[];
  selectedTab: string;
  setSelectedTab: (id: string) => void;
  width?: string;
}

export interface IToggle {
  id: string;
  label: string;
}
export const Toggle: React.FC<IToggleProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  width
}) => {
  return (
    <BoxWrapper width={width}>
      {tabs.map((tab) => (
        <Wrapper
          num={tabs.length}
          onClick={() => setSelectedTab(tab.id)}
          isSelected={selectedTab === tab.id}
          key={tab.id}
          data-testid={`agenda-toggle-${tab.id}`}
        >
          <BoxText>
            <Body
              size={BodySize.Body60}
              variant={TypographyVariant.SemiBold}
              margin={'0'}
              color={
                selectedTab === tab.id
                  ? `${Theme.specialColors.white}`
                  : `${Theme.colors.candy[500]}`
              }
            >
              {tab.label}
            </Body>
          </BoxText>
        </Wrapper>
      ))}
    </BoxWrapper>
  );
};
