import { useColor, useTheme } from '@unobravo/zenit-core';
import { Button, useBreakpointValue } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobileFirstMedia, Theme } from '../../theme';
import {
  IDatePicker,
  IDefaultDatePickerCell,
  PickerDayState
} from '../../types/IDatePicker';
import { Switcher } from '../Switcher';
import {
  Body,
  BodySize,
  Label,
  LabelSize,
  TypographyVariant
} from '../Typography';

const SELECTED_MARGIN = 3;
const VISUALIZED_MONTHS = 4;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: height 500ms;
  gap: 16px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mobileFirstMedia('md')`
    flex-direction: row;
    gap: 32px;
  `}
`;

const MonthDiv = styled.div`
  display: flex;
  justify-content: center;
  ${mobileFirstMedia('md')`
    justify-content: flex-end;
  `}
`;

const ButtonWrapper = styled.div`
  padding: 0 3px 16px 3px;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  flex-grow: 1;
  ${mobileFirstMedia('md')`
    grid-template-rows: max-content 1fr 1fr 1fr 1fr 1fr 1fr;
  `}
`;

const MonthsWrapper = styled(CalendarGrid)`
  grid-template-rows: 1fr;
  border-bottom: 1px solid ${Theme.colors.cappuccino[100]};
  margin-bottom: 24px;
  position: sticky;
  top: 0;
  background-color: ${Theme.specialColors.white};
  z-index: 3;
  outline: 1px solid ${Theme.specialColors.white};
`;

interface IDayCell {
  state: PickerDayState;
  bgColor: string;
}

const DayCell = styled.div<IDayCell>`
  width: 100%;
  min-height: ${({ state }) =>
    state === 'SELECTED_INTERVAL' ? 40 - SELECTED_MARGIN * 2 : 40}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ state }) => (state === 'SELECTED_INTERVAL' ? 0 : 8)}px;
  box-shadow: ${({ state }) =>
    state === 'SELECTED' ? `0 0 0 2px ${Theme.colors.candy[100]}` : 'none'};
  z-index: ${({ state }) => (state === 'SELECTED' ? 2 : 1)};
  cursor: ${({ state }) => (state === 'DISABLED' ? 'default' : 'pointer')};
  margin: ${({ state }) =>
      state === 'SELECTED_INTERVAL' ? SELECTED_MARGIN : 0}px
    0;
  &:hover {
    ${({ state }) =>
      ['DISABLED', 'SELECTED', 'SELECTED_INTERVAL'].includes(state)
        ? ''
        : `background-color:${Theme.colors.cappuccino[50]}`};
  }
`;

const GradientCell = styled.div<{ left?: boolean }>`
  width: 100%;
  background: linear-gradient(
    ${({ left }) => (left ? 90 : 270)}deg,
    ${Theme.colors.candy[100]} 0%,
    rgba(255, 228, 220, 0) 88.02%
  );
  margin: ${SELECTED_MARGIN}px 0;
`;

const DefaultDayCell: React.FC<IDefaultDatePickerCell> = ({
  state,
  setSelectedDate,
  currentDay,
  isToday,
  onHover
}) => {
  const { colors } = useTheme();

  const dayColor: { [key in PickerDayState]: string } = {
    SELECTED: colors.white,
    DISABLED: colors.grey[500],
    DEFAULT: colors.grey[900],
    SELECTED_INTERVAL: colors.candy[600]
  };

  const dayBackground: { [key in PickerDayState]: string } = {
    SELECTED: colors.candy[500],
    DISABLED: 'transparent',
    DEFAULT: 'transparent',
    SELECTED_INTERVAL: colors.candy[100]
  };

  return (
    <DayCell
      state={state}
      onClick={() => state !== 'DISABLED' && setSelectedDate(currentDay)}
      onMouseEnter={() => state !== 'DISABLED' && onHover(currentDay)}
      onMouseLeave={() => state !== 'DISABLED' && onHover(undefined)}
      bgColor={dayBackground[state]}
    >
      <Body
        size={BodySize.Body70}
        variant={isToday ? TypographyVariant.Bold : TypographyVariant.Regular}
        color={
          isToday && state === 'DEFAULT' ? colors.candy[500] : dayColor[state]
        }
        noWrap
      >
        {currentDay.day.toString()}
      </Body>
    </DayCell>
  );
};

interface IMonthSwitcher {
  month: DateTime;
  setSelectedMonth: React.Dispatch<React.SetStateAction<DateTime>>;
  prevActive?: boolean;
  nextActive?: boolean;
}

export const MonthSwitcher: React.FC<IMonthSwitcher> = ({
  month,
  setSelectedMonth,
  prevActive,
  nextActive
}) => {
  const currentMonth = month.monthLong;
  const currentYear = month.year;

  const setPrevMonth = () => {
    setSelectedMonth((prev) => prev.minus({ months: 1 }));
  };

  const setNextMonth = () => {
    setSelectedMonth((prev) => prev.plus({ months: 1 }));
  };

  return (
    <Switcher
      onNext={nextActive ? setNextMonth : undefined}
      onPrev={prevActive ? setPrevMonth : undefined}
      label={`${currentMonth} ${currentYear.toString()}`}
    />
  );
};

const MonthLabels = () => {
  const startDay = DateTime.now().startOf('week');
  const candy = useColor('candy.500');
  return (
    <>
      {Array.from(Array(7).keys()).map((index) => {
        const currentDay = startDay.plus({ days: index });
        const weekdayNarrow = currentDay.toLocaleString({ weekday: 'narrow' });
        return (
          <MonthDiv key={`${weekdayNarrow}_${currentDay.day}`}>
            <Label
              size={LabelSize.Label40}
              color={candy}
              uppercase
              margin="16px 0"
            >
              {(weekdayNarrow || '')[0]}
            </Label>
          </MonthDiv>
        );
      })}
    </>
  );
};

export const DatePicker: React.FC<IDatePicker> = ({
  selectedEndDate,
  selectedStartDate,
  setSelectedStartDate,
  setSelectedEndDate,
  loadMoreLabel,
  disableSunday = false
}) => {
  const today = DateTime.now();
  const [selectedMonth, setSelectedMonth] = useState(today);
  const [monthsNumber, setMonthsNumber] = useState(VISUALIZED_MONTHS);
  const [hoverDate, setHoverDate] = useState<DateTime | undefined>();
  const { isMobile } = useBreakpointValue();

  useEffect(() => {
    if (selectedStartDate) {
      setSelectedMonth(selectedStartDate);
      setMonthsNumber(
        Math.max(
          4,
          (selectedEndDate ?? selectedStartDate).diffNow(['months']).months
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedEndOrHover =
    selectedEndDate || (selectedStartDate ? hoverDate : undefined);

  const checkState = useCallback(
    (currentDay: DateTime, isToday: boolean): PickerDayState => {
      const currentIsoDay = currentDay.toISODate();
      if (
        selectedStartDate?.toISODate() === currentIsoDay ||
        selectedEndDate?.toISODate() === currentIsoDay
      ) {
        return 'SELECTED';
      }
      if (
        (!isToday && currentDay.diffNow('days').days < 0) ||
        (disableSunday && currentDay.weekday === 7)
      ) {
        return 'DISABLED';
      }
      if (
        selectedStartDate &&
        selectedEndOrHover &&
        currentDay > selectedStartDate &&
        currentDay <= selectedEndOrHover
      ) {
        return 'SELECTED_INTERVAL';
      }
      return 'DEFAULT';
    },
    [selectedStartDate, selectedEndOrHover, selectedEndDate]
  );

  const setSelectedDate = useCallback(
    (date: DateTime) => {
      if (!selectedStartDate) {
        setSelectedStartDate(date);
        return;
      }

      if (date < selectedStartDate) {
        setSelectedStartDate(date);
        return;
      }

      if (!selectedEndDate) {
        setSelectedEndDate(date);
        return;
      }

      if (date.toISODate() === selectedStartDate.toISODate()) {
        setSelectedStartDate(undefined);
        setSelectedEndDate(undefined);
        isMobile && setHoverDate(undefined);
        return;
      }

      if (date.toISODate() === selectedEndDate.toISODate()) {
        setSelectedEndDate(undefined);
        isMobile && setHoverDate(undefined);
        return;
      }

      setSelectedEndDate(date);
    },
    [
      selectedStartDate,
      selectedEndDate,
      setSelectedEndDate,
      setSelectedStartDate,
      isMobile
    ]
  );

  const checkStartGradient =
    selectedStartDate &&
    selectedEndOrHover &&
    selectedEndOrHover.month !== selectedStartDate.month;

  const checkEndGradient =
    selectedEndOrHover &&
    selectedStartDate &&
    selectedEndOrHover.month !== selectedStartDate.month;

  return (
    <>
      {isMobile && (
        <MonthsWrapper>
          <MonthLabels />
        </MonthsWrapper>
      )}
      <FlexDiv data-testid="vacations-date-picker">
        {Array.from(Array(isMobile ? monthsNumber : 2).keys()).map((index) => {
          const currentMonth = (isMobile ? today : selectedMonth).plus({
            months: index
          });
          const currentStartMonth = currentMonth.startOf('month');
          const monthStartDay = currentStartMonth.weekday;
          const currentEndMonth = currentMonth.endOf('month');
          return (
            <Container key={`${currentStartMonth.toISODate()}_picker`}>
              <MonthSwitcher
                month={currentMonth}
                setSelectedMonth={setSelectedMonth}
                prevActive={
                  !isMobile &&
                  currentMonth > today.endOf('month') &&
                  index === 0
                }
                nextActive={!isMobile && index === 1}
              />
              <CalendarGrid>
                {!isMobile && <MonthLabels />}
                {Array.from(Array(monthStartDay - 1).keys()).map((idx) => {
                  return idx === monthStartDay - 2 &&
                    checkStartGradient &&
                    selectedStartDate < currentStartMonth &&
                    selectedEndOrHover >= currentStartMonth ? (
                    <GradientCell
                      key={`${currentMonth.toISODate()}_gradient`}
                    />
                  ) : (
                    <div key={`${currentMonth.toISODate()}_padding_${idx}`} />
                  );
                })}
                {Array.from(Array(currentMonth.daysInMonth).keys()).map(
                  (idx) => {
                    const currentDay = currentStartMonth.plus({ days: idx });
                    const currentIsoDate = currentDay.toISODate();
                    const isToday = today.toISODate() === currentIsoDate;

                    return (
                      <DefaultDayCell
                        state={checkState(currentDay, isToday)}
                        setSelectedDate={setSelectedDate}
                        currentDay={currentDay}
                        key={currentIsoDate}
                        isToday={isToday}
                        onHover={setHoverDate}
                      />
                    );
                  }
                )}
                {currentEndMonth.weekday < 7 &&
                  checkEndGradient &&
                  selectedEndOrHover > currentEndMonth &&
                  selectedStartDate < currentEndMonth && <GradientCell left />}
              </CalendarGrid>
            </Container>
          );
        })}
        {isMobile && loadMoreLabel && (
          <ButtonWrapper>
            <Button
              label={loadMoreLabel}
              onClick={() =>
                setMonthsNumber((prev) => prev + VISUALIZED_MONTHS)
              }
              fullWidth
            />
          </ButtonWrapper>
        )}
      </FlexDiv>
    </>
  );
};
