import { useQuery } from '@apollo/client';
import { useFirebaseUser } from '@unobravo/core';
import { GET_PRICE } from '../graphql/queries';

export const usePrice = ({ onError }: { onError?: () => void }) => {
  const { currentUser } = useFirebaseUser();
  const { data, error, loading } = useQuery(GET_PRICE, {
    variables: { id: currentUser.firebaseId },
    returnPartialData: true,
    onError,
    skip: !currentUser?.firebaseId
  });

  const formatAmount = () => {
    const amount = data?.getPatientFromFirebaseId?.pricing?.amount;
    if (amount) {
      return amount / 100;
    }
    return null;
  };

  return { price: formatAmount(), loading, error };
};
