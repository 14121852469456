import { Troubleshooting } from '@unobravo-monorepo/common/components/Troubleshooting';
import { useState } from 'react';
import styled from 'styled-components';
import { Box, ModalAlert, Stack } from '@unobravo/zenit-web';
import { SearchGraph } from '@unobravo/zenit-icons';
import { useTranslation } from 'react-i18next';
import { TooltipIcon } from '../../../shared/components/TooltipIcon';

export const TroubleshootingModal = () => {
  const [openModal, setOpen] = useState(false);
  const { t } = useTranslation();

  const CustomActionIcon = styled(Stack)`
    cursor: pointer;
  `;

  return (
    <>
      <Box data-testid="pendo-click-pt-troubleshooting">
        <CustomActionIcon
          onClick={() => setOpen(true)}
          role="button"
          aria-label="open patient troubleshooting modal"
          tabIndex={0}
        >
          <TooltipIcon
            tooltipText={t('videoCall.troubleshootingButtonTooltip')}
            icon={<SearchGraph color="candy.500" />}
            side="left"
          />
        </CustomActionIcon>
      </Box>
      {openModal && (
        <ModalAlert
          open={openModal}
          title={t('common:videoCall.troubleshootingModal.title')}
          w={376}
          onClose={() => setOpen(false)}
        >
          <Troubleshooting />
        </ModalAlert>
      )}
    </>
  );
};
