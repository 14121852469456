import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { useTranslation } from 'react-i18next';

export const useErrorHandler = () => {
  const sendToast = useToaster();
  const { t } = useTranslation();

  const sendGenericError = () => {
    sendToast({
      variant: 'error',
      title: t('common:errorPage.ops2'),
      description: t('common:errorPage.retry'),
      duration: 'persistent'
    });
  };
  return { sendGenericError };
};
