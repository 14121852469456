import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GetDoctorPresentationVariablesDocument } from '../graphql/queries.generated';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useTherapistInfo } from '../../selectYourTp/hook/useTherapistInfo';

export const useDoctorPresentation = () => {
  const { doctor } = usePatient();
  const [getDoctorPresentation, { data }] = useLazyQuery(
    GetDoctorPresentationVariablesDocument
  );
  const variants = useTherapistInfo(data?.getDoctorPresentationVariables, 3);
  useEffect(() => {
    if (doctor?.id && !data) {
      getDoctorPresentation({ variables: { doctorId: doctor.id } });
    }
  }, [doctor?.id, data]);

  return {
    variants: data?.getDoctorPresentationVariables ? variants : [],
    doctorPresentation: data?.getDoctorPresentationVariables
  };
};
