import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Star: React.FC<IIcon> = ({
  width = 20,
  height = 20,
  color = Theme.colors.yoka[400]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.47139 0.765379C5.84253 0.0381278 6.88168 0.038128 7.25282 0.765379L8.35976 2.93443C8.50506 3.21915 8.77772 3.41725 9.09341 3.46746L11.4984 3.84994C12.3047 3.97819 12.6258 4.96648 12.0489 5.54419L10.328 7.26722C10.1021 7.49339 9.998 7.81393 10.0478 8.12968L10.4272 10.5351C10.5544 11.3416 9.71372 11.9524 8.986 11.5822L6.81553 10.4781C6.53062 10.3331 6.19359 10.3331 5.90869 10.4781L3.73822 11.5822C3.01049 11.9524 2.1698 11.3416 2.29701 10.5351L2.67641 8.12968C2.72622 7.81393 2.62207 7.49339 2.39619 7.26722L0.675359 5.54419C0.0983909 4.96648 0.419506 3.97819 1.22585 3.84994L3.6308 3.46746C3.94649 3.41725 4.21915 3.21915 4.36445 2.93443L5.47139 0.765379Z"
        fill={color}
      />
    </svg>
  );
};
