import React from 'react';
import styled from 'styled-components';
import IAlert, { AlertType } from '../../types/IAlert.d';
import { Theme } from '../../theme';
import { Body, BodySize } from '../Typography/Body';
import { TypographyVariant } from '../Typography';
import { ShieldCheck } from '../Icons/ShieldCheck';
import { Error, Warning } from '../Icons';
import IIcon from '../../types/IIcon';

interface IWrapper {
  textColor?: string;
  color: string;
  margin: string;
}

const Wrapper = styled.div<IWrapper>`
  padding: 12px;
  margin: ${({ margin }) => margin};
  border-radius: 8px;
  background: ${({ color }) => color};
  display: flex;
  align-items: flex-start;
  a {
    color: ${({ textColor }) => textColor};
    text-decoration: underline;
    font-weight: 600;
    &:visited {
      color: ${({ textColor }) => textColor};
    }
  }
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 25px;
`;

const IconMap: { [key in AlertType]: React.FC<IIcon> } = {
  warning: Warning,
  error: Error,
  success: ShieldCheck
};

/**
 * @deprecated This component should not be used use Alert from "@unobravo/zenit-web" instead.
 */
export const Alert: React.FC<IAlert> = ({
  title = '',
  message = '',
  type = 'success',
  margin = '0 auto 16px',
  showIcon,
  CustomIcon,
  'data-testid': dataTestId
}) => {
  const typeMap = {
    success: Theme.colors.edamame,
    error: Theme.colors.red,
    warning: Theme.colors.ginger
  };
  const gradient = type === 'warning' ? '600' : '500';
  const color = typeMap[type][gradient];
  const Icon = CustomIcon ?? IconMap[type];

  return (
    <Wrapper
      id="alert-component"
      data-testid={dataTestId || 'alert-component'}
      margin={margin as string}
      color={typeMap[type]['50'] as string}
      textColor={typeMap[type][gradient] as string}
    >
      {(showIcon || CustomIcon) && (
        <IconWrapper>
          <Icon color={color} />
        </IconWrapper>
      )}
      <TextWrapper>
        {title && (
          <Body
            color={typeMap[type][gradient] as string}
            size={BodySize.Body80}
            variant={TypographyVariant.SemiBold}
            margin={!!message}
          >
            {title}
          </Body>
        )}
        {message && (
          <Body
            color={typeMap[type][gradient] as string}
            size={BodySize.Body70}
          >
            {message}
          </Body>
        )}
      </TextWrapper>
    </Wrapper>
  );
};
