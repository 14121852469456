import {
  OnboardingStepType,
  StepType
} from '@unobravo-monorepo/patient/types/Onboarding';
import { Color } from '@unobravo/zenit-core';
import { Accordion, Box, Stack } from '@unobravo/zenit-web';
import { LeftIcon } from './LeftIcon';

export const AccordionStep = ({
  item,
  showDashedLine = false,
  openAccordion,
  onAccordionChange = () => {}
}: {
  item: StepType;
  showDashedLine: boolean;
  openAccordion: boolean;
  onAccordionChange?: (value: OnboardingStepType | undefined) => void;
}) => {
  const greyGradient: [Color, Color] = ['grey.50', 'grey.100'];

  return (
    <Stack
      direction="row"
      spacing="md"
      onClick={() => {
        if (openAccordion) {
          onAccordionChange(undefined);
        } else {
          onAccordionChange(item.id);
        }
      }}
    >
      <LeftIcon checked={false} showDashedLine={showDashedLine} />
      <Box grow overflow="hidden" position="relative">
        <Accordion
          items={[
            {
              ...item,
              bgColor: greyGradient
            }
          ]}
          value={openAccordion ? 0 : -1}
        />
      </Box>
    </Stack>
  );
};
