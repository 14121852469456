import { useQuery } from '@apollo/client';
import { usePatient } from '../../patientData/hooks/usePatient';
import { GetPatientOnboardingStatusDocument } from '../graphql/queries.generated';

export const useOnboardingStatus = () => {
  const { id, doctor, status } = usePatient();
  const doctorId = doctor?.id;

  const {
    data: onBoardingData,
    error: queryError,
    loading: queryLoading,
    refetch
  } = useQuery(GetPatientOnboardingStatusDocument, {
    variables: { patientId: id!, doctorId: doctorId! },
    skip:
      !id ||
      !doctorId ||
      (!!status &&
        ['SUSPENDED', 'ONGOING', 'COMPLETED', 'TERMINATED'].includes(status))
  });

  return {
    queryError,
    queryLoading,
    refetchStatus: refetch,
    ...onBoardingData?.getPatientOnboardingStatus
  };
};
