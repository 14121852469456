import { createContext, FC, ReactNode, useCallback, useState } from 'react';
import { IToast } from '../../types/IToast';
import { Toaster } from '../../components/Toaster/Toaster';

export const index = createContext({} as (toast: Partial<IToast>) => void);

const defaultToastValues: IToast = {
  title: '',
  description: '',
  variant: 'success',
  id: ''
};

export const ToasterProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setHoverToast] = useState<string | number | undefined>();

  const checkHover = useCallback(
    (id: string | number, hoverToastId?: string | number) => {
      if (hoverToastId === id) {
        setToasts((prevToasts) =>
          prevToasts.map((t) => (t.id === id ? { ...t, expired: true } : t))
        );
        return false;
      }
      return true;
    },
    []
  );

  const dismissToast = useCallback(
    (id: string | number, duration = 4000, check = false) => {
      setTimeout(() => {
        setHoverToast((prev) => {
          if (!check || checkHover(id, prev)) {
            setToasts((prevToasts) => prevToasts.filter((t) => t.id !== id));
            return undefined;
          }
          return prev;
        });
      }, duration);
    },
    [checkHover]
  );

  const addToast = useCallback(
    (toast: Partial<IToast>) => {
      setToasts((prevToasts) => {
        const newToast = {
          ...defaultToastValues,
          id: Math.random(),
          ...toast
        };
        toast.duration !== 'persistent' &&
          dismissToast(newToast.id, toast.duration, true);
        return [...prevToasts, newToast];
      });
    },
    [setToasts, dismissToast]
  );

  return (
    <index.Provider value={addToast}>
      <Toaster
        closeToast={(toastId) => {
          dismissToast(toastId, 0);
        }}
        setHoverToast={setHoverToast}
        toasts={toasts}
      />
      {children}
    </index.Provider>
  );
};
