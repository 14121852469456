import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import {
  PATIENT_REDIRECT_CONTEXTS,
  SIGNUP_REDIRECT_CONTEXTS,
  PARTNERS_REDIRECT_CONTEXT
} from '@unobravo-monorepo/common/utils/libsRedirect';
import { Location, Navigate, useLocation } from 'react-router-dom';

const contextRedirects: {
  [key in
    | SIGNUP_REDIRECT_CONTEXTS
    | PATIENT_REDIRECT_CONTEXTS
    | PARTNERS_REDIRECT_CONTEXT]: string;
} = {
  [SIGNUP_REDIRECT_CONTEXTS.ACTIVATE_PATIENT]: '/patient/activate-patient',
  [SIGNUP_REDIRECT_CONTEXTS.INFO]: '/activation/info',
  [PATIENT_REDIRECT_CONTEXTS.REASSIGNMENT_CONFIRM]:
    '/patient/new-survey/confirm',
  [PATIENT_REDIRECT_CONTEXTS.SIGNUP_WELCOME]: '/signup/resume',
  [PARTNERS_REDIRECT_CONTEXT.SIGNUP]: '/signup'
};

export function CheckRoutes() {
  const { currentUser, loaded } = useFirebaseUser();
  const location = useLocation();
  const { prevLocation, context } =
    (location.state as {
      prevLocation?: Location;
      context?:
        | SIGNUP_REDIRECT_CONTEXTS
        | PATIENT_REDIRECT_CONTEXTS
        | PARTNERS_REDIRECT_CONTEXT;
    }) || {};

  if (!loaded) {
    return <></>;
  }

  if (context && contextRedirects[context]) {
    return (
      <Navigate to={contextRedirects[context]} state={{ context }} replace />
    );
  }

  if (currentUser.isAnonymous) {
    return <Navigate to="/signup/resume" replace />;
  }

  if (currentUser.role === 'onboarding_doctor') {
    return <Navigate to="/doctor-acquisition/" replace />;
  }

  if (currentUser.role === 'doctor') {
    return <Navigate to="/doctor/" replace />;
  }

  if (currentUser.role === 'patient') {
    return <Navigate to="/patient/" replace />;
  }

  return <Navigate to="/login" state={{ prevLocation }} replace />;
}
