import styled from 'styled-components';
import { Theme } from '../../theme';

const Wrapper = styled.div<{ absolute: boolean }>`
  height: 60px;
  width: 60px;
  position: ${({ absolute }) => (absolute ? 'absolute' : undefined)};
  top: ${({ absolute }) => (absolute ? '50%' : undefined)};
  left: ${({ absolute }) => (absolute ? '50%' : undefined)};
  margin: ${({ absolute }) => (absolute ? '-30px 0 0 -30px;' : undefined)};
  -webkit-animation: rotation 1s infinite linear;
  -moz-animation: rotation 1s infinite linear;
  -o-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
  border: 6px solid ${Theme.colors.gray[100]};
  border-radius: 100%;
  z-index: 5;
  :before {
    content: '';
    display: block;
    position: absolute;
    left: -6px;
    top: -6px;
    height: 100%;
    width: 100%;
    border-top: 6px solid ${Theme.colors.candy[500]};
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 100%;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(359deg);
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const Spinner = ({ absolute = true }: { absolute?: boolean }) => {
  return <Wrapper data-testid="spinner" absolute={absolute} />;
};
