import emptyProfilePic from '@unobravo-monorepo/patient/assets/emptyProfilePic.svg';
import { usePatient } from '../../features/patientData/hooks/usePatient';

export const useDoctorInfo = () => {
  const { doctor } = usePatient();

  return {
    ...doctor,
    reviews: (doctor?.reviews?.filter((item) => Boolean(item)) || []) as {
      id?: string | number;
      text?: string;
      vote?: number;
    }[],
    firstName: doctor ? `${doctor.name}` : '',
    name: doctor ? `${doctor.name} ${doctor.surname}` : '',
    professionalAreas: doctor?.professionalAreas || [],
    tagsValues: doctor?.tagsValues || [],
    profilePicture: doctor?.profilePicture || emptyProfilePic
  };
};
