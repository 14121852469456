import { frequencyMap } from '@unobravo-monorepo/common/utils/therapySetting';
import {
  Box,
  Link,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '../../../../../../common/src/components/Divider';
import { DoctorInfoModal } from '../../../../shared/components/TherapySetting/Modals/DoctorInfoModal';
import { appointmentsSelector } from '../../../agenda/store/selectors';
import { setShowDoctorInfoModal } from '../../../home/home.slice';
import { patientSelector } from '../../../patientData/store/selectors';
import { TherapySettingSelector } from '../../store/therapySetting.slice';
import { filterSessionsByStatus } from '../../utils/session';
import { TherapySettingRow } from './TherapySettingRow';

const DATE_FORMAT = 'dd/MM/yyyy';

/**
 * Therapy Setting Informations
 */
export function TherapySettingInfo() {
  const { t } = useTranslation();
  const { index } = useBreakpointValue();
  const dispatch = useDispatch();

  const { therapyInfo } = useSelector(TherapySettingSelector);
  const { doctor } = useSelector(patientSelector);
  const { pastSessions } = useSelector(appointmentsSelector);

  /** Last done session in past sessions */
  const lastSessionDate = useMemo(() => {
    const when = pastSessions.find((session) =>
      filterSessionsByStatus({
        session,
        statuses: ['DONE']
      })
    )?.when;

    return when ? DateTime.fromISO(when).toFormat(DATE_FORMAT) : '–';
  }, [pastSessions]);

  const therapySettings = [
    {
      label: 'therapySetting.frequency',
      value: therapyInfo?.therapySetting
        ? t(frequencyMap[therapyInfo?.therapySetting])
        : t(frequencyMap.notSet)
    },
    {
      label: 'therapySetting.orientationType',
      value: therapyInfo?.doctorTherapeuticOrientation || '–'
    },
    {
      label: 'therapySetting.startDate',
      value: therapyInfo?.firstSessionWithCurrentDoctor
        ? DateTime.fromISO(therapyInfo.firstSessionWithCurrentDoctor).toFormat(
            DATE_FORMAT
          )
        : '–'
    },
    {
      label: 'therapySetting.lastDate',
      value: lastSessionDate
    }
  ];

  return (
    <>
      <RStack
        py={{ base: 'md', sm: 'xl' }}
        px={{ base: undefined, sm: 'xl' }}
        bgColor={index > 1 ? 'cappuccino.50' : undefined}
        rounded="md"
        direction="column"
        h="fit-content"
        spacing="md"
        style={{ flex: 1 }}
      >
        <Stack spacing="xs" align="center">
          <Box w="100px">
            <Text variant="sm" color="grey.700">
              {t('therapySetting.tpName')}
            </Text>
          </Box>
          <Stack direction="column" spacing="sm">
            <Link
              color="candy.500"
              underline
              to={() => {
                dispatch(setShowDoctorInfoModal(true));
              }}
            >
              <Text variant="sm" fontWeight="semibold">
                {doctor?.name} {doctor?.surname}
              </Text>
            </Link>
            <Text variant="xs" fontWeight="medium" color="grey.600">
              {t('therapySetting.tpNameSublabel')}{' '}
              {therapyInfo?.firstSessionWithCurrentDoctor &&
                DateTime.fromISO(
                  therapyInfo.firstSessionWithCurrentDoctor
                ).toFormat(DATE_FORMAT)}
            </Text>
          </Stack>
        </Stack>

        <Divider type="horizontal" />

        {therapySettings.map(({ label, value }, i) => (
          <Fragment key={label}>
            <TherapySettingRow label={label} value={value} />
            {i < therapySettings.length - 1 && <Divider type="horizontal" />}
          </Fragment>
        ))}
      </RStack>
      <DoctorInfoModal />
    </>
  );
}
