/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { theme } from '@unobravo/zenit-core';
import { Invoice } from '@unobravo/zenit-icons';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const InvoiceBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      p="sm"
      spacing="sm"
      align="center"
      bgColor="cappuccino.100"
      rounded="lg"
    >
      <Box h={48} p="sm" rounded="2xl" bgColor="candy.100">
        <Invoice color="candy.600" />
      </Box>
      <Box>
        <Text variant="sm">
          {t('bundles.alertLabel')}{' '}
          <span
            role="link"
            onClick={() => {
              navigate('../invoices?defaultTab=bundles');
            }}
            style={{
              cursor: 'pointer',
              color: theme.colors.candy['500'],
              fontWeight: 600
            }}
          >
            {t('invoices.pageTitle')}
          </span>
        </Text>
      </Box>
    </Stack>
  );
};
