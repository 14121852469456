import { createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';
import { LocalStorageVouchersSnapshot } from './types';

interface IVouchers {
  lastAchievementSnapshot?: LocalStorageVouchersSnapshot;
}

const initialState: IVouchers = {
  lastAchievementSnapshot: undefined
};

export const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    loadLastAchievementSnapshot: (state, action) => {
      state.lastAchievementSnapshot = action.payload;
    }
  }
});

export default vouchersSlice.reducer;

export const { loadLastAchievementSnapshot } = vouchersSlice.actions;

export const vouchersSelector = (state: PatientRootState): IVouchers =>
  state.vouchers;
