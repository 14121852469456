import React from 'react';

import { Toaster } from '@unobravo-monorepo/common/components/Toaster/Toaster';
import { useToast } from './hooks/useToast';

export const PatientToaster: React.FC = () => {
  const { toasts, closeToast, setHoverToast } = useToast();

  return (
    <Toaster
      toasts={toasts}
      setHoverToast={setHoverToast}
      closeToast={closeToast}
    />
  );
};
