import {
  ApolloClientProvider,
  platform
} from '@unobravo-monorepo/common/providers/ApolloClientProvider';
import { ToasterProvider } from '@unobravo-monorepo/common/providers/Toaster';
import { TranslationsProvider, useCountry } from '@unobravo/translations';
import { ErrorPageProvider } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const { NX_APP_APOLLO_URI: apolloUri } = process.env;

export const PatientActivationProvider = () => {
  const { domainCountry } = useCountry();
  const { t } = useTranslation();

  return (
    <TranslationsProvider namespaces={['patient-activation']}>
      <ToasterProvider>
        <ApolloClientProvider
          uri={apolloUri!}
          application={platform}
          country={domainCountry}
        >
          <ErrorPageProvider
            errorPageProps={{
              wentWrong: t('common:errorPage.ops'),
              description: t('common:errorPage.wentWrong'),
              pageMalfunction: t('common:errorPage.pageMalfunction')
            }}
          >
            <Outlet />
          </ErrorPageProvider>
        </ApolloClientProvider>
      </ToasterProvider>
    </TranslationsProvider>
  );
};
