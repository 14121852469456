/* eslint-disable no-nested-ternary */
import React, { forwardRef, ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { mediaQueries, Theme } from '../../theme';
import { IModal } from '../../types/IModal';
import { Cross } from '../Icons';
import { Close } from '../Icons/Close';
import { useViewport } from '../../hooks/useViewportHook';

interface IModalWrapper {
  padding: string;
  fixedHeight: boolean | 'xsmall' | 'small' | 'full';
  width?: number;
  fullScreen?: boolean;
  position: 'start' | 'center' | 'end';
}

enum PositionValues {
  start = 0,
  center = 50,
  end = 100
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.6);
`;

const ModalWrapper = styled.div<IModalWrapper>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  max-width: 100%;
  ${({ fixedHeight }) =>
    fixedHeight
      ? css`
          max-height: 90%;
          height: ${fixedHeight === 'xsmall'
            ? '420px'
            : fixedHeight === 'small'
            ? '620px'
            : fixedHeight === 'full'
            ? 'calc(100% - 82px)'
            : '760px'};
        `
      : css`
          max-height: 90vh;
        `}
  position: fixed;
  z-index: 100;
  left: ${({ position, width }) =>
    position === 'center'
      ? `${PositionValues[position]}%`
      : `calc(${PositionValues[position]}% ${
          position === 'start' ? '+' : '-'
        } ${width ? width / 2 + 24 : 0}px)`};
  top: 50%;
  transform: translate(-50%, -50%);
  transition: width 500ms;
  background: ${Theme.specialColors.white};
  border-radius: 16px;
  padding: ${({ padding }) => padding};
  overflow: auto;
  ${({ fullScreen }) =>
    mediaQueries('md')(`
          top: auto;
          bottom: 0px;
          width: 100%;
          transform: translate(-50%, 0);
          min-height: ${fullScreen ? '100%' : 'auto'};
          ${fullScreen ? 'height: 100%;' : ''}
          border-radius: ${fullScreen ? '0px' : '24px 24px 0 0'};
          max-height: ${fullScreen ? '100%' : `calc(100% - 82px)`};
          overflow: ${fullScreen ? 'auto' : 'visible'};
        `)}
`;

const FlowIcon = styled.button`
  cursor: pointer;
  display: block;
  align-items: center;
  border: none;
  border-radius: 50px;
  background-color: ${Theme.specialColors.white};
  height: 50px;
  width: 50px;
  justify-content: center;
  position: absolute;
  right: 20px;
  text-decoration: none;
  top: -58px;
`;

const IconContainer = styled.div`
  padding: 14px 14px 9px 14px;
  position: absolute;
  z-index: 22;
  right: 0;
  top: 0;
  cursor: pointer;
`;

/**
 * @deprecated use Modal or ModalAlert from '@unobravo/zenit-web' instead
 */
export const Modal = forwardRef<
  HTMLDivElement,
  IModal & { children: ReactNode }
>(
  (
    {
      open,
      padding = '20px',
      children,
      fixedHeight = true,
      width = 500,
      fullScreen = false,
      onClose,
      closeDataTestId,
      closeIconColor,
      position = 'center'
    },
    ref
  ) => {
    const { isMobile } = useViewport();

    return (
      <>
        {open && (
          <>
            <GlobalStyle />
            <Overlay data-testid="overlay" id="modal-overlay" />

            <ModalWrapper
              data-testid="modal"
              position={position}
              fixedHeight={fixedHeight}
              padding={padding}
              ref={ref}
              width={width}
              fullScreen={fullScreen}
              id="modal-wrapper"
            >
              {onClose && !fullScreen && isMobile && (
                <FlowIcon
                  data-testid={closeDataTestId || 'modal-mobile-close-button'}
                  onClick={onClose}
                >
                  <Close width={10} height={10} />
                </FlowIcon>
              )}
              {onClose && (!isMobile || fullScreen) && (
                <IconContainer
                  onClick={onClose}
                  data-testid={closeDataTestId || 'modal-desktop-close-button'}
                >
                  <Cross color={closeIconColor} width={20} height={20} />
                </IconContainer>
              )}
              {children}
            </ModalWrapper>
          </>
        )}
      </>
    );
  }
);
