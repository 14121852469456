import { Alert, Box } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../../../patientData/hooks/usePatient';

export const PartnerMessageAlert: React.FC = () => {
  const { t } = useTranslation();
  const { doctor } = usePatient();
  const { doctorRegisterNumber, doctorRegisterRegion, name, surname, sex } =
    doctor || {};

  return (
    <Box mt="md">
      <Alert
        title=""
        message={t(`legal:informedConsent.alertConsentPartner.${sex}`, {
          name,
          surname,
          region: doctorRegisterRegion || '-',
          number: doctorRegisterNumber || '-'
        })}
        type="informative"
      />
    </Box>
  );
};
