import { useColor } from '@unobravo/zenit-core';
import { Box, Heading, Text } from '@unobravo/zenit-web';
import { Trans } from 'react-i18next';

const Title = ({
  titleKey,
  coloredTitle,
  variant
}: {
  titleKey: string;
  coloredTitle: string;
  variant: 'MEET_TP' | 'START_THERAPY';
}) => {
  const candy = useColor('candy.500');
  const berry = useColor('berry.500');

  return (
    <span>
      <Heading variant="sm" color="grey.800">
        <Trans
          i18nKey={titleKey}
          values={{ name: coloredTitle }}
          components={[
            <span style={{ color: variant === 'MEET_TP' ? candy : berry }}>
              <Heading color="candy.500" variant="sm">
                {coloredTitle}
              </Heading>
            </span>
          ]}
        />
      </Heading>
    </span>
  );
};

export const CarouselCard = ({
  titleKey,
  coloredTitle,
  description,
  variant
}: {
  titleKey: string;
  coloredTitle: string;
  description: string;
  variant: 'MEET_TP' | 'START_THERAPY';
}) => {
  const GRADIENT_BERRY =
    'radial-gradient(77.17% 51.93% at 70.38% 57.34%, rgba(205, 73, 33, 0.05)0%, rgba(244, 99, 56, 0.05)100%), radial-gradient(164.85% 100% at 50% 0%, #CEDDE8 0%, #EDF5FB 100%)';
  const GRADIENT_CANDY =
    'radial-gradient(77.17% 51.93% at 70.38% 57.34%, rgba(211, 94, 221, 0.03)0%, rgba(255, 142, 142, 0.03)100%), radial-gradient(164.85% 100% at 50% 0%, #FCF5DB 0%, #FBD9CE 100%)';

  return (
    <Box
      style={{
        background: variant === 'MEET_TP' ? GRADIENT_CANDY : GRADIENT_BERRY
      }}
      rounded="md"
      h="100%"
      px="md"
      py="xl"
    >
      <Title
        titleKey={titleKey}
        coloredTitle={coloredTitle}
        variant={variant}
      />
      <Box pt="sm">
        <Text color="grey.800">{description}</Text>
      </Box>
    </Box>
  );
};
