import {
  ModalAlert,
  Stack,
  Text,
  Heading,
  Button,
  useBreakpointValue,
  RStack
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export function EndSurveyModal({
  isReassignmentVisible
}: {
  isReassignmentVisible?: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const location = state ? state.modal : '/patient/home';
  const { isMobile } = useBreakpointValue();

  const close = () => {
    return navigate('/patient/home');
  };

  const handleClick = () => {
    return navigate(`../dialog/reassignmentModal`, {
      state: { modal: location }
    });
  };

  return (
    <ModalAlert
      open
      onClose={close}
      dataTestIdCloseIcon="therapy-rating-action-nochange"
    >
      <Stack
        direction="column"
        spacing="md"
        data-testid="therapy-rating"
        pt="md"
      >
        <Stack direction="column" pb="lg" spacing="sm">
          <Heading>{t('feedbackModal.thanksTitle')}</Heading>
          <Text color="grey.600">
            {isReassignmentVisible
              ? t('feedbackModal.changeTpDescription')
              : t('feedbackModal.defaultDescription')}
          </Text>
        </Stack>
        <RStack
          direction="column"
          align={!isReassignmentVisible ? 'end' : undefined}
          spacing="xs"
          pt="lg"
          pb={{ base: '2xl', md: undefined }}
        >
          <Button
            label={t('feedbackModal.continueButton')}
            onClick={close}
            data-testid="therapy-rating-action-nochange"
            fullWidth={isMobile}
          />
          {isReassignmentVisible && (
            <Button
              label={t('feedbackModal.changeTpButton')}
              onClick={handleClick}
              variant="ghost"
              data-testid="therapy-rating-action-change"
            />
          )}
        </RStack>
      </Stack>
    </ModalAlert>
  );
}
