import { PatientHomepageStepperVariant } from '../../../../generated/patient.generated';
import { useOnboardingStatus } from './useOnboardingStatus';

export const useIsFreeSessionHomeEnable = () => {
  const {
    status: onboardingStatus,
    variant,
    queryLoading
  } = useOnboardingStatus();

  return {
    // TODO: restore this check to start FreeSessionHomepage AB test
    value: false, // variant === PatientHomepageStepperVariant.New && !!onboardingStatus,
    loading: queryLoading
  };
};
