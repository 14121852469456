import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { useInternationalVariables } from '@unobravo/translations';
import {
  Alert,
  Button,
  ModalAlert,
  RBox,
  RStack,
  useBreakpointValue,
  Heading,
  Text,
  Box,
  Link
} from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useZendesk } from '../../../shared/hooks/useZendesk';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { usePatient } from '../../patientData/hooks/usePatient';
import { SectionHeader } from '../components/SectionHeader';
import { openReassignmentModal } from '../../reassignment/reassignment.slice';

const ListContainer = styled.div`
  margin-left: 22px;
`;

export const DeleteAccount: React.FC = () => {
  const { t } = useTranslation();
  const { name, email, requestDeletePatient, reassignConditions, doctor } =
    usePatient();
  const { nextSessions, loadAppointments } = useAgenda();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { sendGenericError } = useErrorHandler();
  const [loading, setLoading] = useState(false);
  const { logout } = useFirebaseUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useBreakpointValue();
  const { zendeskDefaultUrl } = useInternationalVariables();

  const showReassignment =
    reassignConditions?.automatic ||
    reassignConditions?.canPerformSmoothReassign;

  const handleClick = () => {
    if (reassignConditions?.canPerformSmoothReassign) {
      return navigate(`../../dialog/reassignmentModal`, {
        state: { modal: location }
      });
    }

    return dispatch(openReassignmentModal());
  };

  useEffect(() => {
    if (nextSessions.length === 0) loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPaidSession = nextSessions.some((n) => n.paid);

  const { goToZendeskAsLoggedUser } = useZendesk();

  const onSubmit = async () => {
    setIsModalOpen(true);
  };

  const confirmDeleteAccount = async () => {
    setLoading(true);
    const success = await requestDeletePatient();
    if (success) {
      await logout();
    }
    if (!success) {
      sendGenericError();
    }
    setIsModalOpen(false);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <PageContainer>
      <SectionHeader path="../" title={`${t('deleteAccount.label')}`} />

      <RStack
        direction="column"
        style={{ flexGrow: 1 }}
        p="lg"
        spacing="lg"
        maxW="800px"
      >
        <RStack direction="column" pb={{ base: undefined, md: 'xl' }}>
          <Box my="xs">
            <Heading variant="lg">{t('deleteAccount.title', { name })}</Heading>
          </Box>
          <Box>
            <Text color="grey.800" variant="md">
              {t('deleteAccount.description.title')}
            </Text>
          </Box>

          <ListContainer>
            <ul>
              <li>
                <Box my="xs">
                  <Text variant="md" color="grey.800">
                    {t('deleteAccount.description.item1')}
                  </Text>
                </Box>
              </li>
              <li>
                <Text variant="md" color="grey.800">
                  {t('deleteAccount.description.item2')}
                </Text>
              </li>
            </ul>
          </ListContainer>
          <Box my="lg">
            <Text variant="md" color="grey.800">
              <Trans
                i18nKey="deleteAccount.description.conclusion"
                values={{
                  name: t('deleteAccount.description.support')
                }}
                components={[
                  <Link
                    color="candy.500"
                    to={() => goToZendeskAsLoggedUser('requests/new')}
                  >
                    {t('deleteAccount.description.support')}
                  </Link>
                ]}
              />
            </Text>
            <Text variant="md" color="grey.800">
              {t('deleteAccount.description.invoiceRemainder')}
            </Text>
          </Box>
        </RStack>
        <RStack
          direction="column"
          spacing="xl"
          align="start"
          maxW={!showReassignment && !isMobile ? '90%' : undefined}
        >
          {showReassignment ? (
            <Alert
              title={t('deleteAccount.reassignment.title', {
                doctorName: doctor?.name
              })}
              message={t('deleteAccount.reassignment.description')}
              type="informative"
              flexDirection={isMobile ? 'column' : 'row'}
              icon={!isMobile}
              action={{
                fullWidth: true,
                label: t('reassignment.button'),
                onPress: handleClick,
                actionDataTestId: 'reassignment-button-delete-account'
              }}
            />
          ) : (
            <Alert
              title={t('deleteAccount.box', { email })}
              type="informative"
              icon={false}
            />
          )}

          <Button
            label={t('deleteAccount.btnLabel')}
            onClick={onSubmit}
            fullWidth={isMobile}
          />
        </RStack>
      </RStack>
      <ModalAlert
        title={
          !hasPaidSession
            ? t('deleteAccount.confirmModal.title')
            : t('deleteAccount.errorModal.title')
        }
        description={
          !hasPaidSession
            ? t('deleteAccount.confirmModal.description', { email })
            : t('deleteAccount.errorModal.description', { zendeskDefaultUrl })
        }
        w={420}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <RStack
          justify={{ base: undefined, md: 'end' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <RBox
            mr={{ base: undefined, md: 'xs' }}
            mb={{ base: 'sm', md: undefined }}
          >
            <Button
              fullWidth
              label={
                !hasPaidSession
                  ? t('deleteAccount.confirmModal.notDeleteBtn')
                  : t('common:close')
              }
              variant={!hasPaidSession ? 'ghost' : 'filled'}
              onClick={handleCloseModal}
            />
          </RBox>
          {!hasPaidSession && (
            <Button
              label={t('deleteAccount.confirmModal.deleteBtn')}
              disabled={loading}
              onClick={confirmDeleteAccount}
              colorVariant="destructive"
            />
          )}
        </RStack>
      </ModalAlert>
    </PageContainer>
  );
};
