import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  HeadingSize
} from '@unobravo-monorepo/common/components/Typography/Heading';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import { TypographyVariant } from '@unobravo-monorepo/common/components/Typography';
import { Theme } from '@unobravo-monorepo/common';
import imgGreen from '@unobravo-monorepo/patient/assets/img/image-green.svg';
import { ConfirmationScreen } from '../../../../shared/components/ConfirmationScreen';
import { usePatient } from '../../../patientData/hooks/usePatient';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Completed = () => {
  const { t } = useTranslation();
  const { prefForWhom } = usePatient();

  return (
    <ConfirmationScreen backgroundUrl={imgGreen}>
      <Heading
        margin="0 0 7px 0"
        size={HeadingSize.Title80}
        color={Theme.colors.spinach[500]}
      >
        {prefForWhom === 'DAUGHTER'
          ? t('legal:informedConsent.confirmation.titleMinor')
          : t('legal:informedConsent.confirmation.title')}
      </Heading>
      <TextContainer>
        <Body size={BodySize.Body70} color={Theme.colors.gray[800]}>
          {prefForWhom === 'DAUGHTER'
            ? t('legal:informedConsent.confirmation.bodyMinor')
            : t('legal:informedConsent.confirmation.body')}
        </Body>
        <Body size={BodySize.Body70} variant={TypographyVariant.Light}>
          {t('legal:informedConsent.confirmation.message')}
        </Body>
      </TextContainer>
    </ConfirmationScreen>
  );
};
