import React from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { Theme } from '../../theme';
import { ButtonSize, ButtonVariant, IButton } from '../../types/IButton';
import { IColorScale } from '../../types/ITheme';
import { TypographyVariant } from '../Typography';
import { Label, LabelSize } from '../Typography/Label';

const sizeMap: { [key in ButtonSize]: string } = {
  small: '42px',
  regular: '50px',
  large: '60px'
};

const paddingMap: { [key in ButtonSize]: string } = {
  small: '24px',
  regular: '28px',
  large: '28px'
};

interface IStyledButton {
  size: ButtonSize;
  fullWidth?: boolean;
  iconOnly?: boolean;
  colorScale: IColorScale;
  inverse?: boolean;
  highContrast?: boolean;
  isActive?: boolean;
}

const colorText = (color?: string) => {
  return color
    ? `
        color: ${color};
        fill: ${color};
        stroke: ${color};
      `
    : '';
};

const ButtonBase = styled.button<IStyledButton>`
  height: ${({ size }) => sizeMap[size]};
  padding: ${({ iconOnly, size }) =>
    iconOnly ? '0' : `0 ${paddingMap[size]}`};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth, iconOnly, size }) =>
    fullWidth ? '100%' : iconOnly ? sizeMap[size] : 'auto'};
  border-radius: 100px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px
      ${({ colorScale, highContrast }) => colorScale[highContrast ? 300 : 200]};
  }
  border: none;
  &:hover {
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;
  svg {
    margin: -2px;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  background: ${({ colorScale, inverse, highContrast }) =>
    inverse
      ? Theme.specialColors.white
      : colorScale.GRADIENT || colorScale[highContrast ? 600 : 500]};
  ${({ inverse }) => (inverse ? '' : 'transition: background-color 0s;')}
  ${({ colorScale, inverse, highContrast }) =>
    colorText(
      inverse ? colorScale[highContrast ? 600 : 500] : Theme.specialColors.white
    )};
  &:active {
    &:not(:disabled) {
      background: ${({ colorScale, inverse, highContrast }) =>
        inverse ? colorScale[50] : colorScale[highContrast ? 700 : 600]};
    }
  }
  &:disabled {
    background: ${({ colorScale, inverse, highContrast }) =>
      inverse
        ? 'rgba(255, 255, 255, 0.95)'
        : colorScale[highContrast ? 300 : 200]};
    ${({ colorScale, inverse, highContrast }) =>
      colorText(
        inverse
          ? colorScale[highContrast ? 100 : 200]
          : colorScale[highContrast ? 100 : 50]
      )};
  }
`;

const SecondaryButton = styled(ButtonBase)`
  background: transparent;
  border: 1.5px solid
    ${({ colorScale, inverse, highContrast }) =>
      inverse
        ? Theme.specialColors.white
        : colorScale[highContrast ? 600 : 500]};
  ${({ colorScale, inverse, highContrast }) =>
    colorText(
      inverse ? Theme.specialColors.white : colorScale[highContrast ? 600 : 500]
    )};
  &:active {
    &:not(:disabled) {
      border: 1.5px solid
        ${({ colorScale, inverse, highContrast }) =>
          inverse
            ? colorScale[highContrast ? 50 : 100]
            : colorScale[highContrast ? 700 : 600]};
      ${({ colorScale, inverse, highContrast }) =>
        colorText(
          inverse
            ? colorScale[highContrast ? 50 : 100]
            : colorScale[highContrast ? 700 : 600]
        )};
    }
  }
  &:disabled {
    border: 1.5px solid
      ${({ colorScale, inverse, highContrast }) =>
        inverse
          ? 'rgba(255, 255, 255, 0.58)'
          : colorScale[highContrast ? 300 : 200]};
    ${({ colorScale, inverse, highContrast }) =>
      colorText(
        inverse
          ? 'rgba(255, 255, 255, 0.58)'
          : colorScale[highContrast ? 300 : 200]
      )};
  }
`;

const GhostButton = styled(SecondaryButton)`
  border: none;
  &:disabled {
    border: none;
  }
  &${({ isActive }) => (isActive ? '' : ':hover')} {
    &:not(:disabled) {
      background: ${({ colorScale, inverse, highContrast }) =>
        inverse
          ? colorScale[highContrast ? 50 : 100]
          : colorScale[highContrast ? 100 : 50]};
      ${({ colorScale, highContrast }) =>
        colorText(colorScale[highContrast ? 600 : 500])};
    }
  }
  &:active {
    &:not(:disabled) {
      border: none;
      ${({ colorScale, highContrast }) =>
        colorText(colorScale[highContrast ? 700 : 600])};
    }
  }
`;

const variantComponents: {
  [key in ButtonVariant]: StyledComponent<
    'button',
    DefaultTheme,
    IStyledButton,
    never
  >;
} = {
  ghost: GhostButton,
  primary: PrimaryButton,
  secondary: SecondaryButton
};

const ButtonContent: React.FC<
  Pick<IButton, 'label' | 'icon' | 'iconPosition'>
> = (props) => {
  const { label, icon: Icon, iconPosition } = props;
  if (iconPosition === 'iconOnly' && Icon) {
    return <Icon width={24} height={24} color="inherit" />;
  }
  return (
    <>
      {iconPosition === 'left' && Icon && (
        <Icon width={24} height={24} color="inherit" />
      )}
      <Label
        size={LabelSize.Label100}
        variant={TypographyVariant.Bold}
        margin={`0 ${iconPosition === 'right' && Icon ? '8px' : '0'} 0 ${
          iconPosition === 'left' && Icon ? '8px' : '0'
        }`}
      >
        {label || ''}
      </Label>
      {iconPosition === 'right' && Icon && (
        <Icon width={24} height={24} color="inherit" />
      )}
    </>
  );
};

/**
 * @deprecated This component should not be used use Button from "@unobravo/zenit-web" instead.
 */
export const Button = React.forwardRef<HTMLButtonElement, IButton>(
  (props, ref) => {
    const {
      variant = 'primary',
      colorScale = Theme.colors.candy,
      inverse,
      label,
      size = 'regular',
      fullWidth,
      icon,
      iconPosition,
      children,
      highContrast,
      ...rest
    } = props;
    const ButtonComponent = variantComponents[variant];
    return (
      <ButtonComponent
        colorScale={colorScale}
        inverse={inverse}
        size={size}
        fullWidth={fullWidth}
        iconOnly={iconPosition === 'iconOnly'}
        aria-label={label}
        highContrast={highContrast}
        {...rest}
        ref={ref}
      >
        <ButtonContent label={label} icon={icon} iconPosition={iconPosition} />
      </ButtonComponent>
    );
  }
);

Button.displayName = 'Button';
