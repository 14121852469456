export interface ChatbotInitializeOptions {
  onChatbotLoaded: () => void;
  onOverlayLoaded: () => void;
}

/**
 * Getter function for window.IndigoAIChat
 * Implemented as a function as declaring a const was not enough
 * and was loading before the script was loaded.
 * @returns
 */
function indigo() {
  return window?.IndigoAIChat;
}

function getIndigoIframeDocument() {
  const iframe = document.querySelector(
    '#iaw-chat iframe'
  ) as HTMLIFrameElement;
  if (!iframe) return;

  // Access the iframe's contentDocument
  const iframeDocument = iframe.contentDocument;
  if (!iframeDocument) return;

  return iframeDocument;
}

/**
 * TODO:
 * Ask Indigo team if there is a easier way on their end to hide the minify icon
 * Initialize the chat styles to remove the minify icon in the chat (not in design)
 * @returns
 */
function initializeChatStyles() {
  // Access the iframe's contentDocument
  const iframeDocument = getIndigoIframeDocument();
  if (!iframeDocument) return;

  // Access the iaw-chat element within the contentDocument
  const iawChatElement = iframeDocument.querySelector(
    '.iaw-minimize-button'
  ) as HTMLDivElement;
  if (!iawChatElement) return;

  iawChatElement.style.display = 'none';
}

function hideCloseButton() {
  // Access the iframe's contentDocument
  const iframeDocument = getIndigoIframeDocument();
  if (!iframeDocument) return;

  // Access the iaw-chat element within the contentDocument
  const iawChatElement = iframeDocument.querySelector(
    '.iaw-close-button'
  ) as HTMLDivElement;
  if (!iawChatElement) return;

  iawChatElement.style.display = 'none';
}

function showCloseButton() {
  // Access the iframe's contentDocument
  const iframeDocument = getIndigoIframeDocument();
  if (!iframeDocument) return;

  // Access the iaw-chat element within the contentDocument
  const iawChatElement = iframeDocument.querySelector(
    '.iaw-close-button'
  ) as HTMLDivElement;
  if (!iawChatElement) return;

  iawChatElement.style.display = 'inline-flex';
}

/**
 * Open chat bot.
 */
function openChat() {
  return indigo()?.setOpen(true);
}

/**
 * Close chat bot.
 */
function closeChat() {
  return indigo()?.setOpen(false);
}

/**
 * PRIVATE function to render gray overlay
 */
function showOverlay() {
  const root = document.getElementById('root') as HTMLDivElement;
  const overlay = document.createElement('div');
  overlay.addEventListener('click', closeChat);
  overlay.classList.add('iaw-chat-overlay');
  root.appendChild(overlay);
  document.dispatchEvent(new CustomEvent('ub-chatbot-overlay-loaded'));
}

/**
 * PRIVATE function to hide gray overlay
 */
function hideOverlay() {
  const overlay = document.querySelector('.iaw-chat-overlay');
  if (!overlay) return;
  overlay.remove();
}

function disableOverlay() {
  const overlay = document.querySelector(
    '.iaw-chat-overlay'
  ) as HTMLElement | null;
  if (!overlay) return;
  overlay.removeEventListener('click', closeChat);
}

function enableOverlay() {
  const overlay = document.querySelector(
    '.iaw-chat-overlay'
  ) as HTMLElement | null;
  if (!overlay) return;
  overlay.addEventListener('click', closeChat);
}

/**
 * Indigo listener initializer
 * Will render the gray overlay when chat is open and vice versa.
 */
function initialize({
  onChatbotLoaded,
  onOverlayLoaded
}: ChatbotInitializeOptions) {
  const script = document.getElementById('indigo-script');
  if (!script) return;

  script.addEventListener('load', () => {
    initializeChatStyles();
    indigo()?.on('open', showOverlay);
    indigo()?.on('close', hideOverlay);

    document.addEventListener('widget-loaded', onChatbotLoaded);
    document.addEventListener('ub-chatbot-overlay-loaded', onOverlayLoaded);
  });
}

/**
 * useEffect cleanup function for Indigo script loader
 */
function loadScriptCleanup(
  script: HTMLScriptElement
): HTMLScriptElement | null {
  try {
    return document.body.removeChild(script);
  } catch {
    return null;
  }
}

/**
 * Loads Indigo html script
 */
function loadScript(scriptUrl: string) {
  const script = document.createElement('script');
  script.src = scriptUrl;
  script.id = 'indigo-script';
  document.body.appendChild(script);

  return {
    cleanup: () => loadScriptCleanup(script)
  };
}

export const Indigo = {
  initialize,
  hideCloseButton,
  showCloseButton,
  openChat,
  closeChat,
  disableOverlay,
  enableOverlay,
  loadScript,
  get: indigo
};
