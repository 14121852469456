import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setSessionStoredData, STORAGE_KEYS } from '../../utils/sessionStorage';

export const PageViewTracker = () => {
  const location = useLocation();
  const { pathname } = location;
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    window.dataLayer?.push({
      event: 'page',
      pageName: pathname
    });

    return () => {
      const t = timeout.current;
      // Delay to prevent automatic redirects to save
      if (!t) {
        timeout.current = setTimeout(() => {
          setSessionStoredData(STORAGE_KEYS.PREV_LOCATION, pathname);
          timeout.current = null;
        }, 100);
      }
    };
  }, [pathname]);

  return <></>;
};
