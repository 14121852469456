import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const CreditCard: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.moka[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.55853 9.76228L5.27153 8.69528C5.20292 8.44123 5.18515 8.17613 5.21923 7.9152C5.25331 7.65427 5.33858 7.40263 5.47013 7.17473C5.60169 6.94682 5.77695 6.74714 5.98586 6.58713C6.19477 6.42711 6.43322 6.30992 6.68753 6.24228L16.7305 3.56628C17.242 3.43216 17.7858 3.50521 18.2437 3.76958C18.7017 4.03394 19.0369 4.46827 19.1765 4.97828L20.9305 11.4873C20.999 11.7413 21.0167 12.0064 20.9825 12.2673C20.9484 12.5282 20.8631 12.7797 20.7316 13.0076C20.6 13.2355 20.4248 13.4352 20.216 13.5952C20.0071 13.7553 19.7688 13.8725 19.5145 13.9403L17.4005 14.5033"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00049 20.4993H15.4005C15.9309 20.4993 16.4396 20.2886 16.8147 19.9135C17.1898 19.5385 17.4005 19.0298 17.4005 18.4993V11.7623C17.4005 11.2319 17.1898 10.7232 16.8147 10.3481C16.4396 9.97304 15.9309 9.76233 15.4005 9.76233H5.00049C4.47006 9.76233 3.96135 9.97304 3.58627 10.3481C3.2112 10.7232 3.00049 11.2319 3.00049 11.7623V18.4993C3.00049 19.0298 3.2112 19.5385 3.58627 19.9135C3.96135 20.2886 4.47006 20.4993 5.00049 20.4993Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.00049 13.8392H17.4005"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
