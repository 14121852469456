export interface StorageConfig<T = unknown> {
  type: T;
  storage: Storage;
  defaultValue: T;
}

export const createOption = <T = void>(
  storage: Storage,
  defaultValue: T
): StorageConfig<T> =>
  ({ type: defaultValue, storage, defaultValue }) as StorageConfig<T>;
