import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { ApolloSignupV2Context } from '@unobravo-monorepo/common/hooks/useApolloSignupV2Client';
import { getAuthLink, getLink, useFirebaseUser } from '@unobravo/core';
import { useCountry } from '@unobravo/translations';
import { FC, ReactNode, useEffect, useState } from 'react';

const { NX_APP_APOLLO_URI_VISITOR_ACQUISITION: signupV2Uri } = process.env;

export const ApolloSignupV2Provider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const { domainCountry } = useCountry();
  const {
    currentUser: { unmappedUser }
  } = useFirebaseUser();

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const authLink = getAuthLink(unmappedUser, 'WEB', domainCountry);

    const link = getLink(signupV2Uri!);

    const apolloClient = new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache()
    });

    setClient(apolloClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unmappedUser]);

  return client ? (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ApolloSignupV2Context.Provider value={{ client }}>
      {children}
    </ApolloSignupV2Context.Provider>
  ) : (
    <></>
  );
};
