import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@unobravo/zenit-web';
import { useTheme } from '@unobravo/zenit-core';
import { Theme } from '../../theme';
import IInputField from '../../types/IInputField';
import { Body, BodySize } from '../Typography/Body';
import { Check, Error, Warning } from '../Icons';
import { TypographyVariant } from '../Typography';

const Wrapper = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<Pick<ITextInput, 'iconPosition'>>`
  position: absolute;
  right: ${({ iconPosition }) => (iconPosition === 'right' ? '20px' : '')};
  left: ${({ iconPosition }) => (iconPosition === 'left' ? '10px' : '')};
  svg {
    color: ${Theme.colors.gray[100]};
    display: block;
  }
`;

const Input = styled.input<
  Pick<ITextInput, 'variant' | 'icon' | 'iconPosition' | 'errorMessage'>
>`
  width: 100%;
  padding: ${({ variant }) =>
      ['rounded', 'small'].includes(variant!) ? '10px' : '14px'}
    16px;
  font-size: 1rem;
  font-weight: 400;
  font-family: ${Theme.fonts.secondary};
  line-height: 113%;
  letter-spacing: 0.01em;
  border-radius: ${({ variant }) =>
    variant?.includes('rounded') ? '25px' : '8px'};
  border: solid 1.5px
    ${({ variant }) =>
      Theme.colors[variant === 'rounded-faq' ? 'cappuccino' : 'gray'][200]};
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 0px ${Theme.colors.candy[200]};
  transition-timing-function: ease-out;
  transition: 0.3s;

  &:focus {
    outline: none;
    border: solid 1.5px ${Theme.colors.candy[500]};
    box-shadow: 0px 0px 0px 3px ${Theme.colors.candy[200]};
  }

  &::placeholder {
    color: ${({ variant }) =>
      variant === 'rounded-faq'
        ? Theme.colors.cappuccino[700]
        : Theme.colors.gray[300]};
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${({ variant }) =>
      variant === 'rounded-faq'
        ? Theme.colors.cappuccino[700]
        : Theme.colors.gray[300]};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:disabled {
    border: solid 1.5px ${Theme.colors.gray[100]};
    background-color: ${Theme.specialColors.white};
    color: ${Theme.colors.gray[200]};
    &::placeholder {
      color: ${Theme.colors.gray[100]};
    }
  }

  ${({ errorMessage }) =>
    errorMessage &&
    css`
      border: solid 1.5px ${Theme.colors.red[500]};
    `}

  ${({ icon, iconPosition }) =>
    !!icon &&
    css`
      padding-${iconPosition}: 40px;
    `}
`;

const InfoLabelWrapper = styled.div<{ withIcon: boolean }>`
  position: relative;
  margin-top: 4px;
  margin-left: ${({ withIcon }) => (withIcon ? '28px' : '8px')};
  display: flex;
  align-items: start;
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  max-height: 20px;
  left: -20px;
`;

type IInfoVariant = 'default' | 'success' | 'warning';
type IInfoPosition = 'left' | 'right';
interface ITextInput extends IInputField {
  variant?: 'default' | 'rounded' | 'rounded-faq' | 'small';
  infoVariant?: IInfoVariant;
  infoMessage?: string;
  infoPosition?: IInfoPosition;
  iconPosition?: 'left' | 'right';
}

type IInfoIcon = {
  [key in IInfoVariant]: JSX.Element | undefined;
};

const infoColor = {
  success: Theme.colors.edamame[500],
  warning: Theme.colors.ginger[600],
  error: Theme.colors.red[500],
  default: Theme.colors.gray[700]
};

const infoIcon: Partial<IInfoIcon> = {
  success: <Check width={16} height={18} color={Theme.colors.edamame[500]} />,
  warning: <Warning width={16} height={18} color={Theme.colors.ginger[600]} />
};

export const TextInput = forwardRef<HTMLInputElement, ITextInput>(
  ({ variant = 'default', iconPosition = 'right', ...props }, ref) => {
    const { infoVariant = 'default', autoComplete } = props;
    const { margin } = useTheme();

    return (
      <Wrapper>
        {props.label && (
          <Text
            fontWeight="medium"
            variant="md"
            style={{ marginBottom: `${margin.xs}px` }}
          >
            {props.label}
          </Text>
        )}
        <InputWrapper>
          <Input
            autoComplete={autoComplete}
            ref={ref}
            variant={variant}
            iconPosition={iconPosition}
            {...props}
          />

          {props.icon && (
            <IconWrapper iconPosition={iconPosition}>{props.icon}</IconWrapper>
          )}
        </InputWrapper>

        {(props?.errorMessage || props?.infoMessage) && (
          <InfoLabelWrapper
            withIcon={!!infoIcon?.[infoVariant] || !!props?.errorMessage}
          >
            {props.errorMessage && (
              <>
                <InfoIconWrapper>
                  <Error width={16} height={16} color={Theme.colors.red[500]} />
                </InfoIconWrapper>
                <Body
                  data-testid="error-message"
                  size={BodySize.Body60}
                  variant={TypographyVariant.Regular}
                  color={Theme.colors.red[500]}
                >
                  {props.errorMessage}
                </Body>
              </>
            )}
            {!props?.errorMessage && props?.infoMessage && (
              <>
                {!!infoIcon[infoVariant] && (
                  <InfoIconWrapper>{infoIcon[infoVariant]}</InfoIconWrapper>
                )}
                <Body
                  size={BodySize.Body60}
                  variant={TypographyVariant.Regular}
                  color={infoColor[infoVariant]}
                >
                  {props.infoMessage}
                </Body>
              </>
            )}
          </InfoLabelWrapper>
        )}
      </Wrapper>
    );
  }
);

TextInput.displayName = 'TextInput';
