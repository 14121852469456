import { useSubscription } from '@apollo/client';
import { IChatMessage } from '@unobravo/core';
import {
  appendMessage,
  openChat,
  receivedNewMessage,
  SUBSCRIPTION_MESSAGES
} from '@unobravo/patient';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useOnboardingStatus } from '../../home/hooks/useOnboardingStatus';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useChat } from '../hooks/useChat';

export const MessageListener = () => {
  const location = useLocation();
  const { id: patientId } = usePatient();
  const dispatch = useDispatch();
  const { refetchStatus } = useOnboardingStatus();

  const { messages } = useChat();

  const { data } = useSubscription(SUBSCRIPTION_MESSAGES, {
    variables: { patientId }
  });

  const getLastMessageFromStore = () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return messages?.[messages?.length - 1];
  };

  useEffect(() => {
    if (messages.length === 0) {
      return;
    }
    const lastMessageStore = getLastMessageFromStore();

    const hasNewMessageFromBackend =
      !lastMessageStore?.isMine && lastMessageStore?.readByOther === false;

    if (hasNewMessageFromBackend) {
      dispatch(receivedNewMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    const message: IChatMessage = data?.patientMessageReceived;

    if (message?.id) {
      messages.length > 0 &&
        !messages?.map(({ id }) => id).includes(message?.id) &&
        dispatch(appendMessage(message));
    }

    if (message?.session?.isFree) {
      refetchStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.patientMessageReceived?.id]);

  useEffect(() => {
    location.pathname.split('/').includes('chat') && dispatch(openChat());
  }, [location.pathname, dispatch]);

  return <></>;
};
