import { Box, Button, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import errorIllustrationImg from '@unobravo-monorepo/common/assets/ErrorIllustration.svg';

const Img = styled.img`
  width: 210px;
`;

interface IDownloadInvoice {
  state: 'notPaid' | 'canceled' | '';
}

export const EmptyState = ({ state }: IDownloadInvoice) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      h="100%"
      w="100%"
      align="center"
      justify="center"
      p="2xl"
    >
      <Img src={errorIllustrationImg} />
      <Box my="2xl">
        <Text
          variant="xl"
          fontWeight="bold"
          textAlign="center"
          data-testid="video-call-error-title"
        >
          {t('videoCall.error.title')}
        </Text>
        <Text variant="xl" textAlign="center">
          {state ? t(`videoCall.error.${state}`) : ''}
        </Text>
      </Box>
      <Button
        label={t('videoCall.error.button')}
        onClick={() => {
          navigate('/');
        }}
      />
    </Stack>
  );
};
