import { useBonusUtils } from '@unobravo/patient';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { usePatient } from './usePatient';

export const useBonus = () => {
  const { bonusPsicologo: featureFlagBonus } = usePatientFeatureFlags();
  const { isBpEnabled, matchWithBp, hasBpCreditsLeft } = usePatient();
  const {
    bonusData,
    totalAmount,
    amountLeft,
    leftAppointments,
    discountedSessions,
    discountedAmount,
    pricingAmount,
    loading,
    error,
    showAlert,
    refetch
  } = useBonusUtils(!isBpEnabled || !featureFlagBonus);

  return {
    ...bonusData,
    leftAppointments,
    discountedSessions,
    discountedAmount,
    loading,
    error,
    isBpEnabled,
    matchWithBp,
    hasBpCreditsLeft,
    showAlert,
    featureFlagBonus,
    refetch,
    totalAmount,
    amountLeft,
    pricingAmount
  };
};
