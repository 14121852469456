import { ArrowLeft } from '@unobravo/zenit-icons';
import {
  Button,
  Heading,
  ModalAlert,
  RStack,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { closeFeedbackModal, homeSelector } from '../../home.slice';
import { ProgressBar } from './ProgressBar';
import {
  feedbackSteps,
  surveysForSession
} from '../../utils/therapyFeedbackUtils';
import { useTherapyFeedback } from '../../hooks/useTherapyFeedback';
import {
  FeedbackData,
  FeedbackDataKeys
} from '../../types/therapyFeedback.types';

type FormFieldName =
  | 'relationWithDoctor'
  | 'doctorConcentration'
  | 'doctorContext';

export function TherapyFeedbackModal() {
  const { isFeedbackModalOpen } = useSelector(homeSelector);
  const [clickedAnswers, setClickedAnswers] = useState<{
    [key: number]: number;
  }>({});
  const [step, setStep] = useState<number>(1);
  const currentQuestion = feedbackSteps[step - 1];

  const { numberOfSessions, saveSurvey, saveSurveyLoading } =
    useTherapyFeedback();

  const pendoTrackFunction = (data: FeedbackData) => {
    const pendoSurveyData = (Object.keys(data) as FeedbackDataKeys[]).reduce(
      (acc, key) => {
        acc[key] = data[key].value;
        return acc;
      },
      {} as { [key in FeedbackDataKeys]: number }
    );
    pendoTrack('therapy-feedback-survey-completed', {
      surveyId: surveysForSession[numberOfSessions].surveyId,
      ...pendoSurveyData
    });
  };

  const { register, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      relationWithDoctor: {
        question: '',
        value: 0
      },
      doctorConcentration: {
        question: '',
        value: 0
      },
      doctorContext: {
        question: '',
        value: 0
      }
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const close = () => {
    setStep(1);
    setClickedAnswers({});
    reset();
    dispatch(closeFeedbackModal());
  };

  const onSubmitButton = async (data: FeedbackData) => {
    if (step === feedbackSteps.length) {
      await saveSurvey(data, surveysForSession[numberOfSessions].id);
      pendoTrackFunction(data);
      close();

      if (data.relationWithDoctor.value <= 3) {
        return navigate('../../dialog/home/reassignmentDiscovery', {
          state: { modal: location }
        });
      }

      return navigate('../../dialog/home/continueTherapy', {
        state: { modal: location }
      });
    }
    setStep(step + 1);
  };

  return (
    <ModalAlert
      open={isFeedbackModalOpen}
      onClose={close}
      dataTestIdCloseIcon="therapy-rating-dismiss"
    >
      <form onSubmit={handleSubmit(onSubmitButton)}>
        <Stack direction="column" align="start" pt="md">
          <Stack direction="column" pb="md" spacing="xs">
            <Heading variant="md">{t(currentQuestion.question)}</Heading>
            <Text color="grey.600">{t(currentQuestion.description)}</Text>
          </Stack>
        </Stack>
        <Stack pb="2xl" direction="column" w="100%" position="relative">
          <Stack
            direction="column"
            bgColor="cappuccino.50"
            p="md"
            rounded="xs"
            spacing="md"
          >
            <RStack w="100%" justify="space-between" h="100%">
              {currentQuestion.answers.map(
                ({ label: answerLabel, value: answerValue }) => (
                  <Button
                    {...register(currentQuestion.name as FormFieldName, {
                      value: {
                        value: clickedAnswers[step] || 0,
                        question: `${t(currentQuestion.question)}`
                      }
                    })}
                    size="lg"
                    name={currentQuestion.name}
                    iconPosition="iconOnly"
                    label={answerLabel}
                    value={answerValue}
                    type="button"
                    key={answerValue}
                    variant={
                      clickedAnswers[step] === answerValue
                        ? 'filled'
                        : 'outlined'
                    }
                    onClick={() => {
                      setClickedAnswers((prev) => ({
                        ...prev,
                        [step]: answerValue
                      }));
                    }}
                  />
                )
              )}
            </RStack>
            <Stack justify="space-between">
              <Text
                textAlign="left"
                color="grey.700"
                variant="sm"
                style={{ width: '20%' }}
              >
                {t('feedbackModal.notAgree')}
              </Text>
              <Text
                textAlign="right"
                color="grey.700"
                variant="sm"
                style={{ width: '20%' }}
              >
                {t('feedbackModal.agree')}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" w="100%">
          <Stack position="absolute" left={0} right={0}>
            <ProgressBar
              progressPercentage={Math.round(
                (step / feedbackSteps.length) * 100
              )}
            />
          </Stack>
          <RStack
            direction="row"
            justify="end"
            spacing="xs"
            pt="lg"
            pb={{ base: '2xl', md: undefined }}
            align="start"
            alignContent="start"
            w="100%"
          >
            {step > 1 && (
              <Button
                iconPosition="iconOnly"
                icon={ArrowLeft}
                size="md"
                variant="ghost"
                data-testid={`${currentQuestion.backTestId}`}
                type="button"
                onClick={() => {
                  setStep(step - 1);
                }}
              />
            )}
            <Button
              variant={step === feedbackSteps.length ? 'filled' : 'tonal'}
              label={t(`${currentQuestion.buttonLabel}`)}
              data-testid={`${currentQuestion.nextTestId}`}
              type="submit"
              fullWidth
              disabled={!clickedAnswers[step]}
              loading={saveSurveyLoading}
            />
          </RStack>
        </Stack>
      </form>
    </ModalAlert>
  );
}
