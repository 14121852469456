import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Email: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0191 19.0002H4.98081C4.44893 19.0002 3.93883 18.7889 3.56273 18.4128C3.18663 18.0367 2.97534 17.5266 2.97534 16.9947V7.00547C2.97534 6.47358 3.18663 5.96349 3.56273 5.58739C3.93883 5.21129 4.44893 5 4.98081 5H19.0191C19.551 5 20.0611 5.21129 20.4371 5.58739C20.8132 5.96349 21.0245 6.47358 21.0245 7.00547V16.9957C21.0243 17.5274 20.8129 18.0372 20.4368 18.4131C20.0607 18.789 19.5508 19.0002 19.0191 19.0002V19.0002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0138 8.9917L12.0001 11.9999L6.98645 8.9917"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
