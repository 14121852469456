import React, { useState, ReactNode } from 'react';
import { ErrorPage } from '../../components/errorPage';
import { IErrorPageProps } from '../../types/IErrorPageProps';

type ErrorPageContext = {
  setGlobalError: React.Dispatch<React.SetStateAction<boolean>>;
};

const contextDefaultValues: ErrorPageContext = {
  setGlobalError: () => null
};

export const ErrorPageContext =
  React.createContext<ErrorPageContext>(contextDefaultValues);

export const ErrorPageProvider: React.FC<{
  children: ReactNode;
  errorPageProps: Omit<IErrorPageProps, 'backHome' | 'customRedirect'>;
}> = ({ children, errorPageProps }) => {
  const [globalError, setGlobalError] = useState(false);

  return (
    <ErrorPageContext.Provider value={{ setGlobalError }}>
      {globalError ? <ErrorPage {...errorPageProps} /> : children}
    </ErrorPageContext.Provider>
  );
};
