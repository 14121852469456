import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@unobravo/zenit-web';
import { Step } from './Step';
import { StepStatus } from '../../../types/IStep';

interface IModalStepperProps {
  stepsLabels:
    | false
    | {
        status: StepStatus;
        id: string;
        labelI18nKey: string;
      }[];
}

export const ModalStepper: FC<IModalStepperProps> = ({ stepsLabels }) => {
  const { t } = useTranslation();

  if (!stepsLabels) {
    return null;
  }

  return (
    <Stack spacing="xl">
      {stepsLabels.map(({ id, labelI18nKey, status }, index) => (
        <Step
          index={index + 1}
          status={status}
          label={t(labelI18nKey)}
          isLast={index === stepsLabels.length - 1}
          key={id}
        />
      ))}
    </Stack>
  );
};
