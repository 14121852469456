import { Theme } from '@unobravo-monorepo/common';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import noCardsImg from '@unobravo-monorepo/patient/assets/img/no_cards.svg';
import pagamentoImg from '@unobravo-monorepo/patient/assets/pagamento.svg';
import { Box } from '@unobravo/zenit-web';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePatientCards } from '../../patientData/hooks/usePatientCards';
import { PaymentMethodComp } from '../../payment/components/PaymentMethodComp';
import { StripeDisclaimer } from '../../payment/components/StripeDisclaimer';
import { RightWrapperIllustration } from '../components/RightWrapperIllustration';
import { SectionHeader } from '../components/SectionHeader';

const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CenteredText = styled.span`
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const FlexContainer = styled.div`
  margin-top: 12px;
`;

const MarginContainer = styled.div`
  margin-top: 40px;
`;

const MaxWidthContainer = styled(MarginContainer)`
  max-width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 16px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LinkDiv = styled.div`
  text-decoration: none;
  color: ${Theme.colors.candy[500]};
  outline: none;
  cursor: pointer;
  display: inline-block;
  border: 2px solid transparent;
  margin: -2px;
  :visited {
    color: ${Theme.colors.candy[600]};
  }
  :focus-visible {
    border: 2px solid ${Theme.colors.candy[200]};
  }
`;

const Img = styled.img`
  display: block;
  margin: 0 auto;
`;

const WidthContainer = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-grow: 1;
  position: relative;
  gap: 48px;
`;

interface IPaymentPage {
  context?: 'MOBILE' | 'WEBAPP';
}

export const Payment: FC<IPaymentPage> = ({ context }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    cards,
    sepaDebits,
    refreshPatientCards,
    removeCard,
    loading: cardsLoading
  } = usePatientCards();
  const location = useLocation();

  const locationState = location?.state as {
    modal?: { pathname?: string };
    reload: boolean;
  };
  const modalState = locationState?.modal;

  const isMobileApp = context === 'MOBILE';

  useEffect(() => {
    if (locationState?.reload) {
      refreshPatientCards();
      navigate(location.pathname, {
        replace: true,
        state: { modal: modalState }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  return (
    <PageContainer>
      {!isMobileApp && (
        <SectionHeader
          path="../"
          title={`${t('profileAndSettings.paymentMethodLabel')}`}
        />
      )}
      <Container>
        <Wrapper>
          {cards && sepaDebits && <Box data-testid="cards-loaded" />}
          <WidthContainer>
            {(!cards || !sepaDebits || cardsLoading) && <Spinner />}
            {cards && sepaDebits && (
              <>
                {cards.length > 0 && (
                  <div>
                    <Heading size={HeadingSize.Title80} margin="4px 0px">
                      {t('paymentMethod.savedCards')}
                    </Heading>
                    <Body
                      size={BodySize.Body80}
                      margin="0 0 16px 0px"
                      color={Theme.colors.gray[800]}
                    >
                      {t('paymentMethod.infoSection')}
                    </Body>
                    {cards.map((c) => (
                      <PaymentMethodComp
                        key={c.cardId}
                        paymentMethod={c}
                        onDeleteSuccess={() => removeCard(c.cardId)}
                      />
                    ))}
                    <FlexContainer>
                      <Body
                        size={BodySize.Body70}
                        color={Theme.colors.gray[600]}
                        element="span"
                      >
                        {t('profileAndSettings.addNewCard')}
                      </Body>
                      &nbsp;
                      <LinkDiv onClick={() => navigate('./addCard')}>
                        <Body
                          size={BodySize.Body70}
                          variant={TypographyVariant.SemiBold}
                          color={Theme.colors.candy[500]}
                          element="span"
                          data-testid="link-add-another-card"
                        >
                          {t('profileAndSettings.clickHere')}
                        </Body>
                      </LinkDiv>
                    </FlexContainer>
                  </div>
                )}
                {cards.length === 0 && sepaDebits.length > 0 && (
                  <div>
                    <Heading size={HeadingSize.Title80} margin="4px 0px">
                      {t('paymentMethod.savedCards')}
                    </Heading>
                    <Body
                      size={BodySize.Body80}
                      margin="0 0 16px 0px"
                      color={Theme.colors.gray[800]}
                      element="span"
                    >
                      {t('profileAndSettings.noSavedCards')}
                    </Body>
                    &nbsp;
                    <LinkDiv onClick={() => navigate('./addCard')}>
                      <Body
                        size={BodySize.Body80}
                        margin="0 0 12px 0px"
                        color={Theme.colors.candy[500]}
                        element="span"
                      >
                        {t('profileAndSettings.clickHere')}
                      </Body>
                    </LinkDiv>
                  </div>
                )}
                {sepaDebits.length > 0 && (
                  <div data-testid="sepa-detail">
                    <Heading size={HeadingSize.Title80} margin="4px 0px">
                      {t('paymentMethod.savedSepa')}
                    </Heading>
                    <Body
                      size={BodySize.Body80}
                      margin="0 0 12px 0px"
                      color={Theme.colors.gray[800]}
                    >
                      {t('paymentMethod.infoSepaSection')}
                    </Body>
                    {sepaDebits.map((s) => (
                      <PaymentMethodComp
                        key={s.sepaId}
                        paymentMethod={s}
                        onDeleteSuccess={() => removeCard(s.sepaId)}
                      />
                    ))}
                  </div>
                )}
                {cards.length === 0 && sepaDebits.length === 0 && (
                  <div data-testid="card-detail">
                    <Heading size={HeadingSize.Title80} margin="4px 0px">
                      {t('profileAndSettings.emptyStateTitle')}
                    </Heading>
                    <Body
                      size={BodySize.Body80}
                      margin="0 0 12px 0px"
                      color={Theme.colors.gray[800]}
                    >
                      {t('profileAndSettings.emptyStateDescription')}
                    </Body>
                    <ColumnContainer>
                      <MaxWidthContainer>
                        <Img src={noCardsImg} alt="" />
                        <CenteredText>
                          <Body
                            size={BodySize.Body80}
                            color={Theme.colors.gray[600]}
                            element="span"
                            data-testid="empty-state"
                          >
                            {t('profileAndSettings.noPaymentMethods')}
                          </Body>
                          &nbsp;
                          <LinkDiv onClick={() => navigate('./addCard')}>
                            <Body
                              data-testid="link-add-card"
                              size={BodySize.Body80}
                              color={Theme.colors.candy[500]}
                              element="span"
                            >
                              {t('profileAndSettings.clickHere')}
                            </Body>
                          </LinkDiv>
                        </CenteredText>
                      </MaxWidthContainer>
                    </ColumnContainer>
                  </div>
                )}
              </>
            )}
            <StripeDisclaimer noStripeLink={isMobileApp} />
          </WidthContainer>
        </Wrapper>
        {!!cards?.length && (
          <RightWrapperIllustration illustration={pagamentoImg} />
        )}
      </Container>
    </PageContainer>
  );
};
