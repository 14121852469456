import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ModalAlert, Stack, Text } from '@unobravo/zenit-web';

interface IChangeEmailModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  newEmail: string;
}

export const ChangeEmailModal: React.FC<IChangeEmailModal> = ({
  isModalOpen,
  setIsModalOpen,
  newEmail
}) => {
  const { t } = useTranslation();

  return (
    <ModalAlert
      open={isModalOpen}
      title={t('changeEmail.modal.title')}
      onClose={() => setIsModalOpen(false)}
      onConfirmClick={() => setIsModalOpen(false)}
      confirmButtonLabel={t('common:close')}
    >
      <Stack direction="column">
        <Box mt="2xs">
          <Text color="grey.600">
            {t('changeEmail.modal.description1', { newEmail })}
          </Text>
        </Box>
        <Box mt="md">
          <Text color="grey.600">{t('changeEmail.modal.description2')}</Text>
        </Box>
      </Stack>
    </ModalAlert>
  );
};
