import { useDispatch, useSelector } from 'react-redux';
import { IToast } from '@unobravo-monorepo/common/types/IToast';
import {
  addToast,
  removeToast,
  toasterSelector,
  setHover,
  checkAndRemove
} from '../toaster.slice';

const defaultToastValues: IToast = {
  title: '',
  description: '',
  variant: 'success',
  id: '',
  transparent: true
};

interface UseToastProps {
  bottomOffset?: number;
}

export const useToast = ({ bottomOffset }: UseToastProps = {}) => {
  const dispatch = useDispatch();
  const { toasts } = useSelector(toasterSelector);

  const closeToast = (id: string | number) => {
    dispatch(removeToast(id));
  };

  const dismissToast = (id: string | number, duration: number) => {
    setTimeout(() => dispatch(checkAndRemove(id)), duration);
  };

  const sendToast = (
    toast: Partial<IToast>,
    customId?: string | number,
    duration?: number | 'persistent'
  ) => {
    const toastId = customId || toasts.length;
    dispatch(
      addToast({
        ...defaultToastValues,
        id: toastId,
        ...toast,
        bottomOffset
      })
    );
    duration !== 'persistent' && dismissToast(toastId, duration || 4000);
  };

  const setHoverToast = (id?: string | number) => {
    dispatch(setHover(id));
  };

  return { toasts, sendToast, closeToast, dismissToast, setHoverToast };
};
