import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export const MailActivationGuard: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  const { currentUser } = useFirebaseUser();
  const { hasEmailVerified: userHasEmailVerified } = currentUser;

  if (userHasEmailVerified) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};
