import { Button, Heading, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { TranslationsProvider, useCountry } from '@unobravo/translations';
import { useFirebaseUser } from '@unobravo/core';
import { Modal } from '../Modal';

const platforms = {
  IT: 'Unobravo',
  ES: 'Unobravo Spain',
  FR: 'Unobravo France'
};

const platformsUrl = {
  IT: process.env['NX_APP_DOMAIN_URL'],
  ES: process.env['NX_APP_ES_DOMAIN_URL'],
  FR: process.env['NX_APP_FR_DOMAIN_URL']
};

type PlatformCountry = keyof typeof platforms;

const ModalContent = () => {
  const { t } = useTranslation();
  const {
    logout,
    currentUser: { platformCountry }
  } = useFirebaseUser();

  const platform = platforms[platformCountry as PlatformCountry];
  const platformUrl = platformsUrl[platformCountry as PlatformCountry];

  const redirect = () => {
    window.location.href = `https://${platformUrl}/login`;
  };

  return (
    <Stack
      direction="column"
      mt="2xs"
      spacing="xs"
      data-testid="country-check-modal-content"
    >
      <Heading variant="md" fontWeight="bold">
        {t('common:wrongCountry.title', { platform })}
      </Heading>

      <Text variant="md" color="grey.600">
        {t('common:wrongCountry.description', {
          platform
        })}
      </Text>

      <Stack direction="row" justify="end" spacing="xs" mt="2xl">
        <Button
          variant="ghost"
          label={t('common:wrongCountry.cancel')}
          onClick={logout}
        />
        <Button
          label={t('common:wrongCountry.cta', {
            platform
          })}
          onClick={redirect}
        />
      </Stack>
    </Stack>
  );
};

export const CountryCheckModal = () => {
  const { domainCountry } = useCountry();
  const { currentUser, loaded } = useFirebaseUser();

  const currentCountry = domainCountry.toLocaleUpperCase();
  const { platformCountry } = currentUser;

  if (
    !currentUser.email ||
    !loaded ||
    currentUser.isAnonymous ||
    !platformCountry
  ) {
    return <></>;
  }

  return (
    <TranslationsProvider namespaces={[]}>
      <Modal
        open={currentCountry !== platformCountry}
        fixedHeight={false}
        width={376}
      >
        <ModalContent data-testid="country-check-modal" />
      </Modal>
    </TranslationsProvider>
  );
};
