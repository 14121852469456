import {
  Alignment,
  Layout as RiveLayout,
  useRive
} from '@rive-app/react-canvas';
import { useTheme } from '@unobravo/zenit-core';
import {
  Box,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import animation from '@unobravo-monorepo/patient/assets/animation/no_doctor_match.riv';

type Label = {
  id: string;
  text: string;
  marginBottom?: number;
};

export function NoDoctorMatch() {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const { margin } = useTheme();

  const { RiveComponent: NoMatchAnimation } = useRive({
    src: animation,
    stateMachines: 'No match animation',
    autoplay: true,
    layout: new RiveLayout({
      alignment: Alignment.Center
    })
  });

  const labels: Label[] = [
    {
      id: 'firstPart',
      text: t('chat.noMatch.paragraph1'),
      marginBottom: !isMobile ? margin.xs : undefined
    },
    {
      id: 'secondPart',
      text: t('chat.noMatch.paragraph2')
    }
  ];

  return (
    <Stack
      direction="column"
      maxW={!isMobile ? '400px' : undefined}
      px={isMobile ? '2xl' : undefined}
      spacing="xs"
      style={{
        alignSelf: 'center',
        marginTop: !isMobile ? '5%' : undefined
      }}
    >
      <Box w="300px" h="300px" style={{ alignSelf: 'center' }}>
        <NoMatchAnimation />
      </Box>

      <Heading textAlign="center" variant="xl" color="grey.900">
        {t('chat.noMatch.title')}
      </Heading>

      {labels.map((label) => (
        <Text
          textAlign="center"
          variant="lg"
          fontWeight="normal"
          color="grey.500"
          key={label.id}
          style={{
            marginBottom: label.marginBottom
          }}
        >
          {label.text}
        </Text>
      ))}
    </Stack>
  );
}
