import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  appointmentsReducer,
  billingInfoReducer,
  chatReducer,
  patientReducer
} from '@unobravo/patient';
import paySessionDataReducer from './features/newPaySession/paySessionData.slice';
import toasterReducer from './features/toaster/toaster.slice';
import layoutReducer from './features/layout/layout.slice';
import chatbotReducer from './features/chatbot/chatbot.slice';
import therapySurveyConsentReducer from './features/therapySurveyConsentModal/therapySurveyConsent.slice';
import vouchersReducer from './features/vouchers/vouchers.slice';
import reassignmentReducer from './features/reassignment/reassignment.slice';
import homeReducer from './features/home/home.slice';
import therapySettingReducer from './features/therapySetting/store/therapySetting.slice';

export const combinedReducer = combineReducers({
  patient: patientReducer,
  chat: chatReducer,
  chatbot: chatbotReducer,
  toaster: toasterReducer,
  appointments: appointmentsReducer,
  billingInfo: billingInfoReducer,
  paySessionData: paySessionDataReducer,
  layout: layoutReducer,
  therapySurveyConsent: therapySurveyConsentReducer,
  vouchers: vouchersReducer,
  reassignment: reassignmentReducer,
  home: homeReducer,
  therapySetting: therapySettingReducer
});

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: Action
) => {
  if (action.type === 'LOGOUT') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export type PatientRootState = ReturnType<typeof store.getState>;
export type PatientAppDispatch = typeof store.dispatch;
