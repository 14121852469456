import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useABTest } from '@unobravo-monorepo/common/hooks/useABTest';
import { TouchPointLayout } from '@unobravo-monorepo/common/layouts/touchpoint';
import { ErrorPageContext } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import { ActiveABTests } from '@unobravo-monorepo/common/types/ab-testing';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MatchAlgorithmVersion } from '../../../common/generated/common.generated';
import { useActivation } from '../hooks/useActivation';

export const VerifyEmail: React.FC = () => {
  const { code } = useParams();
  const { verify: confirmUser } = useActivation();
  const { setGlobalError } = useContext(ErrorPageContext);
  const navigate = useNavigate();

  // k01Drop = 'treatment' means that the user is part of the K01-Drop AB test (new matching algo)
  const [, selectYourTp, others] = useABTest(
    ActiveABTests.selectYourTp,
    ActiveABTests.k01Drop
  );

  let algorithmVersion: MatchAlgorithmVersion | undefined;

  const isK01DropLoaded = !!others[ActiveABTests.k01Drop];
  const isPreMatchLoaded = !!selectYourTp;

  const isK01Drop = others[ActiveABTests.k01Drop]?.value === 'treatment';
  const isK01DropV2 = others[ActiveABTests.k01Drop]?.value === 'treatment-2';
  const isPreMatch = selectYourTp?.value === 'treatment';

  if (isK01Drop) {
    algorithmVersion = MatchAlgorithmVersion.TopDropPerformer;
  } else if (isK01DropV2) {
    algorithmVersion = MatchAlgorithmVersion.SeverityClusters;
  } else if (isPreMatch) {
    algorithmVersion = MatchAlgorithmVersion.PreMatch;
  }

  const verify = async () => {
    if (!code) {
      return navigate('/');
    }

    const {
      verified,
      isTokenExpired,
      hasErrors,
      userAlreadyVerified,
      oneMatch
    } = await confirmUser(code, algorithmVersion);

    if (verified || userAlreadyVerified) {
      if (isPreMatch && !isK01Drop && !oneMatch)
        return navigate('/patient/select-therapist');

      return navigate('/patient');
    }

    if (isTokenExpired) return navigate('/activation/expired');

    if (hasErrors) return setGlobalError(true);

    return navigate('/');
  };

  useEffect(() => {
    const loaded = isK01DropLoaded && isPreMatchLoaded;

    const t = setTimeout(verify, loaded ? 0 : 600);

    return () => clearTimeout(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algorithmVersion]);

  return (
    <TouchPointLayout topLeft="" bottomRight="">
      <Spinner />
    </TouchPointLayout>
  );
};
