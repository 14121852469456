import {
  getMessageDate as convertMessageDate,
  getSessionDate as convertSessionDate,
  getRawDateFromIso as convertFromIsoRaw,
  getRawDateFromMillis as convertFromMillisRaw
} from '@unobravo/core';
import { getLocale } from '@unobravo/translations';
import { DateTime } from 'luxon';

const { NX_APP_DEFAULT_TIMEZONE, NX_APP_DEFAULT_LOCATE } = process.env;
const defaultLocale = NX_APP_DEFAULT_LOCATE || 'it-IT';

export const getMessageDate = (date: string, timeZone?: string | null) =>
  convertMessageDate(
    date,
    timeZone || NX_APP_DEFAULT_TIMEZONE,
    getLocale() ?? defaultLocale
  );

export const getSessionDate = (
  date: string,
  durationMinutes: string,
  timeZone?: string
) =>
  convertSessionDate(
    date,
    durationMinutes,
    timeZone || NX_APP_DEFAULT_TIMEZONE,
    getLocale() ?? defaultLocale
  );

export const getRawDateFromIso = (date: string, timeZone?: string) =>
  convertFromIsoRaw(date, timeZone || NX_APP_DEFAULT_TIMEZONE).setLocale(
    getLocale() ?? defaultLocale
  );

export const getRawDateFromMillis = (date: number, timeZone?: string) =>
  convertFromMillisRaw(date, timeZone || NX_APP_DEFAULT_TIMEZONE).setLocale(
    getLocale() ?? defaultLocale
  );

export const convertFromIso = (date: string, timezone?: string) =>
  convertFromIsoRaw(date, timezone).setLocale(getLocale() ?? defaultLocale);

export const convertFromMillis = (date: number, timezone?: string) =>
  convertFromMillisRaw(date, timezone).setLocale(getLocale() ?? defaultLocale);

export const getTimezoneFromCountry = () => {
  switch (getLocale()) {
    case 'it':
      return 'Europe/Rome';
    case 'es':
      return 'Europe/Madrid';
    default:
      return 'Europe/Rome';
  }
};

export {
  getToday,
  getMonthsLabel,
  sortByDate,
  getSimpleDate
} from '@unobravo/core';

export const isDateBeforeToday = (rawDate: string) => {
  const date = DateTime.fromISO(rawDate);
  const today = DateTime.now();
  return date < today;
};

export const convertDateFormat = (dateString: string, reverse?: boolean) => {
  const rawDatePattern = 'yyyy-MM-dd';
  const prettyDatePattern = 'dd/MM/yyyy';
  const date = DateTime.fromFormat(
    dateString,
    reverse ? prettyDatePattern : rawDatePattern
  );
  const formattedDate = date.toFormat(
    reverse ? rawDatePattern : prettyDatePattern
  );

  return formattedDate;
};

export const formatDate = (date: Date) => {
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.toFormat('yyyy-MM-dd');
};
