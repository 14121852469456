import { Button, Stack, Text, Box } from '@unobravo/zenit-web';

import { Color } from '@unobravo/zenit-core';
import {
  Form,
  FormTextarea,
  useForm,
  z,
  zodResolver
} from '@unobravo/zenit-form';
import { Calendar, CalendarCheck, TimeOver } from '@unobravo/zenit-icons';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import styled from 'styled-components';

const ProposalTextContainer = styled(Box)`
  word-break: break-all;
`;

const variantsMap: Record<
  ModalVariant,
  {
    icon: ReactNode;
    title: string;
    subtitle: string;
    bgColor: Color;
    textColor: Color;
  }
> = {
  UPDATE: {
    icon: <Calendar color="candy.500" width="32px" height="32px" />,
    title: 'modifyAppointment.update.title',
    subtitle: 'modifyAppointment.update.subtitle',
    bgColor: 'candy.50',
    textColor: 'grey.600'
  },
  SENT: {
    icon: <CalendarCheck color="spinach.600" width="32px" height="32px" />,
    title: 'modifyAppointment.sent.title',
    subtitle: 'modifyAppointment.sent.subtitle',
    bgColor: 'spinach.50',
    textColor: 'spinach.600'
  },
  IN_PROGRESS: {
    icon: <TimeOver color="ginger.600" width="32px" height="32px" />,
    title: 'modifyAppointment.in_progress.title',
    subtitle: 'modifyAppointment.in_progress.subtitle',
    bgColor: 'ginger.50',
    textColor: 'ginger.600'
  }
};

const MAX_CHARS = 200;

export const formSchema = z.object({
  description: z
    .string()
    .min(1, { message: '' })
    .max(MAX_CHARS, { message: '' })
});

export type ModalVariant = 'UPDATE' | 'SENT' | 'IN_PROGRESS';

interface IModalBody {
  modalVariant: ModalVariant;
  tpName: string;
  onSubmitValues: (values: z.infer<typeof formSchema>) => Promise<void>;
  isLoading: boolean;
  proposalText?: string;
}

export const ModalBody: React.FC<IModalBody> = ({
  modalVariant,
  tpName,
  onSubmitValues,
  isLoading,
  proposalText
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: ''
    }
  });
  const { t } = useTranslation();

  const isSubmitActive = form.formState?.isValid;
  const charsDiff = MAX_CHARS - form.watch('description').length;

  return (
    <Stack direction="column" spacing="sm">
      <Stack
        rounded="full"
        bgColor={variantsMap[modalVariant].bgColor as Color}
        align="center"
        justify="center"
        w="48px"
        h="48px"
      >
        {variantsMap[modalVariant].icon}
      </Stack>
      <Text fontWeight="semibold" color="grey.800" variant="xl">
        {t(variantsMap[modalVariant].title, { tpName })}
      </Text>
      <Text color="grey.600" variant="md">
        {t(variantsMap[modalVariant].subtitle)}
      </Text>

      {/** BODY - based on variants */}
      {modalVariant === 'UPDATE' ? (
        <Stack direction="column">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmitValues)}>
              <Stack direction="column" spacing="xs" mb="2xl">
                <FormTextarea
                  name="description"
                  placeholder={t('modifyAppointment.textArea.placeholder')}
                  // TODO: we should update the zenit Textarea component with `w` && `h` props
                  style={{ height: '160px' }}
                  maxLength={MAX_CHARS}
                  disabled={isLoading}
                />
                <Text
                  variant="sm"
                  color={charsDiff >= 0 ? 'grey.600' : 'candy.500'}
                >
                  {charsDiff >= 0
                    ? charsDiff
                    : t('modifyAppointment.textArea.maxChars')}
                </Text>
              </Stack>

              <Button
                fullWidth
                data-testid="pt-submit-modify-appointment"
                label={t('modifyAppointment.textArea.submit')}
                type="submit"
                disabled={!isSubmitActive}
                loading={isLoading}
              />
            </form>
          </Form>
        </Stack>
      ) : (
        <Stack
          data-testid="modify-appointment-modal-card-header"
          direction="column"
          spacing="sm"
          rounded="lg"
          bgColor={variantsMap[modalVariant].bgColor as Color}
          mt="xl"
          p="sm"
          pb="2xl"
        >
          <Text
            color={variantsMap[modalVariant].textColor as Color}
            variant="sm"
            fontWeight="bold"
          >
            {t('modifyAppointment.proposal.title')}
          </Text>
          <ProposalTextContainer>
            <Text color="grey.900" variant="md">
              “{proposalText}”
            </Text>
          </ProposalTextContainer>
        </Stack>
      )}
    </Stack>
  );
};
