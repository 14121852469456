import { ITranslatedSessionDate } from '@unobravo/core';
import { Calendar, Clock } from '@unobravo/zenit-icons';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

export const DateContainer = ({
  sessionDate
}: {
  sessionDate: ITranslatedSessionDate;
}) => {
  const { t } = useTranslation();
  const { weekDay, day, month, startHour, endHour, year } = sessionDate;
  const numberDay = day.toString();
  const text = `${weekDay} ${numberDay}`;
  const hour = `${startHour}/${endHour}`;

  return (
    <Stack justify="space-between" p="md">
      <Stack spacing="sm" align="center">
        <Calendar color="candy.500" />
        <Box>
          <Text
            variant="lg"
            fontWeight="semibold"
            style={{
              textTransform: 'capitalize'
            }}
          >
            {text}
          </Text>
          <Text
            variant="sm"
            style={{
              textTransform: 'capitalize'
            }}
          >
            {`${month} ${year}`}
          </Text>
        </Box>
      </Stack>
      <Stack spacing="sm" align="center">
        <Clock color="candy.500" />
        <Box>
          <Text
            variant="lg"
            fontWeight="semibold"
            style={{
              textTransform: 'capitalize'
            }}
          >
            {hour}
          </Text>
          <Text variant="sm">{t('paySession.appointmentRecap.timeZone')}</Text>
        </Box>
      </Stack>
    </Stack>
  );
};
