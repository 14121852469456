import { useBillingInfoV2 } from '@unobravo/patient';
import { Alert, Box, Button, Skeleton, Stack, Text } from '@unobravo/zenit-web';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EditBillingInfoCard } from './EditBillingInfoCard';
import { StripeBuyButton } from './StripeBuyButton';
import { CreateStripeCustomerSessionDocument } from '../../graphql/mutations.generated';
import { IBundlePriceDetails } from '../../../../shared/components/Bundles/bundle-prices-map';

const Divider = () => (
  <Box
    borderColor="grey.50"
    borderTop="md"
    w="100%"
    style={{ alignSelf: 'center' }}
  />
);

export const Summary = ({
  onBack,
  onEditBillingInfo,
  bundleInfo,
  patientId
}: {
  onBack: () => void;
  onEditBillingInfo: () => void;
  bundleInfo: IBundlePriceDetails;
  patientId: number;
}) => {
  const { billingInfoData: billingInfo } = useBillingInfoV2(patientId);
  const { t } = useTranslation();
  const {
    name: billingName,
    surname: billingSurname,
    address: billingAddress,
    city: billingCity,
    country: billingCountry,
    cf: billingCf,
    province: billingProvince,
    cap: billingCap
  } = billingInfo ?? {};

  const [mutate, { data }] = useMutation(CreateStripeCustomerSessionDocument, {
    variables: { patientId }
  });

  useEffect(() => {
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="column" p="lg" spacing="lg">
      <Text color="grey.900" variant="2xl" fontWeight="bold">
        {t('bundles.paywall.summary.title')}
      </Text>
      <Stack direction="column" color="grey.900" spacing="sm">
        <Text fontWeight="bold">{t('bundles.paywall.summary.order')}</Text>
        <Stack justify="space-between" spacing="sm" w="100%" color="grey.800">
          <Text color="grey.800">{t('bundles.paywall.summary.type')}</Text>
          <Text color="grey.800">
            {t('bundles.paywall.summary.numSessions', {
              numSessions: bundleInfo.numSessions
            })}
          </Text>
        </Stack>
        <Stack justify="space-between" spacing="sm" w="100%">
          <Text color="grey.800">{t('bundles.paywall.summary.expire')}</Text>
          <Text color="grey.800">
            {t('bundles.paywall.summary.expInMonths', {
              expireInMonths: bundleInfo.expireInMonths
            })}
          </Text>
        </Stack>
        <Stack justify="space-between" spacing="sm" w="100%" align="center">
          <Text fontWeight="bold">
            {t('bundles.paywall.summary.quantityToPay')}
          </Text>
          <Text variant="2xl" fontWeight="bold">
            {bundleInfo.bundlePrice}€
          </Text>
        </Stack>
      </Stack>

      <Divider />
      <Stack direction="column">
        <EditBillingInfoCard
          dataTestId="edit-billing-info"
          onClick={onEditBillingInfo}
          title={t('bundles.paywall.summary.billingInfoTitle')}
        >
          {billingInfo && (
            <>
              <Text color="grey.800">{`${billingName} ${billingSurname} - ${billingCf}`}</Text>
              <Text color="grey.800">{`${billingAddress}, ${billingCity}, ${billingProvince} ${billingCap} ${billingCountry}`}</Text>
            </>
          )}
        </EditBillingInfoCard>
      </Stack>
      <Divider />
      <Alert message={t('bundles.paywall.summary.disclaimer')} type="warning" />
      {data && data.createStripeCustomerSession.clientSecret ? (
        <StripeBuyButton
          customerSessionClientSecret={
            data.createStripeCustomerSession.clientSecret
          }
          id={bundleInfo.buyButtonId}
          publishablekey={bundleInfo.publishableKey}
        />
      ) : (
        <Stack justify="center">
          <Skeleton rounded="lg" h={44} w={288} />
        </Stack>
      )}

      <Button
        label={t('bundles.paywall.summary.goBack')}
        type="button"
        variant="ghost"
        onClick={onBack}
      />
    </Stack>
  );
};
