import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useDoctorInfo } from '../../hooks/useDoctorInfo';

export const DoctorProfessionalAreas = ({ limit }: { limit?: number }) => {
  const { t } = useTranslation();
  const { professionalAreas } = useDoctorInfo();
  const professionalAreasList =
    Array.isArray(professionalAreas) && limit
      ? professionalAreas.slice(0, limit)
      : professionalAreas;
  const otherProfessionalAreas =
    Array.isArray(professionalAreas) && limit
      ? professionalAreas.slice(limit, professionalAreas.length)
      : [];
  return (
    <Stack direction="column">
      {Array.isArray(professionalAreasList) &&
        professionalAreasList.length > 0 && (
          <Box>
            {professionalAreasList?.map((professionalArea) => (
              <Stack
                key={professionalArea}
                align="center"
                mb="2xs"
                ml="2xs"
                style={{ textAlign: 'left' }}
              >
                <Box bgColor="candy.300" h="8px" w="8px" rounded="xs" mr="xs" />
                <Text variant="md" color="grey.500" key={professionalArea}>
                  {t(`chat.profiletp.professionalAreas.${[professionalArea]}`)}
                </Text>
              </Stack>
            ))}
          </Box>
        )}
      {Array.isArray(otherProfessionalAreas) &&
        otherProfessionalAreas.length > 0 && (
          <Box>
            <Box my="md">
              <Text color="candy.400" fontWeight="medium">
                {t('selectYourTp.detail.specialization.accordionTitle')}
              </Text>
            </Box>
            {otherProfessionalAreas?.map((professionalArea) => (
              <Stack
                key={professionalArea}
                align="center"
                mb="2xs"
                ml="2xs"
                style={{ textAlign: 'left' }}
              >
                <Box bgColor="candy.300" h="8px" w="8px" rounded="xs" mr="xs" />
                <Text variant="md" color="grey.500" key={professionalArea}>
                  {t(`chat.profiletp.professionalAreas.${[professionalArea]}`)}
                </Text>
              </Stack>
            ))}
          </Box>
        )}
    </Stack>
  );
};
