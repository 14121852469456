import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const EyeClosed: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20C11.0377 20 10.0743 19.7966 9.13831 19.4343"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1509 12.5349C19.9886 16.5337 15.9943 20 12 20"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0903 8.48114C20.8799 9.40122 21.5706 10.4018 22.1509 11.4663C22.2392 11.6305 22.2855 11.8141 22.2855 12.0006C22.2855 12.187 22.2392 12.3706 22.1509 12.5349"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 20L20 4"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45486 14.5452C8.77969 13.87 8.40039 12.9543 8.40039 11.9995C8.40039 11.5267 8.49351 11.0585 8.67444 10.6217C8.85536 10.1849 9.12055 9.78807 9.45486 9.45376C9.78917 9.11945 10.186 8.85427 10.6228 8.67334C11.0596 8.49241 11.5278 8.39929 12.0006 8.39929C12.9554 8.39929 13.8711 8.7786 14.5463 9.45376"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7646 6.23543C15.9966 4.86743 13.9977 4 12 4C8.00573 4 4.01145 7.46629 1.84916 11.4663C1.76083 11.6305 1.7146 11.8141 1.7146 12.0006C1.7146 12.1871 1.76083 12.3706 1.84916 12.5349C2.9303 14.5337 4.46859 16.3989 6.23545 17.7657"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
