import * as Sentry from '@sentry/react';

/* disable errors originating from injected scripts such as Google Tag Manager */
export function isInjectedCode(event: Sentry.Event | undefined): boolean {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

  if (!frames || frames.length === 0) return false;

  const firstFrame = frames[0];
  if (firstFrame.filename === '<anonymous>') {
    Sentry.setTag('errorType', '<anonymous>');
    // TODO set to true after we collect some errors
    return false;
  }

  const lastFrame = frames[frames.length - 1];
  if (
    typeof lastFrame.filename === 'string' &&
    (lastFrame.filename === window.location.pathname ||
      (lastFrame.filename.startsWith(window.location.origin) &&
        // static file should not be considered as injected code. We use vite currently, and all js-generated files are in this "assets" directory.
        !lastFrame.filename.includes('/assets/')))
  ) {
    Sentry.setTag('errorType', 'last-frame-condition');
    // TODO set to true after we collect some errors
    return false;
  }
  // node_modules condition
  if (
    frames.some(
      (frame) =>
        typeof frame.filename === 'string' &&
        frame.filename.includes('/node_modules/')
    )
  ) {
    Sentry.setTag('errorType', 'node_modules');
    return false;
  }

  if (
    frames.some(
      (frame) =>
        typeof frame.filename === 'string' &&
        (frame.filename.startsWith('https://www.googletagmanager.com') ||
          frame.filename.startsWith('/gtag/js') ||
          frame.filename.startsWith('/gtm.js') ||
          frame.filename.startsWith('https://googleads.'))
    )
  ) {
    return true;
  }

  return false;
}
