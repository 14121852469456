import { captureException } from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeElements, StripeErrorType } from '@stripe/stripe-js';
import { useState } from 'react';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useGetPaymentIntent } from './useGetPaymentIntent';

const ignoredStripeErrorTypes: StripeErrorType[] = [
  'api_connection_error',
  'card_error'
];

export const usePaymentMethod = () => {
  const [addElementLoading, setAddElementLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements() as StripeElements;
  const { paymentIntent: cardIntent } = useGetPaymentIntent();
  const { sendGenericError, sendErrorMessage } = useErrorHandler();

  const addElement = async () => {
    try {
      setAddElementLoading(true);
      if (!stripe || !cardIntent) {
        sendGenericError();
        return { error: 'Missing stripe' };
      }
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        submitError.message
          ? sendErrorMessage(submitError.message)
          : sendGenericError();
        return { error: 'Submission fail' };
      }
      // Use the clientSecret and Elements instance to confirm the setup
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret: cardIntent,
        confirmParams: {
          return_url: window.location.href
        },
        redirect: 'if_required'
      });
      if (error) {
        if (!ignoredStripeErrorTypes.includes(error.type)) {
          // Avoids authentication failure errors on sentry
          if (
            error.type !== 'invalid_request_error' &&
            error.code !== 'setup_intent_authentication_failure'
          ) {
            captureException(
              new Error(`Stripe error type: ${error.type} code: ${error.code}`),
              {
                extra: { ...error, stripe_code: error.code }
              }
            );
          }
        }
        error.message ? sendErrorMessage(error.message) : sendGenericError();
        return { error: 'Insert card fail' };
      }
      return { payment_method: setupIntent?.payment_method };
    } catch (e) {
      sendGenericError();
      return { error: 'Generic fail' };
    } finally {
      setAddElementLoading(false);
    }
  };

  return {
    addElementLoading,
    addElement
  };
};
