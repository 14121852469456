import { Camera, Book, Fitness, Heart, UserHeart } from '@unobravo/zenit-icons';
import { RStack, Text } from '@unobravo/zenit-web';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ITpInfo } from '../types';
import { CircleIcon } from './circleIcon';

const contentMap = {
  patients: {
    icon: UserHeart,
    textKey: 'selectYourTp.info.patients'
  },
  videocall: {
    icon: Camera,
    textKey: 'selectYourTp.info.videocall'
  },
  study: {
    icon: Book,
    textKey: 'selectYourTp.info.study'
  },
  skills: {
    icon: Fitness,
    textKey: 'selectYourTp.info.skills'
  },
  care: {
    icon: Heart,
    textKey: 'selectYourTp.info.care'
  }
} as const satisfies Record<
  ITpInfo['variant'],
  {
    icon: FC;
    textKey: ReactNode;
  }
>;

export const TpInfoBox = ({
  variant,
  type = 'preview',
  doctorOption
}: ITpInfo) => {
  const { t } = useTranslation();

  const { doctorPresentation, doctor } = doctorOption;

  const sex = doctor?.sex;

  const { ongoingPatientsCount, completedSessionsCount } =
    doctorPresentation || {};

  const { icon: Icon, textKey } = contentMap[variant];

  if (type === 'preview') {
    return (
      <RStack
        bgColor="sand.50"
        h="56px"
        direction="row"
        rounded="sm"
        p="xs"
        spacing="xs"
      >
        <Icon size="lg" color="candy.400" style={{ alignSelf: 'center' }} />
        <Text color="grey.600" variant="sm">
          {t(textKey, {
            patientsNumber: ongoingPatientsCount,
            sessionsNumber: completedSessionsCount,
            context: sex
          })}
        </Text>
      </RStack>
    );
  }

  if (type === 'detail') {
    return (
      <RStack w="100%" direction="row" spacing="xs" align="center">
        <CircleIcon
          icon={
            <Icon size="md" color="candy.400" style={{ alignSelf: 'center' }} />
          }
        />

        <Text color="grey.600" variant="md">
          {t(textKey, {
            patientsNumber: ongoingPatientsCount,
            sessionsNumber: completedSessionsCount,
            context: sex
          })}
        </Text>
      </RStack>
    );
  }

  return null;
};
