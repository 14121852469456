import {
  RStack,
  Skeleton,
  Stack,
  useBreakpointValue
} from '@unobravo/zenit-web';

export const RecapSkeleton = () => {
  const { isMobile } = useBreakpointValue();
  const skeletonColor = 'grey.100';
  return (
    <Stack direction="column" px="xl" grow spacing="2xs">
      <Skeleton bgColor={skeletonColor} h={25} w={200} rounded="2xs" mt="md" />
      <Skeleton bgColor={skeletonColor} h={100} w="100%" rounded="md" my="lg" />
      <Skeleton bgColor={skeletonColor} h={19} w={200} rounded="2xs" mb="xs" />
      <Skeleton bgColor={skeletonColor} rounded="2xs" mb="xs" h={45} w={300} />
      <Stack align="center" direction="row" justify="space-between">
        <Skeleton
          bgColor={skeletonColor}
          rounded="2xs"
          mb="xs"
          h={25}
          w={200}
        />
        <Skeleton bgColor={skeletonColor} rounded="2xs" mb="xs" h={25} w={90} />
      </Stack>
      <RStack
        direction={{ base: 'column', md: 'row' }}
        justify="end"
        grow
        align="end"
      >
        <Skeleton
          bgColor={skeletonColor}
          rounded="xl"
          h={48}
          my="xl"
          w={isMobile ? '100%' : '120px'}
        />
      </RStack>
    </Stack>
  );
};
