import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { closeLeftMenu } from '../../layout/layout.slice';
import { DownloadInvoice } from '../components/DownloadInvoice';
import { useAppointment } from '../hooks/useAppointment';

export const InvoicePage = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const {
    invoiceUrl,
    session,
    appointmentUrlLoading,
    sessionLoading,
    downloadInvoice
  } = useAppointment(uuid!);
  const { dataLoaded } = session;

  useEffect(() => {
    dispatch(closeLeftMenu());
  }, [dispatch]);

  return !dataLoaded || appointmentUrlLoading || sessionLoading ? (
    <Spinner />
  ) : (
    <PatientLayout>
      <PageContainer>
        <DownloadInvoice
          available={!!invoiceUrl}
          downloadInvoice={downloadInvoice}
        />
      </PageContainer>
    </PatientLayout>
  );
};
