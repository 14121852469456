import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import { Tab } from '@unobravo-monorepo/common/components/Tab';
import { Box, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toggle } from '../../../shared/components/Toggle';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { AgendaFutureAppointments } from '../components/AgendaFutureAppointments';
import { AgendaPastAppointments } from '../components/AgendaPastAppointments';
import { useAgenda } from '../hooks/useAgenda';

export const AgendaPatient: React.FC = () => {
  const { reloadNextAppointments } = useAgenda();
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('nextSessions');

  const tabs = [
    {
      id: 'nextSessions',
      label: `${t('agenda.tabs.next')}`
    },
    {
      id: 'pastSessions',
      label: `${t('agenda.tabs.past')}`
    }
  ];

  useEffect(() => {
    reloadNextAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientLayout>
      <PageContainer>
        {!isMobile && (
          <PageContainerHeader>
            <Stack
              align="center"
              justify="space-between"
              w="100%"
              pl="xs"
              pr="xs"
            >
              <Text variant="md" fontWeight="semibold">
                {t('agenda.title')}
              </Text>
              <Toggle
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                width="200px"
              />
            </Stack>
          </PageContainerHeader>
        )}
        <Stack position="relative" style={{ flexGrow: 1 }} direction="column">
          {isMobile && (
            <Box pb="md">
              <Stack
                bgColor="cappuccino.50"
                borderTop="sm"
                borderBottom="sm"
                borderColor="cappuccino.200"
                w="100%"
                h={50}
                align="center"
                justify="center"
              >
                <Text variant="lg" fontWeight="semibold">
                  {t('agenda.titleShort')}
                </Text>
              </Stack>
              <Stack pt="md">
                <Tab
                  tabs={tabs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              </Stack>
            </Box>
          )}
          {selectedTab === 'nextSessions' && <AgendaFutureAppointments />}
          {selectedTab === 'pastSessions' && <AgendaPastAppointments />}
        </Stack>
      </PageContainer>
    </PatientLayout>
  );
};
