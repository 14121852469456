import { Color, useColor } from '@unobravo/zenit-core';

export const Line = ({
  color,
  width = 329,
  height = 90
}: {
  color: Color;
  width?: number | string;
  height?: number | string;
}) => {
  const calculatedColor = useColor(color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 329 90"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M1.00732 89.5842C21.9249 64.2805 73.9503 28.5536 131.573 61.7502C187.036 93.7019 249.094 50.11 271.755 31.8927C300.654 8.66092 328.31 1.28634 328.31 0.780273"
        stroke={calculatedColor}
        strokeLinecap="round"
      />
    </svg>
  );
};
