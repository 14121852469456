import { useDispatch } from 'react-redux';
import { TherapySettingCard } from '../../../therapySetting/components/HomepageCard';
import { setShowDoctorInfoModal } from '../../home.slice';

export const TherapySettingHPCard = ({
  width
}: {
  width?: string | number;
}) => {
  const dispatch = useDispatch();

  const onShowDoctorInfo = () => {
    dispatch(setShowDoctorInfoModal(true));
  };

  return (
    <TherapySettingCard onShowDoctorInfo={onShowDoctorInfo} width={width} />
  );
};
