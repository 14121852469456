import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';

export const Providers = {
  Google: GoogleAuthProvider.PROVIDER_ID,
  Apple: 'apple.com',
  Email: EmailAuthProvider.PROVIDER_ID
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Providers = (typeof Providers)[keyof typeof Providers];
