import { Box, Stack } from '@unobravo/zenit-web';

export const CardContainer = ({
  children,
  testId
}: {
  children: React.ReactNode;
  testId: string;
}) => (
  <Box data-testid={testId} rounded="sm" mt="sm" p="sm" bgColor="white">
    <Stack direction="column" spacing="md">
      {children}
    </Stack>
  </Box>
);
