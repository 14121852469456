import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { BundleType } from '../../../types/Bundles';

export const BundleCardTracker = ({
  bundle
}: {
  bundle: NonNullable<BundleType>;
}) => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" spacing="sm">
      <Text variant="md" fontWeight="semibold">
        {bundle.sessionsAvailables === 1 ? (
          <>
            {t('bundles.activeCard.cardSubtitleSingular', {
              creditSessions: bundle.sessionsAvailables
            })}
          </>
        ) : (
          <>
            {t('bundles.activeCard.cardSubtitle', {
              creditSessions: bundle.sessionsAvailables
            })}
          </>
        )}
      </Text>

      <Stack direction="row" spacing="2xs">
        {Array.from({ length: bundle.numSessions }, (_, index) => (
          <Box
            key={index}
            rounded="md"
            bgColor={
              index < bundle.sessionUsed ? 'candy.400' : 'cappuccino.400'
            }
            maxW="80px"
            w="100%"
            h="8px"
          />
        ))}
      </Stack>
    </Stack>
  );
};
