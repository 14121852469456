import {
  Alignment,
  Fit,
  Layout as RiveLayout,
  useRive
} from '@rive-app/react-canvas';
import { Cross } from '@unobravo/zenit-icons';
import {
  ActionIcon,
  Box,
  Button,
  Heading,
  Modal,
  RBox,
  RStack,
  Stack,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import animation from '@unobravo-monorepo/patient/assets/animation/onboarding-bg-desktop.riv';
import animationMobile from '@unobravo-monorepo/patient/assets/animation/onboarding-bg-mobile.riv';
import { usePreviousLocation } from '@unobravo-monorepo/common/hooks/usePreviousLocation';
import { EducationReassignmentAccordion } from './EducationReassignmentAccordion';

const stateMachineName = 'Background';
const blackListedRoutes = [
  '/patient/chatbot-redirect-reassignment',
  '/patient/dialog/home/reassignmentDiscovery'
];

export function EducationReassignmentModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateBack } = usePreviousLocation();

  const { isMobile, breakpoint } = useBreakpointValue();
  const isMobileSM = breakpoint === 'xs';
  const { state } = useLocation();
  const { RiveComponent: ModalBG } = useRive({
    src: isMobile ? animationMobile : animation,
    autoplay: true,
    stateMachines: stateMachineName,
    layout: new RiveLayout({
      fit: Fit.Cover,
      alignment: Alignment.TopLeft
    })
  });
  const location = state ? state.modal : '/patient/chat';

  const close = () => {
    navigateBack(location, blackListedRoutes);
  };

  const onNextClick = () => {
    navigate('../dialog/reassignmentModal/openRequest', {
      state: { modal: location }
    });
  };

  const Title = (
    <Heading variant="xl" color="grey.800">
      <Trans
        i18nKey="reassignment.educationModal.reminder"
        values={{
          name: t('reassignment.educationModal.coloredReminder')
        }}
        components={[
          <Heading color="candy.500" variant="xl">
            {t('reassignment.educationModal.coloredReminder')}
          </Heading>
        ]}
      />
    </Heading>
  );

  const BottomSection = (
    <>
      <Button
        label={t('reassignment.educationModal.sendRequest')}
        variant="filled"
        onClick={onNextClick}
        data-testid="reassignment-open-request"
        size="lg"
        fullWidth
      />
    </>
  );

  const MobileModal = (
    <Stack
      position="fixed"
      right={0}
      left={0}
      bottom={0}
      top={0}
      w="100%"
      bgColor="candy.100"
      style={{ zIndex: 100 }}
      direction="column"
      justify="space-between"
      data-testid="reassignment-education-page"
    >
      <ModalBG />
      <Stack
        position="fixed"
        w="100vw"
        right={0}
        bottom={0}
        left={0}
        top={0}
        direction="column"
        justify="space-between"
      >
        <Stack pt="xl" px="2xl" direction="column">
          <Box
            style={{ cursor: 'pointer', zIndex: 100 }}
            position="absolute"
            right={0}
            top={0}
            p="md"
            onClick={close}
            tabIndex={0}
            aria-label="Close modal"
          >
            <ActionIcon variant="ghost" size="lg">
              <Cross />
            </ActionIcon>
          </Box>

          <RBox mt={{ base: 'sm', xs: 'xl' }} py="md" style={{ zIndex: 1 }}>
            {Title}
          </RBox>
          <EducationReassignmentAccordion />
        </Stack>

        <RStack
          px="2xl"
          direction="column"
          style={{ paddingBottom: isMobileSM ? 32 : 48 }}
        >
          {BottomSection}
        </RStack>
      </Stack>
    </Stack>
  );

  const DesktopModal = (
    <Modal w={520} h={620} open data-testid="reassignment-education-page">
      <Stack direction="column" h="100%">
        <Stack justify="end" pr="xs" pt="xs" style={{ zIndex: 1 }}>
          <ActionIcon variant="ghost" type="button" onClick={close}>
            <Cross />
          </ActionIcon>
        </Stack>
        <Box
          position="absolute"
          right={0}
          bottom={0}
          left={0}
          top={0}
          bgColor="candy.100"
        >
          <ModalBG />
        </Box>
        <Stack direction="column" h="100%" justify="space-between">
          <Stack direction="column" style={{ zIndex: 10 }} px="2xl" pt="md">
            <Box pb="md">{Title}</Box>
            <EducationReassignmentAccordion />
          </Stack>
          <Box position="absolute" bottom="32px" w="100%" px="2xl">
            {BottomSection}
          </Box>
        </Stack>
      </Stack>
    </Modal>
  );

  return isMobile ? MobileModal : DesktopModal;
}
