import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Theme } from '../../theme';
import { TypographyVariant } from './consts';

export enum BodySize {
  Body100 = 'Body100',
  Body90 = 'Body90',
  Body80 = 'Body80',
  Body70 = 'Body70',
  Body60 = 'Body60',
  Body50 = 'Body50',
  Body40 = 'Body40'
}

interface IBodyProps {
  size: BodySize;
  variant?: TypographyVariant;
  color?: string;
  children: string;
  element?: 'span' | 'p';
  margin?: string | boolean;
  textAlign?: 'left' | 'right' | 'center';
  noWrap?: boolean;
  lineClamp?: number;
  capitalize?: boolean;
  'data-testid'?: string;
  linkTarget?: React.HTMLAttributeAnchorTarget;
}

export interface IStyledBody
  extends Pick<
    IBodyProps,
    'color' | 'variant' | 'textAlign' | 'noWrap' | 'lineClamp' | 'capitalize'
  > {
  margin?: string | false;
}

export const StyledBody = styled.span<IStyledBody>`
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    color: ${Theme.colors.candy[500]};
    text-decoration: none;
    &:visited {
      color: ${Theme.colors.candy[600]};
    }
    &:focus-visible {
      outline-color: ${Theme.colors.candy[500]};
    }
  }
  font-family: ${Theme.fonts.secondary};
  ${({ color }) => (color ? `color:${color};` : '')}
  margin: ${({ margin }) => margin || '0'};
  font-weight: ${({ variant }) => variant || TypographyVariant.Regular};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'pre-line')};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
  ${({ lineClamp }) =>
    lineClamp
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${lineClamp};
          line-clamp: ${lineClamp};
          -webkit-box-orient: vertical;
          overflow: hidden;
          ${lineClamp === 1 ? 'word-break: break-all;' : ''}
        `
      : ''}
  ul {
    margin-left: 20px;
  }
  ol {
    margin-left: 20px;
  }
`;

const Body100 = styled(StyledBody)`
  font-size: 1.313rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 130%;'}
  letter-spacing: 0.013rem;
`;

export const Body90 = styled(StyledBody)`
  font-size: 1.125rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 140%;'}
  letter-spacing: 0.011rem;
`;

export const Body80 = styled(StyledBody)`
  font-size: 1rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 130%;'}
  letter-spacing: 0.01rem;
`;
export const Body70 = styled(StyledBody)`
  font-size: 0.938rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 135%;'}
  letter-spacing: 0.009rem;
`;
const Body60 = styled(StyledBody)`
  font-size: 0.813rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 140%;'}
  letter-spacing: 0.008rem;
`;

export const Body50 = styled(StyledBody)`
  font-size: 0.688rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 140%;'}
  letter-spacing: 0.008rem;
`;

const Body40 = styled(StyledBody)`
  font-size: 0.5rem;
  ${({ noWrap, lineClamp }) =>
    noWrap || lineClamp === 1 ? '' : 'line-height: 140%;'}
  letter-spacing: 0.01rem;
`;

const BodyComponentMap: {
  [key in BodySize]: StyledComponent<'span', DefaultTheme, IStyledBody, never>;
} = {
  [BodySize.Body100]: Body100,
  [BodySize.Body90]: Body90,
  [BodySize.Body80]: Body80,
  [BodySize.Body70]: Body70,
  [BodySize.Body60]: Body60,
  [BodySize.Body50]: Body50,
  [BodySize.Body40]: Body40
};

/**
 * @deprecated This component should not be used use Text from "@unobravo/zenit-web" instead.
 */

export const Body: React.FC<IBodyProps> = ({
  margin,
  element = 'p',
  color,
  variant,
  size,
  children,
  textAlign,
  noWrap,
  lineClamp,
  capitalize,
  linkTarget = '_blank',
  'data-testid': dataTestId
}) => {
  const BodyComponent = BodyComponentMap[size];

  return (
    <BodyComponent
      as={element}
      margin={margin === true ? '0 0 8px 0' : margin}
      color={color}
      variant={variant}
      textAlign={textAlign}
      noWrap={noWrap}
      lineClamp={lineClamp}
      capitalize={capitalize}
      data-testid={dataTestId}
    >
      <ReactMarkdown
        disallowedElements={['pre', 'code', 'p']}
        unwrapDisallowed
        linkTarget={linkTarget}
      >
        {children}
      </ReactMarkdown>
    </BodyComponent>
  );
};
