import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import {
  ApolloClientProvider,
  platform
} from '@unobravo-monorepo/common/providers/ApolloClientProvider';
import { ToasterProvider } from '@unobravo-monorepo/common/providers/Toaster';
import { TranslationsProvider, useCountry } from '@unobravo/translations';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { PatientToaster } from './features/toaster/PatientToaster';
import { DeleteRoutes, PatientRoutes } from './routes';
import { store } from './store';

const { NX_APP_APOLLO_URI: apolloUri, NX_APP_APOLLO_WS: apolloWs } =
  process.env;

const PatientApp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, loaded } = useFirebaseUser();
  const { role, isAnonymous } = currentUser;
  const [firstLoad, setFirstLoad] = useState(false);
  const isDeleteSuccess = location.pathname.includes('delete-account-success');
  const { domainCountry } = useCountry();

  useEffect(() => {
    if (!loaded) {
      return;
    }
    if ((role !== 'patient' || isAnonymous) && !isDeleteSuccess) {
      return navigate('/', {
        state: { prevLocation: firstLoad ? undefined : location }
      });
    }
    setFirstLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, navigate, loaded, isAnonymous]);

  return role === 'patient' && !isAnonymous ? (
    <TranslationsProvider namespaces={['patient', 'legal', 'informedConsent']}>
      <ApolloClientProvider
        uri={apolloUri!}
        uriWs={apolloWs}
        application={platform}
        country={domainCountry}
      >
        <ToasterProvider>
          <Provider store={store}>
            <PatientRoutes />
            <PatientToaster />
          </Provider>
        </ToasterProvider>
      </ApolloClientProvider>
    </TranslationsProvider>
  ) : isDeleteSuccess ? (
    <TranslationsProvider namespaces={['patient']}>
      <DeleteRoutes />
    </TranslationsProvider>
  ) : (
    <Spinner />
  );
};

// eslint-disable-next-line import/no-default-export
export default PatientApp;
