import * as Sentry from '@sentry/react';
import { getLocale } from '@unobravo/translations';
import { Settings } from 'luxon';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import pkg from '../../../package.json';
import { AppWithProfiler } from './app';
import {
  REPLAYS_ON_ERROR_SAMPLE_RATE,
  REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_ALLOWED_URLS,
  SENTRY_IGNORED_ERRORS,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_TRACE_PROPAGATION_TARGETS
} from './sentry.constants';
import { isInjectedCode } from './sentry.utils';

const { NX_APP_SENTRY_DNS, NX_APP_ENVIRONMENT, NX_APP_DEFAULT_LOCATE } =
  process.env;

Sentry.init({
  dsn: NX_APP_SENTRY_DNS,
  allowUrls: SENTRY_ALLOWED_URLS,
  ignoreErrors: SENTRY_IGNORED_ERRORS,
  tracePropagationTargets: SENTRY_TRACE_PROPAGATION_TARGETS,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

  environment: NX_APP_ENVIRONMENT,
  release: `unobravo-webapp@${pkg.version}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      enableInp: true,
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ['ub-webapp'],

      // Defines how to handle errors that contain third party stack frames.
      // Possible values are:
      // - 'drop-error-if-contains-third-party-frames'
      // - 'drop-error-if-exclusively-contains-third-party-frames'
      // - 'apply-tag-if-contains-third-party-frames'
      // - 'apply-tag-if-exclusively-contains-third-party-frames'
      behaviour: 'apply-tag-if-contains-third-party-frames' // third_party_code will be the tag
    })
    // Sentry.replayIntegration()
  ],
  replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE,
  replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,
  beforeSendTransaction(event) {
    // ignored transactions for webvitals
    const ignoredTransaction = ['/gtm', '/login-from-mobile'];

    // Check if the event transaction or URL includes any of the ignored transactions
    const isIgnored = ignoredTransaction.some(
      (ignored) =>
        event.transaction === ignored ||
        event.transaction?.includes(ignored) ||
        event?.request?.url?.includes(ignored)
    );

    // If it's an ignored transaction, return null
    if (isIgnored) {
      return null;
    }

    // Otherwise, return the event
    return event;
  },
  beforeSend: (event, hint) => {
    if (isInjectedCode(event)) {
      return null;
    }

    // Attempts to grab the original Exception before any "magic" happens
    const exception = hint?.originalException as Error;

    // We only want to capture Errors that have a Stack Trace and that are not Anonymous Errors
    return exception?.stack && !exception.stack.includes('<anonymous>')
      ? event
      : null;
  }
});

Settings.defaultLocale = getLocale() ?? (NX_APP_DEFAULT_LOCATE || 'it-IT');

const GlobalStyle = createGlobalStyle`

  :root {
    font-family: "Inter", sans-serif;
  }

  /* TODO: add back after adding Zenit support for Variable font family */
  /* commenting since it's just a 350kB download for nothing */
  /* @supports (font-variation-settings: normal) {
    :root {
      font-family: "InterVariable", sans-serif;
      font-optical-sizing: auto;
    }
  } */

   #root {
    width: 100%;

    /* fallback */
    height: 100vh;
    height: 100dvh;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <GlobalStyle />
    <AppWithProfiler />
  </>
);
