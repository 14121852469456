import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { platform } from '@unobravo-monorepo/common/providers/ApolloClientProvider';
import { ToasterProvider } from '@unobravo-monorepo/common/providers/Toaster';
import { handledRoles } from '@unobravo-monorepo/common/utils/roles';
import { ApolloClientProvider } from '@unobravo/core';
import { useRegistrationStatus } from '@unobravo/signup';
import { TranslationsProvider, useCountry } from '@unobravo/translations';
import { FC, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { ConfirmDelete } from './pages/confirmDelete';
import { Login } from './pages/login';
import { LoginFromToken } from './pages/loginFromToken';
import { Logout } from './pages/logout';
import { ResetPassword } from './pages/recoverPassword';
import { SetPassword } from './pages/setPassword';
import { SocialRegistrationGate } from './pages/socialRegistrationGate';

const { NX_APP_APOLLO_URI: apolloUri } = process.env;

const useRedirectOnUserLogin = () => {
  const { currentUser, loaded } = useFirebaseUser();
  const { token, role, isAnonymous } = currentUser || {};

  const { registrationStatus, loading: registrationStatusLoading } =
    useRegistrationStatus({ skip: !token, fetchPolicy: 'no-cache' });

  const called = !!registrationStatus;

  const location = useLocation();
  const navigate = useNavigate();

  const [prevLocation, setPrevLocation] = useState<Location>();

  useEffect(() => {
    const prevLocationState = (location.state as { prevLocation?: Location })
      ?.prevLocation;
    if (prevLocationState) {
      setPrevLocation(prevLocationState);
    }
  }, [location.state]);

  useEffect(() => {
    if (registrationStatusLoading || !called) return;

    const { doctorSelected, initialized, signupCompleted } =
      registrationStatus?.getRegistrationStatus ?? {};

    const signupStarted = initialized && signupCompleted === false;

    const userNeedToCompleteSignup =
      token && !registrationStatusLoading && signupStarted && !isAnonymous;

    const userIsCorrectlyLoggedIn =
      token && !isAnonymous && handledRoles.includes(role || '');

    if (doctorSelected === false) return navigate('patient/select-therapist');

    if (userNeedToCompleteSignup)
      return navigate('signup/resume', { replace: true });

    if (loaded && userIsCorrectlyLoggedIn && location.pathname !== 'logout') {
      navigate(prevLocation || '/', { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, token, registrationStatusLoading, registrationStatus]);
};

const AuthRoutes: FC = () => {
  useRedirectOnUserLogin();
  return (
    <Routes>
      <Route path="set-password" element={<SetPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="login" element={<Login />} />
      <Route path="confirm-delete/:token" element={<ConfirmDelete />} />
      <Route
        path="social-registration-gate"
        element={<SocialRegistrationGate />}
      />
      <Route path="login-from-token/:code" element={<LoginFromToken />} />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

const LoginApp: React.FC = () => {
  const { domainCountry } = useCountry();

  return (
    <TranslationsProvider namespaces={['auth']}>
      <ToasterProvider>
        <ApolloClientProvider
          uri={apolloUri!}
          application={platform}
          country={domainCountry}
        >
          <AuthRoutes />
        </ApolloClientProvider>
      </ToasterProvider>
    </TranslationsProvider>
  );
};

export { LoginFromToken };

// Disabling it because we lazy load this lib
// eslint-disable-next-line import/no-default-export
export default LoginApp;
