import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const NewWindow: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.candy[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5.25H18.75V9"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.5L18.75 5.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 13.5V17.25C17.25 17.6478 17.092 18.0294 16.8107 18.3107C16.5294 18.592 16.1478 18.75 15.75 18.75H6.75C6.35218 18.75 5.97064 18.592 5.68934 18.3107C5.40804 18.0294 5.25 17.6478 5.25 17.25V8.25C5.25 7.85218 5.40804 7.47064 5.68934 7.18934C5.97064 6.90804 6.35218 6.75 6.75 6.75H10.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
