import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import {
  setSessionStoredData,
  STORAGE_KEYS
} from '@unobravo-monorepo/common/utils/sessionStorage';

export const LoginFromToken: React.FC = () => {
  const { code, redirect, platform } = useParams();
  const navigate = useNavigate();
  const { loginFromCustomToken } = useFirebaseUser();

  setSessionStoredData(STORAGE_KEYS.PLATFORM, platform ?? 'WEB');

  const verify = async () => {
    if (!code) {
      return navigate('/', { replace: true });
    }

    await loginFromCustomToken(code);

    if (redirect) {
      const toUrl = decodeURIComponent(redirect);
      return navigate(toUrl, { replace: true });
    }

    return navigate('/', { replace: true });
  };

  useEffect(() => {
    verify();
  }, []);

  return <Spinner />;
};
