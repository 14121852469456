import { Alert, Stack, useBreakpointValue } from '@unobravo/zenit-web';

type AlertType = Parameters<typeof Alert>[0]['type'];

interface MGMAlertProps {
  type: AlertType;
  title: string;
  message?: string;
}

export const MGMAlert = ({ type, title, message }: MGMAlertProps) => {
  const { isMobile } = useBreakpointValue();

  return (
    <Stack
      direction="column"
      spacing="2xs"
      justify="center"
      w={isMobile ? '100%' : '328px'}
    >
      <Alert type={type} title={title} {...(message ? { message } : null)} />
    </Stack>
  );
};
