import maestroLogo from '@unobravo-monorepo/patient/assets/maestro-logo.svg';
import mastercardLogo from '@unobravo-monorepo/patient/assets/mastercard-logo.svg';
import stripeLogo from '@unobravo-monorepo/patient/assets/stripe-logo.svg';
import visaLogo from '@unobravo-monorepo/patient/assets/visa-logo.svg';
import {
  Box,
  Button,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentElement, useStripe } from '@stripe/react-stripe-js';
import klarnaLogo from '@unobravo-monorepo/patient/assets/klarna-logo.svg';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { usePatientCards } from '../../patientData/hooks/usePatientCards';
import { usePaymentMethod } from '../../payment/hooks/usePaymentMethod';
import { paySessionDataSelector } from '../paySessionData.slice';

const StripeDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" align="center" spacing="sm" justify="center" mt="xs">
      <Text color="grey.600" variant="sm">
        {t('paymentMethod.securePayment')}
      </Text>
      <img src={stripeLogo} style={{ width: 46 }} alt="stripe-logo" />
      <Box borderRight="sm" borderColor="grey.200" h={20} />
      <img src={visaLogo} alt="visa-logo" style={{ width: 46 }} />
      <img src={mastercardLogo} alt="mastercard-logo" style={{ width: 35 }} />
      <img src={maestroLogo} alt="maestro-logo" style={{ width: 35 }} />
    </Stack>
  );
};

const StyledButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-radius: 5px;
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  color: #6d6e78;
  transition:
    background 0.15s ease,
    border 0.15s ease,
    box-shadow 0.15s ease,
    color 0.1s ease;
  border: 1px solid #e6e6e6;
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.03),
    0px 3px 6px rgba(0, 0, 0, 0.02);
  appearance: button;
  background: none;
  cursor: pointer;
  text-transform: none;
  box-sizing: border-box;
  :hover {
    color: #30313d;
  }
`;

export const CardForm = ({
  onBack,
  onNext
}: {
  onBack: () => void;
  onNext: (cardId: string) => void;
}) => {
  const [complete, setComplete] = useState<boolean>();
  const stripe = useStripe();
  const { t } = useTranslation();
  const { refreshPatientCards } = usePatientCards();
  const { addElementLoading: loading, addElement } = usePaymentMethod();
  const { isMobile } = useBreakpointValue();
  const { paymentIntent } = useSelector(paySessionDataSelector);
  const [isKlarnaPresent, setKlarnaPresent] = useState(false);

  const paymentMethodTypes = async (paymentIntentSecret: string) => {
    if (!isKlarnaPresent) {
      if (!stripe) {
        return;
      }
      const { paymentIntent: paymentIntentResult, error } =
        await stripe.retrievePaymentIntent(paymentIntentSecret);
      if (error) {
        return Promise.reject(error);
      }
      if (paymentIntentResult.payment_method_types.includes('klarna')) {
        // setKlarnaPresent(true);
      }
      return paymentIntentResult.payment_method_types;
    }
    return null;
  };

  useEffect(() => {
    if (paymentIntent?.client_secret) {
      paymentMethodTypes(paymentIntent.client_secret);
    }
  }, [paymentIntent?.client_secret]);

  const submitHandler = async () => {
    const cardId = await addElement();
    if (cardId.payment_method) {
      refreshPatientCards();
      onNext(cardId.payment_method as string);
    }
  };

  return (
    <Stack direction="column" grow>
      <Stack direction="column" spacing="sm">
        <PaymentElement
          options={{ layout: 'accordion' }}
          onChange={(e: { complete: boolean }) => {
            setComplete(e.complete);
          }}
        />
        {isKlarnaPresent && (
          <Stack direction="column" align="center" spacing="sm">
            <Text>{t('or')}</Text>
            <StyledButton onClick={() => onNext('klarna')}>
              <Stack style={{ marginRight: '10px' }} align="center">
                <img src={klarnaLogo} alt="klarna-logo" width={42} />
              </Stack>
              {t('paymentMethod.payWithKlarna')}
            </StyledButton>
          </Stack>
        )}
        <Box mb="2xl">
          <StripeDisclaimer />
        </Box>
      </Stack>
      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        justify="space-between"
        spacing="xs"
        py="xl"
      >
        <Button
          variant="ghost"
          onClick={onBack}
          label={t('common:cancel')}
          size="lg"
          iconPosition="left"
          type="button"
          data-testid="new-card-back-button"
        />
        <Button
          label={t('common:save')}
          loading={loading}
          onClick={submitHandler}
          size="lg"
          disabled={!complete || loading}
          data-testid="save-new-card"
        />
      </Stack>
    </Stack>
  );
};
