import { useNavigate } from 'react-router-dom';
import { getSessionStoredData, STORAGE_KEYS } from '../utils/sessionStorage';

export const usePreviousLocation = () => {
  const navigate = useNavigate();

  const navigateBack = (prevDefault: string, blackListedRoutes?: string[]) => {
    const prevLocation = getSessionStoredData(
      STORAGE_KEYS.PREV_LOCATION
    ) as string;
    if (blackListedRoutes && blackListedRoutes.includes(prevLocation)) {
      return navigate(prevDefault);
    }
    prevLocation ? navigate(prevLocation) : navigate(prevDefault);
  };

  return {
    navigateBack
  };
};
