import React from 'react';
import styled from 'styled-components';
import {
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { mobileFirstMedia, Theme } from '@unobravo-monorepo/common';
import { Body } from '@unobravo-monorepo/common/components/Typography/Body';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { toggleRightMenu } from '../../layout/layout.slice';
import { usePatient } from '../../patientData/hooks/usePatient';

const Header = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  cursor: pointer;
  ${mobileFirstMedia('md')(`
    cursor: default;
    pointer-events: none;
  `)}
`;

const InitialsContainer = styled.div`
  align-items: center;
  background-color: ${Theme.specialColors.white};
  border: 1px solid ${Theme.colors.cappuccino[100]};
  border-radius: 50%;
  color: ${Theme.colors.cappuccino[800]};
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  overflow: hidden;
`;

const ProfileImg = styled.img`
  border-radius: 50%;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IHeaderChat {
  'data-testid'?: string;
}

export function HeaderChat({ 'data-testid': dataTestId }: IHeaderChat) {
  const { doctor } = usePatient();
  const doctorName = doctor ? `${doctor.name} ${doctor.surname}` : '';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  return (
    <Header
      onClick={() => dispatch(toggleRightMenu())}
      data-testid={dataTestId}
    >
      {doctor?.profilePicture && (
        <InitialsContainer>
          <ProfileImg src={doctor?.profilePicture} />
        </InitialsContainer>
      )}

      <Wrapper>
        <Body
          margin={!isMobile && !doctor?.profilePicture && '0 auto 0 10px'}
          size={BodySize.Body80}
          variant={TypographyVariant.SemiBold}
        >
          {doctorName}
        </Body>
        {isMobile && (
          <div data-testid="tp-profile-header">
            <Body size={BodySize.Body50} color={Theme.colors.candy[500]}>
              {t('chat.profiletp.checkProfile')}
            </Body>
          </div>
        )}
      </Wrapper>
    </Header>
  );
}
