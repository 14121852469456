import { Checkbox } from '@unobravo-monorepo/common/components/Checkbox';
import {
  Alert,
  Button,
  Heading,
  ModalAlert,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useAcceptPatientTos } from '../hooks/useAcceptPatientTos';

interface IFormInput {
  prefPrivacy: boolean;
  prefTerms: boolean;
}

export function TosPrivacyAcceptanceModal() {
  const { patientTosEs } = usePatientFeatureFlags();
  const { prefTerms, prefPrivacy } = usePatient();
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const { sendGenericError } = useErrorHandler();
  const [open, setOpen] = useState(true);

  const { acceptPatientTos, loading } = useAcceptPatientTos({
    onCompleted: (data) => {
      const success =
        !!data.acceptPatientTos.prefPrivacy &&
        !!data.acceptPatientTos.prefTerms;

      if (success) {
        setOpen(false);
      }
    },
    onError: () => sendGenericError()
  });

  const { register, handleSubmit, formState } = useForm<IFormInput>({
    mode: 'onChange',
    defaultValues: {
      prefPrivacy,
      prefTerms
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = () => {
    acceptPatientTos();
  };

  return (
    <ModalAlert open={patientTosEs && open} w={480}>
      <Stack direction="column" spacing="md">
        <Alert
          title={t('legal:tosPrivacyAcceptance.modal.alertLabel')}
          type="warning"
        />
        <Stack spacing="2xs" direction="column">
          <Heading variant="sm" fontWeight="bold">
            {t('legal:tosPrivacyAcceptance.modal.title')}
          </Heading>

          <Text color="grey.600" variant="sm" fontWeight="normal">
            {t('legal:tosPrivacyAcceptance.modal.paragraph')}
          </Text>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" mt={isMobile ? 'md' : undefined}>
            <Checkbox
              {...register('prefTerms', {
                required: 'prefTerms'
              })}
              small
              id="check-tos-acceptance"
              label={t('legal:tosPrivacyAcceptance.modal.tosLabel')}
              data-testid="check-tos-acceptance"
            />

            <Checkbox
              {...register('prefPrivacy', {
                required: 'prefPrivacy'
              })}
              small
              id="check-privacy-acceptance"
              label={t('legal:tosPrivacyAcceptance.modal.privacyLabel')}
              data-testid="check-privacy-acceptance"
            />
          </Stack>
          <Stack justify="end" mt={isMobile ? '2xl' : 'xs'}>
            <Button
              size="md"
              disabled={!formState.isValid}
              label={t('legal:tosPrivacyAcceptance.modal.confirmLabel')}
              fullWidth={isMobile}
              loading={loading}
            />
          </Stack>
        </form>
      </Stack>
    </ModalAlert>
  );
}
