import { useAgenda } from '@unobravo-monorepo/patient/features/agenda/hooks/useAgenda';
import { AppointmentCard } from '@unobravo-monorepo/patient/features/therapySetting/components/AppointmentCard';
import { filterSessionsByStatus } from '@unobravo-monorepo/patient/features/therapySetting/utils/session';
import { useDoctorInfo } from '@unobravo-monorepo/patient/shared/hooks/useDoctorInfo';
import {
  OnboardingStepType,
  StepType
} from '@unobravo-monorepo/patient/types/Onboarding';
import { sortByDate } from '@unobravo/core';
import { Box, Button, Link, Stack, Text } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PatientOnboardingStatus } from '../../../../../generated/patient.generated';
import { useOnboardingStatus } from '../../hooks/useOnboardingStatus';
import { AccordionStep } from '../OnboardingStep/AccordionStep';
import { CheckedStep } from '../OnboardingStep/CheckedStep';
import { CurrentStep } from '../OnboardingStep/CurrentStep';
import { Section } from '../Section';

export const FreeSessionSteps = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<OnboardingStepType>(
    'SCHEDULE_FREESESSION'
  );
  const [openedAccordion, setOpenedAccordion] = useState<
    OnboardingStepType | undefined
  >(undefined);
  const { firstName } = useDoctorInfo();
  const { nextSessions, pastSessions } = useAgenda();
  const { status } = useOnboardingStatus();
  const navigate = useNavigate();

  /**
   * Compute a session to show
   * cases:
   * 1. Past videocall already active, if there is no nextSessions
   * 2. First next appointment - skipping the recently finished videocall
   * 3. First next appointment, if there is no #2
   */
  const sessionToShow = useMemo(() => {
    const nextNotCanceled = nextSessions
      .filter((session) => !session.canceled)
      .sort((elm, prev) => sortByDate(elm.when, prev.when)); // from newest to oldest

    // case#1
    // Special case where you not have next appointments, the session has passed but you can still connect to the videocall
    if (nextNotCanceled.length === 0) {
      return pastSessions?.find((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['VIDEOCALL']
        })
      );
    }
    // case: #2 && #3
    return (
      nextNotCanceled.find(
        (elm) =>
          DateTime.fromISO(elm.when).plus({ minutes: 50 }) > DateTime.now()
      ) || nextNotCanceled[0]
    );
  }, [pastSessions, nextSessions]);

  const isToday =
    sessionToShow?.when &&
    DateTime.fromISO(sessionToShow?.when).toISODate() ===
      DateTime.now().toISODate();

  const AppointmentHPCard = sessionToShow ? (
    <AppointmentCard appointment={sessionToShow} />
  ) : (
    <Stack
      bgColor="white"
      spacing="xs"
      direction="column"
      py="lg"
      px="sm"
      mt="xs"
      rounded="md"
      align="center"
    >
      <Text color="cappuccino.700" textAlign="center">
        {t('home.freeSession.noAppointments')}
      </Text>
      <Link to={() => navigate('../chat')} underline={false} color="candy.600">
        <Text variant="sm" fontWeight="semibold">
          {t('home.freeSession.writeToButton', { doctorName: firstName })}
        </Text>
      </Link>
    </Stack>
  );

  const steps: StepType[] = [
    {
      id: 'SCHEDULE_FREESESSION',
      checked: !!status,
      title: t('home.freeSession.scheduleFreeSession.title'),
      titleChecked: t('home.freeSession.scheduleFreeSession.titleChecked'),
      description: t('home.freeSession.scheduleFreeSession.description'),
      children: (
        <Box pt="sm">
          <Button
            data-testid="schedule_freesession_action"
            label={t('home.freeSession.goToChat')}
            onClick={() => navigate('../chat')}
            fullWidth
          />
        </Box>
      )
    },
    {
      id: 'CONFIRM_FREESESSION',
      checked:
        !!status && status !== PatientOnboardingStatus.FreeSessionScheduled,
      title: t('home.freeSession.confirmFreeSession.title'),
      titleChecked: t('home.freeSession.confirmFreeSession.titleChecked'),
      description: t('home.freeSession.confirmFreeSession.description', {
        doctorName: firstName
      }),
      children: AppointmentHPCard
    },
    {
      id: 'MEET_TP',
      checked:
        !!status &&
        [
          PatientOnboardingStatus.FreeSessionCompleted,
          PatientOnboardingStatus.PaidSessionCompleted
        ].includes(status),
      title: t('home.freeSession.meetTp.title'),
      titleChecked: t('home.freeSession.meetTp.titleChecked'),
      description:
        current === 'MEET_TP' && !isToday
          ? t('home.freeSession.meetTp.descriptionCustom')
          : t('home.freeSession.meetTp.description'),
      children: AppointmentHPCard
    },
    {
      id: 'MAKE_FIRSTPURCHASE',
      checked: PatientOnboardingStatus.PaidSessionCompleted === status,
      title: t('home.freeSession.makeFirstPurchase.title'),
      description:
        current === 'MAKE_FIRSTPURCHASE' &&
        !!sessionToShow &&
        !sessionToShow.paid
          ? t('home.freeSession.makeFirstPurchase.descriptionPay')
          : current === 'MAKE_FIRSTPURCHASE' &&
            !!sessionToShow &&
            sessionToShow.paid &&
            !isToday
          ? t('home.freeSession.makeFirstPurchase.descriptionPrecall')
          : current === 'MAKE_FIRSTPURCHASE' &&
            !!sessionToShow &&
            sessionToShow.paid &&
            isToday
          ? t('home.freeSession.makeFirstPurchase.descriptionVideocall')
          : // current !== 'MAKE_FIRSTPURCHASE' || !sessionToShow
            t('home.freeSession.makeFirstPurchase.description'),
      children: AppointmentHPCard
    }
  ];

  useEffect(() => {
    if (status === PatientOnboardingStatus.FreeSessionScheduled) {
      setCurrent('CONFIRM_FREESESSION');
    } else if (status === PatientOnboardingStatus.FreeSessionConfirmed) {
      setCurrent('MEET_TP');
    } else if (status === PatientOnboardingStatus.FreeSessionCompleted) {
      setCurrent('MAKE_FIRSTPURCHASE');
    } else {
      setCurrent('SCHEDULE_FREESESSION');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Box>
      <Section title={t('home.freeSession.title')} gotoChat={false} />
      <Stack spacing="md" direction="column">
        {steps.map((item, index) => (
          <Box key={`freeSessionStep_${index.toString()}`}>
            {item.id === current ? (
              <CurrentStep
                title={item.title}
                description={item.description}
                showDashedLine={index !== steps.length - 1}
              >
                {item.children}
              </CurrentStep>
            ) : item.checked ? (
              <CheckedStep
                title={item.titleChecked ?? ''}
                showDashedLine={index !== steps.length - 1}
              />
            ) : (
              <AccordionStep
                item={{ ...item, children: null }}
                showDashedLine={index !== steps.length - 1}
                openAccordion={openedAccordion === item.id}
                onAccordionChange={(value) => setOpenedAccordion(value)}
              />
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};
