import { theme } from '@unobravo/zenit-core';
import { Pencil } from '@unobravo/zenit-icons';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import React from 'react';
import styled, { css } from 'styled-components';

const SelectorWrapper = styled(Stack)<{ sticky?: boolean }>`
  border-bottom: 1px solid ${theme.colors.candy[500]};
  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
          top: 0;
          z-index: 1;
        `
      : ''}
`;

interface ITabDivider {
  title: string;
  sticky?: boolean;
  modify?: () => void;
}

export const TabDivider: React.FC<ITabDivider> = ({
  title,
  sticky,
  modify
}) => (
  <SelectorWrapper
    sticky={sticky}
    justify="space-between"
    pb="xs"
    mb="md"
    bgColor="white"
  >
    <Text fontWeight="semibold" variant="lg" color="candy.500">
      {title}
    </Text>
    {modify && (
      <Box onClick={modify} style={{ cursor: 'pointer' }}>
        <Pencil width={20} height={20} color="candy.500" />
      </Box>
    )}
  </SelectorWrapper>
);
