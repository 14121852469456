import { useBreakpointValue } from '@unobravo/zenit-web';

/**
 * @deprecated use useBreakpointValue() from '@unobravo/zenit-web' instead
 */
export const useViewport = () => {
  const { isMobile, breakpoint } = useBreakpointValue();

  return {
    isMobile,
    isMobileXS: isMobile && breakpoint === 'xs',
    isMobileSM: isMobile && breakpoint === 'sm'
  };
};
