import React from 'react';
import IIcon from '@unobravo-monorepo/common/types/IIcon';

export const DotBooked: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
    >
      <circle cx="5.66699" cy="5" r="5" fill="#F46338" />
    </svg>
  );
};

export const DotConfirmed: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
    >
      <circle cx="5.66699" cy="5" r="5" fill="#25886F" />
    </svg>
  );
};

export const DotEmpty: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
    >
      <circle cx="5.66699" cy="5" r="5" fill="none" />
    </svg>
  );
};

export const DotPlaceholder: React.FC<IIcon> = ({
  width = 10,
  height = 10
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill="#FBE6E0" />
    </svg>
  );
};

export const DotCompleted: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="10" rx="5" fill="#25886F" />
      <path
        d="M7.125 3.5L4.375 6.25L3.125 5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DotCanceled: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="10" rx="5" fill="#CE2828" />
      <path
        d="M3.6875 3.6875L6.3125 6.3125M6.3125 3.6875L3.6875 6.3125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DotExpired: React.FC<IIcon> = ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="10" height="10" rx="5" fill="#858585" />
      <path
        d="M3.6875 3.6875L6.3125 6.3125M6.3125 3.6875L3.6875 6.3125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
