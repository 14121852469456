import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import {
  BonusPsicologo,
  Calendar,
  Finance
} from '@unobravo-monorepo/common/components/Icons';
import { getSimpleDate } from '@unobravo-monorepo/common/utils/dateUtils';
import { Button } from '@unobravo-monorepo/common/components/Button';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { SectionHeader } from '../components/SectionHeader';
import { useBonus } from '../../patientData/hooks/useBonus';
import { BonusItem } from '../components/BonusItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  ${mediaQueries('sm')(`
    padding-bottom: 0;
  `)}
`;

const TextWrapper = styled.div`
  max-width: 75%;
  ${mediaQueries('sm')(`
  max-width: 100%;
  `)}
`;

const BonusContent = styled.div`
  margin: 36px 0;
  background-color: ${Theme.colors.cappuccino[50]};
  width: 70%;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQueries('sm')(`
    width: 100%;
    padding: 16px;
  `)}
`;

const BonusInfo = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries('sm')(`
    flex-direction: column;
    align-items: start;
    margin-top: 0;
    gap: 16px;
  `)}
`;

const LeftSessions = styled.div`
  background-color: ${Theme.specialColors.white};
  border-radius: 8px;
  border: 1.5px solid ${Theme.colors.candy[500]};
  margin: 32px 0px 8px 0px;
  padding: 12px 20px;
  text-align: center;
  ${mediaQueries('sm')(`
    margin: 24px 0 0 0;
  `)}
`;

const DiscountWrapper = styled.div`
  background-color: ${Theme.specialColors.white};
  border-radius: 8px;
  border: 1.5px solid ${Theme.colors.candy[500]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  gap: 16px;
  padding: 24px;
  ${mediaQueries('sm')(`
    margin-top: 16px;
  `)}
`;

export const BonusPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    code,
    activationDate,
    leftAppointments,
    loading,
    totalAmount,
    amountLeft,
    discountedSessions,
    discountedAmount,
    pricingAmount
  } = useBonus();
  return (
    <PageContainer>
      <SectionHeader path="../" title={`${t('bonus.header')}`} />
      <Wrapper>
        <TextWrapper>
          <Heading size={HeadingSize.Title80} margin="4px 0px">
            {t('bonus.titlePatient')}
          </Heading>
          <Body size={BodySize.Body70}>{t('bonus.description')}</Body>
        </TextWrapper>

        {code && activationDate && (
          <BonusContent>
            <BonusInfo>
              <BonusItem
                label={t('bonus.items.code')}
                value={code}
                icon={<BonusPsicologo color={Theme.colors.candy[500]} />}
              />
              <BonusItem
                label={t('bonus.items.activationDate')}
                value={getSimpleDate(activationDate)}
                icon={<Calendar color={Theme.colors.candy[500]} />}
              />
              <BonusItem
                label={t('bonus.items.bonusValue')}
                value={t('bonus.amountLeft', {
                  amountLeft: `**${String(amountLeft)}€**`,
                  totalAmount: `${totalAmount}`
                })}
                isBold={false}
                icon={<Finance />}
              />
            </BonusInfo>

            {discountedSessions && leftAppointments === 0 ? (
              <DiscountWrapper>
                <Body size={BodySize.Body90} variant={TypographyVariant.Bold}>
                  {t('bonus.discountedSessions.title')}
                </Body>
                <Body size={BodySize.Body70}>
                  {t('bonus.discountedSessions.par1', {
                    credit: `${amountLeft}`
                  })}
                </Body>
                <Body size={BodySize.Body70}>
                  {t('bonus.discountedSessions.par2')}
                </Body>
                {!!pricingAmount && (
                  <>
                    {discountedSessions === 1 ? (
                      <Body size={BodySize.Body70}>
                        {t('bonus.discountedSessions.par2single', {
                          discounted: `${discountedAmount}`,
                          price: pricingAmount
                        })}
                      </Body>
                    ) : (
                      <Body size={BodySize.Body70}>
                        {t('bonus.discountedSessions.par2Bis', {
                          discounted: `${discountedAmount}`,
                          sessions: `${discountedSessions}`,
                          price: pricingAmount
                        })}
                      </Body>
                    )}
                  </>
                )}
                <Body size={BodySize.Body70}>
                  {t('bonus.discountedSessions.par3')}
                </Body>
                <Button
                  label={t('bonus.discountedSessions.book')}
                  onClick={() => navigate('../chat')}
                />
              </DiscountWrapper>
            ) : (
              <LeftSessions>
                <Body size={BodySize.Body70}>
                  {leftAppointments === 1
                    ? t('bonus.leftOneSession', {
                        sessions: `**${leftAppointments}**`
                      })
                    : t('bonus.leftSessions', {
                        sessions: `**${leftAppointments}**`
                      })}
                </Body>
              </LeftSessions>
            )}
          </BonusContent>
        )}
        {loading && <Spinner />}
      </Wrapper>
    </PageContainer>
  );
};
