import { Color, IconSizes } from '@unobravo/zenit-core';
import { Loading } from '@unobravo/zenit-icons';
import styled, { keyframes } from 'styled-components';

const rotation = () => keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;
const Spinner = styled(Loading)`
  animation: ${rotation} 1.5s infinite linear;
`;

interface SpinnerIconProps {
  size: IconSizes;
  color?: Color;
}

export const SpinnerIcon = ({ size, color = 'black' }: SpinnerIconProps) => {
  return <Spinner color={color} size={size} data-testid="spinner" />;
};
