export const BoxIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.5 7.33333C5.5 7.05719 5.72386 6.83333 6 6.83333H10C10.2761 6.83333 10.5 7.05719 10.5 7.33333C10.5 7.60948 10.2761 7.83333 10 7.83333H6C5.72386 7.83333 5.5 7.60948 5.5 7.33333Z"
      fill="#323232"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.278 5.13331C14.788 5.00856 15.1667 4.54834 15.1667 4V2.66667C15.1667 2.02252 14.6441 1.5 14 1.5H2C1.35586 1.5 0.833333 2.02252 0.833333 2.66667V4C0.833333 4.54835 1.212 5.00856 1.72201 5.13332V12.6667C1.72201 13.6795 2.54253 14.5 3.55534 14.5H12.4447C13.4575 14.5 14.278 13.6795 14.278 12.6667V5.13331ZM2 2.5C1.90814 2.5 1.83333 2.57481 1.83333 2.66667V4C1.83333 4.09186 1.90814 4.16667 2 4.16667H14C14.0919 4.16667 14.1667 4.09186 14.1667 4V2.66667C14.1667 2.57481 14.0919 2.5 14 2.5H2ZM13.278 5.16667H2.72201V12.6667C2.72201 13.1272 3.09481 13.5 3.55534 13.5H12.4447C12.9052 13.5 13.278 13.1272 13.278 12.6667V5.16667Z"
      fill="#323232"
    />
  </svg>
);
