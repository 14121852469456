import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { motion } from 'framer-motion';
import { mediaQueries, Theme } from '../../theme';
import { Check, Cross, Warning } from '../Icons';
import { Body, BodySize } from '../Typography/Body';
import { TypographyVariant } from '../Typography';

interface IStyledToast {
  variant: 'success' | 'warning' | 'error';
  transparent?: boolean;
  theme?: DefaultTheme;
}

const getWrapperVariant = ({ variant, transparent, theme }: IStyledToast) => {
  switch (variant) {
    case 'warning':
      return transparent
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.82)), rgba(243, 199, 63, 0.4);'
        : theme?.colors.ginger[50];
    case 'error':
      return transparent
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.82)), rgba(206, 40, 40, 0.4);'
        : theme?.colors.ribes[50];
    default:
      return transparent
        ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.82)), rgba(93, 179, 113, 0.4);'
        : theme?.colors.edamame[50];
  }
};

const getContentVariant = ({ variant }: IStyledToast) => {
  switch (variant) {
    case 'warning':
      return Theme.colors.ginger[500];
    case 'error':
      return Theme.colors.red[500];
    default:
      return Theme.colors.edamame[400];
  }
};

export const ToastWrapper = styled(motion.div)<IStyledToast>`
  width: 400px;
  background: ${({ variant, transparent, theme }) =>
    getWrapperVariant({
      variant,
      transparent,
      theme
    })};
  backdrop-filter: ${({ transparent }) =>
    transparent ? 'blur(3px)' : undefined};
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;

  ${mediaQueries('sm')(`
      width: calc(100% - 20px);
  `)}
  cursor: pointer;
  pointer-events: all;
`;

export const ToastIconContainer = styled.div<IStyledToast>`
  margin-right: 12px;
  border-radius: 21px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  background: ${({ variant }) => getContentVariant({ variant })};
  ${mediaQueries('xs')(`
    padding: 0px 2px;
  `)}
`;

export const ToastContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ToastClose = styled.div`
  cursor: pointer;
  margin-left: 8px;
`;

export const ToastTypo = styled.div``;

const getIcon = ({ variant }: IStyledToast) => {
  switch (variant) {
    case 'warning':
      return <Warning width={19} height={19} color="white" />;
    case 'error':
      return <Cross width={19} height={19} color="white" />;
    default:
      return <Check width={19} height={19} color="white" />;
  }
};

export const ToastTitle: React.FC<{
  children: string;
  variant: IStyledToast['variant'];
}> = ({ children, variant }) => {
  return (
    <Body
      element="p"
      size={BodySize.Body80}
      color={getContentVariant({ variant })}
      variant={TypographyVariant.SemiBold}
    >
      {children}
    </Body>
  );
};

export const ToastDescription: React.FC<{ children: string }> = ({
  children
}) => (
  <Body
    element="p"
    size={BodySize.Body60}
    color={Theme.colors.gray[800]}
    variant={TypographyVariant.Regular}
  >
    {children}
  </Body>
);

export const ToastIcon: React.FC<{ variant: IStyledToast['variant'] }> = ({
  variant
}) => {
  return (
    <ToastIconContainer variant={variant}>
      {getIcon({ variant })}
    </ToastIconContainer>
  );
};
