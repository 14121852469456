import { useQuery } from '@apollo/client';
import { GetPatientConsentDataDocument } from '../graphql/queries.generated';
import { usePatient } from '../../patientData/hooks/usePatient';

export const usePatientConsentData = () => {
  const { id } = usePatient();
  const { data, loading, error } = useQuery(GetPatientConsentDataDocument, {
    variables: {
      id: id!
    },
    skip: !id
  });
  return {
    data,
    loading,
    error
  };
};
