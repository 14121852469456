/** @deprecated Please use the ```createSafeStorage``` api */
// eslint-disable-next-line @typescript-eslint/naming-convention
export enum STORAGE_KEYS {
  PLATFORM = 'unobravo.platform',
  PREV_LOCATION = 'unobravo.prevlocation',
  AB_TEST_SIGNUP_REGISTRATION_STEPS = 'unobravo.ab-test-signup-registration-steps',
  AB_TEST_SKIP_PREFERENCES = 'unobravo.ab-test-skip-preferences'
}
/** @deprecated Please use the ```createSafeStorage``` api */
export const getSessionStoredData: <T>(key: STORAGE_KEYS) => T = (
  key: STORAGE_KEYS
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const value = window.sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

/** @deprecated Please use the ```createSafeStorage``` api */
export const setSessionStoredData: <T>(key: STORAGE_KEYS, value: T) => void = (
  key,
  value
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
) => window.sessionStorage.setItem(key, JSON.stringify(value));

export const removeItem: (key: STORAGE_KEYS) => void = (key) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.sessionStorage.removeItem(key);
