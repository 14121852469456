import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import {
  Box,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useEffect } from 'react';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { useNewAchievements } from '../hooks/useNewAchievements';
import { Voucher } from '../components/Voucher';
import { useListAchievements } from '../hooks/useListAchievements';

const AchievementsGrid = styled(Box)<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: repeat(${({ isMobile }) => (isMobile ? 1 : 2)}, 1fr);
  gap: 16px;
`;

const ImageWrapper = styled(Box)<{ imageUrl: string }>`
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-size: cover;
`;

const VouchersPageInternal = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const listAchievements = useListAchievements();

  const { hasNewAchievements, setAchievementsRead } = useNewAchievements();

  useEffect(() => {
    if (!hasNewAchievements) return;
    setAchievementsRead();
  }, [hasNewAchievements, setAchievementsRead]);

  return (
    <PatientLayout>
      <PageContainer>
        <PageContainerHeader>
          <Body
            size={BodySize.Body80}
            variant={TypographyVariant.SemiBold}
            margin="10px"
          >
            {t('vouchers.pageTitle')}
          </Body>
        </PageContainerHeader>

        <Stack direction="column" py="2xl" px="md" spacing="md">
          <Stack direction="column" spacing="xs" pb="2xl" maxW="710px">
            <Heading variant="xl" fontWeight="semibold" color="black">
              {t('vouchers.title')}
            </Heading>

            <Text variant="lg">{t('vouchers.description')}</Text>
          </Stack>
          <AchievementsGrid isMobile={isMobile}>
            {listAchievements.map(
              ({ tag, description, image, subtitle, title, voucher }) => (
                <Stack
                  bgColor="cappuccino.50"
                  rounded="md"
                  key={tag}
                  p="md"
                  direction="column"
                  spacing="2xl"
                >
                  <Stack direction="column" spacing="2xs">
                    <ImageWrapper w="86px" h="86px" imageUrl={image} />
                    <Text variant="xl" fontWeight="bold">
                      {title}
                    </Text>

                    <Text variant="md" fontWeight="bold">
                      {subtitle}
                    </Text>

                    <Text variant="md">{description}</Text>
                  </Stack>
                  {voucher && <Voucher tag={tag} voucher={voucher} />}
                </Stack>
              )
            )}
          </AchievementsGrid>
        </Stack>
      </PageContainer>
    </PatientLayout>
  );
};

export const VouchersPage = () => {
  const { ptVouchers } = usePatientFeatureFlags();

  if (!ptVouchers) return null;

  return <VouchersPageInternal />;
};
