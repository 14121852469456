import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { usePatient } from '../../patientData/hooks/usePatient';
import { SideBarMenu } from '../components/SidebarMenu';
import { AddCard } from './addCard';
import { BonusPage } from './bonus';
import { ChangeEmail } from './changeEmail';
import { ChangePsw } from './changePsw';
import { DeleteAccount } from './deleteAccount';
import { InformedConsent } from './InformedConsent';
import { Payment } from './payment';
import { PersonalInfo } from './personalInfo';

const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RedirectNotMobile: React.FC = () => {
  const { isMobile } = useViewport();
  if (isMobile) {
    return <SideBarMenu />;
  }
  return <Navigate to="personalInformation" replace />;
};

export const Preferences: React.FC = () => {
  const { isMobile } = useViewport();
  const { bonusPsicologo: featureFlagBonus } = usePatientFeatureFlags();
  const { hasDoctorMatch } = usePatient();

  return (
    <PatientLayout>
      <SidebarContainer>
        {!isMobile && <SideBarMenu />}
        <Routes>
          <Route path="/" element={<RedirectNotMobile />} />
          <Route path="/personalInformation" element={<PersonalInfo />} />
          <Route path="/changePassword" element={<ChangePsw />} />
          <Route path="/changeEmail" element={<ChangeEmail />} />

          {featureFlagBonus && <Route path="/bonus" element={<BonusPage />} />}

          <Route path="/deleteAccount" element={<DeleteAccount />} />
          <Route path="*" element={<Navigate to="/" replace />} />

          {hasDoctorMatch && (
            <>
              <Route path="/informedConsent" element={<InformedConsent />} />
              <Route path="/paymentMethod" element={<Payment />} />
              <Route path="/paymentMethod/addCard" element={<AddCard />} />
            </>
          )}
        </Routes>
      </SidebarContainer>
    </PatientLayout>
  );
};
