import { useEffect, useState } from 'react';
import type { ABTestValues } from '../types/ab-testing';
import { ActiveABTests } from '../types/ab-testing';

declare global {
  interface Window {
    /** AB Testing */
    abtesting: Partial<ABTestValues>;
  }
}

const getValue = (name: ActiveABTests) => {
  if (typeof window === 'undefined') return undefined;

  if (window.abtesting?.[name]) return window.abtesting?.[name];
  return undefined;
};

const AB_TEST_EVENT_NAME = 'abFlagChanged';

export const useABTest = <ABTest extends ActiveABTests>(
  name: ABTest,
  ...names: ActiveABTests[]
) => {
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState<ABTestValues[ABTest]>();
  const [values, setValues] = useState<ABTestValues>({} as ABTestValues);

  useEffect(() => {
    setLoaded(true);
    setValue(getValue(name) as ABTestValues[ABTest]);
    names.forEach((n) => setValues((prev) => ({ ...prev, [n]: getValue(n) })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    const handleCustomEvent = () => {
      const newValue = getValue(name);

      if (!newValue) return;

      setValue(newValue as ABTestValues[ABTest]);
      names.forEach((n) =>
        setValues((prev) =>
          getValue(n) ? { ...prev, [n]: getValue(n) } : prev
        )
      );
    };

    // Listen for the custom event
    window.addEventListener(AB_TEST_EVENT_NAME, handleCustomEvent);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener(AB_TEST_EVENT_NAME, handleCustomEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return [loaded, value, values] as const;
};
