import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { useCountry } from '@unobravo/translations';
import { Color } from '@unobravo/zenit-core';
import { Box, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { useWeeklyAgenda } from '../hooks/useWeeklyAgenda';
import { AppointmentCard } from './AppointmentCard';
import { DayItem } from './DayItem';
import { WeekDaySelector } from './WeekDaySelector';
import { NoAppointmentCardBubble } from './NoAppointmentCardBubble';
import { useBundle } from '../../bundles/hooks/useBundle';

const backgroundColorMap = {
  CANDY: 'candy.50',
  SPINACH: 'spinach.50',
  BERRY: 'berry.50'
};

const getBackgroundColor = (
  isBookableWeek: boolean,
  hasSessionsInRestWeek: boolean,
  isRestWeek: boolean,
  hasSessionsConfirmed: boolean
) => {
  if (hasSessionsConfirmed) {
    return backgroundColorMap.SPINACH;
  }
  // I have a week in the future that should be for rest [BERRY], but it has some booked sessions, so it turns into the color CANDY
  if (isBookableWeek && hasSessionsInRestWeek) {
    return backgroundColorMap.CANDY;
  }
  if (isRestWeek) {
    return backgroundColorMap.BERRY;
  }
  /** is not bookable but has some sessions done */
  if (!isBookableWeek) {
    return backgroundColorMap.SPINACH;
  }

  return backgroundColorMap.CANDY;
};

/**
 * Weekly Agenda component
 */
export function WeeklyAgenda({ agendaDay }: { agendaDay?: DateTime }) {
  const [selectedDay, setSelectedDay] = useState<DateTime>(
    agendaDay || DateTime.now().startOf('day')
  );
  const { domainCountry } = useCountry();
  const {
    pastSessions,
    loadAppointments,
    hasPreviousPage,
    firstCursorPast,
    status
  } = useAgenda();
  const {
    currentWeek,
    sessionsInSelectedWeek,
    sessionsBookedInSelectedWeek,
    sessionsNotBookedInSelectedWeek,
    isBookableWeek,
    isRestWeek,
    hasSessionsInRestWeek,
    expiredSessionsReminder,
    notificationCount,
    hasSessionsConfirmed,
    hasAtLeastOneSessionToPay
  } = useWeeklyAgenda({ selectedDay });
  const today = DateTime.now();
  const { t } = useTranslation();
  const { shouldPayWithBundle } = useBundle();

  const isLoading = status === 'loading';

  /**
   * On day selected handle callback
   * @param day selected day
   */
  const onDaySelected = (day: DateTime) => {
    setSelectedDay(day);
    const cursorSession = pastSessions?.find(
      (session: IAppointment) => session.id === String(firstCursorPast)
    );
    const cursorPassed =
      cursorSession && DateTime.fromISO(cursorSession.when) >= day; // I have passed the cursor page, so I need to fetch new data

    const shouldFetch = hasPreviousPage && cursorPassed;
    if (shouldFetch) loadAppointments();
  };

  return (
    <RStack
      px="md"
      maxW="740px"
      minW={{ base: undefined, sm: '356px' }}
      direction="column"
      pb="md"
      rounded="md"
      position="relative"
      bgColor={
        getBackgroundColor(
          isBookableWeek,
          hasSessionsInRestWeek,
          isRestWeek,
          hasSessionsConfirmed
        ) as Color
      }
      style={{ flex: 1 }}
    >
      <WeekDaySelector
        selectedDay={selectedDay}
        setSelectedDay={onDaySelected}
        country={domainCountry}
        notificationCount={notificationCount}
      />
      <Stack
        direction="row"
        justify="space-between"
        data-testid="week-container"
      >
        {/** build weekly day calendar */}
        {currentWeek.map(({ dayOfWeek, dayOfMonth, date }) => {
          const dateIso = date.toISODate();
          const isToday = dateIso === today.toISODate();
          const sessionsInDay = sessionsInSelectedWeek?.filter((elm) => {
            const when = DateTime.fromISO(elm.when);
            return when.day === date.day;
          });
          const isBookable = !isRestWeek && (isToday || today < date);
          return (
            <DayItem
              key={dateIso}
              dayOfWeek={dayOfWeek}
              dayOfMonth={dayOfMonth}
              date={date}
              selectedDay={selectedDay}
              sessions={sessionsInDay || []}
              isBookable={isBookable}
            />
          );
        })}
      </Stack>

      {/** CARDS */}
      {!isLoading && sessionsInSelectedWeek?.length === 0 && (
        <NoAppointmentCardBubble isRestWeek={isRestWeek} />
      )}
      {sessionsBookedInSelectedWeek?.map((session) => {
        return <AppointmentCard appointment={session} key={session.id} />;
      })}
      {sessionsNotBookedInSelectedWeek?.map((session) => {
        return (
          <AppointmentCard
            appointment={session}
            key={session.id}
            showExpiredCTA={expiredSessionsReminder}
          />
        );
      })}

      {shouldPayWithBundle && hasAtLeastOneSessionToPay && (
        <Box pt="sm">
          <Text color="grey.600" variant="sm">
            {t('therapySetting.hpCard.payWithBundleNote')}
          </Text>
        </Box>
      )}
    </RStack>
  );
}
