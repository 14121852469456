import { PaymentElement } from '@stripe/react-stripe-js';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import maestroLogo from '@unobravo-monorepo/patient/assets/maestro-logo.svg';
import mastercardLogo from '@unobravo-monorepo/patient/assets/mastercard-logo.svg';
import stripeLogo from '@unobravo-monorepo/patient/assets/stripe-logo.svg';
import visaLogo from '@unobravo-monorepo/patient/assets/visa-logo.svg';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import { ICreditCard, ISepaDebit } from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { Box, Button, Link, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFootnote } from '../../../../../common/src/utils/priceUtils';
import {
  paySessionDataSelector,
  setSelectedCard
} from '../../newPaySession/paySessionData.slice';
import { usePatientCards } from '../../patientData/hooks/usePatientCards';
import { PaymentMethodComp } from '../../payment/components/PaymentMethodComp';
import { usePaymentMethod } from '../../payment/hooks/usePaymentMethod';

const CursorBox = styled(Box)`
  cursor: pointer;
`;

const TextWrapper = styled(({ ...props }) => <Text {...props} />)`
  a,
  a:visited,
  a:hover,
  a:active {
    cursor: 'pointer';
  }
`;

type FormStatus = 'NEW_CARD' | 'CARD_LIST';

const StripeDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" align="center" spacing="sm" justify="center" mt="xs">
      <Text color="grey.600" variant="sm">
        {t('paymentMethod.securePayment')}
      </Text>
      <img src={stripeLogo} style={{ width: 46 }} alt="stripe-logo" />
      <Box borderRight="sm" borderColor="grey.200" h={20} />
      <img src={visaLogo} alt="visa-logo" style={{ width: 46 }} />
      <img src={mastercardLogo} alt="mastercard-logo" style={{ width: 35 }} />
      <img src={maestroLogo} alt="maestro-logo" style={{ width: 35 }} />
    </Stack>
  );
};

export const CardForm = ({
  pay,
  loading,
  amount,
  onBack,
  onError
}: {
  pay: (cardId?: string) => Promise<void>;
  loading?: boolean;
  amount: string;
  onBack?: () => void;
  onError?: () => void;
}) => {
  const [status, setStatus] = useState<FormStatus>();
  const [complete, setComplete] = useState<boolean>();

  const { pushAuthenticatedEvent } = usePatientGTM();
  const { addElementLoading, addElement } = usePaymentMethod();
  const { selectedCard, paymentIntent } = useSelector(paySessionDataSelector);
  const { t } = useTranslation();
  const { cards, sepaDebits, refreshPatientCards } = usePatientCards();
  const dispatch = useDispatch();
  const { domainCountry } = useCountry();

  const modifySelectedCard = (c: ICreditCard | ISepaDebit | 'klarna') =>
    dispatch(setSelectedCard(c));

  const enablePay =
    (status === 'CARD_LIST' && selectedCard != null) ||
    (status === 'NEW_CARD' && complete);

  useEffect(() => {
    if (addElementLoading || loading) return;
    if (
      (!selectedCard || !status) &&
      ((cards && cards.length > 0) || (sepaDebits && sepaDebits.length > 0))
    ) {
      setStatus('CARD_LIST');
      modifySelectedCard(
        selectedCard ??
          (cards?.[0] || (sepaDebits?.[0] as ICreditCard | ISepaDebit))
      );
    } else if (!status) {
      setStatus('NEW_CARD');
    }
  }, [cards, sepaDebits, status]);

  if (!cards || !sepaDebits) {
    return <Spinner />;
  }

  const footnote = getFootnote(domainCountry, paymentIntent?.amount);

  return (
    <>
      <Stack
        direction="column"
        grow
        data-testid="appointment-payment-stripe-info"
      >
        {status === 'CARD_LIST' ? (
          <Stack data-testid="payment-method-list" direction="column" px="xl">
            <Stack direction="column">
              <Box mb="sm">
                <Text color="grey.600">
                  {t('paySession.paymentMethod.choose')}
                </Text>
              </Box>
              {cards?.map((c) => (
                <CursorBox
                  onClick={() => modifySelectedCard(c)}
                  key={c.cardId}
                  data-testid="choose-credit-card"
                >
                  <PaymentMethodComp
                    paymentMethod={c}
                    isSelectedCard={
                      (selectedCard as ICreditCard)?.cardId === c.cardId
                    }
                  />
                </CursorBox>
              ))}
              {sepaDebits?.map((c) => (
                <CursorBox
                  onClick={() => modifySelectedCard(c)}
                  key={c.sepaId}
                  data-testid="choose-sepa-debit"
                >
                  <PaymentMethodComp
                    paymentMethod={c}
                    isSelectedCard={
                      (selectedCard as ISepaDebit)?.sepaId === c.sepaId
                    }
                  />
                </CursorBox>
              ))}
              <Box mt="sm">
                <TextWrapper variant="sm" color="grey.600">
                  <Trans
                    i18nKey="paySession.paymentMethod.buttonText"
                    components={[
                      <Link
                        data-testid="add-new-card"
                        color="candy.500"
                        to={() => setStatus('NEW_CARD')}
                        underline={false}
                      />
                    ]}
                    values={{ name: t('paySession.paymentMethod.button') }}
                  />
                </TextWrapper>
              </Box>
            </Stack>
          </Stack>
        ) : (
          status === 'NEW_CARD' && (
            <Stack
              direction="column"
              mx="xl"
              data-testid="new-card-section"
              spacing="lg"
            >
              <Text color="grey.600">
                {t('paymentMethod.stripeDescription')}
              </Text>
              <Stack direction="column" spacing="sm">
                <PaymentElement
                  options={{ layout: 'accordion' }}
                  onChange={(e: {
                    complete: boolean;
                    value: { type: string };
                  }) => {
                    if (e.complete && e?.value?.type === 'klarna') {
                      modifySelectedCard('klarna');
                    }
                    setComplete(e.complete);
                  }}
                />
                <Box mb="2xl">
                  <StripeDisclaimer />
                </Box>
              </Stack>
            </Stack>
          )
        )}
      </Stack>

      <Stack
        bottom={0}
        rounded="lg"
        bgColor="white"
        spacing="xs"
        p="xl"
        position="sticky"
        direction="column"
      >
        <RStack
          w="100%"
          direction={{ base: 'column-reverse', md: 'row' }}
          justify="space-between"
          spacing="xs"
        >
          <Button
            variant="ghost"
            onClick={() => onBack?.()}
            label={t('paymentMethod.back')}
            size="lg"
            iconPosition="left"
            data-testid="card-list-back-button"
            type="button"
          />
          <Button
            onClick={async () => {
              let cardId = null;
              if (
                complete &&
                status === 'NEW_CARD' &&
                selectedCard !== 'klarna'
              ) {
                cardId = await addElement();
                pushAuthenticatedEvent('NewPaymentMethod');
                if (cardId?.error) {
                  onError?.();
                  return;
                }
                if (cardId.payment_method) {
                  await refreshPatientCards();
                }
              }
              pay(cardId?.payment_method as string);
            }}
            label={`${t(`paySession.confirmPayment`, {
              cost: amount
            })}${footnote}`}
            loading={addElementLoading || loading}
            disabled={!enablePay}
            size="lg"
            data-testid="pay-session-button"
          />
        </RStack>
        {footnote && (
          <Text variant="sm" color="grey.600">
            {t('paySession.footnotes.esPriceChange')}
          </Text>
        )}
      </Stack>
    </>
  );
};
