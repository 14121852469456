import { BundlesVariations } from '@unobravo-monorepo/patient/types/Bundles'; // FIXME
import { Color } from '@unobravo/zenit-core';
import { bundlePriceMap } from './bundle-prices-map';

export type BundleMap = IBundle[];

export interface IBundle {
  id: string;
  value: string;
  title: string;
  finalPriceOutcome: string;
  price?: string;
  bulletPointList?: IBulletPoint[];
  chips?: IChip[];
}

interface IBulletPoint {
  id: string;
  text: string;
}

interface IChip {
  id: string;
  title: string;
  bgColor: Color;
  textColor: Color;
}

export const labels = {
  bundleTitle: 'bundles.paywall.bundleTitle',
  finalPriceOutcome: 'bundles.paywall.finalPriceOutcome',
  bundleBulletPointList: {
    item2: 'bundles.paywall.bundleBulletPointList.item2',
    item3: 'bundles.paywall.bundleBulletPointList.item3'
  },
  chips: {
    originalPrice: 'bundles.paywall.chips.originalPrice'
  },
  singleSession: {
    title: 'bundles.paywall.singleSession.title',
    finalPriceOutcome: 'bundles.paywall.singleSession.finalPriceOutcome',
    bundleBulletPointList: {
      item1: 'bundles.paywall.singleSession.bundleBulletPointList.item1',
      item2: 'bundles.paywall.singleSession.bundleBulletPointList.item2',
      item3: 'bundles.paywall.singleSession.bundleBulletPointList.item3'
    }
  },
  formCTAs: {
    singleSession: 'bundles.paywall.formCTAs.singleSession',
    multipleSessions: 'bundles.paywall.formCTAs.multipleSessions',
    skip: 'bundles.paywall.formCTAs.skip'
  }
};

/** UI  */
const bundleBulletPointListNoDiscount = [
  {
    id: 'item1',
    text: labels.bundleBulletPointList.item2
  },
  {
    id: 'item2',
    text: labels.bundleBulletPointList.item3
  }
];

export const getBundleMap = (hasDiscount = true): BundleMap => {
  return [
    {
      id: 'largeBundle',
      value: 'largeBundle',
      title: labels.bundleTitle,
      finalPriceOutcome: labels.finalPriceOutcome,
      bulletPointList: bundleBulletPointListNoDiscount,
      chips: hasDiscount
        ? [
            {
              id: 'chip1.1',
              title: labels.chips.originalPrice,
              bgColor: 'spinach.100',
              textColor: 'grey.900'
            }
          ]
        : []
    },
    {
      id: 'smallBundle',
      value: 'smallBundle',
      title: labels.bundleTitle,
      finalPriceOutcome: labels.finalPriceOutcome,
      bulletPointList: bundleBulletPointListNoDiscount,
      chips: hasDiscount
        ? [
            {
              id: 'chip1.2',
              title: labels.chips.originalPrice,
              bgColor: 'spinach.100',
              textColor: 'grey.900'
            }
          ]
        : []
    }
  ];
};

/**
 * Single session
 * Last option no-bundle at all
 */
export const getSingleSession = (hasDiscount = true): IBundle => {
  return {
    id: 'singleSession',
    value: 'singleSession',
    title: labels.singleSession.title,
    finalPriceOutcome: labels.singleSession.finalPriceOutcome,
    bulletPointList: hasDiscount
      ? [
          {
            id: 'item1',
            text: labels.singleSession.bundleBulletPointList.item1
          },
          {
            id: 'item2',
            text: labels.singleSession.bundleBulletPointList.item2
          },
          {
            id: 'item3',
            text: labels.singleSession.bundleBulletPointList.item3
          }
        ]
      : [
          {
            id: 'item1',
            text: labels.singleSession.bundleBulletPointList.item2
          },
          {
            id: 'item2',
            text: labels.singleSession.bundleBulletPointList.item3
          }
        ],
    chips: []
  };
};

export const getBundlesValues = () =>
  [...getBundleMap()].map((elm) => elm.value);

export const getBundlePriceInfo = (
  bundlesVariant: BundlesVariations,
  bundleId: string
) =>
  bundlePriceMap
    .find((bundles) => bundles.variant === bundlesVariant)
    ?.bundles.find((bundleElement) => bundleId === bundleElement.bundleId);
