import { Accordion, Box, useBreakpointValue } from '@unobravo/zenit-web';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useDoctorInfo } from '../../hooks/useDoctorInfo';
import { DoctorProfessionalAreas } from '../ProfileTp/DoctorProfessionalAreas';
import { DoctorReviews } from '../ProfileTp/DoctorReviews';

type AccordionItem = ComponentProps<typeof Accordion>['items'][0];

export const DoctorAccordions = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const { reviews, professionalAreas, name } = useDoctorInfo();
  const defaultValue = reviews.length > 0 ? 1 : 0;
  const therapistName = t('chat.profiletp.reviews', {
    name: name.split(' ')[0]
  });
  const items = [];
  if (professionalAreas?.length > 0) {
    items.unshift({
      title: t('chat.profiletp.tags'),
      children: <DoctorProfessionalAreas />
    });
  }
  if (reviews.length > 0) {
    items.push({
      title: `${therapistName.charAt(0).toUpperCase()}${therapistName.slice(
        1
      )}`,
      children: <DoctorReviews />
    });
  }
  return (
    <Box
      rounded="2xl"
      style={{ borderRadius: isMobile ? '32px 32px 0px 0px' : undefined }}
      bgColor="white"
      p="xs"
    >
      <Accordion
        value={defaultValue}
        items={items as AccordionItem[]}
        spacing="xs"
      />
    </Box>
  );
};
