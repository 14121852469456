import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const BonusPsicologo: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black,
  'data-testid': dataTestId
}) => {
  return (
    <svg
      data-testid={dataTestId}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08115 18.1386H6.66907C6.13046 18.1386 5.69393 17.7021 5.69393 17.1634V14.7504C5.69393 14.4918 5.59088 14.2439 5.4081 14.0611L3.70185 12.3548C3.32074 11.9737 3.32074 11.3564 3.70185 10.9762L5.4256 9.25248C5.59768 9.08039 5.69393 8.84803 5.69393 8.60498V6.16956C5.69393 5.63095 6.13046 5.19442 6.66907 5.19442H9.08213C9.34074 5.19442 9.58865 5.09137 9.77143 4.90859L11.4777 3.20234C11.8588 2.82123 12.4762 2.82123 12.8563 3.20234L14.5625 4.90859C14.7453 5.09137 14.9932 5.19442 15.2518 5.19442H17.6649C18.2035 5.19442 18.64 5.63095 18.64 6.16956V8.58262C18.64 8.84123 18.7431 9.08914 18.9259 9.27192L20.6321 10.9782C21.0132 11.3593 21.0132 11.9766 20.6321 12.3568L18.9259 14.063C18.7431 14.2458 18.64 14.4937 18.64 14.7523V17.1654C18.64 17.704 18.2035 18.1405 17.6649 18.1405H15.2518C14.9932 18.1405 14.7453 18.2436 14.5625 18.4264L12.8563 20.1326C12.4752 20.5137 11.8578 20.5137 11.4777 20.1326L9.77143 18.4264C9.58768 18.2407 9.33977 18.1386 9.08115 18.1386Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0827 10.2083L11.4369 13.8541L9.24939 11.6666"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
