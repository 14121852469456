import { createSlice } from '@reduxjs/toolkit';
import { PaymentIntent } from '@stripe/stripe-js';
import { IAppliedVoucher, ICreditCard, ISepaDebit } from '@unobravo/patient';
import { PaymentStep } from '@unobravo-monorepo/patient/features/newPaySession/types/paymentStepper.types';
import type { PatientRootState } from '../../store';

export type ParsedVoucher = Omit<IAppliedVoucher, 'total'> & {
  code?: string;
  total: string;
};

export interface IPaySessionState {
  step?: PaymentStep;
  voucher?: ParsedVoucher;
  selectedCard?: ICreditCard | ISepaDebit | 'klarna';
  paymentIntent?: PaymentIntent & { sessionId: string };
}

const initialState: IPaySessionState = {};

export const paySessionDataSlice = createSlice({
  name: 'paySessionData',
  initialState,
  reducers: {
    setSelectedCard: (
      state,
      action: { payload: ICreditCard | ISepaDebit | 'klarna'; type: string }
    ) => ({
      ...state,
      selectedCard: action.payload
    }),
    setVoucher: (
      state,
      action: {
        payload: ParsedVoucher | undefined;
        type: string;
      }
    ) => ({
      ...state,
      voucher: action.payload
    }),
    setStep: (state, action: { payload: PaymentStep; type: string }) => ({
      ...state,
      step: action.payload
    }),
    setPaymentIntent: (
      state,
      action: { payload: PaymentIntent & { sessionId: string }; type: string }
    ) => ({
      ...state,
      paymentIntent: action.payload
    }),
    reset: () => initialState
  }
});

export default paySessionDataSlice.reducer;

export const { setSelectedCard, setVoucher, setStep, setPaymentIntent, reset } =
  paySessionDataSlice.actions;

export const paySessionDataSelector = (
  state: PatientRootState
): IPaySessionState => state.paySessionData;
