import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetPatientOnboardingStatusQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Float'];
  doctorId: Types.Scalars['Float'];
}>;


export type GetPatientOnboardingStatusQuery = { __typename?: 'Query', getPatientOnboardingStatus: { __typename?: 'PatientOnboarding', variant?: Types.PatientHomepageStepperVariant | null, status?: Types.PatientOnboardingStatus | null } };

export type ListPatientBundlesQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Float'];
}>;


export type ListPatientBundlesQuery = { __typename?: 'Query', listPatientBundles?: Array<{ __typename?: 'PatientBundleDetails', bundleId: string, buyAt: any, description: string, numSessions: number, patientBundleId: string, patientId: number, price: number, sessionUsed: number, sessionsAvailables: number, validUntil: any } | null> | null };


export const GetPatientOnboardingStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetPatientOnboardingStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"doctorId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientOnboardingStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}},{"kind":"Argument","name":{"kind":"Name","value":"doctorId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"doctorId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variant"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<GetPatientOnboardingStatusQuery, GetPatientOnboardingStatusQueryVariables>;
export const ListPatientBundlesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ListPatientBundles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"listPatientBundles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bundleId"}},{"kind":"Field","name":{"kind":"Name","value":"buyAt"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"numSessions"}},{"kind":"Field","name":{"kind":"Name","value":"patientBundleId"}},{"kind":"Field","name":{"kind":"Name","value":"patientId"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"sessionUsed"}},{"kind":"Field","name":{"kind":"Name","value":"sessionsAvailables"}},{"kind":"Field","name":{"kind":"Name","value":"validUntil"}}]}}]}}]} as unknown as DocumentNode<ListPatientBundlesQuery, ListPatientBundlesQueryVariables>;