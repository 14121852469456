import imageGreen from '@unobravo-monorepo/patient/assets/img/image-green.svg';
import { ITranslatedSessionDate } from '@unobravo/core';
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { PaymentContext } from '../newPaySession/types/paymentContext';
import { usePatient } from '../patientData/hooks/usePatient';
import { DateContainer } from './components/DateContainer';

const Divider = () => (
  <Box
    w="80px"
    borderColor="cappuccino.200"
    borderTop="sm"
    my="lg"
    style={{ alignSelf: 'center' }}
  />
);

export const Completed = ({
  onClose,
  context,
  sessionDate
}: {
  onClose: () => void;
  context: PaymentContext;
  sessionDate: ITranslatedSessionDate;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const { name, doctor } = usePatient();
  const doctorName = doctor?.name;
  const doctorGender = doctor?.sex;

  return (
    <Stack
      style={{
        background: `url(${imageGreen})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left top'
      }}
      direction="column"
      justify="end"
      rounded="lg"
    >
      <Stack
        bgColor="white"
        rounded="lg"
        direction="column"
        justify="space-between"
        align="center"
        p="xl"
        style={{ marginTop: 200 }}
      >
        <Stack direction="column" py="lg" maxW={440}>
          <Heading
            variant="xl"
            textAlign="center"
            color="spinach.500"
            {...(isMobile ? { style: { fontSize: 28 } } : undefined)}
          >
            {t('paySession.paymentCompleted.title', {
              name
            })}
          </Heading>
          <Box my="md">
            <Text color="grey.800" variant="lg" textAlign="center">
              {t('paySession.paymentCompleted.subtitle')}
            </Text>
          </Box>
          <Divider />
          <DateContainer sessionDate={sessionDate} />
        </Stack>
        <Stack justify="center">
          {context === 'MOBILE' ? (
            <Text variant="lg">
              {t(`paySession.paymentCompleted.return.${context.toLowerCase()}`)}
            </Text>
          ) : (
            <Button
              onClick={() => onClose()}
              size="lg"
              label={t(
                `paySession.paymentCompleted.return.${context.toLowerCase()}`
              )}
              data-testid="session-confirmed-cta"
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
