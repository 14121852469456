/* eslint-disable class-methods-use-this */

interface GtmArgs {
  id: string;
  extend: string;
}

export class GoogleTagManager {
  public initialize(args: GtmArgs) {
    const gtm = this.gtm(args);

    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
    this.adChecker();
  }

  private gtm(args: GtmArgs) {
    const tags = this.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.appendChild(tags.iframe);
      return noscript;
    };

    const script = () => {
      const tagScript = document.createElement('script');
      tagScript.innerHTML = tags.script;
      return tagScript;
    };

    return {
      noScript,
      script
    };
  }

  private tags({ id, extend }: GtmArgs): {
    iframe: HTMLIFrameElement;
    script: string;
  } {
    const iframe = document.createElement('iframe');

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}${extend}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const script = `
         (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '${extend}';
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${id}');
        `;

    return {
      iframe,
      script
    };
  }

  private adChecker() {
    const googleAdUrl =
      'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    try {
      fetch(new Request(googleAdUrl)).catch(() =>
        window.dataLayer.push({ event: 'AdBlocker' })
      ); // use the event name you created in Step 1 here
    } catch (e) {
      window.dataLayer.push({ event: 'AdBlocker' });
    }
  }
}
