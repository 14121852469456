import { useEffect, useState } from 'react';

declare global {
  interface WindowEventMap {
    abTestVariant: CustomEvent;
  }
  interface Window {
    VWOVariant: Record<string, string>;
  }
}

const AB_TEST_VARIANT = 'abTestVariant';

export const useABTestVariant = (name: string) => {
  const [variant, setVariant] = useState<{
    name: string;
    variantName: string;
  }>();
  useEffect(() => {
    if (window.VWOVariant && window.VWOVariant[name]) {
      setVariant({ name, variantName: window.VWOVariant[name] });
    }
    const handle = (event: CustomEvent) => {
      name === event.detail.name && setVariant(event.detail);
    };

    window.addEventListener(AB_TEST_VARIANT, handle);

    return () => {
      window.removeEventListener(AB_TEST_VARIANT, handle);
    };
  }, []);

  return variant?.variantName;
};
