import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Trash: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8H6.5M19 8H17.5M17.5 8V17C17.5 18.1046 16.6046 19 15.5 19H8.5C7.39543 19 6.5 18.1046 6.5 17V8M17.5 8H6.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.5 8V6C9.5 5.44772 9.94772 5 10.5 5H13.5C14.0523 5 14.5 5.44772 14.5 6V8"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 12V15M10 12L10 15"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
