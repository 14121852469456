import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography';
import stripeLogo from '@unobravo-monorepo/patient/assets/stripe-logo.svg';
import visaLogo from '@unobravo-monorepo/patient/assets/visa-logo.svg';
import mastercardLogo from '@unobravo-monorepo/patient/assets/mastercard-logo.svg';
import maestroLogo from '@unobravo-monorepo/patient/assets/maestro-logo.svg';
import { Alert } from '@unobravo-monorepo/common/components/Alert';

const Logo = styled.img`
  width: 56px;
  margin: 2px 6px;
`;

const LogoSmall = styled.img`
  width: 40px;
  margin: 2px 6px;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 0 8px 16px 8px;
  justify-content: center;
  ${mediaQueries('md')`
    margin: 0 8px 8px;
  `}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StripeContainer = styled.div`
  border-right: 1px solid ${Theme.colors.gray[100]};
  align-items: center;
  display: flex;
`;

interface IStripeDisclaimer {
  isSavingCard?: boolean;
  noStripeLink?: boolean;
}

export const StripeDisclaimer: React.FC<IStripeDisclaimer> = ({
  isSavingCard,
  noStripeLink
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LogoContainer>
        <StripeContainer>
          <Body size={BodySize.Body50} color={Theme.specialColors.black}>
            {t('paymentMethod.footer')}
          </Body>
          <Logo src={stripeLogo} />
        </StripeContainer>
        <Logo src={visaLogo} />
        <LogoSmall src={mastercardLogo} />
        <LogoSmall src={maestroLogo} />
      </LogoContainer>
      <Alert
        message={
          isSavingCard
            ? t('paymentMethod.saveCardDescription')
            : t(`paymentMethod.description${noStripeLink ? 'NoLink' : ''}`)
        }
        showIcon
        margin="0"
      />
    </Wrapper>
  );
};
