const {
  NX_APP_DOMAIN_URL: domainUrl,
  NX_APP_ZENDESK_URL: defaultUrl,
  NX_APP_ZENDESK_URL_CI: ciUrl,
  NX_APP_ZENDESK_URL_DOCTOR: communityUrl,
  NX_APP_ES_DOMAIN_URL: esDomainUrl,
  NX_APP_ES_ZENDESK_URL: esDefaultUrl,
  NX_APP_ES_ZENDESK_URL_CI: esCiUrl,
  NX_APP_ES_ZENDESK_URL_DOCTOR: esCommunityUrl,
  NX_APP_FR_DOMAIN_URL: frDomainUrl,
  NX_APP_FR_ZENDESK_URL: frDefaultUrl,
  NX_APP_FR_ZENDESK_URL_CI: frCiUrl,
  NX_APP_FR_ZENDESK_URL_DOCTOR: frCommunityUrl
} = process.env;

const mappedBrands: Record<string, string> = {
  [defaultUrl!]: domainUrl!,
  [ciUrl!]: domainUrl!,
  [communityUrl!]: domainUrl!,
  [esDefaultUrl!]: esDomainUrl!,
  [esCiUrl!]: esDomainUrl!,
  [esCommunityUrl!]: esDomainUrl!,
  [frDefaultUrl!]: frDomainUrl!,
  [frCiUrl!]: frDomainUrl!,
  [frCommunityUrl!]: frDomainUrl!
};

export const getZendeskBrandDomain = (zendeskUrl: string): string => {
  return Object.keys(mappedBrands).reduce((prevDomain, brand) => {
    if (zendeskUrl.includes(brand)) {
      return mappedBrands[brand];
    }
    return prevDomain;
  }, domainUrl!);
};

export const zendeskRedirect = (zendeskUrl: string, zendeskToken: string) => {
  const url = decodeURIComponent(zendeskUrl || '');
  const returnTo = url?.split('&')[2]?.split('=')[1];
  // The authentication subdomain must always be the main brand (defaultUrl)
  window.location.replace(
    `https://${defaultUrl}.zendesk.com/access/jwt?jwt=${zendeskToken}&return_to=${returnTo}`
  );
};

export const zendeskRedirectNotLogged = (
  zendeskSubdomain: string | undefined,
  isDoctor: boolean,
  redirectUrl?: string
) => {
  window.open(
    `https://${
      zendeskSubdomain ?? isDoctor ? communityUrl : defaultUrl
    }.zendesk.com/${redirectUrl || ''}`,
    '_blank'
  );
};

export const zendeskRedirectLogged = (
  zendeskSubdomain: string | undefined,
  zendeskToken: string,
  redirectUrl?: string,
  target: '_self' | '_blank' = '_blank'
) => {
  const redirectTo = redirectUrl || '';
  const returnUrl = `https://${zendeskSubdomain}.zendesk.com/${redirectTo}`;

  // The authentication subdomain must always be the main brand (defaultUrl)
  const zendeskUrl = `https://${defaultUrl}.zendesk.com/access/jwt?jwt=${zendeskToken}&return_to=${encodeURIComponent(
    returnUrl
  )}`;
  window.open(zendeskUrl, target);
};
