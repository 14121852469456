import { Stack } from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';

const selector = 'script[src="https://js.stripe.com/v3/buy-button.js"]';

export const StripeBuyButton = ({
  customerSessionClientSecret,
  id,
  publishablekey
}: {
  customerSessionClientSecret?: string;
  id: string;
  publishablekey: string;
}) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadStripeScript = () => {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/buy-button.js';
      script.async = true;

      script.onload = () => {
        setScriptLoaded(true);
      };

      document.body.appendChild(script);
    };

    // check if the script is already present
    if (!document.querySelector(selector)) loadStripeScript();

    return () => {
      const script = document.querySelector(selector);
      if (script) script.remove();
    };
  }, []);

  return (
    <>
      {scriptLoaded && (
        <Stack justify="center">
          <stripe-buy-button
            customer-session-client-secret={customerSessionClientSecret}
            buy-button-id={id}
            publishable-key={publishablekey}
          />
        </Stack>
      )}
    </>
  );
};
