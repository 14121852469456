import { createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';

interface IChatbotState {
  isLoaded: boolean;
  isOverlayLoaded: boolean;
}

const initialState: IChatbotState = {
  isLoaded: false,
  isOverlayLoaded: false
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    chatbotLoaded: (state) => {
      state.isLoaded = true;
    },
    overlayLoaded: (state) => {
      state.isOverlayLoaded = true;
    }
  }
});

export default chatbotSlice.reducer;

export const { chatbotLoaded, overlayLoaded } = chatbotSlice.actions;

export const chatbotSelector = (state: PatientRootState): IChatbotState =>
  state.chatbot;
