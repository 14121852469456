import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../theme';
import { Check } from '../Icons';
import { Body, BodySize } from '../Typography/Body';
import IInputField from '../../types/IInputField';
import { TypographyVariant } from '../Typography';

interface ICheckboxField extends IInputField {
  none?: boolean;
  info?: string;
  errorMessage?: string;
  small?: boolean;
  'data-testid'?: string;
}

const CheckboxInput = styled.input.attrs({
  type: 'checkbox'
})<{ errorMessage?: string; small?: boolean }>`
  margin: 0;
  appearance: none;
  cursor: pointer;
  width: ${({ small }) => (small ? '14px' : '20px')};
  height: ${({ small }) => (small ? '14px' : '20px')};
  background-color: ${Theme.specialColors.white};
  border: ${Theme.colors.candy[500]} solid 1.5px;
  border-radius: ${({ small }) => (small ? 2 : 4)}px;

  &:checked:disabled {
    background-color: ${Theme.colors.candy[300]};
    border: ${Theme.colors.candy[300]} solid 1.5px;
  }

  &:disabled {
    border: ${Theme.colors.candy[300]} solid 1.5px;
  }

  &:checked {
    background-color: ${Theme.colors.candy[500]};
  }

  + svg {
    display: none;
  }

  &:checked + svg {
    position: absolute;
    pointer-events: none;
    display: block;
  }

  &:focus {
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 8px rgba(255, 120, 80, 0.25));
  }

  ${({ errorMessage }) =>
    errorMessage &&
    css`
      border: ${Theme.colors.red[500]} solid 1.5px;
    `}
`;

const Wrapper = styled.div<{
  withLabel?: boolean;
  small?: boolean;
  margin?: string;
}>`
  display: flex;
  cursor: pointer;
  align-items: start;
  ${({ withLabel, small }) =>
    withLabel &&
    css`
      margin-bottom: ${small ? 8 : 20}px;
    `}
`;

const InfoWrapper = styled.div`
  height: 0;
  position: relative;
  top: 2px;
`;

const CheckboxContainer = styled.div<{ small?: boolean }>`
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  margin-right: ${({ small }) => (small ? 6 : 16)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled.label`
  min-height: 26px;
  display: flex;
  flex-direction: column;
  margin-top: -3px;
  align-items: flex-start;
  justify-content: center;
  cursor: unset;
`;

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxField>(
  ({ ...props }, forwardedRef) => {
    const {
      label,
      small,
      info,
      errorMessage,
      'data-testid': dataTestid = 'checkbox-label',
      ...rest
    } = props;

    return (
      <Wrapper withLabel={!!label} small={small}>
        <CheckboxContainer data-testid={dataTestid} small={small}>
          <CheckboxInput
            errorMessage={errorMessage}
            ref={forwardedRef}
            {...rest}
            small={small}
            type="checkbox"
            data-testid="checkbox-input"
          />
          <Check
            width={small ? 12 : 16}
            height={small ? 12 : 16}
            color={Theme.specialColors.white}
          />
        </CheckboxContainer>

        {typeof label === 'string' ? (
          <StyledLabel htmlFor={props.id}>
            <Body
              size={small ? BodySize.Body60 : BodySize.Body70}
              color={props?.disabled ? Theme.colors.gray[500] : undefined}
              variant={TypographyVariant.Light}
            >
              {label}
            </Body>
            <InfoWrapper>
              {info && !errorMessage && (
                <Body
                  size={small ? BodySize.Body50 : BodySize.Body60}
                  variant={TypographyVariant.Light}
                  color={
                    props?.disabled
                      ? Theme.colors.gray[500]
                      : Theme.colors.gray[700]
                  }
                >
                  {info}
                </Body>
              )}
              {errorMessage && (
                <Body
                  size={small ? BodySize.Body50 : BodySize.Body60}
                  color={Theme.colors.red[500]}
                  variant={TypographyVariant.Light}
                  data-testid={`${dataTestid}-error`}
                >
                  {errorMessage}
                </Body>
              )}
            </InfoWrapper>
          </StyledLabel>
        ) : (
          label
        )}
      </Wrapper>
    );
  }
);

Checkbox.displayName = 'Checkbox';
