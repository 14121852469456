import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type IsTipFeedbackInsertedQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Float'];
  tipCode: Types.Scalars['String'];
}>;


export type IsTipFeedbackInsertedQuery = { __typename?: 'Query', isTipFeedbackInserted: boolean };


export const IsTipFeedbackInsertedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IsTipFeedbackInserted"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tipCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isTipFeedbackInserted"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"patientId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}},{"kind":"Argument","name":{"kind":"Name","value":"tipCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tipCode"}}}]}]}}]} as unknown as DocumentNode<IsTipFeedbackInsertedQuery, IsTipFeedbackInsertedQueryVariables>;