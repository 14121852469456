import React from 'react';
import styled from 'styled-components';
import { Box, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { Color, theme } from '@unobravo/zenit-core';
import { StepStatus } from '../../../types/IStep';

interface IStep {
  isLast?: boolean;
  status: StepStatus;
  label: string;
  index: number;
  expanded?: boolean;
}

const getColor = (status: StepStatus, isText?: boolean): Color => {
  switch (status) {
    case 'completed':
      return 'edamame.300';
    case 'active':
      return 'edamame.500';
    case 'inactive':
      return 'grey.200';
    case 'neutral':
      return isText ? 'black' : 'sand.100';
    default:
      return 'grey.200';
  }
};

const Line = styled(Box)<{ status: StepStatus }>`
  border-top: ${({ status }) =>
    status === 'completed'
      ? `1px solid ${theme.colors.edamame[300]}`
      : `1px dotted ${theme.colors.grey[200]}`};
  margin: 0 auto;
`;

export const Step = ({ label, status, isLast, index, expanded }: IStep) => {
  const { isMobile } = useBreakpointValue();

  return (
    <Stack align="center" grow={isLast ? 0 : 1} spacing="xl">
      <Stack align="center" spacing="md">
        <Box>
          <Stack
            rounded="full"
            h={22}
            w={22}
            justify="center"
            align="center"
            bgColor={getColor(status)}
          >
            <Text variant="sm" color={status === 'neutral' ? 'black' : 'white'}>
              {index.toString()}
            </Text>
          </Stack>
        </Box>
        {(!isMobile || expanded) && (
          <Box mr="xs">
            <Text variant="sm" color={getColor(status, true)}>
              {label}
            </Text>
          </Box>
        )}
      </Stack>
      {!isLast && <Line h={1} w={20} status={status} />}
    </Stack>
  );
};
