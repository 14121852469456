import { gql } from '@apollo/client';

export const GET_PRICE = gql`
  query PatientFromFirebaseId($id: String!) {
    getPatientFromFirebaseId(firebaseId: $id) {
      id
      pricing {
        amount
        fee
      }
    }
  }
`;
