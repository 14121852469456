import { DateTime } from 'luxon';
import {
  IAppointment,
  SessionStatus
} from '@unobravo-monorepo/common/types/IAppointments';

const DEFAULT_DAY_STATUS = 'NEW';

/**
 * Get session status
 */
export const getSessionStatus = ({
  session,
  today,
  date,
  isToday = false,
  showVideocall = true
}: {
  session?: IAppointment;
  today: DateTime;
  isToday: boolean;
  date: DateTime;
  showVideocall?: boolean;
}) => {
  const when = session && DateTime.fromISO(session?.when);

  if (session) {
    if (session?.canceled) {
      return 'CANCELED';
    }
    if (session?.paid) {
      if (isToday && showVideocall) {
        return 'VIDEOCALL';
      }
      if (today < date) {
        return 'CONFIRMED';
      }
      return 'DONE';
    }
    if (when && today > when?.plus({ minutes: 10 })) {
      return 'EXPIRED';
    }
    return DEFAULT_DAY_STATUS;
  }
  return undefined;
};

/**
 * Utils to remove duplicated sessions
 * @param sessions
 * @returns sessions without duplicates
 */
export const removeDuplicatedSessions = (sessions: IAppointment[]) =>
  sessions.reduce((accumulator: IAppointment[], currentValue) => {
    const existingObject = accumulator.find(
      (obj: IAppointment) => obj.id === currentValue.id
    );
    if (!existingObject) accumulator.push(currentValue);
    return accumulator;
  }, []);

/**
 * Filter sessions by status
 */
export const filterSessionsByStatus = ({
  session,
  statuses = [],
  includes = true
}: {
  session: IAppointment;
  statuses: SessionStatus[];
  includes?: boolean;
}) => {
  const today = DateTime.now();
  const sessionDate = DateTime.fromISO(session.when);
  const isToday = sessionDate.toISODate() === today.toISODate();
  const sessionStatus = getSessionStatus({
    isToday,
    session,
    today,
    date: sessionDate,
    showVideocall: true
  });

  return includes
    ? sessionStatus && statuses.includes(sessionStatus)
    : sessionStatus && !statuses.includes(sessionStatus);
};
