export const downloadBlob = (
  blob: Blob,
  filename?: string,
  target?: HTMLAnchorElement['target']
) => {
  const url = URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  if (target) {
    anchor.target = target;
  }
  if (filename) {
    anchor.download = filename;
  }
  anchor.click();
};

export const downloadPdf = (base64: string, filename?: string) => {
  const byteString = window.atob(base64);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  downloadBlob(blob, filename);
};

export const downloadPdfFile = async (
  downloadUrl: string,
  fileName: string,
  bearerToken?: string
) => {
  const response = await fetch(`${downloadUrl}`, {
    method: 'GET',
    headers: bearerToken ? { authorization: `Bearer ${bearerToken}` } : {}
  });
  const bodyResponse = await response.blob();
  downloadBlob(bodyResponse, fileName);
  return true;
};

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      const base64 = base64data.split(',')[1];
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export async function getFileUrl(url: string, bearerToken?: string) {
  const response = await fetch(url, {
    method: 'GET',
    headers: bearerToken ? { authorization: `Bearer ${bearerToken}` } : {}
  });
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}
