import { DoctorPresentationVariables } from '@unobravo/patient';
import { useMemo } from 'react';
import { ITpInfo } from '../types';

const SESSION_THRESHOLD = 100;
const PATIENT_ON_GOING_THRESHOLD = 20;

export const useTherapistInfo = (
  doctorPresentation: DoctorPresentationVariables | null | undefined,
  limit: number
) => {
  return useMemo<ITpInfo['variant'][]>(() => {
    const info = [] as ITpInfo['variant'][];

    if (
      (doctorPresentation?.ongoingPatientsCount ?? 0) >
      PATIENT_ON_GOING_THRESHOLD
    )
      info.push('patients');

    if ((doctorPresentation?.completedSessionsCount ?? 0) > SESSION_THRESHOLD)
      info.push('videocall');

    info.push('study', 'skills', 'care');

    return info.slice(0, limit);
  }, [doctorPresentation, limit]);
};
