import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { usePatient } from '@unobravo-monorepo/patient/features/patientData/hooks/usePatient';

export const InformedConsentTextFR = () => {
  const { t } = useTranslation();
  const text = ['FIRST_PARAGRAPH', 'SECOND_PARAGRAPH', 'THIRD_PARAGRAPH'];
  const { prefForWhom = 'ME' } = usePatient();
  const type =
    prefForWhom === 'PARTNER' || prefForWhom === 'ME' ? 'adult' : 'minor';
  return (
    <Box data-testid="consent-text" mb="md">
      <Box mb="md">
        {text.map((textElement, index) => (
          <Stack key={`dot_${index.toString()}`}>
            <Box mr="sm" ml="xs">
              <Text>•</Text>
            </Box>
            <Text variant="sm">
              {t(`informedConsent:MARKETPLACE.${type}.${textElement}`)}
            </Text>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};
