import { useCountry } from '@unobravo/translations';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createSafeStorage,
  StorageKeys
} from '@unobravo-monorepo/common/utils/storage';
import { usePatientFeatureFlags } from '../../featureFlags';
import { Indigo } from '../../shared/utils/indigo';
import { usePatient } from '../patientData/hooks/usePatient';
import { chatbotLoaded, overlayLoaded } from './chatbot.slice';

const { NX_APP_INDIGO_CHAT_SCRIPT: indigoChatScript } = process.env;

const [, setIndigoKey] = createSafeStorage(StorageKeys.indigoStorageKey);

/**
 * In charge of loading Indigo Script and attach close/open event listeners
 * @returns
 */
export const ChatBot = () => {
  const { indigoChatbot } = usePatientFeatureFlags();
  const { domainCountry } = useCountry();
  const { id, name = '', surname = '', email = '' } = usePatient();

  const dispatch = useDispatch();

  /**
   * Load Indigo Chat Bot script
   */
  useEffect(() => {
    let cleanupFn = () => null;
    if (domainCountry === 'it') {
      setIndigoKey(id);

      const patientName = `${name}${name && surname ? ' ' : ''}${surname}`;
      const patientEmail = email;

      const urlencode = encodeURIComponent(
        `init?custom_user_ref=${id}&name=${patientName}&email=${patientEmail}`
      );
      const urlConcatenated = `${indigoChatScript!}&init=${urlencode}`;

      const chat = Indigo.loadScript(urlConcatenated);
      cleanupFn = chat.cleanup as never;
    }
    return () => {
      cleanupFn();
    };
  }, [indigoChatbot, indigoChatScript]);

  /**
   * Initialize Indigo Chat Bot listeners
   */
  useEffect(() => {
    Indigo.initialize({
      onChatbotLoaded: () => {
        dispatch(chatbotLoaded());
      },
      onOverlayLoaded: () => {
        dispatch(overlayLoaded());
      }
    });
  }, [dispatch]);

  return <></>;
};
