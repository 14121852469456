import React from 'react';
import styled from 'styled-components';
import { Star } from '../Icons';

interface IRatingStars {
  numberOfStars: number;
  width?: number;
  height?: number;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RatingStars = ({ numberOfStars, height, width }: IRatingStars) => {
  return (
    <Wrapper>
      {[...Array(numberOfStars)].map((_, index) => (
        <Star key={index} height={height} width={width} />
      ))}
    </Wrapper>
  );
};
