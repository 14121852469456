export const pendoInitialize = (
  visitor: object,
  account?: { id: string; name?: string }
) => {
  const { pendo } = window;
  pendo?.initialize({
    visitor,
    ...(account ? { account } : {})
  });
};

export const pendoTrack = (eventName: string, additionalInfo?: object) => {
  const { pendo } = window;
  pendo?.track(eventName, additionalInfo);
};

export const isPendoActive = () => {
  const { pendo } = window;
  return !!pendo?.id;
};
