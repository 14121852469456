import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import { useTheme } from '@unobravo/zenit-core';
import { Blocked } from '@unobravo/zenit-icons';
import { RStack, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import styled from 'styled-components';

interface TherapistBoxProps {
  doctorOption: PreMatchOptionFragment;
  onClick: () => void;
  isSelected: boolean;
  tpDataTestId: string;
}

const TpImage = styled.img<{ available: boolean }>`
  border-radius: 100%;
  filter: ${({ available }) => (!available ? 'brightness(40%)' : 'none')};
`;

const NotAvailable = styled(Blocked)`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TherapistBox = ({
  doctorOption,
  onClick,
  isSelected,
  tpDataTestId
}: TherapistBoxProps) => {
  const { isMobile } = useBreakpointValue();

  const { colors } = useTheme();

  return (
    <RStack
      data-testid={tpDataTestId}
      style={{
        cursor: 'pointer',
        backgroundColor: !isSelected ? 'transparent' : colors.white
      }}
      rounded="xs"
      direction="column"
      justify={{ base: 'center', md: 'center' }}
      align="center"
      w={{ base: undefined, md: '122.5px' }}
      h={{ base: '90px', md: '137px' }}
      spacing={{ base: 'xs', md: 'sm' }}
      border={isSelected ? undefined : 'sm'}
      borderColor="cappuccino.300"
      onClick={onClick}
      pt={{ base: '2xs', md: undefined }}
    >
      <Stack
        position="relative"
        style={{ marginLeft: '22px', marginRight: '22px' }}
      >
        <TpImage
          available={doctorOption.isAvailable}
          src={doctorOption.doctor.profilePicture || ''}
          alt={`${doctorOption.doctor.name}-${doctorOption.doctor.surname}-profile-picture`}
          width={isMobile ? '48px' : '65px'}
          height={isMobile ? '48px' : '65px'}
        />

        {!doctorOption.isAvailable && (
          <NotAvailable
            width={isMobile ? '30px' : '45px'}
            height={isMobile ? '30px' : '45px'}
            color="white"
          />
        )}
      </Stack>

      <Text
        variant={isMobile ? 'xs' : 'sm'}
        fontWeight="semibold"
        color="black"
        textAlign="center"
      >
        {doctorOption.doctor.name}
      </Text>
    </RStack>
  );
};
