import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const ShieldCheck: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3191 10.3421L11.1711 14.4905L8.68213 12.0012"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1 5.92071C16.7149 5.73352 14.4513 4.792 12.637 3.23248C12.4587 3.08234 12.2331 3 12 3C11.7669 3 11.5413 3.08234 11.363 3.23248C9.5484 4.79158 7.28496 5.73304 4.90002 5.92071C4.65301 5.94279 4.42333 6.05698 4.2566 6.24058C4.08988 6.42418 3.99829 6.66378 4.00002 6.91179V11.2422C4.00002 15.6095 7.15601 19.7049 11.478 20.928C11.817 21.024 12.184 21.024 12.523 20.928C16.844 19.7039 20 15.6105 20 11.2432V6.91279C20.002 6.66461 19.9105 6.42476 19.7437 6.24095C19.577 6.05713 19.3472 5.94281 19.1 5.92071V5.92071Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
