import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import imgSunset from '@unobravo-monorepo/common/assets/image_sunset.svg';

export const Error = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();

  return (
    <Stack
      style={{
        background: `url('${imgSunset}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left top'
      }}
      direction="column"
      justify="end"
      grow
    >
      <Stack
        bgColor="white"
        rounded="lg"
        direction="column"
        justify="space-between"
        align="center"
        p="lg"
        grow
        style={{ marginTop: 300 }}
      >
        <Box pt="lg" {...(!isMobile ? { px: 'xl' } : undefined)}>
          <Heading
            variant="xl"
            textAlign="center"
            color="grey.800"
            {...(isMobile ? { style: { fontSize: 28 } } : undefined)}
          >
            {t('paySession.paymentError.title')}
          </Heading>
          <Box mt="md" mb="md" data-testid="payment-error-description">
            <Text
              color="grey.800"
              variant={isMobile ? 'md' : 'lg'}
              textAlign="center"
            >
              {t('paySession.paymentError.description')}
            </Text>
          </Box>
        </Box>
        <Button
          size="lg"
          onClick={() => onClose()}
          label={t(`paySession.paymentError.return`)}
          data-testid="payment-error-back-button"
        />
      </Stack>
    </Stack>
  );
};
