import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../../../patientData/hooks/usePatient';

export const InformedConsentTextIT = () => {
  const { t } = useTranslation();
  const { prefForWhom = 'ME', doctor } = usePatient();

  const description = t(
    `informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.DESCRIPTION`
  );

  return (
    <Box data-testid="consent-text">
      <Text>
        {t(`informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.INFORMED`)}
      </Text>
      <Box mb="md">
        {description.split('• ').map(
          (one, index) =>
            one.length > 0 && (
              <Stack key={`dot_${index.toString()}`}>
                <Box mr="sm" ml="xs">
                  <Text>•</Text>
                </Box>
                <Text variant="sm">
                  {one.charAt(0).toUpperCase() + one.slice(1)}
                </Text>
              </Stack>
            )
        )}
      </Box>

      <Text variant="sm">
        {t(
          `informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.PREFIX_DECLARATION`
        )}
      </Text>
      <Box mt="xs" mb="xs">
        <Text textAlign="center" fontWeight="semibold" variant="sm">
          {t(
            `informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.DECLARE`
          )}
        </Text>
      </Box>
      <Text variant="sm">
        {t(
          `informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.DECLARATION_OBJECT`
        )}
      </Text>
      <Box mt="xs" mb="xs">
        <Text textAlign="center" fontWeight="semibold" variant="sm">
          {t(`informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.ACCEPT`)}
        </Text>
      </Box>
      <Box mb="md">
        <Text variant="sm">
          {t(
            `informedConsent:HEALTHCARE.${prefForWhom?.toLowerCase()}.ACCEPTATION_OBJECT`,
            {
              doctorName: `${doctor?.name} ${doctor?.surname}`,
              registerNumber: doctor?.doctorRegisterNumber,
              registerRegion: doctor?.doctorRegisterRegion
            }
          )}
        </Text>
      </Box>
    </Box>
  );
};
