import { useMutation, useQuery } from '@apollo/client';
import { fadeIn } from '@unobravo-monorepo/common/utils/animations';
import { Action, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { usePatient } from '../patientData/hooks/usePatient';
import { IsTipFeedbackInsertedDocument } from './graphql/queries.generated';
import { TipFeedbackDocument } from './graphql/mutation.generated';

// Create a styled div that will use the fade-in animation
const FadeInStack = styled(Stack)`
  animation: ${fadeIn} 0.3s ease-out 0.3s forwards;
  opacity: 0;
`;

const TextWithMarginTop = styled(Text)`
  margin-top: 16px;
`;

export const PlanNextSessionsAlert = () => {
  const { t } = useTranslation();

  const { id } = usePatient();

  const { data, loading } = useQuery(IsTipFeedbackInsertedDocument, {
    variables: {
      patientId: id!,
      tipCode: 'PYNS'
    },
    skip: !id
  });

  const [tipFeedback] = useMutation(TipFeedbackDocument, {
    refetchQueries: [IsTipFeedbackInsertedDocument]
  });

  if (loading || data?.isTipFeedbackInserted || !id) return null;

  const onFeedback = (wasUseful: boolean) => {
    tipFeedback({
      variables: {
        patientId: id,
        tipCode: 'PYNS',
        score: wasUseful ? 1 : 0
      }
    });
  };

  return (
    <FadeInStack
      bgColor="smurf.50"
      rounded="md"
      p="md"
      direction="column"
      spacing="md"
    >
      <div>
        <Text variant="md" fontWeight="semibold" color="smurf.800">
          {t('planNextSessionAlert.title')}
        </Text>
        <Text variant="sm" color="grey.800">
          {t('planNextSessionAlert.content.firstParagraph')}
        </Text>
        <TextWithMarginTop variant="sm" color="grey.800">
          {t('planNextSessionAlert.content.secondParagraph')}
        </TextWithMarginTop>
      </div>
      <Stack direction="row" spacing="md">
        <Action
          colorVariant="informative"
          label={t('planNextSessionAlert.buttons.yes')}
          fullWidth
          data-testid="plan-next-session-feedback-yes"
          onClick={() => onFeedback(true)}
        />

        <Action
          colorVariant="informative"
          label={t('planNextSessionAlert.buttons.no')}
          fullWidth
          data-testid="plan-next-session-feedback-no"
          onClick={() => onFeedback(false)}
        />
      </Stack>
    </FadeInStack>
  );
};
