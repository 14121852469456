import { ReassignmentButton } from '@unobravo-monorepo/patient/features/reassignment/components/ReassignmentButton';
import { useTheme } from '@unobravo/zenit-core';
import { ArrowRight } from '@unobravo/zenit-icons';
import { ActionIcon, Box, RBox, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useDoctorInfo } from '../../../../shared/hooks/useDoctorInfo';
import { setShowDoctorInfoModal } from '../../home.slice';
import { Section } from '../Section';

const CARD_MAX_WIDTH = '740px';
const SIZE = 80;

const Img = styled.img`
  border-radius: 50%;
  width: ${SIZE}px;
  height: ${SIZE}px;
  object-fit: cover;
`;

export const DoctorInfoCard: React.FC<{
  width?: string | number;
}> = ({ width }) => {
  const { name, profilePicture, therapeuticOrientation, isTherapist, sex } =
    useDoctorInfo();
  const { t } = useTranslation();
  const { spacing } = useTheme();
  const dispatch = useDispatch();

  const onShowDoctorInfo = () => {
    dispatch(setShowDoctorInfoModal(true));
  };

  const SHADOW_SIZE = SIZE + 2 * spacing['2xs'];

  return (
    <>
      <RBox
        w="100%"
        maxW={
          width ?? {
            base: '100%',
            md: CARD_MAX_WIDTH
          }
        }
        data-testid="doctor-info-card"
      >
        <Section title={t('therapySetting.tpName')} gotoChat />
        <Stack p="md" spacing="xs" bgColor="cappuccino.100" rounded="lg">
          {profilePicture && (
            <Box
              p="2xs"
              bgColor="cappuccino.300"
              rounded="full"
              w={SHADOW_SIZE}
              h={SHADOW_SIZE}
              onClick={onShowDoctorInfo}
              style={{ cursor: 'pointer' }}
            >
              <Img alt="tp-profile-picture" src={profilePicture} />
            </Box>
          )}
          <Stack direction="row" justify="space-between" w="100%">
            <Stack direction="column" spacing="2xs" justify="space-between">
              <Text fontWeight="semibold" variant="lg" color="cappuccino.900">
                {name}
              </Text>
              <Text variant="sm" color="grey.700">
                {`${t(
                  isTherapist
                    ? 'selectYourTp.therapeuticOrientation_tp'
                    : sex === 'FEMALE'
                    ? 'selectYourTp.therapeuticOrientation_psyFEMALE'
                    : 'selectYourTp.therapeuticOrientation_psyMALE'
                )} ${therapeuticOrientation}`}
              </Text>
              <ReassignmentButton variant="link" />
            </Stack>
            <Stack align="end">
              <ActionIcon colorVariant="default" onClick={onShowDoctorInfo}>
                <ArrowRight size="sm" />
              </ActionIcon>
            </Stack>
          </Stack>
        </Stack>
      </RBox>
    </>
  );
};
