import { mediaQueries } from '@unobravo-monorepo/common';
import { Box } from '@unobravo/zenit-web';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const BORDER_RADIUS = 24;

interface ConfirmationScreenProps extends PropsWithChildren {
  backgroundUrl: string;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  text-align: center;
  ${mediaQueries('sm')(`
    border-radius: 24px 24px 0 0
  `)}
`;

const Image = styled.img`
  width: 100%;
`;

const BodyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0 0;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-grow: 1;
  margin-top: -${BORDER_RADIUS}px;
`;

const BodyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 350px;
  padding: 40px 20px 20px 20px;
  max-width: 530px;
`;

export const ConfirmationScreen: FC<ConfirmationScreenProps> = ({
  backgroundUrl,
  children
}) => {
  return (
    <Wrapper>
      <Box maxH={`calc(33% + ${BORDER_RADIUS}px)`} w="100%">
        <Image src={backgroundUrl} />
      </Box>
      <BodyContainer>
        <BodyWrapper>{children}</BodyWrapper>
      </BodyContainer>
    </Wrapper>
  );
};
