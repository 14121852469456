import { createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';

interface ITherapySurveyConsent {
  isConsentModalOpen: boolean;
}

const initialState: ITherapySurveyConsent = {
  isConsentModalOpen: false
};

export const therapySurveyConsentSlice = createSlice({
  name: 'therapySurveyConsent',
  initialState,
  reducers: {
    openTherapySurveyConsentModal: (state) => {
      state.isConsentModalOpen = true;
    },
    closeTherapySurveyConsentModal: (state) => {
      state.isConsentModalOpen = false;
    }
  }
});

export default therapySurveyConsentSlice.reducer;

export const { openTherapySurveyConsentModal, closeTherapySurveyConsentModal } =
  therapySurveyConsentSlice.actions;

export const therapySurveyConsentSelector = (
  state: PatientRootState
): ITherapySurveyConsent => state.therapySurveyConsent;
