import { gql } from '@apollo/client/core';

export const CONFIRM_USER_V2 = gql`
  mutation confirmUserV2(
    $token: String!
    $algorithmVersion: MatchAlgorithmVersion
  ) {
    confirmUserV2(token: $token, algorithmVersion: $algorithmVersion) {
      doctor {
        id
        name
        surname
      }
      patient {
        id
        uuid
        hashedEmail
        age
      }
      token
      verified
    }
  }
`;

export const SEND_VERIFICATION_EMAIL = gql`
  mutation sendVerificationEmail($patientId: Int!, $resend: Boolean) {
    sendVerificationEmail(patientId: $patientId, resend: $resend)
  }
`;
