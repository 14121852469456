import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { DotsIndicator, useBreakpointValue } from '@unobravo/zenit-web';
import { mediaQueries, Theme } from '../../theme';
import { Body, BodySize } from '../Typography';
import { CarouselIcon, CarouselIconLeft } from '../Icons';
import { Slider } from '../Slider/Slider';

type Item = {
  id?: string | number | undefined;
  text?: string | undefined;
  vote?: number | undefined;
};

type Props = {
  items: Item[];
  onChange?: (height: number | undefined) => void;
  sliderMargin?: string;
};

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${mediaQueries('sm')`
    flex-direction: row;
     overflow-x: auto;
     -ms-overflow-style: none;
     scrollbar-width: none;
     &::-webkit-scrollbar {
      display: none;
     }
  `};
`;

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CustomItem = styled.div`
  width: 100%;
  animation: ${opacityAnimation} 0.6s linear;
`;

const ItemMobile = styled.div`
  width: 100%;
  padding: 16px;
  flex-shrink: 0;
  border-radius: 16px;
  height: fit-content;
  margin-right: 16px;
  background-color: ${Theme.colors.cappuccino[50]};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: center;
`;

const Wrapper = styled.div<{ descriptionLength: number }>`
  border-radius: 15px;
  padding: 12px 16px 12px 16px;
  background-color: ${Theme.colors.cappuccino[50]};
  ${({ descriptionLength }) => css`
    transition: height;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.71, 0, 0.58, 1);
    height: ${descriptionLength + 65}px;
  `}
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
`;

const CounterWrapper = styled.div`
  display: flex;
  ${mediaQueries('sm')`
     margin-top:16px;
  `}
`;

const SliderContainer = styled.div<{ sliderMargin?: string }>`
  margin-left: ${({ sliderMargin }) => sliderMargin || '-16px'};
  margin-right: ${({ sliderMargin }) => sliderMargin || '-16px'};
`;

export const Carousel = ({ items, onChange, sliderMargin }: Props) => {
  const carouselItemRef = useRef<HTMLDivElement | null>(null);
  const [itemIndex, setItemIndex] = useState<number>(0);

  const { isMobile, breakpoint } = useBreakpointValue();
  const [descriptionLength, setDescriptionLength] = useState<number>(
    carouselItemRef?.current?.clientHeight || 0
  );

  const goBack = () => {
    if (itemIndex > 0) setItemIndex(itemIndex - 1);
  };

  const goNext = () => {
    if (itemIndex < items.length - 1) setItemIndex(itemIndex + 1);
  };

  const firstItem = itemIndex === 0;
  const lastItem = itemIndex === items.length - 1;

  useEffect(() => {
    setDescriptionLength(carouselItemRef?.current?.clientHeight || 0);
    if (carouselItemRef?.current?.clientHeight && onChange)
      onChange(carouselItemRef?.current?.clientHeight);
  }, [itemIndex]);

  return isMobile && breakpoint === 'sm' ? (
    <SliderContainer sliderMargin={sliderMargin}>
      <Slider>
        {items?.map(
          ({ id, text }, index) =>
            text && (
              <ItemMobile
                key={id}
                ref={itemIndex === index ? carouselItemRef : null}
              >
                <Body
                  textAlign="left"
                  size={BodySize.Body70}
                  color={Theme.colors.cappuccino[700]}
                >
                  {text}
                </Body>
              </ItemMobile>
            )
        )}
      </Slider>
    </SliderContainer>
  ) : (
    <Wrapper descriptionLength={descriptionLength}>
      <CarouselWrapper>
        {items?.map(({ id, text }, index) => {
          return (
            itemIndex === index &&
            text && (
              <CustomItem key={id} ref={carouselItemRef}>
                <Body
                  textAlign="left"
                  size={BodySize.Body60}
                  color={Theme.colors.cappuccino[700]}
                >
                  {text}
                </Body>
              </CustomItem>
            )
          );
        })}
      </CarouselWrapper>
      <Footer>
        <ArrowWrapper onClick={() => goBack()}>
          <CarouselIconLeft
            color={firstItem ? Theme.colors.gray[200] : Theme.colors.candy[500]}
          />
        </ArrowWrapper>
        <CounterWrapper>
          <DotsIndicator
            dots={items.length}
            colorScale="dark"
            activeDot={itemIndex}
          />
        </CounterWrapper>
        <ArrowWrapper onClick={() => goNext()}>
          <CarouselIcon
            color={lastItem ? Theme.colors.gray[200] : Theme.colors.candy[500]}
          />
        </ArrowWrapper>
      </Footer>
    </Wrapper>
  );
};
