import { DatePicker } from '@unobravo-monorepo/common/components/DatePicker';
import { ArrowRight } from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  Heading,
  ModalAlert,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { Interval } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AvailabilityDate = ({
  prevDate,
  onClose,
  onNext
}: {
  prevDate: Interval | undefined;
  onClose: () => void;
  onNext: (choosenDate: Interval) => void;
}) => {
  const [choosenDate, setChoosenDate] = useState<Interval | undefined>(
    prevDate
  );
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();

  return (
    <ModalAlert
      open
      w={700}
      onClose={onClose}
      dataTestId="availability-date-close"
    >
      <Stack direction="column" spacing="2xs" pb={isMobile ? 'xs' : '2xl'}>
        <Heading>{t('chat.availability.title')}</Heading>
        <Text color="grey.600">{t('chat.availability.dateDescription')}</Text>
      </Stack>
      <Box style={isMobile ? { overflow: 'scroll' } : {}}>
        <DatePicker
          selectedStartDate={choosenDate?.start ?? undefined}
          selectedEndDate={choosenDate?.end ?? undefined}
          setSelectedStartDate={(startDate) => {
            if (startDate) {
              setChoosenDate((date) =>
                Interval.fromDateTimes(
                  startDate,
                  date?.end ? date.end : startDate
                )
              );
            } else {
              setChoosenDate(undefined);
            }
          }}
          setSelectedEndDate={(endDate) => {
            if (endDate) {
              setChoosenDate((date) =>
                date?.start
                  ? Interval.fromDateTimes(date.start, endDate)
                  : undefined
              );
            } else {
              setChoosenDate((date) =>
                date?.start
                  ? Interval.fromDateTimes(date.start, date.start)
                  : undefined
              );
            }
          }}
          disableSunday
        />
      </Box>
      <Stack justify="space-between" pt="md" p="2xl">
        <Button
          data-testid="availability-date-close"
          variant="ghost"
          size="lg"
          label={t('cancel')}
          onClick={onClose}
        />
        <Button
          data-testid="availability-date-next"
          disabled={!choosenDate}
          iconPosition="right"
          size="lg"
          icon={ArrowRight}
          label={t('buttonNext')}
          onClick={() => choosenDate && onNext(choosenDate)}
        />
      </Stack>
    </ModalAlert>
  );
};
