import { useTranslation } from 'react-i18next';
import { useToast } from '../../features/toaster/hooks/useToast';

export const useErrorHandler = () => {
  const { t } = useTranslation();
  const { sendToast } = useToast();

  const sendGenericError = (network?: boolean) => {
    sendToast(
      {
        variant: 'error',
        title: t('common:errorPage.ops2'),
        description: t(`common:errorPage.${network ? 'network' : 'retry'}`),
        'data-testid': 'patient-message-toast'
      },
      undefined,
      'persistent'
    );
  };

  const sendErrorMessage = (message: string, title?: string) => {
    sendToast(
      {
        variant: 'error',
        title: title || t('common:errorPage.ops2'),
        description: message,
        'data-testid': 'error-message-toast'
      },
      undefined,
      'persistent'
    );
  };

  return { sendGenericError, sendErrorMessage };
};
