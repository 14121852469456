import { RStack } from '@unobravo/zenit-web';
import React from 'react';

interface CardProps {
  HeaderCard: React.ReactNode;
  children: React.ReactNode;
  type: 'small' | 'large';
}

export const Card = ({ HeaderCard, children, type }: CardProps) => {
  const h =
    type === 'large'
      ? { base: '204px', sm: '304px' }
      : { base: '171px', sm: '144px' };
  return (
    <RStack
      direction="column"
      rounded="md"
      bgColor="cappuccino.100"
      justify="space-between"
      p="md"
      h={h}
      w="100%"
    >
      {HeaderCard}
      {children}
    </RStack>
  );
};
