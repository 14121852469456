import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  IntlRender,
  useCountry,
  useInternationalVariables
} from '@unobravo/translations';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { Download } from '@unobravo/zenit-icons';
import { TransComponent } from '@unobravo-monorepo/common/components/Trans';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import { Button } from '@unobravo-monorepo/common/components/Button';
import {
  Body70,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import { Alert } from '@unobravo-monorepo/common/components/Alert';
import { getSimpleDate } from '@unobravo/core';
import {
  Eye,
  NewWindow,
  ShieldCheck
} from '@unobravo-monorepo/common/components/Icons';
import { ErrorPageContext } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useInformedConsentData } from '@unobravo/patient';
import { SectionHeader } from '../components/SectionHeader';
import { RightWrapperIllustration } from '../components/RightWrapperIllustration';
import { usePatient } from '../../patientData/hooks/usePatient';
import { ConsentFormModal } from '../components/InformedConsent/ConsentFormModal';
import { MinorSteps } from '../components/InformedConsent/MinorSteps';
import { useShouldUpdateInformedConsent } from '../../patientData/hooks/useShouldUpdateInformedConsent';
import { useZendesk } from '../../../shared/hooks/useZendesk';
import { SystemMessageAlert } from '../components/InformedConsent/SystemMessageAlert';
import { PartnerMessageAlert } from '../components/InformedConsent/PartnerMessageAlert';

const Wrapper = styled.div`
  background-color: ${Theme.specialColors.white};
  border-radius: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  z-index: 2;
  width: 70%;
  ${mediaQueries('sm')(`
    padding-bottom: 0;
  `)}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin: 20px 0 0 0;
  ${mediaQueries('sm')(`
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: ${Theme.specialColors.white};
  `)}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const PageContainerExtended = styled(PageContainer)`
  overflow: auto;
`;

const TransWrapper = styled.div`
  margin: 20px 0 0 0;
`;

const Divider = styled.div`
  border-top: 1px solid ${Theme.colors.cappuccino[200]};
  margin: 24px 0;
`;

const InformedConsentBody: React.FC<{
  setIsModalOpen: (status: boolean) => void;
}> = ({ setIsModalOpen }) => {
  const location = useLocation();
  const { informedConsent, timezone, prefForWhom, informedConsentAttachment } =
    usePatient();
  const { t } = useTranslation();
  const { consentData, loading, error } = useInformedConsentData();
  const { domainCountry } = useCountry();
  const { setGlobalError } = useContext(ErrorPageContext);
  const shouldUpdateIC = useShouldUpdateInformedConsent();
  const { goToZendeskAsLoggedUser } = useZendesk();
  const { signed, signDate } = consentData || {};
  const newCoupleConsent = prefForWhom === 'PARTNER';
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const hasInformedConsent = informedConsent && signed;
  const { zendeskCIUrl } = useInternationalVariables();

  const getDescription = () => {
    if (newCoupleConsent && !hasInformedConsent) {
      return `legal:informedConsent.infoConsentPartner`;
    }
    if (newCoupleConsent && !informedConsentAttachment) {
      return 'legal:informedConsent.descriptionFullAfterSignPartner';
    }
    if (prefForWhom === 'DAUGHTER') {
      return 'legal:informedConsent.infoConsentMinor';
    }
    if (shouldUpdateIC) {
      return 'legal:informedConsent.descriptionFullBeforeUpdate';
    }
    if (!hasInformedConsent) {
      return 'legal:informedConsent.descriptionFullBeforeSign';
    }
    return 'legal:informedConsent.descriptionFullAfterSign';
  };

  const getTitle = () => {
    if (shouldUpdateIC) {
      return t('legal:informedConsent.updateInfoConsent');
    }
    if (prefForWhom === 'DAUGHTER' || !signed) {
      return t(`legal:informedConsent.signInfoConsent`);
    }
    if (newCoupleConsent && !informedConsentAttachment) {
      return t('legal:informedConsent.signConsentToPartner');
    }
    return t(`legal:informedConsent.showConsent`);
  };

  const showAlert = Boolean(
    signDate &&
      !shouldUpdateIC &&
      prefForWhom !== 'DAUGHTER' &&
      !newCoupleConsent
  );

  useEffect(() => {
    if (error) {
      setGlobalError(true);
    }
  }, [error, setGlobalError]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <div data-testid="informed-consent">
        <Heading size={HeadingSize.Title80} margin="0 0 4px 0">
          {getTitle()}
        </Heading>
        <TransWrapper>
          <TransComponent
            styledBody={Body70}
            i18nkey={getDescription()}
            value={t('legal:informedConsent.support')}
            onClick={async () => {
              await goToZendeskAsLoggedUser('requests/new');
            }}
          />
          <IntlRender include={['it']}>
            <Body size={BodySize.Body70}>
              {t('legal:informedConsent.privacy')}
            </Body>
          </IntlRender>
        </TransWrapper>
        {(!isMobile || prefForWhom !== 'DAUGHTER') && !newCoupleConsent && (
          <TransWrapper>
            <SystemMessageAlert
              message={t('legal:informedConsent.infoConsent')}
            />
          </TransWrapper>
        )}
        {!isMobile &&
          newCoupleConsent &&
          hasInformedConsent &&
          !informedConsentAttachment && <PartnerMessageAlert />}
        {showAlert && (
          <Alert
            type="success"
            margin="16px 0 0 0"
            CustomIcon={ShieldCheck}
            message={t('legal:informedConsent.confirmedConsent', {
              date: getSimpleDate(signDate, timezone || undefined)
            })}
            data-testid="confirmed-consent-message"
          />
        )}
        {prefForWhom === 'DAUGHTER' && <MinorSteps />}
      </div>
      {hasInformedConsent && !shouldUpdateIC && !newCoupleConsent && (
        <ButtonWrapper>
          {prefForWhom !== 'DAUGHTER' && (
            <Button
              variant="primary"
              size="small"
              label={t('legal:informedConsent.showDocument')}
              iconPosition="right"
              icon={Eye}
              onClick={() => window.open(informedConsent)}
            />
          )}
          {prefForWhom === 'DAUGHTER' && (
            <>
              <Button
                data-testid="download-document-button"
                variant="secondary"
                size="small"
                label={t('legal:informedConsent.downloadDocument')}
                iconPosition="left"
                icon={Download}
                onClick={() => window.open(informedConsent)}
              />

              <Button
                data-testid="upload-document-button"
                variant="primary"
                size="small"
                label={t('legal:informedConsent.uploadDocument')}
                iconPosition="left"
                icon={NewWindow}
                onClick={async () => {
                  window.open(
                    `https://${zendeskCIUrl}.zendesk.com/hc/requests/new`
                  );
                }}
              />
            </>
          )}
        </ButtonWrapper>
      )}
      {(!hasInformedConsent || shouldUpdateIC) && (
        <ButtonWrapper>
          <Button
            data-testid="fill-consent-button"
            variant="primary"
            size="small"
            label={t('legal:informedConsent.fillConsent')}
            onClick={() => setIsModalOpen(true)}
          />
        </ButtonWrapper>
      )}
      {newCoupleConsent && hasInformedConsent && !shouldUpdateIC && (
        <>
          {signed && signDate && !informedConsentAttachment && (
            <ButtonWrapper>
              <Button
                data-testid="download-document-button"
                variant="secondary"
                size="small"
                label={t('legal:informedConsent.downloadPartnerDocument')}
                iconPosition="left"
                icon={Download}
                onClick={() =>
                  window.open(
                    `/docs/consenso-informato-partner-${domainCountry}.pdf`
                  )
                }
              />

              <Button
                data-testid="upload-document-button"
                variant="primary"
                size="small"
                label={t('legal:informedConsent.uploadModule')}
                onClick={() =>
                  navigate('../../dialog/informedConsent/partner/upload', {
                    state: { modal: location }
                  })
                }
              />
            </ButtonWrapper>
          )}
          <Divider />
          <Body size={BodySize.Body70} variant={TypographyVariant.SemiBold}>
            {t('legal:informedConsent.downloadCompletedDocuments')}
          </Body>
          <Body size={BodySize.Body70} margin="8px 0 20px 0">
            {t('legal:informedConsent.downloadCompletedDocumentsHere')}
          </Body>
          <ButtonsWrapper>
            <Button
              variant="secondary"
              label={t('legal:informedConsent.yourInformedConsent')}
              iconPosition="left"
              data-testid="download-user-consent-button"
              icon={Download}
              size="small"
              onClick={() => window.open(informedConsent)}
            />
            {informedConsentAttachment && (
              <Button
                variant="secondary"
                label={t('legal:informedConsent.partnerCItitle')}
                iconPosition="left"
                data-testid="download-partner-consent-button"
                icon={Download}
                size="small"
                onClick={() =>
                  window.open(informedConsentAttachment.downloadLink)
                }
              />
            )}
          </ButtonsWrapper>
        </>
      )}
    </Wrapper>
  );
};

export const InformedConsent: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <PageContainerExtended>
      <SectionHeader path="../" title={`${t('legal:informedConsent.title')}`} />
      <Container>
        <InformedConsentBody setIsModalOpen={setIsModalOpen} />
        <RightWrapperIllustration illustration="/assets/patient/infoConsentBackgroung.svg" />
      </Container>
      <ConsentFormModal
        context="WEBAPP"
        openModal={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </PageContainerExtended>
  );
};
