export { groupBy } from '@unobravo/core';

export const scrollToBottom = (
  divRef: HTMLDivElement | null,
  windowScroll?: boolean,
  smooth?: boolean
): boolean => {
  const toScroll = windowScroll ? window : divRef;

  if (!toScroll) {
    return false;
  }

  const top = windowScroll ? document.body.scrollHeight : divRef!.scrollHeight;

  toScroll.scrollTo({
    ...(smooth ? { behavior: 'smooth' } : {}),
    top
  });
  return true;
};
