import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { RadioButton } from '@unobravo-monorepo/common/components/RadioButton';
import { ArrowRight } from '@unobravo/zenit-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { useZendesk } from '../../../../shared/hooks/useZendesk';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { useReassignment } from '../../hooks/useReassignment';
import { closeTimeSlotModal } from '../../reassignment.slice';
import { useTimeSlot } from '../../hooks/useTimeSlot';
import { CONFIRM_REASSIGNMENT_PENDO_EVENT } from '../../consts';

interface StackChildren {
  children: React.ReactNode;
}

function StackButtons({ children }: StackChildren) {
  return (
    <RStack
      direction={{ base: 'column-reverse', md: 'row' }}
      justify="end"
      spacing="md"
      pt="md"
      align="start"
      alignContent="start"
    >
      {children}
    </RStack>
  );
}

export const TimeSlotsStep = () => {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const [slotProblem, setSlotProblem] = useState<string | undefined>();
  const { register, handleSubmit, reset, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: { reason: '' }
  });
  const { name: patientName, email, doctor } = usePatient();
  const { reassignmentLoading, closeModal, automaticReassign } =
    useReassignment();
  const { prefTime } = useTimeSlot();
  const navigate = useNavigate();
  const { goToZendeskAsLoggedUser } = useZendesk();
  const dispatch = useDispatch();

  const onSubmitButton = (data: { reason: string }) => {
    setSlotProblem(data.reason);
  };

  const onAutomaticReassignConfirm = () => {
    pendoTrack(CONFIRM_REASSIGNMENT_PENDO_EVENT);
    automaticReassign();
  };

  const reasons = [
    {
      label: t('reassignment.requestModal.secondChoice.reason1'),
      value: 'reassignment-timeslot-change-therapist'
    },
    {
      label: t('reassignment.requestModal.secondChoice.reason2'),
      value: 'reassignment-timeslot-change-survey'
    },
    {
      label: t('reassignment.requestModal.secondChoice.reason3'),
      value: 'reassignment-timeslot-contact-us',
      zendeskUrl: `hc/requests/new?ticket_form_id=13724037369617&tf_anonymous_requester_email=${email}&tf_17449127529233=incompatibilita_oraria_csp`
    }
  ];

  const back = () => {
    setSlotProblem(undefined);
    reset();
  };

  const backToRequestModal = () => {
    dispatch(closeTimeSlotModal());
  };

  const contactUs = () => {
    pendoTrack(CONFIRM_REASSIGNMENT_PENDO_EVENT);

    const url =
      reasons.find((one) => one.value === slotProblem)?.zendeskUrl ?? '';
    goToZendeskAsLoggedUser(url);
    closeModal();
  };

  const Step0Algorithm = (
    <>
      <form onSubmit={handleSubmit(onSubmitButton)}>
        <Stack direction="column" spacing="sm" mb="lg" align="start">
          <Heading variant="md">
            {t('reassignment.requestModal.secondChoice.title')}
          </Heading>
          <Box mb="md">
            <Text color="grey.800" variant="md">
              {t('reassignment.requestModal.secondChoice.description')}
            </Text>
            <Stack direction="row">
              {prefTime.map((pref, index) => (
                <Text key={pref} fontWeight="bold">
                  {index < prefTime.length - 1
                    ? `${t(`reassignment.timeSlotModal.${pref}`)},`
                    : t(`reassignment.timeSlotModal.${pref}`)}
                </Text>
              ))}
            </Stack>
          </Box>
          {reasons.map(({ label, value }) => (
            <RadioButton
              {...register('reason', { required: true })}
              key={value}
              label={label}
              value={value}
              dataTestId={value}
            />
          ))}
        </Stack>
        <StackButtons>
          <Button
            variant="ghost"
            label={t('common:back')}
            onClick={backToRequestModal}
            fullWidth={isMobile}
            data-testid="reassignment-timeslot-button-back"
          />

          <Button
            variant="outlined"
            label={t('common:buttonNext')}
            icon={ArrowRight}
            data-testid="reassignment-request-timeSlot-chosed"
            type="submit"
            fullWidth={isMobile}
            disabled={!watch('reason')}
          />
        </StackButtons>
      </form>
    </>
  );

  const Step1ChangeTherapist = (
    <>
      <Stack direction="column" spacing="sm" mb="lg" align="start">
        <Heading variant="md">
          {t('reassignment.timeSlotModal.changeTherapistChoice.title')}
        </Heading>
        <Box mb="md">
          <Text color="grey.600" variant="md">
            {t(
              'reassignment.timeSlotModal.changeTherapistChoice.firstDescription'
            )}
          </Text>
          <Text color="grey.600" variant="md">
            {t(
              'reassignment.timeSlotModal.changeTherapistChoice.secondDescription'
            )}
          </Text>
        </Box>
        <Text color="grey.600" variant="md">
          {t(`reassignment.timeSlotModal.changeTherapistChoice.conclusion`, {
            doctorName: doctor?.name
          })}
        </Text>
      </Stack>

      <StackButtons>
        <Button
          variant="ghost"
          label={t('common:back')}
          onClick={back}
          fullWidth={isMobile}
        />
        <Button
          label={t('reassignment.timeSlotModal.changeTherapistChoice.button')}
          data-testid={slotProblem}
          disabled={reassignmentLoading}
          loading={reassignmentLoading}
          onClick={onAutomaticReassignConfirm}
          fullWidth={isMobile}
        />
      </StackButtons>
    </>
  );

  const Step1NewSurvey = (
    <>
      <Stack direction="column" spacing="sm" mb="lg" align="start">
        <Heading variant="md">
          {t('reassignment.timeSlotModal.timeSlotSecondChoice.title', {
            patientName
          })}
        </Heading>
        <Text color="grey.600" variant="md">
          {t(
            'reassignment.timeSlotModal.timeSlotSecondChoice.firstDescription'
          )}
        </Text>
        <Box my="md">
          <Text color="grey.600" variant="md">
            {t(
              'reassignment.timeSlotModal.timeSlotSecondChoice.secondDescription',
              {
                doctorName: doctor?.name
              }
            )}
          </Text>
        </Box>
      </Stack>
      <StackButtons>
        <Button
          variant="ghost"
          label={t('common:back')}
          onClick={back}
          fullWidth={isMobile}
        />
        <RStack w={{ base: '100%', md: 'auto' }}>
          <Button
            label={t('reassignment.step1NewSurvey.button')}
            data-testid={slotProblem}
            disabled={reassignmentLoading}
            loading={reassignmentLoading}
            onClick={() => {
              navigate('/patient/new-survey');
              return dispatch(closeTimeSlotModal());
            }}
            fullWidth={isMobile}
          />
        </RStack>
      </StackButtons>
    </>
  );

  const Step1ContactComponent = (
    <>
      <Heading variant="md">
        {t('reassignment.timeSlotModal.timeSlotThirdChoice.title', {
          patientName
        })}
      </Heading>
      <Box my="md">
        <Text color="grey.600" variant="md">
          {t('reassignment.timeSlotModal.timeSlotThirdChoice.description')}
        </Text>
      </Box>
      <StackButtons>
        <Button
          variant="ghost"
          label={t('common:back')}
          onClick={back}
          fullWidth={isMobile}
        />
        <RStack w={{ base: '100%', md: 'auto' }}>
          <Button
            label={t('reassignment.step1Contact.contactUs')}
            data-testid={slotProblem}
            onClick={contactUs}
            fullWidth={isMobile}
          />
        </RStack>
      </StackButtons>
    </>
  );

  return (
    <>
      {!slotProblem && Step0Algorithm}
      {reasons[0].value === slotProblem && (
        <Stack direction="column">{Step1ChangeTherapist}</Stack>
      )}
      {reasons[1].value === slotProblem && (
        <Stack direction="column">{Step1NewSurvey}</Stack>
      )}
      {reasons[2].value === slotProblem && (
        <Stack direction="column">{Step1ContactComponent}</Stack>
      )}
    </>
  );
};
