import { useColor } from '@unobravo/zenit-core';

import { Box, Stack } from '@unobravo/zenit-web';
import { Checked } from './icons/Checked';
import { Unchecked } from './icons/Unchecked';

const SIZE = 20;

export const LeftIcon = ({
  checked,
  showDashedLine
}: {
  checked: boolean;
  showDashedLine: boolean;
}) => {
  const grey = useColor('grey.100');
  return (
    <Stack position="relative" mt="sm">
      <Box mb="sm">
        {checked ? (
          <Checked color="spinach.300" size={SIZE} />
        ) : (
          <Unchecked size={SIZE} />
        )}
      </Box>
      {showDashedLine && (
        <Box
          position="absolute"
          top={SIZE + 4}
          h="100%"
          style={{
            borderLeft: `1.5px dashed ${grey}`,
            marginLeft: SIZE / 2 - 0.75
          }}
        />
      )}
    </Stack>
  );
};
