import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { ToastsContainer } from './Toaster.styles';
import { useViewport } from '../../hooks/useViewportHook';
import Toast from '../Toast/Toast';
import { IToast } from '../../types/IToast';

interface IToaster {
  toasts: IToast[];
  closeToast: (toastId: string | number) => void;
  setHoverToast?: (toastId?: string | number) => void;
}

export const Toaster: React.FC<IToaster> = ({
  toasts,
  closeToast,
  setHoverToast
}) => {
  const { isMobile } = useViewport();

  const onMouseLeave = (toast: IToast) => {
    if (toast.expired) {
      closeToast(toast.id);
    }
    setHoverToast?.(undefined);
  };

  return (
    <ToastsContainer bottomOffset={toasts[0]?.bottomOffset}>
      <AnimatePresence>
        {toasts
          ?.slice(Math.max(toasts.length - (isMobile ? 2 : 6), 0))
          .map((toast) => (
            <Toast
              key={toast?.id}
              {...toast}
              onClose={() => closeToast(toast.id)}
              onMouseEnter={() => setHoverToast?.(toast.id)}
              onMouseLeave={() => onMouseLeave(toast)}
            />
          ))}
      </AnimatePresence>
    </ToastsContainer>
  );
};
