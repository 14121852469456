import { useMutation } from '@apollo/client';
import { useFirebaseUser } from '@unobravo/core';
import { SEND_VERIFICATION_EMAIL } from '../graphql/mutation';

export const useVerifyEmail = () => {
  const [sendVerificationEmail] = useMutation(SEND_VERIFICATION_EMAIL);

  const { currentUser } = useFirebaseUser();
  const { unbvId: patientId } = currentUser || {};

  const sendEmail = async (resend?: boolean) => {
    const res = await sendVerificationEmail({
      variables: { patientId, resend }
    });
    return res?.data?.sendVerificationEmail || {};
  };

  return {
    resend: () => sendEmail(true)
  };
};
