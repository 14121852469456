import { changeUser, initialize, openSession } from '@braze/web-sdk';
import * as Sentry from '@sentry/react';
import { useFirebaseUser } from '@unobravo/core';
import { FC, ReactNode, useEffect } from 'react';

const { NX_APP_ENVIRONMENT, NX_APP_BRAZE_API_KEY, NX_APP_BRAZE_BASE_URL } =
  process.env;

export const BrazeProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useFirebaseUser();

  useEffect(() => {
    if (NX_APP_BRAZE_BASE_URL && NX_APP_BRAZE_API_KEY) {
      initialize(NX_APP_BRAZE_API_KEY, {
        enableLogging: NX_APP_ENVIRONMENT !== 'production',
        baseUrl: NX_APP_BRAZE_BASE_URL
      });
      openSession();
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.unbvId) {
      const suffix =
        currentUser.role === 'doctor' ||
        currentUser.role === 'onboarding_doctor'
          ? 'TP-'
          : '';
      changeUser(`${suffix}${currentUser.unbvId}`);
      Sentry.setUser({
        id: currentUser.unbvId
      });
    }
  }, [currentUser]);

  return <>{children}</>;
};
