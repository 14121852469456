import { RadioButton } from '@unobravo-monorepo/common/components/RadioButton';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { ArrowRight } from '@unobravo/zenit-icons';
import {
  Alert,
  Box,
  Button,
  Heading,
  ModalAlert,
  RBox,
  RStack,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInternationalVariables } from '@unobravo/translations';
import { useZendesk } from '../../../../shared/hooks/useZendesk';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { CONFIRM_REASSIGNMENT_PENDO_EVENT } from '../../consts';
import { useReassignment } from '../../hooks/useReassignment';
import { useTimeSlot } from '../../hooks/useTimeSlot';
import {
  closeTimeSlotModal,
  reassignmentSelector,
  setReassignmentModalAnswer
} from '../../reassignment.slice';
import { TimeSlotsStep } from './TimeSlotsStep';
import { useHasAppointmentsPaidWithin24Hours } from '../../hooks/useHasAppointmentsPaidWithin24Hours';

interface StackChildren {
  children: React.ReactNode;
}

interface BackButtonProp {
  back: () => void;
}

function StackButtons({ children }: StackChildren) {
  return (
    <RStack
      direction={{ base: 'column-reverse', md: 'row' }}
      justify="end"
      spacing="md"
      pt="md"
      align="start"
      alignContent="start"
    >
      {children}
    </RStack>
  );
}

function BackButton({ back }: BackButtonProp) {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  return (
    <Button
      variant="ghost"
      label={t('common:back')}
      onClick={back}
      fullWidth={isMobile}
    />
  );
}

const AppointmentsWithin24HoursAlert = ({
  hasAppointment
}: {
  hasAppointment: boolean;
}) => {
  const { t } = useTranslation();
  const { termsAndConditionsUrl } = useInternationalVariables();

  if (!hasAppointment) return null;

  return (
    <Box w="100%" data-testid="reassignment-appointment-within-24h-alert">
      <Alert
        icon={false}
        message={`${t(
          'reassignment.requestModal.appointmentWithin24HoursAlert.message'
        )} ${t('profileAndSettings.termsAndConditions', {
          link: termsAndConditionsUrl
        })}`}
        type="warning"
        title={t(
          'reassignment.requestModal.appointmentWithin24HoursAlert.title'
        )}
      />
    </Box>
  );
};

export function ReassignmentRequestModal() {
  const { isMobile } = useBreakpointValue();
  const { name: patientName, email, doctor } = usePatient();
  const { reassignmentAnswer } = useSelector(reassignmentSelector);
  const { reassignmentLoading, closeModal, automaticReassign } =
    useReassignment();
  const { register, handleSubmit, reset, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: { reason: '' }
  });

  const { goToZendeskAsLoggedUser } = useZendesk();
  const { t } = useTranslation();
  const { loading } = useTimeSlot();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasAppointment, loading: hasAppointmentIn24HLoading } =
    useHasAppointmentsPaidWithin24Hours();

  const back = () => {
    dispatch(setReassignmentModalAnswer(undefined));
    reset();
  };

  // "hc" in zendesk url is needed when it contains parameters
  const reasons = [
    {
      label: t('reassignment.requestModal.reassignmentChoice.firstChoice'),
      value: 'reassignment-automatized-change-survey'
    },
    {
      label: t('reassignment.requestModal.reassignmentChoice.secondChoice'),
      value: 'reassignment-automatized-no-matching-timeslot'
    },
    {
      label: t('reassignment.requestModal.reassignmentChoice.thirdChoice'),
      value: 'reassignment-automatized-specific-reason',
      zendeskUrl: `hc/requests/new?ticket_form_id=13724037369617&tf_anonymous_requester_email=${email}&tf_17449127529233=necessita_molto_specifiche`
    },
    {
      label: t('reassignment.requestModal.reassignmentChoice.fourthChoice'),
      value: 'reassignment-automatized-no-reason'
    }
  ];

  const onSubmitButton = (data: { reason: string }) => {
    dispatch(setReassignmentModalAnswer(data.reason));
    reset();
  };

  const onAutomaticReassignConfirm = () => {
    pendoTrack(CONFIRM_REASSIGNMENT_PENDO_EVENT);
    automaticReassign();
  };

  const contactUs = () => {
    pendoTrack(CONFIRM_REASSIGNMENT_PENDO_EVENT);

    const url =
      reasons.find((one) => one.value === reassignmentAnswer)?.zendeskUrl ?? '';
    goToZendeskAsLoggedUser(url);
    closeModal();
  };

  const Step0Component = (
    <>
      <form onSubmit={handleSubmit(onSubmitButton)}>
        <Stack
          direction="column"
          spacing="sm"
          mb="lg"
          align="start"
          data-testid="reassignment-automatized-modal-page"
        >
          <Heading variant="md">{t('reassignment.requestModal.title')}</Heading>
          {hasAppointmentIn24HLoading ? (
            <Stack
              align="center"
              justify="center"
              direction="column"
              w="100%"
              spacing="sm"
            >
              <Skeleton rounded="sm" h={26} w="100%" />
              <Skeleton rounded="sm" h={26} w="100%" />
              <Skeleton rounded="sm" h={26} w="100%" />
              <Skeleton rounded="sm" h={26} w="100%" />
              <Skeleton rounded="sm" h={26} w="100%" />
              <Skeleton rounded="sm" h={26} w="100%" />
            </Stack>
          ) : (
            <>
              <AppointmentsWithin24HoursAlert hasAppointment={hasAppointment} />
              {reasons.map(({ label, value }) => (
                <RadioButton
                  {...register('reason', { required: true })}
                  key={value}
                  label={label}
                  value={value}
                  dataTestId={value}
                />
              ))}
            </>
          )}
        </Stack>
        <StackButtons>
          <Button
            variant="ghost"
            label={t('common:cancel')}
            onClick={closeModal}
            fullWidth={isMobile}
            data-testid="reassignment-automatized-cancel-button"
          />
          <Button
            variant="outlined"
            label={t('common:buttonNext')}
            icon={ArrowRight}
            data-testid="reassignment-automatized-reason-chosed"
            loading={loading}
            type="submit"
            fullWidth={isMobile}
            disabled={!watch('reason')}
          />
        </StackButtons>
      </form>
    </>
  );
  const Step1NewSurvey = (
    <>
      <Stack direction="column" spacing="sm" mb="lg" align="start">
        <Heading variant="md" style={{ margin: 0 }}>
          {t('reassignment.requestModal.firstChoice.title')}
        </Heading>
        <Text color="grey.600" variant="md">
          {t(`reassignment.requestModal.firstChoice.description`, {
            doctorName: doctor?.name
          })}
        </Text>
      </Stack>
      <StackButtons>
        <BackButton back={back} />

        <Button
          label={t('reassignment.requestModal.firstChoice.button')}
          data-testid={reassignmentAnswer}
          disabled={reassignmentLoading}
          loading={reassignmentLoading}
          onClick={() => {
            navigate('/patient/new-survey');
            return dispatch(closeTimeSlotModal());
          }}
          fullWidth={isMobile}
        />
      </StackButtons>
    </>
  );

  const Step1TimeSlots = <TimeSlotsStep />;

  const Step1ContactComponent = (
    <>
      <Stack direction="column" spacing="sm" mb="lg" align="start">
        <Heading variant="md">
          {t('reassignment.requestModal.thirdChoice.title', {
            patientName
          })}
        </Heading>
        <Box mb="md">
          <Text color="grey.600" variant="md">
            {t('reassignment.requestModal.thirdChoice.firstDescription', {
              doctorName: doctor?.name
            })}
          </Text>
        </Box>
        <Text color="grey.600" variant="md">
          {t('reassignment.requestModal.thirdChoice.secondDescription')}
        </Text>
      </Stack>
      <StackButtons>
        <BackButton back={back} />

        <Button
          label={t('reassignment.requestModal.thirdChoice.button')}
          data-testid={reassignmentAnswer}
          onClick={contactUs}
          fullWidth={isMobile}
        />
      </StackButtons>
    </>
  );

  const Step1NoReason = (
    <>
      <Stack direction="column" spacing="sm" mb="lg" align="start">
        <Heading variant="md">
          {t('reassignment.requestModal.fourthChoice.title', {
            patientName
          })}
        </Heading>
        <Box mb="md">
          <Text color="grey.600" variant="md">
            {t('reassignment.requestModal.fourthChoice.firstDescription', {
              doctorName: doctor?.name
            })}
          </Text>
        </Box>
        <Text color="grey.600" variant="md">
          {t('reassignment.requestModal.fourthChoice.secondDescription', {
            doctorName: doctor?.name
          })}
        </Text>
      </Stack>
      <StackButtons>
        <BackButton back={back} />

        <Button
          label={t('reassignment.requestModal.fourthChoice.button')}
          data-testid={reassignmentAnswer}
          disabled={reassignmentLoading}
          loading={reassignmentLoading}
          onClick={onAutomaticReassignConfirm}
          fullWidth={isMobile}
        />
      </StackButtons>
    </>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalContent() {
    if (!reassignmentAnswer) {
      return Step0Component;
    }
    if (reasons[0].value === reassignmentAnswer) {
      return Step1NewSurvey;
    }
    if (reasons[1].value === reassignmentAnswer) {
      return Step1TimeSlots;
    }
    if (reasons[2].value === reassignmentAnswer) {
      return Step1ContactComponent;
    }
    return Step1NoReason;
  }

  return (
    <ModalAlert open onClose={closeModal} w={isMobile ? '100%' : 375}>
      <RBox style={{ textAlign: 'left' }} pt="md">
        <ModalContent />
      </RBox>
    </ModalAlert>
  );
}
