import { RStack, Text, useBreakpointValue, Link } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface SectionProps {
  title: string;
  gotoChat: boolean;
}

export const Section = ({ title, gotoChat }: SectionProps) => {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <RStack direction="row" justify="space-between" w="100%" pb="md">
      <Text fontWeight="semibold" style={{ opacity: '0.5' }}>
        {title}
      </Text>
      {gotoChat && (
        <Link
          data-testid="hp-go-to-chat-link"
          to={() => navigate('/patient/chat')}
          underline={false}
          style={{
            display: isMobile ? 'block' : 'none'
          }}
        >
          <Text fontWeight="semibold" color="candy.600">
            {t('home.appointments.goToChat')}
          </Text>
        </Link>
      )}
    </RStack>
  );
};
