import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { SuccessPage } from '@unobravo-monorepo/common/components/SuccessFailure/SuccessPage';
import { ErrorPage } from '@unobravo-monorepo/common/components/errorPage';
import { ErrorPageProvider } from '@unobravo-monorepo/common/providers/ErrorPageProvider';
import { clarityFunctions } from '@unobravo-monorepo/common/utils/clarity';
import { pendoInitialize } from '@unobravo-monorepo/common/utils/pendoUtils';
import { PaySessionWrapper } from '@unobravo-monorepo/patient/features/newPaySession/NewPaySessionWrapper';
import { useFirebaseUser } from '@unobravo/core';
import { usePatientAnalyticsUtils } from '@unobravo/patient';
import { useRegistrationStatus } from '@unobravo/signup';
import { useCountry } from '@unobravo/translations';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { PatientFeatureFlagProvider } from './featureFlags';
import { MemberGetMemberPage } from './features/MemberGetMember/pages/MemberGetMember';
import { TosPrivacyAcceptanceModal } from './features/TosPrivacyAcceptance/components/TosPrivacyAcceptanceModal';
import { ActivatePatient } from './features/activatePatient';
import { AgendaPatient } from './features/agenda/pages/agendaPatient';
import { InvoicePage } from './features/appointment/pages/InvoicePage';
import { AppointmentPage } from './features/appointment/pages/appointment';
import { BundlesPage } from './features/bundles/pages/BundlesPage';
import { MessageListener } from './features/chat/components/MessageListener';
import { Chat } from './features/chat/pages/Chat';
import { ChatBot } from './features/chatbot/Chatbot';
import { ChatbotRedirectReassignment } from './features/chatbot/pages/ChatbotRedirectReassignment';
import { ChatbotRedirectZendesk } from './features/chatbot/pages/ChatbotRedirectZendesk';
import { FreeSessionHome } from './features/home/FreeSessionHome';
import { HomePage } from './features/home/HomePage';
import { EndSurveyModal } from './features/home/components/TherapyFeedbackModal/EndSurveyModal';
import { useIsFreeSessionHomeEnable } from './features/home/hooks/useIsFreeSessionHomeEnable';
import { InvoicesPage } from './features/invoices/pages/InvoicesPage';
import { BundlePaySessionWrapper } from './features/newPaySession/BundlePaySessionWrapper';
import { PaymentContext } from './features/newPaySession/types/paymentContext';
import { usePatient } from './features/patientData/hooks/usePatient';
import { ConsentFormModal } from './features/preferences/components/InformedConsent/ConsentFormModal';
import { AddCard } from './features/preferences/pages/addCard';
import { HelpCenter } from './features/preferences/pages/helpCenter';
import { Payment } from './features/preferences/pages/payment';
import { Preferences } from './features/preferences/pages/preferences';
import { ReassignmentRoute } from './features/reassignment';
import { EducationReassignmentModal } from './features/reassignment/components/EducationReassignment/EducationReassignmentModal';
import { ReassignmentRequestModal } from './features/reassignment/components/EducationReassignment/ReassignmentRequestModal';
import { AfterSelection } from './features/selectYourTp/pages/afterSelection';
import { SelectTherapist } from './features/selectYourTp/pages/selectTherapist';
import { TherapySettingPage } from './features/therapySetting/pages/TherapySettingPage';
import { TherapySurveyConsentModal } from './features/therapySurveyConsentModal';
import { VouchersPage } from './features/vouchers/pages/VouchersPage';
import { ActivatedPatientGuard } from './shared/guards/ActivatedPatientGuard';
import { MailActivationGuard } from './shared/guards/MailActivationGuard';
import { BundleThankYouPage } from './features/bundles/pages/BundleThankYouPage';
import { useBundle } from './features/bundles/hooks/useBundle';

const InformedConsentPartnerUpload = React.lazy(
  () => import('./features/informedConsentPartnerUpload')
);

const NO_SCROLL_PATHS = ['chat', 'dialog'];
const { NX_APP_ENVIRONMENT: currentEnv } = process.env;

const bundleRoutesMap: {
  page: 'home' | 'therapy-setting' | 'chat' | 'agenda' | 'mobile';
  context: PaymentContext;
}[] = [
  { page: 'home', context: 'HOME' },
  { page: 'therapy-setting', context: 'THERAPY_SETTING' },
  { page: 'chat', context: 'CHAT' },
  { page: 'agenda', context: 'AGENDA' },
  { page: 'mobile', context: 'MOBILE' }
];

const HomePageRedirect: React.FC = () => {
  const { status } = usePatient();
  const { isBundleOpened, bundleVariant } = useBundle();

  const showHomePage = status === 'ONGOING' || status === 'TERMINATED';
  const { value: showFreeSessionHome, loading } = useIsFreeSessionHomeEnable();
  if (loading) {
    return <Spinner />;
  }

  if (
    (isBundleOpened || bundleVariant === 'control') &&
    status === 'FREE_SESSION'
  )
    return <Navigate to="home" replace />;

  if (showFreeSessionHome) {
    return <Navigate to="free-session-home" />;
  }

  return <Navigate to={showHomePage ? 'home' : 'chat'} replace />;
};

const ActivatedPatientRoutes: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname
        .split('/')
        .every((path) => !NO_SCROLL_PATHS.includes(path))
    ) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  const splittedLocationName = location.pathname.split('/');
  const dialogPathIndex = splittedLocationName.indexOf('dialog');
  const background = dialogPathIndex >= 0;

  // TODO: Refactor -> Location context now is always 1 after the dialog string
  const defaultDialogLocation = background
    ? {
        pathname: `${location.pathname
          .split('/')
          .splice(0, dialogPathIndex)
          .join('/')}/${splittedLocationName[dialogPathIndex + 1]}`,
        search: '',
        hash: '',
        state: {}
      }
    : location;

  // this is a workaround since we need to redirect from a dialog route to another dialog route
  // but as it is now with the current version of react-router-dom we can't do that
  // @klein
  if (location.state && location.state.referrer) {
    navigate(`../dialog/${location.state.referrer.path}`, {
      state: { modal: location }
    });
  }

  return (
    <>
      <MessageListener />
      <Routes location={location.state?.modal || defaultDialogLocation}>
        <Route path="/chat" element={<Chat />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/free-session-home" element={<FreeSessionHome />} />
        <Route path="/chat/:mode" element={<Chat />} />
        <Route path="/agenda" element={<AgendaPatient />} />
        <Route path="/preferences/*" element={<Preferences />} />
        <Route path="/appointment/:uuid" element={<AppointmentPage />} />
        <Route path="/invoices" element={<InvoicesPage />} />
        <Route path="/invoice/:uuid" element={<InvoicePage />} />
        <Route path="/member-get-member" element={<MemberGetMemberPage />} />
        <Route path="/badges-vouchers" element={<VouchersPage />} />
        <Route path="/therapy-setting" element={<TherapySettingPage />} />
        <Route path="/bundles" element={<BundlesPage />} />
        <Route path="/" element={<HomePageRedirect />} />
        <Route path="/select-therapist" element={<SelectTherapist />} />
        <Route path="/after-selection" element={<AfterSelection />} />
        <Route path="*" element={<Navigate to="page-not-found" replace />} />
        <Route
          path="/mobile/informedConsent"
          element={<ConsentFormModal openModal context="MOBILE" />}
        />
        <Route
          path="/mobile/paySession/:sessionId"
          element={<PaySessionWrapper context="MOBILE" />}
        />
        <Route
          path="/mobile/paymentMethod"
          element={<Payment context="MOBILE" />}
        />
        <Route
          path="/mobile/paymentMethod/addCard"
          element={<AddCard context="MOBILE" />}
        />
        <Route
          path="/mobile/informedConsent/partner/upload"
          element={<InformedConsentPartnerUpload isMobileApp />}
        />
        <Route path="/mobile/help-center" element={<HelpCenter />} />
        <Route
          path="/mobile/bundlePaySession/:sessionId"
          element={<BundlePaySessionWrapper context="MOBILE" />}
        />
        <Route
          path="/mobile/bundlePaySession"
          element={<BundlePaySessionWrapper context="MOBILE" />}
        />
        <Route path="/bundle-thank-you-page" element={<BundleThankYouPage />} />
      </Routes>
      {background && (
        <Routes>
          {bundleRoutesMap.map(({ page, context }) => (
            <Route
              key={page}
              path={`/dialog/${page}/bundlePaySession/:sessionId`}
              element={<BundlePaySessionWrapper context={context} />}
            />
          ))}
          <Route
            path="/dialog/bundles/bundlePaySession"
            element={<BundlePaySessionWrapper context="BUNDLES" />}
          />
          <Route
            path="/dialog/home/bundlePaySession"
            element={<BundlePaySessionWrapper context="HOME" />}
          />
          <Route
            path="/dialog/chat/paySession/:sessionId"
            element={<PaySessionWrapper context="CHAT" />}
          />
          <Route
            path="/dialog/home/paySession/:sessionId"
            element={<PaySessionWrapper context="HOME" />}
          />
          <Route
            path="/dialog/agenda/paySession/:sessionId"
            element={<PaySessionWrapper context="AGENDA" />}
          />
          <Route
            path="/dialog/therapy-setting/paySession/:sessionId"
            element={<PaySessionWrapper context="THERAPY_SETTING" />}
          />
          <Route
            path="/dialog/informedConsent/partner/upload"
            element={<InformedConsentPartnerUpload />}
          />
          <Route
            path="/dialog/reassignmentModal"
            element={<EducationReassignmentModal />}
          />
          <Route
            path="/dialog/reassignmentModal/openRequest"
            element={<ReassignmentRequestModal />}
          />
          <Route
            path="/dialog/home/continueTherapy"
            element={<EndSurveyModal />}
          />
          <Route
            path="/dialog/home/reassignmentDiscovery"
            element={<EndSurveyModal isReassignmentVisible />}
          />
        </Routes>
      )}
    </>
  );
};

const usePreMatchRedirect = () => {
  const { currentUser } = useFirebaseUser();
  const { registrationStatus } = useRegistrationStatus({
    skip: !currentUser,
    fetchPolicy: 'no-cache'
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (registrationStatus?.getRegistrationStatus.doctorSelected === false) {
      navigate('/patient/select-therapist');
    }
  }, [navigate, registrationStatus]);
};

const PatientRoutesApp: React.FC = () => {
  const {
    loaded,
    id,
    email,
    doctor,
    name,
    surname,
    hasBpCreditsLeft,
    isBpEnabled,
    prefForWhom,
    platformCountry,
    prefTerms,
    prefPrivacy
  } = usePatient();
  const { t } = useTranslation();
  const { analyticsData, analyticsError } = usePatientAnalyticsUtils();
  const { domain, domainCountry } = useCountry();

  useEffect(() => {
    if (!id || (!analyticsData && !analyticsError)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __typename, ...analytics } = analyticsData || {};

    pendoInitialize(
      {
        id: `${currentEnv !== 'production' ? 'S-' : ''}${id}`,
        firstName: name,
        role: 'patient',
        hasBPRest: isBpEnabled && !hasBpCreditsLeft,
        userLanguage: domainCountry,
        userProperty: domain,
        userCountry: platformCountry,
        ...analytics
      },
      doctor?.id
        ? {
            id: `${currentEnv !== 'production' ? 'S-' : ''}TP-${doctor.id}`,
            name: `${doctor.name} ${doctor.surname}`
          }
        : undefined
    );

    /** initialize clarity tags */
    clarityFunctions.setTag(
      'patientId',
      `${currentEnv !== 'production' ? 'S-' : ''}${id}`
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    email,
    doctor,
    name,
    surname,
    analyticsData,
    analyticsError,
    isBpEnabled,
    hasBpCreditsLeft
  ]);

  usePreMatchRedirect();

  const hasTosPrivacyAcceptanceModal =
    domainCountry === 'es' && (!prefPrivacy || !prefTerms);

  return loaded ? (
    <PatientFeatureFlagProvider customAttributes={{ prefForWhom }}>
      {hasTosPrivacyAcceptanceModal && <TosPrivacyAcceptanceModal />}
      <TherapySurveyConsentModal />
      <Routes>
        <Route
          path="/delete-account-success"
          element={
            <SuccessPage
              label={`${t('deleteAccount.successPage.label')}`}
              title={`${t('deleteAccount.successPage.title')}`}
              description={`${t('deleteAccount.successPage.description')}`}
              footer1={t('deleteAccount.successPage.support1')}
              footer2={t('deleteAccount.successPage.support2')}
            />
          }
        />
        <Route
          path="/activate-patient"
          element={
            <MailActivationGuard>
              <ActivatePatient />
            </MailActivationGuard>
          }
        />

        <Route
          path="/chatbot-redirect-zendesk"
          element={<ChatbotRedirectZendesk />}
        />
        <Route
          path="/chatbot-redirect-reassignment"
          element={<ChatbotRedirectReassignment />}
        />

        <Route path={'/new-survey/*'} element={<ReassignmentRoute />} />
        <Route
          path={'/*'}
          element={
            <ActivatedPatientGuard>
              <ActivatedPatientRoutes />
            </ActivatedPatientGuard>
          }
        />
        <Route
          path="/page-not-found"
          element={
            <ErrorPage
              error404={t('common:errorPage.error404')}
              wentWrong={t('common:errorPage.wentWrong')}
              pageMalfunction={t('common:errorPage.pageMalfunction')}
              backHome={t('common:errorPage.backHome')}
            />
          }
        />
      </Routes>
      <ChatBot />
    </PatientFeatureFlagProvider>
  ) : (
    <></>
  );
};

export const PatientRoutes = () => {
  const { t } = useTranslation();

  const errorPageProps = {
    wentWrong: t('common:errorPage.ops'),
    description: t('common:errorPage.wentWrong'),
    pageMalfunction: t('common:errorPage.pageMalfunction')
  };
  return (
    <ErrorPageProvider errorPageProps={errorPageProps}>
      <PatientRoutesApp />
    </ErrorPageProvider>
  );
};

export const DeleteRoutes = () => {
  const { t } = useTranslation();
  return (
    <Routes>
      <Route
        path="/delete-account-success"
        element={
          <SuccessPage
            label={`${t('deleteAccount.successPage.label')}`}
            title={`${t('deleteAccount.successPage.title')}`}
            description={`${t('deleteAccount.successPage.description')}`}
            footer1={t('changeEmail.support1')}
            footer2={t('changeEmail.support2')}
          />
        }
      />
    </Routes>
  );
};
