import { Theme } from '@unobravo-monorepo/common';
import { FeatureHighlight } from '@unobravo-monorepo/common/components/FeatureHighlight';
import { HamburgerToCross } from '@unobravo-monorepo/common/components/HamburgerToCross';
import { Support } from '@unobravo-monorepo/common/components/Icons';
import { Logo } from '@unobravo-monorepo/common/components/Logo';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import {
  StorageKeys,
  createSafeStorage
} from '@unobravo-monorepo/common/utils/storage';
import { useCountry } from '@unobravo/translations';
import { Box } from '@unobravo/zenit-web';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Message } from '@unobravo/zenit-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { chatSelector } from '@unobravo-monorepo/patient/features/chat/store/selectors';
import { usePatientFeatureFlags } from '../../featureFlags';
import {
  layoutSelector,
  toggleLeftMenu
} from '../../features/layout/layout.slice';
import { useZendesk } from '../hooks/useZendesk';
import { Indigo } from '../utils/indigo';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const [getHighlight] = createSafeStorage(StorageKeys.sidebar);

const Notification = styled.div`
  position: absolute;
  right: 16px;
  top: 9px;
  width: 6px;
  height: 6px;
  background-color: ${Theme.colors.candy[500]};
  border-radius: 21px;
  box-shadow: -1px 2px 5px rgba(255, 80, 50, 0.4);
`;

const ChatIconBox = styled(Box)`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const chatWhiteList = ['/patient/free-session-home', '/patient/home'];

export const PatientHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLeftMenuOpen } = useSelector(layoutSelector);
  const { isMobile } = useViewport();
  const { domainCountry } = useCountry();
  const navigate = useNavigate();
  const { newMessage: receivedNewMessage } = useSelector(chatSelector);
  const location = useLocation();
  const showChatIcon =
    isMobile && !isLeftMenuOpen && chatWhiteList.includes(location.pathname);

  const { indigoChatbot } = usePatientFeatureFlags();

  const { goToZendeskAsLoggedUser } = useZendesk();

  const handleSupportLink = () => {
    if (indigoChatbot && domainCountry === 'it') {
      return Indigo.openChat();
    }
    return goToZendeskAsLoggedUser();
  };

  const navigateToChat = () => {
    navigate('/patient/chat');
  };

  const highlightMobileMenu = useMemo(() => {
    if (domainCountry !== 'it') return false;

    const localStorageValue = getHighlight({ suffix: 'member-get-member' });

    if (!localStorageValue) return true;

    return false;
  }, [domainCountry]);

  return (
    <>
      {isMobile && (
        <Box
          onClick={() => {
            dispatch(toggleLeftMenu());
          }}
          data-testid="menu-toggle-mobile"
        >
          {highlightMobileMenu && (
            <Box position="absolute" left={6} top={2}>
              <FeatureHighlight />
            </Box>
          )}
          <HamburgerToCross isOpen={isLeftMenuOpen} />
        </Box>
      )}
      <Logo variant="no-margin" height={isMobile ? 25 : 29} />
      <div>
        {!isMobile && (
          <Wrapper
            onClick={handleSupportLink}
            data-testid="click-knowledge-base"
          >
            <Body size={BodySize.Body60} color={Theme.colors.candy[500]}>
              {t('help')}
            </Body>
            <Support width={20} height={20} />
          </Wrapper>
        )}
      </div>

      {showChatIcon && (
        <>
          {!receivedNewMessage && <Notification />}
          <ChatIconBox
            onClick={navigateToChat}
            data-testid="hp-go-to-chat-card"
          >
            <Message width={24} height={24} />
          </ChatIconBox>
        </>
      )}
    </>
  );
};
