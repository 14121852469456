import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const BankBuilding: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.moka[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.7207C11.9836 7.7207 11.9674 7.72394 11.9523 7.73024C11.9372 7.73653 11.9235 7.74575 11.9119 7.75737C11.9004 7.769 11.8913 7.78278 11.8851 7.79794C11.879 7.8131 11.8758 7.82934 11.876 7.8457C11.876 7.87886 11.8892 7.91065 11.9126 7.93409C11.936 7.95753 11.9678 7.9707 12.001 7.9707C12.0341 7.9707 12.0659 7.95753 12.0894 7.93409C12.1128 7.91065 12.126 7.87886 12.126 7.8457C12.1255 7.81254 12.112 7.7809 12.0884 7.75754C12.0649 7.73418 12.0331 7.72096 12 7.7207"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.972 10.833H3.028C2.95871 10.8331 2.89007 10.8196 2.82602 10.7932C2.76196 10.7667 2.70375 10.7279 2.65471 10.679C2.60567 10.63 2.56676 10.5719 2.54021 10.5079C2.51366 10.4439 2.5 10.3753 2.5 10.306V8.33C2.50016 8.1202 2.5663 7.91577 2.68905 7.74564C2.8118 7.57551 2.98496 7.44829 3.184 7.382L11.666 4.554C11.8828 4.482 12.1172 4.482 12.334 4.554L20.816 7.382C21.015 7.44829 21.1882 7.57551 21.3109 7.74564C21.4337 7.91577 21.4998 8.1202 21.5 8.33V10.306C21.5 10.3753 21.4863 10.4439 21.4598 10.5079C21.4332 10.5719 21.3943 10.63 21.3453 10.679C21.2962 10.7279 21.238 10.7667 21.174 10.7932C21.1099 10.8196 21.0413 10.8331 20.972 10.833V10.833Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 18.5V10.833"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 10.833V18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.833V18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.833V18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10.833V18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.382 18.5H3.618C3.43234 18.5001 3.25037 18.5519 3.09247 18.6496C2.93458 18.7472 2.80699 18.8869 2.724 19.053L2.106 20.289C2.03621 20.4277 1.99991 20.5808 2 20.736V21.5H22V20.736C22.0001 20.5808 21.9638 20.4277 21.894 20.289L21.276 19.053C21.193 18.8869 21.0654 18.7472 20.9075 18.6496C20.7496 18.5519 20.5677 18.5001 20.382 18.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 21.5H1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
