import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { Box, RStack, Stack, Text } from '@unobravo/zenit-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentBubble } from '../../../shared/components/AppointmentBubble';
import { InformedConsentAlert } from '../../../shared/components/InformedConsentAlert';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useAgenda } from '../hooks/useAgenda';
import { NoAppointment } from './NoAppointment';

type LabelType = 'new' | 'today' | 'next';

export const AgendaFutureAppointments: React.FC = () => {
  const { informedConsent, status: patientStatus, timezone } = usePatient();
  const { nextSessions: nextSessionsAll, status } = useAgenda();
  const { t } = useTranslation();

  const labels: Record<LabelType, string> = {
    today: t('chat.today'),
    new: '',
    next: t('agenda.nextAppointments')
  };

  const mappedSessions = useMemo(() => {
    const result: Record<LabelType, IAppointment[]> = {
      today: [],
      new: [],
      next: []
    };

    nextSessionsAll.forEach((session) => {
      const { isToday } = getSessionDate(session.when, timezone || undefined);

      if (isToday) {
        result.today.push(session);
      } else if (session.paid || session.canceled) {
        result.next.push(session);
      } else {
        result.new.push(session);
      }
    });

    return result;
  }, [nextSessionsAll, timezone]);

  return (
    <RStack direction="column" w="100%" h="100%" pr="md" pl="md">
      {!informedConsent && patientStatus === 'ONGOING' && (
        <InformedConsentAlert />
      )}
      {status === 'loading' && nextSessionsAll.length === 0 && <Spinner />}
      {nextSessionsAll.length > 0 && (
        <Stack direction="column" w="100%" align="center">
          {Object.keys(mappedSessions).map(
            (label) =>
              mappedSessions[label as LabelType].length > 0 && (
                <Box key={label} w="100%" mt="2xl">
                  <Box mb="xs" style={{ textTransform: 'capitalize' }}>
                    <Text variant="sm" color="grey.300" textAlign="center">
                      {labels[label as LabelType]}
                    </Text>
                  </Box>

                  {mappedSessions[label as LabelType].map((session) => (
                    <Box mb="xs" key={session.id}>
                      <AppointmentBubble
                        session={session}
                        key={session.id}
                        showVideocall
                        handleInvoice
                        handleCreditNote
                      />
                    </Box>
                  ))}
                </Box>
              )
          )}
        </Stack>
      )}
      {status === 'done' && nextSessionsAll.length === 0 && <NoAppointment />}
    </RStack>
  );
};
