import { Country } from '@unobravo/core';
import { DateTime } from 'luxon';

export const transformAmount = (amount?: number) =>
  amount || amount === 0
    ? (amount / 100).toFixed(2).replace('.', ',')
    : undefined;

/**
 * Get the footnote for the patients (es) that are still paying 34 euros as of 01/07/2024
 * (valid until 15/01/2025)
 * @param domainCountry "it" | "es" | "fr"
 * @param price price multiplied by 100
 * @returns if the price is 34, return "*"
 */
export const getFootnote = (domainCountry: Country, price?: number) => {
  const now = DateTime.now();

  return domainCountry === 'es' &&
    now >= DateTime.fromISO('2024-07-01') &&
    now <= DateTime.fromISO('2025-01-15') &&
    price === 3400
    ? '*'
    : '';
};
