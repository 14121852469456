import React, { Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom';

import AuthApp, { LoginFromToken } from '@unobravo-monorepo/auth';
import Patient from '@unobravo-monorepo/patient';

import * as Sentry from '@sentry/react';
import { Layout } from '@unobravo-monorepo/common/components/Layout';
import { SentryRouteErrorFallback } from '@unobravo-monorepo/common/components/SentryRouteErrorFallback';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { BrazeProvider } from '@unobravo-monorepo/common/providers/BrazeProvider';
import { FirebaseUserProvider } from '@unobravo-monorepo/common/providers/FirebaseUser';
import { ViewportProvider } from '@unobravo-monorepo/common/providers/Viewport';
import { GoogleTagManager } from '@unobravo-monorepo/common/utils/gtm';
import { getDoctorAcquisitionRoutes } from '@unobravo-monorepo/doctor-acquisition/routes';
import { getActivationRoutes } from '@unobravo-monorepo/patient-activation/routes';
import { getDoctorIncentivesRoutes } from '@unobravo-monorepo/incentive-guide/routes';
import { getPatientChangeMailRoutes } from '@unobravo-monorepo/patient-change-email/routes';
import { getTeamLeaderRoutes } from '@unobravo-monorepo/teamleader/routes';
import { useCountry, useInternationalVariables } from '@unobravo/translations';
import { ZenitProvider } from '@unobravo/zenit-web';
import { AppointmentRedirect } from './components/AppointmentRedirect';
import { CheckRoutes } from './components/CheckRoutes';
import { ErrorBoundary } from './error-boundary';
import { initializeFirebase } from './firebase';

initializeFirebase();

const SignupApp = React.lazy(() => import('@unobravo-monorepo/signup'));
const ZendeskSso = React.lazy(() =>
  import('@unobravo-monorepo/zendesk').then((module) => ({
    default: module.Zendesk
  }))
);
const DoctorApp = React.lazy(() => import('@unobravo-monorepo/doctor'));

/** partners app */
const PartnersApp = React.lazy(() => import('@unobravo-monorepo/partners'));

const gtm = new GoogleTagManager();

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// Custom hook to initialize router
const useInitializeRouter = () => {
  const { domainCountry } = useCountry();
  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<Layout />}
        errorElement={<SentryRouteErrorFallback />}
      >
        {getActivationRoutes(domainCountry)}
        {getDoctorAcquisitionRoutes()}
        {getDoctorIncentivesRoutes()}
        {getPatientChangeMailRoutes()}
        <Route path="/patient/*" element={<Patient />} />
        <Route path="/signup/*" element={<SignupApp />} />
        <Route
          path="/registration/user/"
          element={
            <Navigate to={`/signup/${window.location.search || ''}`} replace />
          }
        />
        <Route path="/doctor/*" element={<DoctorApp />} />
        <Route path="login/zendesk/sso" element={<ZendeskSso />} />
        {getTeamLeaderRoutes()}
        <Route
          path="login-from-mobile/:code/:redirect/:platform"
          element={<LoginFromToken />}
        />
        <Route
          path="login-from-mobile/:code/:redirect"
          element={<LoginFromToken />}
        />
        {/* Redirects */}
        <Route path="/appointment/:id" element={<AppointmentRedirect />} />
        <Route
          path="/beta/doctor/*"
          element={<Navigate to="/doctor/" replace />}
        />
        <Route
          path="/weekly-recap"
          element={<Navigate to="/doctor/weekly-recap" replace />}
        />
        <Route path="/" element={<CheckRoutes />} />
        <Route path="/*" element={<AuthApp />} />
        {/** Partners */}
        <Route path="/partners/*" element={<PartnersApp />} />
      </Route>
    )
  );
};

export const App = () => {
  const { gtmId, gtmExtend } = useInternationalVariables();
  const router = useInitializeRouter();

  useEffect(() => {
    gtm.initialize({ id: gtmId ?? '', extend: gtmExtend });
  }, [gtmId]);

  return (
    <ErrorBoundary>
      <ViewportProvider>
        <FirebaseUserProvider>
          <BrazeProvider>
            <ZenitProvider>
              <Suspense fallback={<Spinner />}>
                <RouterProvider router={router} fallbackElement={<Spinner />} />
              </Suspense>
            </ZenitProvider>
          </BrazeProvider>
        </FirebaseUserProvider>
      </ViewportProvider>
    </ErrorBoundary>
  );
};

export const AppWithProfiler = Sentry.withProfiler(App);
