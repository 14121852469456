import { Calendar as CalendarIcon } from '@unobravo/zenit-icons';
import { Button, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

export const AvailabilityAlert = ({ onClick }: { onClick: () => void }) => {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      bgColor="spinach.50"
      maxW={isMobile ? '100%' : '462px'}
      rounded="md"
      w={isMobile ? '100%' : undefined}
      style={{ padding: 12 }}
      spacing="xs"
      align="center"
    >
      <Text color="spinach.500" variant="md" textAlign="center">
        {t('chat.calendarAlert.description')}
      </Text>
      <Button
        data-testid="calendar-message-button"
        onClick={onClick}
        icon={CalendarIcon}
        colorVariant="accent"
        label={t('chat.calendarAlert.button')}
      />
    </Stack>
  );
};
