import { useTranslation } from 'react-i18next';
import {
  FailurePage,
  IButton
} from '@unobravo-monorepo/common/components/SuccessFailure/FailurePage';
import { useNavigate } from 'react-router-dom';
import { useFirebaseUser } from '@unobravo/core';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useVerifyEmail } from '../hooks/useVerifyEmail';
import { useErrorHandler } from '../hooks/useErrorHandler';

export const VerifyEmailError = () => {
  const { logout, currentUser, loaded } = useFirebaseUser();
  const { resend } = useVerifyEmail();
  const { sendGenericError } = useErrorHandler();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttons: IButton[] = [
    {
      label: t('expiration.btnLabel2'),
      onClick: async () => {
        try {
          await logout();
          navigate('/login');
        } catch (e) {
          sendGenericError();
        }
      }
    }
  ];
  if (currentUser?.unbvId) {
    buttons[0].variant = 'ghost';
    buttons.unshift({
      label: t('expiration.btnLabel'),
      onClick: async () => {
        try {
          await resend();
          navigate('/');
        } catch (e) {
          sendGenericError();
        }
      }
    });
  }
  if (!loaded) {
    return <Spinner />;
  }
  return (
    <FailurePage
      title={t('expiration.title')}
      buttons={buttons}
      description={currentUser?.unbvId ? t('expiration.description') : ''}
      label={t('expiration.label')}
    />
  );
};
