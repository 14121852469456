import {
  setAppointments,
  setAppointmentsError,
  setAppointmentsLoading,
  useAgendaUtils
} from '@unobravo/patient';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePatient } from '../../patientData/hooks/usePatient';
import { setTherapyInfo } from '../../therapySetting/store/therapySetting.slice';
import { appointmentsSelector } from '../store/selectors';

const APPOINTMENT_SIZE = 10;

export const useAgenda = () => {
  const dispatch = useDispatch();
  const { id } = usePatient();
  const {
    nextSessions,
    pastSessions,
    firstCursorPast,
    status,
    hasPreviousPage
  } = useSelector(appointmentsSelector);

  const {
    loadPatientAppointments,
    appointments,
    error,
    loading,
    reloadPatientNextAppointments
  } = useAgendaUtils();

  const loadAppointments = useCallback(() => {
    if (id) {
      dispatch(setAppointmentsLoading());
      loadPatientAppointments(id, APPOINTMENT_SIZE, firstCursorPast, true);
    }
  }, [dispatch, firstCursorPast, id, loadPatientAppointments]);

  const reloadNextAppointments = useCallback(() => {
    if (
      id &&
      reloadPatientNextAppointments(status, id, APPOINTMENT_SIZE, true)
    ) {
      dispatch(setAppointmentsLoading());
    }
  }, [dispatch, id, reloadPatientNextAppointments, status]);

  useEffect(() => {
    if (appointments) {
      dispatch(
        setAppointments({
          nextSessions: appointments.nextSessions,
          pastSessions: appointments.pastSessions?.edges,
          firstCursorPast: appointments.pastSessions?.pageInfo?.firstCursor,
          hasPreviousPage: appointments.pastSessions?.pageInfo?.hasPreviousPage
        })
      );
      dispatch(
        setTherapyInfo({
          therapySetting: appointments.therapySetting,
          firstSession: appointments.firstSession,
          firstSessionWithCurrentDoctor:
            appointments.firstSessionWithCurrentDoctor,
          doctorTherapeuticOrientation:
            appointments.doctor?.therapeuticOrientation
        })
      );
    }
  }, [appointments, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setAppointmentsError());
    }
  }, [error, dispatch]);

  return {
    nextSessions,
    pastSessions,
    firstCursorPast,
    loadAppointments,
    status,
    hasPreviousPage,
    reloadNextAppointments,
    loading
  };
};
