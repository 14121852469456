import { gql } from '@apollo/client';

export const ACCEPT_PATIENT_TOS = gql`
  mutation AcceptPatientTos($patientId: Float!, $data: AcceptPatientTosInput!) {
    acceptPatientTos(patientId: $patientId, data: $data) {
      prefPrivacy
      prefTerms
    }
  }
`;
