import * as Types from '../../generated/auth.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendResetPasswordEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  flowType?: Types.InputMaybe<Types.FlowType>;
  platformCountry?: Types.InputMaybe<Types.PlatformCountry>;
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type TrackLoginMutationVariables = Types.Exact<{
  firebaseId: Types.Scalars['String'];
  providerId: Types.Scalars['String'];
}>;


export type TrackLoginMutation = { __typename?: 'Mutation', trackLogin: boolean };


export const SendResetPasswordEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendResetPasswordEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"flowType"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"FlowType"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"platformCountry"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PlatformCountry"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendResetPasswordEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"flowType"},"value":{"kind":"Variable","name":{"kind":"Name","value":"flowType"}}},{"kind":"Argument","name":{"kind":"Name","value":"platformCountry"},"value":{"kind":"Variable","name":{"kind":"Name","value":"platformCountry"}}}]}]}}]} as unknown as DocumentNode<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const TrackLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"trackLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"firebaseId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"providerId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"trackLogin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"firebaseId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"firebaseId"}}},{"kind":"Argument","name":{"kind":"Name","value":"providerId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"providerId"}}}]}]}}]} as unknown as DocumentNode<TrackLoginMutation, TrackLoginMutationVariables>;