import React, { useEffect } from 'react';
import { Box, useResponsiveValue } from '@unobravo/zenit-web';
import {
  Alignment,
  Fit,
  Layout as RiveLayout,
  useRive,
  useStateMachineInput
} from '@rive-app/react-canvas';
import animation from '@unobravo-monorepo/patient/assets/animation/onboarding-menu.riv';

const viewport = 'Viewport';

interface IRiveBadge {
  w?: string;
  h?: string;
  borderRadius?: string;
}
export const RiveBadge = ({ h, w, borderRadius }: IRiveBadge) => {
  const stateMachineName = 'Background';
  const size = useResponsiveValue<number>({
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0
  });
  const { RiveComponent: BackgroundBadge, rive } = useRive({
    src: animation,
    autoplay: true,
    stateMachines: stateMachineName,
    layout: new RiveLayout({
      fit: Fit.Fill,
      alignment: Alignment.Center
    })
  });
  const viewportInput = useStateMachineInput(
    rive,
    stateMachineName,
    viewport,
    0
  );
  useEffect(() => {
    if (!viewportInput) return;
    viewportInput.value = size;
  });
  return (
    <Box h={h || '78px'} w={w || '100%'} rounded="md">
      <BackgroundBadge
        style={{ borderRadius: borderRadius || '16px', overflow: 'hidden' }}
      />
    </Box>
  );
};
