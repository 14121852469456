/* eslint-disable no-console */
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

type Envs = 'production' | 'staging' | 'preview' | 'local';
type Resource = 'local-files' | 'S3';
type Params = Record<Envs, Resource[]>;

const { NX_APP_ENVIRONMENT } = process.env;
const env = NX_APP_ENVIRONMENT as Envs;

const s3Url = `https://translations.unobravo.com/frontend/{{lng}}/{{ns}}.json`;

export const useI18nBackends = (params: Params) => {
  const envs = params[env] ?? ['local-files'];

  if (!params[env])
    console.error(
      `ENV ${env} is missing in i18n backends params. Check your configuration in useI18nBackends.`
    );

  const backends = envs.map((r) => {
    switch (r) {
      case 'S3':
        return HttpApi;
      default:
        // ! Locale Files
        return resourcesToBackend(
          (language: string, namespace: string) =>
            import(`../../../../locales/${language}/${namespace}.json`)
        );
    }
  });

  const backendOptions = envs.map((r) => {
    switch (r) {
      case 'S3':
        return {
          loadPath: s3Url
        };
      default:
        return {};
    }
  });

  return {
    backends,
    backendOptions
  };
};
