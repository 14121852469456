import { mobileFirstMedia } from '@unobravo-monorepo/common';
import { theme } from '@unobravo/zenit-core';
import { Link, RStack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { usePatient } from '../../patientData/hooks/usePatient';
import { setShowDoctorInfoModal } from '../../home/home.slice';
import { openReassignmentModal } from '../reassignment.slice';

const Footer = styled(RStack)`
  margin-top: auto;
  ${mobileFirstMedia('md')(`
    border-top: 1px solid ${theme.colors.cappuccino[200]};
  `)}
`;

export const ReassignmentButton = ({
  variant = 'footer'
}: {
  variant?: 'footer' | 'link';
}) => {
  const { reassignConditions } = usePatient();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    dispatch(setShowDoctorInfoModal(false));
    if (reassignConditions?.canPerformSmoothReassign) {
      return navigate(`../../dialog/reassignmentModal`, {
        state: { modal: location }
      });
    }

    return dispatch(openReassignmentModal());
  };

  return (
    <>
      {reassignConditions?.automatic ||
      reassignConditions?.canPerformSmoothReassign ? (
        variant === 'footer' ? (
          <Footer
            id="reassignment-footer"
            w="100%"
            justify="center"
            pt="md"
            pb="md"
            bgColor="cappuccino.50"
            radiusBottomLeft={{ md: 'md' }}
            radiusBottomRight={{ md: 'md' }}
            borderColor="cappuccino.200"
            position="sticky"
            bottom={0}
          >
            <Link
              to={handleClick}
              data-testid={
                reassignConditions?.canPerformSmoothReassign
                  ? 'start-reassignment-education'
                  : 'start-reassignment-button'
              }
              color="candy.500"
            >
              <Text variant="sm" fontWeight="semibold">
                {t('reassignment.button')}
              </Text>
            </Link>
          </Footer>
        ) : (
          <Link
            to={handleClick}
            underline={false}
            data-testid={
              reassignConditions?.canPerformSmoothReassign
                ? 'start-reassignment-education'
                : 'start-reassignment-button'
            }
            color="candy.600"
          >
            <Text variant="sm" fontWeight="semibold">
              {t('reassignment.button')}
            </Text>
          </Link>
        )
      ) : null}
    </>
  );
};
