import {
  InformedConsentRecipient,
  useInformedConsentData
} from '@unobravo/patient';
import { theme } from '@unobravo/zenit-core';
import {
  Box,
  Button,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { SystemMessageAlert } from './SystemMessageAlert';
import { TabDivider } from './TabDivider';

interface IInfo {
  label: string;
  value: string | null;
}

const Info: React.FC<IInfo> = ({ label, value }) => {
  const { isMobile } = useBreakpointValue();

  return (
    <Box minW={isMobile ? '100%' : '50%'}>
      <Text variant="md" color="grey.500">
        {label}
      </Text>
      <Text variant="md">{value || ''}</Text>
    </Box>
  );
};

interface IInfoBox {
  values: InformedConsentRecipient;
}

const InfoBox: React.FC<IInfoBox> = ({ values }) => {
  const { t } = useTranslation();
  const {
    name,
    surname,
    taxCode = '',
    residenceStreet = '',
    residenceTown = '',
    residenceZip = '',
    birthDate = '',
    birthPlace = ''
  } = values;

  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      <Info
        label={t('legal:informedConsent.signModal.recapLabels.name')}
        value={name ? `${name} ${surname}` : ''}
      />
      <Info
        label={t('legal:informedConsent.signModal.labels.taxCode')}
        value={taxCode}
      />
      <Info
        label={t('legal:informedConsent.signModal.labels.birthPlace')}
        value={birthPlace}
      />
      <Info
        label={t('legal:informedConsent.signModal.labels.birthDate')}
        value={birthDate}
      />
      <Info
        label={t('legal:informedConsent.signModal.recapLabels.residenceTown')}
        value={residenceTown}
      />
      <Info
        label={t('legal:informedConsent.signModal.labels.residenceZip')}
        value={residenceZip}
      />
      <Info
        label={t(
          'legal:informedConsent.signModal.recapLabels.residenceAddress'
        )}
        value={residenceStreet}
      />
    </Box>
  );
};

interface IConfirmConsentStep {
  onConfirm: () => void;
  prevStep: () => void;
  modifyData: () => void;
}

export const RecapConsent: React.FC<IConfirmConsentStep> = ({
  onConfirm,
  prevStep,
  modifyData
}) => {
  const { t } = useTranslation();
  const { prefForWhom } = usePatient();
  const { consentData, confirmConsentData } = useInformedConsentData();
  const { refetch } = usePatient();
  const { sendGenericError } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  const confirmConsent = async () => {
    setLoading(true);
    const result = await confirmConsentData();
    if (!result) {
      sendGenericError();
      setLoading(false);
      return;
    }
    await refetch();
    setLoading(false);
    onConfirm();
  };

  return (
    <Box data-testid="consent-recap-wrapper">
      <SystemMessageAlert
        message={t('legal:informedConsent.signModal.recapLabels.alert')}
      />
      <Box
        pt="md"
        style={{
          // To avoid button to cover information
          marginBottom: 80
        }}
      >
        {consentData?.child && (
          <>
            <TabDivider
              title={t(`legal:informedConsent.signModal.childSection`)}
              modify={modifyData}
            />
            <InfoBox values={consentData.child} />
          </>
        )}
        {(consentData?.patient || consentData?.partner) && (
          <>
            {consentData?.patient && (
              <>
                <TabDivider
                  title={
                    prefForWhom === 'DAUGHTER'
                      ? consentData?.partner
                        ? t('legal:informedConsent.signModal.parentSection')
                        : t(
                            'legal:informedConsent.signModal.singleParentSection'
                          )
                      : t('legal:informedConsent.signModal.patientSection')
                  }
                  modify={modifyData}
                />
                <InfoBox values={consentData.patient} />
              </>
            )}
            {consentData?.partner && (
              <Box
                style={{ borderTop: `1px solid ${theme.colors.grey[200]}` }}
                pt="md"
                mt="md"
              >
                <InfoBox values={consentData.partner} />
              </Box>
            )}
          </>
        )}
      </Box>

      <Stack
        bgColor="white"
        justify="space-between"
        mt="md"
        pt="md"
        pb="xl"
        position="sticky"
        bottom={0}
        left={24}
        right={24}
        style={{
          borderTop: `1px solid ${theme.colors.grey[100]}`
        }}
      >
        <Button
          label={t('common:back')}
          onClick={prevStep}
          variant="ghost"
          size="lg"
        />
        <Button
          data-testid="confirm-consent-button"
          size="lg"
          label={t('common:confirm')}
          variant="filled"
          onClick={confirmConsent}
          disabled={loading}
        />
      </Stack>
    </Box>
  );
};
