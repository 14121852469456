import { Box } from '@unobravo/zenit-web';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../theme';
import { IMenuItem } from '../../types/IMenuItem';
import { Button } from '../Button';
import { ArrowLeft, ArrowRight } from '../Icons';
import { MenuItem } from './MenuItem';

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-left: 4px;
`;

const MenuItemsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Theme.colors.cappuccino[200]};
  margin: 2px 0 6px 0;
`;

interface IWrapper {
  menuOpen: boolean;
  small?: boolean;
  expandable: boolean;
}

const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  ${({ expandable, menuOpen, small }) =>
    expandable
      ? css`
          transition: width;
          transition-duration: 0.3s;
          transition-timing-function: cubic-bezier(0.71, 0, 0.58, 1);
          width: ${menuOpen ? (small ? 160 : 200) : small ? 48 : 50}px;
        `
      : ''}
  height:100%;
`;

interface ISidebarMenu {
  sections: Array<IMenuItem | 'DIVIDER' | (() => JSX.Element)>;
  toggleMenu?: () => void;
  isMenuOpen?: boolean;
  small?: boolean;
  expandable?: boolean;
  bottom?: JSX.Element;
  bottomMenuItem?: IMenuItem;
  handleSupportLinkClick?: () => void;
}

export const SidebarMenu: FC<ISidebarMenu> = ({
  isMenuOpen = true,
  toggleMenu,
  sections,
  small,
  expandable = true,
  bottom,
  bottomMenuItem,
  handleSupportLinkClick
}) => {
  return (
    <Wrapper menuOpen={isMenuOpen} small={small} expandable={expandable}>
      <MenuItemsWrapper>
        {sections.map((section, idx) => {
          if (section === 'DIVIDER') {
            return <Divider key={`DIVIDER_${idx.toString()}`} />;
          }
          if (typeof section === 'function') {
            return (
              <Box key={`SECTION_${idx.toString()}`} w="100%">
                {section()}
              </Box>
            );
          }
          return (
            <MenuItem
              menuIsOpen={isMenuOpen}
              key={section.id.toString()}
              {...section}
              closeMenu={toggleMenu}
              small={small}
              handleSupportLinkClick={handleSupportLinkClick}
            />
          );
        })}
      </MenuItemsWrapper>
      <div>
        {bottomMenuItem && (
          <MenuItem
            menuIsOpen={false}
            closeMenu={toggleMenu}
            small={small}
            {...bottomMenuItem}
          />
        )}
        {!!bottom && bottom}
        {expandable && (
          <ButtonContainer>
            <Button
              onClick={toggleMenu}
              icon={isMenuOpen ? ArrowLeft : ArrowRight}
              iconPosition="iconOnly"
              variant="ghost"
              size="small"
              data-testid="expand-sidebar-menu"
            />
          </ButtonContainer>
        )}
      </div>
    </Wrapper>
  );
};
