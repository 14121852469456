import * as Tooltip from '@radix-ui/react-tooltip';
import { Box, Text } from '@unobravo/zenit-web';
import React from 'react';

type TooltipIconProps = {
  tooltipText: string;
  icon: React.ReactElement;
  side?: 'left' | 'right' | 'top' | 'bottom';
};

const tooltipBgColor = 'rgb(10,10,10,0.7)';

export const TooltipIcon: React.FC<TooltipIconProps> = ({
  tooltipText,
  icon,
  side = 'bottom'
}) => {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <span tabIndex={0}>{icon}</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side={side}
            style={{ zIndex: 99 }} // 99 is the lowest number that shows it correctly
          >
            <Tooltip.TooltipArrow
              style={{ fill: tooltipBgColor, stroke: tooltipBgColor }}
            />
            <Box
              pl="xs"
              pr="xs"
              pt="2xs"
              pb="2xs"
              rounded="2xs"
              elevation="md"
              style={{
                backgroundColor: tooltipBgColor,
                backdropFilter: 'blur(30px)'
              }}
            >
              <Text fontWeight="medium" variant="sm" color="white">
                {tooltipText}
              </Text>
            </Box>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
