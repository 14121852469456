import { useEffect, useRef } from 'react';

type Callback = () => unknown;

export const useInterval = (callback: Callback, delay: number) => {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback?.current?.();
    };

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
};
