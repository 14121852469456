import { useState } from 'react';
import { usePatientUtils } from '@unobravo/patient';

export const useSendVerificationEmail = () => {
  const [isForcedResend, setForcedResend] = useState(false);
  const [error, setError] = useState<Error | unknown>();
  const { sendVerificationEmail } = usePatientUtils();

  const sendEmail = async (resend?: boolean) => {
    try {
      setForcedResend(resend ?? false);
      setError(undefined);
      await sendVerificationEmail(resend);
    } catch (e: unknown) {
      setError(e);
    }
  };

  return {
    isForcedResend,
    error,
    send: () => sendEmail(),
    resend: () => sendEmail(true)
  };
};
