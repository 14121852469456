import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useZendesk } from '@unobravo-monorepo/patient/shared/hooks/useZendesk';
import { usePatient } from '../../patientData/hooks/usePatient';
import { openReassignmentModal } from '../../reassignment/reassignment.slice';

export const ChatbotRedirectReassignment = () => {
  const { goToZendeskAsLoggedUser } = useZendesk();
  const { reassignConditions, email } = usePatient();
  const zendeskUrl = `hc/requests/new?ticket_form_id=13724037369617&tf_anonymous_requester_email=${email}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (reassignConditions?.canPerformSmoothReassign) {
      return navigate(`../dialog/reassignmentModal`, {
        state: {
          modal: '/patient/chat'
        }
      });
    }

    if (reassignConditions?.automatic) {
      dispatch(openReassignmentModal());
      return navigate(`../chat`);
    }

    goToZendeskAsLoggedUser(zendeskUrl);
    return navigate(`../chat`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
