import { useTheme } from '@unobravo/zenit-core';
import { Box, Stack } from '@unobravo/zenit-web';
import introBg from '@unobravo-monorepo/common/assets/introBg.png';
import introBgXs from '@unobravo-monorepo/common/assets/introBg-XS.png';
import introBgTablet from '@unobravo-monorepo/common/assets/introBg-tablet.png';
import introBgMobile from '@unobravo-monorepo/common/assets/introBg-mobile.png';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { mediaQueries } from '../../theme';

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  & picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    & source {
      right: 0;
    }
  }
`;

const Container = styled(Stack)`
  ${mediaQueries('sm')`
   margin: 0 20px;
  `}
`;

const Wrapper = styled(Box)`
  overflow: hidden;
  height: 100%;
`;

interface SignupLayoutChildren {
  children: ReactNode;
}

export function PlainBackgroundLayout({ children }: SignupLayoutChildren) {
  const theme = useTheme();

  return (
    <Wrapper>
      <ImageContainer>
        <picture>
          <source
            srcSet={introBg}
            media={`(min-width: ${theme.breakpoints.web.md}px)`}
          />
          <source
            srcSet={introBgTablet}
            media={`(min-width: ${theme.breakpoints.web.sm}px)`}
          />
          <source
            srcSet={introBgMobile}
            media={`(min-width: ${theme.breakpoints.mobile.md}px)`}
          />
          <img src={introBgXs} alt="background" />
        </picture>
      </ImageContainer>
      <Container
        align="center"
        direction="column"
        maxW="100%"
        maxH="100%"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        {children}
      </Container>
    </Wrapper>
  );
}
