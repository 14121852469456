import { getSessionDate as getCommonSessionDate } from '@unobravo-monorepo/common/utils/dateUtils';

const { NX_APP_SESSION_DURATION_IN_MINUTES } = process.env;

export function getSessionDate(date: string, timeZone?: string) {
  return getCommonSessionDate(
    date,
    NX_APP_SESSION_DURATION_IN_MINUTES!,
    timeZone
  );
}
