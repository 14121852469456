import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInternationalVariables } from '@unobravo/translations';
import { useZendesk } from '../../../shared/hooks/useZendesk';

const ZENDESK_REDIRECT_PARAM = 'zendeskPath';

export const ChatbotRedirectZendesk = () => {
  const [searchParams] = useSearchParams();
  const { goToZendeskAsLoggedUser } = useZendesk();
  const { zendeskDefaultUrl } = useInternationalVariables();

  const redirectUrl = searchParams.get(ZENDESK_REDIRECT_PARAM);
  const zendeskBaseUrl = `https://${zendeskDefaultUrl}.zendesk.com/`;

  useEffect(() => {
    if (!redirectUrl) return;

    goToZendeskAsLoggedUser(redirectUrl.replace(zendeskBaseUrl, ''), '_self');
  }, [goToZendeskAsLoggedUser, redirectUrl]);

  return null;
};
