import { mobileHeaderHeight } from '../../layouts/consts';
import styled, { css } from 'styled-components';
import { Theme, mobileFirstMedia } from '../../theme';

export * from './ChatInput';

export const HEADER_HEIGHT = 53;

export const ChatContainerHeader = styled.div`
  background-color: ${Theme.specialColors.white};
  height: ${HEADER_HEIGHT}px;
  position: sticky;
  top: ${mobileHeaderHeight}px;
  padding: 0 20px;
  z-index: 3;
  width: 100%;
`;

export const WrapperChatBox = styled.div`
  flex-grow: 1;
  position: relative;
  padding: 20px;
  ${mobileFirstMedia('md')(`
    padding: 0;
  `)}
`;

export const MoreMessagesBtn = styled.button`
  border: none;
  background: none;
  color: ${Theme.colors.candy[500]};
  font-family: ${Theme.fonts.secondary};
  cursor: pointer;
`;

export const MoreMessagesBtnContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DateWrapper = styled.div<{
  isSticky?: boolean;
  index?: number;
  top?: number;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  text-transform: capitalize;
  ${({ isSticky, index = 0, top = 0 }) =>
    isSticky
      ? css`
          position: sticky;
          top: ${top}px;
          z-index: ${index + 1};
          padding: 12px 0;
          margin-top: 12px;
          margin-bottom: -12px;
          background-color: ${Theme.specialColors.white};
        `
      : ''}
`;

export const ChatBubbleWrapper = styled.div<{ isMine: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMine }) => (isMine ? 'flex-end' : 'flex-start')};
  margin-top: 12px;
  > * {
    max-width: 80%;
  }
  ${({ isMine }) =>
    mobileFirstMedia('md')(`
    > * {
      max-width: 60%;
      max-width: max(350px, 60%);
      margin-${isMine ? 'left' : 'right'}: 20%;
    }
  `)}
`;
