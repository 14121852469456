import { Box, Button, Stack, Text } from '@unobravo/zenit-web';
import { CreditNote, Invoice } from '@unobravo/zenit-icons';

const InvoiceButton = ({
  onClick,
  variant = 'invoice',
  label
}: {
  onClick: () => void;
  label: string;
  variant?: 'invoice' | 'creditnote';
}) => (
  <Button
    onClick={onClick}
    size="md"
    variant="outlined"
    icon={variant === 'invoice' ? Invoice : CreditNote}
    iconPosition="left"
    colorVariant="primary"
    data-testid="invoice-cta'"
    loading={false}
    label={label}
  />
);

export const BundleBubble = ({
  title,
  subtitle,
  buttonLabel,
  onClick,
  variant
}: {
  title: string;
  subtitle: string;
  buttonLabel: string;
  onClick: () => void;
  variant?: 'invoice' | 'creditnote';
}) => {
  return (
    <Box data-testid="bundle-bubble" position="relative">
      <Stack
        p="md"
        bgColor="cappuccino.50"
        w="100%"
        rounded="md"
        direction="row"
        justify="space-between"
      >
        <Stack direction="column" pr="xs" spacing="2xs">
          <Text variant="lg" color="candy.500" fontWeight="semibold">
            {title}
          </Text>
          <Text variant="sm" color="black">
            {subtitle}
          </Text>
        </Stack>

        <InvoiceButton
          onClick={onClick}
          variant={variant}
          label={buttonLabel}
        />
      </Stack>
    </Box>
  );
};
