import { LayoutHeader } from '@unobravo-monorepo/common/components/LayoutHeader';
import {
  RiveBackground,
  RiveContainer
} from '@unobravo-monorepo/common/components/RiveBackground';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { useInternationalVariables } from '@unobravo/translations';
import { useTheme } from '@unobravo/zenit-core';
import {
  Button,
  Heading,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function SocialRegistrationGate() {
  const { t } = useTranslation();

  const { currentUser } = useFirebaseUser();

  const { email: currentUserEmail } = currentUser;

  const navigate = useNavigate();
  const { zendeskDefaultUrl } = useInternationalVariables();
  const { isMobile } = useBreakpointValue();

  const { margin, padding } = useTheme();

  return (
    <>
      <LayoutHeader />

      <Stack
        maxW="482px"
        direction="column"
        spacing="2xl"
        justify="center"
        align="center"
        style={{
          marginLeft: isMobile ? `${margin.md}px` : 'auto',
          marginRight: isMobile ? `${margin.md}px` : 'auto'
        }}
      >
        <Stack direction="column" align="center" spacing="sm">
          <Heading
            variant="xl"
            color="black"
            textAlign="center"
            style={{
              fontSize: isMobile ? '26px' : undefined
            }}
          >
            {t('social.welcome')}
          </Heading>
          <Text
            textAlign="center"
            variant="md"
            color="grey.800"
            style={{ width: isMobile ? '312px' : '100%' }}
          >
            {t('social.noEmail', { email: currentUserEmail })}
          </Text>
        </Stack>

        <Stack
          bgColor="white"
          direction="column"
          align="center"
          elevation="sm"
          rounded="sm"
          py="2xl"
          style={{
            paddingRight: isMobile ? `${padding.xl}px` : '48px',
            paddingLeft: isMobile ? `${padding.xl}px` : '48px'
          }}
          spacing="2xl"
        >
          <Text textAlign="center" color="grey.800">
            {t('social.choice')}
          </Text>

          <Stack direction="column" spacing="sm" w="100%">
            <Button
              label={t('social.cta.registration')}
              size="lg"
              onClick={() => {
                return navigate('/signup');
              }}
            />
            <Button
              label={t('social.cta.differentEmail')}
              size="lg"
              variant="outlined"
              onClick={async () => {
                await signOut(getAuth());
                navigate('/login');
              }}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        bgColor="cappuccino.50"
        position="fixed"
        bottom={0}
        w="100%"
        py="xs"
        justify="center"
      >
        <Text color="grey.800">
          {t('social.help', {
            zendeskDefaultUrl
          })}
        </Text>
      </Stack>

      <RiveContainer>
        <RiveBackground />
      </RiveContainer>
    </>
  );
}
