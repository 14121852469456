import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import {
  StorageKeys,
  createSafeStorage
} from '@unobravo-monorepo/common/utils/storage';
import { BundleThankYou } from '../components/BundleThankYou';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';

const [getIsMobileApp] = createSafeStorage(StorageKeys.isMobileApp);

export const BundleThankYouPage = () => {
  const isMobileApp = getIsMobileApp();

  return (
    <>
      {isMobileApp ? (
        <BundleThankYou isMobileApp />
      ) : (
        <PatientLayout>
          <PageContainer>
            <BundleThankYou />
          </PageContainer>
        </PatientLayout>
      )}
    </>
  );
};
