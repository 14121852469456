import { redirect, Route } from 'react-router-dom';
import { GenericErrorPage } from '@unobravo-monorepo/common/components/errorPage';

export function getDoctorAcquisitionRoutes() {
  return (
    <Route
      path="/doctor-acquisition/*"
      lazy={async () => {
        const { DoctorAcquisitionProviders } = await import('./index');
        return { Component: DoctorAcquisitionProviders };
      }}
    >
      <Route path="page-not-found" element={<GenericErrorPage />} />
      <Route
        path="thank-you"
        lazy={async () => {
          const { ThankYouPage } = await import('./index');
          return { Component: ThankYouPage };
        }}
      />
      <Route
        lazy={async () => {
          const { AcquisitionLayout } = await import('./index');
          return { Component: AcquisitionLayout };
        }}
      >
        <Route errorElement={<GenericErrorPage />}>
          <Route
            path=""
            lazy={async () => {
              const { PersonalInformation } = await import('./index');
              return { Component: PersonalInformation };
            }}
          />
          <Route
            path="fiscal-data"
            lazy={async () => {
              const { FiscalData } = await import('./index');
              return { Component: FiscalData };
            }}
          />
          <Route
            path="data-recap"
            lazy={async () => {
              const { DataRecap } = await import('./index');
              return { Component: DataRecap };
            }}
          />
          <Route
            path="terms-and-conditions"
            lazy={async () => {
              const { TermsAndConditions } = await import('./index');
              return { Component: TermsAndConditions };
            }}
          />
          <Route path="*" loader={() => redirect('/')} />
        </Route>
      </Route>
    </Route>
  );
}
