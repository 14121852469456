import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext, useContext } from 'react';

interface IApolloRecapProvider {
  client?: ApolloClient<NormalizedCacheObject>;
}

export const ApolloSignupV2Context = createContext<IApolloRecapProvider>({});

export function useApolloSignupV2Client() {
  const { client } = useContext(ApolloSignupV2Context);

  return client;
}
