import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appendMessage,
  openChat,
  setMessages,
  useChatUtils,
  useGTMUtils,
  usePatientAnalyticsUtils,
  setChatLoading
} from '@unobravo/patient';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useToast } from '../../toaster/hooks/useToast';
import { chatSelector } from '../store/selectors';
import { usePatientGTM } from '../../../shared/hooks/usePatientGTM';

const MESSAGE_SIZE = 20;

export const useChat = () => {
  const dispatch = useDispatch();
  const { analyticsData } = usePatientAnalyticsUtils();
  const { id, doctor, uuid } = usePatient();
  const { GTMData } = useGTMUtils();
  const {
    messages,
    hasPreviousMessages,
    firstCursor,
    scroll,
    status,
    newMessage: receivedNewMessage,
    groupedMessages
  } = useSelector(chatSelector);

  const {
    patientMessages: data,
    loading,
    error,
    loadMessages: exportedLoadMessages,
    reloadLastMessages: exportedReloadLastMessages,
    sendMessage,
    setMessageAsRead
  } = useChatUtils();
  const { sendToast } = useToast();
  const { t } = useTranslation();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const sendNewMessage = async (content: string) => {
    if (id === undefined || doctor?.id === undefined) {
      return;
    }
    const isFirstMessage = messages.every((message) => !message.isMine);
    try {
      const newMessage = await sendMessage(id, doctor.id, content);
      isFirstMessage &&
        !analyticsData?.firstReplyAllTime &&
        pushAuthenticatedEvent('FirstReply', {
          tp_id: doctor.id,
          tp_first_name: doctor?.name,
          tp_last_name: doctor?.surname,
          email: GTMData?.email,
          user_id: id,
          uuid
        });
      dispatch(appendMessage(newMessage?.data?.sendMessage));
      receivedNewMessage && dispatch(openChat());
    } catch {
      sendToast({
        variant: 'error',
        title: t('common:errorPage.ops2'),
        description: t('common:errorPage.retry')
      });
    }
  };

  const loadMessages = useCallback(() => {
    if (doctor?.id !== undefined && id !== undefined) {
      exportedLoadMessages(id, doctor.id, MESSAGE_SIZE, firstCursor);
    }
  }, [firstCursor, doctor, id, exportedLoadMessages]);

  useEffect(() => {
    if (status === 'idle') {
      loadMessages();
      dispatch(setChatLoading());
    }
  }, [status, loadMessages, dispatch]);

  useEffect(() => {
    if (data) {
      dispatch(
        setMessages({
          messages: data?.getMessagesV2?.edges,
          hasPreviousMessages: data?.getMessagesV2?.pageInfo?.hasPreviousPage,
          firstCursor: data?.getMessagesV2?.pageInfo?.firstCursor
        })
      );
    }
  }, [data, dispatch]);

  const reloadLastMessages = () => {
    if (doctor?.id !== undefined && id !== undefined) {
      exportedReloadLastMessages(id, doctor?.id, messages.length);
    }
  };

  const openChatFunction = () => {
    dispatch(openChat());
  };

  return {
    messages,
    hasPreviousMessages,
    sendNewMessage,
    loadMessages,
    firstCursor,
    scroll,
    loading,
    reloadLastMessages,
    error,
    groupedMessages,
    loaded: status === 'done',
    openChat: openChatFunction,
    setMessageAsRead
  };
};
