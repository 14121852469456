import { RStack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

interface WelcomeMessageProps {
  name: string;
  description?: string;
}

export const WelcomeMessage = ({ name, description }: WelcomeMessageProps) => {
  const { t } = useTranslation();

  return (
    <RStack
      direction="column"
      p="xl"
      spacing="md"
      w={{ base: '100%', md: '384px' }}
    >
      <Text color="candy.500">{t('home.welcomeMessage.title', { name })}</Text>
      <Text
        fontWeight="semibold"
        variant="2xl"
        color="black"
        style={{
          opacity: '0.8'
        }}
      >
        {description ?? t('home.welcomeMessage.message')}
      </Text>
    </RStack>
  );
};
