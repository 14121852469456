import { Color, useColor } from '@unobravo/zenit-core';

export const Unchecked = ({
  color = 'grey.100',
  size = 20
}: {
  color?: Color;
  size?: number;
}) => {
  const calculatedColor = useColor(color);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="9.25"
        stroke={calculatedColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};
