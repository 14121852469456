import { Color } from '@unobravo/zenit-core';
import { CreditNote, Invoice } from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useMemo, useState } from 'react';
import { SessionStatus } from '../../types/IAppointments';
import { CustomTooltip } from '../CustomTooltip';

export type AppointmentType =
  | 'New'
  | 'Invoice'
  | 'Videocall'
  | 'Confirmed'
  | 'Expired'
  | 'Paid'
  | 'Past';

interface IAppointment {
  dateLabel: string;
  hourLabel: string;
  messageTime?: string;
  headerLabel?: string;
  status?: SessionStatus;
  type?: AppointmentType;
  actionLabel: string;
  isInvoiceable?: boolean;
  showCreditNote?: boolean;
  creditNoteDisabled?: boolean;
  action?: () => void | Promise<void>;
  'data-testid'?: string;
  id?: string;
  onInvoiceDownloadPress?: () => Promise<void>;
  onCreditNoteDownloadPress?: () => Promise<void>;
  invoiceDisabled?: boolean;
  invoiceTooltipLabel?: string;
  creditNoteTooltipLabel?: string;
  actionLabelCreditNote?: string;
  roundedFull?: boolean;
}

const backgroundColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.50';
    default:
      return 'cappuccino.50';
  }
};

const primaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.500';
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.500';
    default:
      return 'candy.500';
  }
};

const secondaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.700';
    default:
      return 'black';
  }
};

export function Appointment({
  dateLabel,
  hourLabel,
  messageTime,
  headerLabel,
  type,
  actionLabel,
  action,
  status,
  isInvoiceable = false,
  showCreditNote = false,
  onInvoiceDownloadPress,
  onCreditNoteDownloadPress,
  invoiceTooltipLabel,
  invoiceDisabled = false,
  creditNoteDisabled = false,
  'data-testid': dataTestId = 'appointment-bubble',
  creditNoteTooltipLabel,
  actionLabelCreditNote,
  id,
  roundedFull = false
}: IAppointment) {
  const [openInvoiceTooltip, setOpenInvoiceTooltip] = useState(false);
  const [openCreditNoteTooltip, setOpenCreditNoteTooltip] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [creditNoteLoading, setCreditNoteLoading] = useState(false);
  const { isMobile } = useBreakpointValue();

  const buttonClick = action
    ? async () => {
        setButtonLoading(true);
        await action();
        setButtonLoading(false);
      }
    : undefined;

  const handleFileDownload = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    downloadFunction?: () => Promise<void>
  ) => {
    if (!downloadFunction) return;
    setLoading(true);
    try {
      await downloadFunction();
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const calcStatus: SessionStatus = useMemo(() => {
    if (status) {
      return status;
    }
    switch (type) {
      case 'Invoice':
      case 'Paid':
        return 'CONFIRMED';
      case 'Past':
        return 'DONE';
      default:
        return type ? (type.toUpperCase() as SessionStatus) : 'NEW';
    }
  }, [status, type]);

  /* siamo in agenda, be ci ritorna la fattura e la seduta è terminata */
  const onlyInvoiceButton = isInvoiceable && status === 'DONE';

  /* siamo in agenda, be ci ritorna la nota di credito e la seduta è terminata */
  const onlyCreditNoteButton = showCreditNote && status === 'DONE';

  /* siamo in mobile, abbiamo fattura e nota di credito e si deve aprire la modale */
  const showOnlyCreditNoteIcon = isMobile && showCreditNote && isInvoiceable;

  /* qui si deve vedere solo l'icona della fattura perchè non abbiamo nota di credito */
  const showInvoiceButtonIcon =
    !showOnlyCreditNoteIcon && isInvoiceable && calcStatus !== 'DONE';

  const showOnlyCreditNoteButton =
    !isMobile || (isMobile && !onlyCreditNoteButton);

  const colorVariantButton = ['VIDEOCALL', 'CONFIRMED'].includes(calcStatus)
    ? 'accent'
    : 'primary';

  const invoiceClick = () =>
    handleFileDownload(setInvoiceLoading, onInvoiceDownloadPress);

  const creditNoteClick = () =>
    handleFileDownload(setCreditNoteLoading, onCreditNoteDownloadPress);

  const ActionButtons = buttonClick ? (
    <>
      {showOnlyCreditNoteButton ? (
        <Box>
          <Button
            label={actionLabel}
            onClick={buttonClick}
            size="md"
            variant={onlyInvoiceButton ? 'outlined' : 'filled'}
            icon={onlyInvoiceButton ? Invoice : undefined}
            iconPosition="left"
            colorVariant={colorVariantButton}
            data-testid={`${dataTestId}-cta`}
            disabled={buttonLoading}
            loading={buttonLoading}
          />
        </Box>
      ) : null}
      {onlyCreditNoteButton && (
        <Box ml="xs">
          <Button
            label={actionLabelCreditNote}
            onClick={creditNoteClick}
            size="md"
            variant="outlined"
            icon={CreditNote}
            iconPosition="left"
            colorVariant={colorVariantButton}
            data-testid={`${dataTestId}-cta`}
            disabled={creditNoteLoading}
            loading={creditNoteLoading}
          />
        </Box>
      )}
    </>
  ) : (
    <Stack
      direction="column"
      style={{
        flexGrow: 0,
        flexShrink: 1
      }}
      data-testid={`${dataTestId}-${status?.toLowerCase()}-label`}
    >
      <Text
        textAlign="center"
        fontWeight="semibold"
        variant="lg"
        color={primaryColor(calcStatus)}
      >
        {actionLabel}
      </Text>
    </Stack>
  );

  const InvoiceButtonIcon = (
    <>
      <Box mr="sm">
        <CustomTooltip
          label={invoiceTooltipLabel ?? ''}
          open={isMobile ? openInvoiceTooltip : undefined}
        >
          <Button
            onClick={() => (!invoiceDisabled ? invoiceClick() : undefined)}
            onTouchEnd={() =>
              invoiceDisabled
                ? setOpenInvoiceTooltip((value) => !value)
                : undefined
            }
            size="md"
            variant="outlined"
            icon={Invoice}
            iconPosition="iconOnly"
            colorVariant={colorVariantButton}
            color="blue"
            data-testid={`${dataTestId}-invoice-cta`}
            loading={invoiceLoading}
            style={{ opacity: invoiceDisabled ? 0.4 : 1 }}
            disabled={isMobile ? invoiceLoading : invoiceDisabled}
          />
        </CustomTooltip>
      </Box>
    </>
  );

  const CreditNoteButtonIcon = (
    <Box mr="sm">
      <CustomTooltip
        label={creditNoteTooltipLabel ?? ''}
        open={isMobile ? openCreditNoteTooltip : undefined}
      >
        <Button
          onClick={() => (!creditNoteDisabled ? creditNoteClick() : undefined)}
          onTouchEnd={() =>
            creditNoteDisabled
              ? setOpenCreditNoteTooltip((value) => !value)
              : undefined
          }
          size="md"
          variant="outlined"
          icon={CreditNote}
          iconPosition="iconOnly"
          colorVariant={colorVariantButton}
          color="blue"
          data-testid={`${dataTestId}-invoice-cta`}
          loading={creditNoteLoading}
          style={{ opacity: creditNoteDisabled ? 0.4 : 1 }}
          disabled={isMobile ? creditNoteLoading : creditNoteDisabled}
        />
      </CustomTooltip>
    </Box>
  );

  return (
    <Box
      data-testid={id ? `${dataTestId}-${id}` : dataTestId}
      position="relative"
      data-type={type}
    >
      {headerLabel && (
        <Box mb="2xs">
          <Text variant="sm" textAlign="center">
            {headerLabel}
          </Text>
        </Box>
      )}
      <Stack
        p="md"
        bgColor={backgroundColor(calcStatus)}
        w="100%"
        roundedTop="md"
        roundedBottom={roundedFull ? 'md' : undefined}
        direction="row"
        align="center"
        justify="space-between"
      >
        <Stack
          direction="column"
          pr="xs"
          spacing="2xs"
          style={{
            flexShrink: 0,
            flexGrow: 1
          }}
        >
          <Text
            variant="lg"
            color={primaryColor(calcStatus)}
            fontWeight="semibold"
          >
            {dateLabel}
          </Text>
          <Text variant="sm" color={secondaryColor(calcStatus)}>
            {hourLabel}
          </Text>
          {messageTime && (
            <Text variant="xs" color="cappuccino.700">
              {messageTime}
            </Text>
          )}
        </Stack>

        {showInvoiceButtonIcon ? InvoiceButtonIcon : null}
        {showCreditNote && !onlyCreditNoteButton ? CreditNoteButtonIcon : null}
        {ActionButtons}
      </Stack>
    </Box>
  );
}
