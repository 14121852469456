import { Counter } from '@unobravo-monorepo/common/components/Counter';
import { Logo } from '@unobravo-monorepo/common/components/Logo';
import {
  Box,
  Button,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IStepIcon, StepIcon } from '../components/Icons/StepIcon';
import { usePrice } from '../hooks/usePrice';

interface IViewport {
  isMobile: boolean;
}

const StepWrapper = styled(({ ...props }) => (
  <motion.div {...props} />
))<IViewport>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 1.56px;
  font-size: 13px;
`;

const ButtonsWrapper = styled(Stack)<IViewport>`
  button:only-child {
    margin-left: auto;
  }
`;

interface StepProps {
  image: 'first' | 'second' | 'third';
  label: string;
}

const Step = ({ image, label }: StepProps) => {
  const { t } = useTranslation();

  const variants = {
    enter: {
      opacity: 0
    },
    center: {
      opacity: 1
    }
  };

  return (
    <StepWrapper initial="enter" animate="center" variants={variants}>
      <StepIcon step={image} />
      <Box mt="xl" mb="xs">
        <Title color="candy.500" fontWeight="semibold" textAlign="center">
          {t('info.title')}
        </Title>
      </Box>
      <Box maxW={290}>
        <Text variant="2xl" color="grey.900" textAlign="center">
          {label}
        </Text>
      </Box>
    </StepWrapper>
  );
};

export const Info: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const navigate = useNavigate();
  const [infoStep, setInfoStep] = useState(0);
  const nextPage = () => navigate('/');
  const { price } = usePrice({ onError: nextPage });

  const phrases = [
    t('info.phrase1'),
    t('info.phrase2', { price }),
    t('info.phrase3')
  ];
  const images: IStepIcon['step'][] = ['first', 'second', 'third'];

  return (
    <RStack
      align="center"
      direction="column"
      p={{ base: 'xl', md: '2xl' }}
      h="100%"
    >
      <Logo width={128} />
      <Stack
        direction="column"
        justify="center"
        style={{ flex: 1 }}
        maxW={390}
        w="100%"
      >
        <Stack
          direction="column"
          justify="center"
          style={{ flex: isMobile ? 1 : 0 }}
        >
          <Step label={phrases[infoStep]} image={images[infoStep]} />
          <Box mt="2xl" mb="2xl">
            <Counter
              number={infoStep}
              limit={phrases.length}
              variant="primary"
            />
          </Box>
        </Stack>
        <ButtonsWrapper justify="space-between" w="100%" isMobile={isMobile}>
          {infoStep > 0 && (
            <Button
              label={t('info.back')}
              inverse
              size="lg"
              onClick={() => setInfoStep(infoStep - 1)}
            />
          )}
          {infoStep < phrases.length - 1 && (
            <Button
              label={t('info.next')}
              variant="outlined"
              size="lg"
              onClick={() => setInfoStep(infoStep + 1)}
            />
          )}
          {infoStep === phrases.length - 1 && (
            <Button label={t('info.confirm')} onClick={nextPage} size="lg" />
          )}
        </ButtonsWrapper>
      </Stack>
    </RStack>
  );
};
