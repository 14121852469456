import { Outlet } from 'react-router-dom';
import { useVersionCheck } from '../../hooks';
import { CountryCheckModal } from '../CountryCheckModal';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { PageViewTracker } from './PageViewTracker';

const VersionCheck = () => {
  useVersionCheck();
  return <></>;
};

export const Layout = () => (
  <>
    <Outlet />
    <PageViewTracker />
    <VersionCheck />
    <CountryCheckModal />
    <LanguageSwitcher />
  </>
);
