import { usePaymentUtils } from '@unobravo/patient';
import { useEffect } from 'react';
import { usePatient } from '../../patientData/hooks/usePatient';

export const useGetPaymentIntent = () => {
  const { id: patientId } = usePatient();
  const {
    paymentIntentData,
    paymentIntentError,
    paymentIntentLoading,
    loadPaymentIntent
  } = usePaymentUtils();

  useEffect(() => {
    if (patientId) {
      loadPaymentIntent();
    }
  }, [patientId, loadPaymentIntent]);

  return {
    paymentIntent: paymentIntentData,
    loading: paymentIntentLoading,
    error: paymentIntentError
  };
};
