import { useQuery } from '@apollo/client';
import { RStack, Stack, Text } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { BundleCard } from '../../../shared/components/Bundles/BundleCard';
import { ListPatientBundlesDocument } from '../../home/graphql/queries.generated';
import { BuyBackCard } from './BuyBackCard';
import { InvoiceBanner } from './InvoiceBanner';

export const BundlePageContent = ({ patientId }: { patientId: number }) => {
  const { t } = useTranslation();

  const { data, error } = useQuery(ListPatientBundlesDocument, {
    variables: {
      patientId
    }
  });

  if (error) return null;

  if (!data?.listPatientBundles || data.listPatientBundles.length === 0)
    return (
      <Stack p="xl" maxW="948px">
        <BuyBackCard />
      </Stack>
    );

  const bundlesList = data?.listPatientBundles || [];
  const latestBundle = bundlesList[bundlesList.length - 1];

  const shouldBuyAgain =
    latestBundle &&
    (latestBundle.sessionsAvailables === 0 ||
      DateTime.fromISO(latestBundle.validUntil) < DateTime.now());

  return (
    <RStack
      direction={{ base: 'column', sm: 'row' }}
      spacing={{ base: '3xl', sm: 'xl' }}
      py="2xl"
      px={{ base: 'md', sm: 'xl' }}
    >
      <Stack direction="column" maxW="948px" spacing="md" style={{ flex: 1 }}>
        <Text variant="2xl" fontWeight="bold">
          {t('bundles.pageSubtitle')}
        </Text>
        {shouldBuyAgain ? (
          <BuyBackCard />
        ) : (
          <BundleCard activeBundle bundle={latestBundle} />
        )}
        <InvoiceBanner />
      </Stack>
      {data.listPatientBundles.length > 0 && (
        <Stack direction="column" spacing="md" style={{ flex: 1 }}>
          <Text variant="2xl" fontWeight="bold">
            {t('bundles.pastBundles')}
          </Text>
          {data.listPatientBundles
            .slice(0, latestBundle && !shouldBuyAgain ? -1 : undefined)
            .map((bundle) => {
              return (
                <BundleCard key={bundle?.patientBundleId} bundle={bundle} />
              );
            })}
        </Stack>
      )}
    </RStack>
  );
};
