import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import {
  Doc,
  DownloadArrow,
  Edit,
  NewWindow
} from '@unobravo-monorepo/common/components/Icons';
import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import IIcon from '@unobravo-monorepo/common/types/IIcon';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';

const WrapperStepper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px 20px;
  gap: 12px;
  background: ${Theme.colors.cappuccino[50]};
  border-radius: 20px;
  order: 0;
  flex-grow: 1;
  margin: 24px 0 0 0;
  ${mediaQueries('md')(`
    margin: 16px 0 0 0;
  `)};
  ${mediaQueries('sm')(`
    padding: 0 16px;
    height: 76px;
  `)};
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 140px;
`;

const Line = styled.div`
  border-top: 2px dotted ${Theme.colors.sand[400]};
  flex-grow: 1;
  max-width: 10%;
`;

const Step: React.FC<{
  Icon: React.FC<IIcon>;
  label: string;
  description: string;
}> = ({ Icon, label, description }) => {
  const { isMobile } = useViewport();

  return (
    <StepWrapper>
      <Icon color={Theme.colors.candy[500]} />
      <Body
        size={BodySize.Body60}
        variant={TypographyVariant.SemiBold}
        textAlign={'center'}
        margin={'4px 0'}
      >
        {label}
      </Body>
      {!isMobile && (
        <Body
          size={BodySize.Body60}
          variant={TypographyVariant.Regular}
          textAlign={'center'}
          margin={'4px 0'}
        >
          {description}
        </Body>
      )}
    </StepWrapper>
  );
};

export const MinorSteps: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WrapperStepper>
      <Step
        Icon={Doc}
        label={`${t('legal:informedConsent.minorStepper.fillTitle')}`}
        description={`${t(
          'legal:informedConsent.minorStepper.fillDescription'
        )}`}
      />
      <Line />
      <Step
        Icon={DownloadArrow}
        label={`${t('legal:informedConsent.minorStepper.downloadTitle')}`}
        description={`${t(
          'legal:informedConsent.minorStepper.downloadDescription'
        )}`}
      />
      <Line />
      <Step
        Icon={Edit}
        label={`${t('legal:informedConsent.minorStepper.signTitle')}`}
        description={`${t(
          'legal:informedConsent.minorStepper.signDescription'
        )}`}
      />
      <Line />
      <Step
        Icon={NewWindow}
        label={`${t('legal:informedConsent.minorStepper.uploadTitle')}`}
        description={`${t(
          'legal:informedConsent.minorStepper.uploadDescription'
        )}`}
      />
    </WrapperStepper>
  );
};
