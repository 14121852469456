import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import passwordChangeImg from '@unobravo-monorepo/patient/assets/psw-change.svg';
import { Button } from '@unobravo-monorepo/common/components/Button';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { Eye } from '@unobravo-monorepo/common/components/Icons/Eye';
import { EyeClosed } from '@unobravo-monorepo/common/components/Icons/EyeClosed';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { passwordRegex } from '@unobravo-monorepo/common/utils/regexes';
import { SectionHeader } from '../components/SectionHeader';
import { useToast } from '../../toaster/hooks/useToast';
import { RightWrapperIllustration } from '../components/RightWrapperIllustration';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  max-width: 480px;
  ${mediaQueries('sm')(`
    justify-content: space-between;
  `)}
`;

const Wrapper = styled.div`
  background-color: ${Theme.specialColors.white};
  border-radius: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  z-index: 2;
  ${mediaQueries('sm')(`
    padding-bottom: 0;
  `)}
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  padding-top: 20px;
  display: flex;
  ${mediaQueries('sm')(`
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding-bottom: 20px;
    background-color: ${Theme.specialColors.white};
  `)}
`;

const InputRow = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 3fr 1fr;

  ${mediaQueries('sm')(`
    grid-template-columns:1fr 0.001fr;
  `)}
`;

const EyeButtonContainer = styled.div`
  cursor: pointer;
`;

export const ChangePsw: React.FC = () => {
  const { t } = useTranslation();

  const { updateUserPassword } = useFirebaseUser();

  const [inputConfigOldPsw, setInputConfigOldPsw] = useState({
    type: 'password',
    icon: EyeClosed
  });

  const [inputConfigNewPsw, setInputConfigNewPsw] = useState({
    type: 'password',
    icon: EyeClosed
  });

  const { register, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordCheck: ''
    }
  });

  const { sendToast } = useToast();
  const { errors } = formState;
  const onSubmit = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      const { oldPassword, newPassword } = data;
      await updateUserPassword(oldPassword, newPassword);
      sendToast({
        variant: 'success',
        title: `${t('changePassword.pswChanged.title')}`,
        description: `${t('changePassword.pswChanged.description')}`,
        'data-testid': 'success-change-password-toast'
      });
    } catch {
      sendToast({
        variant: 'error',
        title: `${t('changePassword.pswNotChanged.title')}`,
        description: `${t('changePassword.pswNotChanged.description')}`,
        'data-testid': 'error-change-password-toast'
      });
    }
  };

  const oldPswVisibilityToggle = () => {
    if (inputConfigOldPsw.type === 'password') {
      setInputConfigOldPsw({ type: 'text', icon: Eye });
    } else {
      setInputConfigOldPsw({ type: 'password', icon: EyeClosed });
    }
  };

  const newPswVisibilityToggle = () => {
    if (inputConfigNewPsw.type === 'password') {
      setInputConfigNewPsw({ type: 'text', icon: Eye });
    } else {
      setInputConfigNewPsw({ type: 'password', icon: EyeClosed });
    }
  };

  return (
    <PageContainer>
      <SectionHeader path="../" title={`${t('changePassword.title')}`} />
      <Container>
        <Wrapper>
          <div />
          <InputRow>
            <Heading size={HeadingSize.Title80} margin="4px 0px">
              {t('changePassword.title')}
            </Heading>
          </InputRow>
          <InputRow>
            <Body size={BodySize.Body80}>{t('changePassword.subtitle')}</Body>
          </InputRow>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Body
                size={BodySize.Body80}
                variant={TypographyVariant.SemiBold}
                margin="0px 0 4px 0"
              >
                {t('changePassword.oldPassword')}
              </Body>
              <TextInput
                data-testid="old-password"
                type={inputConfigOldPsw.type}
                {...register('oldPassword', {
                  required: t('common:requiredField')
                })}
                errorMessage={errors?.oldPassword?.message}
                icon={
                  <EyeButtonContainer onClick={oldPswVisibilityToggle}>
                    <inputConfigOldPsw.icon />
                  </EyeButtonContainer>
                }
              />
              <Body
                size={BodySize.Body80}
                variant={TypographyVariant.SemiBold}
                margin="20px 0 4px 0"
              >
                {t('changePassword.newPassword')}
              </Body>
              <TextInput
                data-testid="new-password"
                type={inputConfigNewPsw.type}
                {...register('newPassword', {
                  required: `${t('common:requiredField')}`,
                  validate: (value) => {
                    const newPasswordCheckValue = getValues('newPasswordCheck');
                    if (
                      newPasswordCheckValue &&
                      newPasswordCheckValue !== value
                    ) {
                      return `${t('changePassword.pswNotMatch')}`;
                    }
                    if (!passwordRegex.test(value)) {
                      return `${t('changePassword.parametersNotRespected')}`;
                    }
                    return undefined;
                  }
                })}
                errorMessage={errors?.newPassword?.message}
                icon={
                  <EyeButtonContainer onClick={newPswVisibilityToggle}>
                    <inputConfigNewPsw.icon />
                  </EyeButtonContainer>
                }
              />
              <Body
                color={Theme.colors.gray[500]}
                size={BodySize.Body60}
                margin="8px 0 0 0"
                variant={TypographyVariant.Light}
              >
                {t('changePassword.pswParamenters')}
              </Body>
            </div>
            <ButtonContainer>
              <Button
                data-testid="change-password"
                label={t('changePassword.save')}
                variant="primary"
                size="small"
                type="submit"
                fullWidth={false}
              />
            </ButtonContainer>
          </Form>
        </Wrapper>
        <RightWrapperIllustration illustration={passwordChangeImg} />
      </Container>
    </PageContainer>
  );
};
