import { Window } from '@unobravo/zenit-icons';
import { Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TroubleshootingModal } from './TroubleshootingModal';

interface IAppointmentHeader {
  doctorName: string;
  appointmentUrl?: string;
}

const ActionLink = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  align-items: center;
  padding-right: 10px;
  user-select: none;
`;

export const AppointmentHeader = ({
  doctorName,
  appointmentUrl
}: IAppointmentHeader) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();

  return (
    <>
      <Text
        variant={isMobile ? 'md' : 'lg'}
        fontWeight="semibold"
        style={{ margin: '0 auto 0 10px', letterSpacing: '0.16px' }}
      >
        {doctorName}
      </Text>
      {appointmentUrl && (
        <Stack align="start">
          <ActionLink onClick={() => window.open(appointmentUrl)}>
            {!isMobile && (
              <Text
                variant="lg"
                color="candy.500"
                textAlign="right"
                style={{ margin: '0 4px 0 0' }}
              >
                {t('videoCall.button')}
              </Text>
            )}
            <Window color="candy.500" />
          </ActionLink>
          <TroubleshootingModal />
        </Stack>
      )}
    </>
  );
};
