import { useCountry } from '@unobravo/translations';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const apiKey = process.env['NX_APP_GOOGLE_PLACES_API_KEY']!;

type PlaceDetails = {
  street_number?: string;
  route?: string;
  postal_code?: string;
  administrative_area_level_2?: string;
  administrative_area_level_3?: string;
  country?: string;
  locality?: string;
};

const headers = {
  'Content-Type': 'application/json',
  'X-Goog-Api-Key': apiKey
};

export const usePlacesAutocomplete = (disableSession?: boolean) => {
  const [sessionToken, setSessionToken] = useState(uuidv4());
  const { domainCountry } = useCountry();
  const regionCode = domainCountry.toLowerCase();
  const fetchAutocomplete = async (
    searchText: string
  ): Promise<{
    suggestions: {
      placePrediction: { text: { text: string }; placeId: string };
    }[];
  }> => {
    const body: {
      sessionToken?: string;
      input: string;
      includedRegionCodes: string[];
      languageCode: string;
      regionCode: string;
      includedPrimaryTypes: string[];
    } = {
      input: searchText,
      includedRegionCodes: [regionCode],
      languageCode: regionCode,
      regionCode,
      includedPrimaryTypes: [
        'street_address',
        'street_number',
        'route',
        'locality'
      ]
    };
    if (!disableSession) {
      body.sessionToken = sessionToken;
    }
    try {
      const response = await fetch(
        'https://places.googleapis.com/v1/places:autocomplete',
        {
          method: 'POST',
          headers,
          body: JSON.stringify(body)
        }
      );
      return response ? await response.json() : {};
    } catch (e) {
      return Promise.reject(e);
    }
  };
  const fetchAddressValidation = async (
    placeId: string
  ): Promise<PlaceDetails> => {
    try {
      const response = await fetch(
        `https://places.googleapis.com/v1/places/${placeId}?${
          !disableSession ? `sessionToken=${sessionToken}&` : ''
        }regionCode=${regionCode}&languageCode=${regionCode}`,
        {
          method: 'GET',
          headers: {
            ...headers,
            'X-Goog-FieldMask': '*'
          }
        }
      );
      const result: PlaceDetails = {};
      if (response) {
        const json = await response.json();
        if (Array.isArray(json.addressComponents)) {
          json.addressComponents.forEach(
            (component: { shortText: string; types: string[] }) => {
              if (component.types.includes('street_number')) {
                result.street_number = component.shortText;
              }
              if (component.types.includes('route')) {
                result.route = component.shortText;
              }
              if (component.types.includes('postal_code')) {
                result.postal_code = component.shortText;
              }
              if (component.types.includes('administrative_area_level_2')) {
                result.administrative_area_level_2 = component.shortText;
              }
              if (component.types.includes('administrative_area_level_3')) {
                result.administrative_area_level_3 = component.shortText;
              }
              if (component.types.includes('locality')) {
                result.locality = component.shortText;
              }
              if (component.types.includes('country')) {
                result.country = component.shortText;
              }
            }
          );
        }
      }
      return result;
    } finally {
      setSessionToken(uuidv4());
    }
  };
  return {
    fetchAutocomplete,
    fetchAddressValidation
  };
};
