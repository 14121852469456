import { Country } from '@unobravo/core';
import { ReactElement, ReactNode } from 'react';
import { useCountry } from '../hooks/useCountry';

interface IntlRenderProps {
  include?: Country[];
  exclude?: Country[];
  children: ReactNode;
}

export function IntlRender(props: IntlRenderProps) {
  const { domainCountry } = useCountry();
  const { include, exclude, children } = props;

  // Determine if `include` prop is defined and if the current country is in the list
  const isIncluded = include?.includes(domainCountry);

  // Determine if `exclude` prop is defined and if the current country is in the list
  const isExcluded = exclude?.includes(domainCountry);

  if ((include && !isIncluded) || (exclude && isExcluded)) {
    return null;
  }
  return <>{children}</>;
}

type IntlRenderDynamicProps = {
  include?: React.ReactNode;
  exclude?: React.ReactNode;
  default?: React.ReactNode;
  conditions?: {
    include?: Country[];
    exclude?: Country[];
  };
} & {
  [key in Country]?: ReactElement | ReactNode;
};

type IntlRenderFCDynamicProps<TProps> = {
  /**
   * Props to be passed to the component
   */
  componentProps: TProps;
  include?: (props: TProps) => React.ReactNode;
  exclude?: (props: TProps) => React.ReactNode;
  default?: (props: TProps) => React.ReactNode;
  conditions?: {
    include?: Country[];
    exclude?: Country[];
  };
} & {
  [key in Country]?: (props: TProps) => ReactElement | ReactNode;
};

// Usage Example

// const countryComponents = {
//   it: <ItalianComponent/>,
//   es: <SpanishComponent/>,
//   ... more components
// };

/*
<IntlRenderDynamic
  {...countryComponents}
  include={<div>FR Include Component</div>}
  default={<div>Default Component</div>}
  conditions={{ include: ["FR"] }}
  />
*/

const renderComponent = <TProps,>(
  Component: ((props: TProps) => ReactNode) | ReactNode,
  props: TProps | undefined
) => {
  if (typeof Component === 'function' && props) return <Component {...props} />;

  return <>{Component}</>;
};

export function IntlRenderDynamic<TProps>(
  props: IntlRenderDynamicProps | IntlRenderFCDynamicProps<TProps>
) {
  const { domainCountry } = useCountry();
  const { conditions, include, exclude, default: defaultComp, ...rest } = props;

  const renderProps =
    // eslint-disable-next-line react/destructuring-assignment
    'componentProps' in props ? props.componentProps : undefined;

  if (conditions?.exclude?.includes(domainCountry)) {
    return renderComponent(exclude, renderProps);
  }

  if (conditions?.include?.includes(domainCountry)) {
    return renderComponent(include, renderProps);
  }

  const CountrySpecificComponent = rest[domainCountry];

  if (CountrySpecificComponent) {
    return renderComponent(CountrySpecificComponent, renderProps);
  }

  return renderComponent(defaultComp, renderProps);
}
