import { useAppointmentUtils } from '@unobravo/patient';
import { useSession } from '../../../shared/hooks/useSession';

export const useAppointment = (uuid: string) => {
  const session = useSession(uuid);
  const { appointmentUrl, appointmentUrlLoading } = useAppointmentUtils(uuid);

  return {
    session,
    appointmentUrl,
    appointmentUrlLoading,
    invoiceUrl: session.invoiceDownloadUrl,
    sessionLoading: session.dataLoading,
    downloadInvoice: session.downloadInvoice
  };
};
