import { Route } from 'react-router-dom';

export function getPatientChangeMailRoutes() {
  return (
    <Route
      path="/change-mail/*"
      lazy={async () => {
        const { PatientChangeEmailProviders } = await import('./index');
        return { Component: PatientChangeEmailProviders };
      }}
    >
      <Route
        path=""
        lazy={async () => {
          const { ChangeEmailLandingPage } = await import('./index');
          return { Component: ChangeEmailLandingPage };
        }}
      />
      <Route
        path="success"
        lazy={async () => {
          const { ChangeEmailSuccess } = await import('./index');
          return { Component: ChangeEmailSuccess };
        }}
      />
      <Route
        path="success/:email"
        lazy={async () => {
          const { ChangeEmailSuccess } = await import('./index');
          return { Component: ChangeEmailSuccess };
        }}
      />
      <Route
        path="error"
        lazy={async () => {
          const { ChangeEmailError } = await import('./index');
          return { Component: ChangeEmailError };
        }}
      />
    </Route>
  );
}
