import { Interval } from 'luxon';
import { useState } from 'react';
import { AutomaticMessage } from './AutomaticMessage';
import { AvailabilityAlert } from './AvailabilityAlert';
import { AvailabilityConfirm } from './AvailabilityConfirm';
import { AvailabilityDate } from './AvailabilityDate';
import { AvailibilityTime } from './AvailibilityTime';
import { AvailabilityType } from './types/AvailabilityType';
import { useChat } from '../chat/hooks/useChat';

type StatusType = 'CLOSE' | 'DATE' | 'TIME' | 'CONFIRM' | 'MESSAGE';

export const PatientAvailability = () => {
  const [availability, setAvailability] = useState<AvailabilityType[]>([]);
  const [status, setStatus] = useState<StatusType>('CLOSE');
  const [choosenDate, setChoosenDate] = useState<Interval | undefined>();
  const { sendNewMessage } = useChat();

  const onClose = () => {
    setChoosenDate(undefined);
    setAvailability([]);
    setStatus('CLOSE');
  };

  return (
    <>
      <AvailabilityAlert onClick={() => setStatus('DATE')} />
      {status === 'DATE' && (
        <AvailabilityDate
          prevDate={choosenDate}
          onClose={onClose}
          onNext={(date) => {
            setChoosenDate(date);
            setStatus('TIME');
          }}
        />
      )}
      {status === 'TIME' && choosenDate && (
        <AvailibilityTime
          date={choosenDate}
          onCancel={() => setStatus('DATE')}
          onClose={onClose}
          onNext={(time) => {
            const item = { date: choosenDate, time };
            setAvailability((prev) =>
              prev.length === 3 ? [prev[0], prev[1], item] : [...prev, item]
            );
            setStatus('CONFIRM');
          }}
        />
      )}
      {status === 'CONFIRM' && (
        <AvailabilityConfirm
          availability={availability}
          onAdd={() => {
            setStatus('DATE');
            setChoosenDate(undefined);
          }}
          onCancel={() => {
            setStatus('TIME');
            setAvailability((list) => list.slice(0, -1));
          }}
          onClose={onClose}
          onNext={() => setStatus('MESSAGE')}
          onDelete={(idx) => {
            if (availability.length === 1) {
              setStatus('DATE');
              setAvailability([]);
            } else {
              setAvailability((list) =>
                list.filter((_, index) => index !== idx)
              );
            }
          }}
        />
      )}
      {status === 'MESSAGE' && (
        <AutomaticMessage
          availability={availability}
          onCancel={() => setStatus('CONFIRM')}
          onClose={onClose}
          onConfirm={async (message) => {
            await sendNewMessage(message);
            onClose();
          }}
        />
      )}
    </>
  );
};
