import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { Theme } from '../../theme';
import { TypographyVariant } from './consts';

export enum LabelSize {
  Label100 = 'Label100',
  Label80 = 'Label80',
  Label40 = 'Label40',
  Label20 = 'Label20'
}

interface IStyledLabel {
  color?: string;
  margin?: string | false;
  variant?: TypographyVariant;
  uppercase?: boolean;
}

const StyledLabel = styled.label<IStyledLabel>`
  display: block;
  cursor: inherit;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ${Theme.fonts.secondary};
  line-height: 100%;
  ${({ color }) => (color ? `color:${color};` : '')}
  margin: ${({ margin }) => margin || '0'};
  font-weight: ${({ variant }) => variant || TypographyVariant.Bold};
  pointer-events: none;
  ${({ uppercase }) => (uppercase ? `text-transform: uppercase;` : '')}
`;

const Label100 = styled(StyledLabel)`
  font-size: 1rem;
  letter-spacing: 0.01rem;
`;

const Label80 = styled(StyledLabel)`
  font-size: 0.875rem;
  letter-spacing: 0.009rem;
`;

const Label40 = styled(StyledLabel)`
  font-size: 0.813rem;
  letter-spacing: 0.04rem;
`;

const Label20 = styled(StyledLabel)`
  font-size: 0.5rem;
  letter-spacing: 0.04rem;
`;

const LabelComponentMap: Record<
  LabelSize,
  StyledComponent<'label', DefaultTheme, IStyledLabel, never>
> = {
  [LabelSize.Label100]: Label100,
  [LabelSize.Label80]: Label80,
  [LabelSize.Label40]: Label40,
  [LabelSize.Label20]: Label20
};

interface ILabelProps {
  size: LabelSize;
  variant?: TypographyVariant;
  children: string;
  htmlFor?: string;
  color?: string;
  margin?: string | boolean;
  uppercase?: boolean;
}

export const Label: React.FC<ILabelProps> = ({
  htmlFor,
  variant,
  size,
  color,
  children,
  uppercase,
  margin
}) => {
  const LabelComponent = LabelComponentMap[size];
  return (
    <LabelComponent
      htmlFor={htmlFor}
      color={color}
      variant={variant}
      uppercase={uppercase}
      margin={margin === true ? '0 0 8px 0' : margin}
    >
      <ReactMarkdown
        disallowedElements={['pre', 'code', 'p']}
        unwrapDisallowed
        linkTarget="_blank"
      >
        {children}
      </ReactMarkdown>
    </LabelComponent>
  );
};
