import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';

const {
  NX_APP_FIREBASE_API_KEY,
  NX_APP_AUTH_DOMAIN,
  NX_APP_PROJECT_ID,
  NX_APP_STORAGE_BUCKET,
  NX_APP_MESSAGING_SENDER_ID,
  NX_APP_APP_ID
} = process.env;

const options = {
  apiKey: NX_APP_FIREBASE_API_KEY,
  authDomain: NX_APP_AUTH_DOMAIN,
  projectId: NX_APP_PROJECT_ID,
  storageBucket: NX_APP_STORAGE_BUCKET,
  messagingSenderId: NX_APP_MESSAGING_SENDER_ID,
  appId: NX_APP_APP_ID
};

export const initializeFirebase = () => {
  // prevent Error Firebase: (auth/already-initialized)

  const app = initializeApp({ ...options });

  initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    popupRedirectResolver: browserPopupRedirectResolver
  });
};
