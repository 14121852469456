import styled from 'styled-components';
import { mediaQueries } from '../../theme';

interface ToastsContainerProps {
  bottomOffset?: number;
}

export const ToastsContainer = styled.div<ToastsContainerProps>`
  display: grid;
  grid-template-column: repeat(1, auto);
  grid-gap: 15px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 102;
  ${mediaQueries('sm')(`
    width: 100%;
    justify-items: center;
    right: 0px;
  `)}
  margin-bottom: ${({ bottomOffset }) => bottomOffset}px;
`;
