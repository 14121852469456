import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { theme } from '@unobravo/zenit-core';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortByDate } from '@unobravo/core';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { usePatient } from '../../patientData/hooks/usePatient';
import { NoInvoices } from './NoInvoices';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { AppointmentBubbleV2 } from './AppointmentBubbleV2/index';

const hasInvoices = (obj: object) => Object.entries(obj).length > 0;

export const Invoices: React.FC = () => {
  const { t } = useTranslation();
  const {
    pastSessions,
    nextSessions,
    loadAppointments,
    hasPreviousPage,
    status
  } = useAgenda();
  const { timezone } = usePatient();

  const groupByMonth = useMemo(() => {
    const result: { [key: string]: IAppointment[] } = {};

    const sessions = [...nextSessions, ...pastSessions].sort(
      (elm, prev) => sortByDate(prev.when, elm.when) // most recent first - TODO: maybe a dedicated query will help to avoid this management
    );

    sessions
      .filter((session) => session.isInvoiceable)
      .forEach((session) => {
        const { month, year } = getSessionDate(
          session.when,
          timezone || undefined
        );
        const monthKey = `${month} ${year}`;

        if (result[monthKey]) {
          result[monthKey].push(session);
        } else {
          result[monthKey] = [session];
        }
      });

    return result;
  }, [pastSessions, nextSessions, timezone]);

  return (
    <Stack direction="column" w="100%" h="100%" pr="md" pl="md">
      {status === 'loading' && pastSessions.length === 0 && <Spinner />}
      {pastSessions.length > 0 && (
        <Stack direction="column" align="center">
          {Object.keys(groupByMonth).map(
            (month) =>
              groupByMonth[month].length > 0 && (
                <Box key={month} mt="2xl" w="100%">
                  <Box mb="xs" style={{ textTransform: 'capitalize' }}>
                    <Text variant="sm" color="grey.300" textAlign="center">
                      {month}
                    </Text>
                  </Box>
                  {groupByMonth[month].map((session) => (
                    <Box mb="xs" key={session.id}>
                      <AppointmentBubbleV2
                        session={session}
                        key={session.id}
                        handleInvoice
                        handleCreditNote
                      />
                    </Box>
                  ))}
                </Box>
              )
          )}

          {hasPreviousPage && hasInvoices(groupByMonth) && (
            <Box
              onClick={loadAppointments}
              mt="xs"
              data-testid="invoices-more-appointments-button"
              style={{
                cursor: 'pointer',
                color: theme.colors.candy[500]
              }}
            >
              <Text color="candy.500" variant="sm" fontWeight="semibold">
                {t('invoices.loadMoreAppointments')}
              </Text>
            </Box>
          )}
        </Stack>
      )}
      {status === 'done' && !hasInvoices(groupByMonth) && <NoInvoices />}
    </Stack>
  );
};
