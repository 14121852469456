import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const CarouselIconLeft: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.candy[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.4577"
        cy="12"
        r="9.00375"
        transform="rotate(-180 12.4577 12)"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7078 9.5L11.2078 12L13.7078 14.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
