import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import { Button } from '@unobravo-monorepo/common/components/Button';
import { Eye } from '@unobravo-monorepo/common/components/Icons/Eye';
import { EyeClosed } from '@unobravo-monorepo/common/components/Icons/EyeClosed';
import { PageContainer } from '@unobravo-monorepo/common/components/PageContainer';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { providers } from '@unobravo-monorepo/common/utils/social';
import emailChangeImg from '@unobravo-monorepo/patient/assets/email-change.svg';
import { useInternationalVariables } from '@unobravo/translations';
import {
  Alert,
  RBox,
  useBreakpointValue,
  Button as ZenitButton
} from '@unobravo/zenit-web';
import { FirebaseError } from 'firebase/app';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../patientData/hooks/usePatient';
import { ChangeEmailModal } from '../components/ChangeEmailModal';
import { RightWrapperIllustration } from '../components/RightWrapperIllustration';
import { SectionHeader } from '../components/SectionHeader';
import { useRecoverPassword } from '../hooks/useRecoverPassword';

const ButtonContainer = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;
  ${mediaQueries('sm')(`
    justify-content: center;
  `)}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-top: 24px;
  max-width: 480px;
  ${mediaQueries('sm')(`
    justify-content: space-between;
  `)}
`;

const Wrapper = styled.div`
  background-color: ${Theme.specialColors.white};
  border-radius: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  z-index: 2;
  ${mediaQueries('sm')(`
    padding-bottom: 0;
  `)}
`;

const InputRow = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 5fr 1fr;

  ${mediaQueries('sm')(`
    grid-template-columns:1fr 0.001fr;
  `)}
`;

const EyeButtonContainer = styled.div`
  cursor: pointer;
`;

const SocialPasswordVariant = () => {
  const { t } = useTranslation();
  const { currentUser } = useFirebaseUser();
  const { email } = currentUser || {};

  const { isMobile } = useBreakpointValue();

  const { supportUrl } = useInternationalVariables();
  const { recoverPassword, loading } = useRecoverPassword();

  const onSubmit = async () => {
    if (!email) return;

    await recoverPassword(email);
  };

  return (
    <PageContainer>
      <SectionHeader
        path="../"
        title={`${t('profileAndSettings.changeEmailLabel')}`}
      />
      <Container>
        <Wrapper>
          <Body size={BodySize.Body70}>
            {t('profileAndSettings.socialChangeEmail.description', {
              link: supportUrl
            })}
          </Body>
          <RBox
            w={{ base: undefined, md: 400 }}
            mt={{ base: '2xl', md: 'xl' }}
            mb={{ base: 'sm', md: 'xs' }}
          >
            <ZenitButton
              label={t('profileAndSettings.socialChangeEmail.button')}
              size="lg"
              fullWidth
              onClick={onSubmit}
              disabled={loading}
              data-testid="request-password-generation"
            />
          </RBox>
          <Body size={BodySize.Body70} color={Theme.colors.gray['600']}>
            {t('profileAndSettings.socialChangeEmail.subtitle', {
              email,
              breakOrSpace: isMobile ? ' ' : '\n'
            })}
          </Body>

          <RBox
            position={{ base: 'absolute', md: 'relative' }}
            bottom={0}
            left={{ base: 0, md: undefined }}
            mb={{ base: 'lg', md: undefined }}
            mt={{ base: undefined, md: 'xl' }}
            mx={{ base: 'xl', md: undefined }}
            maxW={{ base: undefined, md: 400 }}
          >
            <Alert
              type="informative"
              title={t('profileAndSettings.socialChangeEmail.infoTitle')}
              message={t('profileAndSettings.socialChangeEmail.infoMessage')}
            />
          </RBox>
        </Wrapper>
      </Container>
    </PageContainer>
  );
};

export const ChangeEmail: React.FC = () => {
  const { t } = useTranslation();
  const { email, changePatientEmail } = usePatient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const { reauthenticateUser } = useFirebaseUser();
  const { sendErrorMessage } = useErrorHandler();
  const [inputPsw, setInputPsw] = useState({
    type: 'password' as 'password' | 'text',
    icon: EyeClosed
  });
  const { zendeskDefaultUrl } = useInternationalVariables();

  const { hasEmailProvider } = providers();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors, isSubmitting },
    reset
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newEmail: '',
      password: ''
    }
  });

  const onSubmit = async (data: { newEmail: string; password: string }) => {
    const { password, newEmail: updatedEmail } = data;
    try {
      await reauthenticateUser(password, email || undefined);
      await changePatientEmail(updatedEmail);
      setNewEmail(updatedEmail);
      setIsModalOpen(true);
      reset();
    } catch (e) {
      if (email === updatedEmail) {
        return sendErrorMessage(
          t(`changeEmail.sameEmail`),
          t(`changeEmail.emailErrorTitle`)
        );
      }

      if (e instanceof FirebaseError && e.code === 'auth/wrong-password') {
        return sendErrorMessage(
          t(`changeEmail.wrongPsw`),
          t(`changeEmail.emailErrorTitle`)
        );
      }

      sendErrorMessage(t(`common:errorPage.retry`), t(`common:errorPage.ops2`));
    }
  };

  const pswVisibilityToggle = () => {
    if (inputPsw.type === 'password') {
      setInputPsw({ type: 'text', icon: Eye });
    } else {
      setInputPsw({ type: 'password', icon: EyeClosed });
    }
  };

  if (!hasEmailProvider) return <SocialPasswordVariant />;

  return (
    <PageContainer>
      <SectionHeader
        path="../"
        title={`${t('profileAndSettings.changeEmailLabel')}`}
      />
      <Container>
        <Wrapper>
          <InputRow>
            <Heading size={HeadingSize.Title80} margin="4px 0px">
              {t('changeEmail.title')}
            </Heading>
          </InputRow>
          <InputRow>
            <Body size={BodySize.Body70}>
              {t('changeEmail.description', { zendeskDefaultUrl })}
            </Body>
          </InputRow>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Body
                size={BodySize.Body80}
                variant={TypographyVariant.SemiBold}
                margin="0px 0 4px 0"
              >
                {t('changeEmail.oldEmail')}
              </Body>
              {email && (
                <Body size={BodySize.Body80} margin="12px 0 0 0">
                  {email}
                </Body>
              )}
              <Body
                size={BodySize.Body80}
                variant={TypographyVariant.SemiBold}
                margin="20px 0 4px 0"
              >
                {t('changeEmail.newEmail')}
              </Body>
              <TextInput
                data-testid="new-email"
                {...register('newEmail', {
                  required: `${t('common:requiredField')}`,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('changeEmail.invalidEmail')
                  }
                })}
                errorMessage={errors?.newEmail?.message}
              />
              <Body
                size={BodySize.Body80}
                variant={TypographyVariant.SemiBold}
                margin="20px 0 4px 0"
              >
                {t('changeEmail.passwordCheck')}
              </Body>
              <TextInput
                data-testid="password"
                type={inputPsw.type}
                {...register('password', {
                  required: `${t('common:requiredField')}`
                })}
                errorMessage={errors?.password?.message}
                icon={
                  <EyeButtonContainer onClick={pswVisibilityToggle}>
                    <inputPsw.icon />
                  </EyeButtonContainer>
                }
              />
            </div>
            <ButtonContainer>
              <Button
                label={t('changeEmail.save')}
                variant="primary"
                size="small"
                fullWidth={false}
                type="submit"
                disabled={!isValid || isSubmitting}
                data-testid="change-email-button"
              />
            </ButtonContainer>
          </Form>
        </Wrapper>
        <RightWrapperIllustration illustration={emailChangeImg} />
      </Container>
      <ChangeEmailModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newEmail={newEmail}
      />
    </PageContainer>
  );
};
