import React, { useState } from 'react';
import { CreditNote, Invoice } from '@unobravo/zenit-icons';
import { Box, Heading, ModalAlert, Stack, Text } from '@unobravo/zenit-web';
import { downloadPdfFile } from '@unobravo-monorepo/common/utils/fileUtils';
import { useTranslation } from 'react-i18next';
import { SpinnerIcon } from '@unobravo-monorepo/common/components/SpinnerIcon';
import { Color, DefaultSizes } from '@unobravo/zenit-core';
import { useErrorHandler } from '../../hooks/useErrorHandler';

interface IDownloadStack {
  isLoading: boolean;
  noBorder?: boolean;
  onClick: () => void;
  iconComponent: React.ReactElement;
  labelText: string;
  isLoadingColor?: Color;
  iconColor?: Color;
  mt?: DefaultSizes;
}

const DownloadStack = ({
  isLoading,
  onClick,
  iconComponent,
  labelText,
  isLoadingColor = 'grey.400',
  iconColor = 'candy.500',
  mt = 'md',
  noBorder
}: IDownloadStack) => (
  <Stack
    style={{ cursor: isLoading ? 'default' : 'pointer' }}
    direction="row"
    align="center"
    pb="md"
    mt={mt}
    borderBottom={noBorder ? undefined : 'sm'}
    onClick={onClick}
  >
    <Stack mr="xs" justify="center">
      {isLoading ? <SpinnerIcon size="md" color={iconColor} /> : iconComponent}
    </Stack>
    <Text variant="lg" color={isLoading ? isLoadingColor : 'black'}>
      {labelText}
    </Text>
  </Stack>
);

interface IModalDownloadInvoice {
  filename: string;
  token: string;
  invoiceDownloadUrl?: string;
  openModal: string;
  onClose: () => void;
  creditNoteDownloadUrl?: string;
  label?: string;
}

export const ModalDownloadInvoice = ({
  filename,
  token,
  invoiceDownloadUrl,
  creditNoteDownloadUrl,
  openModal,
  onClose,
  label
}: IModalDownloadInvoice) => {
  const [isLoadingCreditNote, setIsLoadingCreditNote] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const { t } = useTranslation();
  const { sendGenericError } = useErrorHandler();

  const downloadFile = async (
    isLoading: boolean,
    isLoadingState: React.Dispatch<boolean>,
    url?: string
  ) => {
    if (!url || isLoading) return;
    try {
      isLoadingState(true);
      await downloadPdfFile(url, filename, token);
    } catch {
      sendGenericError();
    }
    isLoadingState(false);
  };

  return (
    <ModalAlert open={!!openModal} onClose={onClose}>
      <>
        <Box pb="2xs">
          <Heading
            color="candy.500"
            variant="2xs"
            style={{ letterSpacing: 1.56 }}
          >
            {label}
          </Heading>
        </Box>

        <Heading fontWeight="bold" variant="md">
          {t('agenda.downloadTitle')}
        </Heading>
        <DownloadStack
          isLoading={isLoadingInvoice}
          onClick={() =>
            downloadFile(
              isLoadingInvoice,
              setIsLoadingInvoice,
              invoiceDownloadUrl
            )
          }
          iconComponent={<Invoice color="candy.500" />}
          labelText={t('chat.appointmentProposalAction.invoice')}
          mt="2xl"
        />
        <DownloadStack
          isLoading={isLoadingCreditNote}
          onClick={() =>
            downloadFile(
              isLoadingCreditNote,
              setIsLoadingCreditNote,
              creditNoteDownloadUrl
            )
          }
          iconComponent={<CreditNote color="candy.500" />}
          labelText={t('chat.appointmentProposalAction.creditNote')}
          isLoadingColor="grey.400"
          mt="md"
          noBorder
        />
      </>
    </ModalAlert>
  );
};
