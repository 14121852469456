import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import { ITranslatedSessionDate } from '@unobravo/core';
import { SessionType, useGTMUtils, usePaymentUtils } from '@unobravo/patient';
import { DateTime } from 'luxon';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useOnboardingStatus } from '../../home/hooks/useOnboardingStatus';
import { usePatient } from '../../patientData/hooks/usePatient';

export const usePayFreeSession = () => {
  const { payFreeSessionLoading, payPatientFreeSessionV2 } = usePaymentUtils();
  const { refetchStatus } = useOnboardingStatus();
  const { sendGenericError } = useErrorHandler();
  const { pushAuthenticatedEvent } = useGTM();
  const { GTMData } = useGTMUtils();
  const { doctor, id, uuid } = usePatient();

  const payFreeSession = async (
    sessionId: string,
    sessionDate?: ITranslatedSessionDate & { rawDate?: DateTime },
    type?: SessionType
  ) => {
    const result = await payPatientFreeSessionV2(sessionId);
    if (!result?.success) {
      sendGenericError();
      return false;
    }
    const ISODate = sessionDate?.rawDate?.toISO();
    await refetchStatus();
    pushAuthenticatedEvent('FreeSessionBooked', {
      tp_id: doctor?.id,
      tp_first_name: doctor?.name,
      tp_last_name: doctor?.surname,
      session_date: ISODate,
      session_type: type,
      user_id: id,
      email: GTMData?.email,
      uuid
    });
    return result;
  };

  return {
    payFreeSession,
    loading: payFreeSessionLoading
  };
};
