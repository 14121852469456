import { ITherapySetting } from '../types/ITherapySetting';

export const frequencyMap: Record<ITherapySetting, string> = {
  notSet: 'common:frequencyLabels.notSet',
  weekly: 'common:frequencyLabels.weekly',
  everyTwoWeeks: 'common:frequencyLabels.everyTwoWeeks',
  everyThreeWeeks: 'common:frequencyLabels.everyThreeWeeks',
  everyFourWeeks: 'common:frequencyLabels.everyFourWeeks',
  monthly: 'common:frequencyLabels.everyFourWeeks',
  everyFiveWeeks: 'common:frequencyLabels.everyFiveWeeks'
};

export const therapySettings = {
  notSet: [1, 'week'],
  weekly: [1, 'week'],
  everyTwoWeeks: [2, 'week'],
  everyThreeWeeks: [3, 'week'],
  everyFourWeeks: [4, 'week'],
  monthly: [4, 'week'],
  everyFiveWeeks: [5, 'week']
};

export const therapySettingDropdownValues: ITherapySetting[] = [
  'notSet',
  'weekly',
  'everyTwoWeeks',
  'everyThreeWeeks',
  'monthly',
  'everyFiveWeeks'
];
