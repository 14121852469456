import { Alert, Box, Button, RStack, Stack, Text } from '@unobravo/zenit-web';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Party } from '@unobravo/zenit-icons';
import { AppointmentCardSkeleton } from '../../../shared/components/TherapySetting/AppointmentBubble/AppointmentCardSkeleton';
import { useSessionToPay } from '../../paySession/hooks/useSessionToPay';
import { AppointmentCard } from '../../therapySetting/components/AppointmentCard';
import { CardContainer } from '../../therapySetting/components/CardContainer';
import { useBundle } from '../hooks/useBundle';

const IconContainer = styled(Stack)`
  width: 64px;
  height: 64px;
`;

export const BundleThankYou = ({
  isMobileApp = false
}: {
  isMobileApp?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sessionToPay, isLoading } = useSessionToPay();
  const { latestBundle } = useBundle();

  return (
    <RStack
      direction="column"
      align="center"
      mt={{ base: undefined, md: 'xl' }}
    >
      <RStack
        direction="column"
        p="md"
        spacing="xl"
        maxW={{ base: undefined, md: '50%' }}
      >
        <RStack
          direction="column"
          spacing="sm"
          align={{ base: undefined, md: 'center' }}
        >
          <IconContainer
            rounded="full"
            bgColor="spinach.50"
            align="center"
            justify="center"
          >
            <Party size="lg" color="spinach.500" />
          </IconContainer>
          <Text variant="2xl" fontWeight="bold" color="grey.800">
            {t('bundles.thankYou.title', {
              numSessions: latestBundle?.numSessions
            })}
          </Text>
          {sessionToPay && (
            <Text color="grey.800">{t('bundles.thankYou.reminder')}</Text>
          )}
        </RStack>
        <Stack
          direction="column"
          rounded="md"
          bgColor={sessionToPay || isLoading ? 'cappuccino.100' : undefined}
          p="md"
          w="100%"
        >
          <>
            {sessionToPay ? (
              <>
                <Text color="grey.600" variant="sm">
                  {t('bundles.thankYou.appointmentCard.newProposal')}
                </Text>
                <AppointmentCard appointment={sessionToPay} />
                <Box pt="sm">
                  <Text color="grey.600" variant="sm">
                    {t('therapySetting.hpCard.payWithBundleNote')}
                  </Text>
                </Box>
              </>
            ) : (
              <>
                {isLoading && (
                  <CardContainer testId="appointment-card-skeleton">
                    <AppointmentCardSkeleton />
                  </CardContainer>
                )}
              </>
            )}
          </>
        </Stack>
        <Alert message={t('bundles.thankYou.alert')} type="informative" />
        {!isMobileApp && (
          <Button
            variant="ghost"
            size="lg"
            label={t('bundles.thankYou.goToHomeCTA')}
            onClick={() => navigate('../home')}
          />
        )}
      </RStack>
    </RStack>
  );
};
