import { usePatient } from '../../features/patientData/hooks/usePatient';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';

export const usePatientGTM = () => {
  const { status } = usePatient();
  const { pushAuthenticatedEvent: pushAuthenticatedEventGTM } = useGTM();
  const pushAuthenticatedEvent = (
    event: string,
    eventProps?: Record<string, unknown>
  ) => {
    pushAuthenticatedEventGTM(event, {
      ...eventProps,
      patient_status: status
    });
  };

  return {
    pushAuthenticatedEvent
  };
};
