import { Box, Button, Text } from '@unobravo/zenit-web';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@unobravo-monorepo/common/components/Divider';
import { useCountry } from '@unobravo/translations';
import { useTranslation } from 'react-i18next';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { CardContainer as BubbleContainer } from './CardContainer';
import { usePatient } from '../../patientData/hooks/usePatient';
import { DiaryModal } from '../../../shared/components/TherapySetting/Modals/DiaryModal';

export type AgendaCardVariants = 'DIARY' | 'ASK_FOR';

const DiaryCard = ({
  setOpenDiaryModal
}: {
  setOpenDiaryModal: (val: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box py="md">
      <Button
        onClick={() => {
          setOpenDiaryModal(true);
        }}
        fullWidth
        label={t('therapySetting.diaryCardCTA')}
        variant="outlined"
        colorVariant="accent_berry"
        data-testid="tp-setting-card-bubble-appointment-diary-cta"
      />
    </Box>
  );
};

const AppointmentToBookCard = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text variant="md">{t('therapySetting.appointmentToBookCard')}</Text>
      <Divider type="horizontal" />
      <Button
        onClick={onClick}
        fullWidth
        label={t('therapySetting.appointmentToBookCardCTA')}
        variant="outlined"
        data-testid="tp-setting-card-bubble-appointment-to-book-cta"
      />
    </>
  );
};

const postConversionStatuses = ['TERMINATED', 'ONGOING'];

/** Empty week variants! */
export const NoAppointmentCardBubble: React.FC<{
  isRestWeek?: boolean;
}> = ({ isRestWeek }) => {
  const [openDiaryModal, setOpenDiaryModal] = useState(false);
  const { id: patientId, status } = usePatient();
  const navigate = useNavigate();
  const { domainCountry } = useCountry();

  if (
    isRestWeek &&
    (domainCountry !== 'it' ||
      !status ||
      !postConversionStatuses.includes(status))
  ) {
    return null;
  }

  const variant: AgendaCardVariants = isRestWeek ? 'DIARY' : 'ASK_FOR';
  /**
   * onClick on Appointment Card
   */
  const onAppointmentToBookClicked = () => {
    pendoTrack('appointment_to_book_bubble', {
      patientId
    });
    navigate('../chat');
  };

  return (
    <BubbleContainer testId="no-appointment-card-bubble-container">
      {(() => {
        switch (variant) {
          case 'DIARY':
            return <DiaryCard setOpenDiaryModal={setOpenDiaryModal} />;
          case 'ASK_FOR':
            return (
              <AppointmentToBookCard onClick={onAppointmentToBookClicked} />
            );
          default:
            return null;
        }
      })()}
      <DiaryModal
        openDiaryModal={openDiaryModal}
        setOpenDiaryModal={setOpenDiaryModal}
      />
    </BubbleContainer>
  );
};
