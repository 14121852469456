import { createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';

export interface IHomeState {
  showDoctorInfoModal: boolean;
  isFeedbackModalOpen: boolean;
}

const initialState: IHomeState = {
  showDoctorInfoModal: false,
  isFeedbackModalOpen: false
};

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setShowDoctorInfoModal: (state, action) => {
      state.showDoctorInfoModal = action.payload;
    },
    openFeedbackModal: (state) => {
      state.isFeedbackModalOpen = true;
    },
    closeFeedbackModal: (state) => {
      state.isFeedbackModalOpen = false;
    }
  }
});

export default HomeSlice.reducer;

export const { setShowDoctorInfoModal, openFeedbackModal, closeFeedbackModal } =
  HomeSlice.actions;

export const homeSelector = (state: PatientRootState): IHomeState => state.home;
