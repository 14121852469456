import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useReassignment } from '../hooks/useReassignment';

const WELCOME_URL = '/signup/survey/welcome';
const FALLBACK_URL = 'already-used';

type ResetSurveyStatus = 'not_needed' | 'loading' | 'loaded' | 'error';

export function Reassignment() {
  const { reassignConditions, id } = usePatient();
  const { resetSurvey } = useReassignment();
  const [resetSurveyStatus, setResetSurveyStatus] =
    useState<ResetSurveyStatus>('loading');

  const reset = async () => {
    try {
      await resetSurvey({ variables: { patientId: id! } });
      setResetSurveyStatus('loaded');
    } catch {
      setResetSurveyStatus('error');
    }
  };

  useEffect(() => {
    if (
      reassignConditions?.withNewSurvey ||
      reassignConditions?.canPerformSmoothReassign
    ) {
      reset();
    } else if (
      reassignConditions?.withNewSurvey === false &&
      reassignConditions?.canPerformSmoothReassign === false
    ) {
      setResetSurveyStatus('not_needed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reassignConditions?.withNewSurvey,
    reassignConditions?.canPerformSmoothReassign
  ]);

  switch (resetSurveyStatus) {
    case 'loading':
      return <Spinner />;
    case 'not_needed':
    case 'error':
      return <Navigate to={FALLBACK_URL} replace />;
    case 'loaded':
      return <Navigate to={WELCOME_URL} replace />;
    default:
      return <></>;
  }
}
