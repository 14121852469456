import styled from 'styled-components';
import { Stack } from '@unobravo/zenit-web';
import { mobileFirstMedia, Theme } from '../..';

const pageHeaderHeight = 53;
const pageFooterHeight = 59;

export const PageContainer = styled.div<{ borderColor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ borderColor = Theme.colors.cappuccino[200] }) =>
    mobileFirstMedia('md')(`
			overflow: auto;
			border: 1px solid ${borderColor};
			border-radius: 16px;
		`)}
`;

export const PageContainerHeader = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  background-color: ${({ backgroundColor = Theme.colors.cappuccino[50] }) =>
    backgroundColor};
  border-bottom: 1px solid
    ${({ borderColor = Theme.colors.cappuccino[200] }) => borderColor};
  height: ${pageHeaderHeight}px;
  flex-shrink: 0;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0 10px;
  border-radius: 16px 16px 0px 0px;
  display: none;
  z-index: 3;
  ${mobileFirstMedia('md')(`
    display: flex;
    align-items: center;
  `)}
`;

const PageContainerFooterWrapper = styled(Stack)`
  flex-shrink: 0;
  z-index: 3;
`;

export const PageContainerFooter = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <PageContainerFooterWrapper
      bgColor="cappuccino.50"
      borderTop="sm"
      borderColor="cappuccino.200"
      h={`${pageFooterHeight}px`}
      w="100%"
      position="sticky"
      bottom={0}
      px="xl"
      roundedBottom="md"
      direction="row"
      align="center"
    >
      {children}
    </PageContainerFooterWrapper>
  );
};

export const PageContainerBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  ${mobileFirstMedia('md')(`
     padding: 20px;
  `)}
`;
