import { Box, Skeleton, Stack, useBreakpointValue } from '@unobravo/zenit-web';

const Divider = () => (
  <Box
    borderColor="grey.100"
    borderTop="md"
    w="100%"
    style={{ alignSelf: 'center' }}
  />
);

export const RecapSkeleton = () => {
  const { isMobile } = useBreakpointValue();
  const skeletonColor = 'grey.100';
  return (
    <Stack direction="column" px="xl" grow spacing="2xs">
      <Skeleton bgColor={skeletonColor} h={25} w={200} rounded="2xs" mt="md" />
      <Skeleton bgColor={skeletonColor} h={100} grow rounded="md" my="lg" />
      <Stack align="center">
        <Box grow>
          <Skeleton bgColor={skeletonColor} h={19} w={200} rounded="2xs" />
        </Box>
        <Skeleton bgColor={skeletonColor} h={16} w={16} rounded="2xs" />
      </Stack>
      <Skeleton bgColor={skeletonColor} h={20} grow rounded="2xs" mb="xs" />
      <Skeleton bgColor={skeletonColor} h={20} grow rounded="2xs" />
      <Box my="md">
        <Divider />
      </Box>
      <Stack align="center" mb="xs">
        <Box grow>
          <Skeleton bgColor={skeletonColor} h={19} w={200} rounded="2xs" />
        </Box>
        <Skeleton bgColor={skeletonColor} h={16} w={16} rounded="2xs" />
      </Stack>
      <Stack>
        <Skeleton
          bgColor={skeletonColor}
          h={40}
          w={40}
          rounded="full"
          mr="xs"
        />
        <Skeleton bgColor={skeletonColor} h={40} w={200} rounded="2xs" />
      </Stack>
      <Box my="md">
        <Divider />
      </Box>
      <Skeleton bgColor={skeletonColor} h={19} w={200} rounded="2xs" mb="xs" />
      <Skeleton bgColor={skeletonColor} rounded="md" h={52} grow />
      <Stack direction={isMobile ? 'column' : 'row'} justify="end">
        <Skeleton
          bgColor={skeletonColor}
          rounded="xl"
          h={48}
          my="md"
          minW={152}
        />
      </Stack>
    </Stack>
  );
};
