import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Calendar: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.candy[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6316 3V6.63158"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36841 3V6.63158"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82898 9.35522H20.1711"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3553 4.8158H5.64477C5.16319 4.8158 4.70134 5.0071 4.36081 5.34763C4.02029 5.68815 3.82898 6.15001 3.82898 6.63159V18.4342C3.82898 18.9158 4.02029 19.3776 4.36081 19.7182C4.70134 20.0587 5.16319 20.25 5.64477 20.25H18.3553C18.8369 20.25 19.2987 20.0587 19.6393 19.7182C19.9798 19.3776 20.1711 18.9158 20.1711 18.4342V6.63159C20.1711 6.15001 19.9798 5.68815 19.6393 5.34763C19.2987 5.0071 18.8369 4.8158 18.3553 4.8158V4.8158Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
