import {
  StorageKeys,
  createSafeStorage
} from '@unobravo-monorepo/common/utils/storage';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { usePatient } from '../../patientData/hooks/usePatient';
import {
  loadLastAchievementSnapshot,
  vouchersSelector
} from '../vouchers.slice';
import { useListAchievements } from './useListAchievements';

const [getSnapshot, setSnapshot] = createSafeStorage(
  StorageKeys.patientVouchersSnapshot
);

type UseNewAchievementsResult = {
  hasNewAchievements: boolean;
  setAchievementsRead: () => void;
};

export const useNewAchievements = (): UseNewAchievementsResult => {
  const { ptVouchers } = usePatientFeatureFlags();
  const { lastAchievementSnapshot } = useSelector(vouchersSelector);
  const dispatch = useDispatch();
  const { id } = usePatient();

  const listAchievements = useListAchievements();

  useEffect(() => {
    if (!ptVouchers || !!lastAchievementSnapshot) return;

    const lsSnapshot = getSnapshot();

    dispatch(loadLastAchievementSnapshot(lsSnapshot));
  }, [dispatch, lastAchievementSnapshot, ptVouchers]);

  const setAchievementsRead = useCallback(() => {
    if (!ptVouchers || !lastAchievementSnapshot || !id) return;

    const currentAchievements = listAchievements
      .filter(({ achieved }) => achieved)
      .map(({ tag }) => tag);
    const newLastAchievementSnapshot = {
      ...lastAchievementSnapshot,
      [id]: currentAchievements
    };

    setSnapshot(newLastAchievementSnapshot);

    dispatch(loadLastAchievementSnapshot(newLastAchievementSnapshot));
  }, [dispatch, id, lastAchievementSnapshot, listAchievements, ptVouchers]);

  if (!ptVouchers || !lastAchievementSnapshot || !id) {
    return { hasNewAchievements: false, setAchievementsRead };
  }

  const ptSnapshot = lastAchievementSnapshot[id];
  const hasNewAchievements = listAchievements.some(
    (achievement) =>
      achievement.achieved && !ptSnapshot?.includes(achievement.tag)
  );

  return { hasNewAchievements, setAchievementsRead };
};
