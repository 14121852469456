import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { StyledBody } from '../Typography';

interface TransWrapperProps {
  Body: typeof StyledBody;
  children?: React.ReactNode;
}

const TransWrapper = ({ Body, children }: TransWrapperProps) => {
  const StyledTransWrapper = styled(Body)`
    > button {
      color: ${({ theme }) => theme.colors.candy[500]};
      cursor: pointer;
      text-decoration: none;
      background-color: transparent;
      border: transparent;
      font-size: inherit;
      font-family: inherit;
    }
  `;

  return <StyledTransWrapper>{children}</StyledTransWrapper>;
};

type ITrans = {
  i18nkey: string;
  value?: string;
  onClick?: () => Promise<void> | void;
  styledBody: typeof StyledBody;
};

export const TransComponent = ({
  i18nkey,
  value,
  onClick,
  styledBody
}: ITrans) => {
  return (
    <TransWrapper Body={styledBody}>
      <Trans
        i18nKey={i18nkey}
        values={{ name: value }}
        // We disable the eslint rule because the button's content will be auto populated with the prop "value"
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        components={value ? [<button type="button" onClick={onClick} />] : []}
      />
    </TransWrapper>
  );
};
