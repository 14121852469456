import React from 'react';
import styled from 'styled-components';
import { Button, Stack } from '@unobravo/zenit-web';
import { Color, useColor } from '@unobravo/zenit-core';

type Variant =
  | 'default'
  | 'dark'
  | Parameters<typeof Button>[0]['colorVariant'];

interface ICounter {
  number: number;
  limit: number;
  variant?: Variant;
}

interface IStyledStep {
  isActiveStep: boolean;
  variant?: string;
  bgColor?: string;
}

const Step = styled.div<IStyledStep>`
  height: 8px;
  margin: 2px;
  border-radius: ${({ isActiveStep }) => (isActiveStep ? '32px' : '16px')};
  opacity: ${({ isActiveStep }) => (isActiveStep ? 1 : 0.5)};
  width: ${({ isActiveStep }) => (isActiveStep ? '16px' : '8px')};
  transition: width 0.7s;
`;

const StepVariants: Record<string, Color> = {
  default: 'white',
  primary: 'candy.500',
  dark: 'grey.500',
  accent: 'spinach.500',
  warning: 'ginger.500',
  accent_berry: 'berry.500'
};
const StepVariant = styled(Step)`
  background-color: ${({ bgColor }) => bgColor};
`;

export const Counter = ({ variant = 'default', number, limit }: ICounter) => {
  const steps = Array.from(Array(limit).keys());
  const bgColor = useColor(StepVariants[variant]);

  return (
    <Stack justify="center" align="center">
      {steps.map((_, idx) => (
        <StepVariant
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          bgColor={bgColor}
          isActiveStep={idx === number}
          variant={variant}
        />
      ))}
    </Stack>
  );
};
