import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Doc: React.FC<IIcon> = ({
  width = 22,
  height = 22,
  color = Theme.colors.moka[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58333 20.25H18.4167C18.9029 20.25 19.3692 20.0568 19.713 19.713C20.0568 19.3692 20.25 18.9029 20.25 18.4167V5.58333C20.25 5.0971 20.0568 4.63079 19.713 4.28697C19.3692 3.94315 18.9029 3.75 18.4167 3.75H9.25C8.76377 3.75 8.29745 3.94315 7.95364 4.28697C7.60982 4.63079 7.41667 5.0971 7.41667 5.58333V18.4167C7.41667 18.9029 7.22351 19.3692 6.8797 19.713C6.53588 20.0568 6.06956 20.25 5.58333 20.25ZM5.58333 20.25C5.0971 20.25 4.63079 20.0568 4.28697 19.713C3.94315 19.3692 3.75 18.9029 3.75 18.4167V8.33333C3.75 7.8471 3.94315 7.38079 4.28697 7.03697C4.63079 6.69315 5.0971 6.5 5.58333 6.5H7.41667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 7.41669H17.0417"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 11.0833H17.0417"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 14.75H17.0417"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
