import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { useCountry } from '@unobravo/translations';
import { Color } from '@unobravo/zenit-core';
import {
  Box,
  Link,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../../../../features/patientData/hooks/usePatient';
import { getAppointmentLastProposalStatus } from '../utils';
import { PendingModifyBadge } from './PendingModifyBadge';

const AppointmentDateHour = ({
  textColor,
  appointmentDate,
  hourStart,
  hourEnd,
  dotSeparator,
  dotColor
}: {
  textColor: Color;
  appointmentDate: string;
  hourStart: string;
  hourEnd: string;
  dotSeparator?: boolean;
  dotColor?: Color;
}) => (
  <>
    <Text
      variant="md"
      fontWeight="semibold"
      style={{
        textTransform: 'capitalize'
      }}
      color={textColor}
    >
      {appointmentDate}
    </Text>
    {dotSeparator && (
      <Text variant="md" color={dotColor || 'black'}>
        •
      </Text>
    )}
    <Text variant="md" color={textColor}>
      {hourStart}
      {' - '}
      {hourEnd}
    </Text>
  </>
);

const ModifyCTA = ({
  onModify,
  label,
  showPendingModifyIcon = false
}: {
  onModify: () => void;
  label: string;
  showPendingModifyIcon?: boolean;
}) => (
  <Box position="relative">
    <Link
      color="candy.500"
      underline={false}
      to={onModify}
      as="div"
      data-testid="tp-setting-card-bubble-modify-appointment-cta"
    >
      <Box>
        <Text variant="md" fontWeight="semibold">
          {label}
        </Text>
      </Box>
    </Link>
    {showPendingModifyIcon && <PendingModifyBadge onClick={onModify} />}
  </Box>
);

export const AppointmentCardBubbleHeader = ({
  appointment,
  canModify,
  textColor,
  dotColor,
  onModify
}: {
  appointment: IAppointment;
  onModify: () => void;
  canModify: boolean;
  textColor: Color;
  dotColor: Color;
}) => {
  const { timezone } = usePatient();
  const { t } = useTranslation();
  const { index } = useBreakpointValue();
  const { domainCountry: country } = useCountry();

  const isXSDevice = index < 2;
  const modifyCTALabel = t('therapySetting.modifyAppointmentCTA');
  const showPendingModifyIcon =
    getAppointmentLastProposalStatus(appointment) === 'submitted';

  const when = DateTime.fromISO(appointment?.when).setZone(timezone ?? 'local');

  const appointmentDate = when.toLocaleString(
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    { locale: country }
  );

  const hourStart = when.toLocaleString({
    hour: 'numeric',
    minute: 'numeric'
  });

  const hourEnd = when.plus({ minutes: 50 }).toLocaleString({
    hour: 'numeric',
    minute: 'numeric'
  });

  return (
    <>
      {canModify && isXSDevice ? (
        <Stack direction="row" align="center">
          <Stack direction="column" style={{ flexGrow: 1 }}>
            <AppointmentDateHour
              appointmentDate={appointmentDate}
              textColor={textColor}
              hourStart={hourStart}
              hourEnd={hourEnd}
            />
          </Stack>
          <ModifyCTA
            onModify={onModify}
            label={modifyCTALabel}
            showPendingModifyIcon={showPendingModifyIcon}
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing="xs" align="center">
          <AppointmentDateHour
            appointmentDate={appointmentDate}
            textColor={textColor}
            hourStart={hourStart}
            hourEnd={hourEnd}
            dotSeparator
            dotColor={dotColor}
          />
          {canModify && (
            <>
              <Text variant="md" color={dotColor}>
                •
              </Text>
              <ModifyCTA
                onModify={onModify}
                label={modifyCTALabel}
                showPendingModifyIcon={showPendingModifyIcon}
              />
            </>
          )}
        </Stack>
      )}
    </>
  );
};
