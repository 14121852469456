import { ModalAlert, RStack, Button, Text, Stack } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

/**
 * TODO: maybe we could use a common Modal, migrating this code in shared/DownloadAppModal ?
 *  Used here and in OtherActions 'cause the behaviour and UI/UX are the same
 */
export const DiaryModal: React.FC<{
  openDiaryModal: boolean;
  setOpenDiaryModal: (value: boolean) => void;
}> = ({ openDiaryModal, setOpenDiaryModal }) => {
  const { t } = useTranslation();

  /**
   * NEXT STEPS: Should we implement a deeplink from webapp to mobile-app??
   * WIP -> under investigation by JUNG squad.
   */
  const {
    NX_APPSTORE_APP_URL: appStoreLink,
    NX_PLAYSTORE_APP_URL: playStoreLink
  } = process.env;

  return (
    <ModalAlert
      open={openDiaryModal}
      onClose={() => setOpenDiaryModal(false)}
      w="500px"
      title={t('therapySetting.diaryModal.title')}
      dataTestId="diary-modal"
    >
      <Stack direction="column" spacing="md" pb="2xl">
        <Text variant="md" color="grey.700">
          {t('therapySetting.diaryModal.description1')}
        </Text>
        <Text variant="md" color="grey.700">
          {t('therapySetting.diaryModal.description2')}
        </Text>
      </Stack>

      <RStack
        spacing="xs"
        justify="end"
        direction={{
          base: 'column-reverse',
          md: 'row'
        }}
        w={{
          base: '100%',
          md: 'auto'
        }}
      >
        <Button
          data-testid="go-to-android-app-modal"
          variant="tonal"
          label={t('therapySetting.diaryModal.playStoreCTA')}
          onClick={() => {
            window.open(playStoreLink, '_blank');
            setOpenDiaryModal(false);
          }}
        />
        <Button
          data-testid="go-to-ios-app-modal"
          variant="tonal"
          label={t('therapySetting.diaryModal.appStoreCTA')}
          onClick={() => {
            window.open(appStoreLink, '_blank');
            setOpenDiaryModal(false);
          }}
        />
      </RStack>
    </ModalAlert>
  );
};
