import { useColor } from '@unobravo/zenit-core';
import { IProgressBar } from '../../types/therapyFeedback.types';

export const ProgressBar = ({ progressPercentage = 0 }: IProgressBar) => {
  const candy100 = useColor('candy.100');
  const candy500 = useColor('candy.500');

  const width = `${progressPercentage}%`;

  return (
    <>
      <svg width="100%" height="4px" key="progressBar">
        <rect x="0" y="0" width="100%" height="100%" rx="3" fill={candy100} />
        <rect
          x="0"
          y="0"
          width={width}
          height="100%"
          fill={candy500}
          rx="3"
          style={{
            transition: 'width 0.3s ease-in-out'
          }}
        />
      </svg>
    </>
  );
};
