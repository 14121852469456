import React, { FC } from 'react';
import styled from 'styled-components';
import { mobileFirstMedia, Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography';
import IIcon from '@unobravo-monorepo/common/types/IIcon';
import { Link, useLocation } from 'react-router-dom';

const Wrapper = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 44px;
  :hover {
    background-color: ${({ selected }) =>
      selected
        ? `${Theme.colors.cappuccino[200]}`
        : `${Theme.colors.cappuccino[100]}`};
  }
  width: 100%;
  padding: 0 7px;
  ${({ selected }) =>
    mobileFirstMedia('md')(`
      background-color: ${
        selected
          ? `${Theme.colors.cappuccino[200]}`
          : `${Theme.colors.cappuccino[50]}`
      };
      padding: 10px;
  `)}
  :not(:last-child) {
    margin-bottom: 2px;
  }
`;

interface ISideBarMenuTab {
  Icon: FC<IIcon>;
  text: string;
  path: string;
}

export const SideBarMenuTab: React.FC<ISideBarMenuTab> = ({
  Icon,
  text,
  path
}) => {
  const location = useLocation();

  const isActive = location.pathname.split('/').includes(path);

  return (
    <Wrapper to={path} selected={isActive}>
      <Icon width={24} height={24} color={Theme.colors.candy[500]} />
      <Body
        size={BodySize.Body70}
        margin="0px 0px 0px 8px"
        color={Theme.specialColors.black}
      >
        {text}
      </Body>
    </Wrapper>
  );
};
