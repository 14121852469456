import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { usePatientFeatureFlags } from '@unobravo-monorepo/patient/featureFlags';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppointmentCardBubble } from '../../../shared/components/TherapySetting/AppointmentBubble/AppointmentCardBubble';
import { ModifyAppointmentModal } from '../../../shared/components/TherapySetting/Modals/ModifyAppointment';
import { ModifyAppointmentModalV2 } from '../../../shared/components/TherapySetting/Modals/ModifyAppointment/ModifyAppointmentV2';
import { useSession } from '../../../shared/hooks/useSession';
import { useBubbleHandlers } from '../hooks/useBubbleHandlers';
import { CardContainer } from './CardContainer';
/**
 * AppointmentCard
 */
export const AppointmentCard: React.FC<{
  appointment: IAppointment;
  showVideocall?: boolean;
  showExpiredCTA?: boolean;
}> = ({ appointment, showVideocall = true, showExpiredCTA = true }) => {
  const { uuid } = appointment;
  const navigate = useNavigate();
  const { techPtModifyAppointmentModal } = usePatientFeatureFlags();

  const { status } = useSession(uuid, { showVideocall }, appointment);
  const [openModifyModal, setOpenModifyModal] = useState(false);
  const { onPayClicked, onVideocallClicked, onModifyClicked, sessionCategory } =
    useBubbleHandlers({
      appointment,
      showVideocall
    });

  /**
   * Handle callback to modify appointment
   */
  const onModify = () => {
    setOpenModifyModal(true);
    onModifyClicked();
  };

  /**
   * On Click on appointment expired
   */
  const onExpiredClicked = () => {
    navigate('../chat');
  };

  return (
    <>
      <CardContainer testId="appointment-card">
        <AppointmentCardBubble
          variant={status}
          appointment={appointment}
          showExpiredCTA={showExpiredCTA}
          onModify={onModify}
          onPayClicked={onPayClicked}
          onVideocallClicked={onVideocallClicked}
          onExpiredClicked={onExpiredClicked}
          sessionCategory={sessionCategory}
        />
      </CardContainer>

      {techPtModifyAppointmentModal ? (
        <ModifyAppointmentModalV2
          appointment={appointment}
          openModifyModal={openModifyModal}
          setOpenModifyModal={setOpenModifyModal}
        />
      ) : (
        <ModifyAppointmentModal
          appointment={appointment}
          openModifyModal={openModifyModal}
          setOpenModifyModal={setOpenModifyModal}
        />
      )}
    </>
  );
};
