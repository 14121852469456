import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { ICreditCard, ISepaDebit } from '@unobravo/patient';
import { Box, Button, RStack, Stack } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Plus } from '@unobravo/zenit-icons';
import { useSelector } from 'react-redux';
import { paySessionDataSelector } from '@unobravo-monorepo/patient/features/newPaySession/paySessionData.slice';
import { usePatientCards } from '../patientData/hooks/usePatientCards';
import { PaymentMethodComp } from '../payment/components/PaymentMethodComp';
import { CardForm } from './components/CardForm';
import { InfoHeader } from './components/InfoHeader';
import { usePatientGTM } from '../../shared/hooks/usePatientGTM';

const CursorBox = styled(Box)`
  cursor: pointer;
`;

type FormStatus = 'NEW_CARD' | 'CARD_LIST';

export const PaymentMethod: React.FC<{
  onBack?: () => void;
  onNext?: (selectedCard: ICreditCard | ISepaDebit | 'klarna') => void;
}> = ({ onBack, onNext }) => {
  const [status, setStatus] = useState<FormStatus>();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { selectedCard: storedSelectedCard } = useSelector(
    paySessionDataSelector
  );
  const [selectedCard, setSelectedCard] = useState<ICreditCard | ISepaDebit>();
  const [newCardId, setNewCardId] = useState<string>();
  const { t } = useTranslation();
  const { cards, sepaDebits } = usePatientCards();

  useEffect(() => {
    const hasCards = cards && cards.length > 0;
    const hasSepaDebits = sepaDebits && sepaDebits.length > 0;
    const shouldSelectCard = !selectedCard && (hasCards || hasSepaDebits);

    if (shouldSelectCard) {
      setStatus('CARD_LIST');
      if (storedSelectedCard) {
        setSelectedCard(storedSelectedCard as ICreditCard | ISepaDebit);
      } else {
        setSelectedCard(cards?.[0] || sepaDebits?.[0]);
      }
    } else {
      setStatus('NEW_CARD');
    }
  }, [cards, sepaDebits]);

  // wait the refresh after entering the new card
  useEffect(() => {
    if (newCardId === 'klarna') {
      onNext?.('klarna');
      return;
    }
    if (newCardId) {
      const newCard = cards?.find((card) => card.cardId === newCardId);
      if (newCard) {
        setNewCardId(undefined);
        pushAuthenticatedEvent('NewPaymentMethod');
        onNext?.(newCard);
      }
    }
  }, [cards, newCardId]);

  if (!cards || !sepaDebits) {
    return <Spinner />;
  }

  return (
    <>
      <InfoHeader
        title={t('profileAndSettings.paymentMethodLabel')}
        description={
          status === 'NEW_CARD'
            ? t('paymentMethod.stripeDescription')
            : t('paySession.paymentMethod.choose')
        }
      />
      {status === 'CARD_LIST' ? (
        <Stack direction="column">
          <Stack data-testid="payment-method-list" direction="column" px="xl">
            <Stack direction="column">
              {cards?.map((c) => (
                <CursorBox
                  onClick={() => setSelectedCard(c)}
                  key={c.cardId}
                  data-testid="choose-credit-card"
                >
                  <PaymentMethodComp
                    paymentMethod={c}
                    isSelectedCard={
                      (selectedCard as ICreditCard)?.cardId === c.cardId
                    }
                  />
                </CursorBox>
              ))}
              {sepaDebits?.map((c) => (
                <CursorBox
                  onClick={() => setSelectedCard(c)}
                  key={c.sepaId}
                  data-testid="choose-sepa-debit"
                >
                  <PaymentMethodComp
                    paymentMethod={c}
                    isSelectedCard={
                      (selectedCard as ISepaDebit)?.sepaId === c.sepaId
                    }
                  />
                </CursorBox>
              ))}
              <Stack mt="sm" mb="2xl" justify="end">
                <Button
                  variant="tonal"
                  onClick={() => setStatus('NEW_CARD')}
                  label={t('paySession.paymentMethod.addButtonLabel')}
                  data-testid="add-payment-method-button"
                  type="button"
                  icon={Plus}
                />
              </Stack>
            </Stack>
          </Stack>
          <RStack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justify="space-between"
            position="sticky"
            spacing="xs"
            p="xl"
            rounded="lg"
            bottom={0}
            bgColor="white"
          >
            <Button
              variant="ghost"
              onClick={() => onBack?.()}
              label={t('common:cancel')}
              size="lg"
              iconPosition="left"
              data-testid="card-list-back-button"
              type="button"
            />
            <Button
              label={t('common:confirm')}
              type="submit"
              onClick={() => {
                if (selectedCard) {
                  pushAuthenticatedEvent('ConfirmPaymentMethod');
                  onNext?.(selectedCard);
                }
              }}
              size="lg"
              disabled={!selectedCard}
              data-testid="payment-method-confirmed"
            />
          </RStack>
        </Stack>
      ) : (
        status === 'NEW_CARD' && (
          <Stack
            direction="column"
            mx="xl"
            data-testid="new-card-section"
            spacing="lg"
          >
            <CardForm
              onBack={() =>
                cards.length === 0 ? onBack?.() : setStatus('CARD_LIST')
              }
              onNext={(cardId) => setNewCardId(cardId)}
            />
          </Stack>
        )
      )}
    </>
  );
};
