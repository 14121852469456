import { useTranslation } from 'react-i18next';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';

import { z } from '@unobravo/zenit-form';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { BundlesVariations } from '../../../../types/Bundles';
import { getBundlePriceInfo } from '../../../../shared/components/Bundles/bundle-map';
import { IBundlePriceDetails } from '../../../../shared/components/Bundles/bundle-prices-map';
import {
  BundleForm,
  formSchema
} from '../../../../shared/components/Bundles/BundleForm';

export const Paywall = ({
  onSubmit,
  onBack,
  bundlesVariant,
  showAlert = false
}: {
  onSubmit: (bundleInfo?: IBundlePriceDetails) => void;
  onBack?: () => void;
  bundlesVariant?: BundlesVariations;
  showAlert?: boolean;
}) => {
  const { t } = useTranslation();

  const onSubmitCallback = (values: z.infer<typeof formSchema>) => {
    if (bundlesVariant) {
      const bundleInfo = getBundlePriceInfo(bundlesVariant, values.bundle);
      onSubmit(bundleInfo);
    }
  };

  return (
    <Stack direction="column" h="100%">
      {bundlesVariant ? (
        <>
          <Box px="lg" pt="lg" mr="2xl">
            <Text variant="2xl" fontWeight="bold">
              {t('bundles.paywall.title')}
            </Text>
          </Box>
          <BundleForm
            onSkip={onBack}
            onSubmitValues={(values) => onSubmitCallback(values)}
            bundlesVariant={bundlesVariant}
            showAlert={showAlert}
          />
        </>
      ) : (
        <Spinner />
      )}
    </Stack>
  );
};
