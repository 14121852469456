import React, { FC, ReactNode } from 'react';
import { Theme } from '../../theme';

export type ViewportContextState = {
  width?: number;
  height?: number;
  isMobile?: boolean;
  isMobileXS?: boolean;
  isMobileSM?: boolean;
};

const contextDefaultValues: ViewportContextState = {};

export const index =
  React.createContext<ViewportContextState>(contextDefaultValues);

export const ViewportProvider: FC<{ children?: ReactNode }> = ({
  children
}) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth <= Theme.breakpoints.md
  );
  const [isMobileXS, setIsMobileXS] = React.useState(
    window.innerWidth <= Theme.breakpoints.xs
  );
  const [isMobileSM, setIsMobileSM] = React.useState(
    window.innerWidth <= Theme.breakpoints.sm
  );

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsMobile(window.innerWidth <= Theme.breakpoints.md);
    setIsMobileSM(window.innerWidth <= Theme.breakpoints.sm);
    setIsMobileXS(window.innerWidth <= Theme.breakpoints.xs);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <index.Provider value={{ width, height, isMobile, isMobileXS, isMobileSM }}>
      {children}
    </index.Provider>
  );
};
