import * as Tooltip from '@radix-ui/react-tooltip';
import { useColor } from '@unobravo/zenit-core';
import { Box, Text } from '@unobravo/zenit-web';
import styled from 'styled-components';

const TooltipContent = styled(Tooltip.Content)`
  z-index: 120;
  max-width: 220px;
`;

export const CustomTooltip = ({
  children,
  label,
  open,
  anchor = 'top'
}: {
  children: JSX.Element;
  label: string;
  open?: boolean | undefined;
  anchor?: 'top' | 'right' | 'left' | 'bottom';
}) => {
  const grey = useColor('grey.700');
  return (
    <Tooltip.Provider delayDuration={200}>
      <Tooltip.Root open={open}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <TooltipContent
            className="TooltipContent"
            sideOffset={5}
            side={anchor}
          >
            <Box
              bgColor="grey.700"
              p="2xs"
              rounded="2xs"
              style={{ zIndex: 120 }}
            >
              <Text variant="sm" color="white" textAlign="center">
                {label}
              </Text>
            </Box>
            <Tooltip.Arrow className="TooltipArrow" fill={grey} />
          </TooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
