import { useColor } from '@unobravo/zenit-core';
import {
  Box,
  Heading,
  RBox,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BackgroundCappuccinoImg from '@unobravo-monorepo/patient/assets/Background_cappuccino_Vertical.svg';
import { DoctorAccordionsWrapper } from '@unobravo-monorepo/patient/features/doctorPresentation/components/DoctorAccordionsWrapper';
import { useDoctorInfo } from '../../../../shared/hooks/useDoctorInfo';
import { ReassignmentButton } from '../../../reassignment/components/ReassignmentButton';

const Img = styled.img`
  border-radius: 50%;
  box-shadow: 0px 10px 14px 0px rgba(173, 173, 163, 0.4);
  width: 140px;
  height: 140px;
  margin-bottom: 14px;
  object-fit: cover;
`;

export const DoctorInfoContent = () => {
  const { t } = useTranslation();
  const { name, profilePicture, therapeuticOrientation, sex, isTherapist } =
    useDoctorInfo();

  const { isMobile } = useBreakpointValue();
  const backgroundColor = useColor(isMobile ? 'cappuccino.200' : 'white');

  const role = isTherapist
    ? t('chat.profiletp.therapist')
    : t(`chat.profiletp.noTherapist.${sex}`);

  const orientation = therapeuticOrientation
    ? t(`chat.profiletp.orientation`, {
        orientation: therapeuticOrientation,
        role
      })
    : '';
  return (
    <>
      <RStack
        direction="column"
        borderColor="cappuccino.200"
        rounded={{ md: 'md' }}
        maxH={{ base: '100%', md: 'calc(100% - 12px)' }}
        overflow="auto"
        position="relative"
      >
        <Stack
          w="100%"
          style={{
            background: `url(${BackgroundCappuccinoImg})`,
            backgroundPosition: 'center top',
            backgroundSize: 'cover',
            backgroundColor
          }}
          direction="column"
          spacing="md"
          align="center"
          p="xl"
        >
          {profilePicture && (
            <Img alt="tp-profile-picture" src={profilePicture} />
          )}
          <Stack direction="column">
            <Heading variant="xl" textAlign="center">
              {name}
            </Heading>
            {orientation && (
              <Text
                textAlign="center"
                variant="md"
                lineClamp={profilePicture ? 2 : 4}
                color="grey.500"
              >
                {orientation}
              </Text>
            )}
          </Stack>
        </Stack>
        <RBox
          bgColor={isMobile ? 'cappuccino.200' : 'white'}
          w="100%"
          rounded={{ md: 'md' }}
          mb="2xl"
        >
          <DoctorAccordionsWrapper />
        </RBox>
      </RStack>
      <Box position="absolute" bottom="0" w="100%">
        <ReassignmentButton />
      </Box>
    </>
  );
};
