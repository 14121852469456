import { Navigate, Route, Routes } from 'react-router-dom';
import { Reassignment } from './pages/Reassignment';
import { ReassignmentConfirm } from './pages/ReassignmentConfirm';
import { ReassignmentFallback } from './pages/ReassignmentFallback';

export function ReassignmentRoute() {
  return (
    <Routes>
      <Route path="" element={<Reassignment />} />
      <Route path="/confirm" element={<ReassignmentConfirm />} />
      <Route path="/already-used" element={<ReassignmentFallback />} />
      <Route
        path="*"
        element={<Navigate to="/patient/page-not-found" replace />}
      />
    </Routes>
  );
}
