import { DateTime } from 'luxon';
import { getQueryParam, setQueryParam } from './queryParams';

const dateSearchParamsKey = 'date';
export const getDateFromURL = (view: 'day' | 'week' | 'month') => {
  const now = DateTime.now().startOf(view);
  const date = getQueryParam(dateSearchParamsKey, now.toISODate());
  if (date) {
    const parsedDate = DateTime.fromISO(date);
    if (parsedDate.isValid) {
      return parsedDate;
    }
  }
  return now;
};

export const updateURLDate = (date: DateTime) => {
  const newValue = date.toISODate();
  if (!newValue) return;
  setQueryParam(dateSearchParamsKey, newValue);
};
