import { Stack } from '@unobravo/zenit-web';

interface CircleIconProps {
  icon: React.JSX.Element;
  bgColor?: 'candy.50' | 'white';
  clickable?: boolean;
}

export const CircleIcon = ({
  icon,
  bgColor = 'candy.50',
  clickable = false
}: CircleIconProps) => (
  <Stack
    w="32px"
    h="32px"
    bgColor={bgColor}
    style={{ borderRadius: '50%', cursor: clickable ? 'pointer' : undefined }}
    justify="center"
    align="center"
  >
    <Stack w="100px" justify="center">
      {icon}
    </Stack>
  </Stack>
);
