import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { ITherapySetting } from '@unobravo-monorepo/common/types/ITherapySetting';
import { sortByDate } from '@unobravo-monorepo/common/utils/dateUtils';
import { useCountry } from '@unobravo/translations';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { useAgenda } from '../../agenda/hooks/useAgenda';
import { usePatient } from '../../patientData/hooks/usePatient';
import { TherapySettingSelector } from '../store/therapySetting.slice';
import {
  filterSessionsByStatus,
  removeDuplicatedSessions
} from '../utils/session';

const EXPIRED_SESSION_REMINDER_WEEKS_GAP = -3;

/** Utils to generate WeekKey for grouped-weeks */
const getWeekKey = ({
  weekNumber,
  year
}: {
  weekNumber: number;
  year: number;
}) => `week_${weekNumber}_${year}`;

const therapySettingMap: Record<ITherapySetting, number> = {
  notSet: 0,
  weekly: 1,
  everyTwoWeeks: 2,
  everyThreeWeeks: 3,
  everyFourWeeks: 4,
  monthly: 4,
  everyFiveWeeks: 5
};

export const useWeeklyAgenda = ({ selectedDay }: { selectedDay: DateTime }) => {
  const { pastSessions, nextSessions } = useAgenda();
  const { therapyInfo } = useSelector(TherapySettingSelector);

  const { domainCountry } = useCountry();
  const { timezone } = usePatient();
  const today = DateTime.now();

  const currentWeekKey = getWeekKey({
    weekNumber: selectedDay.weekNumber,
    year: selectedDay.year
  });

  /**
   * Build current week
   */
  const currentWeek = useMemo(() => {
    const newWeek = [];
    const startOfWeek = selectedDay.startOf('week');
    for (let i = 0; i < 7; i++) {
      const date = startOfWeek.plus({ days: i });
      const dayOfWeek = date.toLocaleString(
        { weekday: 'narrow' },
        { locale: domainCountry }
      );
      const dayOfMonth = date.get('day');
      newWeek.push({ dayOfWeek, dayOfMonth, date });
    }
    return newWeek;
  }, [selectedDay, domainCountry]);

  /**
   * Group all sessions by Week
   */
  const groupByWeek = useMemo(() => {
    // Workaround: we need to remove duplicates 'cause in some edge cases we have the problem
    // that one session is in past and prev array at the same time
    // [BE problem] if the session is running it is in both groups
    const allSessions = removeDuplicatedSessions([
      ...pastSessions,
      ...nextSessions
    ]);

    const result: { [key: string]: IAppointment[] } = {};

    allSessions.forEach((session) => {
      const { year } = getSessionDate(session.when, timezone || undefined);
      const { weekNumber } = DateTime.fromISO(session.when);
      const weekKey = getWeekKey({ year, weekNumber });

      if (result[weekKey]) {
        result[weekKey].push(session);
      } else {
        result[weekKey] = [session];
      }
    });

    return result;
  }, [pastSessions, nextSessions, timezone]);

  /** All sessions in selected week */
  const sessionsInSelectedWeek = useMemo(
    () => groupByWeek[currentWeekKey] || [],
    [groupByWeek, currentWeekKey]
  );

  /*
  The sessions to be confirmed must all be
  grouped on-top of the widget and placed in order 
  from most recent to least recent 
  */
  const sessionsBookedInSelectedWeek = useMemo(() => {
    const bookedSorted = sessionsInSelectedWeek
      ?.filter((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['NEW', 'VIDEOCALL'] // session with CTA
        })
      )
      .sort((elm, prev) => sortByDate(elm.when, prev.when)); // from newest to oldest
    return bookedSorted;
  }, [sessionsInSelectedWeek]);

  /*
   * Has at least one session to pay in current week
   */
  const hasAtLeastOneSessionToPay = useMemo(() => {
    const toPay = sessionsBookedInSelectedWeek
      ?.filter((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['NEW']
        })
      )
      .some((elm) => !elm.isFree && !elm.paid);
    return toPay;
  }, [sessionsBookedInSelectedWeek]);

  /*
   Sessions not NEW && VIDEOCALL
   Preserved sorting from BE, only to be selected
  */
  const sessionsNotBookedInSelectedWeek = useMemo(() => {
    const notBooked = sessionsInSelectedWeek?.filter((session) =>
      filterSessionsByStatus({
        session,
        statuses: ['NEW', 'VIDEOCALL'], // sessions with CTA
        includes: false // get all sessions with status !== NEW or VIDEOCALL
      })
    );
    return notBooked;
  }, [sessionsInSelectedWeek]);

  /** Check if is a bookable week */
  const isBookableWeek = useMemo(() => {
    if (selectedDay.year < today.year) return false;
    if (selectedDay.year === today.year) {
      return selectedDay.weekNumber >= today.weekNumber;
    }
    return true;
  }, [selectedDay, today]);

  /**
   * Compute if is in the rest week - based on sessionsDone && Therapy Setting
   */
  const isRestWeek = useMemo(() => {
    // If is a past week
    if (!isBookableWeek) {
      const sessionsDone = sessionsInSelectedWeek?.some((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['DONE']
        })
      );
      return !sessionsDone;
    }

    // If is a current or future week && has T. Setting
    if (isBookableWeek && therapyInfo?.therapySetting) {
      const gap = therapySettingMap[therapyInfo.therapySetting];
      const gapFromToday = selectedDay.weekNumber - today.weekNumber;
      if (gap === 1) return false;
      return gapFromToday % gap !== 0;
    }
    return false;
  }, [
    isBookableWeek,
    selectedDay.weekNumber,
    sessionsInSelectedWeek,
    today.weekNumber,
    therapyInfo
  ]);

  /**
   * Check if we need to remind to user to book a new appointment for an expired session in the past.
   * 1. If they are in the past week and pastWeekGap > EXPIRED_SESSION_REMINDER_WEEKS_GAP and they havent sessions booked for the future.
   */
  const expiredSessionsReminder = useMemo(() => {
    if (!isBookableWeek) {
      const pastWeeksGap = Math.ceil(selectedDay.diffNow('weeks').weeks);
      if (pastWeeksGap > EXPIRED_SESSION_REMINDER_WEEKS_GAP) {
        const nextSessionBooked = nextSessions?.some((session) =>
          filterSessionsByStatus({
            session,
            statuses: ['NEW', 'VIDEOCALL', 'CONFIRMED'] // sessions booked
          })
        );
        return !nextSessionBooked;
      }
    }
    // future || (past && GAP >= EXPIRED_SESSION_REMINDER_WEEKS_GAP)
    return false;
  }, [nextSessions, isBookableWeek, selectedDay]);

  /**
   * Check if the PT has booked sessions in selected rest Week
   */
  const hasSessionsInRestWeek = useMemo(() => {
    if (isRestWeek) {
      return sessionsInSelectedWeek?.some((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['CONFIRMED', 'NEW', 'VIDEOCALL']
        })
      );
    }
    return false;
  }, [sessionsInSelectedWeek, isRestWeek]);

  /**
   * Next sessions count
   */
  const notificationCount = useMemo(() => {
    return nextSessions
      .filter((session) => !session.canceled)
      .filter((session) => {
        const { rawDate: sessionDate } = getSessionDate(
          session.when,
          timezone || undefined
        );
        if (selectedDay.year === sessionDate.year)
          return sessionDate.weekNumber > selectedDay.weekNumber;
        if (selectedDay.year < sessionDate.year) {
          return true;
        }
        return sessionDate > selectedDay;
      }).length;
  }, [nextSessions, timezone, selectedDay]);

  /*
   Has at least one Sessions CONFIRMED || VIDEOCALL 
  */
  const hasSessionsConfirmed = useMemo(() => {
    const notBooked = sessionsInSelectedWeek?.some((session) =>
      filterSessionsByStatus({
        session,
        statuses: ['CONFIRMED', 'VIDEOCALL'] // sessions confirmed
      })
    );
    return notBooked;
  }, [sessionsInSelectedWeek]);

  return {
    currentWeek,
    groupByWeek,
    sessionsInSelectedWeek,
    sessionsBookedInSelectedWeek,
    sessionsNotBookedInSelectedWeek,
    isBookableWeek,
    isRestWeek,
    hasSessionsInRestWeek,
    expiredSessionsReminder,
    notificationCount,
    hasSessionsConfirmed,
    hasAtLeastOneSessionToPay
  };
};
