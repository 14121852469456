import { createSlice } from '@reduxjs/toolkit';
import { theme } from '@unobravo/zenit-core';
import {
  getSessionStoredData,
  setSessionStoredData,
  STORAGE_KEYS
} from '../../shared/utils/sessionStorage';
import { ILayoutState } from '../../types/ILayoutState';
import type { PatientRootState } from '../../store';

const initialState: ILayoutState = {
  isLeftMenuOpen:
    getSessionStoredData(STORAGE_KEYS.SIDEBAR_OPEN) !== false &&
    window.innerWidth > theme.breakpoints.web.md,
  isRightMenuOpen: false,
  isMobileApp: false,
  isOnboardingOpen: false,
  hasSeenPaywall: false
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleLeftMenu: (state) => {
      state.isLeftMenuOpen = !state.isLeftMenuOpen;
      setSessionStoredData(STORAGE_KEYS.SIDEBAR_OPEN, state.isLeftMenuOpen);
    },
    closeLeftMenu: (state) => {
      state.isLeftMenuOpen = false;
      setSessionStoredData(STORAGE_KEYS.SIDEBAR_OPEN, false);
    },
    toggleRightMenu: (state) => {
      state.isRightMenuOpen = !state.isRightMenuOpen;
    },
    setIsMobileApp: (state, action) => {
      state.isMobileApp = action.payload;
    },
    setIsOnBoardingOpen: (state, action) => {
      state.isOnboardingOpen = action.payload;
    },
    setPaywallSeen: (state) => {
      state.hasSeenPaywall = true;
    }
  }
});

export default layoutSlice.reducer;

export const {
  toggleLeftMenu,
  closeLeftMenu,
  toggleRightMenu,
  setIsMobileApp,
  setIsOnBoardingOpen,
  setPaywallSeen
} = layoutSlice.actions;

export const layoutSelector = (state: PatientRootState): ILayoutState =>
  state.layout;
