const { NX_APP_ENVIRONMENT } = process.env;

export const SENTRY_ERROR_TYPE = {
  RENDER: 'render',
  NETWORK: 'network',
  OTHER: 'other'
} as const;

// Allowed to send errors
export const SENTRY_ALLOWED_URLS = [
  /app\.unobravo\.com\//i,
  /app\.unobravo\.xyz\//i,
  /^https:\/\/.+\.vercel\.app/,
  /.*buencoco.*/i,
  /.*unobravo.*/i
];

// Performance Units ratio
export const SENTRY_TRACE_PROPAGATION_TARGETS = [
  /app\.unobravo\.com\//i,
  /app\.buencoco\.es\//i,
  /app-es\.unobravo\.com\//i,
  /app-fr\.unobravo\.com\//i
];

export const REPLAYS_ON_ERROR_SAMPLE_RATE =
  NX_APP_ENVIRONMENT === 'production' ? 1 : 0;

export const REPLAYS_SESSION_SAMPLE_RATE =
  NX_APP_ENVIRONMENT === 'production' ? 0.1 : 0;

// Send Errors Ratio, send all errors on dev,preview enviroment
export const SENTRY_TRACES_SAMPLE_RATE = NX_APP_ENVIRONMENT
  ? NX_APP_ENVIRONMENT === 'production'
    ? 0.1
    : 1
  : 0.1;

export const SENTRY_IGNORED_ERRORS = [
  /.*Network Error.*/i,
  /.*A network error occurred.*/i,
  /.*Unhandled Promise Rejection: Failed to fetch.*/i,
  /.*Can't find variable: fbq.*/i,
  /.*ttq.*/i,
  /.*Unhandled Promise Rejection: Failed to load Stripe.js*/i,
  /.*UnknownError: Connection to Indexed Database server lost. Refresh the page to try again*/i,
  /.*Uncaught NetworkError: Failed to execute*/i,
  /.*NetworkError: Load failed*/i,
  /.*Cannot inject key into script value*/i,
  /.*Non-Error promise rejection captured with value: Object Not Found Matching Id:1, MethodName:update, ParamCount:4*/i,
  /.*Non-Error promise rejection captured with value: Object Not Found Matching Id:5, MethodName:simulateEvent, ParamCount:1*/i,
  /.*Non-Error promise rejection captured with value: Object Not Found Matching*/i,
  /.*null is not an object (evaluating 'window.localStorage.__transferData')*/i,
  /.*gtag*/i
];
