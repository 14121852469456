import { Box, RStack, Stack, Text } from '@unobravo/zenit-web';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface MGMCounterProps {
  limit: number;
  redeemed: number;
}

export const MGMCounter = ({ limit, redeemed }: MGMCounterProps) => {
  const { t } = useTranslation();

  const stacks = useMemo(() => {
    return Array.from({ length: limit }, (_, index) => (
      <Box
        key={index}
        rounded="md"
        bgColor={index < redeemed ? 'candy.400' : 'candy.100'}
        w="49px"
        h="8px"
      />
    ));
  }, [limit, redeemed]);

  return (
    <RStack
      rounded="md"
      bgColor="white"
      py="md"
      px="lg"
      direction="column"
      spacing="xs"
    >
      <Stack direction="row" spacing="2xs">
        {stacks}
      </Stack>
      <Text
        variant="sm"
        fontWeight="medium"
        color="candy.800"
        textAlign="center"
      >
        {t('memberGetMember.counter', { redeemed, limit })}
      </Text>
    </RStack>
  );
};
