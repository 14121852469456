import { useQuery } from '@apollo/client';
import {
  IAppointment,
  SessionStatus
} from '@unobravo-monorepo/common/types/IAppointments';
import { downloadBlob } from '@unobravo-monorepo/common/utils/fileUtils';
import { useFirebaseUser } from '@unobravo/core';
import { GET_SESSION_BY_UUID } from '@unobravo/patient';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../../features/patientData/hooks/usePatient';
import { getSessionDate } from '../utils/dateUtils';
import { useErrorHandler } from './useErrorHandler';

export type SessionOptions = {
  showVideocall: boolean;
};

const calcState = (
  paid: boolean,
  canceled: boolean,
  when: string,
  timezone?: string,
  options?: SessionOptions
): SessionStatus => {
  const today = DateTime.now();
  const date = DateTime.fromISO(when);
  const { isToday } = getSessionDate(when, timezone);
  if (canceled) {
    return 'CANCELED';
  }
  if (paid) {
    if (isToday && options?.showVideocall) {
      return 'VIDEOCALL';
    }
    if (today < date) {
      return 'CONFIRMED';
    }
    return 'DONE';
  }
  if (today > date.plus({ minutes: 10 })) {
    return 'EXPIRED';
  }
  return 'NEW';
};

export const useSession = (
  uuid: string,
  options?: SessionOptions,
  preloadedSession?: IAppointment
) => {
  const { currentUser } = useFirebaseUser();
  const { timezone } = usePatient();
  const { sendGenericError } = useErrorHandler();

  const {
    data,
    loading: dataLoading,
    error,
    refetch
  } = useQuery(GET_SESSION_BY_UUID, {
    variables: { uuid },
    skip:
      !uuid ||
      (preloadedSession &&
        !['NEW', 'EXPIRED'].includes(
          calcState(
            !!preloadedSession.paid,
            !!preloadedSession.canceled,
            preloadedSession.when || '',
            timezone ?? undefined,
            options
          )
        ))
  });
  const session =
    (data?.getSessionByUUID as IAppointment) ?? preloadedSession ?? {};
  const { paid, canceled, when } = session;
  const sessionDate = useMemo(
    () => getSessionDate(when || '', timezone ?? undefined),
    [when, timezone]
  );
  const status = useMemo(
    () =>
      calcState(!!paid, !!canceled, when || '', timezone ?? undefined, options),
    [canceled, paid, when, timezone, options]
  );
  const { t } = useTranslation();

  const downloadInvoice = async () => {
    // TODO: refactor using downloadPdfFile from fileUtils.ts
    if (!session.invoiceDownloadUrl || !currentUser.token) {
      return;
    }
    try {
      const response = await fetch(session.invoiceDownloadUrl, {
        method: 'GET',
        headers: { authorization: `Bearer ${currentUser.token}` }
      });
      const bodyResponse = await response.blob();
      const filename = `${t(
        'common:appointment'
      )}_${sessionDate?.rawDate.toFormat('dd-MM-yyyy')}`;
      downloadBlob(bodyResponse, filename);
    } catch (e) {
      sendGenericError();
    }
  };

  return {
    ...{
      ...session,
      status
    },
    dataLoading,
    dataLoaded: !!session,
    sessionDate,
    error,
    refetch,
    downloadInvoice
  };
};
