import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { TranslationsProvider } from '@unobravo/translations';
import { ErrorPage as CommonErrorPage } from '../errorPage';

const ErrorPage = ({ withReload = false }: { withReload: boolean }) => {
  const { t } = useTranslation();

  const errorPageProps = {
    wentWrong: t('common:errorPage.ops'),
    description: t('common:errorPage.wentWrong'),
    pageMalfunction: t('common:errorPage.pageMalfunction'),
    reloadLabel: t('common:errorPage.reload'),
    withReload
  };

  return <CommonErrorPage {...errorPageProps} />;
};

export function SentryRouteErrorFallback() {
  const routeError = useRouteError();
  const withReload = String(routeError || '').includes(
    'TypeError: Failed to fetch dynamically imported module'
  );

  useEffect(() => {
    captureException(routeError, {
      level: 'fatal',
      tags: { errorType: 'render', withReload }
    });
  }, [routeError]);

  return (
    <TranslationsProvider namespaces={[]}>
      <ErrorPage withReload={withReload} />
    </TranslationsProvider>
  );
}
