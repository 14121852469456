import { gql } from '@apollo/client';

export const GET_HAS_PATIENT_SURVEY_AGREEMENT = gql`
  query HasPatientSurveyAgreement($patientId: Float!) {
    hasPatientSurveyAgreement(patientId: $patientId) {
      consent3a
      consent3g
    }
  }
`;
