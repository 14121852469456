/**
 * ABTest Active
 * Currently active A/B tests on the project.
 */
export enum ActiveABTests {
  signupNewUI = 'signupNewUI',
  signupTherapyExperience = 'signupTherapyExperience',
  therapyType = 'therapyType',
  tpIntroduction = 'tpIntroduction',
  registrationAsAStep = 'registrationAsAStep',
  preferencesRevamp = 'preferencesRevamp',
  firstQuestion = 'firstQuestion',
  signupV2 = 'signupV2',
  selectYourTp = 'selectYourTp',
  /**
   * ab test used to display the new signup flow where the registration is the first step,
   * before every survey question
   */
  regOnTop = 'regOnTop',
  /**
   * ab test used to display the social login, used only for testing purposes
   */
  paySession = 'paySession',
  // ***
  k01Drop = 'k01Drop'
}

/**
 * ABTestValues type
 * Values map for active A/B tests
 */
export type ABTestValues = {
  [ActiveABTests.signupNewUI]: { value: 'treatment' };
  [ActiveABTests.regOnTop]: { value: 'treatment' };
  [ActiveABTests.signupTherapyExperience]: { value: 'treatment' };
  [ActiveABTests.therapyType]: { value: 'treatment' };
  [ActiveABTests.tpIntroduction]: { value: 'tpIntroduction' };
  [ActiveABTests.registrationAsAStep]: { value: 'treatment' };
  [ActiveABTests.preferencesRevamp]: { value: 'treatment' };
  [ActiveABTests.firstQuestion]: { value: 'treatment' };
  [ActiveABTests.signupV2]: { value: 'treatment' };
  [ActiveABTests.selectYourTp]: { value: 'treatment' };
  [ActiveABTests.paySession]: { value: 'treatment'; payload: 'a' | 'b' };
  [ActiveABTests.k01Drop]: { value: 'treatment' | 'treatment-2' };
};
