import { sortByDate } from '@unobravo-monorepo/common/utils/dateUtils';
import { useEffect, useState } from 'react';
import { IAppointment } from '@unobravo/core';
import { useAgenda } from '../../agenda/hooks/useAgenda';

const DELAY_MS = 1000;

export const useSessionToPay = () => {
  const { nextSessions, loadAppointments, status } = useAgenda();
  const [sessionToPay, setSessionToPay] = useState<IAppointment | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsLoading(true);
      loadAppointments();
    }, DELAY_MS);

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextSessionToPay = nextSessions
      .filter(
        (session) => session.sessionState === 'PENDING' && !session.isFree
      )
      .sort((elm, prev) => sortByDate(elm.when, prev.when))[0]; // from newest to oldest
    if (nextSessionToPay) {
      if (sessionToPay === undefined) {
        setSessionToPay(nextSessionToPay);
        setIsLoading(false);
      }
    }
    if (status !== 'loading') {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSessions]);

  return {
    sessionToPay,
    isLoading
  };
};
