import { Box } from '@unobravo/zenit-web';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { loadScript } from '@unobravo-monorepo/common/utils/whereby';

interface VideoCallComponentProps {
  appointmentUrl: string;
}

const WhereByContainer = styled(Box)`
  display: flex;
  > whereby-embed {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

export const WherebyEmbed = ({ appointmentUrl }: VideoCallComponentProps) => {
  useEffect(loadScript, []);

  useEffect(() => {
    const createElement = document.createElement('whereby-embed');
    createElement.setAttribute('room', appointmentUrl);
    const container = document.getElementById('videocall-container');

    if (container) {
      container.appendChild(createElement);
    }
    return () => {
      if (container) {
        container.removeChild(createElement);
      }
    };
  }, [appointmentUrl]);

  return <WhereByContainer id="videocall-container" w="100%" h="100%" />;
};
