import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mobileFirstMedia, Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import calendarEmptyStateImg from '@unobravo-monorepo/common/assets/CalendarEmptyState.svg';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { Button } from '@unobravo-monorepo/common/components/Button';
import { useNavigate } from 'react-router-dom';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 275px;
  ${mobileFirstMedia('md')`max-width: 520px;`}
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const NoAppointment: React.FC<{ isPast?: boolean }> = ({ isPast }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const navigate = useNavigate();

  return (
    <Wrapper data-testid="no-appointment">
      {!isMobile && (
        <ImgContainer>
          <img src={calendarEmptyStateImg} alt="no appointment" />
        </ImgContainer>
      )}
      <TextContainer>
        <Body
          data-testid="appointment-empty-alert"
          size={BodySize.Body70}
          color={Theme.colors.gray[700]}
          margin={isPast ? '0' : ' 0 0 28px 0'}
        >
          {t(isPast ? 'agenda.noPastAppointment' : 'agenda.noAppointment')}
        </Body>
        {!isPast && (
          <Button
            data-testid="appointment-go-to-chat"
            variant="secondary"
            label={t('agenda.goToChat')}
            onClick={() => navigate('../chat')}
          />
        )}
      </TextContainer>
    </Wrapper>
  );
};
