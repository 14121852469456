import React, { Dispatch, SetStateAction } from 'react';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { StripeProvider } from '../../payment/providers/stripeProvider';
import { AddNewCard } from '../components/AddNewCard';
import { useGetPaymentIntent } from '../../payment/hooks/useGetPaymentIntent';

interface IAddCardProps {
  context?: 'MOBILE' | 'WEBAPP';
  setAddNewCard?: Dispatch<SetStateAction<boolean>>;
}

export const AddCard: React.FC<IAddCardProps> = ({
  setAddNewCard,
  context
}) => {
  const { paymentIntent: clientSecret } = useGetPaymentIntent();
  return (
    <>
      {clientSecret ? (
        <StripeProvider clientSecret={clientSecret}>
          <AddNewCard setAddNewCard={setAddNewCard} context={context} />
        </StripeProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};
