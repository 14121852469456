import { useABTestVariant } from '@unobravo-monorepo/common/hooks';
import { DoctorAccordions } from '@unobravo-monorepo/patient/shared/components/TherapistSidebar/DoctorAccordions';
import { DoctorAccordionsVariant } from './DoctorAccordionsVariant';

export const DoctorAccordionsWrapper = () => {
  const vwoVariant = useABTestVariant('DoctorProfilePresentation');
  return vwoVariant === 'variant1' ? (
    <DoctorAccordionsVariant />
  ) : (
    <DoctorAccordions />
  );
};
