import {
  IAppointment,
  SessionStatus
} from '@unobravo-monorepo/common/types/IAppointments';
import { downloadPdfFile } from '@unobravo-monorepo/common/utils/fileUtils';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { ModalDownloadInvoice } from '@unobravo-monorepo/patient/shared/components/ModalDownloadInvoice';
import { useErrorHandler } from '@unobravo-monorepo/patient/shared/hooks/useErrorHandler';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import { useSession } from '@unobravo-monorepo/patient/shared/hooks/useSession';
import { useFirebaseUser } from '@unobravo/core';
import { useBreakpointValue } from '@unobravo/zenit-web';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { useConfirmBonusSession } from '../../../payment/hooks/useConfirmBonusSession';
import { usePayFreeSession } from '../../../payment/hooks/usePayFreeSession';
import { useTherapySurveyConsent } from '../../../therapySurveyConsentModal/hooks/useTherapySurveyConsent';
import { openTherapySurveyConsentModal } from '../../../therapySurveyConsentModal/therapySurveyConsent.slice';
import { AppointmentV2 } from '../AppointmentV2/index';

/**
 * WARNING!:
 * This is a copy of the component with some updates 'cause we need to release the new feature coverd by FF
 (and its only for Invoices page) to not impact the chat components.
 In the next steps after a A/B test and FF enabled to all user we consider to make this component the main component.
 */

interface IAppointmentBubble {
  session: IAppointment;
  sentAt?: string;
  handleInvoice?: boolean;
  handleCreditNote?: boolean;
  showVideocall?: boolean;
}

interface ActionType {
  header?: string;
  label: string;
  action?: () => void;
}

export const AppointmentBubbleV2: React.FC<IAppointmentBubble> = ({
  session,
  sentAt,
  handleInvoice = false,
  handleCreditNote = false,
  showVideocall = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { payFreeSession, loading: paymentLoading } = usePayFreeSession();
  const { confirmBonusSession, loading: confirmBonusLoading } =
    useConfirmBonusSession();
  const { id: patientId, uuid: patientUuid } = usePatient();
  const { currentUser } = useFirebaseUser();
  const { uuid, id: sessionId } = session;
  const {
    category,
    sessionDate,
    id,
    status,
    isFree,
    hasVoucher,
    isInvoiceable,
    invoiceDownloadUrl,
    hasCreditNote,
    creditNoteDownloadUrl,
    refetch
  } = useSession(uuid, { showVideocall }, session);
  const { sendGenericError } = useErrorHandler();
  const { isMobile } = useBreakpointValue();

  const { startHour, endHour, day, month, year } = sessionDate || {};
  const showInvoice = handleInvoice && isInvoiceable;
  const showCreditNote = handleCreditNote && hasCreditNote;
  const sessionCategory = category || 'PAID';
  const isFreeSession = isFree || sessionCategory === 'FREE';
  const isHome = location.pathname.includes('home');
  const [modalSession, setModalSession] = useState('');

  const dispatch = useDispatch();
  const isConsentGiven = useTherapySurveyConsent();

  const checkDialogNavigation = () => {
    if (isHome) return 'home';
    return location.pathname.includes('agenda') ? 'agenda' : 'chat';
  };

  const payClickHandler = async () => {
    if (paymentLoading || confirmBonusLoading || !id) {
      return;
    }
    if (isFreeSession) {
      const payResult = await payFreeSession(id, sessionDate);
      if (!payResult) {
        return;
      }
      await refetch();

      if (isConsentGiven) {
        return;
      }

      setTimeout(() => {
        dispatch(openTherapySurveyConsentModal());
      }, 500);

      return;
    }
    if (sessionCategory === 'BONUS') {
      return (await confirmBonusSession(id)) && refetch();
    }
    return navigate(`../dialog/${checkDialogNavigation()}/paySession/${uuid}`, {
      state: { modal: location }
    });
  };

  const downloadDocument = async (url?: string) => {
    if (!url) return;
    const filename = `${t(
      'common:appointment'
    )}_${sessionDate?.rawDate.toFormat('dd-MM-yyyy')}`;
    try {
      await downloadPdfFile(url!, filename, currentUser.token!);
    } catch {
      sendGenericError();
    }
  };

  const openModalDownloadInvoice = async () => {
    if (isMobile) {
      setModalSession(session.id);
    } else {
      await downloadDocument(creditNoteDownloadUrl);
    }
  };

  const openVideocall = () => {
    pushAuthenticatedEvent('StartVideocall', {
      user_id: patientId,
      uuid: patientUuid
    });
    pendoTrack('join_session', {
      sessionId
    });
    navigate(`../appointment/${uuid}`);
  };

  const headerLabel = t(
    isFreeSession
      ? 'chat.appointmentProposal.headerfree'
      : sessionCategory === 'BONUS'
      ? 'chat.appointmentProposal.headerbonus'
      : 'chat.appointmentProposal.headerpaid'
  );

  const headerFree = isFreeSession
    ? t('chat.appointmentProposal.headerfree')
    : undefined;

  const appointmentMap: Record<SessionStatus, ActionType> = {
    NEW: {
      header: headerLabel,
      label: isFreeSession
        ? 'chat.appointmentProposalAction.free'
        : 'chat.appointmentProposalAction.pending',
      action: payClickHandler
    },
    CONFIRMED: {
      header: headerFree,
      label:
        hasVoucher && !isInvoiceable
          ? 'chat.appointmentProposalAction.confirmedWithVoucher'
          : 'chat.appointmentProposalAction.invoice',
      action: showInvoice
        ? () => downloadDocument(invoiceDownloadUrl)
        : undefined
    },
    VIDEOCALL: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.videocall',
      action: openVideocall
    },
    EXPIRED: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.expired'
    },
    DONE: {
      header: headerFree,
      label: showInvoice
        ? 'agenda.invoice'
        : 'chat.appointmentProposalAction.done',
      action: showInvoice
        ? () => downloadDocument(invoiceDownloadUrl)
        : undefined
    },
    CANCELED: {
      header: headerFree,
      label: 'chat.appointmentProposalAction.canceled'
    }
  };

  useEffect(() => {
    if (!!modalSession && !isMobile) {
      setModalSession('');
    }
  }, [isMobile]);

  return (
    <>
      <ModalDownloadInvoice
        filename={`${t('common:appointment')}_${sessionDate?.rawDate.toFormat(
          'dd-MM-yyyy'
        )}`}
        invoiceDownloadUrl={invoiceDownloadUrl}
        creditNoteDownloadUrl={creditNoteDownloadUrl}
        token={currentUser.token!}
        openModal={modalSession}
        onClose={() => setModalSession('')}
        label={`${day} ${month} ${year}`}
      />
      <div id={status === 'NEW' ? 'statusNew' : undefined}>
        <AppointmentV2
          hourLabel={`${startHour} - ${endHour}`}
          dateLabel={`${day} ${month} ${year}`}
          messageTime={sentAt}
          status={status}
          actionLabel={t(appointmentMap[status].label)}
          headerLabel={!isHome ? appointmentMap[status].header : ''}
          action={appointmentMap[status].action}
          onInvoiceDownloadPress={() => downloadDocument(invoiceDownloadUrl)}
          isInvoiceable={showInvoice}
          showCreditNote={showCreditNote}
          invoiceDisabled={isInvoiceable && !invoiceDownloadUrl}
          creditNoteDisabled={showCreditNote && !creditNoteDownloadUrl}
          invoiceTooltipLabel={
            showInvoice
              ? t(
                  invoiceDownloadUrl
                    ? 'chat.appointmentProposalAction.downloadInvoice'
                    : 'chat.appointmentProposalAction.pendingInvoice'
                )
              : undefined
          }
          creditNoteTooltipLabel={t(
            'chat.appointmentProposalAction.downloadCreditNote'
          )}
          onCreditNoteDownloadPress={openModalDownloadInvoice}
          actionLabelCreditNote={t(
            `chat.appointmentProposalAction.${
              isMobile ? 'creditNoteMobile' : 'creditNote'
            }`
          )}
        />
      </div>
    </>
  );
};
