import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Finance: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.colors.candy[500]
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3440_21138)">
        <path
          d="M13.9749 4.19627C15.3417 5.56311 15.3417 7.77918 13.9749 9.14602C12.608 10.5129 10.392 10.5129 9.02513 9.14602C7.65829 7.77918 7.65829 5.56311 9.02513 4.19627C10.392 2.82943 12.608 2.82943 13.9749 4.19627"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.559 11.6122C17.8678 11.9209 17.8678 12.4215 17.559 12.7303C17.2503 13.039 16.7497 13.039 16.4409 12.7303C16.1322 12.4215 16.1322 11.9209 16.4409 11.6122C16.7497 11.3034 17.2503 11.3034 17.559 11.6122Z"
          fill={color}
        />
        <path
          d="M14.98 7.03014C15.306 6.94614 15.619 6.82314 15.902 6.64114C16.363 6.34414 16.91 6.17114 17.5 6.17114H18.5V8.72514C19.218 9.37314 19.788 10.1811 20.126 11.1061H21C21.552 11.1061 22 11.5541 22 12.1061V15.1711C22 15.7231 21.552 16.1711 21 16.1711H19.689C19.164 17.0791 18.406 17.8351 17.5 18.3621V20.1711C17.5 20.7231 17.052 21.1711 16.5 21.1711H14.5C13.948 21.1711 13.5 20.7231 13.5 20.1711V19.1711H10V20.0391C10 20.5911 9.552 21.0391 9 21.0391H7C6.448 21.0391 6 20.5911 6 20.0391V17.6341C4.775 16.5361 4 14.9461 4 13.1711C4 10.5231 5.718 8.28014 8.098 7.48414"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.288 10.1711C1.819 10.4441 1.5 10.9471 1.5 11.5291C1.5 12.4001 2.206 13.1061 3.077 13.1061H4"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 10.1711H14.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3440_21138">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
