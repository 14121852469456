import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseUser } from '@unobravo/core';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';

export const Logout = () => {
  const { logout } = useFirebaseUser();
  const navigate = useNavigate();

  const logoutAndRedirect = async () => {
    await logout();
    navigate('../login', { replace: true });
  };

  useEffect(() => {
    logoutAndRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};
