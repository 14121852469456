const scriptUrl = 'https://cdn.srv.whereby.com/embed/v1.js';

/**
 * useEffect cleanup function for Whereby script loader
 */
function loadScriptCleanup(script: HTMLScriptElement) {
  return document.body.removeChild(script);
}

/**
 * Loads Whereby html script
 */
export function loadScript(): () => void {
  const script = document.createElement('script');
  script.src = scriptUrl!;
  script.type = 'module';
  document.body.appendChild(script);

  return () => loadScriptCleanup(script);
}
