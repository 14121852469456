export const DownloadInvoiceIcon = ({ width = 249, height = 245 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 249 245"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.05 66.8263C74.9543 32.3255 119.444 31.3885 135.625 31.0481C177.093 30.1749 229.307 47.5322 232.25 71.8182C234.771 92.6219 199.039 101.197 192.024 141.294C188.229 162.989 197.194 169.024 192.024 182.481C178.85 216.776 94.0431 246.76 47.2942 203.698C9.35264 168.748 15.5424 103.241 46.05 66.8255V66.8263Z"
      fill="#FFE1CC"
    />
    <path
      d="M28.6128 77.5696C30.4442 75.7373 30.4442 72.7666 28.6128 70.9343C26.7815 69.102 23.8123 69.102 21.9809 70.9343C20.1496 72.7666 20.1496 75.7373 21.9809 77.5696C23.8123 79.4019 26.7815 79.4019 28.6128 77.5696Z"
      fill="#FF7850"
    />
    <path
      d="M96.3766 218.281C97.8639 218.281 99.0696 217.075 99.0696 215.587C99.0696 214.099 97.8639 212.893 96.3766 212.893C94.8892 212.893 93.6835 214.099 93.6835 215.587C93.6835 217.075 94.8892 218.281 96.3766 218.281Z"
      fill="#FF7850"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M215.82 84.3481L128.253 79.3071C126.461 79.2041 124.911 80.6015 124.809 82.4135L118.238 199.041C118.136 200.853 119.518 202.419 121.311 202.523L208.878 207.564C210.669 207.667 212.219 206.27 212.322 204.458L218.893 87.8298C218.995 86.0186 217.612 84.4519 215.82 84.3489V84.3481Z"
        fill="#3A7EC1"
      />
    </g>
    <path
      d="M207.184 205.157L119.912 200.187C118.126 200.085 116.748 198.541 116.849 196.754L123.398 81.7624C123.5 79.9766 125.044 78.598 126.831 78.6996L214.103 83.6699C215.889 83.7715 217.268 85.3161 217.166 87.1027L210.617 202.094C210.516 203.88 208.971 205.259 207.184 205.157Z"
      fill="#FFF8F3"
    />
    <path
      d="M202.432 115.642L134.789 111.79C133.636 111.725 132.746 110.727 132.812 109.573C132.877 108.42 133.875 107.53 135.029 107.596L202.672 111.448C203.826 111.513 204.716 112.511 204.649 113.665C204.584 114.819 203.586 115.709 202.432 115.642V115.642Z"
      fill="#FFD1B3"
    />
    <path
      d="M201.573 130.796L133.93 126.944C132.776 126.879 131.886 125.881 131.952 124.727C132.017 123.573 133.016 122.683 134.169 122.75L201.812 126.602C202.966 126.667 203.856 127.665 203.79 128.819C203.725 129.972 202.727 130.862 201.573 130.796V130.796Z"
      fill="#FFD1B3"
    />
    <path
      d="M200.711 145.948L133.068 142.097C131.914 142.031 131.024 141.033 131.09 139.879C131.155 138.726 132.153 137.836 133.307 137.902L200.95 141.754C202.104 141.819 202.994 142.817 202.928 143.971C202.863 145.125 201.865 146.015 200.711 145.948V145.948Z"
      fill="#FFD1B3"
    />
    <path
      d="M199.844 161.107L132.201 157.255C131.047 157.19 130.157 156.192 130.223 155.038C130.289 153.885 131.287 152.994 132.44 153.061L200.084 156.913C201.237 156.978 202.127 157.976 202.061 159.13C201.996 160.283 200.998 161.174 199.844 161.107V161.107Z"
      fill="#FFD1B3"
    />
    <path
      d="M198.982 176.262L131.339 172.41C130.185 172.344 129.295 171.346 129.361 170.193C129.427 169.039 130.425 168.149 131.578 168.215L199.221 172.067C200.375 172.132 201.265 173.13 201.199 174.284C201.134 175.438 200.136 176.328 198.982 176.262Z"
      fill="#FFD1B3"
    />
    <path
      d="M198.12 191.416L130.477 187.564C129.323 187.499 128.433 186.501 128.499 185.347C128.564 184.193 129.563 183.303 130.716 183.369L198.359 187.221C199.513 187.287 200.403 188.285 200.337 189.438C200.272 190.592 199.274 191.482 198.12 191.416Z"
      fill="#FFD1B3"
    />
    <path
      d="M203.295 100.488L135.652 96.6362C134.498 96.5709 133.608 95.5728 133.674 94.4191C133.739 93.2654 134.737 92.3753 135.891 92.4414L203.534 96.2935C204.688 96.3588 205.578 97.3569 205.512 98.5106C205.446 99.6643 204.448 100.554 203.295 100.488V100.488Z"
      fill="#FFD1B3"
    />
    <path
      d="M145.481 155.613L84.8606 178.253C80.3512 179.937 78.1103 184.829 79.8562 189.18C81.6021 193.531 86.6723 195.693 91.1817 194.008L148.308 172.673C150.238 171.952 151.197 169.859 150.449 167.996L145.481 155.613V155.613Z"
      fill="#C1822F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M181.317 69.5715L93.6314 76.943C91.8371 77.0937 90.4916 78.6908 90.6417 80.4915L100.32 196.439C100.47 198.24 102.062 199.591 103.856 199.44L191.542 192.068C193.336 191.918 194.682 190.321 194.532 188.52L184.853 72.5719C184.703 70.7712 183.112 69.4209 181.317 69.5715V69.5715Z"
        fill="#3A7EC1"
      />
    </g>
    <path
      d="M189.238 188.471L102.129 195.768C100.346 195.917 98.7652 194.581 98.6161 192.798L89.0012 78.0217C88.8521 76.2391 90.188 74.6582 91.9713 74.509L179.08 67.2121C180.863 67.0629 182.444 68.3988 182.593 70.1821L192.208 184.958C192.357 186.741 191.021 188.322 189.238 188.471V188.471Z"
      fill="white"
    />
    <path
      d="M172.005 100.5L104.489 106.155C103.338 106.252 102.316 105.389 102.22 104.237C102.123 103.086 102.987 102.065 104.138 101.969L171.655 96.3131C172.806 96.2163 173.827 97.0798 173.923 98.231C174.02 99.3823 173.156 100.404 172.005 100.5V100.5Z"
      fill="#7CB8A9"
    />
    <path
      d="M173.27 115.631L105.754 121.286C104.602 121.383 103.581 120.519 103.485 119.368C103.388 118.217 104.252 117.195 105.403 117.099L172.919 111.444C174.071 111.347 175.092 112.211 175.188 113.362C175.285 114.513 174.421 115.535 173.27 115.631V115.631Z"
      fill="#7CB8A9"
    />
    <path
      d="M174.537 130.754L107.021 136.41C105.87 136.506 104.848 135.643 104.752 134.492C104.655 133.34 105.519 132.319 106.67 132.223L174.187 126.567C175.338 126.471 176.36 127.334 176.455 128.485C176.552 129.637 175.689 130.658 174.537 130.754V130.754Z"
      fill="#7CB8A9"
    />
    <path
      d="M175.804 145.88L108.288 151.536C107.137 151.632 106.115 150.769 106.019 149.618C105.923 148.466 106.786 147.445 107.937 147.349L175.454 141.693C176.605 141.597 177.626 142.46 177.722 143.611C177.819 144.763 176.956 145.784 175.804 145.88Z"
      fill="#7CB8A9"
    />
    <path
      d="M177.072 161.006L109.555 166.662C108.404 166.758 107.382 165.895 107.287 164.744C107.19 163.592 108.053 162.571 109.204 162.475L176.721 156.819C177.872 156.723 178.894 157.586 178.99 158.737C179.086 159.889 178.223 160.91 177.072 161.006Z"
      fill="#7CB8A9"
    />
    <path
      d="M178.341 176.137L110.825 181.792C109.673 181.889 108.652 181.026 108.556 179.874C108.459 178.723 109.323 177.702 110.474 177.606L177.991 171.95C179.142 171.854 180.163 172.717 180.259 173.868C180.356 175.019 179.493 176.041 178.341 176.137Z"
      fill="#7CB8A9"
    />
    <path
      d="M170.738 85.3737L103.222 91.0292C102.07 91.126 101.049 90.2625 100.953 89.1113C100.856 87.96 101.72 86.9385 102.871 86.8426L170.388 81.1871C171.539 81.0903 172.56 81.9538 172.656 83.105C172.753 84.2563 171.89 85.2778 170.738 85.3737Z"
      fill="#25886F"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M140.047 46.1524L154.214 82.8355C155.227 85.4568 153.927 88.4045 151.312 89.4183L143.261 92.5417L165.272 149.536C166.209 151.963 165.005 154.692 162.583 155.632L97.4288 180.909L96.8381 174.066L46.03 86.5257C45.1631 84.2802 45.2843 81.8937 46.1852 79.8505C47.086 77.8074 48.7642 76.1101 51.0047 75.2412L133.479 43.2444C136.094 42.2297 139.034 43.5327 140.047 46.1532V46.1524Z"
        fill="#A79C95"
      />
    </g>
    <path
      d="M156.547 145.212L134.832 88.2916L142.773 85.1696C145.353 84.1586 146.637 81.2139 145.638 78.5958L131.66 41.9586C130.664 39.3405 127.762 38.0408 125.182 39.0542L43.8217 71.0118C41.6115 71.8788 39.9585 73.5733 39.069 75.613C38.1795 77.656 38.0615 80.0379 38.9159 82.2806L46.8959 103.204L57.1084 129.97L76.7977 181.584L79.8966 189.709C79.0405 187.464 79.1609 185.083 80.0496 183.043C80.9335 181.016 82.5746 179.328 84.7649 178.455L153.895 151.301C156.282 150.361 157.471 147.637 156.547 145.212Z"
      fill="#FFB380"
    />
    <path
      d="M27.0256 189.305C30.4129 183.341 30.8599 177.085 30.9775 171.216C31.0951 165.346 30.9775 159.383 33.4827 153.244C34.2641 150.896 35.8894 148.918 38.0462 147.691C38.9021 147.244 39.8698 147.054 40.8321 147.143C41.7944 147.233 42.7101 147.598 43.4682 148.195C44.6197 149.427 45.2805 151.035 45.3265 152.717C45.9617 158.575 43.8916 165.311 40.4338 171.614C36.7382 178.055 32.2324 184 27.0256 189.305V189.305Z"
      fill="white"
    />
    <path
      d="M26.1328 104.387C30.1151 105.585 34.989 108.13 35.7282 112.747C36.6 118.124 32.5895 123.139 26.8571 123.947C25.4971 124.139 23.4955 123.804 21.9609 123.59"
      stroke="white"
      strokeWidth="1.04625"
      strokeMiterlimit="10"
    />
    <path
      d="M229.629 64.1992C226.538 65.9963 222.826 68.0918 218.987 69.0137C214.082 70.1267 208.154 69.6932 204.979 66.2957C201.368 62.4413 202.944 56.5016 206.461 52.6003C208.168 50.7057 210.24 49.3716 211.07 47.8789"
      stroke="white"
      strokeWidth="1.04625"
      strokeMiterlimit="10"
    />
  </svg>
);
