import * as Accordion from '@radix-ui/react-accordion';
import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import bgImgDesktop from '@unobravo-monorepo/signup/assets/select_tp_detail_desktop_bg.svg';
import bgImgMobile from '@unobravo-monorepo/signup/assets/select_tp_detail_mobile_bg.svg';
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp
} from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  DotsIndicator,
  Heading,
  ModalAlert,
  RBox,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ProfessionalAreaEnum } from '../../../../../common/generated/common.generated';
import { useTherapistInfo } from '../hook/useTherapistInfo';
import { useTherapistSpecialization } from '../hook/useTherapistSpecialization';
import { CircleIcon } from './circleIcon';
import { ConfirmSelectionModal } from './confirmSelectionModal';
import { CurriculumInfo } from './curriculumInfo';
import { TpInfoBox } from './tpInfoBox';

const Divider = () => <Box borderColor="cappuccino.300" borderTop="sm" />;

const Img = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: fill;
  margin-bottom: 16px;
`;

interface SectionHeaderProps {
  title: string;
}
const SectionHeader = ({ title }: SectionHeaderProps) => {
  return (
    <Box mb="md">
      <Text
        color="grey.700"
        fontWeight="bold"
        style={{ letterSpacing: '1.44px', fontSize: '12px' }}
      >
        {title}
      </Text>
    </Box>
  );
};

const OutlinedCircle = styled(Stack)`
  border-radius: 50%;
  cursor: pointer;
`;

const Bullet = styled.li`
  ::marker {
    color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

const InfoContent = styled(RBox)`
  margin-top: -31px;
  padding-right: ${({ theme }) => theme.spacing.lg}px;
  padding-left: ${({ theme }) => theme.spacing.lg}px;
  overflow-y: scroll;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

const ReviewWrapper = styled(Box)`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.cappuccino[400]};
  }
`;

interface ProfessionalAreasListProps {
  professionalAreas: (ProfessionalAreaEnum | null)[];
  startProfessionalAreasList: number;
  endProfessionalAreasList: number;
}
const ProfessionalAreasList = ({
  professionalAreas,
  startProfessionalAreasList,
  endProfessionalAreasList
}: ProfessionalAreasListProps) => {
  const { t } = useTranslation();
  return (
    <ul style={{ marginLeft: '24px', gap: '4px', display: 'grid' }}>
      {professionalAreas!
        .slice(startProfessionalAreasList, endProfessionalAreasList)
        .map((professionalArea) => (
          <Bullet key={professionalArea}>
            <Text color="grey.600">
              {t(`selectYourTp.professionalAreas.${professionalArea}`)}
            </Text>
          </Bullet>
        ))}
    </ul>
  );
};

interface ModalArrow {
  disabled: boolean;
  onClick: () => void;
}

interface TherapistDetailProps {
  show: boolean;
  onClose: () => void;
  doctorOption: PreMatchOptionFragment;
  right: ModalArrow;
  left: ModalArrow;
}

export const TherapistDetail = ({
  doctorOption,
  show,
  onClose,
  right,
  left
}: TherapistDetailProps) => {
  const { t } = useTranslation();

  const { isMobile } = useBreakpointValue();

  const [reviewIndex, setReviewIndex] = useState(0);
  const [accordionToggle, setAccordionToggle] = useState(false);

  const [openConfirmSelection, setOpenConfirmSelection] = useState(false);

  const variants = useTherapistInfo(doctorOption.doctorPresentation, 3);

  const {
    doctorPresentation,
    doctor: { reviews, sex, professionalAreas }
  } = doctorOption;

  const fullName = `${doctorOption.doctor.name} ${doctorOption.doctor.surname}`;

  const { therapeuticOrientation: therapeuticOrientationLabel } =
    useTherapistSpecialization(doctorOption);

  const onModalClose = () => onClose();

  const onSelectTpClick = () => setOpenConfirmSelection(true);

  const onConfirmSelectionModalClose = () => setOpenConfirmSelection(false);

  return (
    <>
      <ModalAlert
        w="700px"
        open={show && !openConfirmSelection}
        onClose={onModalClose}
        dataTestIdCancelButton="PreMatch-Cancel-TP-Profile"
        dataTestIdCloseIcon="PreMatch-Cancel-TP-Profile"
      >
        <RStack
          direction="column"
          h={{ base: undefined, md: '820px' }}
          style={{ overflowY: 'hidden', margin: '-24px' }}
        >
          <Stack
            direction="column"
            align="center"
            minH="265px"
            style={{
              backgroundSize: 'cover',
              backgroundImage: isMobile
                ? `url(${bgImgMobile})`
                : `url(${bgImgDesktop})`
            }}
          >
            <Stack direction="column" py="xl" w="100%">
              <Img
                alt="tp-profile-picture"
                src={doctorOption.doctor.profilePicture || ''}
                style={{ alignSelf: 'center' }}
              />

              <Stack direction="row" justify="space-between" px="xl">
                <Box
                  style={{ alignContent: 'center' }}
                  onClick={!left.disabled ? left.onClick : undefined}
                  w="32px"
                >
                  {!left.disabled && (
                    <CircleIcon
                      bgColor="white"
                      icon={<ArrowLeft />}
                      clickable
                    />
                  )}
                </Box>

                <Stack direction="column" maxW="240px" spacing="2xs">
                  <Heading
                    variant="md"
                    textAlign="center"
                    fontWeight="bold"
                    color="grey.800"
                  >
                    {fullName}
                  </Heading>

                  <Text textAlign="center" variant="sm" color="grey.600">
                    {therapeuticOrientationLabel}
                  </Text>
                </Stack>

                <Box
                  style={{ alignContent: 'center' }}
                  onClick={!right.disabled ? right.onClick : undefined}
                  w="32px"
                >
                  {!right.disabled && (
                    <CircleIcon
                      bgColor="white"
                      icon={<ArrowRight />}
                      clickable
                    />
                  )}
                </Box>
              </Stack>
            </Stack>
          </Stack>

          <InfoContent h="100%" bgColor="white" w="100%" rounded={{ md: 'xl' }}>
            <RStack direction="column" mt="lg">
              <SectionHeader title={t('selectYourTp.detail.curiosity')} />

              <Stack direction="column" spacing="xs" mb="lg">
                {variants.map((variant) => (
                  <TpInfoBox
                    variant={variant}
                    type="detail"
                    doctorOption={doctorOption}
                  />
                ))}
              </Stack>
            </RStack>

            {professionalAreas && (
              <>
                <Divider />
                <RStack direction="column" mt="lg">
                  <SectionHeader
                    title={t(
                      'selectYourTp.detail.specialization.specialization',
                      { context: sex }
                    )}
                  />

                  <Stack direction="column" mb="lg">
                    <ProfessionalAreasList
                      professionalAreas={professionalAreas!}
                      startProfessionalAreasList={0}
                      endProfessionalAreasList={
                        professionalAreas.length > 5
                          ? 5
                          : professionalAreas.length
                      }
                    />

                    {professionalAreas?.length > 5 && (
                      <Accordion.Root type="single" collapsible>
                        <Accordion.Item value="item-1">
                          <Accordion.Header>
                            <Accordion.AccordionTrigger
                              onClick={() =>
                                setAccordionToggle(!accordionToggle)
                              }
                              style={{
                                background: 'none',
                                border: 'none',
                                marginTop: '16px',
                                cursor: 'pointer'
                              }}
                            >
                              <Stack
                                ml="xl"
                                direction="row"
                                align="center"
                                spacing="2xs"
                              >
                                <Text color="candy.400" fontWeight="medium">
                                  {t(
                                    'selectYourTp.detail.specialization.accordionTitle'
                                  )}
                                </Text>
                                {accordionToggle ? (
                                  <ArrowDown color="candy.400" size="sm" />
                                ) : (
                                  <ArrowUp color="candy.400" size="sm" />
                                )}
                              </Stack>
                            </Accordion.AccordionTrigger>
                          </Accordion.Header>
                          <Accordion.Content style={{ marginTop: '16px' }}>
                            <ProfessionalAreasList
                              professionalAreas={professionalAreas!}
                              startProfessionalAreasList={5}
                              endProfessionalAreasList={
                                professionalAreas.length
                              }
                            />
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion.Root>
                    )}
                  </Stack>
                </RStack>
              </>
            )}

            {doctorPresentation && doctorOption && (
              <>
                <Divider />
                <RStack direction="column" mt="lg">
                  <SectionHeader title={t('selectYourTp.detail.curriculum')} />

                  <Stack direction="column" spacing="xs" mb="lg">
                    <CurriculumInfo
                      variant="order"
                      doctorPresentation={doctorPresentation}
                      doctorOption={doctorOption}
                    />

                    {doctorPresentation.degree && (
                      <CurriculumInfo
                        variant="degree"
                        doctorPresentation={doctorPresentation}
                        doctorOption={doctorOption}
                      />
                    )}

                    <CurriculumInfo
                      variant="job"
                      doctorPresentation={doctorPresentation}
                      doctorOption={doctorOption}
                    />
                  </Stack>
                </RStack>
              </>
            )}

            {reviews?.length > 0 && (
              <>
                <Divider />
                <RStack direction="column" my="lg">
                  <SectionHeader
                    title={`${t(
                      'selectYourTp.detail.aboutTitle'
                    )} ${doctorOption.doctor.name.toUpperCase()}`}
                  />

                  <Stack
                    direction="column"
                    bgColor="cappuccino.50"
                    rounded="lg"
                    p="xl"
                    justify="center"
                    h="132px"
                  >
                    <ReviewWrapper h="40px">
                      <Text color="cappuccino.700" style={{ fontSize: '14px' }}>
                        {reviews[reviewIndex]?.text}
                      </Text>
                    </ReviewWrapper>

                    <Stack direction="row" mt="xl" justify="space-between">
                      <OutlinedCircle
                        justify="center"
                        align="center"
                        w="20px"
                        h="20px"
                        border="sm"
                        borderColor="candy.300"
                        onClick={() => {
                          if (reviewIndex > 0) {
                            setReviewIndex(reviewIndex - 1);
                          }
                        }}
                      >
                        <ArrowLeft size="sm" color="candy.300" />
                      </OutlinedCircle>

                      <DotsIndicator
                        activeDot={reviewIndex}
                        colorScale="dark"
                        dots={reviews.length}
                      />

                      <OutlinedCircle
                        justify="center"
                        align="center"
                        w="20px"
                        h="20px"
                        border="sm"
                        borderColor="candy.300"
                        onClick={() => {
                          if (reviewIndex < reviews.length) {
                            setReviewIndex(reviewIndex + 1);
                          }
                        }}
                      >
                        <ArrowRight size="sm" color="candy.300" />
                      </OutlinedCircle>
                    </Stack>
                  </Stack>
                </RStack>
              </>
            )}
          </InfoContent>

          <Stack direction="column">
            <Divider />

            <Stack my="sm" justify="center">
              <Button
                disabled={!doctorOption.isAvailable}
                fullWidth
                label={t('selectYourTp.choose')}
                style={{ width: '272px' }}
                onClick={onSelectTpClick}
                data-testid="PreMatch-Select-TP-Profile"
              />
            </Stack>
          </Stack>
        </RStack>
      </ModalAlert>

      {openConfirmSelection && (
        <ConfirmSelectionModal
          pendoConfirmEventId="PreMatch-Confirm-TP-Profile"
          pendoCancelEventId="PreMatch-Cancel-TP-Profile"
          onClose={onConfirmSelectionModalClose}
          doctorOption={doctorOption}
        />
      )}
    </>
  );
};
