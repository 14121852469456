import DiaryImg from '@unobravo-monorepo/patient/assets/img/Diary.svg';
import MGMImg from '@unobravo-monorepo/patient/assets/img/MGM.svg';
import {
  Box,
  Button,
  Heading,
  ModalAlert,
  RStack,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Section } from '../Section';
import { OtherAction } from './OtherAction';

type Action = {
  dataTestId: string;
  title: string;
  subtitle?: string;
  actionLabel: string;
  image: string;
  action: () => void;
};

export const OtherActions = ({ enableMgm = true }: { enableMgm?: boolean }) => {
  const { t } = useTranslation();
  const [openDiaryModal, setOpenDiaryModal] = useState(false);
  const appStoreLink =
    'https://apps.apple.com/us/app/unobravo-psicologia-online/id1624675215';
  const playStoreLink =
    'https://play.google.com/store/apps/details?id=com.unobravo.patient';

  const navigate = useNavigate();

  const actions: Action[] = [
    {
      dataTestId: 'hp-go-to-diary-card',
      title: t('home.otherActions.diary.title'),
      actionLabel: t('home.otherActions.diary.actionLabel'),
      image: DiaryImg,
      action: () => setOpenDiaryModal(true)
    }
  ];

  if (enableMgm) {
    actions.unshift({
      dataTestId: 'hp-go-to-mgm-card',
      title: t('home.otherActions.mgm.title'),
      subtitle: t('home.otherActions.mgm.subtitle'),
      actionLabel: t('home.otherActions.mgm.actionLabel'),
      image: MGMImg,
      action: () => navigate('/patient/member-get-member')
    });
  }

  return (
    <Box style={{ flex: 1 }} data-testid="other-actions-card">
      <Section title={t('home.otherActions.title')} gotoChat={false} />
      <RStack direction="column" spacing="md">
        {actions.map((action) => (
          <OtherAction key={action.title} {...action} />
        ))}
      </RStack>
      <ModalAlert
        open={!!openDiaryModal}
        onClose={() => setOpenDiaryModal(false)}
        w="500px"
      >
        <Stack direction="column" align="end" spacing="3xl">
          <Stack direction="column" spacing="2xs">
            <Heading variant="md" color="grey.900">
              {t('home.otherActions.diary.modal.title')}
            </Heading>
            <Text variant="md" color="grey.700">
              {t('home.otherActions.diary.modal.description1')}
            </Text>
            <Box pt="lg">
              <Text variant="md" color="grey.700">
                {t('home.otherActions.diary.modal.description2')}
              </Text>
            </Box>
          </Stack>
          <RStack
            spacing="xs"
            direction={{
              base: 'column',
              md: 'row'
            }}
            w={{
              base: '100%',
              md: 'auto'
            }}
          >
            <Button
              data-testid="hp-go-to-android-app-modal"
              variant="tonal"
              label={t('home.otherActions.diary.modal.androidButton')}
              onClick={() => {
                window.open(playStoreLink, '_blank');
                setOpenDiaryModal(false);
              }}
            />
            <Button
              data-testid="hp-go-to-ios-app-modal"
              variant="tonal"
              label={t('home.otherActions.diary.modal.iosButton')}
              onClick={() => {
                window.open(appStoreLink, '_blank');
                setOpenDiaryModal(false);
              }}
            />
          </RStack>
        </Stack>
      </ModalAlert>
    </Box>
  );
};
