import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from '@unobravo-monorepo/common';
import { useBreakpointValue } from '@unobravo/zenit-web';

const WrapperRightIllustration = styled.div`
  margin-left: -16px;
  position: relative;
  width: 30%;
  max-width: 400px;
  flex-grow: 0;
  flex-shrink: 1;
  ${mediaQueries('sm')(`
    display: none;
  `)};
`;

const RightIllustration = styled.div<{ illustration: string }>`
  height: 100%;
  width: 100%;
  background-image: ${({ illustration }) => `url(${illustration})`};

  background-size: cover;
  background-position: center right;
`;

interface IRightIllustation {
  illustration: string;
}

export const RightWrapperIllustration: React.FC<IRightIllustation> = ({
  illustration
}) => {
  const { index } = useBreakpointValue();

  if (index > 3) {
    return (
      <WrapperRightIllustration>
        <RightIllustration illustration={illustration} />
      </WrapperRightIllustration>
    );
  }
  return null;
};
