import { Info } from '@unobravo/zenit-icons';
import { Box, Stack, Text } from '@unobravo/zenit-web';

export const SystemMessageAlert: React.FC<{ message: string }> = ({
  message
}) => {
  return (
    <Stack bgColor="cappuccino.50" rounded="md" p="xs">
      <Box mr="sm">
        <Info width={20} height={20} color="cappuccino.800" />
      </Box>
      <Text variant="md" color="cappuccino.800">
        {message}
      </Text>
    </Stack>
  );
};
