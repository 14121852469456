import { gql, useQuery } from '@apollo/client';
import { LaunchDarklyKey } from '@unobravo/patient';

const GET_FEATURE_FLAG_BY_KEY = gql`
  query getFeatureFlagByKey($key: LaunchDarklyKey!) {
    getFeatureFlagByKey(key: $key)
  }
`;

export const useFeatureFlagByKey = (key: LaunchDarklyKey) => {
  const { data, loading, error } = useQuery(GET_FEATURE_FLAG_BY_KEY, {
    variables: { key }
  });

  return {
    value: data?.getFeatureFlagByKey as boolean | undefined,
    loading,
    error
  };
};
