import {
  AppleButton,
  GoogleButton
} from '@unobravo-monorepo/common/components/SocialProviderButton';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { useFirebaseUser } from '@unobravo/core';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { FirebaseError } from 'firebase/app';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useTrackLogin } from '../hooks/useTrackLogin';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey[200]};
`;

export const SocialLogin = () => {
  const trackLogin = useTrackLogin();

  const { loginWithGoogle, loginWithApple } = useFirebaseUser();
  const { t } = useTranslation();
  const { pushEvent } = useGTM();
  const sendToast = useToaster();

  const onLoginWithGoogle = async () => {
    try {
      const credential = await loginWithGoogle();
      pushEvent('Login_Google', { application: 'WEB' });

      trackLogin({
        variables: {
          firebaseId: credential?.user?.uid,
          providerId: 'google.com'
        }
      });
    } catch (error) {
      if (!(error instanceof FirebaseError)) return;
      if (error.code !== 'auth/cancelled-popup-request') {
        return sendToast({
          variant: 'error',
          title: t('common:errorPage.ops'),
          description: t('common:errorPage.retry')
        });
      }
    }
  };

  const onLoginWithApple = async () => {
    try {
      const credential = await loginWithApple();
      pushEvent('Login_Apple', { application: 'WEB' });

      trackLogin({
        variables: {
          firebaseId: credential?.user?.uid,
          providerId: 'apple.com'
        }
      });
    } catch (error) {
      if (!(error instanceof FirebaseError)) return;
      if (error.code === 'auth/cancelled-popup-request') {
        return sendToast({
          variant: 'error',
          title: t('common:errorPage.ops'),
          description: t('common:errorPage.retry')
        });
      }
    }
  };

  return (
    <Box>
      <Stack direction="row" py="xl" px="2xs" align="center">
        <Divider />

        <Text
          fontWeight="medium"
          variant="sm"
          color="grey.500"
          style={{ margin: '0 16px' }}
        >
          {t('common:or').toUpperCase()}
        </Text>

        <Divider />
      </Stack>

      <Stack direction="column" spacing="md">
        <GoogleButton onClick={onLoginWithGoogle} />

        <AppleButton onClick={onLoginWithApple} />
      </Stack>
    </Box>
  );
};
