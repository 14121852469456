import { Box } from '@unobravo/zenit-web';
import styled from 'styled-components';
import { Cross, Menu } from '@unobravo/zenit-icons';

const HamburgerToCrossWrapper = styled(Box)`
  @keyframes hamburgerToCross {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }

  @keyframes crossToHamburger {
    0% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open svg {
    animation: hamburgerToCross 0.8s forwards;
  }

  &.closed svg {
    animation: crossToHamburger 0.8s forwards;
  }
`;

interface IHamburgerToCross {
  isOpen: boolean;
}

export const HamburgerToCross = ({ isOpen }: IHamburgerToCross) => {
  return (
    <HamburgerToCrossWrapper
      className={`${isOpen ? 'open' : 'closed'}`}
      w={23}
      h={23}
    >
      {isOpen ? <Cross /> : <Menu />}
    </HamburgerToCrossWrapper>
  );
};
