import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { IntlRender } from '@unobravo/translations';
import { Box, RBox, RStack } from '@unobravo/zenit-web';
import { useEffect } from 'react';
import { usePatientFeatureFlags } from '../../featureFlags';
import { HomepageBundleWidget } from '../../shared/components/Bundles/HomepageBundleWidget';
import { DoctorInfoModal } from '../../shared/components/TherapySetting/Modals/DoctorInfoModal';
import { PatientLayout } from '../../shared/layouts/PatientLayout';
import { useAgenda } from '../agenda/hooks/useAgenda';
import { useBundle } from '../bundles/hooks/useBundle';
import { usePatient } from '../patientData/hooks/usePatient';
import { ConfirmedConsent } from './components/Heroes/ConfirmConsent';
import { TherapyFeedback } from './components/Heroes/TherapyFeedback';
import { WelcomeMessage } from './components/Heroes/WelcomeMessage';
import { OtherActions } from './components/OtherActions';
import { TherapyFeedbackModal } from './components/TherapyFeedbackModal';
import { TherapySettingHPCard } from './components/TherapySetting';
import { useTherapyFeedback } from './hooks/useTherapyFeedback';

const Header = () => {
  const { name: patientName, hasConfirmedConsent } = usePatient();
  const { surveyToShow } = useTherapyFeedback();
  const { qualityOfService } = usePatientFeatureFlags();
  const mustFillTherapyFeedback = surveyToShow && qualityOfService;

  const name = patientName || '';

  if (mustFillTherapyFeedback) {
    return <TherapyFeedback />;
  }

  if (!hasConfirmedConsent) {
    return <ConfirmedConsent />;
  }

  return <WelcomeMessage name={name} />;
};

export const HomePage = () => {
  const { surveyToShow } = useTherapyFeedback();
  const { qualityOfService } = usePatientFeatureFlags();
  const mustFillTherapyFeedback = surveyToShow && qualityOfService;
  const { id } = usePatient();

  const { status, loadAppointments } = useAgenda();
  const { isBundleOpened } = useBundle();

  useEffect(() => {
    loadAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientLayout>
      {status === 'loading' ? (
        <Spinner />
      ) : (
        <Box h="100%" style={{ overflow: 'auto' }}>
          <RBox bgColor="cappuccino.50" rounded={{ base: undefined, md: 'md' }}>
            <Header />
          </RBox>

          <RStack
            direction={{ base: 'column', md: 'row' }}
            px={{ base: 'md', md: undefined }}
            pt="xl"
            pb="md"
            spacing="xl"
          >
            <RStack
              direction="column"
              spacing={{ base: 'sm', md: 'md' }}
              maxW="948px"
              style={{
                flex: 1
              }}
            >
              <TherapySettingHPCard width="100%" />
              {isBundleOpened && id && <HomepageBundleWidget patientId={id} />}
            </RStack>
            <IntlRender include={['it']}>
              <OtherActions />
            </IntlRender>
          </RStack>
          <DoctorInfoModal />
          {mustFillTherapyFeedback && <TherapyFeedbackModal />}
        </Box>
      )}
    </PatientLayout>
  );
};
