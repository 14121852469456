import React from 'react';
import styled from 'styled-components';
import { Theme } from '@unobravo-monorepo/common';
import { TypographyVariant } from '@unobravo-monorepo/common/components/Typography';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 12px;
  text-align: center;
  max-width: 462px;
  width: fit-content;
  border-radius: 12px;
  background: ${Theme.colors.spinach[50]};
  margin: 0 auto;
`;

export const AccountLink = styled(Link)`
  text-decoration: underline;
  text-decoration-color: ${Theme.colors.spinach[500]};
  color: ${Theme.colors.spinach[500]};
`;

export const BonusActivatedAlert: React.FC<{
  alertText: string;
  linkText?: string;
}> = ({ linkText, alertText }) => {
  return (
    <Wrapper data-testid="bonus-activated-alert">
      <Body
        element="span"
        margin="0"
        color={Theme.colors.spinach[500]}
        variant={TypographyVariant.Regular}
        size={BodySize.Body70}
      >
        {alertText}
      </Body>
      {linkText && (
        <AccountLink to={'../preferences/bonus'}>
          <Body
            element="span"
            margin="0"
            color={Theme.colors.spinach[500]}
            variant={TypographyVariant.Regular}
            size={BodySize.Body70}
          >
            {linkText}
          </Body>
        </AccountLink>
      )}
    </Wrapper>
  );
};
