import { useColor } from '@unobravo/zenit-core';
import { Cross } from '@unobravo/zenit-icons';
import {
  ActionIcon,
  Box,
  Heading,
  RBox,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import bgVerticalImg from '@unobravo-monorepo/patient/assets/Background_cappuccino_Vertical.svg';
import { DoctorAccordionsWrapper } from '@unobravo-monorepo/patient/features/doctorPresentation/components/DoctorAccordionsWrapper';
import { toggleRightMenu } from '../../../features/layout/layout.slice';
import { ReassignmentButton } from '../../../features/reassignment/components/ReassignmentButton';
import { useDoctorInfo } from '../../hooks/useDoctorInfo';

const Img = styled.img`
  border-radius: 50%;
  box-shadow: 0px 10px 14px 0px rgba(173, 173, 163, 0.4);
  width: 140px;
  height: 140px;
  margin-bottom: 14px;
  object-fit: cover;
`;

export const DoctorInfo = () => {
  const { t } = useTranslation();
  const { name, profilePicture, therapeuticOrientation, sex, isTherapist } =
    useDoctorInfo();
  const { mode } = useParams();

  const { isMobile, index: breakpointIndex } = useBreakpointValue();
  const backgroundColor = useColor(isMobile ? 'cappuccino.200' : 'white');
  const dispatch = useDispatch();
  useEffect(() => {
    if (mode === 'doctor-info' && isMobile) {
      dispatch(toggleRightMenu());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const role = isTherapist
    ? t('chat.profiletp.therapist')
    : t(`chat.profiletp.noTherapist.${sex}`);

  const orientation = therapeuticOrientation
    ? t(`chat.profiletp.orientation`, {
        orientation: therapeuticOrientation,
        role
      })
    : '';
  return (
    <RStack
      direction="column"
      border={breakpointIndex > 2 ? 'sm' : undefined}
      borderColor="cappuccino.200"
      rounded={{ md: 'md' }}
      maxH={{ base: '100%', md: 'calc(100% - 12px)' }}
      h={isMobile ? '100%' : undefined}
      overflow="auto"
      position="relative"
    >
      {isMobile && (
        <Box position="absolute" right="22px" top="6px">
          <ActionIcon
            variant="ghost"
            onClick={() => dispatch(toggleRightMenu())}
          >
            <Cross />
          </ActionIcon>
        </Box>
      )}
      <Stack
        w="100%"
        style={
          isMobile
            ? {
                background: `url('${bgVerticalImg}')`,
                backgroundPosition: 'center top',
                backgroundSize: 'cover',
                backgroundColor
              }
            : undefined
        }
        direction="column"
        spacing="md"
        align="center"
        p="xl"
      >
        {profilePicture && (
          <Img alt="tp-profile-picture" src={profilePicture} />
        )}
        <Stack direction="column">
          <Heading variant="xl" textAlign="center">
            {name}
          </Heading>
          {orientation && (
            <Text
              textAlign="center"
              variant="md"
              lineClamp={profilePicture ? 2 : 4}
              color="grey.500"
            >
              {orientation}
            </Text>
          )}
        </Stack>
      </Stack>
      <RBox
        bgColor={isMobile ? 'cappuccino.200' : 'white'}
        w="100%"
        rounded={{ md: 'md' }}
      >
        <DoctorAccordionsWrapper />
      </RBox>
      <ReassignmentButton />
    </RStack>
  );
};
