import {
  Box,
  Button,
  Heading,
  ModalAlert,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePatient } from '../patientData/hooks/usePatient';
import { AvailabilityType } from './types/AvailabilityType';

export const AutomaticMessage = ({
  availability,
  onClose,
  onCancel,
  onConfirm
}: {
  availability: AvailabilityType[];
  onClose: () => void;
  onCancel: () => void;
  onConfirm: (message: string) => void;
}) => {
  const { t } = useTranslation();
  const { doctor } = usePatient();

  const formatDay = (date: DateTime) => date.toFormat('dd LLLL');
  const formatTime = (time: DateTime) => time.toFormat('HH:mm');

  const messageBody = useMemo(() => {
    let message = t('chat.availability.messageIntro', {
      doctorName: doctor?.name ?? ''
    });

    availability.forEach((one, index) => {
      // Date
      const startDay = one.date.start && formatDay(one.date.start);
      const endDay =
        one.date.end &&
        !one.date.start?.equals(one.date.end) &&
        formatDay(one.date.end);
      const dayMessage = endDay
        ? t('chat.availability.messageMultipleDate', { startDay, endDay })
        : t('chat.availability.messageSingleDate', { day: startDay });

      // Time
      const startTime = one.time.start && formatTime(one.time.start);
      const endTime = one.time.end && formatTime(one.time.end);
      const timeMessage = t('chat.availability.messageTime', {
        startTime,
        endTime
      });

      message = message.concat(
        dayMessage,
        timeMessage,
        index === availability.length - 1 ? '.' : '; '
      );
    });
    return message;
  }, [availability, doctor?.name, t]);

  return (
    <ModalAlert
      open
      onClose={onClose}
      w={470}
      dataTestId="availability-message-close"
    >
      <Stack direction="column" spacing="2xs" mb="md">
        <Heading>{t('chat.availability.confirmTitle')}</Heading>
        <Text color="grey.600">
          {t('chat.availability.messageDescription')}
        </Text>
      </Stack>

      <Box bgColor="cappuccino.100" rounded="md" p="md">
        <Text variant="lg" color="grey.700">
          {messageBody}
        </Text>
      </Box>

      <Stack justify="space-between" mt="2xl">
        <Button
          data-testid="availability-message-back"
          size="lg"
          variant="ghost"
          label={t('back')}
          onClick={onCancel}
        />
        <Button
          data-testid="availability-message-send"
          iconPosition="right"
          size="lg"
          label={t('chat.availability.send')}
          onClick={() => onConfirm(messageBody)}
        />
      </Stack>
    </ModalAlert>
  );
};
