import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { IAppointment } from '@unobravo/core';
import { useUpdateSessionProposal } from '@unobravo/patient';
import { z } from '@unobravo/zenit-form';
import { ModalAlert, Stack } from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useErrorHandler } from '@unobravo-monorepo/patient/shared/hooks/useErrorHandler';
import { useSession } from '@unobravo-monorepo/patient/shared/hooks/useSession';
import { patientSelector } from '../../../../../features/patientData/store/selectors';
import { HeaderBubble } from './components/HeaderBubble';
import { ModalBody, ModalVariant, formSchema } from './components/ModalBody';
import {
  getAppointmentLastProposalBody,
  getAppointmentLastProposalStatus,
  hasAppointmentLastProposal
} from '../../utils';

export const ModifyAppointmentModalV2: React.FC<{
  appointment: IAppointment;
  openModifyModal: boolean;
  variant?: ModalVariant;

  setOpenModifyModal: (value: boolean) => void;
}> = ({ appointment, openModifyModal, setOpenModifyModal }) => {
  const [sentStatus, setSentStatus] = useState(false);
  const { t } = useTranslation();
  const { sendGenericError } = useErrorHandler();
  const { sessionDate } = useSession(appointment.uuid);

  const { doctor } = useSelector(patientSelector);
  const { id: sessionId } = appointment;
  const proposaStatus = getAppointmentLastProposalStatus(appointment);
  const proposalBody = getAppointmentLastProposalBody(appointment);
  const modalVariant = proposaStatus === 'submitted' ? 'IN_PROGRESS' : 'UPDATE';

  /** Update appointment mutation */
  const { mutate: updateSessionProposal, loading: isLoading } =
    useUpdateSessionProposal();

  const {
    rawDate: appointmentDate,
    startHour: hourStart,
    endHour: hourEnd
  } = sessionDate;

  const onSubmitValues = async (values: z.infer<typeof formSchema>) => {
    pendoTrack('modify_session_modal', {
      sessionId
    });
    try {
      const { data, errors } = await updateSessionProposal({
        variables: {
          sessionId: +sessionId,
          body: values.description
        }
      });

      if (errors || !data) {
        sendGenericError();
        return;
      }

      if (hasAppointmentLastProposal(data.updateSessionProposal)) {
        const responseStatus = getAppointmentLastProposalStatus(
          data.updateSessionProposal
        );

        if (responseStatus && responseStatus === 'submitted') {
          setSentStatus(true);
        }
      }
    } catch (mutationError) {
      sendGenericError();
    }
  };
  return (
    <ModalAlert
      open={openModifyModal}
      onClose={() => {
        setOpenModifyModal(false);
        setSentStatus(false);
      }}
      w="500px"
    >
      <Stack direction="column" spacing="3xl">
        {/** BUBBLE HEADER Appointment */}
        <HeaderBubble
          hourStart={hourStart}
          hourEnd={hourEnd}
          appointmentDate={appointmentDate.toLocaleString({
            month: 'long',
            day: '2-digit',
            year: 'numeric'
          })}
          title={t('modifyAppointment.currentAppointment')}
        />
        {/** TEXT elements */}
        <ModalBody
          modalVariant={sentStatus ? 'SENT' : modalVariant}
          tpName={`${doctor?.name} ${doctor?.surname}`}
          onSubmitValues={onSubmitValues}
          isLoading={isLoading}
          proposalText={proposalBody}
        />
      </Stack>
    </ModalAlert>
  );
};
