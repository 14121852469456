import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useFirebaseUser } from '@unobravo/core';
import { zendeskRedirectLogged } from '@unobravo-monorepo/common/utils/zendeskUtils';
import { useInternationalVariables } from '@unobravo/translations';

export const GET_ZENDESK_TOKEN = gql`
  query getZendeskToken {
    getZendeskToken
  }
`;

export const useZendesk = () => {
  const [getZendeskToken] = useLazyQuery(GET_ZENDESK_TOKEN, {
    fetchPolicy: 'no-cache'
  });
  const { currentUser } = useFirebaseUser();
  const { zendeskCommunityUrl, zendeskDefaultUrl } =
    useInternationalVariables();

  const getToken = useCallback(async () => {
    const response = await getZendeskToken();
    return response?.data?.getZendeskToken;
  }, [getZendeskToken]);

  const goToZendeskAsLoggedUser = useCallback(
    async (redirectUrl?: string, target: '_blank' | '_self' = '_blank') => {
      const zendeskToken = await getToken();

      if (!zendeskToken) return;
      const isDoctor =
        currentUser.role === 'doctor' ||
        currentUser.role === 'onboarding_doctor';
      zendeskRedirectLogged(
        isDoctor ? zendeskCommunityUrl : zendeskDefaultUrl,
        zendeskToken,
        redirectUrl,
        target
      );
    },
    [currentUser.role, getToken]
  );

  return {
    goToZendeskAsLoggedUser
  };
};
