import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useFirebaseUser } from '@unobravo/core';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PaySessionWithBundleDocument } from '../../../shared/graphql/mutation.generated';
import { shouldOpenPaywall } from '../../../shared/utils/bundles';
import { BundlesVariations } from '../../../types/Bundles';
import { ListPatientBundlesDocument } from '../../home/graphql/queries.generated';
import { layoutSelector, setPaywallSeen } from '../../layout/layout.slice';
import { PaymentContext } from '../../newPaySession/types/paymentContext';
import { useToast } from '../../toaster/hooks/useToast';

type BundlesFlags = {
  bundlesVariations?: BundlesVariations;
};

export const useBundle = () => {
  const { t } = useTranslation();
  const { currentUser } = useFirebaseUser();
  const patientId = currentUser.unbvId!;
  const { bundlesVariations } = useFlags<BundlesFlags>();
  const { hasSeenPaywall } = useSelector(layoutSelector);
  const dispatch = useDispatch();

  const isBundleOpened =
    bundlesVariations &&
    bundlesVariations !== 'outside' &&
    bundlesVariations !== 'control';

  const bundleVariant = bundlesVariations;

  const [paySessionWithBundle, { loading }] = useMutation(
    PaySessionWithBundleDocument
  );
  const { data, refetch } = useQuery(ListPatientBundlesDocument, {
    variables: { patientId },
    skip: !isBundleOpened
  });
  const { sendToast } = useToast();

  const payWithBundle = async (sessionId: number) => {
    try {
      const { data: paySessionData } = await paySessionWithBundle({
        variables: { sessionId }
      });

      if (paySessionData?.paySessionWithBundle.result) {
        sendToast({
          variant: 'success',
          title: t('bundles.toast.title'),
          description: t('bundles.toast.subtitle')
        });
        refetch();
      }
      return paySessionData?.paySessionWithBundle.result || false;
    } catch (error) {
      if (error instanceof ApolloError) {
        sendToast({
          variant: 'error',
          title: t('common:errorPage.ops2'),
          description: t('common:errorPage.retry')
        });
      }
      return false;
    }
  };

  const hasBundleRemaining = useMemo(() => {
    return !shouldOpenPaywall(data);
  }, [data]);

  const canOpenPaywall = isBundleOpened && !hasBundleRemaining;

  const shouldPayWithBundle = isBundleOpened && hasBundleRemaining;

  const paywallSeen = () => {
    dispatch(setPaywallSeen());
  };

  const getPathFromContext = (context: PaymentContext) => {
    switch (context) {
      case 'AGENDA':
        return 'agenda';
      case 'CHAT':
        return 'chat';
      case 'THERAPY_SETTING':
        return 'therapy-setting';
      case 'HOME':
        return 'home';
      default:
        return 'home';
    }
  };

  const bundlesList = data?.listPatientBundles || [];

  return {
    bundleList: data,
    payWithBundle,
    isBundleOpened,
    hasBundleRemaining,
    canOpenPaywall,
    bundleVariant,
    hasSeenPaywall,
    paywallSeen,
    payLoading: loading,
    getPathFromContext,
    latestBundle: bundlesList[bundlesList.length - 1],
    shouldPayWithBundle
  };
};
