import * as RadioGroup from '@radix-ui/react-radio-group';
import { Stack, Text } from '@unobravo/zenit-web';

import React from 'react';
import styled from 'styled-components';

const RadioGroupIndicatorContainer = styled(Stack)`
  display: block;
  border: ${({ theme }) => `1.5px solid ${theme.colors.grey[500]}`};
`;

const RadioGroupIndicatorDot = styled(RadioGroup.Indicator)`
  &::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.candy[500]};
  }
`;
/** needed for data-state="checked" styling */
const RadioItem = styled(Stack)`
  cursor: pointer;
`;

const RadioItemContainer = styled(RadioGroup.Item)`
  /* RadioGroupIndicatorContainer data-state="checked" styled  */
  &[data-state='checked'] ${RadioGroupIndicatorContainer} {
    border: ${({ theme }) => `1.5px solid ${theme.colors.candy[500]}`};
  }
  /* RadioItem data-state="checked" styled */
  &[data-state='checked'] ${RadioItem} {
    border: ${({ theme }) => `1.5px solid ${theme.colors.candy[500]}`};
    outline: ${({ theme }) => `2px solid ${theme.colors.candy[100]}`};
  }
`;

export const BundleRadioItem = ({
  id,
  value,
  title,
  linethroughPrice,
  finalPriceOutcome,
  children
}: {
  id: string;
  value: string;
  title: string;
  linethroughPrice?: number;
  finalPriceOutcome?: string;
  children?: React.ReactElement;
}) => (
  <RadioItemContainer value={value} id={id} data-testid="radioItem">
    <RadioItem
      direction="column"
      p="md"
      data-testid="bundle-container"
      border="md"
      rounded="sm"
      spacing="md"
      borderColor="candy.100"
    >
      <Stack justify="space-between">
        <Stack direction="column" spacing="xs">
          <Text color="candy.600" fontWeight="semibold">
            {title}
          </Text>
          <Stack spacing="2xs" align="center">
            {linethroughPrice && (
              <Text
                color="grey.600"
                variant="sm"
                style={{
                  textDecoration: 'line-through'
                }}
              >
                {`${linethroughPrice}€`}
              </Text>
            )}
            <Text color="grey.900" variant="xl" fontWeight="bold">
              {finalPriceOutcome}
            </Text>
          </Stack>
        </Stack>
        <RadioGroupIndicatorContainer
          align="center"
          justify="center"
          rounded="full"
          bgColor="white"
          w="16px"
          h="16px"
        >
          <RadioGroupIndicatorDot />
        </RadioGroupIndicatorContainer>
      </Stack>
      {children}
    </RadioItem>
  </RadioItemContainer>
);
