import { platform } from '@unobravo-monorepo/common/providers/ApolloClientProvider';
import { ApolloSignupV2Provider } from '@unobravo-monorepo/common/providers/ApolloSignupV2Provider';
import { ApolloClientProvider, Country } from '@unobravo/core';
import { redirect, Route } from 'react-router-dom';
import { PatientActivationProvider } from '.';
import { Info } from './pages/info';
import { RegOnTopVerifyEmail } from './pages/regOnTopVerifyEmail';
import { VerifyEmail } from './pages/verifyEmail';
import { VerifyEmailError } from './pages/verifyEmailError';

const { NX_APP_APOLLO_URI: apolloUri } = process.env;

export const getActivationRoutes = (domainCountry?: Country) => {
  return (
    <>
      <Route path="/gtm" element={<></>} />
      <Route path="/activation/*" element={<PatientActivationProvider />}>
        <Route path="verify-email/:code" element={<VerifyEmail />} />
        <Route path="info" element={<Info />} />
        <Route path="expired" element={<VerifyEmailError />} />
      </Route>
      {/* #region /activation redirects */}
      <Route
        path="/patient/verify-email/:code"
        loader={({ params }) => {
          //  TODO: update BE Verify email!
          const { code } = params;
          return redirect(`/activation/verify-email/${code}`);
        }}
      />
      <Route
        path="visitor/verify-email/:code"
        element={
          <ApolloClientProvider
            uri={apolloUri!}
            application={platform}
            country={domainCountry}
          >
            <ApolloSignupV2Provider>
              <RegOnTopVerifyEmail />
            </ApolloSignupV2Provider>
          </ApolloClientProvider>
        }
      />

      <Route
        path="/signup/confirm-change/:code"
        loader={({ params }) => {
          //  TODO: update BE Confirm Change email
          const { code } = params;
          return redirect(`/activation/confirm-change/${code}`);
        }}
      />
      {/* #endregion */}
    </>
  );
};
