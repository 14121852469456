import { useMutation, useQuery } from '@apollo/client';
import { waitAtLeast } from '@unobravo-monorepo/common/utils/promise';
import { useFirebaseUser } from '@unobravo/core';
import { useMemo, useState } from 'react';
import { GenerateMemberGetMemberReferralCodeDocument } from '../graphql/mutation.generated';
import { GetMemberGetMemberReferralCodeDocument } from '../graphql/queries.generated';

export type CodeStatus =
  | 'code_generated'
  | 'not_eligible'
  | 'code_redeemed'
  | 'error_code'
  | 'generating_code'
  | 'initial';

const SIMULATED_DELAY_MS = 2000;

interface UseVoucherDataProps {
  onMutationCompleted?: () => void;
}

export const useVoucherData = ({
  onMutationCompleted
}: UseVoucherDataProps) => {
  const { currentUser } = useFirebaseUser();

  const [isSimulatingLoading, setIsSimulatingLoading] = useState(false);

  const {
    data: voucherData,
    error: queryError,
    loading: queryLoading
  } = useQuery(GetMemberGetMemberReferralCodeDocument, {
    variables: { patientId: currentUser.unbvId! }
  });

  const [generateReferralCode, { error: mutationError }] = useMutation(
    GenerateMemberGetMemberReferralCodeDocument,
    {
      // eslint-disable-next-line no-console
      onError: console.error,
      refetchQueries: [GetMemberGetMemberReferralCodeDocument],
      awaitRefetchQueries: true
    }
  );

  const codeStatus = useMemo<CodeStatus>(() => {
    if (queryError || mutationError) return 'error_code';

    if (queryLoading || isSimulatingLoading) return 'generating_code';

    const { isEligible, referralCode, rewardCode, referralCampaign } =
      voucherData?.getMemberGetMemberReferralCode ?? {};

    if (isEligible === false) return 'not_eligible';

    if (!referralCode) return 'initial';

    // Reward Code is generated after 24 hours due to legal reason
    // instead referralCode.hasBeenRedeemed is instantaneous
    if (
      referralCode?.hasBeenRedeemed &&
      rewardCode?.code &&
      referralCode?.redemptionsCount === referralCampaign?.redemptionsLimit
    )
      return 'code_redeemed';

    return 'code_generated';
  }, [
    queryError,
    mutationError,
    queryLoading,
    voucherData,
    isSimulatingLoading
  ]);

  const generatingCode = async () => {
    setIsSimulatingLoading(true);
    await waitAtLeast(
      () =>
        generateReferralCode({
          variables: { patientId: currentUser.unbvId || 0 },
          refetchQueries: [GetMemberGetMemberReferralCodeDocument],
          awaitRefetchQueries: true
        }),
      SIMULATED_DELAY_MS
    ).then(() => {
      onMutationCompleted?.();

      setIsSimulatingLoading(false);
    });
  };

  return {
    codeStatus,
    generatingCode,
    data: voucherData?.getMemberGetMemberReferralCode
  };
};
