import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePaymentUtils } from '@unobravo/patient';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { useBonus } from '../../patientData/hooks/useBonus';
import { useTranslation } from 'react-i18next';

export const useConfirmBonusSession = () => {
  const { t } = useTranslation();
  const { confirmPatientBonusSession, confirmBonusLoading } = usePaymentUtils();
  const { refetch } = useBonus();
  const { sendGenericError, sendErrorMessage } = useErrorHandler();

  const confirmBonusSession = async (sessionId: string) => {
    const result = await confirmPatientBonusSession(sessionId);

    if (result) {
      refetch();
      pendoTrack('bonus_session_confirmed', {
        sessionId
      });
      return true;
    }

    if (result === false) {
      sendErrorMessage(
        t('bonus.errorAmountInsufficient'),
        t('bonus.errorInsufficientTitle')
      );
      return false;
    }

    sendGenericError();
    return false;
  };

  return {
    confirmBonusSession,
    loading: confirmBonusLoading
  };
};
