import FlowerImg from '@unobravo-monorepo/patient/assets/img/Flower.svg';
import { AppointmentCardSkeleton } from '@unobravo-monorepo/patient/shared/components/TherapySetting/AppointmentBubble/AppointmentCardSkeleton';
import {
  Box,
  Link,
  RBox,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useMemo } from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { sortByDate } from '@unobravo/core';
import { useDispatch } from 'react-redux';
import { openReassignmentModal } from '@unobravo-monorepo/patient/features/reassignment/reassignment.slice';
import { usePatient } from '@unobravo-monorepo/patient/features/patientData/hooks/usePatient';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { useDoctorInfo } from '../../../../shared/hooks/useDoctorInfo';
import { useAgenda } from '../../../agenda/hooks/useAgenda';
import { filterSessionsByStatus } from '../../utils/session';
import { AppointmentCard } from '../AppointmentCard';
import { CardContainer as BubbleContainer } from '../CardContainer';
import { CardContainer } from './CardContainer';
import { Section } from './Section';
import { useBundle } from '../../../bundles/hooks/useBundle';
import { NoAppointmentCardBubble } from '../NoAppointmentCardBubble';

const CARD_MAX_WIDTH = '740px';

const Img = styled.img`
  border-radius: 50%;
  box-shadow: 0px 10px 14px 0px rgba(173, 173, 163, 0.4);
  width: 50px;
  height: 50px;
  margin-bottom: 14px;
  object-fit: cover;
`;

const BackgroundContainer = styled(Box)`
  align-items: center;
  background-image: url(${FlowerImg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 217px;
  width: 108px;
`;

export const TherapySettingCard: React.FC<{
  width?: string | number;
  onShowDoctorInfo: () => void;
}> = ({ onShowDoctorInfo, width }) => {
  const { name, profilePicture } = useDoctorInfo();
  const { nextSessions, pastSessions, status } = useAgenda();
  const { isMobile } = useBreakpointValue();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loading = status === 'loading';
  const { shouldPayWithBundle } = useBundle();
  const dispatch = useDispatch();
  const location = useLocation();
  const { reassignConditions } = usePatient();

  /**
   * Compute a session to show
   * cases:
   * 1. Past videocall already active, if there is no nextSessions
   * 2. First next appointment - skipping the recently finished videocall
   * 3. First next appointment, if there is no #2
   */
  const sessionToShow = useMemo(() => {
    const nextNotCanceled = nextSessions
      .filter((session) => !session.canceled)
      .sort((elm, prev) => sortByDate(elm.when, prev.when)); // from newest to oldest

    // case#1
    // Special case where you not have next appointments, the session has passed but you can still connect to the videocall
    if (nextNotCanceled.length === 0) {
      return pastSessions?.find((session) =>
        filterSessionsByStatus({
          session,
          statuses: ['VIDEOCALL']
        })
      );
    }
    // case: #2 && #3
    return (
      nextNotCanceled.find(
        (elm) =>
          DateTime.fromISO(elm.when).plus({ minutes: 50 }) > DateTime.now()
      ) || nextNotCanceled[0]
    );
  }, [pastSessions, nextSessions]);

  const isModifyRequestPending =
    sessionToShow?.sessionsUpdateProposals?.[0]?.status === 'submitted';

  const handleClick = () => {
    if (reassignConditions?.canPerformSmoothReassign) {
      pendoTrack('start-reassignment-education-hp');
      return navigate(`../../dialog/reassignmentModal`, {
        state: { modal: location }
      });
    }

    pendoTrack('start-reassignment-hp');
    return dispatch(openReassignmentModal());
  };

  const getCardTitle = () => {
    if (isModifyRequestPending)
      return `${t('therapySetting.hpCard.firstNextAppointment')} ${t(
        'therapySetting.hpCard.appointmentModifyRunning'
      )}`;
    if (sessionToShow?.sessionState === 'PENDING') {
      return t('therapySetting.hpCard.firstNextAppointment');
    }
    return t('therapySetting.hpCard.nextAppointment');
  };

  return (
    <>
      <RBox
        w={
          width ?? {
            base: '100%',
            sm: '50%'
          }
        }
        maxW={
          width ?? {
            base: '100%',
            md: CARD_MAX_WIDTH
          }
        }
        data-testid="therapy-setting-card"
      >
        <Section title={t('therapySetting.hpCard.title')} />
        <Box position="relative">
          {!isMobile && (
            <Box position="absolute" bottom={48} right={0}>
              <BackgroundContainer />
            </Box>
          )}
          <CardContainer
            HeaderCard={
              <Stack spacing="md">
                {profilePicture && (
                  <Img
                    alt="tp-profile-picture"
                    src={profilePicture}
                    onClick={onShowDoctorInfo}
                    style={{ cursor: 'pointer' }}
                  />
                )}
                <Stack direction="column" spacing="2xs">
                  <Text fontWeight="semibold" variant="lg">
                    {name}
                  </Text>
                  {(reassignConditions?.canPerformSmoothReassign ||
                    reassignConditions?.automatic) && (
                    <Link to={handleClick} underline={false} color="candy.600">
                      <Text fontWeight="semibold">
                        {t('reassignment.button')}
                      </Text>
                    </Link>
                  )}
                </Stack>
              </Stack>
            }
          >
            <Box style={{ zIndex: 1 }} mt="md">
              <Text color="grey.600" variant="md">
                {getCardTitle()}
              </Text>
              {sessionToShow ? (
                <>
                  <AppointmentCard appointment={sessionToShow} />
                  {!sessionToShow.isFree &&
                    !sessionToShow.paid &&
                    shouldPayWithBundle && (
                      <Box pt="sm">
                        <Text color="grey.600" variant="sm">
                          {t('therapySetting.hpCard.payWithBundleNote')}
                        </Text>
                      </Box>
                    )}
                </>
              ) : loading ? (
                <BubbleContainer testId="bubble-loading-skeleton">
                  <AppointmentCardSkeleton />
                </BubbleContainer>
              ) : (
                <NoAppointmentCardBubble isRestWeek={false} />
              )}
            </Box>
          </CardContainer>
        </Box>
      </RBox>
    </>
  );
};
