/**
 * Usage: getQueryParam<number>('current_page', 1)
 *
 * ADDING A GENERIC WITHOUT SPECIFYING THE DEFAULT VALUE WILL HAVE NO EFFECT
 */
export function getQueryParam<T>(key: string, defaultValue: T): T {
  const params = new URLSearchParams(window.location.search);

  const value = params.get(key);

  if (!value) {
    return defaultValue;
  }

  if (typeof defaultValue === 'number') {
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue)) {
      return defaultValue;
    }
    return parsedValue as T;
  }
  if (typeof defaultValue === 'boolean') {
    return (value === 'true') as T;
  }
  if (typeof defaultValue === 'object') {
    return JSON.parse(value as string) as T;
  }
  return value as unknown as T;
}

/**
 * @param key the key to set
 * @param value the value to set
 * @param type push or replace, default is push
 */
export function setQueryParam(
  key: string,
  value: string,
  type: 'replace' | 'push' = 'push'
) {
  const params = new URLSearchParams(window.location.search);

  params.set(key, value);

  if (type === 'push') {
    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    );
  } else {
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    );
  }
}
