import { Checkbox } from '@unobravo-monorepo/common/components/Checkbox';
import { PatientForWhomPref } from '@unobravo/patient';
import { useTheme } from '@unobravo/zenit-core';
import {
  ActionIcon,
  Box,
  Button,
  Heading,
  Modal,
  Stack
} from '@unobravo/zenit-web';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowRight, Cross } from '@unobravo/zenit-icons';
import { ModalStepper } from '../../../../shared/components/Stepper/ModalStepper';
import { usePatient } from '../../../patientData/hooks/usePatient';
import { Completed } from './Completed';
import { ConsentFormStep } from './ConsentFormStep';
import { InformedConsentText } from './InformedConsentText';
import { RecapConsent } from './RecapConsent';

interface IConfirmConsentStep {
  nextStep: () => void;
  prevStep: () => void;
  confirmedConsent: boolean;
  setConfirmedConsent: Dispatch<SetStateAction<boolean>>;
}

const ConfirmConsentStep: React.FC<IConfirmConsentStep> = ({
  nextStep,
  prevStep,
  confirmedConsent,
  setConfirmedConsent
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [checkError, setCheckError] = useState(false);

  const confirmConsent = () => {
    if (!confirmedConsent) {
      setCheckError(true);
      return;
    }
    nextStep();
  };

  useEffect(() => {
    if (confirmedConsent) {
      setCheckError(false);
    }
  }, [confirmedConsent]);

  return (
    <Stack
      justify="space-between"
      direction="column"
      data-testid="consent-text-wrapper"
      grow
    >
      <Box mb="lg">
        <InformedConsentText />
      </Box>
      <Box
        bgColor="white"
        style={{
          borderTop: `1px solid ${colors.grey[100]}`
        }}
        pt="xl"
        pb="xl"
        position="sticky"
        bottom={0}
      >
        <Checkbox
          onChange={() => setConfirmedConsent((prev) => !prev)}
          id="confirm-consent-footer"
          label={t('legal:informedConsent.signModal.declaration')}
          checked={confirmedConsent}
          errorMessage={
            checkError
              ? t(`legal:informedConsent.signModal.checkError`)
              : undefined
          }
          data-testid="confirm-consent-footer"
        />
        <Stack
          justify="space-between"
          align="center"
          pt={checkError ? '2xl' : '2xs'}
        >
          <Button
            label={t('common:back')}
            onClick={prevStep}
            variant="ghost"
            size="lg"
          />
          <Button
            data-testid="confirm-consent-button"
            label={t('common:buttonNext')}
            variant="filled"
            onClick={confirmConsent}
            icon={ArrowRight}
            size="lg"
            iconPosition="right"
          />
        </Stack>
      </Box>
    </Stack>
  );
};

interface IConsentFormModal {
  openModal: boolean;
  closeModal?: () => void;
  context: 'MOBILE' | 'WEBAPP';
}

export const ConsentFormModal = ({
  openModal,
  closeModal,
  context
}: IConsentFormModal) => {
  const [activeStep, setActiveStep] = useState<1 | 2 | 3 | 4>(1);
  const { t } = useTranslation();
  const { prefForWhom = PatientForWhomPref.Me } = usePatient();
  const [confirmedConsent, setConfirmedConsent] = useState(false);
  const [modifyState, setModifyState] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const modifyBack = () => {
    setActiveStep(3);
    setModifyState(false);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, [activeStep]);

  const Content = (
    <Box h="100%">
      {activeStep === 4 ? (
        <Completed />
      ) : (
        <>
          <Stack
            p="xl"
            position="sticky"
            top={0}
            h={70}
            bgColor="white"
            style={{ zIndex: 50 }}
          >
            <ModalStepper
              stepsLabels={[
                {
                  labelI18nKey: 'legal:informedConsent.stepper.personalInfo',
                  status: activeStep === 1 ? 'active' : 'completed',
                  id: 'PERSONAL_INFO'
                },
                {
                  labelI18nKey: 'legal:informedConsent.stepper.informedConsent',
                  id: 'INFORMED_CONSENT',
                  status:
                    activeStep === 2
                      ? 'active'
                      : confirmedConsent
                      ? 'completed'
                      : 'inactive'
                },
                {
                  labelI18nKey: 'legal:informedConsent.stepper.confirmConsent',
                  id: 'CONFIRM_CONSENT',
                  status: activeStep === 3 ? 'active' : 'inactive'
                }
              ]}
            />
          </Stack>

          <Stack
            direction="column"
            h="calc(100% - 70px)"
            pl="xl"
            pr="xl"
            ref={divRef}
          >
            <Box mb="2xs">
              <Heading fontWeight="semibold" variant="2xs" color="candy.500">
                {t(
                  `legal:informedConsent.signModal.therapyTitle.${
                    activeStep === 3 ? 'confirm' : prefForWhom.toLowerCase()
                  }`
                ).toUpperCase()}
              </Heading>
            </Box>
            <Box mb="xs">
              <Heading variant="lg">
                {t('legal:informedConsent.signModal.title')}
              </Heading>
            </Box>
            {activeStep === 1 && (
              <ConsentFormStep
                closeModal={modifyState ? modifyBack : closeModal}
                nextStep={modifyState ? modifyBack : () => setActiveStep(2)}
                modify={modifyState}
              />
            )}
            {activeStep === 2 && (
              <ConfirmConsentStep
                nextStep={() => setActiveStep(3)}
                prevStep={() => setActiveStep(1)}
                confirmedConsent={confirmedConsent}
                setConfirmedConsent={setConfirmedConsent}
              />
            )}
            {activeStep === 3 && (
              <RecapConsent
                onConfirm={closeModal || (() => setActiveStep(4))}
                prevStep={() => setActiveStep(2)}
                modifyData={() => {
                  setActiveStep(1);
                  setModifyState(true);
                }}
              />
            )}
          </Stack>
        </>
      )}
    </Box>
  );

  return context === 'MOBILE' ? (
    <>{Content}</>
  ) : (
    <Modal open={openModal} w={600} h={760} data-testid="consent-modal">
      <Box
        position="sticky"
        top={0}
        right={0}
        pr="xs"
        pt="xs"
        h={70}
        bgColor="white"
        style={{ zIndex: 100, float: 'right' }}
      >
        <ActionIcon
          data-testid="tp-modal-close"
          variant="ghost"
          type="button"
          onClick={closeModal}
        >
          <Cross />
        </ActionIcon>
      </Box>
      {Content}
    </Modal>
  );
};
