import React from 'react';
import { Theme } from '../../theme';
import IIcon from '../../types/IIcon';

export const Error: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.772 14.0001C20.3376 15.9062 19.2942 17.619 17.7998 18.8795C16.3054 20.14 14.4412 20.8797 12.4891 20.9865C10.537 21.0934 8.60323 20.5618 6.98014 19.472C5.35704 18.3822 4.13295 16.7935 3.49296 14.9462C2.85297 13.0989 2.83191 11.0935 3.43294 9.23312C4.03397 7.37278 5.22442 5.75874 6.82426 4.63508C8.4241 3.51142 10.3463 2.93926 12.3002 3.00511C14.2541 3.07096 16.1335 3.77125 17.654 5.00008"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0031 8.00012L18.0031 11.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0031 8.00012L21.0031 11.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0031 11.669V8.40002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.003 15.2781C11.9818 15.2781 11.9609 15.2823 11.9413 15.2905C11.9218 15.2987 11.9041 15.3106 11.8892 15.3257C11.8743 15.3408 11.8626 15.3586 11.8546 15.3783C11.8467 15.3979 11.8428 15.4189 11.843 15.4401C11.8438 15.4823 11.8609 15.5226 11.8907 15.5524C11.9206 15.5822 11.9608 15.5994 12.003 15.6001C12.046 15.6001 12.0872 15.5831 12.1176 15.5527C12.148 15.5223 12.165 15.4811 12.165 15.4381C12.165 15.3952 12.148 15.354 12.1176 15.3236C12.0872 15.2932 12.046 15.2761 12.003 15.2761"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
