import { useMutation } from '@apollo/client';
import {
  ASSOCIATE_PATIENT_TO_DOCTOR,
  GET_PATIENT_FROM_FIREBASE_ID,
  REASSIGNMENT,
  resetChat
} from '@unobravo/patient';
import { ResetSurveyDocument } from '@unobravo/signup';
import { useBreakpointValue } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useToast } from '../../toaster/hooks/useToast';
import { closeTimeSlotModal } from '../reassignment.slice';

export const useReassignment = () => {
  const [reassignPatientToDoctor, { loading: reassignmentLoading }] =
    useMutation(REASSIGNMENT);
  const { sendErrorMessage } = useErrorHandler();
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const { sendToast } = useToast();
  const { id, refetch: refetchPatient } = usePatient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    associateDoctorAction,
    { data: patientData, loading: associationLoading }
  ] = useMutation(ASSOCIATE_PATIENT_TO_DOCTOR);
  const { state } = useLocation();
  const [resetSurveyMutation, { loading: resetLoading }] = useMutation(
    ResetSurveyDocument,
    {
      refetchQueries: [GET_PATIENT_FROM_FIREBASE_ID],
      awaitRefetchQueries: true
    }
  );
  const previousLocation = state ? state.modal : '/patient/chat';

  const reassign = async (patientId: number) => {
    const res = await reassignPatientToDoctor({ variables: { patientId } });
    return res.data.reassign;
  };

  const closeModal = () => {
    navigate(previousLocation);
    dispatch(closeTimeSlotModal());
  };

  const goToSurvey = () => {
    navigate('/patient/new-survey');
    dispatch(closeTimeSlotModal());
  };

  const automaticReassign = async () => {
    try {
      if (id) {
        const reassigned = await reassign(id);
        const patient = await refetchPatient();

        if (!reassigned && patient?.doctor) {
          closeModal();
          sendErrorMessage(t('reassignment.errorDescription'));
          return;
        }

        dispatch(resetChat());
        if (patient?.doctor) {
          sendToast({
            variant: 'success',
            title: t('reassignment.successToastTitle'),
            ...(!isMobile
              ? { description: t('reassignment.successToastDescription') }
              : {})
          });
        }

        navigate('/patient/chat/doctor-info');
        dispatch(closeTimeSlotModal());
      }
    } catch {
      closeModal();
      sendErrorMessage(t('reassignment.errorDescription'));
    }
  };

  return {
    reassign,
    reassignmentLoading,
    associationLoading,
    patientData,
    associateDoctor: associateDoctorAction,
    resetSurvey: resetSurveyMutation,
    resetLoading,
    closeModal,
    automaticReassign,
    goToSurvey
  };
};
