import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useApolloSignupV2Client } from '@unobravo-monorepo/common/hooks/useApolloSignupV2Client';
import { ConfirmActivationDocument } from '../graphql/mutation.visitor.generated';

export const useActivationRegOnTop = () => {
  const client = useApolloSignupV2Client();
  const [confirmUserRegOnTop] = useMutation(ConfirmActivationDocument, {
    client
  });

  const verify = async (code: string) => {
    try {
      return await confirmUserRegOnTop({ variables: { token: code } });
    } catch (error) {
      captureException(error);
      return null;
    }
  };

  return { verify } as const;
};
