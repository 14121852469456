import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { countriesMap } from '@unobravo/utils';
import {
  IBillingData,
  setBillingInfo,
  useBillingInfoUtils
} from '@unobravo/patient';
import { useEffect } from 'react';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { billingSelector } from '../store/selectors';
import { patientSelector } from '../../patientData/store/selectors';

const countries = countriesMap as Record<string, string>;

export const useBillingInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const billingInfo = useSelector(billingSelector);
  const { id } = useSelector(patientSelector);
  const { sendErrorMessage } = useErrorHandler();
  const {
    loadBillingInfo,
    updatePatientBilling,
    createPatientBilling,
    billingInfoData,
    loading
  } = useBillingInfoUtils();

  const loaded = !!billingInfo?.billingInfoId;

  useEffect(() => {
    if (!loaded && !!id) {
      loadBillingInfo(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loaded]);

  const updateBilling = async (data: IBillingData) => {
    if (!id) {
      return false;
    }
    const billingData = {
      ...data,
      country: countries[data.countryIsoCode!]
    };
    const updatePatientBillingInfo = await updatePatientBilling(
      billingData,
      id
    );
    if (updatePatientBillingInfo) {
      dispatch(setBillingInfo({ ...updatePatientBillingInfo }));
      return true;
    }
    sendErrorMessage(t('billingInfo.saveError'));
    return false;
  };

  const createBillingInfo = async (data: IBillingData) => {
    if (!id) {
      return false;
    }
    const billingData = {
      ...data,
      country: countries[data.countryIsoCode!]
    };
    const createPatientBillingInfo = await createPatientBilling(
      billingData,
      id
    );
    if (createPatientBillingInfo) {
      dispatch(setBillingInfo({ ...createPatientBillingInfo }));
      return true;
    }
    sendErrorMessage(t('billingInfo.saveError'));
    return false;
  };

  useEffect(() => {
    if (billingInfoData) {
      dispatch(setBillingInfo({ ...billingInfoData }));
    }
  }, [billingInfoData, dispatch]);

  return {
    ...billingInfo,
    loadBillingInfo,
    loading,
    updateBilling,
    createBillingInfo
  };
};
