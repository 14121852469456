import { Color } from '@unobravo/zenit-core';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

const variantMap = {
  submitted: {
    title: 'chat.modifyAppointmentBox.submittedTitle',
    bgColor: 'ginger.50',
    borderColor: 'ginger.200',
    textColor: 'ginger.800'
  },
  accepted: {
    title: 'chat.modifyAppointmentBox.acceptedTitle',
    bgColor: 'edamame.50',
    borderColor: 'edamame.200',
    textColor: 'grey.800'
  },
  declined: {
    title: '-',
    bgColor: 'edamame.50',
    borderColor: 'edamame.200',
    textColor: 'grey.800'
  }
};

export const ModifyAppointmentBox = ({
  variant = 'submitted',
  body
}: {
  variant: 'submitted' | 'accepted' | 'declined';
  body: string;
}) => {
  const variantObj = variantMap[variant];
  const { t } = useTranslation();

  return (
    <Stack
      w="100%"
      bgColor={variantObj.bgColor as Color}
      borderColor={variantObj.borderColor as Color}
      minH="50px"
      roundedBottom="sm"
      spacing="sm"
      direction="column"
      position="relative"
      p="md"
    >
      <Text
        fontWeight="semibold"
        variant="md"
        color={variantObj.textColor as Color}
      >
        {t(variantObj.title)}
      </Text>
      <Box>
        <Text
          fontWeight="semibold"
          variant="sm"
          color={variantObj.textColor as Color}
        >
          {t('chat.modifyAppointmentBox.yourProposal')}
        </Text>
        <Text variant="sm" color="grey.800">
          “{body}”
        </Text>
      </Box>
    </Stack>
  );
};
