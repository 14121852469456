import { IAppointment } from '@unobravo/core';

export const getAppointmentLastProposalStatus = (appointment: IAppointment) =>
  appointment?.sessionsUpdateProposals?.[0]?.status;

export const getAppointmentLastProposalBody = (appointment: IAppointment) =>
  appointment?.sessionsUpdateProposals?.[0]?.body || '';

export const hasAppointmentLastProposal = (appointment: IAppointment) =>
  appointment?.sessionsUpdateProposals?.length;
