import { useMutation } from '@apollo/client';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { SelectDoctorFromPreMatchDocument } from '@unobravo-monorepo/common/graphql/mutation.generated';
import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { useFirebaseUser } from '@unobravo/core';
import { Box, ModalAlert, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePatient } from '../../patientData/hooks/usePatient';

interface ConfirmSelectionModalProps {
  onClose: () => void;
  doctorOption: PreMatchOptionFragment;
  pendoConfirmEventId: string;
  pendoCancelEventId: string;
}

export const ConfirmSelectionModal = ({
  onClose,
  doctorOption,
  pendoCancelEventId,
  pendoConfirmEventId
}: ConfirmSelectionModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const doctorName = doctorOption.doctor.name;

  const sendToast = useToaster();

  const [selectDoctorFromPreMatch, { loading }] = useMutation(
    SelectDoctorFromPreMatchDocument
  );

  const { refetch: refetchPatient } = usePatient();

  const { currentUser } = useFirebaseUser();

  const selectTp = async () => {
    await selectDoctorFromPreMatch({
      variables: {
        patientId: currentUser.unbvId || 0,
        options: {
          doctorId: +doctorOption.doctorId,
          randomChoice: false
        }
      }
    }).catch(() => {
      sendToast({
        variant: 'error',
        title: t('common:errorPage.ops'),
        description: t('common:errorPage.retry')
      });

      navigate(-1);
    });

    await refetchPatient();

    navigate(`/patient/after-selection?name=${doctorName}`);
  };

  return (
    <ModalAlert
      open
      onClose={onClose}
      confirmButtonLabel={t('selectYourTp.detail.confirmTp.confirm')}
      cancelButtonLabel={t('common:cancel')}
      title={t('selectYourTp.detail.confirmTp.title', { doctorName })}
      onConfirmClick={selectTp}
      dataTestIdConfirmButton={pendoConfirmEventId}
      dataTestIdCancelButton={pendoCancelEventId}
      dataTestIdCloseIcon={pendoCancelEventId}
      onCancelClick={onClose}
    >
      {!loading && (
        <Text
          variant="md"
          fontWeight="normal"
          color="grey.600"
          style={{ marginTop: '4px' }}
        >
          {t('selectYourTp.detail.confirmTp.message')}
        </Text>
      )}

      {loading && (
        <Box h="80px">
          <Spinner />
        </Box>
      )}
    </ModalAlert>
  );
};
