import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { mediaQueries, Theme } from '../theme';
import { Header } from '../components/Header';

const Container = styled.main<{ backgrounds: ITouchPointLayout }>`
  background: ${Theme.specialColors.white};
  overflow: auto;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${({
    backgrounds: { topLeft, topRight, bottomRight, bottomLeft }
  }) => {
    const backgrounds = [];
    topLeft && backgrounds.push(`url(${topLeft})`);
    topRight && backgrounds.push(`url(${topRight})`);
    bottomRight && backgrounds.push(`url(${bottomRight})`);
    bottomLeft && backgrounds.push(`url(${bottomLeft})`);
    return backgrounds.join(',');
  }};
  background-repeat: no-repeat;
  background-position: ${({
    backgrounds: { topLeft, topRight, bottomRight, bottomLeft }
  }) => {
    const backgrounds = [];
    topLeft && backgrounds.push(`left top`);
    topRight && backgrounds.push(`right top`);
    bottomRight && backgrounds.push(`right bottom`);
    bottomLeft && backgrounds.push(`left bottom`);
    return backgrounds.join(',');
  }};
  background-size: 320px;
  ${mediaQueries('md')(`     
    background-size: 160px;
    top: 50px;
  `)}
`;

const Content = styled.div`
  margin: 0 auto;
  display: flex;
  width: 720px;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaQueries('md')`      
     width: 100%;     
     padding: 20px;
  `}

  ${mediaQueries('sm')(`
    padding: 0px 20px;
  `)};

  ${mediaQueries('xs')(`
    padding: 0px 15px;
  `)};
`;

interface ITouchPointLayout {
  topLeft?: string | null;
  bottomLeft?: string | null;
  topRight?: string | null;
  bottomRight?: string | null;
  children?: ReactNode;
}

export const TouchPointLayout: React.FC<ITouchPointLayout> = ({
  topLeft = null,
  topRight = null,
  bottomRight = null,
  bottomLeft = null,
  children
}) => {
  return (
    <>
      <Header />
      <Container
        backgrounds={{ topLeft, topRight, bottomRight, bottomLeft }}
        data-testid="layout"
      >
        <Content>{children}</Content>
      </Container>
    </>
  );
};
