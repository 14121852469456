import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import {
  Header,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE
} from '@unobravo-monorepo/common/components/Header';
import { motion } from 'framer-motion';

const Container = styled.div`
  padding-top: 20px;
  background: ${Theme.specialColors.white};
  height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries('md')(`
		 height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
     padding-top: 0;
  `)}
`;

const Content = styled(motion.div)`
  width: 50vw;
  max-width: 490px;
  border-radius: ${Theme.borderRadius};
  box-shadow: rgb(255 179 128 / 20%) 0px 0px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  overflow: hidden;
  box-sizing: content-box;
  ${mediaQueries('md')`
     box-shadow: none;
		 width: 100%;
     padding: 20px;
  `};
`;

interface AnimateChangeInHeightProps {
  children: React.ReactNode;
  onAnimationComplete?: () => void;
  onAnimationStart?: () => void;
  duration: number;
}

export const AnimateChangeInHeight: React.FC<AnimateChangeInHeightProps> = ({
  children,
  onAnimationComplete,
  onAnimationStart,
  duration
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, []);

  return (
    <Content
      style={{ height }}
      animate={{ height }}
      transition={{ duration }}
      onAnimationComplete={onAnimationComplete}
      onAnimationStart={onAnimationStart}
    >
      <div ref={containerRef}>{children}</div>
    </Content>
  );
};

export const AuthLayout: React.FC<{
  children?: ReactNode;
  onAnimationComplete?: () => void;
  onAnimationStart?: () => void;
  duration?: number;
  animateHeight?: boolean;
}> = ({
  children,
  onAnimationComplete,
  onAnimationStart,
  duration = 0.3,
  animateHeight = false
}) => {
  return (
    <>
      <Header />
      <Container data-testid="layout">
        {!animateHeight ? (
          <Content>{children}</Content>
        ) : (
          <AnimateChangeInHeight
            duration={duration}
            onAnimationComplete={onAnimationComplete}
            onAnimationStart={onAnimationStart}
          >
            {children}
          </AnimateChangeInHeight>
        )}
      </Container>
    </>
  );
};
