import { Stack, Text } from '@unobravo/zenit-web';

interface IHeaderBubble {
  title: string;
  appointmentDate: string;
  hourStart: string;
  hourEnd: string;
}

export const HeaderBubble: React.FC<IHeaderBubble> = ({
  appointmentDate,
  hourStart,
  hourEnd,
  title
}) => (
  <Stack
    data-testid="card-header"
    direction="column"
    spacing="sm"
    rounded="lg"
    bgColor="cappuccino.50"
    p="sm"
    mt="sm"
  >
    <Text color="grey.600" variant="md">
      {title}
    </Text>
    <Stack direction="row" spacing="xs" align="center">
      <Text
        variant="md"
        fontWeight="semibold"
        style={{
          textTransform: 'capitalize'
        }}
        color="grey.800"
      >
        {appointmentDate}
      </Text>
      <Text variant="md" color="candy.500">
        •
      </Text>
      <Text variant="md" color="grey.800">
        {hourStart} - {hourEnd}
      </Text>
    </Stack>
  </Stack>
);
