import React from 'react';
import styled from 'styled-components';
import { TypographyVariant } from '@unobravo-monorepo/common/components/Typography';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@unobravo-monorepo/common/components/Icons/ArrowLeft';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { PageContainerHeader } from '@unobravo-monorepo/common/components/PageContainer';
import { Theme } from '@unobravo-monorepo/common';

const Header = styled.div`
  align-items: center;
  background-color: ${Theme.colors.cappuccino[50]};
  border-bottom: 1px solid ${Theme.colors.cappuccino[200]};
  display: flex;
  justify-content: stretch;
  height: 50px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  padding: 0 20px;
  flex-shrink: 0;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

interface IsectionHeaderProps {
  path: string;
  title: string;
}

export const SectionHeader: React.FC<IsectionHeaderProps> = ({
  path,
  title
}: IsectionHeaderProps) => {
  const { isMobile } = useViewport();

  const navigate = useNavigate();

  return (
    <>
      <PageContainerHeader>
        <Body
          size={BodySize.Body80}
          variant={TypographyVariant.SemiBold}
          textAlign="center"
          margin={isMobile ? '0 auto' : '0 0 0 10px'}
        >
          {title}
        </Body>
      </PageContainerHeader>
      {isMobile && (
        <Header>
          <IconWrapper onClick={() => navigate(`${path}`)}>
            <ArrowLeft color={Theme.colors.gray[900]} />
          </IconWrapper>
          <Body
            size={BodySize.Body80}
            variant={TypographyVariant.SemiBold}
            textAlign="center"
            margin={isMobile ? '0 auto' : '0'}
          >
            {title}
          </Body>
        </Header>
      )}
    </>
  );
};
