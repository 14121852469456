import { useTheme } from '@unobravo/zenit-core';
import { useResponsiveValue } from '@unobravo/zenit-web';
import styled from 'styled-components';
import { Logo } from '../Logo';

export const HEADER_HEIGHT_DESKTOP = 61;
export const HEADER_HEIGHT_MOBILE = 40;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ResponsiveHeaderSizes {
  marginTop: number;
  height: number;
  logoWidth: number;
  logoHeight: number;
}

const useComponentValues = () => {
  const { margin } = useTheme();

  return useResponsiveValue<ResponsiveHeaderSizes>({
    xs: {
      marginTop: margin.md,
      height: HEADER_HEIGHT_MOBILE,
      logoWidth: 124,
      logoHeight: 26
    },
    sm: {
      marginTop: margin.md,
      height: HEADER_HEIGHT_MOBILE,
      logoWidth: 124,
      logoHeight: 26
    },
    md: {
      marginTop: margin.md,
      height: HEADER_HEIGHT_MOBILE,
      logoWidth: 124,
      logoHeight: 26
    },
    lg: {
      marginTop: margin['2xl'],
      height: HEADER_HEIGHT_DESKTOP,
      logoWidth: 187,
      logoHeight: 40
    },
    xl: {
      marginTop: margin['2xl'],
      height: HEADER_HEIGHT_DESKTOP,
      logoWidth: 187,
      logoHeight: 40
    }
  });
};

export const LayoutHeader = () => {
  const { marginTop, height, logoWidth, logoHeight } = useComponentValues();

  return (
    <StyledHeader
      data-testid="header"
      style={{
        marginTop: `${marginTop}px`,
        height: `${height}px`
      }}
    >
      <Logo width={logoWidth} height={logoHeight} />
    </StyledHeader>
  );
};
