import { theme as zenitTheme } from '@unobravo/zenit-core';
import ITheme from './types/ITheme';

/**
 * @deprecated This theme should not be used use ${theme} on styled-components or get the theme with useTheme from "@unobravo/zenit-core" instead.
 */
export const Theme: ITheme = {
  colors: {
    gray: zenitTheme.colors.grey,
    sand: zenitTheme.colors.sand,
    candy: {
      ...zenitTheme.colors.candy,
      GRADIENT: zenitTheme.colors.gradient.primary
    },
    red: zenitTheme.colors.ribes,
    sunset: {
      50: '#FFEEEB',
      100: '#FFDCD6',
      200: '#FFB9AD',
      300: '#FF9684',
      400: '#FF735B',
      500: '#FF5032',
      600: '#BF3C26',
      700: '#99301e',
      800: '#662014',
      900: '#33100a'
    },
    ginger: zenitTheme.colors.ginger,
    spinach: {
      ...zenitTheme.colors.spinach,
      GRADIENT: 'linear-gradient(87.6deg, #51A08C -3.69%, #25886F 61.31%)'
    },
    edamame: zenitTheme.colors.edamame,
    makeup: {
      50: '#FDFAF9',
      100: '#F9F3F1',
      200: '#F3E7E4',
      300: '#EDDCD6',
      400: '#E7D0C9',
      500: '#E1C4BB',
      600: '#B49D96',
      700: '#877670',
      800: '#5A4E4B',
      900: '#2D2725'
    },
    cappuccino: zenitTheme.colors.cappuccino,
    moka: {
      400: '#A1816F',
      500: '#8A614B',
      300: '#B9A093',
      200: '#D0C0B7',
      100: '#E8DFDB',
      50: '#F4F0EE',
      600: '#6E4E3C',
      700: '#533A2D',
      800: '#37271E',
      900: '#1C130F'
    },
    yoka: {
      400: '#FFCD65',
      500: '#FFC03F',
      300: '#FFD98C',
      200: '#FFE6B2',
      100: '#FFF2D9',
      50: '#FFF9EC',
      600: '#CC9A32',
      700: '#997326',
      800: '#664D19',
      900: '#33260D'
    },
    coral: {
      500: '#FF8D7E',
      400: '#FFA498',
      300: '#FFD1B3',
      200: '#FFD1CB',
      100: '#FFE8E5',
      50: '#FFF4F3',
      600: '#CC7165',
      700: '#99554C',
      800: '#663832',
      900: '#331C19'
    }
  },
  specialColors: {
    facebookBlue: '#1877F2',
    black: zenitTheme.colors.black,
    white: zenitTheme.colors.white
  },
  gradients: {
    gradientSecondary: zenitTheme.colors.gradient.secondary
  },
  fonts: {
    primary: 'Vollkorn, sans-serif',
    secondary: 'Inter, sans-serif'
  },
  breakpoints: {
    xs: 385,
    sm: 768,
    md: 960,
    lg: 1280,
    xl: 1920
  },

  /* OLD */
  deprecatedColors: {
    textSecondary: '#666666',
    secondaryLight: '#FBF0E8',
    secondaryHighlight: '#FFF7F2'
  },
  shadow: {
    primary: '0 0 12px rgba(255, 179, 128, 0.3)'
  },
  borderRadius: '12px',
  toolbar: {
    height: 50
  },
  chat: {
    header: {
      height: 48
    },
    textArea: {
      height: 74
    }
  }
};

export const mediaQueries = (key: keyof typeof Theme.breakpoints) => {
  return (style: TemplateStringsArray | string): string =>
    `@media (max-width: ${Theme.breakpoints[key] - 1}px) { ${style} }`;
};

export const mobileFirstMedia = (key: keyof typeof Theme.breakpoints) => {
  return (style: TemplateStringsArray | string): string =>
    `@media (min-width: ${Theme.breakpoints[key]}px) { ${style} }`;
};
