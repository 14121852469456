import { useEffect } from 'react';
import { useCountry } from '@unobravo/translations';
import { useSelector } from 'react-redux';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { Indigo } from '../../../shared/utils/indigo';
import { chatbotSelector } from '../../chatbot/chatbot.slice';

export const HelpCenter = () => {
  const { indigoChatbot } = usePatientFeatureFlags();
  const { domainCountry } = useCountry();

  const { isLoaded, isOverlayLoaded } = useSelector(chatbotSelector);

  useEffect(() => {
    if (!isLoaded) return;

    if (indigoChatbot && domainCountry === 'it') {
      Indigo.openChat();
      Indigo.hideCloseButton();
    }

    return () => {
      Indigo.closeChat();
      Indigo.showCloseButton();
    };
  }, [domainCountry, indigoChatbot, isLoaded]);

  useEffect(() => {
    if (!isOverlayLoaded) return;

    Indigo.disableOverlay();

    return () => {
      Indigo.enableOverlay();
    };
  }, [isOverlayLoaded]);

  return <></>;
};
