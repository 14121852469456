import { Theme } from '@unobravo-monorepo/common';
import { Button } from '@unobravo-monorepo/common/components/Button';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { TypographyVariant } from '@unobravo-monorepo/common/components/Typography';
import { useFeatureFlagByKey } from '@unobravo-monorepo/common/hooks';
import { providers } from '@unobravo-monorepo/common/utils/social';

import { captureException } from '@sentry/react';
import {
  Body,
  BodySize
} from '@unobravo-monorepo/common/components/Typography/Body';
import {
  Heading,
  HeadingSize
} from '@unobravo-monorepo/common/components/Typography/Heading';
import { useFirebaseUser } from '@unobravo-monorepo/common/hooks/useFirebaseUser';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import useToaster from '@unobravo-monorepo/common/hooks/useToaster';
import { IToast } from '@unobravo-monorepo/common/types/IToast';
import { handledRoles } from '@unobravo-monorepo/common/utils/roles';
import { LaunchDarklyKey } from '@unobravo/patient';
import { useRegistrationStatus } from '@unobravo/signup';
import { useInternationalVariables } from '@unobravo/translations';
import { Box, Text } from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SocialLogin } from '../components/SocialLogin';
import { useTrackLogin } from '../hooks/useTrackLogin';
import { AuthLayout } from '../layout';

const StyledForm = styled.form`
  > * {
    margin-top: 16px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Theme.colors.candy[500]};
  display: inline-block;
  margin-top: 8px;
`;

export function Login() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { pushEvent } = useGTM();

  const trackLogin = useTrackLogin();

  const navigate = useNavigate();

  const { login, currentUser, logout } = useFirebaseUser();
  const addToast = useToaster();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { registrationStatus, loading: registrationStatusLoading } =
    useRegistrationStatus({ skip: !currentUser.token });

  const { value: socialLoginEnabled } = useFeatureFlagByKey(
    LaunchDarklyKey.SocialLogin
  );

  const { signupUrl } = useInternationalVariables();

  useEffect(() => {
    if (registrationStatusLoading) return;

    const { hasSocialLogin } = providers();

    const userHasRoles = handledRoles.includes(currentUser?.role || '');

    const userHasSignupCompleted =
      registrationStatus?.getRegistrationStatus.signupCompleted;

    const userIsVisitor = currentUser?.role === 'visitor';

    if (userIsVisitor) {
      if (currentUser?.visitorId)
        return navigate(
          `/signup/welcome-back?visitorId=${currentUser.visitorId}`
        );

      captureException(
        new Error(
          `User is visitor but visitorId is null user: ${currentUser.email}`
        )
      );
    }

    if (currentUser.token && !currentUser.isAnonymous && !userHasRoles) {
      if (hasSocialLogin && !userHasSignupCompleted)
        return navigate('/social-registration-gate');

      if (hasSocialLogin && userHasSignupCompleted) return navigate('/patient');

      addToast({
        variant: 'error',
        title: `${t('login.unknownUser')}`
      });

      logout();
    }
  }, [
    addToast,
    currentUser,
    logout,
    navigate,
    registrationStatus,
    registrationStatusLoading,
    t
  ]);

  const onSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    try {
      const { email, password } = data;
      const credentials = await login(email, password);

      pushEvent('Login', { application: 'WEB' });

      trackLogin({
        variables: {
          firebaseId: credentials.user.uid,
          providerId: 'password'
        }
      });
    } catch (e) {
      let toast: Partial<IToast> = {
        title: t('common:errorPage.ops2'),
        description: t('common:errorPage.wentWrong2'),
        variant: 'error'
      };
      const error = e as { code?: string };
      if (
        error.code &&
        ['auth/wrong-password', 'auth/user-not-found'].includes(error.code)
      ) {
        toast = {
          title: t('toast.credentialError.title'),
          description: t('toast.credentialError.description'),
          variant: 'error',
          duration: 'persistent'
        };
      }
      if (error.code === 'auth/too-many-requests') {
        toast = {
          title: t('toast.credentialError.title'),
          description: t('toast.credentialError.descriptionTooMany'),
          variant: 'error',
          duration: 'persistent'
        };
      }
      addToast(toast);
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <Body color={Theme.colors.gray['600']} size={BodySize.Body100} margin>
        {t('login.title')}
      </Body>
      <Heading size={HeadingSize.Title90} margin>
        {t('login.description')}
      </Heading>
      <StyledForm data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          data-testid="email"
          placeholder={t('login.email')}
          autoComplete="unobravo_email"
          type="email"
          {...register('email', {
            required: 'required'
          })}
        />
        <TextInput
          data-testid="password"
          autoComplete="unobravo_password"
          placeholder={t('login.password')}
          type="password"
          {...register('password', {
            required: 'required'
          })}
        />
        <StyledLink to="/reset-password">
          <Body
            size={BodySize.Body60}
            margin="0.203rem 0"
            variant={TypographyVariant.Light}
          >
            {t('login.forgotPassword')}
          </Body>
        </StyledLink>
        <Button
          disabled={!formState.isValid || loading}
          fullWidth
          type="submit"
          data-testid="login-btn"
          label={t('login.submit')}
        />
      </StyledForm>
      {socialLoginEnabled && <SocialLogin />}

      <Box style={{ textAlign: 'center', marginTop: '16px' }}>
        <Text>{t('login.noAccount', { link: signupUrl })}</Text>
      </Box>
    </AuthLayout>
  );
}
