import { CustomTooltip } from '@unobravo-monorepo/common/components/CustomTooltip';
import { SessionStatus } from '@unobravo-monorepo/common/types/IAppointments';
import { Color } from '@unobravo/zenit-core';
import { CreditNote, Invoice } from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useMemo, useState } from 'react';
/**
 * WARNING!:
 * This is a copy of the component with some updates 'cause we need to release the new feature coverd by FF
 (and its only for Invoices page) to not impact the chat components.
 In the next steps after a A/B test and FF enabled to all user we consider to make this component the main component.
 */
export type AppointmentType =
  | 'New'
  | 'Invoice'
  | 'Videocall'
  | 'Confirmed'
  | 'Expired'
  | 'Paid'
  | 'Past';

interface IAppointment {
  dateLabel: string;
  hourLabel: string;
  messageTime?: string;
  headerLabel?: string;
  status?: SessionStatus;
  type?: AppointmentType;
  actionLabel: string;
  isInvoiceable?: boolean;
  showCreditNote?: boolean;
  creditNoteDisabled?: boolean;
  action?: () => void | Promise<void>;
  'data-testid'?: string;
  id?: string;
  onInvoiceDownloadPress?: () => Promise<void>;
  onCreditNoteDownloadPress?: () => Promise<void>;
  invoiceDisabled?: boolean;
  invoiceTooltipLabel?: string;
  creditNoteTooltipLabel?: string;
  actionLabelCreditNote?: string;
  isInvoicesPage?: boolean;
}

const backgroundColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.50';
    default:
      return 'cappuccino.50';
  }
};

const primaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'CONFIRMED':
    case 'VIDEOCALL':
      return 'spinach.500';
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.500';
    default:
      return 'candy.500';
  }
};

const primaryColorInvoices = (status: SessionStatus): Color => {
  switch (status) {
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.500';
    default:
      return 'candy.500';
  }
};

const secondaryColor = (status: SessionStatus): Color => {
  switch (status) {
    case 'EXPIRED':
    case 'CANCELED':
      return 'cappuccino.700';
    default:
      return 'black';
  }
};

const isSessionInvoiceable = ({
  isInvoicesPage = false,
  status
}: {
  isInvoicesPage: boolean;
  status?: SessionStatus;
}) =>
  isInvoicesPage
    ? status === 'DONE' || status === 'CONFIRMED'
    : status === 'DONE';

const showInvoiceButtonByStatus = ({
  isInvoicesPage = false,
  status
}: {
  isInvoicesPage: boolean;
  status?: SessionStatus;
}) =>
  isInvoicesPage
    ? status !== 'DONE' && status !== 'CONFIRMED'
    : status !== 'DONE';

export function AppointmentV2({
  dateLabel,
  hourLabel,
  messageTime,
  headerLabel,
  type,
  actionLabel,
  action,
  status,
  isInvoiceable = false,
  showCreditNote = false,
  onInvoiceDownloadPress,
  onCreditNoteDownloadPress,
  invoiceTooltipLabel,
  invoiceDisabled = false,
  creditNoteDisabled = false,
  'data-testid': dataTestId = 'appointment-bubble',
  creditNoteTooltipLabel,
  actionLabelCreditNote,
  id,
  isInvoicesPage = true
}: IAppointment) {
  const [openInvoiceTooltip, setOpenInvoiceTooltip] = useState(false);
  const [openCreditNoteTooltip, setOpenCreditNoteTooltip] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [creditNoteLoading, setCreditNoteLoading] = useState(false);
  const { isMobile } = useBreakpointValue();

  const isInvoicesPageActive =
    (isInvoiceable || showCreditNote) && isInvoicesPage;

  const buttonClick = action
    ? async () => {
        setButtonLoading(true);
        await action();
        setButtonLoading(false);
      }
    : undefined;

  const handleFileDownload = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    downloadFunction?: () => Promise<void>
  ) => {
    if (!downloadFunction) return;
    setLoading(true);
    try {
      await downloadFunction();
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const calcStatus: SessionStatus = useMemo(() => {
    if (status) {
      return status;
    }
    switch (type) {
      case 'Invoice':
      case 'Paid':
        return 'CONFIRMED';
      case 'Past':
        return 'DONE';
      default:
        return type ? (type.toUpperCase() as SessionStatus) : 'NEW';
    }
  }, [status, type]);

  const isStatusInvoiceable = isSessionInvoiceable({
    isInvoicesPage,
    status
  });

  /* siamo in agenda, be ci ritorna la fattura e la seduta è terminata */
  const onlyInvoiceButton = isInvoiceable && isStatusInvoiceable;

  /* siamo in agenda, be ci ritorna la nota di credito e la seduta è terminata */
  const onlyCreditNoteButton = showCreditNote && isStatusInvoiceable;

  /* siamo in mobile, abbiamo fattura e nota di credito e si deve aprire la modale */
  const showOnlyCreditNoteIcon = isMobile && showCreditNote && isInvoiceable;

  /* qui si deve vedere solo l'icona della fattura perchè non abbiamo nota di credito */
  const showInvoiceButtonIcon =
    !showOnlyCreditNoteIcon &&
    isInvoiceable &&
    showInvoiceButtonByStatus({ isInvoicesPage, status });

  const showOnlyCreditNoteButton =
    !isMobile || (isMobile && !onlyCreditNoteButton);

  const colorVariantButton = isInvoiceable
    ? 'primary'
    : ['VIDEOCALL', 'CONFIRMED'].includes(calcStatus)
    ? 'accent'
    : 'primary';

  const invoiceClick = () =>
    handleFileDownload(setInvoiceLoading, onInvoiceDownloadPress);

  const creditNoteClick = () =>
    handleFileDownload(setCreditNoteLoading, onCreditNoteDownloadPress);

  const ActionButtons = buttonClick ? (
    <>
      {showOnlyCreditNoteButton ? (
        <Box>
          <Button
            label={actionLabel}
            onClick={buttonClick}
            size="md"
            variant={onlyInvoiceButton ? 'outlined' : 'filled'}
            icon={onlyInvoiceButton ? Invoice : undefined}
            iconPosition="left"
            colorVariant={colorVariantButton}
            data-testid={`${dataTestId}-cta`}
            disabled={buttonLoading}
            loading={buttonLoading}
          />
        </Box>
      ) : null}
      {onlyCreditNoteButton && (
        <Box ml="xs">
          <Button
            label={actionLabelCreditNote}
            onClick={creditNoteClick}
            size="md"
            variant="outlined"
            icon={CreditNote}
            iconPosition="left"
            colorVariant={colorVariantButton}
            data-testid={`${dataTestId}-cta`}
            disabled={creditNoteLoading}
            loading={creditNoteLoading}
          />
        </Box>
      )}
    </>
  ) : (
    <Stack
      direction="column"
      style={{
        flexGrow: 0,
        flexShrink: 1
      }}
      data-testid={`${dataTestId}-${status?.toLowerCase()}-label`}
    >
      <Text
        textAlign="center"
        fontWeight="semibold"
        variant="lg"
        color={primaryColor(calcStatus)}
      >
        {actionLabel}
      </Text>
    </Stack>
  );

  const InvoiceButtonIcon = (
    <>
      <Box mr="sm">
        <CustomTooltip
          label={invoiceTooltipLabel ?? ''}
          open={isMobile ? openInvoiceTooltip : undefined}
        >
          <Button
            onClick={() => (!invoiceDisabled ? invoiceClick() : undefined)}
            onTouchEnd={() =>
              invoiceDisabled
                ? setOpenInvoiceTooltip((value) => !value)
                : undefined
            }
            size="md"
            variant="outlined"
            icon={Invoice}
            iconPosition="iconOnly"
            colorVariant={colorVariantButton}
            color="blue"
            data-testid={`${dataTestId}-invoice-cta`}
            loading={invoiceLoading}
            style={{ opacity: invoiceDisabled ? 0.4 : 1 }}
            disabled={isMobile ? invoiceLoading : invoiceDisabled}
          />
        </CustomTooltip>
      </Box>
    </>
  );

  const CreditNoteButtonIcon = (
    <Box mr="sm">
      <CustomTooltip
        label={creditNoteTooltipLabel ?? ''}
        open={isMobile ? openCreditNoteTooltip : undefined}
      >
        <Button
          onClick={() => (!creditNoteDisabled ? creditNoteClick() : undefined)}
          onTouchEnd={() =>
            creditNoteDisabled
              ? setOpenCreditNoteTooltip((value) => !value)
              : undefined
          }
          size="md"
          variant="outlined"
          icon={CreditNote}
          iconPosition="iconOnly"
          colorVariant={colorVariantButton}
          color="blue"
          data-testid={`${dataTestId}-invoice-cta`}
          loading={creditNoteLoading}
          style={{ opacity: creditNoteDisabled ? 0.4 : 1 }}
          disabled={isMobile ? creditNoteLoading : creditNoteDisabled}
        />
      </CustomTooltip>
    </Box>
  );

  return (
    <Box
      data-testid={id ? `${dataTestId}-${id}` : dataTestId}
      position="relative"
      data-type={type}
    >
      {headerLabel && (
        <Box mb="2xs">
          <Text variant="sm" textAlign="center">
            {headerLabel}
          </Text>
        </Box>
      )}
      <Stack
        p="md"
        bgColor={
          isInvoicesPageActive ? 'cappuccino.50' : backgroundColor(calcStatus)
        }
        w="100%"
        rounded="md"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Stack
          direction="column"
          pr="xs"
          spacing="2xs"
          style={{
            flexShrink: 0,
            flexGrow: 1
          }}
        >
          <Text
            variant="lg"
            color={
              isInvoicesPageActive
                ? primaryColorInvoices(calcStatus)
                : primaryColor(calcStatus)
            }
            fontWeight="semibold"
          >
            {dateLabel}
          </Text>
          <Text variant="sm" color={secondaryColor(calcStatus)}>
            {hourLabel}
          </Text>
          {messageTime && (
            <Text variant="xs" color="cappuccino.700">
              {messageTime}
            </Text>
          )}
        </Stack>

        {showInvoiceButtonIcon ? InvoiceButtonIcon : null}
        {showCreditNote && !onlyCreditNoteButton ? CreditNoteButtonIcon : null}
        {ActionButtons}
      </Stack>
    </Box>
  );
}
