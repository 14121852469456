import { GradientModal } from '@unobravo-monorepo/patient/shared/components/GradientModal';
import { GradientLabelType } from '@unobravo-monorepo/patient/types/GradientLabelType';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsOnBoardingOpen } from '../../layout/layout.slice';

export const ModalOnboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const labels: GradientLabelType[] = [
    {
      titleKey: 'onBoarding.firstTitle',
      coloredTitle: t('onBoarding.safeMode'),
      description: t('onBoarding.firstDescription'),
      buttonLabel: t('onBoarding.next'),
      colorVariant: 'primary',
      color: 'rgba(244, 99, 56, 1)'
    },
    {
      titleKey: 'onBoarding.secondTitle',
      coloredTitle: t('onBoarding.free'),
      description: t('onBoarding.secondDescription'),
      buttonLabel: t('onBoarding.next'),
      colorVariant: 'warning',
      color: 'rgba(186, 150, 38, 1)'
    },
    {
      titleKey: 'onBoarding.thirdTitle',
      coloredTitle: t('onBoarding.safe'),
      description: t('onBoarding.thirdDescription'),
      buttonLabel: t('onBoarding.next'),
      colorVariant: 'accent',
      color: 'rgba(37, 136, 111, 1)'
    },
    {
      titleKey: 'onBoarding.fourthTitle',
      coloredTitle: t('onBoarding.always'),
      description: t('onBoarding.fourthDescription'),
      buttonLabel: t('onBoarding.goToChat'),
      colorVariant: 'accent_berry',
      color: 'rgba(8, 85, 141, 1)'
    }
  ];

  return (
    <GradientModal
      steps={labels}
      onCloseModal={() => {
        dispatch(setIsOnBoardingOpen(false));
        navigate('../chat');
      }}
      onCancelModal={() => dispatch(setIsOnBoardingOpen(false))}
    />
  );
};
