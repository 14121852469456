import {
  Alignment,
  Fit,
  Layout as RiveLayout,
  useRive,
  useStateMachineInput
} from '@rive-app/react-canvas';
import { Counter } from '@unobravo-monorepo/common/components/Counter';
import animation from '@unobravo-monorepo/patient/assets/animation/onboarding-bg-desktop.riv';
import animationMobile from '@unobravo-monorepo/patient/assets/animation/onboarding-bg-mobile.riv';
import { GradientLabelType } from '@unobravo-monorepo/patient/types/GradientLabelType';
import { useTheme } from '@unobravo/zenit-core';
import { Cross } from '@unobravo/zenit-icons';
import {
  ActionIcon,
  Box,
  Button,
  Heading,
  Modal,
  Stack,
  Text,
  useBreakpointValue,
  useResponsiveValue
} from '@unobravo/zenit-web';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const stateMachineName = 'Background';
const input = 'Colore';
const viewport = 'Viewport';
const colorVariantToInputColor = {
  primary: 0,
  primary_gradient: 0,
  confirm: 0,
  destructive: 0,
  warning: 1,
  accent: 2,
  accent_berry: 3
};

export const GradientModal = ({
  steps,
  open = true,
  closeButtonDataTestId = 'onboarding-button-goChat',
  onCloseModal = () => {},
  onCancelModal
}: {
  steps: GradientLabelType[];
  open?: boolean;
  closeButtonDataTestId?: string;
  onCloseModal?: () => void;
  onCancelModal?: () => void;
}) => {
  const { t } = useTranslation();

  const size = useResponsiveValue<number>({
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    xl: 0
  });
  const { isMobile, breakpoint } = useBreakpointValue();
  const isMobileSM = breakpoint === 'xs';

  const { RiveComponent: ModalBG, rive } = useRive({
    src: isMobile ? animationMobile : animation,
    autoplay: true,
    stateMachines: stateMachineName,
    layout: new RiveLayout({
      fit: Fit.Cover,
      alignment: Alignment.TopLeft
    })
  });

  const calculatedBgcolor = (index: number) => {
    const variant = steps[index]?.colorVariant;
    if (!variant) {
      return 0;
    }

    return colorVariantToInputColor[variant];
  };

  const skinInput = useStateMachineInput(
    rive,
    stateMachineName,
    input,
    calculatedBgcolor(0)
  );
  const viewportInput = useStateMachineInput(rive, stateMachineName, viewport);
  const { spacing } = useTheme();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (!viewportInput) return;
    viewportInput.value = size;
  });

  useEffect(() => {
    setStep(0);
    if (skinInput) {
      rive && rive.play();
      skinInput.value = calculatedBgcolor(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onNextClick = () => {
    if (step < steps.length - 1) setStep(step + 1);
    if (steps && step === steps.length - 1) {
      onCloseModal();
    }
    if (skinInput) {
      skinInput.value = calculatedBgcolor(step + 1);
    }
  };

  const onBackClick = () => {
    setStep(step - 1);
    if (skinInput) {
      skinInput.value = calculatedBgcolor(step - 1);
    }
  };

  const Title = (
    <span>
      <Heading variant="xl" color="grey.800">
        <Trans
          i18nKey={steps[step]?.titleKey}
          values={{ name: steps[step]?.coloredTitle }}
          components={[
            <span style={{ color: steps[step]?.color }}>
              <Heading color="candy.500" variant={isMobile ? 'lg' : 'xl'}>
                {steps[step]?.coloredTitle}
              </Heading>
            </span>
          ]}
        />
      </Heading>
    </span>
  );

  const BottomSection = (
    <>
      <Box mb="2xl" style={{ alignItems: 'center' }}>
        <Counter
          variant={steps[step]?.colorVariant}
          number={step}
          limit={steps?.length}
        />
      </Box>
      <Stack direction="row" justify="space-between" align="center" right={24}>
        <Box style={{ visibility: step === 0 ? 'hidden' : 'unset' }}>
          <Button
            label={t('onBoarding.back')}
            variant="ghost"
            colorVariant={steps[step]?.colorVariant}
            onClick={onBackClick}
            data-testid="onboarding-button-back"
            size="lg"
          />
        </Box>
        <Button
          label={steps[step]?.buttonLabel}
          variant="filled"
          colorVariant={steps[step]?.colorVariant}
          onClick={onNextClick}
          data-testid={
            step === steps.length - 1
              ? closeButtonDataTestId
              : 'onboarding-button-next'
          }
          size="lg"
        />
      </Stack>
    </>
  );

  return isMobile ? (
    <Box
      position="fixed"
      right={0}
      left={0}
      bottom={0}
      top={0}
      w="100%"
      bgColor="candy.100"
      style={{ zIndex: 100 }}
    >
      <ModalBG />
      <Box position="fixed" w="100vw" right={0} bottom={0} left={0} top={0}>
        <Box p="lg">
          {onCancelModal !== undefined && (
            <Box
              style={{ cursor: 'pointer' }}
              position="absolute"
              right={0}
              top={0}
              p="md"
              onClick={onCancelModal}
            >
              <Cross color="grey.800" size="lg" />
            </Box>
          )}
          <Box
            pb="md"
            pt="md"
            style={{ zIndex: 10, marginTop: isMobileSM ? 48 : 80 }}
          >
            {Title}
          </Box>
          <Text color="grey.800" variant="xl">
            {steps[step]?.description}
          </Text>
        </Box>
        <Box
          position="absolute"
          bottom={0}
          right={0}
          left={32}
          pr="2xl"
          style={{ paddingBottom: isMobileSM ? 32 : 48 }}
        >
          {BottomSection}
        </Box>
      </Box>
    </Box>
  ) : (
    <Modal w={520} open={open} h={620}>
      {onCancelModal !== undefined && (
        <Box
          position="absolute"
          top={spacing.xs}
          right={spacing.xs}
          style={{ zIndex: 5 }}
        >
          <ActionIcon
            variant="ghost"
            size="xl"
            type="button"
            onClick={onCancelModal}
          >
            <Cross />
          </ActionIcon>
        </Box>
      )}
      <Box
        position="absolute"
        right={0}
        bottom={0}
        left={0}
        top={0}
        bgColor="candy.100"
      >
        <ModalBG />
      </Box>
      <Stack pl="xl" pr="xl">
        <Box
          position="relative"
          h="100%"
          grow
          style={{ zIndex: 10, marginTop: 64 }}
        >
          <Box h="100%">
            <Box pb="md">{Title}</Box>
            <Text color="grey.800" variant="xl">
              {steps[step]?.description}
            </Text>
          </Box>
        </Box>
      </Stack>
      <Box position="absolute" bottom={spacing.xl} w="100%" px="xl">
        {BottomSection}
      </Box>
    </Modal>
  );
};
