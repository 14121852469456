import { ArrowRight } from '@unobravo/zenit-icons';
import { Stack, Text, Link } from '@unobravo/zenit-web';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SectionProps {
  title: string;
}

export const Section = ({ title }: SectionProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack direction="row" justify="space-between" w="100%" pb="md">
      <Text fontWeight="semibold" style={{ opacity: '0.5' }}>
        {title}
      </Text>
      <Stack align="center">
        <Link
          data-testid="hp-go-to-tp-setting-link"
          to={() => navigate('/patient/therapy-setting')}
          underline={false}
          color="candy.600"
        >
          <Text fontWeight="semibold">
            {t('therapySetting.hpCard.showMore')}
          </Text>
        </Link>
        <ArrowRight size="sm" color="candy.600" />
      </Stack>
    </Stack>
  );
};
