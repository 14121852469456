import { useTheme } from '@unobravo/zenit-core';
import { Cross } from '@unobravo/zenit-icons';
import { ActionIcon, Box, Modal } from '@unobravo/zenit-web';
import { useDispatch, useSelector } from 'react-redux';
import { DoctorInfoContent } from '../../../../features/home/components/DoctorInfoContent/DoctorInfoContent';
import {
  homeSelector,
  setShowDoctorInfoModal
} from '../../../../features/home/home.slice';

export const DoctorInfoModal = () => {
  const { spacing } = useTheme();
  const dispatch = useDispatch();

  const { showDoctorInfoModal: open } = useSelector(homeSelector);

  return (
    <Modal w="464px" h="664px" open={open}>
      <Box
        position="absolute"
        top={spacing.md}
        right={spacing.md}
        style={{
          zIndex: 1
        }}
      >
        <ActionIcon
          data-testid="tp-modal-close"
          variant="ghost"
          type="button"
          onClick={() => dispatch(setShowDoctorInfoModal(false))}
        >
          <Cross />
        </ActionIcon>
      </Box>
      <DoctorInfoContent />
    </Modal>
  );
};
