import { Color } from '@unobravo/zenit-core';
import { Text, Stack } from '@unobravo/zenit-web';
import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.8 }
});

const NotificationContainer = styled(Stack)`
  animation: ${fadeIn} 0.3s ease-out 0.3s forwards;
  opacity: 0;
`;

export const NotificationBadge = ({
  value,
  onClick,
  w = '25px',
  h = '25px',
  right = '30px',
  top = '10px',
  position = 'absolute',
  color = 'candy.500',
  style = {}
}: {
  value?: string | number;
  onClick?: () => void;
  w?: string;
  h?: string;
  right?: string;
  top?: string;
  position?: 'absolute' | 'fixed' | 'relative' | 'sticky';
  color?: Color;
  style?: object;
}) => (
  <NotificationContainer
    alignContent="center"
    position={position}
    bgColor={color}
    justify="center"
    align="center"
    w={w}
    h={h}
    right={right}
    top={top}
    rounded="full"
    onClick={onClick}
    style={style}
  >
    {value && (
      <Text variant="xs" color="white">
        {value}
      </Text>
    )}
  </NotificationContainer>
);
