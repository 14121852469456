import { useQuery } from '@apollo/client';
import { usePatient } from '../../patientData/hooks/usePatient';
import { GET_HAS_PATIENT_SURVEY_AGREEMENT } from '../graphql/queries';

export const useTherapySurveyConsent = () => {
  const { id } = usePatient();

  const { data } = useQuery(GET_HAS_PATIENT_SURVEY_AGREEMENT, {
    variables: { patientId: id },
    skip: !id
  });

  const isConsentGiven = !!data?.hasPatientSurveyAgreement;

  return isConsentGiven;
};
