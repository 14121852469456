import { Country } from '@unobravo/core';
import { useCountry } from './useCountry';

const { NX_APP_DOMAIN_URL, NX_APP_FR_DOMAIN_URL, NX_APP_ES_DOMAIN_URL } =
  process.env;

const mgmShareUrl: Record<Country, string> = {
  it: 'https://bit.ly/invita-chi-ami',
  es: 'https://bit.ly/invita-chi-ami',
  fr: 'https://bit.ly/invita-chi-ami'
};

const blogUrl: Record<Country, string> = {
  es: 'https://www.unobravo.com/es/blog',
  it: 'https://www.unobravo.com/blog',
  fr: 'https://www.fr.unobravo.com/blog'
};

const patientUrl = {
  it: `https://${NX_APP_DOMAIN_URL}/patient`,
  es: `https://${NX_APP_ES_DOMAIN_URL}/patient`,
  fr: `https://${NX_APP_FR_DOMAIN_URL}/patient`
} as const satisfies Record<Country, string>;

const privacyPolicyUrl: Record<Country, string> = {
  it: 'https://www.unobravo.com/privacy-policy',
  es: 'https://www.unobravo.com/es/privacy-policy',
  fr: 'https://www.unobravo.com/fr/privacy-policy'
};

const termsAndConditionsUrl: Record<Country, string> = {
  it: 'https://www.unobravo.com/terms-conditions',
  es: 'https://www.unobravo.com/es/terms-conditions',
  fr: 'https://www.unobravo.com/fr/terms-conditions'
};

const signupUrl: Record<Country, string> = {
  it: `https://${NX_APP_DOMAIN_URL}/signup`,
  es: `https://${NX_APP_ES_DOMAIN_URL}/signup`,
  fr: `https://${NX_APP_FR_DOMAIN_URL}/signup`
};

const emergencyNumbersUrl: Record<Country, string> = {
  it: 'https://www.unobravo.com/emergency-numbers',
  es: 'https://www.unobravo.com/es/emergency-numbers',
  fr: 'https://www.unobravo.com/fr/emergency-numbers'
};

const supportUrl: Record<Country, string> = {
  it: 'https://{{zendeskDefaultUrl}}.zendesk.com/',
  es: 'https://{{zendeskDefaultUrl}}.zendesk.com/',
  fr: 'https://{{zendeskDefaultUrl}}.zendesk.com/'
};

const tellMoreDeleteAccount: Record<Country, string> = {
  it: 'https://unobravo.typeform.com/to/ibO8MAE5',
  es: 'https://unobravo.typeform.com/to/iLaT1QgW',
  fr: 'https://unobravo.typeform.com/to/gDLuhfxo'
};

export const useInternationalVariables = () => {
  const { domainCountry } = useCountry();

  /**
   * Will be ES_ if es, EN_ if en ecc..
   * */
  const countryString = `${
    domainCountry !== 'it' ? `${domainCountry.toUpperCase()}_` : ''
  }`;

  const concatKey = (key: string) => {
    return process.env[`NX_APP_${countryString}${key}`];
  };

  const gtmAuth = concatKey('GTM_AUTH');
  const gtmEnv = concatKey('GTM_ENV');

  const zendeskDefaultUrl = concatKey('ZENDESK_URL');

  const gtmExtend =
    gtmAuth && gtmEnv
      ? `&gtm_auth=${gtmAuth}&gtm_preview=${gtmEnv}&gtm_cookies_win=x`
      : '';

  return {
    stripeKey: concatKey('STRIPE_APIKEY'),
    gtmId: concatKey('GTM_ID'),
    gtmExtend,
    zendeskDefaultUrl,
    zendeskCIUrl: concatKey('ZENDESK_URL_CI'),
    zendeskCommunityUrl: concatKey('ZENDESK_URL_DOCTOR'),
    blogUrl: blogUrl[domainCountry || 'it'],
    tellMoreDeleteAccount: tellMoreDeleteAccount[domainCountry || 'it'],
    privacyPolicyUrl: privacyPolicyUrl[domainCountry || 'it'],
    termsAndConditionsUrl: termsAndConditionsUrl[domainCountry || 'it'],
    mgmShareUrl: mgmShareUrl[domainCountry || 'it'],
    signupUrl: signupUrl[domainCountry || 'it'],
    patientUrl: patientUrl[domainCountry || 'it'],
    emergencyNumbersUrl: emergencyNumbersUrl[domainCountry || 'it'],
    supportUrl: supportUrl[domainCountry || 'it'].replace(
      '{{zendeskDefaultUrl}}',
      zendeskDefaultUrl || ''
    )
  };
};
