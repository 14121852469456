import React from 'react';
import { Skeleton, Stack } from '@unobravo/zenit-web';

import { Divider } from '@unobravo-monorepo/common/components/Divider';

export const AppointmentCardSkeleton = () => {
  return (
    <>
      <Stack direction="column" spacing="md">
        <Skeleton h="18px" rounded="2xl" w="50%" />
        <Divider type="horizontal" />
        <Skeleton h="42px" rounded="2xl" w="100%" />
      </Stack>
    </>
  );
};
