import { TypographyVariant } from '@unobravo-monorepo/common/components/Typography';
import { Password } from '@unobravo/zenit-icons';
import { Box, Button, Stack, Text } from '@unobravo/zenit-web';

import { CSSProperties, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface CopyToClipboardProps {
  value: string;
  onCopySuccess?: () => void;
  onCopyError?: () => void;
  variant: CopyToClipboardVariant;
  style?: CSSProperties;
  ['data-testid']?: string;
}

type CopyStatus = 'ready' | 'success' | 'error' | 'loading';
export type CopyToClipboardVariant =
  | 'code_generated'
  | 'code_redeemed'
  | 'code_locked'
  | 'code_already_used'
  | 'code_expired';

type ExtendedButtonProps = {
  copyVariant?: CopyToClipboardVariant;
} & React.ComponentProps<typeof Button>;

type ExtendedTextProps = {
  copyVariant?: CopyToClipboardVariant;
} & React.ComponentProps<typeof Text>;

const CopyAction = styled(Text)<ExtendedTextProps>`
  position: absolute;
  right: 20px;
  color: ${({ theme, copyVariant }) => {
    switch (copyVariant) {
      case 'code_redeemed':
        return theme.colors.edamame[500];
      case 'code_locked':
        return theme.colors.grey[800];
      case 'code_generated':
      default:
        return theme.colors.candy[500];
    }
  }};
`;

const LockedCTCStack = styled(Stack)`
  background: rgba(0, 0, 0, 0.05);
`;

const Black30Text = styled(Text)`
  color: rgba(10, 10, 10, 0.3);
`;

const CopyButton = styled(Button)<ExtendedButtonProps>`
  background: ${({ theme, copyVariant }) => {
    switch (copyVariant) {
      case 'code_redeemed':
        return theme.colors.edamame[50];
      case 'code_locked':
        return theme.colors.grey[100];
      case 'code_generated':
      default:
        return theme.colors.candy[50];
    }
  }};
  font-family: ${({ theme }) => theme.fonts.primary};
  position: relative;

  p:first-child {
    font-size: 18px;
    line-height: 150%;
    position: absolute;
    left: 20px;
    top: 11px;
    color: ${({ theme }) => theme.colors.grey[800]};
    font-weight: ${TypographyVariant.Bold};
  }
`;

const LockedCTC = ({
  label,
  icon
}: {
  label: React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <LockedCTCStack
      rounded="xl"
      px="md"
      py="sm"
      justify="space-between"
      align="center"
    >
      {label}

      {icon}
    </LockedCTCStack>
  );
};

export const CopyToClipboard = ({
  value,
  onCopySuccess,
  onCopyError,
  variant,
  style,
  'data-testid': dataTestId
}: CopyToClipboardProps) => {
  const { t } = useTranslation();
  const [copyStatus, setCopyStatus] = useState<CopyStatus>('ready');

  const onCopyAction = () => {
    setCopyStatus('loading');

    navigator.clipboard
      .writeText(value)
      .then(() => {
        setCopyStatus('success');

        onCopySuccess?.();
      })
      .catch(() => {
        setCopyStatus('error');

        onCopyError?.();
      });
  };

  const Icon = useMemo(() => {
    const translations: Record<CopyStatus, string> = {
      ready: t('copyToClipboard.ready'),
      success: t('copyToClipboard.success'),
      error: t('copyToClipboard.error'),
      loading: t('copyToClipboard.loading')
    };

    return () => (
      <CopyAction variant="lg" fontWeight="semibold" copyVariant={variant}>
        {translations[copyStatus]}
      </CopyAction>
    );
  }, [copyStatus, t, variant]);

  switch (variant) {
    case 'code_locked':
      return (
        <LockedCTC
          label={
            <Text variant="xl" fontWeight="bold">
              <span>{[...value].map(() => '*').join('')}</span>
            </Text>
          }
          icon={
            <Box rounded="xs" bgColor="grey.100" p="2xs" w="24px" h="24px">
              <Password size="sm" color="grey.800" />
            </Box>
          }
        />
      );

    case 'code_already_used':
      return (
        <LockedCTC
          label={
            <Black30Text variant="xl" fontWeight="bold">
              <del>{value}</del>
            </Black30Text>
          }
          icon={
            <Black30Text variant="xl" fontWeight="bold">
              {t('copyToClipboard.alreadyUsed')}
            </Black30Text>
          }
        />
      );

    case 'code_expired':
      return (
        <LockedCTC
          label={
            <Black30Text variant="xl" fontWeight="bold">
              <del>{value}</del>
            </Black30Text>
          }
          icon={
            <Black30Text variant="xl" fontWeight="bold">
              {t('copyToClipboard.expired')}
            </Black30Text>
          }
        />
      );

    default:
      return (
        <CopyButton
          onClick={onCopyAction}
          data-testid={dataTestId}
          label={value}
          copyVariant={variant}
          icon={Icon}
          iconPosition="right"
          size="lg"
          style={style}
          disabled={copyStatus === 'loading' || copyStatus === 'error'}
          colorVariant={variant === 'code_redeemed' ? 'confirm' : 'primary'}
        />
      );
  }
};
