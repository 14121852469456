import { Box, Heading, Stack, Text } from '@unobravo/zenit-web';

interface IInfoHeaderProps {
  title?: string;
  description?: string;
}

export const InfoHeader = ({ title, description }: IInfoHeaderProps) => {
  return (
    <Box
      position="sticky"
      top={0}
      bgColor="white"
      style={{ zIndex: 3 }}
      px="xl"
      pt="xl"
      rounded="lg"
      radiusTopLeft="lg"
      radiusTopRight="lg"
      pb="2xs"
    >
      <Stack direction="column" spacing="2xs" pb="md">
        {title && (
          <Heading variant="lg" color="grey.900">
            {title}
          </Heading>
        )}
        {description && (
          <Text variant="md" color="grey.600">
            {description}
          </Text>
        )}
      </Stack>
    </Box>
  );
};
