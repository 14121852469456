import { ReactNode } from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { ArrowLeft, ArrowRight } from '../Icons';
import { Body, BodySize, TypographyVariant } from '../Typography';

const ArrowWrapper = styled.div<{ onClick?: () => void }>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  padding: 0 8px;
  display: flex;
  align-items: center;
  user-select: none;
`;

const SwitcherDiv = styled.div<{ width: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${({ width }) => width}px;
  position: relative;
`;

const ArrowHidden = styled.div`
  width: 36px;
`;

interface ISwitcher {
  onNext?: () => void;
  onPrev?: () => void;
  disableNext?: boolean;
  disablePrev?: boolean;
  label?: string;
  children?: ReactNode;
  width?: number;
  'data-testid'?: string;
}

export const Switcher: React.FC<ISwitcher> = ({
  onNext,
  onPrev,
  disableNext,
  disablePrev,
  label,
  children,
  width = 200,
  'data-testid': dataTestId
}) => {
  const isInactive = disableNext && disablePrev;
  return (
    <SwitcherDiv width={width} data-testid={dataTestId}>
      {onPrev ? (
        <ArrowWrapper
          data-testid="arrow-prev"
          onClick={disablePrev ? undefined : onPrev}
        >
          <ArrowLeft
            width={20}
            height={20}
            color={
              disablePrev ? Theme.colors.gray[200] : Theme.specialColors.black
            }
          />
        </ArrowWrapper>
      ) : (
        <ArrowHidden />
      )}
      {children}
      {label && (
        <Body
          size={BodySize.Body70}
          variant={TypographyVariant.Medium}
          capitalize
          element="span"
          noWrap
          color={
            isInactive ? Theme.colors.gray[200] : Theme.specialColors.black
          }
        >
          {label}
        </Body>
      )}
      {onNext ? (
        <ArrowWrapper
          onClick={disableNext ? undefined : onNext}
          data-testid="switcher-arrow-next"
        >
          <ArrowRight
            width={20}
            height={20}
            color={
              disableNext ? Theme.colors.gray[200] : Theme.specialColors.black
            }
          />
        </ArrowWrapper>
      ) : (
        <ArrowHidden />
      )}
    </SwitcherDiv>
  );
};
