import { Box, Stack, Text } from '@unobravo/zenit-web';
import {
  Camera,
  Fan,
  Interface,
  Router,
  Tabs,
  Videogame
} from '@unobravo/zenit-icons';
import { useTranslation } from 'react-i18next';

interface ModalContentItem {
  Icon: React.ReactNode;
  text: string;
}

export const Troubleshooting = () => {
  const { t } = useTranslation();

  const modalContent: ModalContentItem[] = [
    {
      Icon: <Router />,
      text: t('common:videoCall.troubleshootingModal.firstSuggestion')
    },
    {
      Icon: <Videogame />,
      text: t('common:videoCall.troubleshootingModal.secondSuggestion')
    },
    {
      Icon: <Tabs />,
      text: t('common:videoCall.troubleshootingModal.thirdSuggestion')
    },
    {
      Icon: <Interface />,
      text: t('common:videoCall.troubleshootingModal.fourthSuggestion')
    },
    {
      Icon: <Camera />,
      text: t('common:videoCall.troubleshootingModal.fifthSuggestion')
    },
    {
      Icon: <Fan />,
      text: t('common:videoCall.troubleshootingModal.sixthSuggestion')
    }
  ];

  return (
    <Stack direction="column" mt="md" spacing="md">
      {modalContent.map((item: ModalContentItem) => (
        <Stack spacing="md" align="center" key={item.text}>
          <Box>{item.Icon}</Box>
          <Text color="grey.900" variant="sm">
            {item.text}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
};
