import { Support } from '@unobravo-monorepo/common/components/Icons';
import { Calendar } from '@unobravo-monorepo/common/components/Icons/Calendar';
import { User } from '@unobravo-monorepo/common/components/Icons/User';
import { SidebarMenu } from '@unobravo-monorepo/common/components/SidebarMenu';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import IIcon from '@unobravo-monorepo/common/types/IIcon';
import { IMenuItem } from '@unobravo-monorepo/common/types/IMenuItem';
import { useIsFreeSessionHomeEnable } from '@unobravo-monorepo/patient/features/home/hooks/useIsFreeSessionHomeEnable';
import { useCountry, useInternationalVariables } from '@unobravo/translations';
import {
  BonusPsicologico,
  Home,
  Invoice,
  Message,
  MessageReviewStar
} from '@unobravo/zenit-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { chatSelector } from '../../../features/chat/store/selectors';
import {
  layoutSelector,
  toggleLeftMenu
} from '../../../features/layout/layout.slice';
import { BadgeDrawer } from '../../../features/onboarding/components/BadgeDrawer';
import { usePatient } from '../../../features/patientData/hooks/usePatient';
import { PlanNextSessionsAlert } from '../../../features/planNextSessionsAlert';
import { useNewAchievements } from '../../../features/vouchers/hooks/useNewAchievements';
import { useZendesk } from '../../hooks/useZendesk';
import { useBundle } from '../../../features/bundles/hooks/useBundle';

export const DashboardMenu: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { newMessage } = useSelector(chatSelector);
  const { isLeftMenuOpen } = useSelector(layoutSelector);
  const { isMobile } = useViewport();
  const { goToZendeskAsLoggedUser } = useZendesk();
  const { indigoChatbot } = usePatientFeatureFlags();
  const { domainCountry } = useCountry();
  const { zendeskDefaultUrl } = useInternationalVariables();
  const { planNextSessionsAlert, ptVouchers } = usePatientFeatureFlags();
  const { hasNewAchievements } = useNewAchievements();
  const { value: showFreeSessionHome } = useIsFreeSessionHomeEnable();

  const { status } = usePatient();
  const { isBundleOpened, bundleVariant } = useBundle();

  let showHomePage =
    (isBundleOpened || bundleVariant === 'control') &&
    status === 'FREE_SESSION';

  showHomePage =
    showHomePage || status === 'ONGOING' || status === 'TERMINATED';

  const showMgmV2Highlight =
    status === 'ONGOING' ||
    status === 'NOT_CONVERTED' ||
    status === 'FREE_SESSION';

  const sections: Array<IMenuItem | (() => JSX.Element)> = [
    ...(showHomePage
      ? [
          {
            Icon: Home as React.FC<IIcon>,
            title: t('dashboard.sections.home'),
            path: { pathname: `../home` },
            id: 'home'
          }
        ]
      : showFreeSessionHome
      ? [
          {
            Icon: Home as React.FC<IIcon>,
            title: t('dashboard.sections.home'),
            path: { pathname: `../free-session-home` },
            id: 'free-session-home'
          }
        ]
      : []),

    {
      Icon: Message as React.FC<IIcon>,
      title: t('dashboard.sections.chat'),
      path: {
        pathname: `../chat`
      },
      notification: newMessage,
      id: 'chat'
    },
    {
      Icon: Calendar,
      title: t('dashboard.sections.therapySetting'),
      path: {
        pathname: '../therapy-setting'
      },
      id: 'therapy-setting'
    },
    {
      Icon: Invoice as React.FC<IIcon>,
      title: t('dashboard.sections.invoices'),
      path: {
        pathname: `../invoices`
      },
      id: 'invoices'
    },
    {
      Icon: User,
      title: t('dashboard.sections.profile'),
      path: {
        pathname: `../preferences`,
        activeIfOverride: 'preferences'
      },
      id: 'profile'
    },
    ...(domainCountry === 'it'
      ? [
          {
            Icon: MessageReviewStar as React.FC<IIcon>,
            title: t('dashboard.sections.memberGetMember'),
            path: {
              pathname: `../member-get-member`
            },
            id: 'member-get-member-v2',
            highlight: true,
            highlightFirstTime: showMgmV2Highlight
          }
        ]
      : []),
    ...(ptVouchers
      ? [
          {
            Icon: BonusPsicologico as React.FC<IIcon>,
            title: t('dashboard.sections.vouchers'),
            path: {
              pathname: `../badges-vouchers`
            },
            id: 'badges-vouchers',
            highlight: hasNewAchievements
          }
        ]
      : []),
    ...(isMobile
      ? [
          {
            Icon: Support,
            title: t('help'),
            path: {
              pathname: `https://${zendeskDefaultUrl}.zendesk.com/hc`
            },
            external: true,
            id: 'support'
          }
        ]
      : []),

    () => <BadgeDrawer />
  ];

  const toggleMenu = () => {
    dispatch(toggleLeftMenu());
  };

  const handleSupportLinkClick = () => {
    toggleMenu();
    if (indigoChatbot && domainCountry === 'it') {
      return window?.IndigoAIChat?.setOpen(true);
    }
    return goToZendeskAsLoggedUser();
  };

  return (
    <SidebarMenu
      sections={sections}
      isMenuOpen={isLeftMenuOpen}
      toggleMenu={toggleMenu}
      expandable={!isMobile}
      bottom={
        planNextSessionsAlert && isLeftMenuOpen ? (
          <PlanNextSessionsAlert />
        ) : undefined
      }
      handleSupportLinkClick={handleSupportLinkClick}
    />
  );
};
