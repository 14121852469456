import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import {
  IBillingData,
  InvoiceLanguage,
  PatientBillingInfo,
  PlatformCountry,
  UpdatePatientInput,
  setBillingInfo,
  useBillingInfoV2
} from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { countriesMap } from '@unobravo/utils';
import { Button, Stack, useBreakpointValue } from '@unobravo/zenit-web';
import { useABTestVariant } from '@unobravo-monorepo/common/hooks';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePatientGTM } from '../../shared/hooks/usePatientGTM';
import { FormPatient } from '../../types/IBillingForm';
import { BillingInfoForm } from '../billingInfo/billingInfoForm';
import { usePatient } from '../patientData/hooks/usePatient';
import { InfoHeader } from './components/InfoHeader';
import { usePatientConsentData } from './hooks/usePatientConsentData';

const countries = countriesMap as Record<string, string>;

const mapBillingInfo: (
  info: Partial<PatientBillingInfo>,
  domain: PlatformCountry
) => IBillingData = (info, domain) => {
  const defaultInvoiceLanguage =
    info?.invoiceLanguage ?? (domain.toLowerCase() as InvoiceLanguage);

  return {
    name: info?.name ?? '',
    address: info?.address ?? '',
    cap: info?.cap ?? '',
    cf: info?.cf ?? '',
    city: info?.city ?? '',
    createdAt: info?.createdAt,
    invoiceLanguage: info?.invoiceLanguage ?? defaultInvoiceLanguage,
    province: info?.province ?? '',
    surname: info?.surname ?? '',
    countryIsoCode: info?.countryIsoCode ?? ''
  };
};

interface ISubmitData {
  patient: Partial<UpdatePatientInput>;
  billing: IBillingData;
}

export const BillingInfo = ({
  onNext,
  onBack
}: {
  onNext?: () => void;
  onBack?: () => void;
}) => {
  const vwoVariant = useABTestVariant('AutocompletePatientInfo');
  const isInVariantABTest = vwoVariant === 'variant1';
  const [updateLoading, setUpdateLoading] = useState(false);
  const { t } = useTranslation();
  const { consentTS, updatePatient, name, id, surname } = usePatient();
  const { isMobile } = useBreakpointValue();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const { domainCountry } = useCountry();
  const dispatch = useDispatch();
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
  const {
    billingInfoData: billingInfoState,
    loading,
    updatePatientBilling: updateBilling,
    createPatientBilling: createBillingInfo
  } = useBillingInfoV2(id!);
  const { data: patientConsentData, loading: patientConsentLoading } =
    usePatientConsentData();
  const { id: billingInfoId } = billingInfoState ?? {};
  const billing = {
    ...mapBillingInfo(
      billingInfoState ?? {},
      domainCountry.toUpperCase() as PlatformCountry
    ),
    name: billingInfoState?.name ?? name ?? '',
    surname:
      billingInfoState?.surname ?? (isInVariantABTest && surname)
        ? surname!
        : ''
  };
  const defaultValues = {
    billing,
    patient: {
      consentTS
    } as FormPatient
  };
  const methods = useForm({
    mode: 'onChange',
    defaultValues
  });
  const { formState, setValue, getValues, handleSubmit, reset } = methods;
  useEffect(() => {
    const valuesMap = {
      consentTS
    } as FormPatient;
    setValue('patient', valuesMap);
    setValue('billing', billing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfoId]);
  useEffect(() => {
    if (
      isInVariantABTest &&
      patientConsentData?.getPatientConsentData?.patient &&
      !billingInfoId
    ) {
      reset({
        ...defaultValues,
        billing: {
          name: patientConsentData.getPatientConsentData.patient.name ?? '',
          surname:
            patientConsentData.getPatientConsentData.patient.surname ?? '',
          address:
            patientConsentData.getPatientConsentData.patient.residenceStreet ??
            '',
          cap:
            patientConsentData.getPatientConsentData.patient.residenceZip ?? '',
          cf: patientConsentData.getPatientConsentData.patient.taxCode ?? '',
          city:
            patientConsentData.getPatientConsentData.patient.residenceTown ??
            '',
          countryIsoCode:
            patientConsentData.getPatientConsentData.patient.residenceCountry ??
            '',
          province:
            patientConsentData.getPatientConsentData.patient
              .residenceProvince ?? ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientConsentData?.getPatientConsentData?.patient, isInVariantABTest]);

  const nextStep = (isNew = false) => {
    pushAuthenticatedEvent(isNew ? 'NewBillingInfo' : 'EditBillingInfo');
    onNext?.();
  };

  const onSubmit = async (data: ISubmitData, isNew: boolean) => {
    if (!showAddressFields && !data.billing.cap) {
      setShowAddressFields(true);
      return;
    }
    setUpdateLoading(true);
    const patientSuccess = await updatePatient({
      ...data.patient,
      name: data.billing.name,
      surname: data.billing.surname
    });
    if (!patientSuccess) {
      setUpdateLoading(false);
      return;
    }
    let billingSuccess;
    const billingData = {
      ...data.billing,
      country: countries[data.billing.countryIsoCode!]
    };
    if (isNew) {
      billingSuccess = await createBillingInfo(billingData);
    } else {
      billingSuccess = await updateBilling(billingData);
    }
    if (billingSuccess) {
      // TODO: remove after useBillingInfoV2 refactor
      dispatch(setBillingInfo({ ...billingSuccess }));

      nextStep(isNew);
    }
    setUpdateLoading(false);
  };

  if (loading || patientConsentLoading) {
    return <Spinner />;
  }

  return (
    <FormProvider {...methods}>
      <InfoHeader
        showStepper
        title={t('paySession.billingInfo.title')}
        active="BILLING_INFO"
        description={t('paySession.billingInfo.description')}
      />
      <form
        onSubmit={handleSubmit(
          formState.isDirty
            ? (data) => onSubmit(data, !billingInfoId)
            : () => nextStep()
        )}
        style={{ flex: 1, display: 'flex' }}
      >
        <Stack direction="column" grow>
          <Stack direction="column" px="xl" pt="xl" grow>
            <BillingInfoForm
              showAddressFields={
                (formState.touchedFields?.billing &&
                  formState.touchedFields.billing.address) ||
                !!getValues('billing.cap') ||
                showAddressFields
              }
              isFirstFilling={!billingInfoId}
            />
          </Stack>
          <Stack
            direction={isMobile ? 'column-reverse' : 'row'}
            justify={onBack ? 'space-between' : 'end'}
            spacing="xs"
            position="sticky"
            bottom={-1}
            left={0}
            bgColor="white"
            p="xl"
          >
            {onBack && (
              <Button
                variant="ghost"
                onClick={onBack}
                label={t('paymentMethod.back')}
                size="lg"
                iconPosition="left"
                type="button"
                data-testid="billing-info-back-button"
              />
            )}
            <Button
              onClick={() => updateLoading}
              label={t('common:buttonNext')}
              iconPosition="right"
              size="lg"
              disabled={updateLoading}
              type="submit"
              data-testid={
                billingInfoId ? 'edit-billing-info-cta' : 'new-billing-info-cta'
              }
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
