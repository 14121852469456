import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Stack } from '@unobravo/zenit-web';

export const NoInvoices = () => {
  const { t } = useTranslation();

  return (
    <Stack
      data-testid="no-invoices"
      direction="column"
      align="center"
      justify="center"
      style={{ flexGrow: 1 }}
    >
      <Stack
        data-testid="no-invoices"
        direction="column"
        align="center"
        justify="center"
        spacing="sm"
        alignContent="center"
        maxW="275px"
      >
        <Text textAlign="center" fontWeight="semibold">
          {t('invoices.noInvoicesDescription1')}
        </Text>
        <Text textAlign="center">{t('invoices.noInvoicesDescription2')}</Text>
      </Stack>
    </Stack>
  );
};
