import {
  StorageKeys,
  createSafeStorage
} from '@unobravo-monorepo/common/utils/storage';
import { Country } from '@unobravo/core';

const [getLocaleStorageCountry, setLocaleStorageCountry] = createSafeStorage(
  StorageKeys.localCountry
);
const [getCrowdinLocale] = createSafeStorage(StorageKeys.crowdinFeature);

/**
 * Check for local environment
 * @param searchParams string
 * @returns boolean
 */
export const isLocalDev = (searchParams: string): boolean => {
  const { NODE_ENV } = process.env;
  return (
    NODE_ENV !== 'production' && window.location.search.includes(searchParams)
  );
};

/**
 * Locale based on domain
 * @returns Country
 */
export const getLocale = () => {
  const { NX_COUNTRY, NX_APP_ENVIRONMENT } = process.env;

  const isLocal = NX_APP_ENVIRONMENT === 'local';

  const getDefaultLocale = () => {
    const localStorageCountry = getLocaleStorageCountry();

    if (!localStorageCountry) return (NX_COUNTRY as Country) ?? 'it';
    return localStorageCountry;
  };

  const locale = () => {
    switch (true) {
      case isLocal:
        return getDefaultLocale();
      case window.origin.includes('buencoco'):
      case window.origin.includes('app-es.unobravo'):
      case window.origin.includes('maintenance-buencoco'):
        return 'es';
      case window.origin.includes('app-fr.unobravo'):
      case window.origin.includes('maintenance-fr-unobravo'):
        return 'fr';
      case window.origin.includes('unobravo'):
      case window.origin.includes('maintenance-unobravo'):
        return 'it';
      // In Context Feature (Crowdin)
      case window.origin.includes('translations-ub') || isLocalDev('lang=cc'):
        return getCrowdinLocale()?.split('-')[0] as Country;

      // Development Environment
      default:
        return NX_COUNTRY as Country;
    }
  };

  return locale();
};

export { setLocaleStorageCountry };
