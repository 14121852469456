import { SessionStatus } from '@unobravo-monorepo/common/types/IAppointments';
import { useBundle } from '@unobravo-monorepo/patient/features/bundles/hooks/useBundle';
import { Color } from '@unobravo/zenit-core';
import { Camera } from '@unobravo/zenit-icons';
import { Button, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

export const AppointmentCardBubbleBody = ({
  variant,
  textColor,
  onPayClicked,
  onVideocallClicked,
  onExpiredClicked,
  showExpiredCTA = false,
  sessionCategory
}: {
  variant: SessionStatus;
  showExpiredCTA?: boolean;
  textColor: Color;
  onPayClicked?: () => void;
  onVideocallClicked?: () => void;
  onExpiredClicked?: () => void;
  sessionCategory?: string;
}) => {
  const { t } = useTranslation();
  const { shouldPayWithBundle, isBundleOpened } = useBundle();

  const payWithBundleCTALabel = shouldPayWithBundle
    ? t('therapySetting.payWithBundle')
    : isBundleOpened
    ? t('therapySetting.payWithPaywall')
    : undefined;

  const payWithBundleLabel =
    sessionCategory === 'PAID' && payWithBundleCTALabel;

  const payLabel =
    sessionCategory === 'PAID'
      ? t('therapySetting.appointmentPending')
      : t('therapySetting.newAppointmentConfirmCTA');

  switch (variant) {
    case 'CANCELED':
      return (
        <Text
          variant="md"
          fontWeight="bold"
          textAlign="center"
          color={textColor}
        >
          {t('therapySetting.appointmentCanceled')}
        </Text>
      );
    case 'EXPIRED':
      if (showExpiredCTA) {
        return (
          <>
            <Text variant="sm" color={textColor}>
              {t('therapySetting.appointmentExpiredText')}
            </Text>
            <Button
              fullWidth
              data-testid="tp-setting-card-bubble-appointment-expired-cta"
              label={t('therapySetting.appointmentExpiredCTA')}
              variant="outlined"
              onClick={onExpiredClicked}
            />
          </>
        );
      }
      return (
        <Text
          variant="md"
          fontWeight="bold"
          textAlign="center"
          color={textColor}
        >
          {t('therapySetting.appointmentExpired')}
        </Text>
      );

    case 'DONE':
      return (
        <Text
          variant="md"
          fontWeight="bold"
          textAlign="center"
          color="spinach.500"
        >
          {t('therapySetting.appointmentDone')}
        </Text>
      );
    case 'CONFIRMED':
      return (
        <Text
          variant="md"
          fontWeight="bold"
          textAlign="center"
          color="spinach.500"
        >
          {t('therapySetting.appointmentConfirmed')}
        </Text>
      );
    case 'VIDEOCALL':
      return (
        <Button
          fullWidth
          data-testid="tp-setting-card-bubble-appointment-videocall-cta"
          label={t('therapySetting.videocallCTA')}
          variant="filled"
          icon={Camera}
          iconPosition="left"
          onClick={onVideocallClicked}
          colorVariant="accent"
        />
      );
    case 'NEW':
      return (
        <Button
          fullWidth
          data-testid="tp-setting-card-bubble-appointment-new-cta"
          label={payWithBundleLabel || payLabel}
          variant="filled"
          onClick={onPayClicked}
        />
      );
    default:
      return null;
  }
};
