import { DownloadInvoiceIcon } from '@unobravo-monorepo/common/components/Icons/DownloadInvoiceIcon';
import { Doc } from '@unobravo/zenit-icons';
import { Box, Button, Stack, Text } from '@unobravo/zenit-web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IDownloadInvoice {
  available: boolean;
  downloadInvoice: () => Promise<void>;
}

export const DownloadInvoice = ({
  available,
  downloadInvoice
}: IDownloadInvoice) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <Stack
      align="center"
      justify="center"
      w="100%"
      h="100%"
      p="2xl"
      direction="column"
    >
      <DownloadInvoiceIcon />
      <Box my="2xl">
        {available ? (
          <>
            <Text variant="xl" fontWeight="bold" textAlign="center">
              {t('videoCall.downloadInvoice.title')}
            </Text>
            <Text variant="xl" textAlign="center">
              {t('videoCall.downloadInvoice.description')}
            </Text>
          </>
        ) : (
          <Text variant="xl" fontWeight="bold" textAlign="center">
            {t('videoCall.downloadInvoice.notAvailable')}
          </Text>
        )}
      </Box>
      <Stack justify="center" spacing="md" direction="column">
        {available && (
          <Button
            variant="filled"
            label={t('videoCall.downloadInvoice.button')}
            // eslint-disable-next-line react/no-unstable-nested-components
            icon={() => <Doc size="md" />}
            iconPosition="left"
            loading={buttonLoading}
            onClick={async () => {
              setButtonLoading(true);
              await downloadInvoice();
              setButtonLoading(false);
            }}
          />
        )}
        <Button
          variant={available ? 'ghost' : 'filled'}
          label={t('videoCall.error.button')}
          onClick={() => navigate('/')}
        />
      </Stack>
    </Stack>
  );
};
