import { redirect, Route } from 'react-router-dom';
import { GenericErrorPage } from '@unobravo-monorepo/common/components/errorPage';

export function getTeamLeaderRoutes() {
  return (
    <Route
      path="/team-leader/*"
      lazy={async () => {
        const { TeamLeaderProviders } = await import('./index');
        return { Component: TeamLeaderProviders };
      }}
    >
      <Route
        path=""
        lazy={async () => {
          const { TherapistList } = await import('./index');
          return { Component: TherapistList };
        }}
      />
      <Route
        path="therapist/:id/*"
        lazy={async () => {
          const { TherapistLayout } = await import('./index');
          return { Component: TherapistLayout };
        }}
      >
        <Route errorElement={<GenericErrorPage noHeader />}>
          <Route
            path="dashboard"
            lazy={async () => {
              const { Dashboard } = await import('./index');
              return { Component: Dashboard };
            }}
          />
          <Route
            path="log-event"
            lazy={async () => {
              const { LogEvent } = await import('./index');
              return { Component: LogEvent };
            }}
          />
          <Route
            path="in-therapy"
            lazy={async () => {
              const { InTherapy } = await import('./index');
              return { Component: InTherapy };
            }}
          />
          <Route
            path="new-invitations"
            lazy={async () => {
              const { NewInvitations } = await import('./index');
              return { Component: NewInvitations };
            }}
          />
          <Route
            path="profile"
            lazy={async () => {
              const { TherapistProfile } = await import('./index');
              return { Component: TherapistProfile };
            }}
          />
          <Route path="*" loader={() => redirect('profile')} />
        </Route>
      </Route>
      <Route path="*" loader={() => redirect('/team-leader')} />
    </Route>
  );
}
