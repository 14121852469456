import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Password: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V12C5 11.4696 5.21071 10.9609 5.58579 10.5858C5.96086 10.2107 6.46957 10 7 10H17C17.5304 10 18.0391 10.2107 18.4142 10.5858C18.7893 10.9609 19 11.4696 19 12V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15.541C8.997 15.541 8.995 15.543 8.995 15.546C8.995 15.549 8.997 15.551 9 15.551C9.003 15.551 9.005 15.549 9.005 15.546C9.005 15.543 9.003 15.541 9 15.541Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.005 15.541C12.002 15.541 12 15.543 12 15.546C12 15.549 12.003 15.551 12.005 15.551C12.008 15.551 12.01 15.549 12.01 15.546C12.01 15.543 12.008 15.541 12.005 15.541Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15.541C14.997 15.541 14.995 15.543 14.995 15.546C14.995 15.549 14.997 15.551 15 15.551C15.003 15.551 15.005 15.549 15.005 15.546C15.005 15.543 15.003 15.541 15 15.541Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
