import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import { IIcon } from '@unobravo/zenit-core';
import { Briefcase, Exam, Graduate } from '@unobravo/zenit-icons';
import { RStack, Text } from '@unobravo/zenit-web';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTherapistSpecialization } from '../hook/useTherapistSpecialization';
import { CircleIcon } from './circleIcon';

type Variant = 'job' | 'degree' | 'order';

interface CurriculumInfoProps {
  variant: Variant;
  doctorPresentation: PreMatchOptionFragment['doctorPresentation'];
  doctorOption: PreMatchOptionFragment;
}

const CURRENT_YEAR = new Date().getFullYear();

export const CurriculumInfo = ({
  variant,
  doctorPresentation,
  doctorOption
}: CurriculumInfoProps) => {
  const { t } = useTranslation();

  const { yearsOfExperience, degree, university } = doctorPresentation || {};

  const { doctorRegisterNumber, doctorRegisterRegion, sex } =
    doctorOption?.doctor || {};

  const { specializationJobKey } = useTherapistSpecialization(doctorOption);

  const contentMap = {
    degree: {
      icon: Graduate,
      title: 'selectYourTp.curriculum.degree',
      subtitle: ''
    },
    order: {
      icon: Exam,
      title: 'selectYourTp.curriculum.order',
      subtitle: 'selectYourTp.curriculum.orderNumber'
    },
    job: {
      icon: Briefcase,
      title: specializationJobKey,
      subtitle: 'selectYourTp.curriculum.jobWhen'
    }
  } as const satisfies Record<
    Variant,
    { icon: FC<IIcon>; title: string; subtitle: string }
  >;

  const { icon: Icon, title, subtitle } = contentMap[variant];

  const i18nVariables = {
    context: sex,
    region: doctorRegisterRegion,
    orderNumber: doctorRegisterNumber,
    specialization: degree,
    university,
    year: CURRENT_YEAR - (yearsOfExperience || 0)
  };

  return (
    <RStack w="100%" direction="row" spacing="xs" align="center">
      <CircleIcon
        icon={
          <Icon size="md" color="candy.400" style={{ alignSelf: 'center' }} />
        }
      />

      <RStack direction="column">
        <Text color="grey.600" variant="md">
          {t(title, i18nVariables)}
        </Text>
        {subtitle && (
          <Text color="grey.400" variant="sm">
            {t(subtitle, i18nVariables)}
          </Text>
        )}
      </RStack>
    </RStack>
  );
};
