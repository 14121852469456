import { captureException } from '@sentry/react';
import { IAppliedVoucher, usePaymentUtils } from '@unobravo/patient';
import { transformAmount } from '@unobravo-monorepo/common/utils/priceUtils';
import { useState } from 'react';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useBonus } from '../../patientData/hooks/useBonus';

export const useApplyVoucher = () => {
  const {
    applyPatientVoucher,
    verifyPatientVoucher,
    applyBonusVoucher: applyBonusVoucherUtil,
    verifyBonusVoucher
  } = usePaymentUtils();
  const { sendGenericError } = useErrorHandler();
  const { refetch: refetchBonusData } = useBonus();

  const [voucherLoading, setVoucherLoading] = useState(false);

  const applyVoucher = async (code: string, sessionUUID: string) => {
    const result = await applyPatientVoucher(code, sessionUUID);
    if (!result) {
      captureException({
        error: true,
        voucher: code,
        sessionUUID
      });
      sendGenericError();
    }
    return result;
  };

  const applyBonus = async (sessionUUID: string) => {
    const result = await applyBonusVoucherUtil(sessionUUID);
    if (!result || !result.success) {
      sendGenericError();
    }
    if (result?.success) {
      refetchBonusData();
    }
    return result;
  };

  const verifyVoucher = async (code: string, sessionUUID: string) => {
    const result = await verifyPatientVoucher(code, sessionUUID);
    if (!result || !result.success) {
      sendGenericError();
    }
    return result;
  };

  const verifyBonus = async (sessionUUID: string) => {
    const result = await verifyBonusVoucher(sessionUUID);
    if (!result) {
      sendGenericError();
    }
    return result;
  };

  const saveVoucherCode = (
    voucher: IAppliedVoucher,
    verify = true,
    savedTotal?: string,
    code?: string
  ) => {
    if (!voucher) {
      return false;
    }
    const { total } = voucher;
    const newTotal = transformAmount(total);

    if (!verify && savedTotal !== newTotal) {
      return false;
    }

    return voucher?.success;
  };

  const applyBonusVoucher = async (
    sessionUUID: string,
    total?: string,
    verify = true
  ): Promise<boolean> => {
    setVoucherLoading(true);
    const voucher = await (verify ? verifyBonus : applyBonus)(sessionUUID);
    setVoucherLoading(false);
    return !!voucher && saveVoucherCode(voucher, verify, total);
  };

  const applyVoucherCode = async (
    sessionUUID: string,
    code: string,
    total?: string,
    verify = true
  ): Promise<boolean> => {
    setVoucherLoading(true);
    const voucher = await (verify ? verifyVoucher : applyVoucher)(
      code,
      sessionUUID
    );
    setVoucherLoading(false);
    return !!voucher && saveVoucherCode(voucher, verify, total, code);
  };

  return {
    applyBonusVoucher,
    applyVoucherCode,
    applyVoucher,
    applyBonus,
    verifyBonus,
    verifyVoucher,
    voucherLoading
  };
};
