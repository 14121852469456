import { Country, useFirebaseUser } from '@unobravo/core';
import { setLocaleStorageCountry, useCountry } from '@unobravo/translations';
import { Alert, Box, Button, Heading, Stack, Text } from '@unobravo/zenit-web';
import { useState } from 'react';
import styled from 'styled-components';
import pkg from '../../../../../package.json';
import { Modal } from '../Modal';

const ActionButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
`;

const languages: Country[] = ['it', 'es', 'fr'];
const { NX_APP_ENVIRONMENT } = process.env;

const TranslationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="#fffff"
      viewBox="0 0 256 256"
    >
      <path d="M242.73,210.63l-56-112a12,12,0,0,0-21.46,0l-20.52,41A84.2,84.2,0,0,1,106,126.22,107.48,107.48,0,0,0,131.33,68H152a12,12,0,0,0,0-24H100V32a12,12,0,0,0-24,0V44H24a12,12,0,0,0,0,24h83.13A83.69,83.69,0,0,1,88,110.35,84,84,0,0,1,75.6,91a12,12,0,1,0-21.81,10A107.55,107.55,0,0,0,70,126.24,83.54,83.54,0,0,1,24,140a12,12,0,0,0,0,24,107.47,107.47,0,0,0,64-21.07,108.4,108.4,0,0,0,45.39,19.44l-24.13,48.26a12,12,0,1,0,21.46,10.73L143.41,196h65.17l12.68,25.36a12,12,0,1,0,21.47-10.73ZM155.41,172,176,130.83,196.58,172Z" />
    </svg>
  );
};

export const LanguageSwitcher = () => {
  const { domainCountry } = useCountry();
  const { logout } = useFirebaseUser();
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState(domainCountry);

  const handleConfirm = async () => {
    setLocaleStorageCountry(selected);
    await logout();
    window.location.reload();
  };

  if (NX_APP_ENVIRONMENT !== 'local') return <></>;

  return (
    <>
      <ActionButton
        onClick={() => setOpen(true)}
        iconPosition="iconOnly"
        icon={TranslationIcon}
        name="language-switcher"
        aria-label="language-switcher"
        data-testid="language-switcher"
      />
      <Modal
        open={open}
        fixedHeight={false}
        fullScreen
        padding="0"
        onClose={() => setOpen(false)}
      >
        <Stack direction="column" p="xl" spacing="xl">
          {/* Header */}
          <Box>
            <Heading variant="xl">Cambia Lingua</Heading>
            <Text variant="lg" color="grey.600">
              In quale lingua vorresti visualizzare questa applicazione?
            </Text>
          </Box>
          {/* Languages */}
          <Stack direction="row" spacing="xs">
            {languages.map((l) => (
              <Button
                key={l}
                variant={l === selected ? 'filled' : 'outlined'}
                label={l.toUpperCase()}
                onClick={() => setSelected(l)}
              />
            ))}
          </Stack>
          {/* Alert */}
          <Alert
            title="Attenzione"
            message="Cambiando la lingua verrai disconnesso da questa sessione e dovrai nuovamente effettuare l'accesso."
            type="warning"
          />
          {/* Actions */}
          <Stack direction="row" justify="space-between" align="center">
            <Text color="candy.500" variant="sm">
              Current Version: {pkg.version}
            </Text>

            <Stack direction="row" spacing="xs">
              <Button
                variant="ghost"
                label="Annulla"
                onClick={() => setOpen(false)}
              />
              <Button
                variant="filled"
                label="Conferma"
                disabled={selected === domainCountry}
                onClick={handleConfirm}
              />
            </Stack>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};
