import { useTheme } from '@unobravo/zenit-core';
import { ArrowRight, Plus } from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  Heading,
  ModalAlert,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { AvailabilityType } from './types/AvailabilityType';
import { DateLabel } from './DateLabel';

export const AvailabilityConfirm = ({
  availability,
  onAdd,
  onNext,
  onClose,
  onCancel,
  onDelete
}: {
  availability: AvailabilityType[];
  onAdd: () => void;
  onNext: () => void;
  onClose: () => void;
  onCancel: () => void;
  onDelete?: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();
  return (
    <ModalAlert
      open
      onClose={onClose}
      w={470}
      dataTestId="availability-confirm-close"
    >
      <Stack direction="column" spacing="2xs" mb="md">
        <Heading>{t('chat.availability.confirmTitle')}</Heading>
        <Text color="grey.600">
          {t('chat.availability.confirmDescription')}
        </Text>
      </Stack>
      <Stack direction="column" spacing="2xs">
        {availability.map((one, index) => {
          const startDateLabel = one.date.start
            ? one.date.start.toFormat('dd LLL')
            : '';
          const endDateLabel =
            one.date.start !== one.date.end
              ? one.date.end?.toFormat('dd LLL')
              : undefined;
          const timeLabel =
            one.time.start && one.time.end
              ? `${one.time.start.toFormat('HH:mm')}-${one.time.end.toFormat(
                  'HH:mm'
                )}`
              : undefined;

          return (
            <Box key={`availability_${index.toString()}`}>
              <DateLabel
                startDateLabel={startDateLabel}
                endDateLabel={endDateLabel}
                timeLabel={timeLabel}
                onDelete={() => onDelete?.(index)}
              />
            </Box>
          );
        })}
      </Stack>
      {availability.length < 3 && (
        <Stack mt="md" justify="end">
          <Button
            label={t('chat.availability.addButton')}
            data-testid="add-availability"
            colorVariant="primary"
            variant="outlined"
            icon={Plus}
            onClick={onAdd}
          />
        </Stack>
      )}
      <Stack justify="space-between" style={{ marginTop: spacing['6xl'] }}>
        <Button
          data-testid="availability-confirm-cancel"
          size="lg"
          variant="ghost"
          label={t('back')}
          onClick={onCancel}
        />
        <Button
          data-testid="availability-confirm-next"
          iconPosition="right"
          size="lg"
          icon={ArrowRight}
          label={t('buttonNext')}
          onClick={onNext}
        />
      </Stack>
    </ModalAlert>
  );
};
