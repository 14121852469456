import { createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';

export interface IReassignmentState {
  isRequestOpen: boolean;
  reassignmentAnswer: string | undefined;
  isReassignmentModalOpen: boolean;
}

const initialState: IReassignmentState = {
  isRequestOpen: false,
  reassignmentAnswer: undefined,
  isReassignmentModalOpen: false
};

export const reassignmentSlice = createSlice({
  name: 'reassignment',
  initialState,
  reducers: {
    setReassignmentModalAnswer: (state, action) => {
      state.reassignmentAnswer = action.payload;
    },
    closeTimeSlotModal: (state) => {
      state.reassignmentAnswer = undefined;
    },
    openReassignmentModal: (state) => {
      state.isReassignmentModalOpen = true;
    },
    closeReassignmentModal: (state) => {
      state.isReassignmentModalOpen = false;
    }
  }
});

export default reassignmentSlice.reducer;

export const {
  setReassignmentModalAnswer,
  closeTimeSlotModal,
  openReassignmentModal,
  closeReassignmentModal
} = reassignmentSlice.actions;

export const reassignmentSelector = (
  state: PatientRootState
): IReassignmentState => state.reassignment;
