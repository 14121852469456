import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { BodySize, TypographyVariant } from '../Typography';
import { Theme } from '../../theme';
import mergeRefs from '../../utils/mergeRefs';
import { Body } from '../Typography/Body';

interface IRadioButton extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  dataTestId?: string;
}

const OuterRadioItem = styled.div<{
  disabled: boolean | undefined;
}>`
  flex-shrink: 0;
  align-items: center;
  background-color: ${Theme.specialColors.white};
  border: ${({ disabled }) => {
    if (disabled) {
      return `2px solid ${Theme.colors.gray[200]}`;
    }
    return `2px solid ${Theme.colors.gray[200]}`;
  }};
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  margin: 4px 4px 4px 0;
  width: 20px;
  outline: none;
  > * {
    display: none;
  }
  &:focus {
    border: ${({ disabled }) => {
      if (disabled) {
        return `2px solid ${Theme.colors.gray[200]}`;
      }
      return `2px solid ${Theme.colors.candy[200]}`;
    }};
  }
`;

const RadioInput = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
  &:checked + div {
    border: 2px solid ${Theme.colors.candy[500]};
    > * {
      display: block;
    }
  }
`;

const RadioItemCheck = styled.div<{ disabled: boolean | undefined }>`
  background-color: ${Theme.colors.candy[500]};
  background-color: ${({ disabled }) =>
    disabled ? Theme.colors.gray[200] : Theme.colors.candy[500]};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`;

const Wrapper = styled.label<{ disabled: boolean | undefined }>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  margin: 4px 4px 4px 0;
`;

export const RadioButton = forwardRef<HTMLInputElement, IRadioButton>(
  (props, forwardedRef) => {
    const innerRef = React.useRef<HTMLInputElement>(null);
    const combinedRef = mergeRefs([forwardedRef, innerRef]);
    const { disabled, label } = props;

    return (
      <Wrapper disabled={disabled}>
        <RadioInput
          ref={combinedRef}
          {...props}
          type="radio"
          data-testid="radio-input"
        />
        <OuterRadioItem disabled={disabled} tabIndex={0}>
          <RadioItemCheck disabled={disabled} />
        </OuterRadioItem>
        {label && (
          <Body
            size={BodySize.Body80}
            variant={TypographyVariant.Light}
            color={disabled ? Theme.colors.gray[200] : undefined}
            margin="1px 0 0 4px"
            data-testid={props.dataTestId}
          >
            {label}
          </Body>
        )}
      </Wrapper>
    );
  }
);

RadioButton.displayName = 'RadioButton';
