import { useQuery } from '@apollo/client';
import { GetCurrentPreMatchDocument } from '@unobravo-monorepo/common/graphql/queries.generated';

export const useGetCurrentPreMatch = (patientId: number) => {
  const { data, loading, error, refetch } = useQuery(
    GetCurrentPreMatchDocument,
    { variables: { patientId }, skip: !patientId }
  );

  return {
    data: data?.getCurrentPreMatch,
    loading,
    refetch,
    error
  };
};
