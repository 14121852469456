import { useQuery } from '@apollo/client';
import { useFirebaseUser } from '@unobravo/core';
import { GET_PREF_TIME } from '@unobravo/patient';

const TimeSlot = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',
  NO_PREF: 'noPreference'
};

export const useTimeSlot = () => {
  const { currentUser } = useFirebaseUser();

  const { data, loading } = useQuery(GET_PREF_TIME, {
    variables: { id: currentUser.firebaseId }
  });

  const checkPrefTime = () => {
    if (loading || !data?.getPatientFromFirebaseId) return [];

    const { prefTimeMorning, prefTimeAfternoon, prefTimeEvening } =
      data.getPatientFromFirebaseId;

    const timeSlots = {
      [TimeSlot.MORNING]: prefTimeMorning,
      [TimeSlot.AFTERNOON]: prefTimeAfternoon,
      [TimeSlot.EVENING]: prefTimeEvening,
      [TimeSlot.NO_PREF]:
        !prefTimeMorning && !prefTimeAfternoon && !prefTimeEvening
    };

    return Object.entries(timeSlots)
      .filter(([, value]) => value)
      .map(([key]) => key);
  };

  return {
    loading,
    prefTime: checkPrefTime()
  };
};
