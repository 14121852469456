import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetPatientConsentDataQueryVariables = Types.Exact<{
  id: Types.Scalars['Float'];
}>;


export type GetPatientConsentDataQuery = { __typename?: 'Query', getPatientConsentData?: { __typename?: 'InformedConsent', id: string, patient?: { __typename?: 'InformedConsentRecipient', name?: string | null, surname?: string | null, taxCode?: string | null, residenceTown?: string | null, residenceZip?: string | null, residenceStreet?: string | null, residenceProvince?: string | null, residenceCountry?: string | null } | null } | null };


export const GetPatientConsentDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getPatientConsentData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getPatientConsentData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"patient"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"surname"}},{"kind":"Field","name":{"kind":"Name","value":"taxCode"}},{"kind":"Field","name":{"kind":"Name","value":"residenceTown"}},{"kind":"Field","name":{"kind":"Name","value":"residenceZip"}},{"kind":"Field","name":{"kind":"Name","value":"residenceStreet"}},{"kind":"Field","name":{"kind":"Name","value":"residenceProvince"}},{"kind":"Field","name":{"kind":"Name","value":"residenceCountry"}}]}}]}}]}}]} as unknown as DocumentNode<GetPatientConsentDataQuery, GetPatientConsentDataQueryVariables>;