import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Plane: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32263 19.8781L20.7516 13.1791C21.7496 12.7161 21.7496 11.2821 20.7516 10.8191L6.32263 4.12205C5.28763 3.64205 4.19263 4.66005 4.57963 5.74305L6.81263 11.9971L4.57963 18.2581C4.19263 19.3401 5.28763 20.3581 6.32263 19.8781Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80969 12.0001H21.4997"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
