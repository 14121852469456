import { useQuery } from '@apollo/client';
import { ListPatientBundlesDocument } from '../../../features/home/graphql/queries.generated';
import { BundleCard } from './BundleCard';

export const HomepageBundleWidget = ({ patientId }: { patientId: number }) => {
  const { data, error } = useQuery(ListPatientBundlesDocument, {
    variables: {
      patientId
    }
  });

  if (error) return null;

  const bundlesList = data?.listPatientBundles || [];
  const latestBundle = bundlesList[bundlesList.length - 1];

  return (
    <>
      {latestBundle ? (
        <BundleCard isInHomepage bundle={latestBundle} />
      ) : (
        <BundleCard />
      )}
    </>
  );
};
