import { useFirebaseUser } from '@unobravo/core';

const { NX_APP_ENVIRONMENT } = process.env;

function getSuffix(role?: string) {
  const isDoctorRole = role === 'doctor' || role === 'onboarding_doctor';
  const isProduction = NX_APP_ENVIRONMENT === 'production';

  if (!isProduction && isDoctorRole) {
    return 'STP-';
  }
  if (!isProduction && !isDoctorRole) {
    return 'S-';
  }
  if (isProduction && isDoctorRole) {
    return 'TP-';
  }
  return '';
}

export const useGTM = () => {
  const { currentUser } = useFirebaseUser();
  const { role, firebaseId } = currentUser || {};

  const suffix = getSuffix(currentUser?.role);

  const getUserInfo = (unbvId?: string, email?: string) => {
    return {
      user_id: unbvId ? `${suffix}${unbvId}` : undefined,
      firebase_id: firebaseId,
      role,
      email
    };
  };

  const pushEvent = (event: string, eventProps?: Record<string, unknown>) => {
    window.dataLayer.push({
      event,
      event_id: event,
      ...(eventProps && {
        ...eventProps,
        application: (eventProps?.['application'] as string) || 'WEB'
      })
    });
  };
  const removeNullishValues = (
    data?: Record<string, unknown>
  ): Record<string, unknown> | undefined => {
    if (!data) return data;

    const cleanedData: Record<string, unknown> = {};

    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== null && data[key] !== '') {
        cleanedData[key] = data[key];
      }
    });

    return cleanedData;
  };

  const pushAuthenticatedEvent = (
    event: string,
    eventProps?: Record<string, unknown>
  ) => {
    pushEvent(event, {
      event_id: event,
      ...removeNullishValues(eventProps),
      ...removeNullishValues(
        getUserInfo(
          eventProps?.['user_id'] as string,
          eventProps?.['email'] as string
        )
      )
    });
  };

  const pushSetPasswordEvent = (email: string) => {
    pushEvent('SetPassword', { event_id: 'SetPassword', email });
  };

  return {
    pushEvent,
    pushAuthenticatedEvent,
    pushSetPasswordEvent
  };
};
