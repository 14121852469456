import { SurveyForSession } from '../types/therapyFeedback.types';

export const feedbackSteps = [
  {
    id: 1,
    name: 'relationWithDoctor',
    question: 'feedbackModal.firstQuestion',
    description: 'feedbackModal.instruction',
    answers: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 }
    ],
    buttonLabel: 'feedbackModal.next',
    nextTestId: 'therapy-nav-next-question-1',
    backTestId: 'therapy-nav-back-question-1'
  },
  {
    id: 2,
    name: 'doctorConcentration',
    question: 'feedbackModal.secondQuestion',
    description: 'feedbackModal.instruction',
    answers: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 }
    ],
    buttonLabel: 'feedbackModal.next',
    nextTestId: 'therapy-nav-next-question-2',
    backTestId: 'therapy-nav-back-question-2'
  },
  {
    id: 3,
    name: 'doctorContext',
    question: 'feedbackModal.thirdQuestion',
    description: 'feedbackModal.instruction',
    answers: [
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
      { label: '5', value: 5 }
    ],
    buttonLabel: 'feedbackModal.send',
    nextTestId: 'therapy-nav-send-question-3',
    backTestId: 'therapy-nav-back-question-3'
  }
];

export const surveysForSession: SurveyForSession = {
  2: {
    id: 'qos_first_session',
    title: 'home.feedbackMessage.firstSessionMessage',
    surveyId: 'session1'
  },
  4: {
    id: 'qos_third_session',
    title: 'home.feedbackMessage.thirdSessionMessage',
    surveyId: 'session3'
  }
};
