import {
  Alignment,
  Layout as RiveLayout,
  useRive,
  useStateMachineInput
} from '@rive-app/react-canvas';
import {
  PageContainer,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import {
  Body,
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import animation from '@unobravo-monorepo/patient/assets/animation/new_mgm_bg.riv';
import mgmBgSphereDesktop from '@unobravo-monorepo/patient/assets/mgm_bg_desktop_sphere.svg';
import mgmBgSphereMobile from '@unobravo-monorepo/patient/assets/mgm_bg_mobile_sphere.svg';
import { useInternationalVariables } from '@unobravo/translations';
import { useTheme } from '@unobravo/zenit-core';
import { Share } from '@unobravo/zenit-icons';
import {
  Button,
  Heading,
  ModalAlert,
  RBox,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from '../../../shared/components/CopyToClipboard';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { useToast } from '../../toaster/hooks/useToast';
import { MGMAlert } from '../components/MGMAlert';
import { MGMCounter } from '../components/MGMCounter';
import { MGMInfo } from '../components/MGMInfo';
import { CodeStatus, useVoucherData } from '../hooks/useVoucherData';

const canShare = !!navigator.share;
const shareDiscountCode = ({ text, title, url }: ShareData) => {
  if (!canShare) return null;
  return navigator.share({ title, text, url });
};

const DISCOUNT_AMOUNT = '10€';

export const MemberGetMemberPage = () => {
  const { t } = useTranslation();
  const { sendToast } = useToast();
  const { colors, margin } = useTheme();
  const { isMobile, breakpoint } = useBreakpointValue();

  const { mgmShareUrl } = useInternationalVariables();

  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);

  const { RiveComponent: BackgroundAnimation, rive } = useRive({
    src: animation,
    stateMachines: 'mgm-states',
    autoplay: true,
    layout: new RiveLayout({
      alignment: Alignment.BottomCenter
    })
  });

  const backgroundInput = useStateMachineInput(
    rive,
    'mgm-states',
    'code-fired'
  );

  const redeemedNumberInput = useStateMachineInput(
    rive,
    'mgm-states',
    'number-refer',
    0
  );

  const onMutationCompleted = () => {
    if (!backgroundInput) return;
    backgroundInput.fire();
  };

  const {
    data: voucherData,
    codeStatus,
    generatingCode
  } = useVoucherData({ onMutationCompleted });

  if (redeemedNumberInput) {
    redeemedNumberInput.value =
      voucherData?.referralCode?.redemptionsCount || 0;
  }

  const onShareClick = () => {
    const title = t('memberGetMember.shareTitle');
    const text = t('memberGetMember.shareText', {
      discountCode: voucherData?.referralCode?.code,
      discountAmount: DISCOUNT_AMOUNT,
      link: mgmShareUrl
    });
    // eslint-disable-next-line no-console
    return shareDiscountCode({ text, title })?.catch(console.error);
  };

  const currentCodeComponent: Record<CodeStatus, ReactNode> = {
    initial: (
      <Button
        data-testid="generate-mgm-code"
        label={t('memberGetMember.card.ctaCode')}
        onClick={generatingCode}
        size="lg"
      />
    ),
    not_eligible: (
      <MGMAlert
        type="warning"
        title={t('memberGetMember.notEligible.title')}
        message={t('memberGetMember.notEligible.description')}
      />
    ),
    generating_code: (
      <Button
        data-testid="generate-mgm-code"
        label={t('memberGetMember.card.ctaCode')}
        loading
        size="lg"
      />
    ),
    code_generated: (
      <RStack direction={{ base: 'column', md: 'row' }} spacing="md">
        <RStack spacing="2xs" direction="column" w="100%">
          <CopyToClipboard
            variant="code_generated"
            value={voucherData?.referralCode?.code ?? ''}
            onCopySuccess={() => {
              sendToast({
                variant: 'success',
                title: t('memberGetMember.codeGenerated.codeCopied')
              });
            }}
            style={{ minWidth: '250px', width: '102%' }}
          />
        </RStack>

        {canShare && (
          <Button
            label={t('memberGetMember.shareButtonTitle')}
            icon={Share}
            onClick={onShareClick}
            size="lg"
          />
        )}
      </RStack>
    ),
    code_redeemed: (
      <MGMAlert
        type="success"
        title={t('memberGetMember.codeRedeemed.title')}
        message={t('memberGetMember.codeRedeemed.description')}
      />
    ),
    error_code: <MGMAlert type="critical" title={t('common:errorPage.ops3')} />
  };

  return (
    <PatientLayout>
      <PageContainer>
        <PageContainerHeader>
          <Body
            size={BodySize.Body80}
            variant={TypographyVariant.SemiBold}
            color={colors.black}
            margin="10px"
          >
            {t('dashboard.sections.memberGetMember')}
          </Body>
        </PageContainerHeader>

        <RStack direction="column" maxW={{ xl: '60%' }}>
          <RStack
            rounded="xl"
            ml="md"
            mr="md"
            mt="md"
            bgColor="cappuccino.300"
            mb={{ md: 'xl' }}
            h={{ base: '36dvh', xs: '55dvh', md: '474px' }}
            position="relative"
            overflow="hidden"
          >
            <RBox
              style={{
                backgroundImage: isMobile
                  ? `url(${mgmBgSphereMobile})`
                  : `url(${mgmBgSphereDesktop})`,
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: isMobile ? 'center' : 'right',
                backgroundPositionY: 'bottom',
                display: breakpoint === 'xs' ? 'none' : undefined
              }}
              w={{ base: 'auto', md: '866px' }}
              minW={{ base: '100%', md: undefined }}
              h={{ base: '115%', md: '599px' }}
              position="absolute"
              bottom={isMobile ? undefined : 0}
              right={isMobile ? undefined : 0}
            />

            <RStack
              direction="column"
              w={{ base: '100%', md: '700px' }}
              h={{ base: '295px', sm: '380px', md: '490px' }}
              align="center"
              spacing="2xl"
              position="absolute"
              right={0}
              bottom={0}
              style={{
                display: breakpoint === 'xs' ? 'none' : undefined,
                zIndex: isMobile ? 2 : 0
              }}
              mb="2xl"
            >
              <BackgroundAnimation />

              {(codeStatus === 'code_redeemed' ||
                codeStatus === 'code_generated') && (
                <MGMCounter
                  limit={voucherData?.referralCampaign?.redemptionsLimit || 0}
                  redeemed={voucherData?.referralCode?.redemptionsCount || 0}
                />
              )}
            </RStack>
            <RStack
              direction="column"
              w={{ base: '330px', md: '324px' }}
              mt={{ base: 'xl', md: '2xl' }}
              mb={{ md: '2xl' }}
              style={{
                marginLeft: isMobile ? 'auto' : margin['2xl'],
                marginRight: isMobile ? 'auto' : margin['2xl']
              }}
            >
              <RStack
                direction="column"
                spacing="2xs"
                w="100%"
                align={{ base: 'center', md: 'start' }}
                style={{ zIndex: 1 }}
              >
                <Heading
                  color="candy.800"
                  fontWeight="semibold"
                  variant="xl"
                  textAlign={isMobile ? 'center' : undefined}
                  style={{ fontSize: isMobile ? '26px' : undefined }}
                >
                  {t('memberGetMember.card.title')}
                </Heading>

                <RBox w={{ base: '90%', md: '95%' }}>
                  <Text
                    variant="lg"
                    color="candy.800"
                    textAlign={isMobile ? 'center' : undefined}
                  >
                    {t('memberGetMember.card.description', {
                      discountAmount: DISCOUNT_AMOUNT
                    })}
                  </Text>
                </RBox>
              </RStack>

              <RStack
                direction="column"
                style={{
                  marginTop: isMobile ? undefined : 'auto',
                  position: isMobile ? 'fixed' : 'relative'
                }}
                spacing={{ base: 'xs', md: 'md' }}
                mb={{ base: '2xl', md: undefined }}
                bottom={{ base: 0, md: undefined }}
                left={{ base: 0, md: undefined }}
                w={{ base: '100%', md: undefined }}
                px={{ base: 'md', md: undefined }}
              >
                {isMobile && (
                  <Stack
                    justify="center"
                    onClick={() => {
                      setIsOpenInfoModal(true);
                    }}
                    mb="xs"
                  >
                    <Text
                      textAlign="center"
                      variant="lg"
                      fontWeight="bold"
                      color="black"
                      style={{ textDecoration: 'underline' }}
                    >
                      {t('memberGetMember.card.ctaInfo')}
                    </Text>
                  </Stack>
                )}
                {currentCodeComponent[codeStatus]}
              </RStack>
            </RStack>
          </RStack>

          {isMobile && (
            <ModalAlert
              open={isOpenInfoModal}
              onClose={() => {
                setIsOpenInfoModal(false);
              }}
            >
              <MGMInfo />
            </ModalAlert>
          )}
          {!isMobile && <MGMInfo />}
        </RStack>
      </PageContainer>
    </PatientLayout>
  );
};
