import { useLazyQuery } from '@apollo/client';
import { useCountry } from '@unobravo/translations';
import { PlatformCountry } from '@unobravo/patient';
import { usePatient } from '../../patientData/hooks/usePatient';
import { GetCitiesByNameDocument } from '../graphql/query.generated';

export const useCities = (limit = 5) => {
  const { domainCountry } = useCountry();
  const { id } = usePatient();
  const [getCities] = useLazyQuery(GetCitiesByNameDocument);

  return {
    getCities: async (city: string) => {
      try {
        if (id) {
          const { data } = await getCities({
            variables: {
              cityName: city,
              patientId: id!,
              limit,
              platformCountry: domainCountry.toUpperCase() as PlatformCountry
            }
          });
          return data?.getCitiesByName ?? [];
        }
        return [];
      } catch (e) {
        return [];
      }
    }
  };
};
