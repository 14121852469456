import React from 'react';
import IIcon from '@unobravo-monorepo/common/types/IIcon';

const StepNumbers = {
  first: (
    <path
      d="M80.7741 28.7601V90.6587H67.6871V41.1821H67.3245L53.1494 50.068V38.462L68.473 28.7601H80.7741Z"
      fill="white"
    />
  ),
  second: (
    <path
      d="M47.9774 90.6587V81.2288L70.0107 60.8277C71.8846 59.0142 73.4562 57.3822 74.7256 55.9314C76.0152 54.4807 76.9924 53.0601 77.6573 51.6698C78.3223 50.2594 78.6547 48.7381 78.6547 47.106C78.6547 45.2926 78.2417 43.731 77.4155 42.4213C76.5894 41.0915 75.4611 40.0739 74.0305 39.3687C72.5999 38.6433 70.9778 38.2806 69.1644 38.2806C67.2704 38.2806 65.6181 38.6635 64.2077 39.4292C62.7972 40.1948 61.7092 41.293 60.9435 42.7236C60.1778 44.1542 59.795 45.8568 59.795 47.8314H47.373C47.373 43.7814 48.2898 40.2653 50.1233 37.2833C51.9569 34.3012 54.526 31.9941 57.8304 30.362C61.1349 28.7299 64.9431 27.9138 69.2551 27.9138C73.6879 27.9138 77.5465 28.6997 80.8308 30.2713C84.1353 31.8228 86.7044 33.9788 88.5379 36.7392C90.3715 39.4997 91.2883 42.6631 91.2883 46.2295C91.2883 48.5668 90.8249 50.8739 89.898 53.1508C88.9913 55.4277 87.3693 57.9564 85.032 60.737C82.6946 63.4975 79.4002 66.812 75.1487 70.6807L66.1118 79.5363V79.9594H92.1044V90.6587H47.9774Z"
      fill="white"
    />
  ),
  third: (
    <path
      d="M69.493 91.505C64.9796 91.505 60.9598 90.7292 57.4337 89.1777C53.9277 87.6061 51.1572 85.4501 49.1221 82.7098C47.1072 79.9494 46.0695 76.7658 46.009 73.159H59.1867C59.2673 74.6702 59.7609 76.0001 60.6676 77.1486C61.5945 78.277 62.8236 79.1535 64.355 79.7781C65.8863 80.4027 67.6091 80.715 69.5232 80.715C71.518 80.715 73.2811 80.3624 74.8124 79.6572C76.3438 78.952 77.5427 77.9747 78.4091 76.7255C79.2755 75.4762 79.7087 74.0355 79.7087 72.4034C79.7087 70.7512 79.2453 69.2904 78.3184 68.021C77.4117 66.7314 76.102 65.724 74.3893 64.9986C72.6968 64.2732 70.6818 63.9105 68.3445 63.9105H62.5717V54.2993H68.3445C70.3191 54.2993 72.0621 53.9568 73.5733 53.2717C75.1046 52.5866 76.2934 51.6396 77.1397 50.4307C77.9859 49.2016 78.4091 47.771 78.4091 46.1389C78.4091 44.5874 78.0363 43.2273 77.2908 42.0586C76.5654 40.8698 75.5378 39.943 74.208 39.278C72.8983 38.6131 71.3669 38.2806 69.6139 38.2806C67.8408 38.2806 66.2188 38.603 64.7479 39.2478C63.277 39.8724 62.0982 40.7691 61.2117 41.9377C60.3251 43.1064 59.8516 44.4765 59.7911 46.0482H47.2482C47.3087 42.4818 48.3262 39.3385 50.3008 36.6183C52.2755 33.8982 54.9352 31.7724 58.28 30.2411C61.6449 28.6896 65.443 27.9138 69.6744 27.9138C73.946 27.9138 77.6837 28.6896 80.8874 30.2411C84.0912 31.7926 86.5796 33.8881 88.3527 36.5277C90.146 39.1471 91.0326 42.0889 91.0125 45.353C91.0326 48.8187 89.9546 51.7101 87.7785 54.0273C85.6225 56.3445 82.8117 57.8154 79.346 58.44V58.9236C83.8998 59.5079 87.3654 61.0896 89.7431 63.6687C92.1408 66.2277 93.3296 69.4314 93.3095 73.2799C93.3296 76.8061 92.3121 79.9393 90.2569 82.6796C88.2218 85.4199 85.411 87.5759 81.8244 89.1475C78.2378 90.7191 74.1274 91.505 69.493 91.505Z"
      fill="white"
    />
  )
};

export interface IStepIcon extends IIcon {
  step: 'first' | 'second' | 'third';
}

export const StepIcon: React.FC<IStepIcon> = ({
  width = 140,
  height = 140,
  step = 'first'
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="140" height="140" fill="none" />
      <circle
        cx="69.9989"
        cy="59.9486"
        r="53.5018"
        fill="url(#paint0_linear_2895_31711)"
      />
      <path
        d="M52.4103 69.6004C53.3877 69.6004 54.18 68.8081 54.18 67.8307C54.18 66.8534 53.3877 66.061 52.4103 66.061C51.4329 66.061 50.6406 66.8534 50.6406 67.8307C50.6406 68.8081 51.4329 69.6004 52.4103 69.6004Z"
        fill="#FFF4F3"
      />
      <path
        d="M39.1923 14.3553C40.7655 14.3553 42.0408 13.08 42.0408 11.5068C42.0408 9.93354 40.7655 8.6582 39.1923 8.6582C37.6191 8.6582 36.3438 9.93354 36.3438 11.5068C36.3438 13.08 37.6191 14.3553 39.1923 14.3553Z"
        fill="#FFF4F3"
      />
      <path
        d="M41.6485 34.2691C43.2504 34.2691 44.5489 33.1329 44.5489 31.7312C44.5489 30.3296 43.2504 29.1934 41.6485 29.1934C40.0466 29.1934 38.748 30.3296 38.748 31.7312C38.748 33.1329 40.0466 34.2691 41.6485 34.2691Z"
        fill="#FFF4F3"
      />
      {StepNumbers[step]}
      <mask
        id="mask0_2895_31711"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="6"
        width="108"
        height="108"
      >
        <circle
          cx="69.9989"
          cy="59.9486"
          r="53.5018"
          fill="url(#paint1_linear_2895_31711)"
        />
      </mask>
      <g mask="url(#mask0_2895_31711)">
        <path
          d="M80.0217 89.5549C80.0217 89.5549 87.5753 89.1069 90.2345 88.1671C92.8937 87.2272 98.5596 83.9142 98.5596 83.9142C98.5596 83.9142 91.7492 82.2768 88.3157 83.513C84.8823 84.7492 80.1574 88.9184 80.1574 88.9184L80.0217 89.5549Z"
          fill="#D1E7D9"
        />
        <path
          d="M95.3975 84.3892C95.3456 84.4053 95.2927 84.4181 95.2392 84.4274C95.1361 84.4501 94.9835 84.4827 94.7857 84.521C94.39 84.5975 93.8164 84.6996 93.1057 84.8187C92.395 84.9378 91.5514 85.0753 90.6146 85.244C90.147 85.3305 89.6567 85.4269 89.1551 85.5474C88.9036 85.6083 88.6521 85.6792 88.4006 85.7628C88.149 85.8465 87.906 85.9712 87.6545 86.079C86.6414 86.5043 85.658 86.8728 84.7693 87.2131C83.8805 87.5533 83.0836 87.8694 82.421 88.1487C81.7583 88.428 81.2284 88.6718 80.8667 88.8377L80.4428 89.0319C80.3944 89.0557 80.3444 89.0761 80.2931 89.0928C80.3381 89.0628 80.3853 89.0362 80.4344 89.0135L80.8484 88.8036C81.2101 88.6279 81.7357 88.3784 82.4026 88.0948C83.0695 87.8113 83.8579 87.481 84.7467 87.1379C85.6354 86.7949 86.616 86.4291 87.6248 85.9967C87.8778 85.889 88.1208 85.7742 88.3779 85.6792C88.6351 85.5842 88.8894 85.5233 89.1423 85.4623C89.6468 85.3418 90.1314 85.2468 90.6076 85.1632C91.5458 84.9959 92.3935 84.8683 93.1042 84.7549L94.7871 84.487L95.2449 84.4118C95.2949 84.3993 95.346 84.3917 95.3975 84.3892Z"
          fill="#E0E0E0"
        />
        <path
          opacity="0.1"
          d="M80.1569 88.9176L80.0156 89.5541C80.0156 89.5541 81.9937 89.4379 84.277 89.1969L88.3096 83.5122C84.8803 84.7484 80.1569 88.9176 80.1569 88.9176Z"
          fill="black"
        />
        <path
          d="M74.2393 110.657C74.2393 110.657 83.2525 109.873 86.4019 108.662C89.5514 107.452 96.2148 103.307 96.2148 103.307C96.2148 103.307 88.0198 101.571 83.9561 103.165C79.8925 104.758 74.3806 109.892 74.3806 109.892L74.2393 110.657Z"
          fill="#D1E7D9"
        />
        <path
          d="M92.4521 103.979C92.4369 103.987 92.4207 103.992 92.4041 103.995L92.2628 104.029C92.1399 104.059 91.9576 104.103 91.7231 104.154C91.2526 104.257 90.5687 104.396 89.7237 104.562C88.8788 104.728 87.8714 104.918 86.7566 105.153C86.1999 105.271 85.6177 105.403 85.0215 105.564C84.7229 105.644 84.4238 105.738 84.1242 105.848C83.8332 105.964 83.5407 106.113 83.2426 106.249C82.0458 106.799 80.8829 107.264 79.8317 107.702C78.7805 108.14 77.838 108.541 77.0553 108.9C76.2725 109.259 75.6423 109.565 75.2184 109.776L74.7225 110.023L74.5911 110.085C74.5772 110.094 74.5619 110.1 74.5459 110.104C74.5459 110.104 74.5586 110.094 74.5869 110.077L74.7126 110.006L75.2015 109.744C75.6254 109.521 76.2499 109.202 77.0313 108.839C77.8126 108.476 78.7508 108.059 79.802 107.62C80.8533 107.18 82.0147 106.711 83.2072 106.163C83.5054 106.021 83.7936 105.879 84.096 105.759C84.3984 105.638 84.7007 105.554 85.0017 105.475C85.6008 105.313 86.1857 105.184 86.7424 105.07C87.8587 104.839 88.8619 104.657 89.7096 104.503L91.7118 104.123L92.2557 104.016L92.397 103.991C92.4147 103.984 92.4333 103.98 92.4521 103.979Z"
          fill="#E0E0E0"
        />
        <path
          opacity="0.1"
          d="M74.3776 109.891L74.2363 110.658C74.2363 110.658 77.3999 110.381 80.6341 109.906L83.9531 103.163C79.8881 104.753 74.3776 109.891 74.3776 109.891Z"
          fill="black"
        />
        <path
          d="M76.7366 100.024C76.7366 100.024 84.8582 99.7407 87.7392 98.8093C90.6202 97.8779 96.792 94.4686 96.792 94.4686C96.792 94.4686 89.5238 92.525 85.8035 93.7597C82.0832 94.9945 76.9019 99.3438 76.9019 99.3438L76.7366 100.024Z"
          fill="#D1E7D9"
        />
        <path
          d="M93.3838 94.8983C93.3698 94.9042 93.3551 94.908 93.34 94.9096L93.2128 94.9351C93.1012 94.9564 92.9302 94.9876 92.7225 95.0216C92.2986 95.0939 91.6769 95.1861 90.9111 95.2952C90.1453 95.4044 89.234 95.5277 88.2265 95.6879C87.7221 95.7673 87.1923 95.8566 86.6511 95.9714C86.3798 96.0281 86.1057 96.099 85.8344 96.1812C85.5631 96.2634 85.2975 96.391 85.0234 96.5002C83.9255 96.9382 82.8588 97.2983 81.8965 97.6414C80.9343 97.9844 80.0682 98.3006 79.3532 98.5841C78.6383 98.8676 78.059 99.1129 77.6577 99.2816L77.2013 99.48L77.0812 99.5282C77.0678 99.535 77.0536 99.5397 77.0388 99.5424C77.0506 99.5333 77.0634 99.5257 77.077 99.5197L77.1928 99.4616L77.6436 99.2476C78.0364 99.0689 78.6086 98.8123 79.3264 98.5217C80.0442 98.2311 80.9046 97.9036 81.8697 97.5592C82.8347 97.2147 83.8959 96.8503 84.9909 96.4123C85.265 96.3031 85.5306 96.1855 85.8076 96.0919C86.0845 95.9984 86.3614 95.9374 86.6341 95.8793C87.1795 95.763 87.7094 95.6751 88.2152 95.5957C89.2269 95.4398 90.1397 95.325 90.9069 95.2229L92.7211 94.9791L93.2142 94.911L93.3428 94.8954C93.3565 94.8944 93.3703 94.8953 93.3838 94.8983Z"
          fill="#E0E0E0"
        />
        <path
          opacity="0.1"
          d="M76.8974 99.3477L76.7349 100.028C76.7349 100.028 80.1471 99.8396 82.5379 99.6539L85.8018 93.7637C82.0801 94.9998 76.8974 99.3477 76.8974 99.3477Z"
          fill="black"
        />
        <path
          d="M81.5879 84.5117C81.5879 84.5117 76.798 101.247 74.1275 113.651L72.8432 113.57C74.3819 103.994 77.3335 94.8037 81.5879 84.5117Z"
          fill="#D1E7D9"
        />
        <path
          d="M74.5215 110.538C74.5215 110.538 70.4649 102.424 68.1816 99.9345C65.8983 97.4452 59.5965 92.7642 59.5965 92.7642C59.5965 92.7642 61.0095 101.045 63.9908 104.247C66.9721 107.449 73.7599 110.69 73.7599 110.69L74.5215 110.538Z"
          fill="#D1E7D9"
        />
        <path
          d="M61.602 96.0239C61.602 96.0239 61.6147 96.0353 61.6345 96.0622L61.7193 96.177L62.0415 96.6222C62.324 97.0134 62.731 97.5734 63.2524 98.2553C63.7737 98.9371 64.411 99.7367 65.1415 100.606C65.5074 101.039 65.896 101.49 66.3086 101.95C66.7211 102.409 67.1634 102.872 67.5802 103.374C68.428 104.378 69.1896 105.366 69.9102 106.244C70.6308 107.121 71.3047 107.889 71.8883 108.512C72.4718 109.134 72.9805 109.613 73.3281 109.944L73.7364 110.316L73.841 110.414C73.865 110.437 73.8763 110.45 73.8749 110.451C73.8735 110.453 73.8608 110.443 73.8353 110.423L73.7237 110.331L73.2998 109.971C72.9437 109.649 72.4294 109.179 71.8346 108.553C71.2397 107.926 70.5559 107.169 69.8324 106.293C69.109 105.417 68.3404 104.432 67.4968 103.431C67.073 102.929 66.6364 102.467 66.2252 102.006C65.814 101.546 65.4255 101.092 65.0609 100.655C64.3318 99.7835 63.7003 98.9783 63.1845 98.2907C62.6688 97.6032 62.2661 97.0361 61.9962 96.6378C61.8549 96.4407 61.7589 96.2833 61.6896 96.177C61.6571 96.1274 61.6317 96.0877 61.6119 96.0551C61.6077 96.045 61.6044 96.0346 61.602 96.0239Z"
          fill="#E0E0E0"
        />
        <path
          d="M77.0371 99.2325C77.0371 99.2325 74.5955 91.128 72.8166 88.2544C71.0377 85.3809 65.7166 79.6069 65.7166 79.6069C65.7166 79.6069 65.5753 88.0078 67.8953 91.7106C70.2154 95.4135 76.2939 99.8705 76.2939 99.8705L77.0371 99.2325Z"
          fill="#D1E7D9"
        />
        <path
          d="M67.0861 83.1807C67.096 83.1937 67.105 83.2075 67.113 83.2218L67.1864 83.3451L67.469 83.8243C67.7106 84.2496 68.054 84.8492 68.4779 85.5878L69.9657 88.2104C70.5224 89.2027 71.1271 90.2929 71.7262 91.4553C72.9513 93.7703 74.1791 95.7989 75.0594 97.2676L76.1021 99.0113L76.3847 99.4876L76.4554 99.6138C76.4645 99.6273 76.4716 99.6421 76.4766 99.6577C76.4766 99.6577 76.4653 99.645 76.4469 99.618L76.3678 99.4989C76.2971 99.3855 76.1982 99.2296 76.0725 99.0311C75.8167 98.6158 75.4522 98.0274 75.0043 97.303C74.1099 95.8386 72.8721 93.8128 71.6457 91.4908C71.0452 90.3255 70.4447 89.2339 69.8922 88.243L68.4214 85.6119C68.0102 84.8648 67.6753 84.2595 67.4394 83.8328L67.1794 83.3451L67.113 83.2161C67.1022 83.2058 67.0932 83.1938 67.0861 83.1807Z"
          fill="#E0E0E0"
        />
        <path
          d="M79.8584 89.9023C79.8584 89.9023 78.6207 82.8312 77.4465 80.2724C76.2723 77.7136 72.593 72.2671 72.593 72.2671C72.593 72.2671 71.9699 78.8378 73.5072 82.1437C75.0445 85.4496 79.4274 90.2993 79.4274 90.2993L79.8584 89.9023Z"
          fill="#D1E7D9"
        />
        <path
          d="M73.4135 75.3115C73.4353 75.3603 73.4537 75.4105 73.4686 75.4618C73.5025 75.5624 73.552 75.7099 73.6099 75.9013C73.73 76.284 73.8925 76.8411 74.0917 77.5301C74.291 78.2191 74.5156 79.0413 74.7982 79.9486C74.9395 80.4008 75.0808 80.8757 75.256 81.3662C75.3436 81.6072 75.4383 81.851 75.5485 82.0906C75.6587 82.3302 75.7986 82.5627 75.9243 82.7994C76.4344 83.7677 76.8795 84.7118 77.2949 85.5624C77.7103 86.4129 78.089 87.1785 78.4252 87.8121C78.7615 88.4458 79.0385 88.9533 79.2306 89.3049C79.3253 89.475 79.4002 89.6083 79.4553 89.7089C79.4827 89.7544 79.5068 89.8017 79.5273 89.8507C79.4941 89.8058 79.4643 89.7584 79.4383 89.7089C79.379 89.6125 79.297 89.4807 79.1995 89.3148C78.9961 88.9689 78.705 88.4642 78.3659 87.8348C78.0268 87.2054 77.6368 86.4441 77.22 85.5936C76.8032 84.743 76.3539 83.8017 75.8452 82.8349C75.718 82.5924 75.5838 82.3628 75.4665 82.1175C75.3493 81.8723 75.2602 81.627 75.1726 81.3832C74.9988 80.8955 74.8533 80.4192 74.7177 79.9656C74.4492 79.0555 74.2274 78.2304 74.0366 77.54C73.8459 76.8496 73.6933 76.2996 73.5831 75.9041L73.4587 75.459C73.4405 75.4109 73.4254 75.3616 73.4135 75.3115Z"
          fill="#E0E0E0"
        />
        <path
          d="M80.8044 87.5271C80.8044 87.5271 85.2763 81.627 86.2385 78.9675C87.2008 76.3081 88.2266 69.8125 88.2266 69.8125C88.2266 69.8125 82.6482 74.0654 81.4261 77.5088C80.2039 80.9522 80.5967 87.4775 80.5967 87.4775L80.8044 87.5271Z"
          fill="#D1E7D9"
        />
        <path
          d="M86.5977 72.5674C86.5783 72.6164 86.5551 72.6637 86.5284 72.7091C86.4804 72.8041 86.4083 72.9445 86.3122 73.1217C86.1215 73.4775 85.8361 73.9864 85.4786 74.6144C85.1211 75.2424 84.6888 75.9839 84.2296 76.8188C83.9992 77.2356 83.7633 77.6779 83.5386 78.1443C83.4256 78.3768 83.3215 78.6173 83.2264 78.8659C83.1402 79.114 83.0738 79.3805 82.9961 79.6456C82.688 80.706 82.3433 81.6983 82.0437 82.6042C81.7442 83.51 81.4786 84.328 81.2779 85.0141C81.0773 85.7003 80.9247 86.2716 80.8201 86.6614C80.7707 86.85 80.7311 87.0002 80.7028 87.1108C80.6916 87.1641 80.6755 87.2163 80.6548 87.2667C80.6607 87.2127 80.6706 87.1591 80.6845 87.1065C80.7085 86.9946 80.7424 86.8429 80.7834 86.6529C80.8781 86.2588 81.0151 85.6903 81.2073 84.9957C81.3994 84.3011 81.6566 83.4774 81.9547 82.5701C82.2528 81.6629 82.5934 80.6705 82.9 79.613C82.9763 79.3493 83.0413 79.087 83.1317 78.8276C83.2221 78.5682 83.3352 78.3314 83.4496 78.0975C83.6757 77.6283 83.9145 77.1874 84.1476 76.7706C84.6125 75.9371 85.0505 75.1971 85.4193 74.5747L86.2783 73.0976L86.5101 72.695C86.536 72.6503 86.5652 72.6076 86.5977 72.5674Z"
          fill="#E0E0E0"
        />
      </g>
      <path
        d="M2.39821 70.9142C4.6273 70.9142 42.1543 71.1676 44.5141 71.088C44.6837 71.088 44.772 70.8273 44.606 70.7259C43.1187 69.9693 41.4266 70.1214 39.8864 69.5892C38.9785 69.2743 38.4203 68.6661 37.788 67.9674C37.2655 67.3571 36.5899 66.9051 35.8345 66.6605C35.1459 66.4485 34.4152 66.4248 33.7149 66.5917C32.9518 66.78 32.3018 67.2252 31.5635 67.5076C29.7619 68.2027 28.0097 67.5402 26.7238 66.1754C25.664 65.0495 24.6043 64.0033 23.1736 63.377C22.5842 63.1029 21.9462 62.9557 21.299 62.9445C20.6519 62.9333 20.0094 63.0583 19.4113 63.3118C18.0336 63.9526 17.0904 65.2378 15.7974 66.0125C14.2643 66.9284 12.4308 66.7112 10.7387 66.9899C9.0466 67.2687 7.7466 68.7276 6.2629 69.5675C5.77887 69.8856 5.21971 70.0633 4.64495 70.0816C4.31289 70.0816 3.98435 69.9802 3.65935 69.9404C3.14712 69.8752 2.66316 69.8897 2.31696 70.3277C2.22864 70.4436 2.24984 70.9142 2.39821 70.9142Z"
        fill="#F9F3F1"
      />
      <path
        d="M103.618 40.6981C105.416 40.6981 135.685 40.9025 137.589 40.8382C137.726 40.8382 137.797 40.628 137.663 40.5462C136.463 39.9359 135.098 40.0586 133.856 39.6293C133.124 39.3753 132.674 38.8847 132.164 38.3211C131.742 37.8289 131.197 37.4643 130.588 37.2669C130.032 37.096 129.443 37.0769 128.878 37.2115C128.263 37.3633 127.738 37.7225 127.143 37.9503C125.69 38.5109 124.276 37.9765 123.239 36.8757C122.384 35.9675 121.529 35.1236 120.375 34.6184C119.9 34.3974 119.385 34.2786 118.863 34.2696C118.341 34.2606 117.823 34.3614 117.341 34.5659C116.229 35.0827 115.468 36.1194 114.426 36.7443C113.189 37.483 111.71 37.3078 110.345 37.5327C108.98 37.7575 107.932 38.9343 106.735 39.6118C106.344 39.8684 105.893 40.0117 105.43 40.0264C105.162 40.0264 104.897 39.9447 104.635 39.9126C104.222 39.86 103.831 39.8717 103.552 40.225C103.481 40.3184 103.498 40.6981 103.618 40.6981Z"
        fill="#FBF2EF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2895_31711"
          x1="-1.16758"
          y1="-96.6836"
          x2="5.5682"
          y2="111.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBF5F1" />
          <stop offset="0.255208" stopColor="#FFF2EA" />
          <stop offset="1" stopColor="#FFAD7B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2895_31711"
          x1="-1.16758"
          y1="-96.6836"
          x2="5.5682"
          y2="111.41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBF5F1" />
          <stop offset="0.255208" stopColor="#FFF2EA" />
          <stop offset="1" stopColor="#FFAD7B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
