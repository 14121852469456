import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mobileFirstMedia, Theme } from '../../';
import { Plane } from '../Icons/Plane';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';

interface IChatInput {
  sendMessage: (message: string) => Promise<void>;
  onError: () => void;
  placeholder: string;
}

const Form = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 8px 20px;
  ${mobileFirstMedia('md')`padding: 8px 10px;`}
`;

const Wrapper = styled.div`
  z-index: 40;
  background-color: ${Theme.colors.cappuccino[50]};
  border-top: 1px solid ${Theme.colors.cappuccino[200]};
  bottom: 0;
  position: sticky;
  ${mobileFirstMedia('md')`border-radius: 0px 0px 16px 16px;`}
`;

const TextArea = styled.textarea`
  flex-grow: 1;
  margin-right: 5px;
  border-radius: 20px;
  padding: 10px 15px;
  border: 1px solid ${Theme.colors.cappuccino[200]};
  font-size: 1rem;
  font-family: ${Theme.fonts.secondary};
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 130%;
  &:focus {
    outline: none;
    border: solid 1px ${Theme.colors.candy[500]};
    box-shadow: 0px 0px 0px 3px ${Theme.colors.candy[200]};
  }
  resize: none;
  font-family: ${Theme.fonts.secondary};
  transition-timing-function: ease-out;
  transition:
    box-shadow 0.3s,
    border 0.3s;
`;

const enterKeys = ['Enter', 'NumpadEnter'];

export const ChatInput: React.FC<IChatInput> = ({
  sendMessage,
  onError,
  placeholder
}) => {
  const { register, handleSubmit, reset, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: ''
    }
  });
  const sendRef = useRef<HTMLButtonElement>();
  const textAreaRef = useRef<HTMLTextAreaElement | null>();
  const [rows, setRows] = useState(1);
  const watchText = watch('message');

  useEffect(() => {
    if (!watchText) {
      setRows(1);
      return;
    }
    const scrollHeight = textAreaRef.current?.scrollHeight || 30;
    const rowHeight = 20;
    const padding = 10;
    const maxRows = 5;
    const rows = Math.floor((scrollHeight - padding) / rowHeight);
    setRows(Math.min(rows, maxRows));
  }, [watchText, setRows, textAreaRef]);

  const onSubmit = async (data: { message: string }) => {
    try {
      await sendMessage(data.message);
      reset();
    } catch {
      onError();
    }
  };

  const onKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (enterKeys.includes(event.key) && !event.shiftKey) {
      sendRef.current?.click();
    }
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (enterKeys.includes(event.key) && !event.shiftKey) {
      event.preventDefault();
    }
  };

  const assignButtonRef = (instance: HTMLButtonElement | null) => {
    if (instance) {
      sendRef.current = instance;
    }
  };

  const { ref: refRegister, ...textAreaInputRegister } = register('message', {
    required: 'required'
  });

  return (
    <Wrapper>
      <Form data-testid="write-box-form" onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          data-testid="write-test-area"
          {...textAreaInputRegister}
          ref={(ref) => {
            refRegister(ref);
            textAreaRef.current = ref;
          }}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          rows={rows}
        />
        <Button
          ref={assignButtonRef}
          type="submit"
          disabled={formState.isSubmitting}
          colorScale={
            watchText ? Theme.colors.spinach : Theme.colors.cappuccino
          }
          variant={watchText ? 'primary' : 'ghost'}
          icon={Plane}
          iconPosition="iconOnly"
          size="small"
          highContrast={!watchText}
          data-testid="patient-send-msg"
        />
      </Form>
    </Wrapper>
  );
};
