import { useTheme } from '@unobravo/zenit-core';
import { ArrowLeft, ArrowRight } from '@unobravo/zenit-icons';
import { ActionIcon, Box, DotsIndicator, Stack } from '@unobravo/zenit-web';
import React, { ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';

const ChildrenWrapper = styled(Stack)`
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SliderWrapper = styled(Box)`
  overflow: hidden;
  position: relative;
`;

interface ISlider {
  children?: ReactNode;
  childrenWidth?: number;
  showArrows?: boolean;
}

export const Slider: React.FC<ISlider> = ({
  children,
  childrenWidth = 300,
  showArrows = false
}) => {
  const [activePage, setActivePage] = useState(0);
  const scrollableDiv = useRef<HTMLDivElement | null>(null);
  const pages = Math.ceil(React.Children.count(children));
  const {
    spacing: { xs: childSpacing }
  } = useTheme();
  const childWidth = childrenWidth + childSpacing;
  const offset = activePage * childWidth;

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const leftScroll = e.currentTarget.scrollLeft;
    const offWidth = e.currentTarget.offsetWidth;

    if (leftScroll + offWidth >= e.currentTarget.scrollWidth) {
      setActivePage(pages - 1);
      return;
    }
    const newActivePage = Math.round(leftScroll / childWidth);
    setActivePage(newActivePage);
  };

  const scrollLeft = () => {
    scrollableDiv.current?.scrollTo({
      left: offset - childWidth,
      behavior: 'smooth'
    });
  };

  const scrollRight = () => {
    scrollableDiv.current?.scrollTo({
      left: offset + childWidth,
      behavior: 'smooth'
    });
  };

  return (
    <SliderWrapper>
      <ChildrenWrapper
        px="md"
        onScroll={handleScroll}
        ref={scrollableDiv}
        spacing="xs"
      >
        {React.Children.map(children, (c) => (
          <Box minW={childrenWidth}>{c}</Box>
        ))}
      </ChildrenWrapper>
      <Stack m="md" justify="center" spacing="xl">
        {showArrows && pages > 1 && (
          <ActionIcon
            size="sm"
            disabled={activePage === 0}
            onClick={() => scrollLeft()}
          >
            <ArrowLeft size="sm" />
          </ActionIcon>
        )}
        <DotsIndicator colorScale="dark" dots={pages} activeDot={activePage} />
        {showArrows && pages > 1 && (
          <ActionIcon
            size="sm"
            disabled={activePage === pages - 1}
            onClick={() => scrollRight()}
          >
            <ArrowRight size="sm" />
          </ActionIcon>
        )}
      </Stack>
    </SliderWrapper>
  );
};
