import { Theme } from '@unobravo-monorepo/common';
import { Check, CreditCard } from '@unobravo-monorepo/common/components/Icons';
import { Trash } from '@unobravo-monorepo/common/components/Icons/Trash';
import {
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { Body } from '@unobravo-monorepo/common/components/Typography/Body';
import { ICreditCard, ISepaDebit } from '@unobravo/patient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import klarnaLogo from '@unobravo-monorepo/patient/assets/klarna-logo.svg';
import { Box, Stack } from '@unobravo/zenit-web';
import { useDeleteCard } from '../hooks/useDeleteCard';

const TextContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px;
  height: 42px;
`;

const SelectedCardChecked = styled.div`
  align-items: center;
  background-color: ${Theme.colors.candy[50]};
  border-radius: 100px;
  display: flex;
  padding: 10px;
`;

export const NewPaymentMethodComp: React.FC<{
  isSelectedCard?: boolean;
  paymentMethod?: ICreditCard | ISepaDebit | 'klarna';
  onDeleteSuccess?: () => void;
}> = ({ isSelectedCard, paymentMethod, onDeleteSuccess }) => {
  const { removeCard, loading } = useDeleteCard();

  const onDelete = async () => {
    if (paymentMethod) {
      if (paymentMethod !== 'klarna') {
        if (loading || !onDeleteSuccess) {
          return;
        }
        let success;
        if ('cardId' in paymentMethod) {
          success = await removeCard(paymentMethod?.cardId);
        }
        if ('sepaId' in paymentMethod) {
          success = await removeCard(paymentMethod?.sepaId);
        }
        if (success) {
          onDeleteSuccess();
        }
      }
    }
  };

  const { t } = useTranslation();

  return (
    <Stack
      w="100%"
      align="center"
      justify="space-between"
      data-testid="card-detail"
    >
      {paymentMethod === 'klarna' ? (
        <img src={klarnaLogo} alt="klarna-logo" style={{ width: 50 }} />
      ) : (
        <Stack align="center">
          {paymentMethod && 'cardId' in paymentMethod && (
            <CreditCard color={Theme.colors.candy[500]} />
          )}
          {paymentMethod && (
            <TextContainer>
              {'cardId' in paymentMethod && (
                <Body
                  size={BodySize.Body60}
                  variant={TypographyVariant.SemiBold}
                  capitalize
                >
                  {paymentMethod?.brand}
                </Body>
              )}
              <Body
                size={BodySize.Body70}
                variant={TypographyVariant.Regular}
                margin="0.28rem 0"
              >
                {t('paymentMethod.savedCard.cardNumberHidden', {
                  lastFour: paymentMethod?.lastFour
                })}
              </Body>
            </TextContainer>
          )}
        </Stack>
      )}
      {isSelectedCard && (
        <SelectedCardChecked>
          <Check color={Theme.colors.candy[500]} />
        </SelectedCardChecked>
      )}
      {onDeleteSuccess && (
        <Box
          style={{ cursor: 'pointer' }}
          data-testid="trash-icon"
          onClick={onDelete}
        >
          <Trash color={Theme.colors.gray[loading ? 500 : 900]} />
        </Box>
      )}
    </Stack>
  );
};
