import { ArrowDown } from '@unobravo/zenit-icons';
import { Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import * as Accordion from '@radix-ui/react-accordion';
import { useDoctorInfo } from '../../../../shared/hooks/useDoctorInfo';

interface AccordionItem {
  value: string;
  title: string;
  description: string;
  testId?: string;
}

const StyledHeader = styled(Accordion.Header)`
  cursor: pointer;

  button,
  h3 {
    all: unset;
    width: 100%;
  }

  button > div {
    all: unset;
  }
`;

const StyledTrigger = styled(Accordion.Trigger)`
  &[data-state='open'] > div > .accordion-icon {
    transform: rotate(-180deg);
  }
`;

const StyledTitle = styled(Text)`
  flex: 1;
`;

const StyledArrowDown = styled(ArrowDown)`
  transition: transform 150ms ease-in-out;
`;

const slideDownAnimation = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUpAnimation = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const StyledContent = styled(Accordion.Content)`
  overflow: hidden;
  &[data-state='open'] {
    animation: ${slideDownAnimation} 150ms ease-in-out;
  }
  &[data-state='closed'] {
    animation: ${slideUpAnimation} 150ms ease-in-out;
  }
`;

const AccordionRoot = styled(({ ...props }) => <Accordion.Root {...props} />)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledItem = styled(Accordion.Item)`
  background: rgba(255, 255, 255, 0.5);
  padding: 12px 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EducationReassignmentAccordion = memo(() => {
  const { t } = useTranslation();
  const { name } = useDoctorInfo();

  const items: AccordionItem[] = [
    {
      value: 'first',
      title: t('reassignment.educationModal.firstItem.title'),
      description: t('reassignment.educationModal.firstItem.description', {
        doctorName: name
      })
    },
    {
      value: 'second',
      title: t('reassignment.educationModal.secondItem.title'),
      description: t('reassignment.educationModal.secondItem.description')
    },
    {
      value: 'third',
      title: t('reassignment.educationModal.thirdItem.title'),
      description: t('reassignment.educationModal.thirdItem.description')
    },
    {
      value: 'fourth',
      title: t('reassignment.educationModal.fourthItem.title'),
      description: t('reassignment.educationModal.fourthItem.description')
    }
  ];

  return (
    <AccordionRoot type="single" defaultValue="first" collapsible>
      {items.map((item) => {
        return (
          <StyledItem value={item.value} key={item.value}>
            <StyledHeader title={item.title}>
              <StyledTrigger>
                <Stack
                  justify="space-between"
                  align="start"
                  mx="md"
                  py="xl"
                  spacing="xs"
                >
                  <StyledTitle
                    variant="md"
                    color="grey.900"
                    fontWeight="medium"
                  >
                    {item.title}
                  </StyledTitle>
                  <StyledArrowDown
                    className="accordion-icon"
                    color="grey.600"
                  />
                </Stack>
              </StyledTrigger>
            </StyledHeader>
            <StyledContent>
              <Text variant="md" color="grey.700">
                {item.description}
              </Text>
            </StyledContent>
          </StyledItem>
        );
      })}
    </AccordionRoot>
  );
});
