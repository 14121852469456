import { mediaQueries } from '@unobravo-monorepo/common';
import { Logo } from '@unobravo-monorepo/common/components/Logo';
import { PlainBackgroundLayout } from '@unobravo-monorepo/common/components/PlainBackgroundLayout';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { theme } from '@unobravo/zenit-core';
import { Camera, Chat, Heart } from '@unobravo/zenit-icons';
import { Box, Button, Heading, Stack, Text } from '@unobravo/zenit-web';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { resetChat } from '@unobravo/patient';
import { useDispatch } from 'react-redux';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useToast } from '../../toaster/hooks/useToast';
import { useReassignment } from '../hooks/useReassignment';
import { CONFIRM_REASSIGNMENT_PENDO_EVENT } from '../consts';

const StyledLogo = styled(Logo)`
  margin: 56px 0;
  width: 180px;
  align-self: center;
  ${mediaQueries('sm')`
     margin: 56px 0px 42px 0px;
  `}
`;

const RightLabel = styled(Text)`
  margin-left: ${theme.spacing.xs}px;
  align-self: center;
`;

export function ReassignmentConfirm() {
  const { id, refetch: refetchPatient, reassignConditions } = usePatient();
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const { sendErrorMessage } = useErrorHandler();
  const { associateDoctor, associationLoading } = useReassignment();
  const navigate = useNavigate();
  const { sendToast } = useToast();
  const dispatch = useDispatch();

  const iconSize = isMobile ? theme.spacing.xl : theme.spacing['2xl'];

  const reassign = async () => {
    pendoTrack(CONFIRM_REASSIGNMENT_PENDO_EVENT);

    try {
      await associateDoctor({ variables: { patientId: id } });
      await refetchPatient();
      dispatch(resetChat());
      sendToast({
        variant: 'success',
        title: t('reassignment.successToastTitle'),
        ...(!isMobile
          ? { description: t('reassignment.successToastDescription') }
          : {})
      });
      navigate('/patient/chat/doctor-info');
    } catch {
      if (isMobile) {
        sendErrorMessage('', t('reassignment.final.errorTitleMobile'));
      } else {
        sendErrorMessage(
          t('reassignment.final.errorDescription'),
          t('reassignment.final.errorTitle')
        );
      }
    }
  };
  const reassignConditionsRef = useRef(
    reassignConditions?.withNewSurvey ||
      reassignConditions?.canPerformSmoothReassign
  );

  useEffect(() => {
    if (!reassignConditionsRef.current) {
      navigate('../already-used');
    }
  }, [reassignConditionsRef]);

  return (
    <PlainBackgroundLayout>
      <Stack maxW="400px" direction="column">
        <StyledLogo />
        <Heading variant="xl" color="candy.900">
          {t('reassignment.final.title')}
        </Heading>
        <Stack mt="xl">
          <Box>
            <Heart color="candy.500" width={iconSize} height={iconSize} />
          </Box>
          <RightLabel variant="xl" color="grey.800">
            {t('reassignment.final.description1')}
          </RightLabel>
        </Stack>
        <Stack mt="xl">
          <Box>
            <Chat color="candy.500" width={iconSize} height={iconSize} />
          </Box>
          <RightLabel variant="xl" color="grey.800">
            {t('reassignment.final.description2')}
          </RightLabel>
        </Stack>
        <Stack mt="xl">
          <Box>
            <Camera color="candy.500" width={iconSize} height={iconSize} />
          </Box>
          <RightLabel variant="xl" color="grey.800">
            {t('reassignment.final.description3')}
          </RightLabel>
        </Stack>
      </Stack>
      <Stack mt="2xl" direction="column" align="center">
        <Button
          data-testid="reassignment-final-confirm"
          disabled={associationLoading}
          loading={associationLoading}
          size="lg"
          label={t('reassignment.final.confirmButton')}
          onClick={reassign}
        />
        <Box mt="md">
          <Button
            data-testid="reassignment-final-cancel"
            disabled={associationLoading}
            variant="outlined"
            size="lg"
            label={t('reassignment.final.cancelButton')}
            onClick={() => navigate('/')}
          />
        </Box>
      </Stack>
    </PlainBackgroundLayout>
  );
}
