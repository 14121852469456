import React, { useState } from 'react';
import styled from 'styled-components';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';
import { Theme } from '../../theme';
import { Body, BodySize } from '../Typography/Body';
import { useViewport } from '../../hooks/useViewportHook';
import { Error, Warning, Check } from '../Icons';

import { ArrowDown } from '../Icons/ArrowDown';
import { ArrowUp } from '../Icons/ArrowUp';
import { TypographyVariant } from '../Typography';

const Wrapper = styled.div`
  width: 100%;
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div<{ size: string }>`
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: calc(50% - ${({ size }) => (size === 'extraSmall' ? '8px' : '12px')});
  cursor: pointer;
  + svg {
    color: ${Theme.colors.gray[100]};
  }
`;

const InfoLabelWrapper = styled.div<{ withIcon: boolean }>`
  position: relative;
  margin-top: 4px;
  margin-left: ${({ withIcon }) => (withIcon ? '36px' : '18px')};
  display: flex;
  align-items: start;
`;

const InfoIconWrapper = styled.div`
  position: absolute;
  max-height: 20px;
  left: -20px;
  top: 1px;
`;

type IInfoVariant = 'default' | 'success' | 'warning';

type IInfoIcon = {
  [key in IInfoVariant]: JSX.Element | undefined;
};

interface ISelect {
  size?: 'small' | 'normal' | 'extraSmall';
  infoVariant?: IInfoVariant;
  infoMessage?: string;
  errorMessage?: string;
  label?: string;
  'data-testid'?: string;
}

const infoColor = {
  success: Theme.colors.edamame[500],
  warning: Theme.colors.ginger[500],
  error: Theme.colors.red[500],
  default: Theme.colors.gray[700]
};

const infoIcon: Partial<IInfoIcon> = {
  success: <Check width={16} height={16} color={Theme.colors.edamame[500]} />,
  warning: <Warning width={16} height={16} color={Theme.colors.ginger[500]} />
};

const fontStyle = {
  fontFamily: Theme.fonts.secondary,
  letterSpacing: '0.01em',
  fontSize: '1rem',
  color: Theme.specialColors.black,
  fontWeight: 400,
  lineHeight: '113%'
};

const sizeMap = {
  extraSmall: '6px',
  small: '8px',
  normal: '12px'
};

const searchableSizeMap = {
  extraSmall: '4px',
  small: '4px',
  normal: '8px'
};

export function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & ISelect) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile } = useViewport();
  const {
    label,
    isDisabled,
    placeholder,
    errorMessage,
    infoMessage,
    maxMenuHeight,
    'data-testid': dataTestid,
    infoVariant = 'default',
    isSearchable = false,
    size = 'normal'
  } = props || {};

  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.isDisabled
          ? Theme.colors.gray[100]
          : Theme.colors.gray[300],
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: state.isDisabled
          ? Theme.colors.gray[200]
          : Theme.specialColors.black
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 15,
        marginTop: '-5px',
        borderRadius: 6,
        boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.1)`
      };
    },

    noOptionsMessage: (provided) => {
      return {
        ...provided,
        ...fontStyle
      };
    },

    option: (provided, state) => {
      return {
        ...provided,
        ...fontStyle,
        appearance: 'none',
        '&:hover': {
          ...(!state?.isSelected && {
            backgroundColor: Theme.colors.cappuccino[50]
          }),
          cursor: 'pointer'
        },
        backgroundColor: state.isSelected
          ? Theme.colors.cappuccino[100]
          : Theme.specialColors.white
      };
    },
    control: (_, state) => {
      return {
        cursor: 'pointer',
        borderRadius: 8,
        backgroundColor: Theme.specialColors.white,
        border: `solid 1.5px ${
          state.isDisabled ? Theme.colors.gray[100] : Theme.colors.gray[200]
        }`,
        padding: `${
          isSearchable ? searchableSizeMap[size] : sizeMap[size]
        } 6px`,
        paddingRight: '42px',
        ...fontStyle,
        transitionTimingFunction: 'ease-out',
        transition: '0.3s',
        ...(state.isFocused && {
          outline: 'none',
          border: `solid 1.5px ${Theme.colors.candy[500]}`,
          boxShadow: `0px 0px 0px 3px ${Theme.colors.candy[200]}`
        }),
        ...(errorMessage && {
          border: `solid 1.5px ${Theme.colors.red[500]}`
        })
      };
    }
  };

  return (
    <Wrapper>
      {label && (
        <Body
          variant={TypographyVariant.Bold}
          size={isMobile ? BodySize.Body80 : BodySize.Body90}
          margin
        >{`${label}`}</Body>
      )}
      <SelectWrapper data-testid={dataTestid}>
        <ReactSelect
          maxMenuHeight={maxMenuHeight || 175}
          styles={customStyles}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          placeholder={placeholder || ''}
          components={{
            IndicatorSeparator: null,
            // eslint-disable-next-line react/no-unstable-nested-components
            DropdownIndicator: () => {
              return (
                <IconWrapper size={size}>
                  {isMenuOpen ? (
                    <ArrowUp
                      color={
                        isDisabled
                          ? Theme.colors.gray[300]
                          : Theme.colors.gray[500]
                      }
                      width={size === 'extraSmall' ? 18 : 24}
                      height={size === 'extraSmall' ? 18 : 24}
                    />
                  ) : (
                    <ArrowDown
                      color={
                        isDisabled
                          ? Theme.colors.gray[300]
                          : Theme.colors.gray[500]
                      }
                      width={size === 'extraSmall' ? 18 : 24}
                      height={size === 'extraSmall' ? 18 : 24}
                    />
                  )}
                </IconWrapper>
              );
            }
          }}
          {...(props as Props<Option, IsMulti, Group>)}
        />
      </SelectWrapper>
      {errorMessage && (
        <InfoLabelWrapper withIcon={!!infoIcon[infoVariant] || !!errorMessage}>
          <InfoIconWrapper>
            <Error width={16} height={16} color={Theme.colors.red[500]} />
          </InfoIconWrapper>
          <Body
            size={BodySize.Body60}
            variant={TypographyVariant.Regular}
            color={Theme.colors.red[500]}
          >
            {errorMessage}
          </Body>
        </InfoLabelWrapper>
      )}
      {!errorMessage && infoMessage && (
        <InfoLabelWrapper withIcon={!!infoIcon[infoVariant] || !!errorMessage}>
          {!!infoIcon[infoVariant] && (
            <InfoIconWrapper>{infoIcon?.[infoVariant]}</InfoIconWrapper>
          )}
          <Body
            size={BodySize.Body60}
            variant={TypographyVariant.Regular}
            color={infoColor?.[infoVariant]}
          >
            {infoMessage}
          </Body>
        </InfoLabelWrapper>
      )}
    </Wrapper>
  );
}

CustomSelect.displayName = 'CustomSelect';
