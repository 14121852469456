import { useMutation } from '@apollo/client';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { useApolloSignupV2Client } from '@unobravo-monorepo/common/hooks/useApolloSignupV2Client';
import { usePollingRedirect } from '@unobravo-monorepo/common/hooks/usePollingRedirect';

import { TouchPointLayout } from '@unobravo-monorepo/common/layouts/touchpoint';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CompleteQuestionnaireDocument } from '../graphql/mutation.visitor.generated';
import { useActivationRegOnTop } from '../hooks/useActivationRegOnTop';

export const RegOnTopVerifyEmail = () => {
  const { code } = useParams();
  const { verify: confirmUser } = useActivationRegOnTop();

  const client = useApolloSignupV2Client();

  const [completeQuestionnaire] = useMutation(CompleteQuestionnaireDocument, {
    client
  });

  const navigate = useNavigate();

  const verify = async () => {
    if (!code) {
      return navigate('/');
    }

    const visitor = await confirmUser(code);

    const id = visitor?.data?.confirmActivation.visitor.id;

    if (!id) return;

    await completeQuestionnaire({
      variables: { id }
    });
  };

  usePollingRedirect();

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TouchPointLayout topLeft="" bottomRight="">
      <Spinner />
    </TouchPointLayout>
  );
};
