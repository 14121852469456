import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IBillingData,
  mapBillingInfo,
  PlatformCountry,
  UpdatePatientInput
} from '@unobravo/patient';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { Select } from '@unobravo-monorepo/common/components/Select';
import { useCountry } from '@unobravo/translations';
import {
  Box,
  Button,
  ModalAlert,
  RBox,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { SectionHeader } from '../components/SectionHeader';
import { BillingInfoForm } from '../../billingInfo/billingInfoForm';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useBillingInfo } from '../../billingInfo/hooks/useBillingInfo';

interface ISubmitData {
  patient: Partial<UpdatePatientInput>;
  billing: IBillingData;
}

export const PersonalInfo: React.FC = () => {
  const { t } = useTranslation();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { breakpoint } = useBreakpointValue();
  const { email, name, surname, sex, phone, consentTS, updatePatient } =
    usePatient();
  const {
    loadBillingInfo,
    loading,
    updateBilling,
    createBillingInfo,
    ...billingInfoState
  } = useBillingInfo();
  const { domainCountry } = useCountry();

  const { billingInfoId } = billingInfoState;

  const selectOptions = [
    { label: t('common:male'), value: 'MALE' },
    { label: t('common:female'), value: 'FEMALE' }
  ];

  const onSubmit = async (data: ISubmitData) => {
    const patientSuccess = await updatePatient(data.patient);
    if (!patientSuccess) {
      return;
    }

    if (billingInfoId) {
      await updateBilling(data.billing);
    } else {
      await createBillingInfo(data.billing);
    }
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      patient: {
        name,
        surname,
        sex,
        phone,
        consentTS
      },
      billing: mapBillingInfo(
        billingInfoState,
        domainCountry.toUpperCase() as PlatformCountry
      )
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = methods;

  useEffect(() => {
    reset({
      patient: {
        name,
        surname,
        sex,
        phone,
        consentTS
      },
      billing: mapBillingInfo(
        billingInfoState,
        domainCountry.toUpperCase() as PlatformCountry
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfoId]);

  const requiredErrorMessage = t('personalInfo.error');
  const validateEmptyString = (value: string | undefined | null) => {
    if (value?.trim().length === 0) {
      return t('personalInfo.fieldError');
    }
    return undefined;
  };

  const handleReset = () => {
    setIsCancelModalOpen(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <RBox
        border="sm"
        borderColor="cappuccino.200"
        rounded={{ md: 'md' }}
        h="100%"
        w="100%"
        overflow="scroll"
      >
        <SectionHeader path="../" title={`${t('profileAndSettings.title')}`} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: '20px', maxWidth: '900px' }}
        >
          <Stack direction="column" spacing="xl">
            <Box>
              <Box mb="sm">
                <Text color="cappuccino.600" variant="lg" fontWeight="semibold">
                  {t('personalInfo.title')}
                </Text>
              </Box>
              <Stack spacing="md" wrap>
                <RBox
                  grow={breakpoint === 'sm' ? 1 : 2}
                  w={{ xs: '100%', sm: 'auto' }}
                >
                  <Box mb="2xs">
                    <Text variant="md" fontWeight="medium" color="grey.900">
                      {t('personalInfo.mainInfo.name')}
                    </Text>
                  </Box>
                  <TextInput
                    autoComplete="off"
                    data-testid="name-input"
                    {...register('patient.name', {
                      required: `${requiredErrorMessage}`,
                      validate: validateEmptyString
                    })}
                    errorMessage={
                      errors?.patient?.name?.message as string | undefined
                    }
                    placeholder={t('personalInfo.placeholders.name')}
                  />
                </RBox>
                <RBox
                  grow={breakpoint === 'sm' ? 1 : 2}
                  w={{ xs: '100%', sm: 'auto' }}
                >
                  <Box mb="2xs">
                    <Text variant="md" fontWeight="medium" color="grey.900">
                      {t('personalInfo.mainInfo.surname')}
                    </Text>
                  </Box>
                  <TextInput
                    autoComplete="off"
                    data-testid="surname-input"
                    {...register('patient.surname', {
                      required: `${requiredErrorMessage}`,
                      validate: validateEmptyString
                    })}
                    errorMessage={
                      errors?.patient?.surname?.message as string | undefined
                    }
                    placeholder={t('personalInfo.placeholders.lastname')}
                  />
                </RBox>
                <RBox grow w={{ xs: '100%', sm: 'auto' }}>
                  <Box mb="2xs">
                    <Text variant="md" fontWeight="medium" color="grey.900">
                      {t('personalInfo.mainInfo.gender')}
                    </Text>
                  </Box>
                  <Controller
                    name="patient.sex"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        data-testid="gender"
                        onBlur={onBlur}
                        options={selectOptions}
                        value={selectOptions.find((c) => c.value === value)}
                        onChange={(val) => onChange(val?.value)}
                        placeholder={t('personalInfo.placeholders.gender')}
                      />
                    )}
                  />
                </RBox>
              </Stack>
            </Box>
            <Box>
              <Box mb="sm">
                <Text color="cappuccino.600" variant="lg" fontWeight="semibold">
                  {t('personalInfo.billingInfo.title')}
                </Text>
              </Box>
              <BillingInfoForm showAddressFields context="PAGE" />
            </Box>
            <Box>
              <Box mb="sm">
                <Text color="cappuccino.600" variant="lg" fontWeight="semibold">
                  {t('personalInfo.contacts.title')}
                </Text>
              </Box>
              <Stack spacing="md" wrap>
                <RBox
                  grow={breakpoint === 'sm' ? 1 : 2}
                  w={{ xs: '100%', sm: 'auto' }}
                >
                  <Box mb="2xs">
                    <Text variant="md" fontWeight="medium" color="grey.900">
                      {t('personalInfo.contacts.email')}
                    </Text>
                  </Box>
                  <TextInput
                    value={email || undefined}
                    disabled
                    placeholder={t('personalInfo.placeholders.email')}
                  />
                </RBox>
                <RBox
                  grow={breakpoint === 'sm' ? 1 : 2}
                  w={{ xs: '100%', sm: 'auto' }}
                >
                  <Box mb="2xs">
                    <Text variant="md" fontWeight="medium" color="grey.900">
                      {t('personalInfo.contacts.phone')}
                    </Text>
                  </Box>
                  <TextInput
                    data-testid="telephone-input"
                    {...register('patient.phone', {
                      required: `${requiredErrorMessage}`,
                      pattern: {
                        value:
                          /^(((\+|00)\d{1,3}))?[\s]?\(?\d{3}\)?\d{3}\d{3,6}$/,
                        message: t('personalInfo.contacts.phoneValid')
                      }
                    })}
                    errorMessage={
                      errors?.patient?.phone?.message as string | undefined
                    }
                    placeholder={t('personalInfo.placeholders.phone')}
                  />
                </RBox>
              </Stack>
            </Box>
            <Stack spacing="xs">
              <Button
                type="submit"
                label={t('personalInfo.save')}
                size="lg"
                data-testid="button-save"
              />
              <Button
                data-testid="button-cancel"
                type="button"
                label={t('personalInfo.cancel')}
                variant="outlined"
                size="lg"
                onClick={() => setIsCancelModalOpen(true)}
              />
            </Stack>
          </Stack>
        </form>
        <ModalAlert
          onClose={() => setIsCancelModalOpen(false)}
          open={isCancelModalOpen}
          title={t('personalInfo.deleteChangesModal.title')}
          description={t('personalInfo.deleteChangesModal.description')}
          cancelButtonLabel={t('personalInfo.deleteChangesModal.close')}
          confirmButtonLabel={t(
            'personalInfo.deleteChangesModal.deleteChanges'
          )}
          onCancelClick={() => setIsCancelModalOpen(false)}
          onConfirmClick={handleReset}
          dataTestIdCancelButton="button-modal-back"
          dataTestIdConfirmButton="button-modal-cancel"
        />
      </RBox>
    </FormProvider>
  );
};
