import { Theme } from '@unobravo-monorepo/common';
import { Check, CreditCard } from '@unobravo-monorepo/common/components/Icons';
import { BankBuilding } from '@unobravo-monorepo/common/components/Icons/BankBuilding';
import { Trash } from '@unobravo-monorepo/common/components/Icons/Trash';
import {
  BodySize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { Body } from '@unobravo-monorepo/common/components/Typography/Body';
import { ICreditCard, ISepaDebit } from '@unobravo/patient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import klarnaLogo from '@unobravo-monorepo/patient/assets/klarna-logo.svg';
import { useDeleteCard } from '../hooks/useDeleteCard';

const CardContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Theme.colors.gray[100]};
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  height: 67px;
  width: 100%;
`;

const CardInfo = styled.div`
  align-items: center;
  display: flex;
`;

const TextContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px;
  height: 42px;
`;

const ClickableDiv = styled.div`
  cursor: pointer;
`;

const SelectedCardChecked = styled.div`
  align-items: center;
  background-color: ${Theme.colors.candy[50]};
  border-radius: 100px;
  display: flex;
  padding: 10px;
`;

export const PaymentMethodComp: React.FC<{
  isSelectedCard?: boolean;
  paymentMethod: ICreditCard | ISepaDebit | 'klarna';
  onDeleteSuccess?: () => void;
}> = ({ isSelectedCard, paymentMethod, onDeleteSuccess }) => {
  const { removeCard, loading } = useDeleteCard();
  const onDelete = async () => {
    if (paymentMethod !== 'klarna') {
      if (loading || !onDeleteSuccess) {
        return;
      }
      let success;
      if ('cardId' in paymentMethod) {
        success = await removeCard(paymentMethod.cardId);
      }
      if ('sepaId' in paymentMethod) {
        success = await removeCard(paymentMethod.sepaId);
      }
      if (success) {
        onDeleteSuccess();
      }
    }
  };
  const { t } = useTranslation();

  return (
    <CardContainer data-testid="card-detail">
      {paymentMethod === 'klarna' ? (
        <img src={klarnaLogo} alt="klarna-logo" style={{ width: 50 }} />
      ) : (
        <CardInfo>
          {'cardId' in paymentMethod ? (
            <CreditCard color={Theme.colors.candy[500]} />
          ) : (
            <BankBuilding color={Theme.colors.candy[500]} />
          )}

          <TextContainer>
            {'cardId' in paymentMethod ? (
              <Body
                size={BodySize.Body60}
                variant={TypographyVariant.SemiBold}
                capitalize
              >
                {paymentMethod.brand}
              </Body>
            ) : (
              <Body
                size={BodySize.Body60}
                variant={TypographyVariant.SemiBold}
                capitalize
              >
                {t('paymentMethod.savedSepa')}
              </Body>
            )}
            <Body
              size={BodySize.Body70}
              variant={TypographyVariant.Regular}
              margin="0.28rem 0"
            >
              {t('paymentMethod.savedCard.cardNumberHidden', {
                lastFour: paymentMethod.lastFour
              })}
            </Body>
          </TextContainer>
        </CardInfo>
      )}
      {isSelectedCard && (
        <SelectedCardChecked>
          <Check color={Theme.colors.candy[500]} />
        </SelectedCardChecked>
      )}
      {onDeleteSuccess && (
        <ClickableDiv data-testid="trash-icon" onClick={onDelete}>
          <Trash color={Theme.colors.gray[loading ? 500 : 900]} />
        </ClickableDiv>
      )}
    </CardContainer>
  );
};
