import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GenerateMemberGetMemberReferralCodeMutationVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;


export type GenerateMemberGetMemberReferralCodeMutation = { __typename?: 'Mutation', generateMemberGetMemberReferralCode: { __typename?: 'ReferralCode', code: string, startDate?: any | null, expirationDate?: any | null, hasBeenRedeemed: boolean } };


export const GenerateMemberGetMemberReferralCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateMemberGetMemberReferralCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateMemberGetMemberReferralCode"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"hasBeenRedeemed"}}]}}]}}]} as unknown as DocumentNode<GenerateMemberGetMemberReferralCodeMutation, GenerateMemberGetMemberReferralCodeMutationVariables>;