import { Pencil } from '@unobravo/zenit-icons';

import { Box, Stack, Text } from '@unobravo/zenit-web';
import React, { ReactNode } from 'react';

import styled from 'styled-components';

const CustomRipple = styled.button<{ ripple?: string }>`
  all: unset;
  overflow: hidden;
  &:active {
    background-color: ${({ ripple }) => ripple};
  }
`;

export const EditBillingInfoCard = ({
  children,
  title,
  onClick,
  dataTestId
}: {
  children: ReactNode;
  title: string;
  onClick: () => void;
  dataTestId: string;
}) => {
  return (
    <Stack data-testid={dataTestId} rounded="2xs" w="100%" direction="column">
      <CustomRipple ripple="cappuccino.50" type="button" onClick={onClick}>
        <Stack align="center" mb="2xs">
          <Box grow>
            <Text variant="lg" fontWeight="bold" color="grey.800">
              {title}
            </Text>
          </Box>
          <Pencil size="sm" color="candy.500" />
        </Stack>
        {children}
      </CustomRipple>
    </Stack>
  );
};
