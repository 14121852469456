import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { HEADER_HEIGHT } from '@unobravo-monorepo/common/components/Chat';
import { Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useColor } from '@unobravo/zenit-core';
import { usePatient } from '../../patientData/hooks/usePatient';

const Wrapper = styled(Stack)`
  text-align: center;
  padding: 12px;
  border-radius: 12px;
  margin: 0 auto;
  svg {
    flex-shrink: 0;
  }
`;

const TextWrapper = styled(({ linkColor, ...props }) => <Text {...props} />)`
  a,
  a:visited,
  a:hover,
  a:active {
    font-weight: bold;
    text-decoration: underline;
    color: ${({ linkColor }) => linkColor};
  }
`;

export const UpdateInformedConsentAlert = () => {
  const { isMobile } = useBreakpointValue();
  const { t } = useTranslation();
  const ginger700 = useColor('ginger.700');
  const alertRef = useRef<HTMLDivElement>(null);
  const { prefForWhom, informedConsent, informedConsentVersion } = usePatient();
  const hasInformedConsent = informedConsent && !!informedConsentVersion;
  const informedConsentLink = '/patient/preferences/informedConsent';

  useEffect(() => {
    const topRef = alertRef.current?.getBoundingClientRect().top;
    if (topRef && topRef >= HEADER_HEIGHT) {
      pendoTrack('PT View warning consenso informato');
    }
  }, []);

  const getTextInformedConsertAlert = () => {
    const textDevice = isMobile ? 'mobile' : 'desktop';
    if (prefForWhom === 'PARTNER')
      return hasInformedConsent
        ? `chat.informedConsentOnlyPartnerAlert.${textDevice}`
        : `chat.informedConsentPartnerAlert.${textDevice}`;

    return `chat.newInformedConsentAlert.${textDevice}`;
  };

  return (
    <Wrapper
      ref={alertRef}
      maxW="462px"
      bgColor="ginger.50"
      align="center"
      justify="center"
    >
      <TextWrapper color="ginger.700" linkColor={ginger700}>
        <Trans
          i18nKey={getTextInformedConsertAlert()}
          components={[<Link to={informedConsentLink} />]}
          values={{ name: t('chat.informedConsent') }}
        />
      </TextWrapper>
    </Wrapper>
  );
};
