import { IIcon } from '@unobravo/zenit-core';
import { Box, RStack, Stack, Text } from '@unobravo/zenit-web';
import { FC } from 'react';
import styled from 'styled-components';

const Circle = styled(Stack)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

interface ElementProps {
  title: string;
  description: string;
  Icon: FC<IIcon>;
}

export const MGMInfoElement: React.FC<ElementProps> = ({
  title,
  description,
  Icon
}) => {
  return (
    <RStack
      direction="row"
      maxW={{ base: undefined, md: '495px', lg: '595px' }}
    >
      <Box>
        <Circle justify="center" align="center" bgColor="candy.50">
          <Icon color="candy.500" />
        </Circle>
      </Box>
      <Stack direction="column" spacing="2xs" ml="xs">
        <Text variant="lg" color="black" fontWeight="medium">
          {title}
        </Text>
        <Text variant="sm" color="black">
          {description}
        </Text>
      </Stack>
    </RStack>
  );
};
