import React from 'react';
import {
  Box,
  RStack,
  Stack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';

interface ITabProps {
  tabs: ITab[];
  selectedTab: string;
  setSelectedTab: (id: string) => void;
  'data-testid'?: string;
  mb?: 'sm' | 'md' | 'lg';
  size?: 'sm' | 'md' | 'lg';
}

export interface ITab {
  id: string;
  label: string;
}

export const Tab: React.FC<ITabProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  size = 'md',
  mb,
  'data-testid': dataTestId
}) => {
  const { isMobile } = useBreakpointValue();
  return (
    <Stack mb={mb} w="100%" data-testid={dataTestId}>
      {tabs.map((tab) => (
        <RStack
          justify="center"
          w={{ base: '100%', md: 'unset' }}
          pr={{ base: undefined, md: 'xs' }}
          pl={{ base: undefined, md: 'xs' }}
          borderBottom="md"
          borderColor={selectedTab === tab.id ? 'candy.500' : 'grey.200'}
          data-testid={dataTestId ? `${dataTestId}-${tab.id}` : 'tab'}
          onClick={() => setSelectedTab(tab.id)}
          key={tab.id}
          style={{
            flexShrink: !isMobile ? 0 : 'unset',
            cursor: 'pointer',
            borderWidth: selectedTab === tab.id ? 2 : 1.5
          }}
        >
          <Box mb="xs">
            <Text
              variant={size}
              fontWeight="semibold"
              data-testid="tab-label"
              color={selectedTab === tab.id ? 'candy.500' : 'grey.700'}
            >
              {tab.label}
            </Text>
          </Box>
        </RStack>
      ))}
      {!isMobile && <Box w="100%" borderBottom="md" borderColor="grey.200" />}
    </Stack>
  );
};
