import { mobileFirstMedia, Theme } from '@unobravo-monorepo/common';
import {
  BonusPsicologo,
  Email,
  Trash
} from '@unobravo-monorepo/common/components/Icons';
import { CreditCard } from '@unobravo-monorepo/common/components/Icons/CreditCard';
import { DocDone } from '@unobravo-monorepo/common/components/Icons/DocDone';
import { Password } from '@unobravo-monorepo/common/components/Icons/Password';
import { Setting } from '@unobravo-monorepo/common/components/Icons/Setting';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import IIcon from '@unobravo-monorepo/common/types/IIcon';
import { providers } from '@unobravo-monorepo/common/utils/social';
import { useInternationalVariables } from '@unobravo/translations';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { PrivacySection } from '../../../shared/components/Privacy';
import { useLogout } from '../../../shared/hooks/useLogout';
import { usePatient } from '../../patientData/hooks/usePatient';
import { SideBarMenuTab } from './SideBarMenuTab';

const DeleteBtn = styled(Link)`
  display: flex;
  padding: 0 20px 20px 28px;
  text-decoration: none;
  align-items: center;
`;

const PrivacySectionContainer = styled.div`
  padding: 0 20px 20px 28px;
`;

const TitleContainer = styled.div`
  border-bottom: 1px solid ${Theme.colors.cappuccino[200]};
  background-color: ${Theme.colors.cappuccino[50]};
  padding: 20px;
  ${mobileFirstMedia('md')`
      border-radius: 16px;
      border-bottom: none;
  `}
`;

const Section = styled.div`
  padding: 20px;
`;

const LinkWrapper = styled.div`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  ${mobileFirstMedia('md')(`
      overflow: auto;
      background-color: ${Theme.colors.cappuccino[50]};
      border-radius: 16px;
      width: 300px;
      border: 1px solid ${Theme.colors.cappuccino[200]};
      border-radius: 16px;
      margin-right: 20px;
  `)}
`;

interface IMenuItems {
  [k: string]: {
    title: string;
    subSections?: {
      text: string;
      Icon: FC<IIcon>;
      path: string;
      hide?: boolean;
    }[];
  };
}

export const SideBarMenu: React.FC = () => {
  const { t } = useTranslation();
  const { name, surname, email, isBpEnabled, hasDoctorMatch } = usePatient();
  const logout = useLogout();
  const { changeEmail, bonusPsicologo } = usePatientFeatureFlags();
  const { privacyPolicyUrl, termsAndConditionsUrl } =
    useInternationalVariables();

  const { hasEmailProvider } = providers();

  const menuConfig: IMenuItems = {
    profile: {
      title: t('profileAndSettings.profile'),
      subSections: [
        {
          text: `${t('profileAndSettings.personalInfoLabel')}`,
          Icon: Setting,
          path: 'personalInformation'
        }
      ]
    },
    ...(hasDoctorMatch && {
      documents: {
        title: t('profileAndSettings.documentsLabel'),
        subSections: [
          {
            text: `${t('profileAndSettings.informedConsentLabel')}`,
            Icon: DocDone,
            path: 'informedConsent'
          }
        ]
      }
    }),
    settings: {
      title: t('profileAndSettings.settingsLabel'),
      subSections: [
        ...(hasDoctorMatch
          ? [
              {
                text: `${t('profileAndSettings.paymentMethodLabel')}`,
                Icon: CreditCard,
                path: 'paymentMethod'
              }
            ]
          : []),
        ...(hasEmailProvider
          ? [
              {
                text: `${t('profileAndSettings.changePasswordLabel')}`,
                Icon: Password,
                path: 'changePassword'
              }
            ]
          : []),
        {
          text: `${t('profileAndSettings.changeEmailLabel')}`,
          Icon: Email,
          path: 'changeEmail',
          hide: !changeEmail
        },
        {
          text: `${t('profileAndSettings.bonusLabel')}`,
          Icon: BonusPsicologo,
          path: 'bonus',
          hide: bonusPsicologo ? !isBpEnabled : true
        }
      ]
    }
  };

  return (
    <Wrapper>
      <div>
        <TitleContainer>
          <Heading size={HeadingSize.Title80} margin="0px 0px 1px 0px">{` ${
            name || ''
          } ${surname || ''}`}</Heading>
          {email && (
            <Body size={BodySize.Body70} margin="2px 0px 5px 0px">
              {email}
            </Body>
          )}
          <LinkWrapper onClick={() => logout()}>
            <Body
              data-testid="logout-button"
              size={BodySize.Body60}
              variant={TypographyVariant.Bold}
              color={Theme.colors.candy[500]}
              margin="12px 0px 20px 0px"
            >
              {t('profileAndSettings.logout')}
            </Body>
          </LinkWrapper>
        </TitleContainer>
        {Object.values(menuConfig).map(({ title, subSections }) => (
          <Section key={title}>
            <Body
              size={BodySize.Body80}
              variant={TypographyVariant.SemiBold}
              margin="0 0 8px 10px"
            >
              {title}
            </Body>
            {subSections
              ?.filter((item) => !item.hide)
              ?.map((subSection) => (
                <SideBarMenuTab {...subSection} key={subSection.path} />
              ))}
          </Section>
        ))}
      </div>
      <div>
        <DeleteBtn to="deleteAccount">
          <Trash width={24} height={24} color={Theme.colors.red[500]} />
          <Body
            size={BodySize.Body70}
            margin="0px 0px 0px 8px"
            color={Theme.specialColors.black}
          >
            {t('profileAndSettings.deleteAccount')}
          </Body>
        </DeleteBtn>
        <PrivacySectionContainer>
          <PrivacySection
            termsAndConditionsLabel={t(
              'profileAndSettings.termsAndConditions',
              {
                link: termsAndConditionsUrl
              }
            )}
            privacyPolicyLabel={t('profileAndSettings.privacyPolicy', {
              link: privacyPolicyUrl
            })}
          />
        </PrivacySectionContainer>
      </div>
    </Wrapper>
  );
};
