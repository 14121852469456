import { Box } from '@unobravo/zenit-web';
import styled from 'styled-components';

const HIGHLIGHT_COLOR = '#20ABCC';
const HIGHLIGHT_BLINK_SPACE = 24;
const HIGHLIGHT_SPACE = 6;

const HighlightBlink = styled(Box)`
  @keyframes blink {
    0% {
      opacity: 0.7;
      transform: scale(0);
    }

    70% {
      opacity: 0;
      transform: scale(1.1);
    }

    100% {
      opacity: 0;
      transform: scale(2);
    }
  }

  animation: blink 3s infinite;
`;

interface FeatureHighlightProps {
  style?: React.CSSProperties;
  height?: number;
  width?: number;
}

export const FeatureHighlight: React.FC<FeatureHighlightProps> = ({
  style,
  height,
  width
}) => {
  return (
    <Box
      w={HIGHLIGHT_BLINK_SPACE}
      h={HIGHLIGHT_BLINK_SPACE}
      position="relative"
      style={{
        zIndex: 2,
        opacity: 0.8,
        ...style
      }}
    >
      <HighlightBlink
        w={HIGHLIGHT_BLINK_SPACE}
        h={HIGHLIGHT_BLINK_SPACE}
        rounded="full"
        position="absolute"
        style={{
          backgroundColor: `${HIGHLIGHT_COLOR}`,
          zIndex: 2
        }}
      />
      <Box
        w={width || HIGHLIGHT_SPACE}
        h={height || HIGHLIGHT_SPACE}
        rounded="full"
        top="50%"
        left="50%"
        position="absolute"
        style={{
          backgroundColor: `${HIGHLIGHT_COLOR}`,
          transform: 'translate(-50%, -50%)',
          zIndex: 1
        }}
      />
    </Box>
  );
};
