import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme';
import { useViewport } from '../../hooks/useViewportHook';
import { Body, BodySize, TypographyVariant } from '../Typography';

const Wrapper = styled.div<{
  backgroundColor: string;
  isMobileSM: boolean | undefined;
}>`
  padding: 12px;
  text-align: center;
  max-width: ${({ isMobileSM }) => (isMobileSM ? '100%' : '462px')};
  width: ${({ isMobileSM }) => isMobileSM && '100%'};
  border-radius: 12px;
  background: ${({ backgroundColor }) => backgroundColor};
  margin: 0 auto 16px;
`;

interface IChatAlert {
  color?: string;
  backgroundColor?: string;
  text?: string;
}

export const ChatAlert: React.FC<IChatAlert> = ({
  text = '',
  color = Theme.colors.spinach[500],
  backgroundColor = Theme.colors.spinach[50]
}) => {
  const { isMobileSM } = useViewport();
  return (
    <Wrapper isMobileSM={isMobileSM} backgroundColor={backgroundColor}>
      <Body
        margin="0"
        color={color}
        variant={TypographyVariant.Regular}
        size={BodySize.Body70}
      >
        {text}
      </Body>
    </Wrapper>
  );
};
