import { IAppointment } from '@unobravo/core';
import { DateTime } from 'luxon';
import React from 'react';
import { Stack, Text } from '@unobravo/zenit-web';

import { SessionStatus } from '@unobravo-monorepo/common/types/IAppointments';
import {
  DotBooked,
  DotPlaceholder,
  DotCompleted,
  DotCanceled,
  DotExpired,
  DotEmpty,
  DotConfirmed
} from './icons/AppointmentDots';

import { filterSessionsByStatus, getSessionStatus } from '../utils/session';

const getTextColor = ({
  isSelected,
  isToday,
  isBookable
}: {
  isSelected: boolean;
  isToday: boolean;
  isBookable?: boolean;
}) => {
  if (isToday) {
    return isSelected ? 'white' : 'grey.800';
  }
  return isBookable ? 'grey.800' : 'grey.600';
};

const DotIndicatorMap = {
  DONE: DotCompleted,
  CANCELED: DotCanceled,
  EXPIRED: DotExpired,
  NEW: DotBooked,
  CONFIRMED: DotConfirmed,
  VIDEOCALL: DotConfirmed
};

const getBackGroundColor = ({
  isToday,
  itemStatus
}: {
  isToday: boolean;
  itemStatus?: SessionStatus;
}) =>
  isToday
    ? 'grey.800'
    : itemStatus === 'DONE' || itemStatus === 'CONFIRMED'
    ? 'spinach.100'
    : undefined;

const DotIndicator = ({
  isBookable,
  disableWeekendDay,
  itemStatus
}: {
  isBookable: boolean;
  disableWeekendDay: boolean;
  itemStatus?: SessionStatus;
}) => {
  if (itemStatus) {
    const Dot = DotIndicatorMap[itemStatus];
    if (Dot) return <Dot />;
  }
  if (isBookable && !disableWeekendDay) return <DotPlaceholder />;
  return <DotEmpty />;
};

export const DayItem: React.FC<{
  dayOfWeek: string;
  dayOfMonth: number;
  date: DateTime;
  selectedDay: DateTime;
  sessions: IAppointment[] | [];
  showVideocall?: boolean;
  disableWeekendDay?: boolean;
  isBookable: boolean;
}> = ({
  dayOfWeek,
  dayOfMonth,
  date,
  selectedDay,
  sessions,
  isBookable,
  showVideocall = true,
  disableWeekendDay = false
}) => {
  const dateIso = date.toISODate();
  const today = DateTime.now();
  const isToday = dateIso === today.toISODate();
  const isSelected = dateIso === selectedDay.toISODate();
  const firstSessionsDone = sessions?.find((session) =>
    filterSessionsByStatus({
      session,
      statuses: ['DONE']
    })
  );
  const session = firstSessionsDone || sessions[0];

  const itemStatus = getSessionStatus({
    session,
    today,
    date,
    showVideocall,
    isToday
  });

  return (
    <Stack
      key={dateIso}
      alignContent="center"
      direction="column"
      style={{
        position: 'relative'
      }}
    >
      <Stack w={35} h={35} align="center" justify="center">
        <Text
          color="grey.500"
          variant="sm"
          textAlign="center"
          fontWeight="medium"
        >
          {dayOfWeek}
        </Text>
      </Stack>
      <Stack
        rounded="xs"
        bgColor={getBackGroundColor({ isToday, itemStatus })}
        w={35}
        h={35}
        align="center"
        justify="center"
        style={{
          transition: 'background-color 0.2s linear'
        }}
      >
        <Text
          color={getTextColor({
            isSelected,
            isToday,
            isBookable
          })}
          variant="lg"
          fontWeight="normal"
          textAlign="center"
          style={{ transition: 'color 0.2s ease-in-out' }}
        >
          {dayOfMonth}
        </Text>
      </Stack>
      <Stack w={35} h={15} align="center" justify="center">
        <DotIndicator
          isBookable={isBookable}
          disableWeekendDay={disableWeekendDay}
          itemStatus={itemStatus}
        />
      </Stack>
    </Stack>
  );
};
