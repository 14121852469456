import { User } from 'firebase/auth';
import { Providers } from './providers';

export const userHas = (provider: Providers, user: User | null) =>
  user?.providerData?.some((p) => p.providerId === provider);

export const getProvider = (user: User | null) => {
  if (userHas(Providers.Google, user)) return Providers.Google;
  if (userHas(Providers.Apple, user)) return Providers.Apple;
  if (userHas(Providers.Email, user)) return Providers.Email;
  return undefined;
};
