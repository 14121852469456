import { useApolloClient } from '@apollo/client';
import { useRegistrationStatus } from '@unobravo/signup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { refreshUserInfo } from '../utils/social';

const MS_POLL_TIME = 1000;
const MS_REDIRECT_TIME = 3200;

// this is the only way we find
// to let it work
// navigate, even with context doesn't work
const hardRedirectToPatient = () => {
  window.location.href = '/';
};

export const usePollingRedirect = () => {
  const [pollingStarted, setPollingStarted] = useState(false);

  const client = useApolloClient();

  const navigate = useNavigate();

  const { registrationStatus } = useRegistrationStatus({
    fetchPolicy: 'no-cache',
    pollInterval: MS_POLL_TIME,
    client,
    skip: !pollingStarted
  });

  useEffect(() => {
    const { initialized, signupCompleted } =
      registrationStatus?.getRegistrationStatus ?? {};

    if (initialized && signupCompleted) {
      // sometimes the match procedure is slow
      // this timeout reassures us that
      // the user logs in with the match already finished
      setTimeout(() => {
        refreshUserInfo().then(hardRedirectToPatient);
      }, MS_REDIRECT_TIME);
    }
  }, [navigate, registrationStatus]);

  const start = () => {
    setPollingStarted(true);
  };

  return { start };
};
