import { Box, RStack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import styled from 'styled-components';
import { Card } from '../Card';

interface OtherActionProps {
  dataTestId: string;
  title: string;
  subtitle?: string;
  actionLabel: string;
  image: string;
  action: () => void;
}

const BackgroundContainer = styled(Box)<{ image: string }>`
  align-items: center;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 16px;
  height: 124px;
  width: 124px;
`;

export const OtherAction = ({
  title,
  subtitle,
  actionLabel,
  image,
  action,
  dataTestId
}: OtherActionProps) => {
  const { isMobile } = useBreakpointValue();
  return (
    <Box position="relative">
      <Card
        type="small"
        HeaderCard={
          <RStack spacing="2xs" direction="column">
            <Text color="cappuccino.900" fontWeight="semibold">
              {title}
            </Text>

            <Text
              style={{
                display: isMobile ? 'none' : 'block'
              }}
              color="cappuccino.700"
            >
              {subtitle}
            </Text>
          </RStack>
        }
      >
        <Box
          data-testid={dataTestId}
          style={{
            cursor: 'pointer',
            zIndex: 1
          }}
          onClick={action}
        >
          <Text fontWeight="semibold" color="candy.600">
            {actionLabel}
          </Text>
        </Box>
      </Card>

      <Box position="absolute" bottom={0} right={0}>
        <BackgroundContainer image={image} />
      </Box>
    </Box>
  );
};
