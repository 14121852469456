import React from 'react';
import { Theme } from '../../theme';
import { Close } from '../Icons/Close';
import {
  ToastDescription,
  ToastTitle,
  ToastWrapper,
  ToastIcon,
  ToastContent,
  ToastTypo,
  ToastClose
} from './Toast.styles';

export interface IToast {
  title: string;
  description: string;
  variant: 'success' | 'warning' | 'error';
  onClose?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  'data-testid'?: string;
  transparent?: boolean;
}

const Toast: React.FC<IToast> = (props) => {
  const {
    variant = 'success',
    title = 'Toast Title',
    description = 'Toast Description',
    onClose,
    transparent = true
  } = props;

  return (
    <ToastWrapper
      layout
      transparent={transparent}
      variant={variant}
      initial={{ y: 300, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ x: 300, opacity: 0 }}
      transition={{ stiffness: 200, damping: 400 }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={onClose}
      data-testid={props['data-testid']}
    >
      <ToastContent>
        <ToastIcon variant={variant} />
        <ToastTypo>
          <ToastTitle variant={variant}>{title}</ToastTitle>
          <ToastDescription>{description}</ToastDescription>
        </ToastTypo>
      </ToastContent>

      <ToastClose>
        <Close color={Theme.colors.gray[400]} height={12} width={12} />
      </ToastClose>
    </ToastWrapper>
  );
};

export default Toast;
