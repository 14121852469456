import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { useGTM } from '@unobravo-monorepo/common/hooks/useGTM';
import { Box, Button, Heading } from '@unobravo/zenit-web';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoverPassword } from '../hooks/useRecoverPassword';
import { AuthLayout } from '../layout';

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { recoverPassword, loading } = useRecoverPassword();
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  });
  const { pushSetPasswordEvent } = useGTM();

  const onSubmit = async (data: { email: string }) => {
    const success = await recoverPassword(data.email);
    if (success) {
      pushSetPasswordEvent(data.email);
      navigate('/login');
    }
  };

  return (
    <AuthLayout>
      <Box mb="md">
        <Heading variant="xl">{t('password.title')}</Heading>
      </Box>

      <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          data-testid="email"
          autoComplete="unobravo_password"
          placeholder={t('login.email')}
          type="email"
          errorMessage={formState?.errors?.email?.message}
          {...register('email', {
            required: `${t('password.errors.email')}`,
            pattern: {
              value:
                /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: `${t('password.errors.email')}`
            }
          })}
        />
        <Box mt="md">
          <Button
            size="lg"
            disabled={!formState.isValid || loading}
            fullWidth
            type="submit"
            data-testid="submit"
            label={t('password.button')}
          />
        </Box>
      </form>
    </AuthLayout>
  );
};
