import React from 'react';
import styled from 'styled-components';
import { mediaQueries, Theme } from '@unobravo-monorepo/common';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  TypographyVariant
} from '@unobravo-monorepo/common/components/Typography';
import { useTranslation } from 'react-i18next';
import { CircleRight } from '@unobravo-monorepo/common/components/Icons';
import { ChatAlert } from '@unobravo-monorepo/common/components/ChatAlert';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { useDispatch } from 'react-redux';
import { RatingStars } from '@unobravo-monorepo/common/components/RatingStars';
import { toggleRightMenu } from '../../layout/layout.slice';
import { useDoctorInfo } from '../../../shared/hooks/useDoctorInfo';
import { usePatient } from '../../patientData/hooks/usePatient';

const Wrapper = styled.div`
  width: 462px;
  max-width: 462px;
  ${mediaQueries('sm')`
    max-width: 100%;
    width: 100%;
`}
  padding: 12px;
  border-radius: 12px;
  background-color: ${Theme.colors.cappuccino[200]};
  margin-bottom: 20px;
`;

const Rectangle = styled.div`
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 0px 12px 12px 12px;
  border-radius: 12px;
  background-color: ${Theme.specialColors.white};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ProfileImg = styled.img`
  border-radius: 70px;
  width: 70px;
  height: 70px;
  object-fit: cover;
`;

const LeftSide = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  align-self: center;
  margin: 12px 12px 0px 0px;
`;

const RightSide = styled.div`
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const HeadingWrapper = styled.div`
  max-width: 300px;
  ${mediaQueries('sm')`
    max-width:100%;
    width:100%;
`}
`;

const Footer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  margin-left: -2px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

const FooterWithoutStart = styled(Footer)`
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

export function TherapistAlert() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    profilePicture,
    name,
    therapeuticOrientation,
    sex,
    isTherapist,
    reviews
  } = useDoctorInfo();

  const { name: patientName } = usePatient();

  const role = isTherapist
    ? t('chat.profiletp.therapist')
    : t(`chat.profiletp.noTherapist.${sex}`);

  const orientation = therapeuticOrientation
    ? t(`chat.profiletp.orientation`, {
        orientation: therapeuticOrientation,
        role
      })
    : '';
  const { isMobile } = useViewport();

  return isMobile ? (
    <Wrapper>
      <Body
        margin="0px 0px 8px 0px"
        size={BodySize.Body70}
        variant={TypographyVariant.Regular}
        color={Theme.colors.gray[700]}
        textAlign="center"
      >
        {t(
          `chat.newAlertMatch.mobile.firstPart.${(
            sex || 'MALE'
          ).toLowerCase()}`,
          {
            name: patientName
          }
        )}
      </Body>
      <Rectangle
        data-testid="tp-profile-welcome-alert"
        onClick={() => dispatch(toggleRightMenu())}
      >
        {profilePicture && (
          <LeftSide>
            <ProfileImg src={profilePicture} />
          </LeftSide>
        )}

        <RightSide>
          <HeadingWrapper>
            <Heading
              noWrap
              size={HeadingSize.Title70}
              variant={TypographyVariant.Bold}
            >
              {name || ''}
            </Heading>
          </HeadingWrapper>
          <Body size={BodySize.Body60} color={Theme.colors.gray[800]}>
            {orientation}
          </Body>
          {reviews?.length >= 0 ? (
            <Footer>
              <RatingStars height={16} width={16} numberOfStars={5} />
              <CircleRight />
            </Footer>
          ) : (
            <FooterWithoutStart>
              <CircleRight />
            </FooterWithoutStart>
          )}
        </RightSide>
      </Rectangle>
      <Body
        size={BodySize.Body70}
        variant={TypographyVariant.Regular}
        color={Theme.colors.gray[700]}
        textAlign="center"
      >
        {t(
          `chat.newAlertMatch.mobile.secondPart.${(
            sex || 'MALE'
          ).toLowerCase()}`
        )}
      </Body>
    </Wrapper>
  ) : (
    <ChatAlert
      backgroundColor={Theme.colors.cappuccino[200]}
      color={Theme.colors.gray[700]}
      text={t(`chat.newAlertMatch.${(sex || 'MALE').toLowerCase()}`, {
        name: patientName,
        doctorName: name || ''
      })}
    />
  );
}
