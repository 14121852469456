import { TouchPointLayout } from '@unobravo-monorepo/common/layouts/touchpoint';
import { MatchingSlider } from '@unobravo-monorepo/signup/components/MatchingSlider';
import { Button, Stack, Text, useBreakpointValue } from '@unobravo/zenit-web';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PAGE_TIMEOUT = 7000; // 7 seconds

const OPEN_GUIDE_EVENT_NAME = 'open-guide';
const DISMISS_GUIDE_EVENT_NAME = 'dismiss-guide';

export const AfterSelection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();

  const [searchParams] = useSearchParams();

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const doctorName = searchParams.get('name');

  const redirectToHome = () => {
    navigate('/');
  };

  const onModalOpen = () => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
  };

  const onModalDismiss = () => {
    redirectToHome();
  };

  useEffect(() => {
    window.addEventListener(OPEN_GUIDE_EVENT_NAME, onModalOpen);
    window.addEventListener(DISMISS_GUIDE_EVENT_NAME, onModalDismiss);

    return () => {
      window.removeEventListener(OPEN_GUIDE_EVENT_NAME, onModalOpen);
      window.removeEventListener(DISMISS_GUIDE_EVENT_NAME, onModalDismiss);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timerId.current = setTimeout(redirectToHome, PAGE_TIMEOUT);

    return () => {
      if (timerId.current) clearTimeout(timerId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <TouchPointLayout
      topLeft={isMobile ? '' : undefined}
      bottomRight={isMobile ? '' : undefined}
    >
      <MatchingSlider
        label={t('selectYourTp.afterSelection.title', {
          doctorName
        })}
      />

      <Stack
        rounded="md"
        border="sm"
        borderColor="cappuccino.300"
        bgColor="cappuccino.100"
        direction="column"
        align="center"
        spacing="xl"
        p="xl"
      >
        <Stack direction="column" spacing="2xs" align="center">
          <Text
            variant="xl"
            fontWeight="semibold"
            color="candy.800"
            textAlign="center"
          >
            {t('selectYourTp.afterSelection.tellMore')}
          </Text>

          <Text
            variant="md"
            fontWeight="normal"
            color="candy.800"
            textAlign="center"
          >
            {t('selectYourTp.afterSelection.why', { doctorName })}
          </Text>
        </Stack>

        <Button
          data-testid="why-tp-button"
          fullWidth
          label={t('selectYourTp.afterSelection.answer')}
        />
      </Stack>
    </TouchPointLayout>
  );
};
