import { RadioButton } from '@unobravo-monorepo/common/components/RadioButton';
import { useViewport } from '@unobravo-monorepo/common/hooks/useViewportHook';
import { ArrowRight } from '@unobravo/zenit-icons';
import {
  Box,
  Button,
  Heading,
  ModalAlert,
  Stack,
  Text
} from '@unobravo/zenit-web';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { resetChat } from '@unobravo/patient';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from '../../../shared/hooks/useErrorHandler';
import { useZendesk } from '../../../shared/hooks/useZendesk';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useReassignment } from '../hooks/useReassignment';
import { useToast } from '../../toaster/hooks/useToast';
import {
  closeReassignmentModal,
  reassignmentSelector
} from '../reassignment.slice';

interface StackChildren {
  children: React.ReactNode;
}

interface CancelButtonProp {
  close: () => void;
}

function StackModalContainer({ children }: StackChildren) {
  return (
    <Stack direction="column" spacing="xs" mb="xl" align="start" pt="md">
      {children}
    </Stack>
  );
}

function StackButtons({ children }: StackChildren) {
  const { isMobile } = useViewport();
  return (
    <Stack
      direction={isMobile ? 'column-reverse' : 'row'}
      justify="end"
      spacing="xs"
      pt="md"
      align="start"
      alignContent="start"
    >
      {children}
    </Stack>
  );
}

function CancelButton({ close }: CancelButtonProp) {
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  return (
    <Button
      variant="ghost"
      label={t('common:cancel')}
      onClick={close}
      fullWidth={isMobile}
    />
  );
}

function ModalContent() {
  const { isMobile } = useViewport();
  const {
    name: patientName,
    email,
    id,
    doctor,
    refetch: refetchPatient
  } = usePatient();
  const { reassign, reassignmentLoading } = useReassignment();
  const { register, handleSubmit, reset, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: { reason: '' }
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goToZendeskAsLoggedUser } = useZendesk();
  const { t } = useTranslation();
  const { sendErrorMessage } = useErrorHandler();
  const { sendToast } = useToast();
  const [answer, setAnswer] = useState<string | undefined>();

  const close = () => {
    setAnswer(undefined);
    reset();
    dispatch(closeReassignmentModal());
  };

  // "hc" in zendesk url is needed when it contains parameters
  const reasons = [
    {
      label: t('reassignment.step0.reason1'),
      value: 'reassignment-no-matching-creteria'
    },
    {
      label: t('reassignment.step0.reason2'),
      value: 'reassignment-specific-reason',
      zendeskUrl: `hc/requests/new?ticket_form_id=13724037369617&tf_anonymous_requester_email=${email}&tf_17449127529233=necessita_molto_specifiche`
    },
    {
      label: t('reassignment.step0.reason3'),
      value: 'reassignment-more-info-needed'
    },
    {
      label: t('reassignment.step0.reason4'),
      value: 'reassignment-change-survey'
    },
    {
      label: t('reassignment.step0.reason5'),
      value: 'reassignment-no-matching-timeslot',
      zendeskUrl: `hc/requests/new?ticket_form_id=13724037369617&tf_anonymous_requester_email=${email}&tf_17449127529233=incompatibilita_oraria_csp`
    },
    {
      label: t('reassignment.step0.reason6'),
      value: 'reassignment-no-reason'
    }
  ];

  const onSubmitButton = (data: { reason: string }) => {
    setAnswer(data.reason);
  };

  const automaticReassign = async () => {
    try {
      if (id) {
        await reassign(id);
        await refetchPatient();
        dispatch(resetChat());
        sendToast({
          variant: 'success',
          title: t('reassignment.successToastTitle'),
          ...(!isMobile
            ? { description: t('reassignment.successToastDescription') }
            : {})
        });

        close();
      }
    } catch {
      close();
      sendErrorMessage(t('reassignment.errorDescription'));
    }
  };

  const contactUs = () => {
    const url = reasons.find((one) => one.value === answer)?.zendeskUrl ?? '';
    goToZendeskAsLoggedUser(url);
    close();
  };

  const Step0Component = (
    <>
      <form onSubmit={handleSubmit(onSubmitButton)}>
        <StackModalContainer>
          <Heading variant="md">{t('reassignment.step0.title')}</Heading>
          {reasons.map(({ label, value }) => (
            <RadioButton
              {...register('reason', { required: true })}
              key={value}
              label={label}
              dataTestId={value}
              value={value}
            />
          ))}
        </StackModalContainer>
        <StackButtons>
          <CancelButton close={close} />
          <Button
            variant="outlined"
            label={t('common:buttonNext')}
            icon={ArrowRight}
            data-testid="reassignment-reason-chosed"
            type="submit"
            fullWidth={isMobile}
            disabled={!watch('reason')}
          />
        </StackButtons>
      </form>
    </>
  );

  const Step1Algorithm = (
    <>
      <StackModalContainer>
        <Heading variant="md">
          {t('reassignment.step1General.title', {
            doctorName: doctor?.name
          })}
        </Heading>
        <Box mb="md">
          <Text color="grey.600" variant="md">
            {t(
              `reassignment.step1General.description.${
                doctor?.sex === 'MALE' ? 'male' : 'female'
              }`,
              { doctorName: doctor?.name }
            )}
          </Text>
        </Box>
        <Text color="grey.600" variant="md">
          {t('reassignment.reassignmentConclusion', {
            doctorName: doctor?.name
          })}
        </Text>
      </StackModalContainer>
      <StackButtons>
        <CancelButton close={close} />
        <Stack data-testid={answer} w={isMobile ? '100%' : 'auto'}>
          <Button
            label={t('common:next')}
            data-testid="reassignment-confirm"
            disabled={reassignmentLoading}
            loading={reassignmentLoading}
            onClick={automaticReassign}
            fullWidth={isMobile}
          />
        </Stack>
      </StackButtons>
    </>
  );

  const Step1NewSurvey = (
    <>
      <StackModalContainer>
        <Heading variant="md">{t('reassignment.step1NewSurvey.title')}</Heading>
        <Text color="grey.600" variant="md">
          {t(`reassignment.step1NewSurvey.description`, {
            doctorName: doctor?.name
          })}
        </Text>
      </StackModalContainer>
      <StackButtons>
        <CancelButton close={close} />
        <Stack data-testid={answer} w={isMobile ? '100%' : 'auto'}>
          <Button
            label={t('reassignment.step1NewSurvey.button')}
            data-testid="reassignment-new-survey"
            disabled={reassignmentLoading}
            loading={reassignmentLoading}
            onClick={() => {
              navigate('/patient/new-survey');
              dispatch(closeReassignmentModal());
            }}
            fullWidth={isMobile}
          />
        </Stack>
      </StackButtons>
    </>
  );

  const Step1ContactComponent = (
    <>
      <StackModalContainer>
        <Heading variant="md">
          {t('reassignment.step1Contact.title', {
            name: patientName
          })}
        </Heading>
        <Box mb="md">
          <Text color="grey.600" variant="md">
            {t('reassignment.step1Contact.description')}
          </Text>
        </Box>
        <Text color="grey.600" variant="md">
          {t('reassignment.reassignmentConclusion', {
            doctorName: doctor?.name
          })}
        </Text>
      </StackModalContainer>
      <StackButtons>
        <CancelButton close={close} />
        <Stack data-testid={answer} w={isMobile ? '100%' : 'auto'}>
          <Button
            label={t('reassignment.step1Contact.contactUs')}
            data-testid="reassignment-contact-us"
            onClick={contactUs}
            fullWidth={isMobile}
          />
        </Stack>
      </StackButtons>
    </>
  );

  if (!answer) {
    return Step0Component;
  }
  if ([reasons[1].value, reasons[4].value].includes(answer)) {
    return Step1ContactComponent;
  }
  if (reasons[3].value === answer) {
    return Step1NewSurvey;
  }
  return Step1Algorithm;
}

export function ReassignmentModal() {
  const { isReassignmentModalOpen } = useSelector(reassignmentSelector);
  const dispatch = useDispatch();

  return (
    <ModalAlert
      open={isReassignmentModalOpen}
      onClose={() => dispatch(closeReassignmentModal())}
    >
      <ModalContent />
    </ModalAlert>
  );
}
