import React from 'react';
import ExtendedIIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const User: React.FC<ExtendedIIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black,
  strokeWidth = 1.5
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 12C10.9604 11.9974 9.96418 11.5768 9.22909 10.83C8.49401 10.0832 8.07992 9.07118 8.07738 8.01511C8.07738 5.82133 9.84137 4 12 4C13.0396 4.00258 14.0358 4.42325 14.7709 5.17C15.506 5.91675 15.9201 6.92882 15.9226 7.98489C15.9226 10.1787 14.1586 12 12 12ZM18.125 20H5.875C5.39375 20 5 19.6 5 19.1111V18.2222C5 16.2667 6.575 14.6667 8.5 14.6667H15.5C17.425 14.6667 19 16.2667 19 18.2222V19.1111C19 19.6 18.6063 20 18.125 20Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
