import { Logo } from '@unobravo-monorepo/common/components/Logo';
import { usePatientUtils } from '@unobravo/patient';
import { useInternationalVariables } from '@unobravo/translations';
import {
  Alert,
  Box,
  Button,
  Heading,
  RBox,
  RStack,
  Text,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import errorDeleteDesktop from '../assets/error_delete_desktop.svg';
import errorDeleteMobile from '../assets/error_delete_mobile.svg';
import successDeleteDesktop from '../assets/success_delete_desktop.svg';
import successDeleteMobile from '../assets/success_delete_mobile.svg';

type Variant = 'success' | 'error';

interface DeleteWrapperProps {
  variant: Variant;
  children: ReactNode;
}

const backgroundDictionary = {
  success: {
    mobile: successDeleteMobile,
    desktop: successDeleteDesktop
  },
  error: {
    mobile: errorDeleteMobile,
    desktop: errorDeleteDesktop
  }
} as const satisfies Record<Variant, Record<'mobile' | 'desktop', string>>;

const getBackground = (isMobile: boolean, variant: Variant) =>
  backgroundDictionary[variant][isMobile ? 'mobile' : 'desktop'];

const DeleteWrapper = ({ variant, children }: DeleteWrapperProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();

  const background = useMemo(
    () => getBackground(isMobile, variant),
    [isMobile, variant]
  );

  const resultLabel =
    variant === 'success'
      ? t('deleteUser.confirm.result')
      : t('deleteUser.error.result');

  const title =
    variant === 'success'
      ? t('deleteUser.confirm.title')
      : t('deleteUser.error.title');

  if (!background) return null;

  return (
    <RStack
      w="100%"
      h={{ base: 'auto', md: '100dvh' }}
      style={{
        backgroundImage: `url("${background}")`,
        backgroundSize: 'cover',
        backgroundPositionY: isMobile
          ? variant === 'success'
            ? '-10vh'
            : '-20vh'
          : undefined
      }}
      justify={{ base: undefined, md: 'center' }}
      align={{ base: undefined, md: 'center' }}
    >
      <RStack
        style={{
          transform: isMobile ? `translateY(30%)` : undefined
        }}
        bgColor="white"
        roundedTop="3xl"
        roundedBottom={{ base: undefined, md: '3xl' }}
        justify="center"
        align="center"
        direction="column"
        px={{ base: 'md', md: undefined }}
      >
        <Logo
          width={isMobile ? '146px' : '290px'}
          height={isMobile ? '40px' : '81px'}
          alt="unobravo-logo"
          style={{
            marginTop: isMobile
              ? '40px'
              : variant === 'success'
              ? '104px'
              : '79px',
            marginBottom: isMobile ? '24px' : '72px'
          }}
        />

        <RStack
          direction="column"
          style={{
            marginLeft: isMobile ? undefined : '198px',
            marginRight: isMobile ? undefined : '198px'
          }}
          maxW={{ base: undefined, md: '593px' }}
        >
          <RStack
            spacing={variant === 'success' ? 'md' : 'sm'}
            direction="column"
            mb="xs"
            style={{ alignSelf: 'center' }}
            maxW={{ base: '328px', md: undefined }}
          >
            <Text
              color="candy.500"
              textAlign="center"
              fontWeight={isMobile ? 'bold' : 'black'}
              variant={isMobile ? 'md' : 'sm'}
              style={{ letterSpacing: isMobile ? undefined : '1.68px' }}
            >
              {resultLabel}
            </Text>
            <Heading
              variant="xl"
              textAlign="center"
              style={{
                fontSize: isMobile ? '28px' : '39px'
              }}
            >
              {title}
            </Heading>
          </RStack>

          {children}
        </RStack>
      </RStack>
    </RStack>
  );
};

const Success = () => {
  const { t } = useTranslation();

  const { tellMoreDeleteAccount } = useInternationalVariables();

  const { isMobile } = useBreakpointValue();
  return (
    <RStack
      direction="column"
      style={{
        marginBottom: isMobile ? undefined : '173px',
        alignSelf: 'center'
      }}
    >
      <Text
        color="grey.800"
        textAlign="center"
        variant="lg"
        style={{
          marginBottom: isMobile ? '24px' : '60px',
          maxWidth: isMobile ? '328px' : undefined,
          alignSelf: 'center'
        }}
      >
        {t('deleteUser.confirm.subtitle')}
      </Text>

      <RBox
        mx={{ base: 'sm', md: undefined }}
        mb={{ base: 'md', md: undefined }}
      >
        <Alert
          title={t('deleteUser.confirm.alert.title')}
          message={t('deleteUser.confirm.alert.message', {
            link: tellMoreDeleteAccount
          })}
          type="informative"
        />
      </RBox>
    </RStack>
  );
};

const Error = () => {
  const { t } = useTranslation();

  const { supportUrl, patientUrl } = useInternationalVariables();

  const { isMobile } = useBreakpointValue();
  return (
    <RStack
      mb={{ base: 'lg', md: undefined }}
      direction="column"
      style={{
        marginBottom: isMobile ? undefined : '79px',
        alignSelf: 'center'
      }}
    >
      <RStack w={{ base: undefined, md: '500px' }} direction="column">
        <Text
          color="grey.800"
          textAlign="center"
          variant="lg"
          style={{
            marginBottom: isMobile ? '24px' : '32px',
            maxWidth: isMobile ? '328px' : undefined,
            alignSelf: 'center'
          }}
        >
          {t('deleteUser.error.subtitle1')}
        </Text>

        <Text
          color="grey.800"
          textAlign="center"
          variant="lg"
          style={{
            marginBottom: isMobile ? '24px' : '80px',
            maxWidth: isMobile ? '328px' : undefined,
            alignSelf: 'center'
          }}
        >
          {t('deleteUser.error.subtitle2')}
        </Text>
      </RStack>

      <Box
        style={{
          alignSelf: 'center',
          marginBottom: isMobile ? '24px' : '64px'
        }}
      >
        <a
          href={patientUrl}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button size="lg" label={t('deleteUser.error.cta')} />
        </a>
      </Box>

      <RBox mx={{ base: 'xs', md: undefined }}>
        <Text variant="lg" color="grey.800" textAlign="center">
          {t('deleteUser.error.help', { link: supportUrl })}
        </Text>
      </RBox>
    </RStack>
  );
};

export function ConfirmDelete() {
  const loading = useRef(false);
  const { token } = useParams();
  const [status, setStatus] = useState<Variant>();
  const { confirmDeletePatient } = usePatientUtils();

  useEffect(() => {
    if (status || loading.current) return;

    loading.current = true;

    confirmDeletePatient(token || '').then((result) => {
      setStatus(result ? 'success' : 'error');
    });
  }, [confirmDeletePatient, status, token]);

  if (!status) return null;

  return (
    <DeleteWrapper variant={status}>
      {status === 'success' ? <Success /> : <Error />}
    </DeleteWrapper>
  );
}
