import { Theme } from '../../theme';
import IIcon from '../../types/IIcon';

export const DownloadArrow: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5575 11.1104L12 14.6678L8.4425 11.1104"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.99707V14.6685"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0033 16.4463C20.0033 18.411 18.4106 20.0038 16.4459 20.0038H7.55416C5.58941 20.0038 3.99667 18.411 3.99667 16.4463"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
