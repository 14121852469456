import { Modal, useBreakpointValue } from '@unobravo/zenit-web';
import { PaymentContext } from '../../types/paymentContext';
import { BundleModalContent } from './BundleModalContent';

export const BundleModal = ({
  uuid,
  context
}: {
  uuid?: string;
  context: PaymentContext;
}) => {
  const { isMobile } = useBreakpointValue();

  return (
    <>
      <Modal
        open
        w={600}
        h={isMobile ? '100%' : 660}
        dataTestId="pay-session-modal"
      >
        <BundleModalContent context={context} uuid={uuid} />
      </Modal>
    </>
  );
};
