import { Box } from '@unobravo/zenit-web';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from '../..';
import { useViewport } from '../../hooks/useViewportHook';
import { IMenuItem } from '../../types/IMenuItem';

import { StorageKeys, createSafeStorage } from '../../utils/storage';
import { FeatureHighlight } from '../FeatureHighlight';
import { TypographyVariant } from '../Typography';
import { Body, BodySize } from '../Typography/Body';

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px;
`;

interface ITextWrapper {
  menuIsOpen?: boolean;
}

const TextWrapper = styled.div<ITextWrapper>`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  overflow: visible;
  width: ${({ menuIsOpen }) => (menuIsOpen ? 'auto' : '0px')};
`;

interface IWrapper {
  voiceActive: boolean;
}

const Wrapper = styled.div<IWrapper>`
  background-color: ${({ voiceActive }) =>
    voiceActive ? Theme.colors.cappuccino[50] : 'none'};
  border: ${({ voiceActive }) =>
    voiceActive
      ? `1px solid ${Theme.colors.cappuccino[200]}`
      : '1px solid transparent'};
  border-radius: 12px;
  height: 50px;
  position: relative;
  left: 0;
  width: 100%;
  margin-bottom: 4px;
  overflow-x: hidden;

  :hover {
    background-color: ${Theme.colors.cappuccino[100]};
  }
`;

const MenuLinkSupport = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
`;

const MenuLink = styled(Link)`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  padding: 10px 0;
`;

const MenuLinkExternal = styled.a`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  padding: 10px 0;
`;

const Notification = styled.div`
  position: absolute;
  left: 36px;
  top: 9px;
  width: 6px;
  height: 6px;
  background-color: ${Theme.colors.candy[500]};
  border-radius: 21px;
  box-shadow: -1px 2px 5px rgba(255, 80, 50, 0.4);
`;

interface IMenuItemComponent extends IMenuItem {
  menuIsOpen: boolean | undefined;
  closeMenu?: () => void;
  small?: boolean;
  external?: boolean;
  handleSupportLinkClick?: () => void;
  /**
   * - When undefined we consider only highlight
   * - When true the highlight is viewed only once, it disappears after a click on the menu item
   * - When false the highlight is not visible
   */
  highlightFirstTime?: boolean;
}

const [getHighlight, setHighlight] = createSafeStorage(StorageKeys.sidebar);

export const MenuItem: React.FC<IMenuItemComponent> = ({
  Icon,
  title,
  path,
  notification,
  menuIsOpen,
  closeMenu,
  small,
  external,
  handleSupportLinkClick,
  id,
  highlight,
  highlightFirstTime = false
}) => {
  const { isMobile } = useViewport();
  const location = useLocation();

  const pathToCheck =
    path.activeIfOverride || (path.pathname || '').replace('../', '');

  const isActive =
    pathToCheck && path.exact
      ? location.pathname.endsWith(pathToCheck)
      : location.pathname.split('/').includes(pathToCheck);

  const handleCloseMenu = () => {
    if (isMobile && closeMenu) {
      closeMenu();
    }
  };

  const handleHighlightClick = () => {
    if (!highlightFirstTime) return;

    setHighlight(true, { suffix: id });
  };

  const shouldHighlightSection = useMemo(() => {
    if (!highlight) return false;

    if (highlight && highlightFirstTime === undefined) return true;

    if (highlightFirstTime === false) return false;

    const highLight = getHighlight({ suffix: id });

    if (!highLight) return true;

    return false;
  }, [highlight, highlightFirstTime, id]);

  const content = (
    <>
      <InfoContainer>
        <IconWrapper>
          <Icon
            width={small ? 24 : 26}
            height={small ? 24 : 26}
            color={Theme.colors.candy[500]}
          />
        </IconWrapper>
        <TextWrapper menuIsOpen={menuIsOpen}>
          <Body
            size={small ? BodySize.Body60 : BodySize.Body70}
            variant={TypographyVariant.SemiBold}
            color={Theme.specialColors.black}
            noWrap
          >
            {title}
          </Body>
        </TextWrapper>
      </InfoContainer>
      {notification && <Notification />}
      {shouldHighlightSection && (
        <Box position="absolute" left={2} top={2}>
          <FeatureHighlight />
        </Box>
      )}
    </>
  );

  return (
    <Wrapper
      voiceActive={isActive}
      data-testid={`sidebar-menu-${id}`}
      onClick={handleHighlightClick}
    >
      {id === 'support' && handleSupportLinkClick ? (
        <MenuLinkSupport onClick={handleSupportLinkClick}>
          {content}
        </MenuLinkSupport>
      ) : external ? (
        <MenuLinkExternal
          onClick={handleCloseMenu}
          target="_blank"
          href={path.pathname}
        >
          {content}
        </MenuLinkExternal>
      ) : (
        <MenuLink onClick={handleCloseMenu} to={`${path.pathname}`}>
          {content}
        </MenuLink>
      )}
    </Wrapper>
  );
};
