import { useColor } from '@unobravo/zenit-core';
import { Box, Stack, Text } from '@unobravo/zenit-web';
import { ReactNode } from 'react';
import { Line } from './icons/Line';
import { LeftIcon } from './LeftIcon';

const LineComponent = () => (
  <Box position="absolute" bottom={0} left={0} right={0} top={30}>
    <Line color="candy.200" width="100%" height="90%" />
  </Box>
);

export const CurrentStep = ({
  title,
  description,
  children,
  showDashedLine = false
}: {
  title: string;
  description: string;
  children: ReactNode;
  showDashedLine: boolean;
}) => {
  const startGradient = useColor('sand.50');
  const endGradient = useColor('sand.100');

  return (
    <Stack direction="row" spacing="md">
      <LeftIcon checked={false} showDashedLine={showDashedLine} />
      <Box
        style={{
          background: `linear-gradient(${startGradient},${endGradient})`
        }}
        position="relative"
        rounded="md"
        px="md"
        py="sm"
        grow
      >
        <LineComponent />
        <Box position="relative" style={{ zIndex: 1 }}>
          <Box pb="xs">
            <Text color="grey.800" fontWeight="medium">
              {title}
            </Text>
          </Box>
          <Text color="grey.700">{description}</Text>
          <Box>{children}</Box>
        </Box>
      </Box>
    </Stack>
  );
};
