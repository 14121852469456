import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { IAppointment } from '@unobravo/core';
import {
  Button,
  ModalAlert,
  RStack,
  useBreakpointValue
} from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ModifyAppointmentModal: React.FC<{
  appointment: IAppointment;
  openModifyModal: boolean;
  setOpenModifyModal: (value: boolean) => void;
}> = ({ appointment, openModifyModal, setOpenModifyModal }) => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpointValue();
  const { id: sessionId } = appointment;
  const { t } = useTranslation();

  const onGoToChatClicked = () => {
    pendoTrack('modify_session_modal', {
      sessionId
    });
    navigate('../chat');
  };
  return (
    <ModalAlert
      open={openModifyModal}
      onClose={() => setOpenModifyModal(false)}
      w="500px"
      title={t('therapySetting.modifyAppointmentModal.title')}
      description={t('therapySetting.modifyAppointmentModal.body')}
    >
      <RStack
        direction={{ base: 'column', md: 'row-reverse' }}
        justify="end"
        spacing="sm"
      >
        <Button
          data-testid="modify-appointment-open-chat-confirm"
          fullWidth={isMobile}
          label={t('therapySetting.modifyAppointmentModal.openChat')}
          onClick={onGoToChatClicked}
        />
        <Button
          fullWidth={isMobile}
          data-testid="modify-appointment-open-chat-cancel"
          label={t('therapySetting.modifyAppointmentModal.cancel')}
          variant="ghost"
          onClick={() => setOpenModifyModal(false)}
        />
      </RStack>
    </ModalAlert>
  );
};
