import React from 'react';
import IIcon from '../../types/IIcon';

export const Progress: React.FC<IIcon> = ({ width = 146, height = 146 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 146 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="73"
        cy="73"
        r="70"
        fill="white"
        stroke="#EEDFD5"
        strokeWidth="6"
      />
      <mask
        id="mask0_110:8"
        maskUnits="userSpaceOnUse"
        x="14"
        y="23"
        width="120"
        height="120"
      >
        <g clipPath="url(#clip0_110:8)">
          <path
            d="M119.705 118.574V142.337H27.6553V118.574C27.6553 98.7519 43.783 82.6265 63.6028 82.6265H83.7578C103.578 82.6265 119.705 98.7519 119.705 118.574Z"
            fill="#00A5FF"
          />
          <path
            d="M101.477 50.946C101.477 66.2729 89.0068 78.7435 73.6798 78.7435C58.3529 78.7435 45.8823 66.2729 45.8823 50.946C45.8823 35.619 58.3529 23.1484 73.6798 23.1484C89.0068 23.1484 101.477 35.619 101.477 50.946Z"
            fill="#00A5FF"
          />
          <path
            d="M73.6797 78.7435V23.1484C89.0066 23.1484 101.477 35.619 101.477 50.946C101.477 66.2729 89.0066 78.7435 73.6797 78.7435Z"
            fill="#0087FF"
          />
          <path
            d="M119.705 118.574V142.337H73.6797V82.6265H83.7572C103.577 82.6265 119.705 98.7519 119.705 118.574Z"
            fill="#0087FF"
          />
        </g>
      </mask>
      <g mask="url(#mask0_110:8)">
        <circle
          opacity="0.6"
          cx="73.1193"
          cy="72.063"
          r="69.152"
          fill="url(#paint0_linear_110:8)"
        />
      </g>
      <path
        d="M146 73C146 32.6832 113.317 0 73 0C32.6832 0 0 32.6832 0 73C0 113.317 32.6832 146 73 146C113.317 146 146 113.317 146 73ZM5.4433 73C5.4433 35.6895 35.6895 5.4433 73 5.4433C110.311 5.4433 140.557 35.6895 140.557 73C140.557 110.311 110.311 140.557 73 140.557C35.6895 140.557 5.4433 110.311 5.4433 73Z"
        fill="url(#paint1_angular_110:8)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_110:8"
          x1="73.1193"
          y1="2.91107"
          x2="73.1193"
          y2="141.215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB380" />
          <stop offset="1" stopColor="#FF5032" />
        </linearGradient>
        <radialGradient
          id="paint1_angular_110:8"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(73 73) rotate(-90) scale(73)"
        >
          <stop stopColor="#FF5032" />
          <stop offset="1" stopColor="#FFB380" />
        </radialGradient>
        <clipPath id="clip0_110:8">
          <rect
            width="119.189"
            height="119.189"
            fill="white"
            transform="translate(14.0859 23.1484)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
