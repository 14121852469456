import { Box, Button, RStack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ConfirmedConsent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <RStack direction="column" p="xl" w={{ base: '100%', md: '384px' }}>
      <RStack pb="md" direction="column">
        <Box pb="xs">
          <Text color="candy.500">{t('home.confirmConsent.title')}</Text>
        </Box>
        <Box pb="2xs">
          <Text
            fontWeight="semibold"
            variant="2xl"
            color="black"
            style={{
              opacity: '0.8'
            }}
          >
            {t('home.confirmConsent.message')}
          </Text>
        </Box>
        <Text
          variant="sm"
          color="grey.600"
          style={{
            opacity: '0.6'
          }}
        >
          {t('home.confirmConsent.description')}
        </Text>
      </RStack>
      <Box>
        <Button
          data-testid="hp-go-to-informed-consent-hero"
          label={t('home.confirmConsent.button')}
          size="md"
          variant="tonal"
          onClick={() => navigate('../preferences/informedConsent')}
        />
      </Box>
    </RStack>
  );
};
