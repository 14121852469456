import { useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import {
  TrackLoginDocument,
  TrackLoginMutationVariables
} from '../graphql/mutations.generated';

interface TrackLoginParams {
  variables: TrackLoginMutationVariables;
}

export const useTrackLogin = () => {
  const [trackLogin] = useMutation(TrackLoginDocument);

  return (params: TrackLoginParams) =>
    trackLogin(params).catch(captureException);
};
