import { useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';
import { pendoTrack } from '@unobravo-monorepo/common/utils/pendoUtils';
import { transformAmount } from '@unobravo-monorepo/common/utils/priceUtils';
import { useErrorHandler } from '@unobravo-monorepo/patient/shared/hooks/useErrorHandler';
import { usePatientGTM } from '@unobravo-monorepo/patient/shared/hooks/usePatientGTM';
import {
  IAppliedVoucher,
  ICreditCard,
  PatientStatus,
  SepaDebit,
  useFirstPayment,
  useGTMUtils,
  usePaymentIntent
} from '@unobravo/patient';
import { useCountry } from '@unobravo/translations';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSession } from '../../../shared/hooks/useSession';
import {
  paySessionDataSelector,
  setPaymentIntent
} from '../../newPaySession/paySessionData.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useApplyVoucher } from './useApplyVoucher';
import { layoutSelector } from '../../layout/layout.slice';

export const useStripePaymentIntent = (sessionId: string) => {
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { getPaymentIntent } = usePaymentIntent();
  const session = useSession(sessionId!);
  const { domainCountry } = useCountry();
  const dispatch = useDispatch();
  const { applyBonus, applyVoucher } = useApplyVoucher();
  const { sendGenericError } = useErrorHandler();
  const { status, setStatus, platformCountry, email, doctor, id, uuid } =
    usePatient();
  const { candidateForFirstPurchase } = useFirstPayment(sessionId!);
  const { GTMData } = useGTMUtils();
  const { pushAuthenticatedEvent } = usePatientGTM();
  const stripe = useStripe();
  const {
    paymentIntent,
    voucher: selectedVoucher,
    selectedCard
  } = useSelector(paySessionDataSelector);
  const { isMobileApp } = useSelector(layoutSelector);

  const getPaymentIntentData = useCallback(
    async (clientIntent: string) => {
      if (!stripe) {
        return;
      }
      const result = await stripe.retrievePaymentIntent(clientIntent);
      return result;
    },
    [stripe]
  );

  useEffect(() => {
    async function intent() {
      if (
        session?.id !== undefined &&
        (paymentIntent?.sessionId !== session.id || !paymentIntentId)
      ) {
        const result = await getPaymentIntent(parseInt(session.id, 10));
        setPaymentIntentId(result);
      }
    }
    intent();
  }, [session.id, session.uuid]);

  useEffect(() => {
    async function secret() {
      if (paymentIntentId) {
        const result = await getPaymentIntentData(paymentIntentId);
        if (result?.paymentIntent) {
          dispatch(
            setPaymentIntent({ ...result.paymentIntent, sessionId: session.id })
          );
        }
      }
    }
    secret();
  }, [paymentIntentId, session.id, session.uuid]);

  const confirmPaymentWithSavedId = async (
    clientIntent: string,
    paymentId: string,
    type: 'CARD' | 'SEPA' | 'KLARNA'
  ) => {
    setLoading(true);

    if (!stripe) {
      sendGenericError();
      return;
    }
    let result: PaymentIntentResult | undefined;
    if (type === 'CARD') {
      try {
        result = await stripe.confirmCardPayment(clientIntent, {
          payment_method: paymentId
        });
      } catch {
        // do nothing
      }
    }
    if (type === 'SEPA') {
      try {
        result = await stripe.confirmSepaDebitPayment(clientIntent, {
          payment_method: paymentId
        });
      } catch {
        // do nothing
      }
    }
    if (type === 'KLARNA') {
      try {
        result = await stripe.confirmKlarnaPayment(clientIntent, {
          payment_method: {
            billing_details: {
              address: {
                country: platformCountry ?? domainCountry
              },
              email: email ?? undefined
            }
          },
          return_url: window.location.href
        });
      } catch {
        // do nothing
      }
    }
    setLoading(false);
    if (!result?.paymentIntent) return result;
    if (['NEW', 'FREE_SESSION'].includes(status || '')) {
      setStatus(PatientStatus.Ongoing);
    }

    return result;
  };

  const paySession: (newCardId?: string) => Promise<
    | {
        intent?: PaymentIntent;
        voucher?: Partial<IAppliedVoucher> & { code?: string };
      }
    | undefined
  > = async (newCardId) => {
    if (!session?.uuid || !paymentIntentId) return;

    let appliedVoucher: Partial<IAppliedVoucher> & { code?: string } = {
      code: selectedVoucher?.code
    };
    if (selectedVoucher?.success) {
      appliedVoucher = {
        ...appliedVoucher,
        ...(selectedVoucher.code
          ? await applyVoucher(selectedVoucher.code, session.uuid)
          : await applyBonus(session.uuid))
      };

      if (!appliedVoucher || !appliedVoucher.success) {
        throw new Error('Fail applying voucher');
      }
      appliedVoucher &&
        pendoTrack(
          selectedVoucher.code ? 'voucher_applied' : 'bonus_voucher_applied',
          selectedVoucher
        );
    }

    if (appliedVoucher?.success && appliedVoucher?.total === 0) {
      return { voucher: appliedVoucher };
    }

    if (!selectedCard && !newCardId) throw new Error('Missing card');
    const cardId =
      newCardId ??
      (selectedCard !== 'klarna'
        ? (selectedCard as ICreditCard)?.cardId
        : undefined);

    const sepaId =
      selectedCard !== 'klarna'
        ? (selectedCard as SepaDebit)?.sepaId
        : undefined;
    const paymentType =
      selectedCard === 'klarna' ? 'KLARNA' : cardId ? 'CARD' : 'SEPA';

    const result = await confirmPaymentWithSavedId(
      paymentIntentId,
      cardId ?? sepaId ?? 'klarna',
      paymentType
    );

    if (result?.error) {
      throw new Error(result.error.message);
    }

    const amount = result?.paymentIntent?.amount
      ? result.paymentIntent.amount / 100
      : null;
    const ISODate = session.sessionDate?.rawDate?.toISO();
    pushAuthenticatedEvent('Purchase', {
      voucher_code: appliedVoucher?.code,
      voucher_amount: appliedVoucher?.discount,
      total_amount: amount,
      tp_id: doctor?.id,
      tp_first_name: doctor?.name,
      tp_last_name: doctor?.surname,
      session_type: paymentType,
      session_date: ISODate,
      currency: 'EUR',
      user_id: id,
      uuid,
      is_firstpurchase: candidateForFirstPurchase,
      voucher_campaignname: appliedVoucher?.campaignName,
      application: isMobileApp ? 'APP' : 'WEB',
      ...GTMData
    });
    pendoTrack('pay_session', {
      amount
    });
    if (result?.paymentIntent) {
      setPaymentIntent({ ...result?.paymentIntent, sessionId: session.id });
    }

    return { intent: result?.paymentIntent, voucher: appliedVoucher };
  };

  return {
    paymentIntentId,
    ...paymentIntent,
    amount: transformAmount(paymentIntent?.amount),
    clientSecret: paymentIntent?.client_secret,
    paySession,
    paymentLoading: loading
  };
};
