import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { usePatientFeatureFlags } from '../../../featureFlags';
import { vouchersSelector } from '../vouchers.slice';
import { usePatient } from '../../patientData/hooks/usePatient';
import { ListAchievementsDocument } from '../graphql/queries.generated';
import { VoucherStatus } from '../types';

const getAchievementVoucherStatus = ({
  achieved,
  redeemedAt,
  validUntil
}: {
  achieved: boolean;
  redeemedAt: string;
  validUntil: string;
}): VoucherStatus => {
  if (!achieved) return 'not-achieved';

  if (redeemedAt) return 'used';

  const isExpired = DateTime.fromISO(validUntil).diffNow().milliseconds < 0;

  if (isExpired) return 'expired';

  return 'achieved';
};

export const useListAchievements = () => {
  const { ptVouchers } = usePatientFeatureFlags();
  const { lastAchievementSnapshot } = useSelector(vouchersSelector);
  const { id } = usePatient();

  const { data } = useQuery(ListAchievementsDocument, {
    variables: { id: id! },
    skip: !id || !ptVouchers || !lastAchievementSnapshot
  });
  const listAchievements = useMemo(
    () =>
      data?.listAchievements.map((achievement) => {
        if (!achievement.voucher) {
          return { ...achievement, voucher: undefined };
        }

        const voucherStatus = getAchievementVoucherStatus({
          achieved: achievement.achieved,
          redeemedAt: achievement.voucher.redeemedAt,
          validUntil: achievement.voucher.validUntil
        });

        return {
          ...achievement,
          voucher: { ...achievement.voucher, voucherStatus }
        };
      }) || [],
    [data?.listAchievements]
  );

  return listAchievements;
};
