import { Box, Stack, Text } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';

export const TherapySettingRow = ({
  label,
  value
}: {
  label: string;
  value?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="xs" align="center">
      <Box w="100px">
        <Text variant="sm" color="grey.700">
          {t(label)}
        </Text>
      </Box>
      <Box>
        <Text variant="sm" fontWeight="bold" color="grey.800">
          {value}
        </Text>
      </Box>
    </Stack>
  );
};
