import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const Eye: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.425 9.57715C15.0677 10.2208 15.4287 11.0933 15.4287 12.0029C15.4287 12.9125 15.0677 13.7849 14.425 14.4286C13.7814 15.0713 12.9089 15.4322 11.9993 15.4322C11.0897 15.4322 10.2173 15.0713 9.57361 14.4286C8.93092 13.7849 8.56995 12.9125 8.56995 12.0029C8.56995 11.0933 8.93092 10.2208 9.57361 9.57715C9.89203 9.25837 10.2702 9.00548 10.6864 8.83294C11.1026 8.6604 11.5488 8.57159 11.9993 8.57159C12.4499 8.57159 12.896 8.6604 13.3123 8.83294C13.7285 9.00548 14.1066 9.25837 14.425 9.57715"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.71423 12C1.71423 11.2469 1.88795 10.5017 2.22395 9.81486C3.95538 6.27543 7.78166 4 11.9999 4C16.2182 4 20.0445 6.27543 21.7759 9.81486C22.1119 10.5017 22.2857 11.2469 22.2857 12C22.2857 12.7531 22.1119 13.4983 21.7759 14.1851C20.0445 17.7246 16.2182 20 11.9999 20C7.78166 20 3.95538 17.7246 2.22395 14.1851C1.88959 13.5052 1.71524 12.7577 1.71423 12V12Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
