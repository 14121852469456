import { gql } from '@apollo/client';

export const ACCEPT_PATIENT_SURVEY_AGREEMENT = gql`
  mutation AcceptPatientSurveyAgreement(
    $patientId: Float!
    $consent3a: Boolean!
    $consent3g: Boolean!
  ) {
    acceptPatientSurveyAgreement(
      patientId: $patientId
      consent3a: $consent3a
      consent3g: $consent3g
    ) {
      version
      validFrom
      validTo
      platformCountry
    }
  }
`;
