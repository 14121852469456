import { Color } from '@unobravo/zenit-core';
import { Stack, Text } from '@unobravo/zenit-web';
import { ReactNode } from 'react';

export const BundleChip = ({
  bgColor,
  textColor,
  text,
  weight,
  icon
}: {
  bgColor: Color;
  textColor?: Color;
  text: string;
  weight?: 'normal' | 'medium';
  icon?: ReactNode;
}) => (
  <Stack
    bgColor={bgColor}
    rounded="2xl"
    px="xs"
    py="2xs"
    align="center"
    spacing="2xs"
  >
    {icon && icon}
    <Text color={textColor} variant="sm" fontWeight={weight}>
      {text}
    </Text>
  </Stack>
);
