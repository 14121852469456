import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PatientRootState } from '../../store';

export interface IToast {
  id: string | number;
  title: string;
  description: string;
  variant: 'success' | 'warning' | 'error';
  duration?: number | 'persistent';
  expired?: boolean;
}

interface IToasterState {
  toasts: IToast[];
  hoverToast?: string | number;
}

const initialState: IToasterState = {
  toasts: []
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.toasts = [...state.toasts, action.payload];
    },
    setHover: (state, action) => {
      state.hoverToast = action.payload;
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(({ id }) => id !== action.payload);
      state.hoverToast = undefined;
    },
    checkAndRemove: (state, action) => {
      if (state.hoverToast === action.payload) {
        state.toasts = state.toasts.map((t) =>
          t.id === action.payload ? { ...t, expired: true } : t
        );
        return;
      }
      state.toasts = state.toasts.filter(({ id }) => id !== action.payload);
    }
  }
});

export default toasterSlice.reducer;

export const { addToast, removeToast, setHover, checkAndRemove } =
  toasterSlice.actions;

type IToastWithDismiss = Omit<IToast, 'id'> & {
  id?: undefined | string | number;
};

export const addToastWithDismiss = createAsyncThunk<
  void,
  IToastWithDismiss,
  { state: PatientRootState }
>('toaster/addToastWithDismiss', async (payload, thunkAPI) => {
  const id = thunkAPI.getState().toaster.toasts.length;
  const duration = payload?.duration;
  const toast = payload;
  thunkAPI.dispatch(
    addToast({
      ...toast,
      id: toast?.id || id
    })
  );
  duration !== 'persistent' &&
    setTimeout(() => thunkAPI.dispatch(checkAndRemove(id)), duration || 4000);
});

export const toasterSelector = (state: PatientRootState): IToasterState =>
  state.toaster;
