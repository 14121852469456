import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { IAppointment } from '@unobravo-monorepo/common/types/IAppointments';
import { theme } from '@unobravo/zenit-core';
import { Box, RStack, Stack, Text } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentBubble } from '../../../shared/components/AppointmentBubble';
import { getSessionDate } from '../../../shared/utils/dateUtils';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useAgenda } from '../hooks/useAgenda';
import { NoAppointment } from './NoAppointment';

export const AgendaPastAppointments: React.FC = () => {
  const { t } = useTranslation();
  const { pastSessions, loadAppointments, hasPreviousPage, status } =
    useAgenda();
  const { timezone } = usePatient();

  const groupByMonth = useMemo(() => {
    const result: { [key: string]: IAppointment[] } = {};

    pastSessions
      .filter(
        ({ when }) =>
          DateTime.fromISO(when).plus({ minutes: 50 }) < DateTime.now()
      )
      .forEach((session) => {
        const { month, year } = getSessionDate(
          session.when,
          timezone || undefined
        );
        const monthKey = `${month} ${year}`;

        if (result[monthKey]) {
          result[monthKey].push(session);
        } else {
          result[monthKey] = [session];
        }
      });

    return result;
  }, [pastSessions, timezone]);

  return (
    <RStack direction="column" w="100%" h="100%" pr="md" pl="md">
      {status === 'loading' && pastSessions.length === 0 && <Spinner />}
      {pastSessions.length > 0 && (
        <Stack direction="column" align="center">
          {Object.keys(groupByMonth).map(
            (month) =>
              groupByMonth[month].length > 0 && (
                <Box key={month} mt="2xl" w="100%">
                  <Box mb="xs" style={{ textTransform: 'capitalize' }}>
                    <Text variant="sm" color="grey.300" textAlign="center">
                      {month}
                    </Text>
                  </Box>
                  {groupByMonth[month].map((session) => (
                    <Box mb="xs" key={session.id}>
                      <AppointmentBubble
                        session={session}
                        key={session.id}
                        handleInvoice
                        handleCreditNote
                      />
                    </Box>
                  ))}
                </Box>
              )
          )}

          {hasPreviousPage && (
            <Box
              onClick={loadAppointments}
              mt="xs"
              data-testid="agenda-more-appointments-button"
              style={{
                cursor: 'pointer',
                color: theme.colors.candy[500]
              }}
            >
              <Text color="candy.500" variant="sm" fontWeight="semibold">
                {t('agenda.moreAppointments')}
              </Text>
            </Box>
          )}
        </Stack>
      )}
      {status === 'done' && pastSessions.length === 0 && (
        <NoAppointment isPast />
      )}
    </RStack>
  );
};
