import { Header } from '@unobravo-monorepo/common/components/Header';
import { Progress } from '@unobravo-monorepo/common/components/Icons/Progress';
import { PlainBackgroundLayout } from '@unobravo-monorepo/common/components/PlainBackgroundLayout';
import { Spinner } from '@unobravo-monorepo/common/components/Spinner/Spinner';
import { TextInput } from '@unobravo-monorepo/common/components/TextInput';
import { Box, Button, Heading, Stack, Text } from '@unobravo/zenit-web';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useErrorHandler } from '../../shared/hooks/useErrorHandler';
import { usePatient } from '../patientData/hooks/usePatient';
import { useSendVerificationEmail } from '../patientData/hooks/useSendVerificationEmail';
import { useToast } from '../toaster/hooks/useToast';

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function ActivatePatient() {
  const { t } = useTranslation();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sendToast } = useToast();
  const { sendGenericError } = useErrorHandler();
  const { email, loaded, updateEmail } = usePatient();
  const {
    send,
    resend,
    error: sendEmailError,
    isForcedResend
  } = useSendVerificationEmail();
  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email
    }
  });
  const { errors, isValid } = formState;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showToast = useCallback(sendGenericError, []);

  useEffect(() => {
    send();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (sendEmailError && isForcedResend) {
      showToast();
    }
  }, [sendEmailError, isForcedResend, showToast]);

  const handleSendEmail = async () => {
    await resend();
    sendToast({
      title: t('verifyEmailScreen.emailSent'),
      variant: 'success'
    });
  };

  const handleToggleUpdateEmail = async () => {
    await setShowEmailForm(!showEmailForm);
  };

  const onSubmit = async (data: { email?: string | null }) => {
    setLoading(true);
    const success = await updateEmail(data.email!);
    if (success) {
      await resend();
      setShowEmailForm(false);
    }
    setLoading(false);
  };
  if (!loaded) {
    return <Spinner />;
  }
  return (
    <PlainBackgroundLayout>
      <Header bgTransparent />
      <Stack
        style={{ height: '100%' }}
        align="center"
        direction="column"
        justify="center"
      >
        <Progress />
        <Box mb="md" mt="2xl">
          <Heading
            style={{ letterSpacing: 0.25, fontWeight: 600 }}
            variant="xl"
          >
            {t('verifyEmailScreen.title')}
          </Heading>
        </Box>
        <Text
          variant="md"
          color="grey.500"
          textAlign="center"
          style={{ fontSize: 16 }}
        >
          {t('verifyEmailScreen.description', { email })}
        </Text>
        <Box mt="2xl" mb="2xl">
          <Text
            variant="md"
            color="grey.500"
            textAlign="center"
            style={{ fontSize: 16 }}
          >
            {t('verifyEmailScreen.description2', { hours: 48 })}
          </Text>
        </Box>
        <Box minW={360}>
          <Box mt="2xl">
            <Box mb="md">
              <Text textAlign="center" color="grey.500" variant="sm">
                {t('verifyEmailScreen.email')}
              </Text>
            </Box>
            <Box style={{ cursor: 'pointer' }} onClick={handleSendEmail}>
              <Text
                fontWeight="semibold"
                textAlign="center"
                color="candy.500"
                variant="sm"
              >
                {t('verifyEmailScreen.checkEmailCallToAction')}
              </Text>
            </Box>
          </Box>
          {showEmailForm ? (
            <Form
              onSubmit={handleSubmit(
                formState.isDirty ? onSubmit : handleToggleUpdateEmail
              )}
            >
              <TextInput
                {...register('email', {
                  required: t('common:requiredField') as string,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('verifyEmailScreen.invalidEmail')
                  }
                })}
                errorMessage={errors?.email?.message}
                name="email"
                label={t('verifyEmailScreen.label')}
                placeholder={t('verifyEmailScreen.placeholder')}
              />
              <Button
                disabled={!isValid || loading}
                type="submit"
                label={t('verifyEmailScreen.changeEmailSubmit')}
              />
            </Form>
          ) : (
            <Box>
              <Box mb="xs" mt="xs">
                <Text textAlign="center" color="grey.500" variant="sm">
                  {t('verifyEmailScreen.or')}
                </Text>
              </Box>
              <Box
                style={{ cursor: 'pointer' }}
                onClick={handleToggleUpdateEmail}
              >
                <Text
                  textAlign="center"
                  fontWeight="semibold"
                  color="candy.500"
                  variant="sm"
                >
                  {t('verifyEmailScreen.changeEmailCallToAction')}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Stack>
    </PlainBackgroundLayout>
  );
}
