import { useMutation } from '@apollo/client';
import { HookOptions } from '@unobravo/patient';
import { usePatient } from '../../patientData/hooks/usePatient';
import { ACCEPT_PATIENT_TOS } from '../graphql/mutation';

export const useAcceptPatientTos = (
  options: HookOptions<{
    acceptPatientTos: { prefPrivacy: boolean; prefTerms: boolean };
  }> = {}
) => {
  const { id } = usePatient();
  const { onCompleted, onError } = options;

  const [acceptPatientTos, { loading }] = useMutation(ACCEPT_PATIENT_TOS, {
    onCompleted,
    onError,
    variables: {
      patientId: id,
      data: {
        prefTerms: true,
        prefPrivacy: true
      }
    }
  });

  return {
    acceptPatientTos,
    loading
  };
};
