import { redirect, Route } from 'react-router-dom';
import { GenericErrorPage } from '@unobravo-monorepo/common/components/errorPage';

export function getDoctorIncentivesRoutes() {
  return (
    <Route
      path="/guide/*"
      lazy={async () => {
        const { DoctorIncentiveProviders } = await import('./index');
        return { Component: DoctorIncentiveProviders };
      }}
    >
      <Route path="page-not-found" element={<GenericErrorPage />} />
      <Route
        lazy={async () => {
          const { IncentivesLayout } = await import('./index');
          return { Component: IncentivesLayout };
        }}
      >
        <Route errorElement={<GenericErrorPage />}>
          <Route
            path=""
            lazy={async () => {
              const { Home } = await import('./index');
              return { Component: Home };
            }}
          />
          <Route
            path="test-and-learn"
            lazy={async () => {
              const { TestAndLearn } = await import('./index');
              return { Component: TestAndLearn };
            }}
          />
          <Route
            path="program"
            lazy={async () => {
              const { Program } = await import('./index');
              return { Component: Program };
            }}
          />
          <Route path="*" loader={() => redirect(`page-not-found`)} />
        </Route>
      </Route>
    </Route>
  );
}
