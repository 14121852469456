import { BackRight, Trash } from '@unobravo/zenit-icons';
import { Button, Stack, Text } from '@unobravo/zenit-web';

export const DateLabel = ({
  startDateLabel,
  endDateLabel,
  timeLabel,
  onDelete
}: {
  startDateLabel: string;
  endDateLabel?: string;
  timeLabel?: string;
  onDelete?: () => void;
}) => {
  return (
    <Stack
      borderColor="grey.100"
      border="md"
      rounded="xs"
      minW={250}
      justify="space-between"
      align="center"
      style={{
        padding: 12
      }}
    >
      <Stack
        style={timeLabel ? undefined : { flex: 1 }}
        justify="space-between"
        align="center"
        spacing="2xs"
        mr="sm"
      >
        <Text fontWeight="semibold" style={{ textTransform: 'capitalize' }}>
          {startDateLabel}
        </Text>
        {endDateLabel && (
          <>
            <BackRight color="candy.500" />
            <Text fontWeight="semibold" style={{ textTransform: 'capitalize' }}>
              {endDateLabel}
            </Text>
          </>
        )}
      </Stack>
      {timeLabel && (
        <Stack spacing="md" align="center">
          <Text color="grey.600">{timeLabel}</Text>
          <Button
            iconPosition="iconOnly"
            variant="ghost"
            iconOnly
            icon={Trash}
            onClick={onDelete}
          />
        </Stack>
      )}
    </Stack>
  );
};
