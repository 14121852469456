import { TimeInput } from '@unobravo-monorepo/common/components/TimeInput';
import { ArrowRight } from '@unobravo/zenit-icons';
import { Button, Heading, ModalAlert, Stack, Text } from '@unobravo/zenit-web';
import { DateTime, Interval } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateLabel } from './DateLabel';

const startWorkTime = DateTime.local().set({
  hour: 8,
  minute: 0,
  second: 0,
  millisecond: 0
});
const endWorkTime = DateTime.local().set({
  hour: 22,
  minute: 0,
  second: 0,
  millisecond: 1
});
const workTime = Interval.fromDateTimes(startWorkTime, endWorkTime);
const DURATION = 50;

export const AvailibilityTime = ({
  date,
  onClose,
  onCancel,
  onNext
}: {
  date: Interval;
  onClose: () => void;
  onCancel: () => void;
  onNext: (times: Interval) => void;
}) => {
  const { t } = useTranslation();
  const [time, setTime] = useState<{
    start: DateTime | undefined;
    end: DateTime | undefined;
  }>();

  const startDateLabel = date.start ? date.start.toFormat('DD, ccc') : '';
  const endDateLabel =
    date.start !== date.end ? date.end?.toFormat('DD, ccc') : undefined;

  const timeFormat = (h: DateTime) => h.toFormat('HH:mm');
  const errorWorkTimeLabel = t('chat.availability.workTimeError', {
    startTime: timeFormat(startWorkTime),
    endTime: timeFormat(endWorkTime)
  });

  const getEndError = () => {
    if (!time?.start || !time?.end) {
      return '';
    }
    if (time.start.diff(time.end, 'milliseconds').milliseconds > 0) {
      return t('chat.availability.afterErrorMessage');
    }
    if (time.end.diff(time.start, 'minutes').minutes < DURATION) {
      return t('chat.availability.intervalTimeError', { duration: DURATION });
    }
    if (!workTime.contains(time.end)) {
      return errorWorkTimeLabel;
    }
    return '';
  };

  const getStartError = () => {
    if (time?.start && !workTime.contains(time.start)) {
      return errorWorkTimeLabel;
    }
    return '';
  };

  const disabledButton =
    time?.start == null ||
    time?.end == null ||
    !time.start.isValid ||
    !time.end.isValid ||
    getEndError() !== '' ||
    getStartError() !== '';

  return (
    <ModalAlert
      open
      onClose={onClose}
      w={410}
      dataTestId="availability-time-close"
    >
      <Stack direction="column" spacing="2xs" mb="md">
        <Heading>{t('chat.availability.title')}</Heading>
        <Text color="grey.600">{t('chat.availability.timeDescription')}</Text>
      </Stack>

      <DateLabel startDateLabel={startDateLabel} endDateLabel={endDateLabel} />
      <Stack mt="md" mb="xl" direction="row" justify="space-between">
        <TimeInput
          label={t('chat.availability.from')}
          onChange={(from) => {
            const fromDate = DateTime.fromFormat(from.target.value, 'HH:mm');
            setTime((prevTime) => ({ start: fromDate, end: prevTime?.end }));
          }}
          errorMessage={getStartError()}
        />
        <TimeInput
          label={t('chat.availability.to')}
          onChange={(to) => {
            const toDate = DateTime.fromFormat(to.target.value, 'HH:mm');
            setTime((prevTime) => ({ start: prevTime?.start, end: toDate }));
          }}
          errorMessage={getEndError()}
        />
      </Stack>
      <Stack justify="space-between">
        <Button
          data-testid="availability-time-back"
          size="lg"
          variant="ghost"
          label={t('back')}
          onClick={onCancel}
        />
        <Button
          data-testid="availability-time-next"
          disabled={disabledButton}
          iconPosition="right"
          size="lg"
          icon={ArrowRight}
          label={t('buttonNext')}
          onClick={() =>
            time?.start &&
            time?.end &&
            onNext(Interval.fromDateTimes(time.start, time.end))
          }
        />
      </Stack>
    </ModalAlert>
  );
};
