import { useRive } from '@rive-app/react-canvas';
import loaderAnimation from '@unobravo-monorepo/signup/assets/animation/loader_simple.riv';
import { Box, Stack, Text } from '@unobravo/zenit-web';

interface MatchingSliderProp {
  label: string;
}

export const MatchingSlider = ({ label }: MatchingSliderProp) => {
  const { RiveComponent: Loader } = useRive({
    src: loaderAnimation,
    stateMachines: 'loader',
    autoplay: true
  });

  return (
    <Stack
      w={320}
      align="center"
      direction="column"
      style={{ zIndex: 1, marginBottom: '23dvh' }}
      spacing="2xs"
    >
      <Text
        variant="2xl"
        fontWeight="medium"
        color="grey.900"
        textAlign="center"
      >
        {label}
      </Text>
      <Box w="70px" h="70px">
        <Loader />
      </Box>
    </Stack>
  );
};
