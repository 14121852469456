import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

interface LogoProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  variant?: 'default' | 'no-margin';
}

export const Logo = ({ variant = 'default', ...imgProps }: LogoProps) => {
  const fileName = variant === 'no-margin' ? 'logo-no-margin.svg' : 'logo.svg';
  const logoPath = `/assets/logo/${fileName}`;

  return <img src={logoPath} alt="logo" {...imgProps} />;
};
