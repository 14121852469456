import { Country } from '@unobravo/core';
import { useMemo } from 'react';
import { getLocale } from '../utils';

type Domain = 'unobravo' | 'buencoco';

export const useCountry = (): {
  domain: Domain;
  domainCountry: Country;
  userCountry: null;
} => {
  const { NX_DOMAIN } = process.env;

  const domainCountry = useMemo(() => {
    return getLocale();
  }, []);

  const domain = useMemo(() => {
    const locale = getLocale();
    switch (locale) {
      case 'es':
        return 'buencoco';
      case 'it':
      case 'fr':
        return 'unobravo';
      default:
        return NX_DOMAIN as Domain;
    }
  }, []);

  return {
    domainCountry,
    domain,
    userCountry: null
  };
};
