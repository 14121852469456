import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Theme } from '../../theme';
import { TypographyVariant } from './consts';

export enum HeadingSize {
  Title100 = 'Title100',
  Title90 = 'Title90',
  Title80 = 'Title80',
  Title70 = 'Title70',
  Title60 = 'Title60',
  Title50 = 'Title50'
}

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const headingLevelMap: { [key in HeadingSize]: HeadingLevel } = {
  [HeadingSize.Title100]: 'h1',
  [HeadingSize.Title90]: 'h2',
  [HeadingSize.Title80]: 'h3',
  [HeadingSize.Title70]: 'h4',
  [HeadingSize.Title60]: 'h5',
  [HeadingSize.Title50]: 'h6'
};

interface IStyledHeading {
  variant?: TypographyVariant;
  color?: string;
  margin?: string | false;
  textAlign?: 'left' | 'right' | 'center';
  noWrap?: boolean;
}

const headingMarginMap: {
  [key in HeadingSize]: string;
} = {
  [HeadingSize.Title100]: '0 0 16px 0',
  [HeadingSize.Title90]: '0 0 8px 0',
  [HeadingSize.Title80]: '0 0 8px 0',
  [HeadingSize.Title70]: '0 0 8px 0',
  [HeadingSize.Title60]: '0 0 4px 0',
  [HeadingSize.Title50]: '0 0 4px 0'
};

const StyledHeading = styled.span<IStyledHeading>`
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${({ color }) => (color ? `color:${color};` : '')}
  margin: ${({ margin }) => margin || '0'};
  font-weight: ${({ variant }) => variant || TypographyVariant.Bold};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const Title100 = styled(StyledHeading)`
  font-family: ${Theme.fonts.primary};
  font-size: 3.1875rem;
  line-height: 100%;
  letter-spacing: 0.032rem;
`;

const Title90 = styled(StyledHeading)`
  font-family: ${Theme.fonts.primary};
  font-size: 2.375rem;
  line-height: 110%;
  letter-spacing: 0.024rem;
`;

const Title80 = styled(StyledHeading)`
  font-family: ${Theme.fonts.primary};
  font-size: 1.75rem;
  line-height: 115%;
  letter-spacing: 0.018rem;
`;

const Title70 = styled(StyledHeading)`
  font-family: ${Theme.fonts.primary};
  font-size: 1.3125rem;
  line-height: 120%;
  letter-spacing: 0.013rem;
`;

const Title60 = styled(StyledHeading)`
  font-family: ${Theme.fonts.secondary};
  font-size: 1rem;
  line-height: 130%;
  letter-spacing: 0.09rem;
`;

const Title50 = styled(StyledHeading)`
  font-family: ${Theme.fonts.secondary};
  font-size: 0.75rem;
  line-height: 130%;
  letter-spacing: 0.08rem;
`;

const HeadingComponentMap: {
  [key in HeadingSize]: StyledComponent<
    'span',
    DefaultTheme,
    IStyledHeading,
    never
  >;
} = {
  [HeadingSize.Title100]: Title100,
  [HeadingSize.Title90]: Title90,
  [HeadingSize.Title80]: Title80,
  [HeadingSize.Title70]: Title70,
  [HeadingSize.Title60]: Title60,
  [HeadingSize.Title50]: Title50
};

interface IHeadingPros {
  size: HeadingSize;
  variant?: TypographyVariant;
  color?: string;
  margin?: string | boolean;
  children: string;
  textAlign?: 'left' | 'right' | 'center';
  noWrap?: boolean;
}

/**
 * @deprecated This component should not be used use Heading from "@unobravo/zenit-web" instead.
 */

export const Heading: React.FC<IHeadingPros> = ({
  color,
  variant,
  margin,
  size,
  children,
  textAlign,
  noWrap
}) => {
  const level = headingLevelMap[size];
  const HeadingComponent = HeadingComponentMap[size];
  const defaultMargin = headingMarginMap[size];

  return (
    <HeadingComponent
      as={level}
      margin={margin === true ? defaultMargin : margin}
      variant={variant}
      color={color}
      textAlign={textAlign}
      noWrap={noWrap}
    >
      <ReactMarkdown
        disallowedElements={['pre', 'code', 'p']}
        unwrapDisallowed
        linkTarget="_blank"
      >
        {children}
      </ReactMarkdown>
    </HeadingComponent>
  );
};
