function getConsonants(str: string) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}

function getVowels(str: string) {
  return str.replace(/[^AEIOU]/gi, '');
}

function processNameOrSurname(rawStr: string, isSurname = true) {
  const str = rawStr.toUpperCase();
  let consonants = getConsonants(str);
  const vowels = getVowels(str);

  if (isSurname) {
    let result = consonants + vowels;
    result = result.padEnd(3, 'X').substring(0, 3);
    return result;
  }
  if (consonants.length > 3) {
    consonants = consonants[0] + consonants[2] + consonants[3];
  }
  let result = consonants + vowels;
  result = result.padEnd(3, 'X').substring(0, 3);
  return result;
}

export function generateFirstSixFiscalCode(name: string, surname: string) {
  const surnamePart = processNameOrSurname(surname);
  const namePart = processNameOrSurname(name, false);
  return surnamePart + namePart;
}
