import { TimeOver } from '@unobravo/zenit-icons';
import { Color } from '@unobravo/zenit-core';
import { Stack } from '@unobravo/zenit-web';
import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.8 }
});

const IconContainer = styled(Stack)`
  animation: ${fadeIn} 0.3s ease-out 0.3s forwards;
  opacity: 0;
  cursor: pointer;
  border-radius: ${({ theme }) =>
    `${theme.radius.lg}px ${theme.radius.lg}px ${theme.radius.lg}px 0px`};
`;

export const PendingModifyBadge = ({
  onClick,
  w = '20px',
  h = '20px',
  right = '-13px',
  top = '-15px',
  position = 'absolute',
  color = 'ginger.500',
  style = {}
}: {
  onClick?: () => void;
  w?: string;
  h?: string;
  right?: string;
  top?: string;
  position?: 'absolute' | 'fixed' | 'relative' | 'sticky';
  color?: Color;
  style?: object;
}) => (
  <IconContainer
    alignContent="center"
    position={position}
    bgColor={color}
    justify="center"
    align="center"
    w={w}
    h={h}
    right={right}
    top={top}
    onClick={onClick}
    style={style}
  >
    <TimeOver color="white" size="sm" />
  </IconContainer>
);
