import { mobileFirstMedia } from '@unobravo-monorepo/common';
import { HEADER_HEIGHT } from '@unobravo-monorepo/common/components/Chat';
import { ChatAlert } from '@unobravo-monorepo/common/components/ChatAlert';
import { mobileHeaderHeight } from '@unobravo-monorepo/common/layouts/consts';
import { useTheme } from '@unobravo/zenit-core';
import { Box } from '@unobravo/zenit-web';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useABTestVariant } from '@unobravo-monorepo/common/hooks/useABTestVariant';
import { useBonus } from '../../patientData/hooks/useBonus';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useShouldUpdateInformedConsent } from '../../patientData/hooks/useShouldUpdateInformedConsent';
import { BonusActivatedAlert } from './BonusActivatedAlert';
import { PatientAvailability } from '../../availabilityChat/PatientAvailability';
import { TherapistAlert } from './TherapistAlert';
import { UpdateInformedConsentAlert } from './UpdateInformedConsentAlert';

const MIN_EVENING_MESSAGE_HOUR = 19;
const MAX_EVENING_MESSAGE_HOUR = 8;

const AlertContainer = styled.div<{ position?: 'sticky' }>`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  ${({ position, theme }) =>
    position === 'sticky'
      ? [
          css`
            position: sticky;
            margin-top: -20px;
            padding-top: 12px;
            top: ${HEADER_HEIGHT + mobileHeaderHeight}px;
            background-color: ${theme.colors.white};
          `,
          mobileFirstMedia('md')(`top: ${HEADER_HEIGHT}px`)
        ]
      : ''}
`;
export const ChatAlerts = React.forwardRef<HTMLDivElement>((_, ref) => {
  const {
    showAlert: showBonusAlert,
    isBpEnabled,
    featureFlagBonus,
    code
  } = useBonus();
  const { t } = useTranslation();

  const {
    status: patientStatus,
    matchWithBp,
    doctor,
    prefForWhom,
    informedConsentAttachment
  } = usePatient();

  const theme = useTheme();
  // ? setting patientStatus to NEW when is null or undefined
  const shouldUpdateIC = useShouldUpdateInformedConsent();
  const shouldSignPartnerIC =
    prefForWhom === 'PARTNER' && !informedConsentAttachment;

  const status = patientStatus ?? 'NEW';
  const validateStatuses = ['NEW', 'REPLIER', 'NOT_CONVERTED'];

  const hasInfoBox = validateStatuses.includes(status);
  const passedCognitiveInterview = !hasInfoBox;

  const hasAlert =
    hasInfoBox ||
    matchWithBp ||
    showBonusAlert ||
    shouldUpdateIC ||
    shouldSignPartnerIC ||
    (featureFlagBonus && matchWithBp && !isBpEnabled);

  const shouldShowNightMessage = useMemo(() => {
    const currentHour = DateTime.utc().hour;
    return (
      status === 'NEW' &&
      (currentHour < MAX_EVENING_MESSAGE_HOUR ||
        currentHour >= MIN_EVENING_MESSAGE_HOUR)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vwoVariant = useABTestVariant('newChatAlertInChat');
  const allDayMessage = vwoVariant === 'allDayMessage';

  const i18nVariables = {
    context: doctor?.sex,
    doctorName: doctor?.name
  };
  return hasAlert ? (
    <>
      <AlertContainer theme={theme}>
        {hasInfoBox && (
          <>
            <TherapistAlert />
            <Box id="new-alert">
              <ChatAlert text={t('chat.newAlert')} />
              {shouldShowNightMessage && !allDayMessage ? (
                <ChatAlert text={t('chat.nightAlert', i18nVariables)} />
              ) : (
                allDayMessage && (
                  <ChatAlert text={t('chat.allDayMessage', i18nVariables)} />
                )
              )}
            </Box>
            <Box id="calendar-message" style={{ display: 'none' }}>
              <PatientAvailability />
            </Box>
          </>
        )}

        {featureFlagBonus && matchWithBp && !isBpEnabled && (
          <BonusActivatedAlert alertText={t(`chat.bonusAlert`)} />
        )}
        {showBonusAlert && (
          <BonusActivatedAlert
            alertText={t(
              `chat.bonusActivatedAlert.${(
                doctor?.sex || 'MALE'
              ).toLowerCase()}`,
              {
                code
              }
            )}
            linkText={t(`chat.account`)}
          />
        )}
      </AlertContainer>

      {passedCognitiveInterview && (shouldUpdateIC || shouldSignPartnerIC) && (
        <AlertContainer position="sticky" ref={ref}>
          <UpdateInformedConsentAlert />
        </AlertContainer>
      )}
    </>
  ) : null;
});
