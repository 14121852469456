import { RStack } from '@unobravo/zenit-web';
import React from 'react';

type Variants = 'xlarge';
interface CardProps {
  HeaderCard: React.ReactNode;
  children: React.ReactNode;
  variant?: Variants;
}

type StackHeight = Parameters<typeof RStack>[0]['h'];

const VariantsMap = {
  xlarge: { base: '264px', sm: '304px' }
} as const satisfies Record<Variants, StackHeight>;

export const CardContainer = ({
  HeaderCard,
  children,
  variant = 'xlarge'
}: CardProps) => {
  const h = VariantsMap[variant];
  return (
    <RStack
      direction="column"
      rounded="md"
      bgColor="cappuccino.100"
      justify="space-between"
      p="md"
      minH={h}
      w="100%"
    >
      {HeaderCard}
      {children}
    </RStack>
  );
};
