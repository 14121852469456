import { Box, useBreakpointValue, Accordion, Stack } from '@unobravo/zenit-web';
import { useTranslation } from 'react-i18next';
import { useDoctorInfo } from '@unobravo-monorepo/patient/shared/hooks/useDoctorInfo';
import { DoctorReviews } from '@unobravo-monorepo/patient/shared/components/ProfileTp/DoctorReviews';
import { PreMatchOptionFragment } from '@unobravo-monorepo/common/graphql/queries.generated';
import { DoctorProfessionalAreas } from '@unobravo-monorepo/patient/shared/components/ProfileTp/DoctorProfessionalAreas';
import { useDoctorPresentation } from '../hooks/useDoctorPresentation';
import { TpInfoBox } from '../../selectYourTp/components/tpInfoBox';
import { CurriculumInfo } from '../../selectYourTp/components/curriculumInfo';

export const DoctorAccordionsVariant = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpointValue();
  const doctor = useDoctorInfo();
  const { reviews, professionalAreas, name } = doctor;
  const { variants, doctorPresentation } = useDoctorPresentation();
  const doctorOption = {
    doctor,
    doctorPresentation
  } as unknown as PreMatchOptionFragment;
  const therapistName = t('chat.profiletp.reviews', {
    name: name.split(' ')[0]
  });
  const items = [];
  if (Array.isArray(variants) && variants.length > 0) {
    items[0] = {
      title: t('chat.profiletp.curiosity'),
      children: (
        <Stack direction="column" spacing="xs" mb="lg">
          {variants.map((variant) => (
            <TpInfoBox
              variant={variant}
              type="detail"
              doctorOption={doctorOption}
            />
          ))}
        </Stack>
      )
    };
  }
  if (
    Array.isArray(professionalAreas) &&
    professionalAreas.length > 0 &&
    doctor?.sex
  ) {
    items[1] = {
      title: t(`chat.profiletp.specializationTitle.${doctor?.sex}`),
      children: (
        <DoctorProfessionalAreas
          limit={professionalAreas.length > 5 ? 5 : professionalAreas.length}
        />
      )
    };
  }
  if (doctorPresentation) {
    items[2] = {
      title: t('chat.profiletp.curriculum'),
      children: (
        <Stack direction="column" spacing="xs" mb="lg">
          <CurriculumInfo
            variant="order"
            doctorPresentation={doctorPresentation}
            doctorOption={doctorOption}
          />
          {doctorPresentation.degree && (
            <CurriculumInfo
              variant="degree"
              doctorPresentation={doctorPresentation}
              doctorOption={doctorOption}
            />
          )}
          {doctorPresentation.yearsOfExperience &&
          doctorPresentation.yearsOfExperience >= 5 ? (
            <CurriculumInfo
              variant="job"
              doctorPresentation={doctorPresentation}
              doctorOption={doctorOption}
            />
          ) : null}
        </Stack>
      )
    };
  }
  if (reviews.length > 0) {
    items[3] = {
      title: `${therapistName.charAt(0).toUpperCase()}${therapistName.slice(
        1
      )}`,
      children: <DoctorReviews />
    };
  }
  return (
    <Box
      rounded="2xl"
      style={{ borderRadius: isMobile ? '32px 32px 0px 0px' : undefined }}
      bgColor="white"
      p="xs"
    >
      <Accordion value={0} items={items} spacing="xs" />
    </Box>
  );
};
