import * as Types from '../../../../generated/patient.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetDoctorPresentationVariablesQueryVariables = Types.Exact<{
  doctorId: Types.Scalars['Float'];
}>;


export type GetDoctorPresentationVariablesQuery = { __typename?: 'Query', getDoctorPresentationVariables?: { __typename?: 'DoctorPresentationVariables', completedSessionsCount?: number | null, degree?: string | null, ongoingPatientsCount?: number | null, university?: string | null, yearsOfExperience?: number | null } | null };


export const GetDoctorPresentationVariablesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetDoctorPresentationVariables"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"doctorId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getDoctorPresentationVariables"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"doctorId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"doctorId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completedSessionsCount"}},{"kind":"Field","name":{"kind":"Name","value":"degree"}},{"kind":"Field","name":{"kind":"Name","value":"ongoingPatientsCount"}},{"kind":"Field","name":{"kind":"Name","value":"university"}},{"kind":"Field","name":{"kind":"Name","value":"yearsOfExperience"}}]}}]}}]} as unknown as DocumentNode<GetDoctorPresentationVariablesQuery, GetDoctorPresentationVariablesQueryVariables>;