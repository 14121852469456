import {
  ChatContainerHeader,
  ChatInput,
  WrapperChatBox
} from '@unobravo-monorepo/common/components/Chat';
import {
  PageContainer,
  PageContainerBody,
  PageContainerHeader
} from '@unobravo-monorepo/common/components/PageContainer';
import { scrollToBottom } from '@unobravo-monorepo/common/utils/chatUtils';
import { useBreakpointValue } from '@unobravo/zenit-web';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientLayout } from '../../../shared/layouts/PatientLayout';
import { usePatient } from '../../patientData/hooks/usePatient';
import { useToast } from '../../toaster/hooks/useToast';
import { ChatBox } from '../components/ChatBox';
import { HeaderChat } from '../components/HeaderChat';
import { NoDoctorMatch } from '../components/NoDoctorMatch';
import { useChat } from '../hooks/useChat';

export const Chat = () => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useBreakpointValue();
  const { sendNewMessage, openChat } = useChat();
  const { sendToast } = useToast();
  const { t } = useTranslation();
  const { hasDoctorMatch } = usePatient();

  useEffect(() => {
    openChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendError = () => {
    sendToast({
      variant: 'error',
      title: t('common:errorPage.ops2'),
      description: t('common:errorPage.wentWrong2')
    });
  };

  return (
    <PatientLayout showTherapistSidebar={hasDoctorMatch}>
      <PageContainer ref={divRef} data-testid="scrollable-chat-box">
        {!hasDoctorMatch ? (
          <NoDoctorMatch />
        ) : (
          <>
            <PageContainerHeader>
              <HeaderChat />
            </PageContainerHeader>
            <PageContainerBody>
              {isMobile && (
                <ChatContainerHeader>
                  <HeaderChat />
                </ChatContainerHeader>
              )}
              <WrapperChatBox data-testid="patient-chat-box">
                <ChatBox
                  scrollToBottom={(scroll) => {
                    scrollToBottom(divRef.current, isMobile, scroll);
                  }}
                />
              </WrapperChatBox>
            </PageContainerBody>
            <ChatInput
              sendMessage={sendNewMessage}
              onError={onSendError}
              placeholder={t('chat.writeBox.placeholder')}
            />
          </>
        )}
      </PageContainer>
    </PatientLayout>
  );
};
