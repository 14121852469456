import React from 'react';
import IIcon from '../../types/IIcon';
import { Theme } from '../../theme';

export const DocDone: React.FC<IIcon> = ({
  width = 24,
  height = 24,
  strokeWidth = 1.5,
  color = Theme.specialColors.black
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66675 3.87504L9.07508 2.11462C9.17662 1.9878 9.30539 1.88544 9.45184 1.81512C9.5983 1.74479 9.7587 1.70831 9.92117 1.70837H14.0801C14.2425 1.70831 14.4029 1.74479 14.5494 1.81512C14.6959 1.88544 14.8246 1.9878 14.9262 2.11462L16.3334 3.87504V4.95837C16.3334 5.24569 16.2193 5.52124 16.0161 5.72441C15.813 5.92757 15.5374 6.04171 15.2501 6.04171H8.75008C8.46276 6.04171 8.18721 5.92757 7.98405 5.72441C7.78088 5.52124 7.66675 5.24569 7.66675 4.95837V3.87504Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66671 3.875H5.50004C4.9254 3.875 4.3743 4.10327 3.96798 4.5096C3.56165 4.91593 3.33337 5.46703 3.33337 6.04167V20.125C3.33337 20.6996 3.56165 21.2507 3.96798 21.6571C4.3743 22.0634 4.9254 22.2917 5.50004 22.2917H18.5C19.0747 22.2917 19.6258 22.0634 20.0321 21.6571C20.4384 21.2507 20.6667 20.6996 20.6667 20.125V6.04167C20.6667 5.46703 20.4384 4.91593 20.0321 4.5096C19.6258 4.10327 19.0747 3.875 18.5 3.875H16.3334"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 13.0834L11.4584 15.7917L9.83337 14.1667"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
