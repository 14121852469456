import React from 'react';
import styled from 'styled-components';
import success from '@unobravo-monorepo/common/assets/success.svg';
import successMobile from '@unobravo-monorepo/common/assets/Success-mobile-256.svg';
import { useNavigate } from 'react-router-dom';
import { mediaQueries, Theme } from '../..';
import {
  Body,
  BodySize,
  Heading,
  HeadingSize,
  Label,
  LabelSize
} from '../Typography';
import { Button } from '../Button';
import { useViewport } from '../../hooks/useViewportHook';
import { Logo } from '../Logo';

const BackgroundContainer = styled.div`
  align-items: center;
  background-image: url('${success}');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;

  ${mediaQueries('sm')`
    background-image: url('
    ${
      // @ts-expect-error
      successMobile
    }');
     background-size: contain;
    align-items: end;
  `}
`;

const ButtonContainer = styled.div`
  margin-top: 30px;

  ${mediaQueries('sm')`
  `}
`;

const FooterContainer = styled.div`
  padding-top: 60px;

  ${mediaQueries('sm')`
    padding-top: 20px;
  `}
`;

const StyledLogo = styled(Logo)`
  margin-top: 30px;
  margin-bottom: 50px;
  ${mediaQueries('sm')`
    margin: 30px;
  `}
`;

const Wrapper = styled.div`
  align-items: center;
  background-color: ${Theme.specialColors.white};
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 750px;
  padding: 60px;
  text-align: center;

  ${mediaQueries('sm')`
    border-radius: 50px 50px 0 0;
    height: 75%;
    padding: 20px;
  `}
`;

interface ISuccessPage {
  title: string;
  description: string;
  label: string;
  path?: string;
  btnLabel?: string;
  footer1: string;
  footer2: string;
}

export const SuccessPage: React.FC<ISuccessPage> = ({
  title,
  description,
  label,
  path,
  btnLabel,
  footer1,
  footer2
}) => {
  const navigate = useNavigate();
  const { isMobile } = useViewport();

  return (
    <BackgroundContainer>
      <Wrapper>
        <StyledLogo width={isMobile ? 200 : 240} />
        <div>
          <Label
            size={LabelSize.Label40}
            color={Theme.colors.candy[500]}
            margin="16px"
          >
            {label}
          </Label>
          <Heading size={isMobile ? HeadingSize.Title80 : HeadingSize.Title90}>
            {title}
          </Heading>
          <Body
            size={isMobile ? BodySize.Body70 : BodySize.Body80}
            margin="8px"
          >
            {description}
          </Body>
        </div>
        {path && btnLabel && (
          <ButtonContainer>
            <Button
              label={btnLabel}
              variant="primary"
              size="small"
              fullWidth={false}
              onClick={() => {
                navigate(path);
              }}
            />
          </ButtonContainer>
        )}
        <FooterContainer>
          <Body size={BodySize.Body80} color={Theme.colors.gray[900]}>
            {footer1}
          </Body>
          <Body size={BodySize.Body80}>{footer2}</Body>
        </FooterContainer>
      </Wrapper>
    </BackgroundContainer>
  );
};
