import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useViewport } from '../../hooks/useViewportHook';
import { Theme } from '../../theme';
import IInputField from '../../types/IInputField';
import { CheckInCircle, Clock } from '../Icons';
import { Body, BodySize, TypographyVariant } from '../Typography';

interface ITimeInput extends IInputField {
  successMessage?: string;
  label?: string;
  showIcon?: boolean;
}

const IconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 14px;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input<{ error?: boolean }>`
  width: 100%;
  min-width: 120px;
  max-width: 200px;
  height: 51px;
  padding: 0 16px 0 16px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: ${Theme.fonts.secondary};
  letter-spacing: 0.01em;
  border-radius: 8px;
  border: solid 1.5px
    ${({ error }) => (error ? Theme.colors.red[500] : Theme.colors.gray[200])};
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 0px ${Theme.colors.candy[200]};
  transition-timing-function: ease-out;
  transition: 0.3s;
  appearance: none;
  background: ${Theme.specialColors.white};
  margin-bottom: 26px;

  &:focus {
    outline: none;
    border: solid 1.5px ${Theme.colors.candy[500]};
    box-shadow: 0px 0px 0px 3px ${Theme.colors.candy[200]};
  }

  &::placeholder {
    color: ${Theme.colors.gray[300]};
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${Theme.colors.gray[300]};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:disabled {
    border: solid 1.5px ${Theme.colors.gray[100]};
    background-color: ${Theme.specialColors.white};
    color: ${Theme.colors.gray[200]};
    &::placeholder {
      color: ${Theme.colors.gray[100]};
    }
  }
`;

const InfoDiv = styled.div`
  position: absolute;
  bottom: -4px;
  left: 16px;
  height: 0;
  overflow: visible;
  display: flex;
  z-index: 2;
`;

export const TimeInput: React.FC<ITimeInput> = forwardRef<
  HTMLInputElement,
  ITimeInput
>((props, ref) => {
  const {
    errorMessage,
    label,
    successMessage,
    disabled,
    showIcon = true,
    ...rest
  } = props;
  const { isMobile } = useViewport();
  return (
    <InputWrapper>
      {label && (
        <Body size={BodySize.Body70} variant={TypographyVariant.Medium} margin>
          {label}
        </Body>
      )}
      <InputWrapper>
        {showIcon && (
          <IconWrapper>
            <Clock color={Theme.colors.candy[disabled ? 200 : 500]} />
          </IconWrapper>
        )}
        <Input
          ref={ref}
          type="time"
          error={!!errorMessage}
          disabled={disabled}
          data-testid="time-input"
          {...rest}
          style={{
            ...(showIcon && {
              paddingLeft: '48px',
              minWidth: '150px'
            })
          }}
        />
        {errorMessage && (
          <InfoDiv>
            <Body
              size={isMobile ? BodySize.Body50 : BodySize.Body60}
              color={Theme.colors.red[500]}
            >
              {errorMessage}
            </Body>
          </InfoDiv>
        )}
        {successMessage && !errorMessage && (
          <InfoDiv>
            <CheckInCircle
              color={Theme.colors.edamame[500]}
              width={18}
              height={18}
            />
            <Body
              size={BodySize.Body60}
              color={Theme.colors.edamame[500]}
              margin="0 0 0 4px"
            >
              {successMessage}
            </Body>
          </InfoDiv>
        )}
      </InputWrapper>
    </InputWrapper>
  );
});
